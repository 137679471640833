import React from 'react';
import trim from 'lodash/trim';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { VALIDATION_CODES } from './constants';
// eslint-disable-next-line no-useless-escape,no-control-regex
const emailPattern = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
const uintPattern = /^\d+$/;
const isEmpty = value => !value;
const join = rules => (value, data) => rules.map(rule => rule(value, data)).find(error => error);

export const createValidator = rules => (data = {}) => {
  const errors = {};

  Object.keys(rules).forEach((key) => {
    const rule = join([].concat(rules[key]));
    const error = rule(data[key], data);

    if (error) {
      errors[key] = <FormattedMessage id={error} />;
    }
  });

  return errors;
};

export const required = value => isEmpty(value) && VALIDATION_CODES.REQUIRED;
export const email = value => !isEmpty(value) && !emailPattern.test(value) && VALIDATION_CODES.EMAIL;
export const uint = value => !isEmpty(value) && !uintPattern.test(value) && VALIDATION_CODES.UINT;
export const emailsList = value => !isEmpty(value) && value.split(',').some(str => email(trim(str))) && VALIDATION_CODES.EMAILS_LIST;
export const date = value => !isEmpty(value) && !moment(value).isValid() && VALIDATION_CODES.DATE;
