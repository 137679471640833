import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik, Form } from 'formik';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import * as v from 'shared/validation';
import Modal, { ModalContent, ModalFooter } from 'components/Modal';
import { FILE_TYPE as EXPORT_FILE_TYPE } from 'ducks/exportFile/constants';
import { SIGNED } from 'shared/constants/questionnaireStatus';
import Button, { SECONDARY } from 'components/Button';
import FieldRow from 'components/form/FieldRow';
import InputText from 'components/form/InputText';
import Label from 'components/form/Label';
import ChoiceCard from 'components/ChoiceCard';
import styles from './index.css';

export class DownloadDocumentModalComponent extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onDownloadDocument: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({ documentName: PropTypes.string, exportMethod: PropTypes.oneOf(Object.keys(EXPORT_FILE_TYPE)) }),
    intl: intlShape.isRequired,
    exportToWordModule: PropTypes.bool.isRequired,
    isDownloading: PropTypes.bool,
    questionnaireStatus: PropTypes.string,
  };

  validator = v.createValidator({ documentName: [v.required], exportMethod: [v.required] });

  exportFileMethods = [
    {
      name: EXPORT_FILE_TYPE.PDF,
      choiceCard: {
        icon: <img src="/assets/icons/file-pdf-light.svg" alt="pdf-icon" className={styles.choiceCardIcon} />,
        label: 'PDF',
      },
      enabled: true,
    },
    {
      name: EXPORT_FILE_TYPE.DOCX,
      choiceCard: {
        icon: <img src="/assets/icons/file-microsoft-word.svg" alt="microsoft-word-icon" className={styles.choiceCardIcon} />,
        label: 'Word (.docx)',
      },
      enabled: this.props.exportToWordModule && this.props.questionnaireStatus !== SIGNED,
    },
  ];

  isInitialValuesValid = initialValues => (initialValues && Object.keys(initialValues).filter(e => !initialValues[e])).length === 0;

  render() {
    const { open, toggleModal, onDownloadDocument, intl, initialValues, isDownloading } = this.props;

    return (
      <Modal size="medium" toggleModal={toggleModal} open={open} modalTitle={<FormattedMessage id="questionnaire.download_document_modal.title" />}>
        <Formik onSubmit={onDownloadDocument} validate={this.validator} initialValues={initialValues} isInitialValid={this.isInitialValuesValid(initialValues)}>
          {({ handleSubmit, isValid }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <ModalContent>
                <p className={styles.description}>
                  <FormattedMessage id="questionnaire.download_document_modal.description" />
                </p>
                <FieldRow className={styles.fieldRow}>
                  <Label htmlFor="documentName">
                    <FormattedMessage id="questionnaire.download_document_modal.label" />
                  </Label>
                  <Field name="documentName">
                    {({ field, form }) => (
                      <InputText
                        errorMessage={form.errors[field.name]}
                        required
                        testId="DownloadDocument-name"
                        placeholder={intl.formatMessage({ id: 'questionnaire.download_document_modal.placeholder' })}
                        {...field} />
                    )}
                  </Field>
                </FieldRow>
                <FieldRow className={styles.fieldRow}>
                  <Label htmlFor="exportMethod">
                    <FormattedMessage id="questionnaire.download_document_modal.format_label" />
                  </Label>
                  <Field name="exportMethod">
                    {({ field, form }) => (
                      <div className={styles.exportMethods}>
                        {this.exportFileMethods.filter(e => e.enabled).map(method => (
                          <ChoiceCard
                            testId={`DownloadDocument-file-format-${method.name}`}
                            name={`DownloadDocument-file-format-${method.name}`}
                            className={styles.exportMethodCard}
                            checked={field.value === method.name}
                            onChange={() => form.setFieldValue('exportMethod', method.name)}
                            label={method.choiceCard.label}>
                            {method.choiceCard.icon}
                          </ChoiceCard>
                        ))}
                      </div>
                    )}
                  </Field>
                </FieldRow>
              </ModalContent>

              <ModalFooter>
                <Button onClick={toggleModal} variant={SECONDARY} testId="DownloadDocument-cancel" type="cancel" disabled={isDownloading}>
                  <FormattedMessage id="shared.cancel" />
                </Button>
                <Button disabled={!isValid || isDownloading} testId="DownloadDocument-save" type="submit" loading={isDownloading}>
                  <FormattedMessage id="questionnaire.download_document_modal.download" />
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default injectIntl(DownloadDocumentModalComponent);
