import assert from 'assert';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _noop from 'lodash/noop';
import { ThemeProvider } from 'styled-components';

import SelectItem from './Item';
import styles, {
  ComponentStyle,
  DisplayAreaStyle,
  DropdownArrowStyle,
  DropdownStyle,
  PlaceholderStyle,
  ValueStyle,
} from './styled-components';

const ALLOWED_MODIFIERS = ['small'];

class Select extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    modifiers: PropTypes.arrayOf(PropTypes.string),
    testId: PropTypes.string,
  };

  static defaultProps = { modifiers: [] };

  static getDerivedStateFromProps(props, state) {
    const newOptionData = React.Children.map(props.children, ({ props: childProps }) => childProps);

    if (_isEqual(state.optionsData, newOptionData)) {
      return null;
    }

    return { optionData: newOptionData };
  }

  state = {
    optionData: [],
    isDropdownVisible: false,
  };

  selectRef = React.createRef();

  componentWillUnmount() {
    // Ensure event listeners are removed
    this.hideDropdown();
  }

  toggleDropdown = () => {
    if (this.state.isDropdownVisible) {
      this.hideDropdown();
      return;
    }

    this.showDropdown();
  };

  handleOutsideClick = () => {
    this.hideDropdown();
  };

  handlePropagation = (e) => {
    e.nativeEvent.stopImmediatePropagation();
  };

  hideDropdown = () => {
    this.setState({ isDropdownVisible: false });
    global.document.removeEventListener('mousedown', this.handleOutsideClick);
  };

  showDropdown = () => {
    this.setState({ isDropdownVisible: true });
    global.document.addEventListener('mousedown', this.handleOutsideClick);
  };

  // Passing a faux event back since the app is configured to use
  // selects which return this value already
  handleClickFunc = value => () => {
    const fauxEvent = { target: this.selectRef.current, persist: _noop };

    this.selectRef.current.value = value;
    this.props.onChange(fauxEvent);
    this.toggleDropdown();
  };

  /**
   * Get <Option /> children by <Option /> value
   * @param {string} value
   * @return {string} Node - <Option /> node children
   */
  _getChildrenByValue = (value) => {
    const optionItem = this.state.optionData.find(item => item.value === value);
    assert.ok(optionItem, "The `value` attr doesn't match a value on the `<Options />`.");

    return optionItem.children;
  };

  _renderFauxChildren = () => {
    const fauxChildren = this.state.optionData.map(({ children, value }) => {
      const isSelected = `${value}` === `${this.props.value}`;

      return (
        <SelectItem key={value} onMouseDown={this.handleClickFunc(value)} value={value} isSelected={isSelected}>
          {children}
        </SelectItem>
      );
    });

    return fauxChildren;
  };

  render() {
    const { className, name, id, placeholder, value, modifiers, testId } = this.props;

    const modifiersObj = modifiers.reduce(
      (acc, item) => {
        if (ALLOWED_MODIFIERS.includes(item)) {
          acc[item] = true;
        }

        return acc;
      },
      ALLOWED_MODIFIERS.reduce((acc, modifierName) => {
        acc[modifierName] = false;

        return acc;
      }, {}),
    );

    return (
      <ThemeProvider theme={modifiersObj}>
        <ComponentStyle
          className={className}
          onMouseDown={this.handlePropagation}
          isOpen={this.state.isDropdownVisible}>
          <DisplayAreaStyle data-test-id={testId} onClick={this.toggleDropdown} className="js-Select-display">
            {!value && <PlaceholderStyle className="js-Select-placeholder">{placeholder}</PlaceholderStyle>}

            {value && <ValueStyle className="js-Select-value">{this._getChildrenByValue(value)}</ValueStyle>}
            <DropdownArrowStyle />
          </DisplayAreaStyle>

          {this.state.isDropdownVisible && (
            <DropdownStyle className="js-Select-dropdown">{this._renderFauxChildren()}</DropdownStyle>
          )}

          <input
            className="js-Select-input"
            style={styles.nativeInput}
            name={name}
            id={id}
            onChange={_noop}
            value={value}
            ref={this.selectRef}
            onFocus={this.showDropdown} />
        </ComponentStyle>
      </ThemeProvider>
    );
  }
}

export default Select;
