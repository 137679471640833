import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Creators as QuestionnaireActions } from 'ducks/questionnaire/actions';

class QuestionnaireCreator extends React.Component {
  static propTypes = {
    createUserQuestionnaire: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    match: ReactRouterPropTypes.match,
  };

  componentDidMount() {
    const {
      match: { params },
      createUserQuestionnaire,
    } = this.props;

    createUserQuestionnaire(params.templateId, this.props.mode);
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = { createUserQuestionnaire: QuestionnaireActions.createUserQuestionnaire };

export default connect(
  null,
  mapDispatchToProps,
)(QuestionnaireCreator);
