import styled from 'styled-components';
import Button from 'components/Button';
import { legacyMedia } from 'shared/styled-helpers';

/*
 * 260px - 90px (side menu width - collapsed side menu width)
 * 50% + 90px (doc preview width + collapsed side menu width)
 */
export const QuestionnaireWithProgressBar = styled.div`
  transition: margin 0.6s ease-in;
  flex: 2;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ${legacyMedia.sm`
    margin: 0;
  `}

  ${({ isLeftToolbarVisible }) => isLeftToolbarVisible === false && `margin-left: -170px;`}

  ${({ isPreviewVisible }) => isPreviewVisible === false && `margin-right: calc(-50% + 90px);`}
`;

export const QuestionnaireWrapper = styled.div`
  display: flex;
  padding: 50px 7.5%;
  min-width: 320px;
  overflow-x: auto;
  justify-content: center;
  transition: margin 0.6s ease-in;
`;

export const QuestionnaireContent = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  & > * {
    flex-shrink: 0;
  }
`;

export const StyledButton = styled(Button)`
  margin: 20px 10px 20px 0;
  align-self: baseline;
  ${legacyMedia.sm`
    width: 100%;
  `}
`;

export const StyledPreviousQuestion = styled.div`
  display: flex;
  align-items: center;
  min-height: 1.6rem;
  font-size: 0.9rem;
  margin-bottom: 10px;
  color: gray;
  cursor: pointer;
  svg {
    margin-right: 5px;
    width: 0.9rem;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  border-bottom: ${({ isVisible, theme }) => (isVisible ? `1px solid ${theme.border.normal}` : 0)};
  min-height: 68px;
  padding: 0 15px;
  ${p => !p.isVisible && 'padding: 0'};
`;

export const HeaderTitle = styled.strong`
  flex: 1;
  ${p => p.textCenter && ' text-align: center'};
  color: ${props => props.theme.font.dark};
  font-weight: 500;
  font-size: 1.2rem;
  margin-right: 80px;
`;

export const OpacityChanger = styled.div`
  transition-property: opacity;
  transition-delay: ${({ isVisible }) => (!isVisible ? '.6s' : '0s')};
  ${({ isVisible }) => !isVisible
    && `
      opacity: 0;
  `};
`;
