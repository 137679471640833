import assert from 'assert';

import ApiClient from '../ApiClient';

export default class FoldersService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  fetchFoldersRequest() {
    return this.apiClient.request('/folders', { method: 'get' });
  }

  fetchFolders(folderId) {
    assert.ok(folderId, 'folderId<string> is required');

    return this.apiClient.request(`/folders/${folderId}`, { method: 'get' });
  }

  createFolder(folderName, parentId) {
    assert.ok(folderName, 'folderName<string> is required');

    return this.apiClient.request('/folders', {
      method: 'post',
      data: { name: folderName, parent_id: parentId },
    });
  }

  deleteFolder(folderId) {
    assert.ok(folderId, 'id<string> is required');

    return this.apiClient.request(`/folders/${folderId}`, { method: 'delete' });
  }

  updateFolder(folderId, folder) {
    assert.ok(folderId, 'id<string> is requred');
    assert.ok(folder, 'folder is requred');

    return this.apiClient.request(`/folders/${folderId}`, {
      method: 'patch',
      data: folder,
    });
  }
}
