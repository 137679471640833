import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { legacyMedia } from 'shared/styled-helpers';

const StyledSvg = styled.svg`
  display: none;
  ${legacyMedia.sm`display: block`};
  ${({ isListOpen }) => isListOpen
    && `
    transform: rotate(-180deg);
  `} transition: transform .5s ease-in;
`;

const ArrowDown = ({ onClick, isListOpen }) => (
  <StyledSvg
    version="1.1"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    onClick={onClick}
    isListOpen={isListOpen}
    style={{
      mixBlendMode: 'normal',
      fill: 'rgb(48, 69, 85)',
      width: '28px',
      height: '28px',
      filter: 'none',
    }}
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M7 10l5 5 5-5z" style={{ fill: 'rgb(48, 69, 85)' }} />
    </g>
  </StyledSvg>
);

ArrowDown.propTypes = {
  onClick: PropTypes.func,
  isListOpen: PropTypes.bool,
};

export default ArrowDown;
