import each from 'lodash/each';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import isQuestion from 'Visualization/helpers/isQuestion';
import { QUESTION_CLASS } from 'Visualization/helpers/constants';
import { notificationIconTag as notificationIconRegex } from 'utils/regex';

function mergeSimilarNodes() {
  const questions = document.querySelectorAll(QUESTION_CLASS);

  each(questions, (question) => {
    const questionSibbling = question.nextElementSibling;

    if (
      isQuestion(question)
      && isQuestion(questionSibbling)
      && sameAttributes(question, questionSibbling)
    ) {
      // if both question and its sibbling have same attributes
      // we should merge those nodes
      mergeNodes(question, questionSibbling);

      // do it again till there are no nodes to merge
      mergeSimilarNodes();
    }
  });
}

function sameAttributes(node1, node2) {
  const node1Attributes = node1.getAttributeNames();
  const node1AttrValues = map(node1Attributes, attr => node1.getAttribute(attr));

  const node2Attributes = node2.getAttributeNames();
  const node2AttrValues = map(node2Attributes, attr => node2.getAttribute(attr));

  return isEqual(node1AttrValues, node2AttrValues);
}

function mergeNodes(node1, node2) {
  const node1Content = node1.innerHTML;
  const node2Content = removeNotificationIcons(node2.innerHTML);
  node2.remove();
  node1.innerHTML = node1Content + node2Content; // eslint-disable-line no-param-reassign
}

function removeNotificationIcons(html) {
  return html.replace(notificationIconRegex, '');
}

export default debounce(mergeSimilarNodes, 500);
