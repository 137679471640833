import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import Modal, { ModalContent, ModalFooter } from 'components/Modal';
import Switch from 'components/form/Switch';
import Button, { SECONDARY } from 'components/Button';
import withNotificationsHoc from 'shared/NotificationSystem/withNotificationsHoc';
import {
  CopyInput, Subtitle, CopyButtonWrapper, CopyButton,
  InformationLabel, InformationWrapper, StyledInfoIcon, EnableLabel, EnableTemplateWrapper,
} from './styled-components';


class SharedLinkModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    toggleSharingTemplate: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    infoNotification: PropTypes.func.isRequired,
    sharingActive: PropTypes.bool.isRequired,
    sharingSlug: PropTypes.string.isRequired,
  };

  copyInput = null;

  setCopyInputRef = (element) => {
    this.copyInput = element;
  };

  handleSharingToggle = () => {
    const { toggleSharingTemplate, sharingActive } = this.props;
    toggleSharingTemplate(!sharingActive);
  };

  handleCopy = () => {
    const { infoNotification, intl } = this.props;
    this.copyInput.select();
    const copySucceeded = document.execCommand('copy');
    this.copyInput.blur();
    if (copySucceeded) {
      infoNotification({
        message: intl.formatMessage({ id: 'share_template.copied' }),
        position: 'bl',
      });
    }
  };

  render() {
    const { open, toggleModal, sharingActive, sharingSlug } = this.props;
    return (
      <Modal
        open={open}
        toggleModal={toggleModal}
        modalTitle={<FormattedMessage id="share_template.title" />}
        testId="template-settings-popup">
        <ModalContent>
          <Subtitle>
            <FormattedMessage id="share_template.description" />
          </Subtitle>
          <EnableTemplateWrapper>
            <EnableLabel>
              <FormattedMessage id="share_template.enable" />
            </EnableLabel>
            <Switch
              onClick={this.handleSharingToggle}
              checked={sharingActive} />
          </EnableTemplateWrapper>
          <CopyButtonWrapper>
            <CopyInput
              value={`${window.location.origin.toString()}/generic-access/${sharingSlug}`}
              blocked={!sharingActive}
              innerRef={this.setCopyInputRef}
              readOnly />
            <CopyButton onClick={this.handleCopy} disabled={!sharingActive} variant={SECONDARY}>
              <FormattedMessage id="share_template.copy" />
            </CopyButton>
          </CopyButtonWrapper>
        </ModalContent>
        <ModalFooter>
          <InformationWrapper>
            <StyledInfoIcon />
            <InformationLabel>
              <FormattedMessage id="share_template.info" />
            </InformationLabel>
          </InformationWrapper>
          <Button onClick={toggleModal} testId="SharedLinkModal-ok">
            <FormattedMessage id="shared.ok" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default compose(
  injectIntl,
  withNotificationsHoc,
)(SharedLinkModal);
