import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InputText from '../InputText/index';
import styles from './index.css';

const defaultRowsNumber = 10;

class TextArea extends PureComponent {
  static propTypes = {
    errorMessage: PropTypes.node,
    textAreaRef: PropTypes.func,
    testId: PropTypes.string.isRequired,
  };

  render() {
    return (
      <InputText
        rows={defaultRowsNumber}
        rowsMax={defaultRowsNumber}
        multiline
        multilineClassName={styles.wrapper}
        inputMultilineClassName={styles.textarea}
        {...this.props} />
    );
  }
}

export default TextArea;
