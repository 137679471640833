import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import Modal, { ModalContent, ModalFooter } from 'components/Modal';
import Button, { SECONDARY } from 'components/Button';
import Label from 'components/form/Label';
import InputText from 'components/form/InputText';
import { withFormik, Field } from 'formik';
import * as v from 'shared/validation';
import { compose } from 'redux';
import { Creators as documentDetailsActions } from 'pages/DocumentDetails/actions';
import { selectCurrentDocumentVersion, selectDocumentId, selectDocumentName } from 'pages/DocumentDetails/selectors';

import styles from './SaveVersionModal.css';

export class SaveVersionModalComponent extends PureComponent {
  static propTypes = {
    toggleModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    currentVersion: PropTypes.shape({ revision: PropTypes.number.isRequired }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
  };

  render() {
    const { toggleModal, handleChange, handleSubmit, handleBlur, isValid, currentVersion, intl } = this.props;
    return (
      <Modal
        modalTitle={<FormattedMessage id="questionnaire.save_version_modal.title" />}
        open
        size="medium"
        toggleModal={toggleModal}>
        <form onSubmit={handleSubmit}>
          <ModalContent>
            <p className={styles.text}>
              <FormattedMessage id="questionnaire.save_version_modal.description" />
            </p>
            <div className={styles.versionContainer}>
              <Label><FormattedMessage id="questionnaire.save_version_modal.version" /></Label>
              <span className={styles.version}>{currentVersion.revision}</span>
            </div>
            <Label><FormattedMessage id="questionnaire.save_version_modal.name" /></Label>
            <Field
              name="documentName"
              onChange={handleChange}
              onBlur={handleBlur}>
              {({ form, field }) => (
                <InputText
                  {...field}
                  autoFocus
                  placeholder={intl.formatMessage({ id: 'questionnaire.save_version_modal.placeholder' })}
                  errorMessage={form.errors[field.name]}
                  testId="FolderModal-input" />
              )}
            </Field>
          </ModalContent>
          <ModalFooter>
            <Button onClick={toggleModal} variant={SECONDARY} testId="DocumentPreview-SaveVersionCancel">
              <FormattedMessage id="shared.cancel" />
            </Button>
            <Button type="submit" disabled={!isValid} testId="DocumentPreview-DocumentPreview-SaveVersionAccept">
              <FormattedMessage id="questionnaire.save_version" />
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

const validator = v.createValidator({ documentName: [v.required] });

const formikConfig = {
  mapPropsToValues: props => ({ documentName: props.documentName }),
  isInitialValid: (props) => {
    const errors = props.validate(props.initialValues);
    return isEmpty(errors);
  },
  validate: validator,
  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true);
    const { saveDocumentVersion, documentId, currentVersion } = props;
    saveDocumentVersion(documentId, values.documentName, currentVersion);
    props.toggleModal();
  },
};

const mapStateToProps = state => ({
  documentName: selectDocumentName(state),
  currentVersion: selectCurrentDocumentVersion(state),
  documentId: selectDocumentId(state),
});

const mapDispatchToProps = { saveDocumentVersion: documentDetailsActions.saveDocumentVersionRequest };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withFormik(formikConfig),
)(SaveVersionModalComponent);
