import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import cn from 'classnames';
import Button, { PRIMARY, SECONDARY } from 'components/Button';
import { MenuList } from 'components/MenuList';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styles from './index.css';

const SplitButton = ({ onClick, children, options, variant, btnTestId }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    if (open !== false) {
      setOpen(false);
    }
  };

  return (
    <Fragment>
      <div className={styles.buttonGroup} ref={anchorRef} aria-label="split button">
        <Button
          testId={btnTestId}
          variant={variant}
          className={cn(styles.button, { [styles.buttonSecondary]: variant === SECONDARY })}
          onClick={onClick}>
          {children}
        </Button>
        <ClickAwayListener onClickAway={handleClose}>
          <Button
            variant={variant}
            className={cn(styles.arrowButton, { [styles.arrowButtonSecondary]: variant === SECONDARY })}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ClickAwayListener>
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end">
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <MenuList id="split-button-menu">
                {options}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

SplitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  options: PropTypes.node,
  variant: PropTypes.oneOf([PRIMARY, SECONDARY]),
  btnTestId: PropTypes.string,
};

SplitButton.defaultProps = { variant: PRIMARY };

export default SplitButton;
