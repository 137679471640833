import styled from 'styled-components';
import Color from 'color';
import { ICONS_URLS } from 'Visualization/helpers/constants';

function getQuestionColor(colors, index) {
  if (index >= colors.length) {
    return colors[0];
  }
  return colors[index];
}

export const QuestionWrapper = styled.div`
  ${(props) => {
    const color = getQuestionColor(props.theme.flowColors, props.parentCount);
    const isIE = /* @cc_on!@ */ false || !!document.documentMode;

    return `
    .editor__question-wrapper & {
      display: ${props.isBlock ? 'block' : 'inline'};
      background: ${Color(color).fade(0.9)};
      &:hover {
        background: ${Color(color).fade(0.5)};
      }
      &:before {
        display: ${props.isBlock ? 'block' : 'inline-block'};
        position: relative;
        top: 3px;
        width: 1em;
        height: 1em;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
        content: '';
        mask-image: url("${ICONS_URLS[props.logicType]}");
        mask-size: cover;
        background-color: ${color};
        ${isIE ? `
          background-image: url("${ICONS_URLS[props.logicType]}");
          background-size: 13px 13px;
        ` : ''}
      }
      &.selected {
        background: ${Color(color).fade(0.5)};
      }
      &:not(.selected).hovered {
        background: ${Color(color).fade(0.8)};
      }
    }
  `;
  }};
`;

export const BlockElement = QuestionWrapper.withComponent('div');
export const InlineElement = QuestionWrapper.withComponent('span');
