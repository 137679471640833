exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-Editor-___index__toolbarContainer___3eNW9 > div {\n    /* Important here is because the tinymce sets width as inline style, and for some reason it calculates it incorrectly */\n    width: 100% !important;\n    border: 0;\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);\n  }\n", "", {"version":3,"sources":["/workspace/app/components/Editor/index.css"],"names":[],"mappings":"AACE;IACE,wHAAuH;IACvH,uBAAsB;IACtB,UAAS;IACT,6EAA4E;GAC9E","file":"index.css","sourcesContent":[".toolbarContainer {\n  & > div {\n    /* Important here is because the tinymce sets width as inline style, and for some reason it calculates it incorrectly */\n    width: 100% !important;\n    border: 0;\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"toolbarContainer": "app-components-Editor-___index__toolbarContainer___3eNW9"
};