import assert from 'assert';

import ApiClient from '../ApiClient';

export default class DocumentVersionsService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  fetchDocumentDetails(documentId) {
    assert.ok(documentId, 'documentId<string> is required');

    return this.apiClient.get(`/documents/${documentId}`);
  }

  fetchDocumentVersions(documentId) {
    assert.ok(documentId, 'documentId<string> is required');

    return this.apiClient.get(`/documents/${documentId}/versions`);
  }

  fetchDocumentVersion(versionLink) {
    assert.ok(versionLink.startsWith('http'), 'versionLink<URL> should be an absolute url');

    return this.apiClient.get('', { url: versionLink });
  }

  createDocumentVersion(documentId, name, html) {
    assert.ok(documentId, 'documentId<string> is required');
    assert.ok(name, 'name<string> is required');


    return this.apiClient.post(`/documents/${documentId}/versions`, { data: { name, html } });
  }

  updateDocumentVersion(versionLink, version) {
    assert.ok(versionLink, 'versionLink<string> is required');

    return this.apiClient.put('', { url: versionLink, data: version });
  }

  updateDocumentVersionName(versionLink, name) {
    assert.ok(versionLink, 'versionLink<string> is required');
    assert.ok(name, 'name<string> is required');

    return this.apiClient.patch('', { url: versionLink, data: { name } });
  }

  saveDocumentVersion(documentId, versionId) {
    assert.ok(documentId, 'documentId<string> is required');
    assert.ok(versionId, 'versionId<string> is required');

    return this.apiClient.put(`/documents/${documentId}/versions/${versionId}/complete`);
  }
}
