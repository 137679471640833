import styled from 'styled-components';
import { ICONS_URLS } from 'Visualization/helpers/constants';
import { legacyMedia } from 'shared/styled-helpers';
import { OpacityChanger } from '../styled-components';

export const QuestionnaireCompletedWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 800px;
  margin: auto;
`;

const PreviewContainer = OpacityChanger.extend`
  width: 100%;
  margin: 30px auto 60px auto;
  padding: 60px 70px 70px 70px;
  overflow: auto;
  background-color: #fff;
  color: #304555;
  text-rendering: optimizeLegibility;
  line-height: 1.666;
  box-shadow: 5px 1px 25px rgba(48,69,85,0.06);
  strong { font-weight: bold }
  table th,
  table td {
    border: 1px solid #ddd;
  }
  h1,
  h2 {
    margin: 0.67rem 0;
  }
  h3,
  h4 {
    margin: 1rem 0;
  }
  .not_ready {
    background-color: pink;
  }
  [data-logictype="choiceShowIfType"], [data-logictype="inputReplaceType"] {
    background-color: rgba(62, 148, 246, 0.15);
    cursor: ${p => (p.isClickablePreview ? 'pointer' : 'default')};
    &:hover {
      background-color: rgba(62, 148, 246, 0.30);
    }
  }
  [data-question] {
    &.current-question-highlight {
      background-color: #e2f3fd !important;
      color: #509cf7;
      font-weight: bold;
    }
    &.answered-question-highlight {
      background-color: #e9e9e9;
    }
    &:before {
      text-align: center;
      cursor: pointer;
      font-family: FontAwesome;
    }
    &:not(.not_ready):before {
      content: none
    }
  }
  [data-question='block'] {
    display: block;
    &:before {
      display: block;
      width: 100%;
    }
  }
  [data-question='inline'] {
    display: inline;
    &:before {
      display: inline-block;
      width: 1em;
    }
  }
  [data-logictype='inputReplaceType'] {
    &:before {
      content: '${ICONS_URLS.inputReplaceType}';
    }
  }
  [data-logictype='anchorType'] {
    &:before {
      content: '${ICONS_URLS.anchorType}';
    }
  }
  [data-logictype='choiceShowIfType'] {
    &:before {
      content: '${ICONS_URLS.choiceShowIfType}';
    }
  }
  ${legacyMedia.sm`
    padding: 24px 12px;
  `}
`;

export const PreviewContainerWithTooltip = PreviewContainer.extend`
  .tooltip {
    padding: 8px 12px;
    top: -20px;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: white;
    background: #35404a;
    max-width: 300px;
    border-radius: 4px;
  }

  .popper .popper__arrow,
  .tooltip .tooltip-arrow {
    border-style: solid;
    margin: 5px;
    position: absolute;
  }

  .tooltip .tooltip-arrow,
  .popper .popper__arrow {
    border-color: #35404a;
  }

  .popper[x-placement^="top"],
  .tooltip[x-placement^="top"] {
    margin-bottom: 5px;
  }

  .popper[x-placement^="top"] .popper__arrow,
  .tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .popper[x-placement^="bottom"],
  .tooltip[x-placement^="bottom"] {
    margin-top: 5px;
  }

  .tooltip[x-placement^="bottom"] .tooltip-arrow,
  .popper[x-placement^="bottom"] .popper__arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }
`;
