import styled, { injectGlobal } from 'styled-components';
import { rem } from 'polished';
import { StickyContainer } from 'react-sticky';

const renderIcons = () => {
  const icons = require.context('./../assets/tinymce', true, /\.svg$/);
  return icons.keys().map((key) => {
    const className = key.replace(/(^\.\/|\.svg$)/gm, '');
    // https://stackoverflow.com/questions/21509982/change-svg-fill-color-in-before-or-after-css
    // Adding important to content in order to override the font icons from tinymce
    return `
      .${className}::before {
        content: url("${icons(key)}") !important;
        display: inline-block;
      }
    `;
  });
};

// TODO: We should check our global styles of inputs etc., they probably break tinyMce modal size calculation
// https://stackoverflow.com/questions/42550955/how-to-control-the-width-and-height-of-tinymce-modal
/* eslint-disable */
injectGlobal`
  i.mce-i-backcolor {
    background-color: transparent !important;
  };


  .lex-hide-mce-tooltips {
      .mce-floatpanel.mce-arrow {
        display: none !important;
      }

      .mce-widget.mce-tooltip {
        display: none !important;
    }
   }

    [class*='mce-i-']::before {
      width: 16px;
      height: 16px;
      margin: 0;
   }

   .mce-active {
      background-color: #2276d2;
      color: white;
   }

    .mce-window-body {
      width: inherit !important;
   }

    .mce-window {
      width: 30% !important;
      left: 50% !important;
      top: 50% !important;
      margin-left: -15% !important;
      margin-top: -150px !important;
   }

   .mce-container.mce-panel.mce-floatpanel.mce-window.mce-in {

      .mce-title {
        text-align: center;
     }

      .mce-container-body,.mce-container {
        width: 100% !important;
     }

      .mce-widget {
        width: auto !important;
      }

      .mce-textbox {
       font-size: ${rem('15px')} !important;
       height: 2rem !important;
       border: 1px solid #e9edf1 !important;
       background-color: #fff !important;
       border-radius: 4px;
       padding: 0 5px;
       margin: 3px 0;
      }

      .mce-btn.mce-open {
         border: 1px solid #e9edf1;
         border-radius: 4px;
         vertical-align: sub;
         height: 2rem;
      }

      .mce-foot {
        .mce-btn-has-text {
          margin: 10px 10px 10px 0;
          position: relative;
          float: right;
          left: 0 !important;
          top: 0 !important;
        }
      }
   }
  ${renderIcons()};
`;
/* eslint-enable */

export const EditorViewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const EditorSide = styled.div`
  flex: 5;
  padding: 20px;
`;
export const StickyRightSidebarContainer = styled(StickyContainer)`
  min-width: 417px;
  flex: 0 0 417px;
  padding: 0;
  background-color: ${props => props.theme.background.white};
  border-left: 1px solid ${p => p.theme.border.normal};
`;

export const EditorWithFlow = styled.div`
  display: flex;
`;

export const EditorWithToolbar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px auto 0;
  flex: 0 1 842px;
  width: 842px;
  margin: 0 auto;

  .mce-content-body {
    background-color: #fff;
    width: 100%;
    min-height: 400px;
    padding: 5px;
  }
`;
