import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FolderModal from './FolderModal';

export class CreateFolderModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func,
    parentFolderId: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
  };

  onSubmit = (folderName) => {
    this.props.onSubmit(folderName, this.props.parentFolderId);
  };

  render() {
    const { isOpen, onModalClose } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <FolderModal
        modalTitleId="panel.folders.folder_modal.create"
        onSubmit={this.onSubmit}
        isOpen
        name=""
        onModalClose={onModalClose} />
    );
  }
}
