import Label from 'components/form/Label';
import { rem } from 'polished';
import styled from 'styled-components';

export const SkipQuestionLabel = styled(Label)`
  font-size: ${rem('13px')};
  background: none;
  color: #a9b1bb;
`;

export const HelpTextContainer = styled.div`
  word-break: break-word;
  transition: background-color 0.2s;
  background-color: ${p => (p.open ? '#fff' : 'transparent')};
  padding: 20px;
  margin-left: -20px;
  margin-top: -10px;
`;
export const HelpTextHelpContent = styled.div`
  transition: max-height 0.2s ease-in;
  margin-bottom: 20px;
  overflow: hidden;
  color: ${({ theme }) => theme.font.lightGray};

  a {
    color: ${({ theme }) => theme.blueLight};
    text-decoration: underline;
  }

  > span {
    padding-right: 5px;
    font-weight: 300;
  }
`;

export const HelpTextWrapper = styled.div`
  transition: max-height 0.5s linear;
  height: auto;
  word-wrap: break-word;
  max-height: ${p => (p.showFullText ? p.maxHeight : '2.2rem')};
  line-height: 2rem;

  overflow: hidden;
  strong {
    font-weight: bold;
  }
  p {
    font-size: 1rem;
    line-height: 22px;    
    margin: 0 0 11px 0;
  }
`;

export const HelpTextButtonWrapper = styled.div`
  border-top: 1px solid #e3e3e3;
  margin-top: 5px;
`;

export const SkipHeading = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 20px;
  margin-bottom: 12px;
  &:after {
    content: '';
    width: 100%;
    background-color: #dbdde7;
    height: 1px;
    margin-left: 15px;
  }
`;
