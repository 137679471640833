import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './index.css';

export const SwitchIcon = ({ checked }) => (
  <span className={styles.iconContainer}>
    <span className={cn(checked ? styles.iconChecked : styles.iconUnchecked, styles.icon)} />
  </span>
);

SwitchIcon.propTypes = { checked: PropTypes.bool };
