import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import DocumentTableRow from 'pages/Documents/components/TableRows/DocumentTableRow';

class DocumentsTable extends PureComponent {
  static propTypes = {
    history: ReactRouterPropTypes.history,
    fetchDocumentsForTemplateRequest: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({
      version: PropTypes.string,
      isPublished: PropTypes.bool,
    })),
    match: PropTypes.shape({ params: PropTypes.shape({ templateId: PropTypes.string }) }),
    deleteDocumentRequest: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      match: { params },
      fetchDocumentsForTemplateRequest,
    } = this.props;
    fetchDocumentsForTemplateRequest(params.templateId);
  }

  renderTableRow = (doc) => {
    const { history, deleteDocumentRequest, duplicateDocumentEnabled } = this.props;
    return (
      <DocumentTableRow
        document={{
          ...doc,
          state: doc.status,
          owner: {
            ...doc.createdBy,
            role: doc.createdBy.roles[0],
            type: doc.ownerType,
          },
        }}
        key={doc.id}
        duplicateDocumentEnabled={duplicateDocumentEnabled}
        moveToTrash={deleteDocumentRequest}
        goToRoute={history.push} />
    );
  };

  render() {
    const { data } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="35%">
              <FormattedMessage id="panel.questionnaire_templates.template_documents.document" />
            </TableCell>
            <TableCell width="10%">
              <FormattedMessage id="panel.questionnaire_templates.template_documents.created_at" />
            </TableCell>
            <TableCell width="10%">
              <FormattedMessage id="panel.questionnaire_templates.template_documents.edited_at" />
            </TableCell>
            <TableCell width="15%">
              <FormattedMessage id="panel.questionnaire_templates.template_documents.status" />
            </TableCell>
            <TableCell width="30%">
              <FormattedMessage id="panel.questionnaire_templates.template_documents.created_by" />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(this.renderTableRow)}
        </TableBody>
      </Table>
    );
  }
}

export default withRouter(DocumentsTable);
