import React from 'react';
import PropTypes from 'prop-types';
import MTabs from '@material-ui/core/Tabs';
import MTab from '@material-ui/core/Tab';
import styles from './index.css';

export const Tab = props => (
  <MTab
    classes={{
      root: styles.tabRoot,
      selected: styles.selectedTab,
      label: styles.tabLabel,
    }}
    {...props} />
);

const Tabs = (props) => {
  const { onChange, currentTabIndex, indicatorPosition, ...rest } = props;
  return (
    <MTabs
      {...rest}
      value={currentTabIndex}
      onChange={onChange}
      indicatorColor="primary"
      classes={{ indicator: styles.indicator }}>
      {props.children}
    </MTabs>
  );
};
Tabs.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired,
  indicatorPosition: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
};

Tabs.defaultProps = { indicatorPosition: 'top' };

export default Tabs;
