import React from 'react';

const EmailSigningLogo = props => (
  <svg
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 121.155 49.166"
    height={16.193}
    width={41}
    {...props}>
    <g fill="currentColor">
      <path
        d="M48.877 23.679H2.741a1.74 1.74 0 1 0 0 3.48h46.136a1.74 1.74 0 0 0 0-3.48zM50.475 17.334a1.74 1.74 0 0 0-1.739-1.74H9.905a1.74 1.74 0 0 0 0 3.479h38.831c.96 0 1.739-.778 1.739-1.739zM48.735 31.573H14.958a1.74 1.74 0 1 0 0 3.48h33.777a1.74 1.74 0 1 0 0-3.48zM120.151 2.959c-.002-.068-.012-.136-.02-.204-.008-.062-.014-.125-.026-.185-.013-.06-.034-.117-.054-.176-.021-.067-.04-.134-.068-.198-.022-.051-.053-.1-.08-.149-.037-.067-.072-.133-.115-.196-.01-.012-.014-.025-.023-.038-.029-.04-.064-.07-.097-.107-.034-.041-.063-.084-.101-.123-.012-.012-.026-.02-.039-.032-.049-.047-.104-.087-.156-.128-.051-.04-.102-.08-.156-.115-.053-.034-.109-.06-.166-.088-.061-.03-.119-.062-.184-.086-.057-.021-.115-.036-.174-.052a1.604 1.604 0 0 0-.196-.049c-.066-.012-.133-.015-.2-.019-.049-.003-.096-.014-.147-.014l-63 .146c-.133 0-.262.02-.387.02h-.005c-.166 0-.321.113-.47.185-.016.008-.033.02-.049.027-.021.011-.039.036-.061.048-.119.067-.23.148-.334.238-.023.021-.049.039-.072.061-.117.113-.219.24-.307.378-.016.026-.028.055-.043.082a1.94 1.94 0 0 0-.162.37 1.781 1.781 0 0 0-.103.598l-.002.013v43l.002.021c.001.083.014.165.025.248.006.044.006.09.016.134.021.099.053.194.088.29.01.025.016.053.025.077.035.085.084.166.131.246.02.033.035.069.057.102.039.057.087.107.131.16.037.045.07.094.111.136l.018.014.016.019c.041.04.09.069.134.104.053.042.103.088.159.124.066.043.135.075.205.109.047.022.092.05.14.069.074.029.149.049.227.069.05.014.099.03.15.04.071.014.142.017.214.021.051.004.102.016.152.016h63a2.01 2.01 0 0 0 1.414-.586 2.01 2.01 0 0 0 .586-1.414V3c0-.014-.004-.027-.004-.041zM87.047 23.446L61.382 5.149l50.667-.133-25.002 18.43zM57.155 7.048l19.391 13.823-19.391 20.305V7.048zm22.679 16.169l6.071 4.328a2.004 2.004 0 0 0 2.348-.019l6.13-4.519 18.673 21.158H59.83l20.004-20.948zm17.781-2.592l18.54-13.666v34.673l-18.54-21.007z"
        vectorEffect="non-scaling-stroke" />
    </g>
  </svg>
);

export default EmailSigningLogo;
