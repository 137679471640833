exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-pages-Questionnaire-components-ToggleButton-___styles__component___3bVwU {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  word-break: break-all;\n  outline: none;\n  width: 80px;\n  margin-left: 5px;\n  margin-right: 5px;\n}\n\n.app-pages-Questionnaire-components-ToggleButton-___styles__componentReverse___AIEds {\n  flex-direction: row-reverse;\n}\n\n.app-pages-Questionnaire-components-ToggleButton-___styles__componentUnchecked___2EKCa {\n  flex-direction: column;\n}\n\nbutton.app-pages-Questionnaire-components-ToggleButton-___styles__iconBtn___3u5xV {\n  color: #2d866e;\n  color: var(--c-brand);\n}\n\nbutton.app-pages-Questionnaire-components-ToggleButton-___styles__iconBtn___3u5xV:hover {\n  color: #2d866e !important;\n  color: var(--c-brand) !important;\n}\n\n.app-pages-Questionnaire-components-ToggleButton-___styles__icon___6A76- {\n  border: 1px solid #2d866e;\n  border: 1px solid var(--c-brand);\n  border-radius: 50%;\n}\n", "", {"version":3,"sources":["/workspace/app/pages/Questionnaire/components/ToggleButton/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAa;EACb,oBAAmB;EACnB,oBAAmB;EACnB,gBAAe;EACf,sBAAqB;EACrB,cAAa;EACb,YAAW;EACX,iBAAgB;EAChB,kBAAiB;CACnB;;AAEA;EACE,4BAA2B;CAC7B;;AAEA;EACE,uBAAsB;CACxB;;AAEA;EACE,eAAqB;EAArB,sBAAqB;CACvB;;AAEA;EACE,0BAAgC;EAAhC,iCAAgC;CAClC;;AAEA;EACE,0BAAgC;EAAhC,iCAAgC;EAChC,mBAAkB;CACpB","file":"styles.css","sourcesContent":[".component {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  word-break: break-all;\n  outline: none;\n  width: 80px;\n  margin-left: 5px;\n  margin-right: 5px;\n}\n\n.componentReverse {\n  flex-direction: row-reverse;\n}\n\n.componentUnchecked {\n  flex-direction: column;\n}\n\nbutton.iconBtn {\n  color: var(--c-brand);\n}\n\nbutton.iconBtn:hover {\n  color: var(--c-brand) !important;\n}\n\n.icon {\n  border: 1px solid var(--c-brand);\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-pages-Questionnaire-components-ToggleButton-___styles__component___3bVwU",
	"componentReverse": "app-pages-Questionnaire-components-ToggleButton-___styles__componentReverse___AIEds",
	"componentUnchecked": "app-pages-Questionnaire-components-ToggleButton-___styles__componentUnchecked___2EKCa",
	"iconBtn": "app-pages-Questionnaire-components-ToggleButton-___styles__iconBtn___3u5xV",
	"icon": "app-pages-Questionnaire-components-ToggleButton-___styles__icon___6A76-"
};