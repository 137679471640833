exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-Question-___UnsignedIntegerQuestion__input___2mcXa {\n  max-width: 192px;\n}\n", "", {"version":3,"sources":["/workspace/app/components/Question/UnsignedIntegerQuestion.css"],"names":[],"mappings":"AAAA;EACE,iBAAgB;CAClB","file":"UnsignedIntegerQuestion.css","sourcesContent":[".input {\n  max-width: 192px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"input": "app-components-Question-___UnsignedIntegerQuestion__input___2mcXa"
};