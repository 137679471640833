import { createSelector } from 'reselect';
import convert from 'shared/utils/questionnaireTemplateConverter';
import { snakeToCamelCase } from 'shared/helpers/transforms';

const wysiwygHtmlSelector = state => state.questionnaire.wysiwygHtml;
const questionsSelector = state => snakeToCamelCase(state.questionnaire.questions);
const answersSelector = state => snakeToCamelCase(state.questionnaire.answers);
const templateSettings = state => state.questionnaire.settings;
export const stylesheetSelector = state => state.questionnaire.stylesheet;

const previewHtmlSelector = createSelector(
  wysiwygHtmlSelector,
  questionsSelector,
  answersSelector,
  stylesheetSelector,
  templateSettings,
  (wysiwygHtml, questions, answers, stylesheet, settings) => {
    if (answers !== undefined && questions !== undefined && stylesheet !== undefined && wysiwygHtml !== undefined) {
      return convert(wysiwygHtml, questions, answers, true, stylesheet, settings);
    }
    return '';
  },
);

export default previewHtmlSelector;
