import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputRadio from 'components/form/InputRadio';

import styles from './index.css';

class RadioGroup extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]),
      label: PropTypes.node,
      subtitle: PropTypes.node,
    })),
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    testId: PropTypes.string.isRequired,
  };

  render() {
    const { className, name, value, onChange, options, testId, ...otherProps } = this.props;
    const classString = cn(styles.component, className);

    return (
      <fieldset {...otherProps} className={classString} name={name} data-test-id={testId}>
        {options.map(role => (
          <div className={styles.wrapper} key={role.value}>
            <InputRadio
              value={role.value}
              name={name}
              checked={value === role.value}
              onChange={onChange}
              id={`radioGroup-${role.value}`}
              testId={`${testId}-${role.value}`}
              required />
            <label htmlFor={`radioGroup-${role.value}`} className={styles.label}>
              <span className={styles.title}>{role.label}</span>
              <span className={styles.subtitle}>{role.subtitle}</span>
            </label>
          </div>
        ))}
      </fieldset>
    );
  }
}

export default RadioGroup;
