import assert from 'assert';

import ApiClient from '../ApiClient';

export default class QuestionnaireService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  getUserQuestionnairePreviewForTemplate(qtvId) {
    assert.ok(qtvId, 'questionId<string> is required');

    return this.apiClient.request(`/questionnaire_template_versions/${qtvId}/user_questionnaire_preview`, { method: 'post' });
  }

  getDraftTemplateVersion(templateId) {
    assert.ok(templateId, 'templateId<string> is required');

    return this.apiClient.request(`/questionnaire_templates/${templateId}/edit`, { method: 'get' });
  }

  removeAllAnswersFromQuestionnaire(userQuestionnaireId) {
    assert.ok(userQuestionnaireId, 'userQuestionnaireId<string> is required');

    return this.apiClient.request(`/user_questionnaires/${userQuestionnaireId}/remove_all_answers`, { method: 'delete' });
  }

  updateUserQuestionnaire(userQuestionnaireId, params) {
    assert.ok(userQuestionnaireId, 'userQuestionnaireId<string> is required');
    assert.ok(params, 'params is required');

    return this.apiClient.request(`/user_questionnaires/${userQuestionnaireId}`, {
      method: 'patch',
      data: { user_questionnaire: params },
    });
  }

  create(templateId) {
    assert.ok(templateId, 'templateId<string> is required');

    return this.apiClient.request(`/user_questionnaires/new?questionnaire_template_id=${templateId}`, { method: 'get' });
  }

  fetch(questionnaireId) {
    assert.ok(questionnaireId, 'questionnaireId<string> is required');

    return this.apiClient.request(`/user_questionnaires/${questionnaireId}`, { method: 'get' });
  }

  update(questionnaireId, params) {
    assert.ok(questionnaireId, 'questionnaireId<string> is required');

    return this.apiClient.request(`/user_questionnaires/${questionnaireId}`, {
      method: 'patch',
      data: { user_questionnaire: params },
    });
  }

  previewDocument(questionnaireId, format) {
    assert.ok(questionnaireId, 'questionnaireId<string> is required');
    assert.ok(format, 'format<string> is required');

    return this.apiClient.get(`/user_questionnaires/${questionnaireId}/documents/preview_document.${format}`);
  }

  updateStatus(questionnaireId, status) {
    assert.ok(questionnaireId, 'questionnaireId<string> is required');
    assert.ok(status, 'status<string> is required');

    return this.apiClient.request(`/user_questionnaires/${questionnaireId}/statuses`, {
      method: 'patch',
      data: { user_questionnaire: { status } },
    });
  }

  getDownloadAttachmentToken(questionnaireId) {
    assert.ok(questionnaireId, 'questionnaireId<string> is required');

    return this.apiClient.request(`/user_questionnaires/${questionnaireId}/documents`, { method: 'post' });
  }

  getDownloadAttachment(questionnaireId, format, accessToken) {
    assert.ok(questionnaireId, 'questionnaireId<string> is required');
    assert.ok(accessToken, 'accessToken<string> is required');
    assert.ok(format, 'format<string> is required');

    return this.apiClient.request(
      `/user_questionnaires/${questionnaireId}/documents.${format}?token=${accessToken}`, { method: 'get', responseType: 'blob' },
    );
  }
}
