import { all } from 'redux-saga/effects';

import createTrashSagas from 'pages/Trash/sagas';

import createQuestionnaireSagas from 'ducks/questionnaire/sagas';
import createExportFileSagas from 'ducks/exportFile/sagas';

import api from 'shared/utils/api';
import notificationService from 'react-notification-system-redux';
import createLoggedInUserSagas from 'ducks/loggedInUser/sagas';
import createUploadSagas from 'ducks/upload/sagas';

import createSsoSagas from 'Sso/sagas';
import createSsoService from 'Sso/api';

import createAppSagas from 'containers/App/sagas';
import { createErrorsSagas } from 'ducks/error/sagas';
import createDocumentStylesSagas from 'pages/DocumentStyles/sagas';
import createErrorTemplateNotActiveSagas from 'containers/ErrorTemplateNotActive/sagas';
import createUserListSagas from 'pages/UserList/sagas';
import { runSagas } from 'shared/helpers/sagas';

import progressBar from 'Questionnaire2/ProgressBar/sagas';
import createFlowSagas from 'containers/Flow/sagas';
import createUserEditSagas from 'pages/UserEdit/sagas';
import documentsSagas from 'pages/Documents/sagas';
import createTemplatesSagas from 'pages/Templates/sagas';
import createInvitationsSaga from 'pages/UserInvitation/sagas';
import createDocumentDetailsSaga from 'pages/DocumentDetails/sagas';
import createQuestionSagas from '../Visualization/Editor-v2/Questions/QuestionsSagas';
import editorSagas from '../Visualization/Editor-v2/sagas';
import logisManagerSagas from '../Visualization/Editor-v2/LogicsManager/sagas';
import openLogisManagerSagas from '../Visualization/Editor-v2/LogicsManager/openLogicsManager.sagas';

export default function* root() {
  yield all(editorSagas);
  yield all(logisManagerSagas);
  yield all(openLogisManagerSagas);
  yield all(progressBar);
}

const ssoService = createSsoService({ authorizedApi: api });

export function createSagas(store, {
  trackingService,
  amplitudeService,
  googleAnalyticsService,
  mixpanelService,
  authenticationService,
  userService,
  credentialsService,
  documentService,
  answerService,
  documentStylesService,
  questionService,
  templatesService,
  foldersService,
  documentFoldersService,
  questionnaireService,
  signDocumentService,
  companyService,
  uploadService,
  trashService,
  externalHttpClient,
  genericAccessService,
  storageConfig,
  invitationsService,
  documentVersionsService,
  documentExportService,
  newDocumentService,
}) {
  const sagaArr = [
    createAppSagas({ trackingService, credentialsService, amplitudeService, mixpanelService }),
    createLoggedInUserSagas({ authenticationService, userService, notificationService, credentialsService }),
    createSsoSagas({ ssoService, notificationService }),
    documentsSagas({ documentService, newDocumentService, notificationService, documentFoldersService }),
    createErrorsSagas({ googleAnalyticsService }),
    createDocumentStylesSagas({ documentStylesService }),
    createUserListSagas({ userService, notificationService }),
    createTemplatesSagas({ templatesService, foldersService, documentService, notificationService }),
    createQuestionSagas({ questionService }),
    createQuestionnaireSagas({
      answerService,
      companyService,
      credentialsService,
      documentService,
      notificationService,
      questionnaireService,
      signDocumentService,
      trackingService,
    }),
    createExportFileSagas({
      questionnaireService,
      documentExportService,
      trackingService,
    }),
    createErrorTemplateNotActiveSagas({ genericAccessService, notificationService }),
    createFlowSagas(),
    createUserEditSagas({ userService, notificationService }),
    createUploadSagas({ uploadService, externalHttpClient, storageConfig }),
    createTrashSagas({ trashService, notificationService }),
    createInvitationsSaga({ invitationsService, notificationService }),
    createDocumentDetailsSaga({ documentVersionsService, signDocumentService, notificationService }),
  ];

  runSagas(store, sagaArr);
}
