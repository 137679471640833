import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { createClassNameModifiers } from 'utils/componentUtils';
import Spinner from '../Spinner';

import styles from './index.css';

const INFO = 'info';
const DANGER = 'danger';

const PRIMARY = 'primary';
const SECONDARY = 'secondary';
const GHOST = 'ghost';
const LINK = 'link';
const TEXT = 'text';

const LARGE = 'large';
const SMALL = 'small';
const TINY = 'tiny';

class Button extends React.PureComponent {
  static propTypes = {
    buttonRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    testId: PropTypes.string.isRequired,
    glyph: PropTypes.node,
    variant: PropTypes.oneOf([PRIMARY, SECONDARY, GHOST, LINK, TEXT]),
    size: PropTypes.oneOf([LARGE, SMALL, TINY]),
    color: PropTypes.oneOf([INFO, DANGER]),
    fullWidth: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    loading: false,
    glyph: null,
    variant: PRIMARY,
    size: LARGE,
    color: INFO,
    fullWidth: false,
  };

  onClick = (event) => {
    const { loading, onClick } = this.props;
    if (!loading) {
      onClick(event);
    }
  };

  render() {
    const { children, className, disabled, loading, variant, size, color, fullWidth, glyph: Glyph, buttonRef, testId, onClick, ...otherProps } = this.props;
    const formattedModifiers = createClassNameModifiers({
      modifiers: [].concat(
        disabled ? ['disabled'] : [],
        loading ? ['loading'] : [],
        fullWidth ? ['fullWidth'] : [],
        Glyph ? ['hasGlyph'] : [],
        [variant], [size], [color],
      ),
      styles,
    });
    const classString = cn(className, ...formattedModifiers);

    return (
      <button
        type="button"
        {...otherProps}
        onClick={onClick ? this.onClick : undefined}
        className={cn(classString, styles.component)}
        data-test-id={testId}
        disabled={disabled}
        ref={buttonRef}>
        {loading
          ? <Spinner />
          : (
            <>
              {Glyph && <Glyph className={styles.glyph} />}
              <div className={styles.label}>{children}</div>
            </>
          )
        }
      </button>
    );
  }
}

export {
  INFO,
  DANGER,
  PRIMARY,
  SECONDARY,
  GHOST,
  LARGE,
  SMALL,
  TINY,
  LINK,
  TEXT,
};

export default Button;
