import React from 'react';
import cn from 'classnames';
import styles from './index.css';

export const BackgroundShadowsPage = props => <div className={styles.pageWithShadows}>{props.children}</div>;

const Page = props => (
  <div className={cn(styles.page, { [styles.pageFullWidth]: props.fullWidth })}>
    {props.children}
  </div>
);

export default Page;
