import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { templatePropertiesSelector } from 'ducks/questionnaire/selectors';

import GridItem from 'components/Grid/Item';
import DownloadPdf, { SECONDARY } from '../modules/DownloadPdf';
import Editing from '../modules/Editing';
import styles from './index.css';


const Completed = ({ esigningModule, signingEnabled, isMobile, disabled }) => {
  const downloadButtonVariant = (esigningModule && signingEnabled) ? SECONDARY : '';

  return (
    <Fragment>
      <GridItem xs="auto" sm="auto">
        <Editing isMobile={isMobile} />
      </GridItem>
      <GridItem xs={false} sm="auto">
        <div className={styles.seperator} />
      </GridItem>
      <GridItem xs="auto" sm="auto">
        <DownloadPdf variant={downloadButtonVariant} isMobile={isMobile} disabled={disabled} />
      </GridItem>
    </Fragment>
  );
};

Completed.propTypes = {
  esigningModule: PropTypes.bool.isRequired,
  signingEnabled: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  disabled: PropTypes.bool,
};

const mapStateToProps = state => ({
  esigningModule: _get(state, 'questionnaire.company.esigning_module'),
  signingEnabled: templatePropertiesSelector(state).signing_enabled,
});

export default connect(mapStateToProps)(Completed);
