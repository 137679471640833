import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { SEQUENTIAL, PARALLEL } from 'shared/constants/signingOrder';
import Modal from 'components/Modal';
import Button, { SMALL, LARGE } from 'components/Button';
import { Send } from 'icons/Send';
import AddIcon from '@material-ui/icons/Add';
import { templatePropertiesSelector } from 'ducks/questionnaire/selectors';
import { selectUserPersonalInformation } from 'ducks/loggedInUser/selectors';
import { selectDocumentStatus, selectCurrentDocumentVersion } from 'pages/DocumentDetails/selectors';
import { Creators } from 'ducks/questionnaire/actions';

import EsignForm from './EsignForm';

import styles from './styles.css';

export class SignDocument extends Component {
  static propTypes = {
    signDocument: PropTypes.func.isRequired,
    esigningModule: PropTypes.bool.isRequired,
    userQuestionnaireId: PropTypes.string.isRequired,
    userQuestionnaireName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    userEmail: PropTypes.string.isRequired,
    signingEnabled: PropTypes.bool.isRequired,
    displayButton: PropTypes.bool,
    questionnaireSigningMethods: PropTypes.arrayOf(PropTypes.string),
    templateQtvProperty: PropTypes.shape({
      signing_order: PropTypes.oneOf([SEQUENTIAL, PARALLEL]),
      signing_methods: PropTypes.arrayOf(PropTypes.string),
      sender_has_to_sign: PropTypes.bool,
    }),
    disabled: PropTypes.bool,
    logToggleModalEvent: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
  };

  static defaultProps = { disabled: false };

  state = { isOpen: false, isSubmitted: false };

  isDisabled = () => this.props.disabled;

  toggleModal = () => {
    // Amplitude event log using analytics middleware
    if (!this.state.isOpen) { // When opening...
      this.props.logToggleModalEvent(this.props.userQuestionnaireId);
    }
    this.setState({ isSubmitted: false });
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  sendToSign = (values) => {
    // check if it's user questionnaire or dv
    const { signDocument } = this.props;

    signDocument(values);
    this.setState({ isSubmitted: true });
  };

  getModalTitle = () => (this.state.isSubmitted ? 'questionnaire.esign.form.summary_title' : 'questionnaire.esign.send_signature');

  render() {
    const {
      esigningModule,
      userQuestionnaireName,
      userName,
      userEmail,
      signingEnabled,
      questionnaireSigningMethods,
      templateQtvProperty,
      displayButton,
      disabled,
      isMobile,
    } = this.props;
    const { isOpen } = this.state;

    if (!esigningModule) return null;
    if (!signingEnabled) return null;

    return (
      <React.Fragment>
        {displayButton && (
        <Button
          onClick={this.toggleModal}
          disabled={disabled}
          glyph={isMobile ? AddIcon : Send}
          size={isMobile ? SMALL : LARGE}
          testId="SignDocument-send">
          <FormattedMessage id={`questionnaire.esign.send${isMobile ? '_mobile' : ''}`} />
        </Button>
        )}
        <Modal className={styles.esignModal} toggleModal={this.toggleModal} size="big" open={isOpen} modalTitle={<FormattedMessage id={this.getModalTitle()} />}>
          <EsignForm
            userName={userName}
            userEmail={userEmail}
            toggleModal={this.toggleModal}
            onSubmit={this.sendToSign}
            userQuestionnaireName={userQuestionnaireName}
            questionnaireSigningMethods={questionnaireSigningMethods}
            signingOrder={templateQtvProperty.signing_order}
            senderHasToSign={templateQtvProperty.sender_has_to_sign} />
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  signDocument: values => dispatch(Creators.signDocumentRequest(values)),
  logToggleModalEvent: userQuestionnaireId => dispatch({
    type: '',
    meta: {
      analytics: {
        type: 'OpenSigning',
        payload: { context: 'Questionnaire', documentId: userQuestionnaireId },
      },
    },
  }),
});

const mapStateToProps = state => ({
  questionnaireStatus: selectDocumentStatus(state),
  esigningModule: get(state, 'questionnaire.company.esigning_module'),
  userQuestionnaireId: get(state, 'questionnaire.userQuestionnaire.id'),
  userQuestionnaireName: get(state, 'questionnaire.userQuestionnaire.name'),
  signingOrder: get(state, 'questionnaire.userQuestionnaire.questionnaire_template_version.qtv_property.signing_order'),
  questionnaireSigningMethods: get(state, 'questionnaire.userQuestionnaire.questionnaire_template_version.qtv_property.signing_methods'),
  templateQtvProperty: get(state, 'questionnaire.userQuestionnaire.questionnaire_template_version.qtv_property'),
  userEmail: get(selectUserPersonalInformation(state), 'email'),
  userName: get(selectUserPersonalInformation(state), 'fullName'),
  signingEnabled: templatePropertiesSelector(state).signing_enabled,
  currentDocumentVersion: selectCurrentDocumentVersion(state),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SignDocument);
