exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-FolderList-___styles__folderItemAction___1RB4n {\n  margin-top: 0;\n}\n\n.app-components-FolderList-___styles__folderItemSubheader___Wabwg {\n  font-weight: 400;\n}\n\n.app-components-FolderList-___styles__folderItemContent___3gsqz {\n  overflow: hidden;\n}\n\n.app-components-FolderList-___styles__folderItemTitle___2nEwB {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.app-components-FolderList-___styles__folderItemLabel___31cro {\n  font-size: 2.13333rem;\n}\n\n.app-components-FolderList-___styles__cardRoot___13wbw {\n  cursor: pointer;\n  transition: all 0.2s;\n}\n\n.app-components-FolderList-___styles__cardRoot___13wbw:hover {\n  box-shadow: 3px 3px 27px 0 rgba(0, 0, 0, 0.15);\n}\n\n.app-components-FolderList-___styles__folderIcon___20skj {\n  color: #2d866e;\n  color: var(--c-brand);\n}\n", "", {"version":3,"sources":["/workspace/app/components/FolderList/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAa;CACf;;AAEA;EACE,iBAAgB;CAClB;;AAEA;EACE,iBAAgB;CAClB;;AAEA;EACE,iBAAgB;EAChB,wBAAuB;EACvB,oBAAmB;CACrB;;AAEA;EACE,sBAAe;CACjB;;AAEA;EACE,gBAAe;EACf,qBAAoB;CACtB;;AAEA;EACE,+CAA8C;CAChD;;AAEA;EACE,eAAqB;EAArB,sBAAqB;CACvB","file":"styles.css","sourcesContent":[".folderItemAction {\n  margin-top: 0;\n}\n\n.folderItemSubheader {\n  font-weight: 400;\n}\n\n.folderItemContent {\n  overflow: hidden;\n}\n\n.folderItemTitle {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.folderItemLabel {\n  font-size: 32px;\n}\n\n.cardRoot {\n  cursor: pointer;\n  transition: all 0.2s;\n}\n\n.cardRoot:hover {\n  box-shadow: 3px 3px 27px 0 rgba(0, 0, 0, 0.15);\n}\n\n.folderIcon {\n  color: var(--c-brand);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"folderItemAction": "app-components-FolderList-___styles__folderItemAction___1RB4n",
	"folderItemSubheader": "app-components-FolderList-___styles__folderItemSubheader___Wabwg",
	"folderItemContent": "app-components-FolderList-___styles__folderItemContent___3gsqz",
	"folderItemTitle": "app-components-FolderList-___styles__folderItemTitle___2nEwB",
	"folderItemLabel": "app-components-FolderList-___styles__folderItemLabel___31cro",
	"cardRoot": "app-components-FolderList-___styles__cardRoot___13wbw",
	"folderIcon": "app-components-FolderList-___styles__folderIcon___20skj"
};