import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import GridItem from 'components/Grid/Item';
import Button, { PRIMARY, SECONDARY } from 'components/Button';
import DownloadPdf from '../modules/DownloadPdf';
import styles from './index.css';

const PreviousVersion = ({ isMobile, goToLatestVersion }) => (
  <Fragment>
    <GridItem xs={false} sm="auto">
      <div className={styles.seperator} />
    </GridItem>
    <GridItem xs="auto" sm="auto">
      <DownloadPdf variant={SECONDARY} isMobile={isMobile} />
    </GridItem>
    <GridItem xs="auto" sm="auto">
      <Button variant={PRIMARY} onClick={goToLatestVersion}>
        <FormattedMessage id="document_details.versions.see_latest_version" />
      </Button>
    </GridItem>
  </Fragment>
);

PreviousVersion.propTypes = {
  isMobile: PropTypes.bool,
  goToLatestVersion: PropTypes.func.isRequired,
};

export default PreviousVersion;
