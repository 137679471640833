import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import Carousel from 'shared/Components/Carousel';
import * as v from 'shared/validation';
import { SEQUENTIAL, PARALLEL } from 'shared/constants/signingOrder';
import StepProgressBar from 'components/StepProgressBar';
import { ModalFooter } from 'components/Modal';
import Button, { SECONDARY } from 'components/Button';
import Typography from 'components/Typography';
import { Rocket } from 'icons/Rocket';
import { EsignFormInvitationStep } from './EsignFormInvitationStep';
import { EsignFormSigneesStep } from './EsignFormSigneesStep';
import EsignFormSummary from './EsignFormSummary';
import EsignFormConfirmationStep from './EsignFormConfirmationStep';
import styles from './styles.css';

const INVITATION_STEP_INDEX = 0;
const SIGNEES_STEP_INDEX = 1;
const CONFIRMATION_STEP_INDEX = 2;
const SUMMARY_INDEX = 3;

export class EsignFormComponent extends Component {
  state = {
    index: 0,
    emailSenderIndex: 0,
  };

  componentDidMount() {
    this.props.validateForm(this.props.values, this.props);
  }

  setPage = (index) => {
    this.setState({ index });
  };

  goToNextStep = () => {
    this.setState(prevState => ({ index: prevState.index + 1 }));
  };

  goToPreviousStep = () => {
    this.setState(prevState => ({ index: prevState.index - 1 }));
  };

  setEmailSenderIndex = (emailSenderIndex) => {
    this.setState({ emailSenderIndex });
  };

  submitInvitationStep = () => {
    const { from, userQuestionnaireName } = this.props.errors;
    this.props.setTouched({
      from: true,
      userQuestionnaireName: true,
    });
    if (!(from || userQuestionnaireName)) {
      this.goToNextStep();
    }
  };

  goToSigneesStep = () => {
    const { emails } = this.props.errors;
    this.props.setTouched({ emails: true });
    if (!emails) {
      this.goToNextStep();
    }
  };

  onSubmit = (e) => {
    this.goToNextStep();
    this.props.handleSubmit(e);
  };

  render() {
    const { index, emailSenderIndex } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      <form noValidate className={styles.esignForm}>
        {index !== SUMMARY_INDEX && (
          <StepProgressBar
            className={styles.progressBarContainer}
            steps={[
              { title: formatMessage({ id: 'questionnaire.esign.form.invitation' }), testId: 'EsignForm-invitationStep' },
              { title: formatMessage({ id: 'questionnaire.esign.form.signees' }), testId: 'EsignForm-signeesStep' },
              { title: formatMessage({ id: 'questionnaire.esign.form.confirm' }), testId: 'EsignForm-confirmStep' },
            ]}
            active={index}
            goToStep={this.setPage} />
        )}
        <Carousel index={index} className={styles.carousel}>
          {index === INVITATION_STEP_INDEX ? (
            <EsignFormInvitationStep
              {...this.props}
              onNextClick={this.goToNextStep}
              toggleModal={this.props.toggleModal}
              emailSenderIndex={emailSenderIndex} />
          ) : <Fragment />}
          {index === SIGNEES_STEP_INDEX ? (
            <EsignFormSigneesStep
              {...this.props}
              emailSenderIndex={emailSenderIndex}
              onNextClick={this.goToNextStep}
              onBackClick={this.goToPreviousStep}
              toggleModal={this.props.toggleModal}
              setEmailSenderIndex={this.setEmailSenderIndex} />
          ) : <Fragment />}
          {index === CONFIRMATION_STEP_INDEX ? (
            <EsignFormConfirmationStep
              signingMethod={this.props.values.signingMethod}
              onBackClick={this.goToPreviousStep}
              emails={this.props.values.emails.tags}
              submit={this.onSubmit} />
          ) : <Fragment />}
          {index === SUMMARY_INDEX ? (
            <EsignFormSummary
              emails={this.props.values.emails.tags}
              signingOrder={this.props.signingOrder}
              toggleModal={this.props.toggleModal} />
          ) : <Fragment />}
        </Carousel>
        {index === INVITATION_STEP_INDEX && (
          <ModalFooter>
            <Button variant={SECONDARY} testId="EsignFormInvitationStep-close" onClick={this.props.toggleModal}>
              <FormattedMessage id="questionnaire.esign.form.close" />
            </Button>
            <Button
              testId="EsignFormInvitationStep-continue"
              onClick={this.submitInvitationStep}>
              <FormattedMessage id="questionnaire.esign.form.continue" />
            </Button>
          </ModalFooter>
        )}
        {index === SIGNEES_STEP_INDEX && (
          <ModalFooter>
            <Button variant={SECONDARY} testId="EsignFormSigneesStep-back" onClick={this.goToPreviousStep}>
              <FormattedMessage id="questionnaire.esign.form.back" />
            </Button>
            <Button
              testId="EsignFormSigneesStep-continue"
              onClick={this.goToSigneesStep}>
              <FormattedMessage id="questionnaire.esign.form.continue" />
            </Button>
          </ModalFooter>
        )}
        {index === CONFIRMATION_STEP_INDEX && (
          <ModalFooter>
            <Button variant={SECONDARY} testId="EsignFormConfirmation-back" onClick={this.goToPreviousStep}>
              <FormattedMessage id="questionnaire.esign.form.back" />
            </Button>
            <Button
              testId="EsignFormConfirmation-submit"
              type="submit"
              glyph={Rocket}
              onClick={this.onSubmit}>
              <FormattedMessage id="questionnaire.esign.form.submit_label" />
            </Button>
          </ModalFooter>
        )}
        {index === SUMMARY_INDEX && (
        <ModalFooter>
          <Button
            testId="EsignFormSummary-close"
            onClick={this.props.toggleModal}>
            <Typography variant="buttonText">
              <FormattedMessage id="questionnaire.esign.form.close" />
            </Typography>
          </Button>
        </ModalFooter>
        )}
      </form>
    );
  }
}

export const formikConfig = {
  mapPropsToValues: props => ({
    userQuestionnaireName: props.userQuestionnaireName,
    emails: { tags: props.senderHasToSign ? [props.userEmail] : [], inputValue: '' },
    from: props.userEmail,
    message: '',
    signingMethod: props.questionnaireSigningMethods[0],
  }),
  isInitialValid: (props) => {
    const errors = props.validate(props.initialValues);
    return isEmpty(errors);
  },
  validate: (values, props) => {
    const errors = {};
    const { emails, userQuestionnaireName, from } = values;
    if (emails.tags.length - props.senderHasToSign < 1) {
      errors.emails = props.intl.formatMessage({ id: 'questionnaire.esign.errors.no_signees' });
    }
    if (v.required(userQuestionnaireName)) {
      errors.userQuestionnaireName = props.intl.formatMessage({ id: 'questionnaire.esign.errors.no_document_title' });
    }
    if (emails.tags.filter(email => email.toLowerCase() === from.toLowerCase()).length > 1) {
      errors.from = props.intl.formatMessage({ id: 'shared_components.tags_area.errors.email_exist' });
    }
    if (v.email(from) || v.required(from)) {
      errors.from = props.intl.formatMessage({ id: 'questionnaire.esign.errors.invalid_email' });
    }
    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true);
    const customMessage = values.message
      ? values.message
      : props.intl.formatMessage({ id: 'questionnaire.esign.form.placeholder.default_custom_message' }, { email: values.from });
    props.onSubmit({ ...values, signingOrder: props.signingOrder, message: customMessage }, setSubmitting);
  },
};

EsignFormComponent.propTypes = {
  values: PropTypes.shape({
    userQuestionnaireName: PropTypes.string,
    emails: PropTypes.object,
    message: PropTypes.string,
    from: PropTypes.string,
  }),
  signingOrder: PropTypes.oneOf([SEQUENTIAL, PARALLEL]),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  senderHasToSign: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired, //eslint-disable-line
  userEmail: PropTypes.string.isRequired, //eslint-disable-line
  intl: intlShape.isRequired,
};

export default compose(
  injectIntl,
  withFormik(formikConfig),
)(EsignFormComponent);
