import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { load } from 'cheerio';
import { imgSrcToBlob } from 'blob-util';
import { Creators as UploadActions } from 'ducks/upload/actions';
import { TEMPLATE_EDITOR_ID } from 'appRoot/constants';
import { getEnv } from 'utils/env';

export default (WrappedComponent) => {
  class ReplaceImageHoc extends PureComponent {
    componentDidUpdate(prevProps) {
      if (this.props.uploadedFiles !== prevProps.uploadedFiles) {
        this.replaceImages();
      }
    }

    replaceImages = () => {
      const { uploadedFiles } = this.props;
      const templateEditor = tinymce.get(TEMPLATE_EDITOR_ID); // eslint-disable-line
      Object.keys(uploadedFiles).forEach((oldFileName) => {
        const imgEl = templateEditor.dom.select(`img[src="${oldFileName}"]`);
        const newSrc = `https://${getEnv('S3_EDITOR_ATTACHMENTS_BUCKET')}.s3-${getEnv('S3_BUCKET_REGION')}.amazonaws.com/${uploadedFiles[oldFileName]}`;
        templateEditor.dom.setAttribs(imgEl, { src: newSrc });
      });
      templateEditor.fire('change');
    }

    uploadImages = ({ content }) => {
      const $$ = load(content);
      const images = $$('img');
      images.each((index, img) => {
        const imgSrc = $$(img).attr('src');
        imgSrcToBlob(imgSrc, undefined, 'Anonymous')
          .then((blob) => {
            this.props.uploadImage(imgSrc, blob);
          })
          .catch(console.error);
      });
    }

    render() {
      return <WrappedComponent {...this.props} uploadImages={this.uploadImages} />;
    }
  }

  const mapStateToProps = state => ({ uploadedFiles: state.upload.uploadedFiles });
  const mapDispatchToProps = dispatch => ({ uploadImage: (imageName, file) => dispatch(UploadActions.uploadRequest(imageName, file)) });

  return connect(mapStateToProps, mapDispatchToProps)(ReplaceImageHoc);
};
