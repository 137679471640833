import keycode from 'keycode';
import checkIsInlineQuestion from 'Visualization/helpers/checkIsInlineQuestion';

export default (editor) => {
  editor.on('keydown', (e) => {
    const isInlineQuestion = checkIsInlineQuestion(editor.selection.getNode());
    if (e.keyCode === keycode('enter') && isInlineQuestion) {
      e.preventDefault();
      return false;
    }

    return true;
  });
};
