import { connect } from 'react-redux';
import { Creators as TemplateActionCreators } from 'pages/Templates/reducer';
import { Creators } from '../actions';
import SelectTemplateForDocument from '../components/SelectTemplateForDocument';

const mapStateToProps = state => ({
  folders: state.templates.folders,
  total: state.templates.templates.total,
});

export const mapDispatchToProps = dispatch => ({
  selectTemplateForDocumentRequest: (id, folderId) => dispatch(Creators.selectTemplateForDocumentRequest(id, folderId)),
  fetchTemplatesInfoRequest: params => dispatch(TemplateActionCreators.fetchTemplatesInfoRequest(params, true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectTemplateForDocument);
