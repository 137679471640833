import rangy from 'rangy';
import { Parser } from 'html-to-react';
import generateUUID from 'uuid/v4';
import { QUESTION_TYPES } from 'Visualization/helpers/constants';
import removeEmptyQuestionNodes from 'Visualization/helpers/removeEmptyQuestionNodes';
import renderQuestion from 'Visualization/Editor-v2/utils/renderQuestion';
import NodesHelper from './nodesHelper';

export default (appliedOptionsIds, logicType, questionId) => {
  const tagType = QUESTION_TYPES[logicType];
  const range = rangy.getSelection().getRangeAt(0);
  const nodesHelper = new NodesHelper();
  const questionTag = document.createElement(`${nodesHelper.multipleBlocksSelected ? 'div' : 'span'}`);
  const uniqueId = generateUUID();
  removeEmptyQuestionNodes();
  questionTag.setAttribute('data-question-container', uniqueId);
  questionTag.setAttribute('class', '__react_container');
  questionTag.appendChild(range.extractContents());
  range.insertNode(questionTag);
  $('.temp-whitespace-for-selection-extend').remove();
  const surroundContents = range.getDocument().querySelector(`[data-question-container='${uniqueId}']`);
  const htmlParser = new Parser();
  // we need a node near selection and count its parent questions
  // we could use right/left node - it does not matter, we just need a node near it.
  const parentCount = $(rangy.getSelection().anchorNode).parents('[data-question]').length;
  const props = {
    [tagType]: appliedOptionsIds,
    logicType,
    questionId,
    isBlock: nodesHelper.multipleBlocksSelected,
    children: htmlParser.parse(surroundContents.innerHTML),
    parentCount,
  };
  const questionType = nodesHelper.multipleBlocksSelected ? 'block' : 'inline';
  renderQuestion(props, questionType, tagType, surroundContents);
};
