import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './FormHeader.css';

const FormHeader = ({ children }) => (
  <h4 className={styles.component}>
    {children}
  </h4>
);

FormHeader.propTypes = { children: PropTypes.node };

export default FormHeader;
