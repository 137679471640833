import React from 'react';

export const InformationIcon = props => (
  <svg
    version="1.2"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width={32}
    height={32}
    {...props}>
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
      style={{ fill: 'rgb(135, 135, 135)' }}
      vectorEffect="non-scaling-stroke" />
  </svg>
);
