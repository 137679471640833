import flow from 'lodash/flow';
import removeQuestionTagsFromHtml from 'Visualization/helpers/removeQuestionTagsFromHtml';
// import removeWrappingDivs from 'Visualization/helpers/removeWrappingDivs';
import changeTags from 'Visualization/helpers/changeTags';
import changeBrToParagraphs from 'Visualization/helpers/changeBrToParagraphs';
import checkIfSelectionIsInsideInputReplaceLogic from 'Visualization/Editor-v2/LogicsManager/LogicsTypes/TriggersFn/utils/checkIfSelectionIsInsideQuestion';

const parsePastedContent = flow(
  enchancedRemoveQuestionTagsFromHtml,
  changeTags,
  // removeWrappingDivs,
  changeBrToParagraphs,
);

function enchancedRemoveQuestionTagsFromHtml(html) {
  return removeQuestionTagsFromHtml(html, checkIfSelectionIsInsideInputReplaceLogic());
}

export default parsePastedContent;
