const checkIsInlineQuestion = (node) => {
  if (
    !node
    || (node.classList && node.classList.contains('mce-content-body'))
  ) {
    return false;
  }

  if (node.getAttribute('data-question') === 'inline') {
    return true;
  }
  return checkIsInlineQuestion(node.parentNode);
};

export default checkIsInlineQuestion;
