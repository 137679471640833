import styled from 'styled-components';
import Card from '@material-ui/core/Card';

export const HeaderWrapper = styled.article`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10%;
  margin-top: 25px;
`;

export const HeaderText = styled.div`
  display: flex;
`;

export const StyledHeaderCard = styled(Card)`
  && {
    height: 51px;
    width: 50px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    color: ${({ theme }) => theme.mainColor};
  }
`;
