import assert from 'assert';

import { put, call, take } from 'redux-saga/effects';
import { Creators, Types } from '../actions';

export default function createSagas({ documentService }) {
  assert.ok(documentService, 'documentService is required');

  function* duplicateDocumentSaga() {
    while (true) {
      const { documentId, documentName } = yield take(Types.DUPLICATE_DOCUMENT_REQUEST);
      try {
        yield call(
          [documentService, documentService.duplicateDocument],
          documentId,
          documentName,
        );
        yield put(Creators.duplicateDocumentSuccess());
      } catch (e) {
        yield put(Creators.duplicateDocumentFailure(e));
      }
    }
  }

  return { duplicateDocumentSaga };
}
