import React from 'react';

export function Template(props) {
  return (
    <svg
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 15.718999862670898 21.75"
      height="22"
      width="15"
      y="0px"
      x="0px"
      {...props}>
      <g transform="translate(1, 1)">
        <g opacity="0.12">
          <polyline
            points="6.506,0 0,0 0,17.152 13.012,17.152 13.012,5.718"
            fill="#F58570"
            vectorEffect="non-scaling-stroke" />
        </g>
        <g opacity="0.4">
          <polyline
            points="6.506,0 6.506,5.718 13.012,5.718"
            fill="#F05436"
            vectorEffect="non-scaling-stroke" />
        </g>
      </g>
    </svg>
  );
}
