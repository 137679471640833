import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import proptypes from 'utils/proptypes';
import { Creators as LogicsManagerActions } from 'Visualization/Editor-v2/LogicsManager/LogicsManagerRedux';
import { optionsSelector } from '../QuestionsRedux';
import Question from '.';

class QuestionContainer extends PureComponent {
  static propTypes = {
    options: proptypes.optionsScheme,
    questions: proptypes.questionsScheme,
    templateVersion: proptypes.templateVersionScheme,
    openLogicsManager: PropTypes.func.isRequired,
  };

  static defaultProps = { questions: [] };

  editQuestion = (logicType, questionId) => {
    const option = this.props.options.find(el => questionId.includes(el.id));
    const question = this.props.questions.find(q => q.id === option.question_id);
    this.props.openLogicsManager(logicType, {
      initialValidations: false,
      question,
    });
  };

  render() {
    return <Question {...this.props} onIconClick={this.editQuestion} />;
  }
}

const mapStateToProps = state => ({
  options: optionsSelector(state),
  questions: state.questions.questions,
});

const mapDispatchToProps = dispatch => ({ openLogicsManager: (kind, options) => dispatch(LogicsManagerActions.openLogicsManager(kind, options)) });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionContainer);
