import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import CheckMark from 'icons/CheckMark';
import styles from './index.css';

const handleGoToStep = (index, active, goToStep) => () => {
  if (index < active) {
    goToStep(index);
  }
};

const StepProgressBar = ({ steps, active, goToStep, className }) => (
  <div className={cn(styles.component, className)}>
    <ul className={styles.progressBar}>
      {steps.map((step, i) => (
        <li key={step.testId} className={i === active ? styles.active : undefined}>
          <button data-test-id={step.testId} type="button" onClick={handleGoToStep(i, active, goToStep)}>
            <span className={styles.stepTitle}>{step.title}</span>
            <CheckMark />
          </button>
        </li>
      ))}
    </ul>
  </div>
);

StepProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.node,
    testId: PropTypes.string,
  })).isRequired,
  active: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default StepProgressBar;
