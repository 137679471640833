import React from 'react';

export const TrashIcon = props => (
  <svg
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width={21}
    height={21}
    fill="#7783a1"
    {...props}>
    <path
      d="M9.91 17.24a.34.34 0 0 1-.24.09H9c-.18.01-.32-.13-.33-.31V9.67c-.01-.18.12-.33.3-.34H9.67c.18-.01.32.13.33.31V17c0 .09-.03.18-.09.24zm2.67 0a.34.34 0 0 1-.24.09h-.67a.317.317 0 0 1-.34-.3V9.67c-.01-.18.13-.32.31-.33H12.33c.18-.01.32.13.33.31V17c0 .09-.03.18-.09.24h.01zm2.67 0a.34.34 0 0 1-.24.09h-.68c-.18.01-.32-.13-.33-.31V9.67c-.01-.18.13-.32.31-.33H15c.18-.01.32.13.33.31V17c0 .09-.03.18-.09.24h.01zM10.18 5.45c.04-.06.11-.1.18-.11h3.3c.07.01.14.05.18.11l.5 1.22H9.67l.51-1.22zM19.33 7c.01-.18-.13-.32-.31-.33h-3.24l-.73-1.74c-.11-.27-.31-.5-.56-.66-.24-.17-.53-.26-.82-.27h-3.34c-.29.01-.58.1-.82.27-.25.16-.45.39-.56.66l-.73 1.74H5c-.18-.01-.32.13-.33.31V7.67c-.01.18.13.32.31.33H6v9.91c-.01.53.16 1.05.49 1.47.27.38.71.61 1.18.62h8.66c.47-.01.91-.24 1.18-.63.33-.43.5-.96.49-1.5V8h1c.18.01.32-.13.33-.31V7z"
      vectorEffect="non-scaling-stroke" />
  </svg>
);
