exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-Page-___index__page___zpS5u {\n  padding: 30px 7%;\n}\n\n  .app-components-Page-___index__page___zpS5u:empty {\n    padding: 0 0;\n  }\n\n.app-components-Page-___index__pageFullWidth___Uf5bu {\n  padding: 0;\n}\n\n.app-components-Page-___index__pageWithShadows___3s-az {\n  display: flex;\n  flex-flow: column;\n  height: 100vh;\n  background-color: #ecf0f1;\n  background-image: url('/assets/bg-shadows.png');\n  background-position: bottom left;\n  background-repeat: no-repeat;\n  background-size: contain;\n}\n\n.app-components-Page-___index__pageWithShadows___3s-az:empty {\n    display: none;\n  }\n", "", {"version":3,"sources":["/workspace/app/components/Page/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAgB;CAKlB;;EAHE;IACE,aAAY;GACd;;AAGF;EACE,WAAU;CACZ;;AAEA;EACE,cAAa;EACb,kBAAiB;EACjB,cAAa;EACb,0BAAyB;EACzB,gDAA+C;EAC/C,iCAAgC;EAChC,6BAA4B;EAC5B,yBAAwB;CAK1B;;AAHE;IACE,cAAa;GACf","file":"index.css","sourcesContent":[".page {\n  padding: 30px 7%;\n\n  &:empty {\n    padding: 0 0;\n  }\n}\n\n.pageFullWidth {\n  padding: 0;\n}\n\n.pageWithShadows {\n  display: flex;\n  flex-flow: column;\n  height: 100vh;\n  background-color: #ecf0f1;\n  background-image: url('/assets/bg-shadows.png');\n  background-position: bottom left;\n  background-repeat: no-repeat;\n  background-size: contain;\n\n  &:empty {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"page": "app-components-Page-___index__page___zpS5u",
	"pageFullWidth": "app-components-Page-___index__pageFullWidth___Uf5bu",
	"pageWithShadows": "app-components-Page-___index__pageWithShadows___3s-az"
};