import React, { PureComponent } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SelectField from 'shared/Components/SelectField';
import fontFaces from 'shared/constants/styles/fontFaces';
import fontSizes from 'shared/constants/styles/fontSizes';
import Switch from 'components/form/Switch';
import Typography, { VARIANTS } from 'components/Typography';
import FormHeader from './FormHeader';
import HeadlineStyles from './HeadlineStyles';
import StyledField from './StyledField';
import styles from './Styles.css';

class Styles extends PureComponent {
  static propTypes = { active: PropTypes.bool };

  render() {
    const { active } = this.props;
    return (
      <div className={styles.component} active={active}>
        <FormHeader><FormattedMessage id="document_templates.paragraphs" /></FormHeader>
        <div className="paragraphs">
          <StyledField label={<FormattedMessage id="document_templates.font" />} name="font_family">
            <Field
              component={SelectField}
              name="font_family"
              dataSource={fontFaces} />
          </StyledField>
          <StyledField label={<FormattedMessage id="document_templates.font_size" />} name="default_size">
            <Field
              component={SelectField}
              name="default_size"
              dataSource={fontSizes} />
          </StyledField>
        </div>
        <hr />
        <FormHeader><FormattedMessage id="document_templates.headlines" /></FormHeader>
        <StyledField label={<FormattedMessage id="document_templates.font" />} name="headlines_font_family">
          <Field
            component={SelectField}
            name="headlines_font_family"
            dataSource={fontFaces} />
        </StyledField>
        <StyledField label={<FormattedMessage id="document_templates.title" />} name="title">
          <Field
            component={SelectField}
            name="title_size"
            dataSource={fontSizes} />
          <HeadlineStyles headerType="title" />
        </StyledField>
        <StyledField label={<FormattedMessage id="document_templates.h1" />} name="h1_size">
          <Field
            component={SelectField}
            name="h1_size"
            dataSource={fontSizes} />
          <HeadlineStyles headerType="h1" />
        </StyledField>
        <StyledField label={<FormattedMessage id="document_templates.h2" />} name="h2_size">
          <Field
            component={SelectField}
            name="h2_size"
            dataSource={fontSizes} />
          <HeadlineStyles headerType="h2" />
        </StyledField>
        <StyledField label={<FormattedMessage id="document_templates.h3" />} name="h3_size">
          <Field
            component={SelectField}
            name="h3_size"
            dataSource={fontSizes} />
          <HeadlineStyles headerType="h3" />
        </StyledField>
        <StyledField label={<FormattedMessage id="document_templates.h4" />} name="h4_size">
          <Field
            component={SelectField}
            name="h4_size"
            dataSource={fontSizes} />
          <HeadlineStyles headerType="h4" />
        </StyledField>
        <StyledField label={<FormattedMessage id="document_templates.numbering.label" />} name="section_numbering_enabled">
          <Field name="section_numbering_enabled">
            {({ field }) => (
              <>
                <Switch
                  checked={field.value}
                  testId="DocumentStyles-enableNumberingSwitch"
                  {...field} />
                <Typography variant={VARIANTS.BODY_SMALL}>
                  <FormattedMessage id={`document_templates.numbering.${field.value ? 'switch_on' : 'switch_off'}`} />
                </Typography>
              </>
            )}
          </Field>
        </StyledField>
        <div className={styles.helpTextWrapper}>
          <Typography variant={VARIANTS.HELP_TEXT}>
            <FormattedMessage id="document_templates.numbering.description" />
          </Typography>
        </div>
      </div>
    );
  }
}

export default Styles;
