import assert from 'assert';

import ApiClient from '../ApiClient';

export default class GenericAccessService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  resendLink(id) {
    assert.ok(id, 'folderId<string> is required');

    return this.apiClient.request(`/generic_access/resend_document/${id}`, { method: 'patch' });
  }
}
