import { LOGIN_PATH, NOT_FOUND_PATH } from 'appRoot/constants';
import { HUBSPOT_PORTAL_ID } from 'appRoot/constants/services';
import NavigationBar from 'components/NavigationBar';
import Page from 'components/Page';
import PageSpinner from 'components/PageSpinner';
import { Creators } from 'ducks/loggedInUser/actions';
import _intersection from 'lodash/intersection';
import _omit from 'lodash/omit';
import PageHeader from 'MainApp/PageHeaders';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { setChatUser, useChat } from 'utils/useChat';
import mapStateToProps from './selectors';


/*
 * This component exists temporarily for incomplete page files that require the Nav and Header to be provided
 */
export class PrivatePartialRouteComponent extends PureComponent {
  static propTypes = {
    hasFetchedUser: PropTypes.bool.isRequired,
    isUserAuthorized: PropTypes.bool.isRequired,
    mode: PropTypes.string,
    wideContainer: PropTypes.bool,
    userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  componentDidMount() {
    document.title = this.props.title;
    const { isChatEnabled } = this.props;
    if (isChatEnabled) {
      useChat(HUBSPOT_PORTAL_ID);
    }
    if (!this.props.hasFetchedUser) {
      this.props.fetchProfile();
    }
  }

  componentDidUpdate(prevProps) {
    const { user, isChatEnabled } = this.props;
    const isUserLoaded = !prevProps.user.email && user.email;
    let userData;
    if (isUserLoaded) {
      userData = { email: user.email, uid: user.id, displayName: `${user.firstName} ${user.lastName}` };
    }
    if (!prevProps.isChatEnabled && isChatEnabled) {
      useChat(HUBSPOT_PORTAL_ID, userData);
    }
    if (isUserLoaded && isChatEnabled) {
      setChatUser(userData);
    }
  }

  hasAccess = () => {
    const { userRoles, permissions } = this.props;
    return permissions.length === 0 || _intersection(userRoles, permissions).length > 0;
  };

  _renderPage = (Component, componentProps) => {
    if (!this.props.wideContainer) {
      return (
        <Page>
          <Component {...componentProps} mode={this.props.mode} />
        </Page>
      );
    }

    return (
      <div>
        <Component {...componentProps} mode={this.props.mode} />
      </div>
    );
  };

  _renderRoute = (props) => {
    const { component: Component, hasFetchedUser, isUserAuthorized } = this.props;
    if (!isUserAuthorized) {
      // This is triggered for users not logged in as well as
      // any server errors
      return (
        <Redirect
          to={{
            pathname: LOGIN_PATH,
            state: { from: props.location },
          }} />
      );
    }

    if (!hasFetchedUser) {
      return <PageSpinner />;
    }

    if (!this.hasAccess()) {
      return (
        <Redirect
          to={{
            pathname: NOT_FOUND_PATH,
            state: { from: props.location },
          }} />
      );
    }

    return (
      <Fragment>
        <NavigationBar />
        <PageHeader />
        {this._renderPage(Component, props)}
      </Fragment>
    );
  };

  render() {
    const routeProps = _omit(this.props, [
      'component',
      'isUserAuthorized',
      'hasFetchedUser',
      'wideContainer',
      'mode',
      'title',
      'permissions',
      'isChatEnabled',
    ]);

    return <Route {...routeProps} render={this._renderRoute} />;
  }
}

const connector = connect(
  mapStateToProps,
  { fetchProfile: Creators.fetchProfileRequest },
);
export default withRouter(connector(PrivatePartialRouteComponent));
