exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-containers-Route-___styles__logoWrapper___FlOiF {\n  padding-top: 16px;\n  padding-left: 16px;\n}\n", "", {"version":3,"sources":["/workspace/app/containers/Route/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAiB;EACjB,mBAAkB;CACpB","file":"styles.css","sourcesContent":[".logoWrapper {\n  padding-top: 16px;\n  padding-left: 16px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"logoWrapper": "app-containers-Route-___styles__logoWrapper___FlOiF"
};