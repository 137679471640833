import queryString from 'qs';
import { createSelector } from 'reselect';

export const currentPageSelector = createSelector(
  state => state.router.location.search,
  (search) => {
    const { page } = queryString.parse(search, { ignoreQueryPrefix: true });
    return page || 1;
  },
);
