import { createActions } from 'reduxsauce';

export const REDUX_NAMESPACE = 'app/UserList/';
export const REDUXACTIONS_STRUCTURE = {
  fetchUsersRequest: ['payload'],
  fetchUsersSuccess: ['users', 'usersCount'],
  fetchUsersFailure: ['error'],

  inviteUsersRequest: ['emails', 'role'],
  inviteUsersSuccess: null,
  inviteUsersFailure: ['error'],
};

export const { Types, Creators } = createActions(REDUXACTIONS_STRUCTURE, { prefix: REDUX_NAMESPACE });
