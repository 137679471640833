import assert from 'assert';
import { put, call, select, take } from 'redux-saga/effects';
import _get from 'lodash/get';
import { snakeToCamelCase } from 'shared/helpers/transforms';
import { constructFolderQueryParams, parseSearchToObject } from 'utils/url';

import { Creators, Types } from '../reducer';
import { getCurrentFolderId, getLocation } from '../selectors';
import { ROOT_DIR } from '../constants';

export default function createSagas({ templatesService, foldersService, documentService, notificationService } = {}) {
  assert.ok(templatesService, 'templatesService is required');
  assert.ok(foldersService, 'foldersService is required');
  assert.ok(documentService, 'documentService is required');
  assert.ok(notificationService, 'notificationService is required');

  function* moveToTrashSaga() {
    while (true) {
      const { templateId } = yield take(Types.MOVE_TO_TRASH);
      try {
        const location = yield select(getLocation);
        const params = parseSearchToObject(location.search);
        const { data: { id: trashId } } = yield call([templatesService, templatesService.moveToTrash], templateId);
        const currentFolderId = yield select(getCurrentFolderId);
        yield put(Creators.fetchTemplatesInfoRequest(
          {
            ...constructFolderQueryParams(currentFolderId),
            page: params.page,
          },
          undefined,
          {
            message: 'shared.statuses.moved_to_trash',
            action: {
              label: 'shared.statuses.undo',
              callback: Creators.restoreFromTrashRequest(trashId),
            },
            level: 'info',
          },
        ));
        yield put(Creators.moveToTrashSuccess());
      } catch (e) {
        const errorMessage = _get(e, 'data.message', 'generic');
        yield put(notificationService.error({
          message: `shared.errors.${errorMessage}`,
          position: 'bl',
          useTranslate: true,
        }));

        yield put(Creators.moveToTrashFailure(e));
      }
    }
  }

  function* restoreFromTrashSaga() {
    while (true) {
      const { trashId } = yield take(Types.RESTORE_FROM_TRASH_REQUEST);
      try {
        const { data: { deleted_item: { name } } } = yield call([templatesService, templatesService.restoreFromTrash], trashId);
        const currentFolderId = yield select(getCurrentFolderId);
        const location = yield select(getLocation);
        const params = parseSearchToObject(location.search);
        yield put(Creators.restoreFromTrashSuccess());
        yield put(Creators.fetchTemplatesInfoRequest(
          {
            ...constructFolderQueryParams(currentFolderId),
            page: params.page,
          },
          undefined,
          {
            message: 'shared.statuses.restored_from_trash',
            level: 'info',
            values: { name },
          },
        ));
      } catch (e) {
        const errorMessage = _get(e, 'data.message', 'generic');
        yield put(notificationService.error({
          message: `shared.errors.${errorMessage}`,
          position: 'bl',
          useTranslate: true,
        }));
        yield put(Creators.restoreFromTrashFailure(e));
      }
    }
  }

  function* updateFolderNameSaga() {
    while (true) {
      const { folderId, folderName } = yield take(Types.UPDATE_FOLDER_NAME);
      try {
        yield call([foldersService, foldersService.updateFolder], folderId, { id: folderId, name: folderName });
        yield put(Creators.updateFolderNameSuccess(folderId, folderName));
      } catch (e) {
        yield put(notificationService.error({
          message: 'folder_list.rename_folder.error',
          position: 'bl',
          useTranslate: true,
        }));
        yield put(Creators.updateFolderNameFailure(e));
      }
    }
  }

  function* deleteFolderSaga() {
    while (true) {
      const { folderId } = yield take(Types.DELETE_FOLDER);
      try {
        yield call([foldersService, foldersService.deleteFolder], folderId);
        yield put(Creators.deleteFolderSuccess(folderId));
      } catch (e) {
        yield put(notificationService.error({
          message: 'folder_list.delete_folder.error',
          position: 'bl',
          useTranslate: true,
        }));
        yield put(Creators.deleteFolderFailure(e));
      }
    }
  }

  function* moveToFolderSaga() {
    while (true) {
      const { templateId, folderId } = yield take(Types.MOVE_TO_FOLDER);
      try {
        yield call([templatesService, templatesService.moveTemplateToFolder], templateId, folderId || 0);
        const currentFolderId = yield select(getCurrentFolderId);
        yield put(Creators.moveToFolderSuccess());
        yield put(Creators.fetchTemplatesInfoRequest(constructFolderQueryParams(currentFolderId)));
      } catch (e) {
        yield put(Creators.moveToFolderFailure(e));
      }
    }
  }

  function* createTemplateFolderSaga() {
    while (true) {
      const { folderName, parentId } = yield take(Types.CREATE_TEMPLATE_FOLDER);
      try {
        const { data } = yield call([foldersService, foldersService.createFolder], folderName, parentId);
        yield put(Creators.createTemplateFolderSuccess(snakeToCamelCase(data.folder)));
      } catch (e) {
        yield put(Creators.createTemplateFolderFailure(e));
      }
    }
  }

  function* goToRootFolder() {
    try {
      const { data: { folders } } = yield call([foldersService, foldersService.fetchFoldersRequest]);
      yield put(Creators.goToFolderSuccess(folders, ROOT_DIR));
    } catch (e) {
      yield put(Creators.goToFolderFailure(e));
    }
  }

  function* goToSubfolder(folderId) {
    try {
      const { data: { folder } } = yield call([foldersService, foldersService.fetchFolders], folderId);
      yield put(Creators.goToFolderSuccess(
        folder.children,
        {
          id: folder.id,
          name: folder.name,
          parents: folder.parents,
        },
      ));
    } catch (e) {
      yield put(Creators.goToFolderFailure(e));
    }
  }

  function* goToFolderSaga() {
    while (true) {
      const { folderId } = yield take(Types.GO_TO_FOLDER_REQUEST);
      if (folderId) {
        yield* goToSubfolder(folderId);
      } else {
        yield* goToRootFolder();
      }
    }
  }


  return {
    goToFolderSaga,
    updateFolderNameSaga,
    deleteFolderSaga,
    createTemplateFolderSaga,
    moveToTrashSaga,
    moveToFolderSaga,
    restoreFromTrashSaga,
  };
}
