import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import queryString from 'qs';
import searchParams from 'shared/constants/queryParams/search';

export default function (WrappedComponent) {
  return class withPagination extends React.PureComponent {
    static propTypes = {
      history: ReactRouterPropTypes.history.isRequired,
      location: ReactRouterPropTypes.location.isRequired,
    };

    getCurrentPage = () => {
      const { page } = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });
      if (page === undefined) {
        return 1;
      }
      return page;
    };

    getCurrentPageIndex = () => this.getCurrentPage() - 1;

    handleChangePage = ({ selected }) => {
      const nextPage = selected + 1;
      const currentQueryParams = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });

      const params = queryString.stringify({
        ...currentQueryParams,
        [searchParams.page]: nextPage === 1 ? undefined : nextPage,
      });

      const historyParams = { search: params };

      const empty = this.props.location.search.length === 0;
      const duplicate = params === this.props.location.search.replace('?', '');

      if (empty || duplicate) {
        this.props.history.replace(historyParams);
      } else {
        this.props.history.push(historyParams);
      }
    };

    render() {
      return (
        <WrappedComponent
          getCurrentPage={this.getCurrentPage}
          getCurrentPageIndex={this.getCurrentPageIndex}
          handleChangePage={this.handleChangePage}
          {...this.props} />
      );
    }
  };
}
