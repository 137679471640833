import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import { Slot } from 'react-slot-fill';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import proptypes from 'utils/proptypes';
import removeQuestionFromDom from 'Visualization/helpers/removeQuestionFromDom';
import InputText from 'components/form/InputText';

import Action from '../Action';
import TriggersFn from '../TriggersFn';
import QuestionsList from './QuestionsList';
import withTriggersFnHoc from '../withTriggersFnHoc';
import withStaticOptionsHoc from '../withStaticOptionsHoc';
import styles from './index.css';

const StyledHeader = styled.p`
  display: flex;
  flex-direction: column;
  margin: 0 0 5px 0;
  color: ${props => props.theme.font.light};
  strong {
    text-transform: uppercase;
    font-size: 80%;
  }
`;

class AnchorType extends Component {
  static propTypes = {
    enableTriggersFn: PropTypes.func.isRequired,
    stopTriggersFn: PropTypes.func.isRequired,
    questions: proptypes.questionsScheme,

    setSelectedNodeWithOption: PropTypes.func.isRequired,
    refreshOptions: PropTypes.func.isRequired,
    selectedNodeWithOption: proptypes.domElement,

    assignAndClose: PropTypes.func.isRequired,
    triggerImmediately: PropTypes.bool.isRequired,
    triggersFnEnabled: PropTypes.bool.isRequired,
    triggeredOptionId: PropTypes.string,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      questions: props.questions,
      searchVal: '',
    };
  }

  updateSearchVal = (e) => {
    this.setState({ searchVal: e.target.value }, this.filterQuestionsList);
  };

  filterQuestionsList = () => {
    const newQuestions = this.props.questions.filter((el) => {
      const regexp = new RegExp(this.state.searchVal, 'i');
      if (el.name.match(regexp)) return true;
      if (el.details.options.find(option => option.name.match(regexp))) {
        return true;
      }
      return false;
    });
    this.setState({ questions: newQuestions });
    return newQuestions;
  };

  removeTriggersFn = () => {
    removeQuestionFromDom(this.props.selectedNodeWithOption);
    this.props.setSelectedNodeWithOption(null);
    this.props.refreshOptions();
  };

  setOptionSelectedState = (option, selected) => {
    if (option) {
      option.classList[selected ? 'add' : 'remove']('selected');
    }
  };

  componentDidUpdate(prevProps) {
    const { selectedNodeWithOption } = this.props;

    if (prevProps.selectedNodeWithOption !== selectedNodeWithOption) {
      this.setOptionSelectedState(selectedNodeWithOption, true);
    }
  }

  componentWillUnmount() {
    const { selectedNodeWithOption } = this.props;

    this.setOptionSelectedState(selectedNodeWithOption, false);
  }

  render() {
    const { selectedNodeWithOption, intl } = this.props;
    return (
      <div>
        <StyledHeader>
          <strong>
            <FormattedMessage id="visualization.logics_manager.reuse_question.header" />
          </strong>
          <small>
            <FormattedMessage id="visualization.logics_manager.reuse_question.header_caption" />
          </small>
        </StyledHeader>
        {selectedNodeWithOption ? (
          <Action primary onClick={this.removeTriggersFn} title={`Unlink - ${selectedNodeWithOption.innerText}`} />
        ) : null}
        {this.props.triggersFnEnabled === false ? null : (
          <TriggersFn
            logicType="anchorType"
            triggerImmediately={this.props.triggerImmediately}
            stopTriggersFn={this.props.stopTriggersFn}
            onSuccess={this.props.assignAndClose}
            optionId={this.props.triggeredOptionId} />
        )}
        <div className={styles.content}>
          <InputText
            className={styles.search}
            value={this.state.searchVal}
            onChange={this.updateSearchVal}
            searchVal={this.state.searchVal}
            placeholder={intl.formatMessage({ id: 'visualization.logics_manager.reuse_question.search_placeholder' })} />
          <QuestionsList
            noQuestions={intl.formatMessage({ id: 'visualization.logics_manager.reuse_question.no_questions' })}
            questions={this.state.questions}
            flow={this.props.flow}
            selectedNodeWithOption={selectedNodeWithOption}
            enableTriggersFn={this.props.enableTriggersFn} />
        </div>
        <Slot name="LogicsManager.Close" />
      </div>
    );
  }
}

const isQuestionInTheEditor = question => $(`[data-question-id="${question.id}"]`).length > 0;

const mapStateToProps = state => ({ flow: state.flow });

const mapDispatchToProps = state => ({ questions: state.questions.questions.filter(q => ['radio', 'checkbox'].includes(q.kind) && q.details.options.length > 0 && isQuestionInTheEditor(q)) });

export default compose(
  connect(mapStateToProps),
  connect(mapDispatchToProps),
  withTriggersFnHoc,
  withStaticOptionsHoc,
  injectIntl,
)(AnchorType);
