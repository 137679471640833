const { load } = require('cheerio');

/**
 * Converts millimeters to twip
 * @param value millimeters value
 * @return return twip
 */
export const mmToTwip = value => Number(((value || 0) * 567 * 0.1).toFixed(0));

/**
 * This hidden table solution gives us flexibility to have header and footer content in single html file.
 * @param html content
 * @param header header html content
 * @param footer footer html content
 */
export const applyHeaderAndFooter = (html, header, footer) => {
  const $$ = load(html);
  const msoPageNumber = '<span style=\'mso-field-code: PAGE \'></span> / <span style=\'mso-field-code: NUMPAGES \'></span>';

  $$('body')
    .append(
      `
        <div style="margin:0in 0in 0in 9in;">
          <div class="__document_template_dynamic_stylesheet" id="document-template-header">
              ${header.replace(/\[page\]/g, msoPageNumber)}
          </div>
          <div class="__document_template_dynamic_stylesheet" id="document-template-footer">
              ${footer.replace(/\[page\]/g, msoPageNumber)}
          </div>
        </div>
    `,
    );

  return $$.html();
};

/**
 * Css attribute selectors such as -> span[data-logictype='inputReplaceType' does not recognized by MS Office style rules so this function replaces style definition with class name.
 * @param html content
 */
export const fixQuestionHighlightingStyle = (html) => {
  const selector = `span[data-logictype='inputReplaceType']`;
  const $$ = load(html);

  // update input
  $$(selector)
    .removeClass();
  $$(selector)
    .addClass('highlighted_answer');

  return $$.html();
};


/**
 * Converts millimeter defined margin object to Twip defined margin object,
 * @param object consist of shape -> { margin_top, margin_bottom, margin_left, margin_right }, reponse of ruby API
 * @return { top, bottom, left, right } shaped Twip margin values for DOCX page layout configuration
 *
 * camelcase suppressed because of properties names comes from Ruby App
 */
// eslint-disable-next-line camelcase
export const convertMillimeterMarginsToTwipMargins = ({ margin_top, margin_bottom, margin_left, margin_right }) => ({
  top: mmToTwip(margin_top),
  bottom: mmToTwip(margin_bottom),
  left: mmToTwip(margin_left),
  right: mmToTwip(margin_right),
});
