exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-pages-Templates-views-___styles__folderListContainer____mWJz {\n  margin-bottom: 35px;\n}\n", "", {"version":3,"sources":["/workspace/app/pages/Templates/views/styles.css"],"names":[],"mappings":"AAAA;EACE,oBAAmB;CACrB","file":"styles.css","sourcesContent":[".folderListContainer {\n  margin-bottom: 35px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"folderListContainer": "app-pages-Templates-views-___styles__folderListContainer____mWJz"
};