import assert from 'assert';
import { call, put, take } from 'redux-saga/effects';
import _get from 'lodash/get';
import { push } from 'connected-react-router';
import { snakeToCamelCase } from 'shared/helpers/transforms';
import { Creators, Types } from './actions';
import { Creators as QuestionnaireActions } from '../questionnaire/actions';

export default function createSagas({
  authenticationService,
  userService,
  notificationService,
  credentialsService,
} = {}) {
  assert.ok(authenticationService, 'authenticationService is required');
  assert.ok(userService, 'userService is required');
  assert.ok(notificationService, 'notificationService is required');
  assert.ok(credentialsService, 'credentialsService is required');

  function* changeLanguageSaga() {
    while (true) {
      const { language } = yield take(Types.CHANGE_LANGUAGE_REQUEST);

      try {
        yield call([userService, userService.changeLanguage], language);
        yield put(Creators.changeLanguageSuccess(language));
      } catch (e) {
        yield put(Creators.changeLanguageFailure(e));
      }
    }
  }

  function* changePasswordSaga() {
    while (true) {
      const { currentPassword, newPassword, confirmPassword } = yield take(Types.CHANGE_PASSWORD_REQUEST);

      try {
        const response = yield call(
          [userService, userService.savePassword],
          { currentPassword, newPassword, confirmPassword },
        );
        const transformedUserData = snakeToCamelCase(response.data.user);
        yield put(Creators.changePasswordSuccess(transformedUserData));
        yield put(notificationService.success({
          title: 'user_profile.password_change_form.notification_success',
          message: 'user_profile.password_change_form.notification_password_changed',
          position: 'bl',
          useTranslate: true,
        }));
      } catch (e) {
        yield put(Creators.changePasswordFailure(e));
        const errorKey = e.data && e.data.current_password ? 'current_password' : 'default';
        yield put(notificationService.error({
          title: 'user_profile.password_change_form.notification_error',
          message: `user_profile.password_change_form.notification_error_${errorKey}`,
          position: 'bl',
          useTranslate: true,
        }));
      }
    }
  }

  function* changePersonalInformationSaga() {
    while (true) {
      const { firstName, lastName, email } = yield take(Types.CHANGE_PERSONAL_INFORMATION_REQUEST);

      try {
        const response = yield call([userService, userService.savePersonalInformation], { firstName, lastName, email });
        const transformedUserData = snakeToCamelCase(response.data.user);

        yield put(Creators.changePersonalInformationSuccess(transformedUserData));
        yield put(notificationService.success({
          title: 'user_profile.personal_information_form.notification_success',
          message: 'user_profile.personal_information_form.notification_personal_information_updated',
          position: 'bl',
          useTranslate: true,
        }));
      } catch (e) {
        yield credentialsService.saveCredentials({});
        yield put(Creators.changePersonalInformationFailure(e));
        yield put(notificationService.error({
          title: 'user_profile.personal_information_form.notification_error',
          message: 'user_profile.personal_information_form.notification_error_something',
          position: 'bl',
          useTranslate: true,
        }));
      }
    }
  }

  function* fetchProfileSaga() {
    while (true) {
      yield take(Types.FETCH_PROFILE_REQUEST);

      try {
        const response = yield call([userService, userService.fetchProfile]);
        const user = snakeToCamelCase(response.data);
        yield put(QuestionnaireActions.fetchCompany());
        yield put(Creators.fetchProfileSuccess(user));
      } catch (e) {
        yield put(Creators.fetchProfileFailure(e));
      }
    }
  }

  function* forgotPasswordSaga() {
    while (true) {
      const { email } = yield take(Types.FORGOT_PASSWORD_REQUEST);

      try {
        yield call([authenticationService, authenticationService.forgotPassword], { email });
        yield put(Creators.forgotPasswordSuccess());
        yield put(push('/auth/forgot-password/sent'));
      } catch (e) {
        yield put(Creators.forgotPasswordFailure(e));
        yield put(notificationService.error({
          message: 'forgot_password.errors.email_not_exist',
          useTranslate: true,
        }));
      }
    }
  }

  function* resetPasswordSaga() {
    while (true) {
      const { password, confirmPassword, token } = yield take(Types.RESET_PASSWORD_REQUEST);

      try {
        const response = yield call(
          [authenticationService, authenticationService.resetPassword],
          { password, confirmPassword },
          token,
        );

        yield credentialsService.saveCredentials({
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
        });
        yield put(Creators.resetPasswordSuccess(response.data.access_token, response.data.refresh_token));
        yield put(Creators.fetchProfileRequest());
        yield put(push('/'));
      } catch (e) {
        yield put(Creators.resetPasswordFailure(e));
      }
    }
  }

  function* signInSaga() {
    while (true) {
      const { email, password } = yield take(Types.SIGN_IN_REQUEST);

      try {
        const response = yield call([authenticationService, authenticationService.signIn], { email, password });
        yield put(Creators.signInSuccess(response.data.access_token, response.data.refresh_token));
      } catch (e) {
        yield credentialsService.saveCredentials({});
        yield put(Creators.signInFailure(e));
        if (_get(e, 'data.message', '') !== 'user_disabled') {
          yield put(
            notificationService.error({
              message: 'sign_in.errors.wrong_password',
              useTranslate: true,
            }),
          );
        }
      }
    }
  }

  function* signInSuccessSaga() {
    while (true) {
      const { accessToken, refreshToken } = yield take(Types.SIGN_IN_SUCCESS);
      yield credentialsService.saveCredentials({
        accessToken,
        refreshToken,
      });
      yield put(Creators.fetchProfileRequest());
    }
  }

  function* signOutSaga() {
    while (true) {
      yield take(Types.SIGN_OUT);
      yield credentialsService.saveCredentials({});
    }
  }

  return {
    changeLanguageSaga,
    changePasswordSaga,
    changePersonalInformationSaga,
    fetchProfileSaga,
    forgotPasswordSaga,
    resetPasswordSaga,
    signInSaga,
    signInSuccessSaga,
    signOutSaga,
  };
}
