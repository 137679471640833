import React, { PureComponent } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import Views from 'shared/Components/Views';
import Tabs, { Tab } from 'shared/Components/Tabs';

import TemplateHistoryContainer from '../containers/TemplateHistory';
import DocumentsContainer from '../containers/Documents';

class TemplateDetails extends PureComponent {
  static propTypes = { match: ReactRouterPropTypes.match }

  state = { currentTabIndex: 0 };

  handleChange = (event, value) => {
    this.setState({ currentTabIndex: value });
  };

  handleChangeIndex = (index) => {
    this.setState({ currentTabIndex: index });
  };

  render() {
    const { currentTabIndex } = this.state;
    const { match } = this.props;
    return (
      <section>
        <Tabs currentTabIndex={currentTabIndex} onChange={this.handleChange}>
          <Tab label="History" data-test-id="TemplatesInformation-history" />
          <Tab label="Documents" data-test-id="TemplatesInformation-documents" />
        </Tabs>
        <Views index={currentTabIndex}>
          <TemplateHistoryContainer match={match} />
          <DocumentsContainer match={match} />
        </Views>
      </section>
    );
  }
}

export default TemplateDetails;
