import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import FieldRow from 'components/form/FieldRow';
import InputPassword from 'components/form/InputPassword';
import Label from 'components/form/Label';
import Button from 'components/Button';
import { isPasswordStrong } from 'utils/password';
import { ButtonGroup } from './styled-components';

const requiredValues = ['currentPassword', 'newPassword', 'confirmPassword'];

class PasswordChangeForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isUpdatingUser: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    initialValues: PropTypes.shape({
      currentPassword: PropTypes.string,
      newPassword: PropTypes.string,
      confirmPassword: PropTypes.string,
    }),
  };

  validateFormik = (values) => {
    const errors = {};
    const { intl } = this.props;

    requiredValues.forEach((name) => {
      if (!values[name]) errors[name] = intl.formatMessage({ id: 'user_profile.password_change_form.cant_be_blank' });
    });

    if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = intl.formatMessage({ id: 'user_profile.password_change_form.passwords_must_match' });
    }

    if (errors.newPassword == null && !isPasswordStrong(values.newPassword)) {
      errors.newPassword = intl.formatMessage({ id: 'user_profile.password_change_form.password_not_strong_enough' });
    }

    return errors;
  }

  onSubmitFormik = (values) => {
    if (!this.props.isUpdatingUser) {
      this.props.onSubmit(values);
    }
  }

  render() {
    const { initialValues, isUpdatingUser } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={this.onSubmitFormik}
        validate={this.validateFormik}>
        {props => (
          <React.Fragment>
            <FieldRow>
              <Label htmlFor="currentPassword">
                <FormattedMessage id="user_profile.password_change_form.current_password" />
              </Label>
              <Field name="currentPassword">
                {({ field, form }) => (
                  <InputPassword
                    autocomplete="off"
                    errorMessage={form.errors[field.name]}
                    id="currentPassword"
                    required
                    testId="PasswordChangeForm-currentPasswordField"
                    {...field} />
                )}
              </Field>
            </FieldRow>

            <FieldRow>
              <Label htmlFor="newPassword">
                <FormattedMessage id="user_profile.password_change_form.new_password" />
              </Label>

              <Field name="newPassword">
                {({ field, form }) => (
                  <InputPassword
                    autocomplete="new-password"
                    errorMessage={form.errors[field.name]}
                    id="newPassword"
                    required
                    showStrengthIndicator
                    testId="PasswordChangeForm-newPasswordField"
                    {...field} />
                )}
              </Field>
            </FieldRow>

            <FieldRow>
              <Label htmlFor="confirmPassword">
                <FormattedMessage id="user_profile.password_change_form.confirm_password" />
              </Label>

              <Field name="confirmPassword">
                {({ field, form }) => (
                  <InputPassword
                    autocomplete="new-password"
                    errorMessage={form.errors[field.name]}
                    id="confirmPassword"
                    required
                    testId="PasswordChangeForm-confirmPasswordField"
                    {...field} />
                )}
              </Field>
            </FieldRow>

            <ButtonGroup>
              <Button
                type="submit"
                testId="PasswordChangeForm-submit"
                loading={isUpdatingUser}
                onClick={props.submitForm}>
                <FormattedMessage id="user_profile.password_change_form.save_new_passsword" />
              </Button>
            </ButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    );
  }
}

export default injectIntl(PasswordChangeForm);
