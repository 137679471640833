import assert from 'assert';
import Response from '../models/Response';

const ALLOWED_HTTP_METHODS = ['get', 'post', 'delete', 'put', 'head', 'patch'];
function _getHttpMethod({ method = 'get' }) {
  const loweredCase = method.toLowerCase();

  if (ALLOWED_HTTP_METHODS.indexOf(loweredCase) < 0) {
    throw new Error(`Illegal http method: ${method}`);
  }

  return loweredCase;
}

/**
 * Class used for making http calls. This is just a pure
 * wrapper around the http lib that we decide to use,
 * so that we can switch implementations.
 */
export class BasicHttpClient {
  /**
   * Creates an HttpClient instance.
   *
   * @param {string} obj.accessType - The value of header for resolving app mode on backend.
   * @param {string} obj.apiPath - The relative path to the api.
   * @param {string} obj.baseUrl - The base URL where all requests are made.
   * @param {object} obj.axiosInstance - The http client library
   */
  constructor({ accessType, apiPath, baseUrl, axiosInstance } = {}) {
    assert.ok(typeof accessType === 'string', 'accessType<string> is required');
    assert.ok(typeof baseUrl === 'string', 'baseUrl<string> is required');
    assert.ok(axiosInstance, 'axios<axios> is required');
    this.accessType = accessType;
    this.apiPath = apiPath;
    this.baseUrl = baseUrl;
    this.axiosInstance = axiosInstance;
  }
  /**
   * Wrapper for the axios API
   *
   * @param {string} path - Required. Path (appended to baseUrl) where API call will be made
   * @param {object} options - Required. Contains the data needed for the fetch API
   * @return {Promise<Response>} - A promise which will return a Response object (https://developer.mozilla.org/en-US/docs/Web/API/Response)
   */

  async request(path, options) {
    assert.ok(options, 'options are required');
    const apiPath = this.apiPath + path;
    const axiosOptions = Object.assign(
      {},
      options,
      {
        url: apiPath,
        baseURL: this.baseUrl,
        ...options,
        method: _getHttpMethod({ method: options.method }),
        headers: { ...options.headers, 'x-access-type': this.accessType },
      },
    );
    let response;

    try {
      const {
        data,
        headers,
        status,
        statusText,
        config,
      } = await this.axiosInstance.request(axiosOptions);
      response = Promise.resolve(new Response({
        data,
        headers,
        status,
        statusText,
        ok: statusText === 'OK',
        url: config ? config.url : null,
      }));
    } catch (error) {
      const responseObject = error.response;

      if (!responseObject) {
        // Either server error or something else.
        const requestUrl = `${axiosOptions.baseURL}${axiosOptions.url}`;
        const method = axiosOptions.method.toUpperCase();
        throw new Error(`Could not make ${method} request to URL ${requestUrl} - Received error: ${error}`);
      }

      const {
        data,
        headers,
        status,
        statusText,
        config,
      } = responseObject;

      response = Promise.reject(new Response({
        data,
        headers,
        status,
        statusText,
        ok: statusText === 'OK',
        url: config ? config.url : null,
      }));
    }

    return response;
  }
}

export default class AuthenticatedHttpClient {
  /**
   * Creates an HttpClient instance.
   *
   * @param {object} obj
   * @param {string} obj.accessType - The value of header for resolving app mode on backend.
   * @param {string} obj.apiPath - The relative path to the api.
   * @param {string} obj.baseUrl - The base URL where all requests are made.
   * @param {object} axiosInstance - axiosInstance
   * @param {Function<Promise>} obj.fetchCredentials - Fetches authentication tokens
   */
  constructor({ accessType, apiPath, baseUrl, axiosInstance, fetchCredentials = () => Promise.resolve() } = {}) {
    assert.ok(typeof accessType === 'string', 'accessType<string> is required');
    assert.ok(typeof baseUrl === 'string', 'baseUrl<string> is required');
    assert.ok(typeof fetchCredentials === 'function', 'fetchCredentials<function> is requried');
    assert.ok(axiosInstance, 'axiosInstance<axios> is required');

    this.httpClient = new BasicHttpClient({ accessType, apiPath, baseUrl, axiosInstance });
    this.fetchCredentials = fetchCredentials;
    this.apiPath = apiPath;
    this.baseUrl = baseUrl;
    this.middlewareList = [];
    this.accessType = accessType;
    this.axiosInstance = axiosInstance;
  }

  /**
   * Wrapper for the axios API
   *
   * @param {string} path - Required. Path (appended to baseUrl) where API call will be made
   * @param {object} options - Required. Contains the data needed for the fetch API
   * @return {Promise<Response>} - A promise which will return a Response object (https://developer.mozilla.org/en-US/docs/Web/API/Response)
   */
  async request(path, options) {
    assert.ok(options, 'options are required');

    const { accessToken } = await this.fetchCredentials() || {};
    let httpClientOptions = Object.assign(
      {},
      options,
      { headers: { ...options.headers, Authorization: `Bearer ${accessToken}`, 'x-access-type': this.accessType } },
    );

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.middlewareList.length; index++) {
      const middleware = this.middlewareList[index];

      // eslint-disable-next-line no-await-in-loop
      httpClientOptions = await middleware(httpClientOptions);
    }

    return this.httpClient.request(path, httpClientOptions);
  }

  registerMiddleware(middleware) {
    assert.ok(typeof middleware === 'function', 'middleware<function> is required');

    this.middlewareList.push(middleware);
  }
}
