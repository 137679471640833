import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';
import { Types } from './actions';
import { FILE_TYPE } from './constants';

export const initialState = {
  fileType: null,
  fileName: null,
  file: null,
  error: null,
};

const setExportDocxRequest = (state, { fileName }) => update(state, {
  fileName: { $set: fileName },
  fileType: { $set: FILE_TYPE.DOCX },
});

const setExportDocxFailure = (state, { error }) => update(state, { error: { $set: error } });

const setExportDocxSuccess = (state, { file }) => update(state, { file: { $set: file } });

const setExportPdfRequest = (state, { fileName }) => update(state, {
  fileName: { $set: fileName },
  fileType: { $set: FILE_TYPE.PDF },
});

const setExportPdfFailure = (state, { error }) => update(state, { error: { $set: error } });

const setExportPdfSuccess = (state, { file }) => update(state, { file: { $set: file } });

export default createReducer(initialState, {
  [Types.EXPORT_DOCX_REQUEST]: setExportDocxRequest,
  [Types.EXPORT_DOCX_FAILURE]: setExportDocxFailure,
  [Types.EXPORT_DOCX_SUCCESS]: setExportDocxSuccess,
  [Types.EXPORT_PDF_REQUEST]: setExportPdfRequest,
  [Types.EXPORT_PDF_FAILURE]: setExportPdfFailure,
  [Types.EXPORT_PDF_SUCCESS]: setExportPdfSuccess,
});
