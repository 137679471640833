import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import findLastIndex from 'lodash/findLastIndex';
import slice from 'lodash/slice';
import proptypes from 'utils/proptypes';
import { Creators as QuestionnaireActions } from 'ducks/questionnaire/actions';
import { answerableQuestionsSelector } from 'ducks/questionnaire/selectors';
import Question from '../../components/Question';
import styles from './index.css';

export function QuestionListComponent({
  isVisible,
  questions,
  currentQuestion,
  setCurrentQuestion,
  hideIntroduction,
  isIntroductionVisible,
}) {
  const classes = cn(styles.component, { [styles.visible]: isVisible });
  const lastVisitedQuestionIndex = findLastIndex(questions, q => q.answer !== undefined || currentQuestion.id === q.id);
  const filteredQuestions = slice(questions, 0, lastVisitedQuestionIndex + 1);

  function goToQuestion(question) {
    if (isIntroductionVisible) {
      hideIntroduction();
    }
    setCurrentQuestion(question);
  }

  return (
    <div id="question-list-container" className={classes}>
      {filteredQuestions.map(question => (
        <Question
          key={question.id}
          active={currentQuestion.id === question.id && !isIntroductionVisible}
          question={question}
          goToQuestion={goToQuestion} />
      ))}
    </div>
  );
}

QuestionListComponent.propTypes = {
  questions: proptypes.questionsScheme,
  currentQuestion: proptypes.questionScheme,
  isVisible: PropTypes.bool.isRequired,
  setCurrentQuestion: PropTypes.func.isRequired,
  hideIntroduction: PropTypes.func.isRequired,
  isIntroductionVisible: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    currentQuestion: state.questionnaire.currentQuestion,
    questions: answerableQuestionsSelector(state),
  };
}

const mapDispatchToProps = { setCurrentQuestion: QuestionnaireActions.setCurrentQuestion };


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionListComponent);
