import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_KEY } from 'shared/helpers/constants';
import proptypes from 'utils/proptypes';
import WithPageBreak from 'shared/Components/WithPageBreak';

import editorSetupHoc from './editorSetupHoc';
import EditorWrapper from './EditorWrapper';

class TemplateEditor extends PureComponent {
  static propTypes = {
    templateVersion: proptypes.templateVersionScheme,
    stylesheet: PropTypes.string.isRequired,
  };

  handleEditorInit = (editor) => {
    const { className } = editor.bodyElement;
    // eslint-disable-next-line
    editor.bodyElement.className = `${className} __document_template_dynamic_stylesheet`;
  };

  render() {
    const { stylesheet, templateVersion } = this.props;

    return (
      <WithPageBreak>
        <EditorWrapper>
          <Editor
            apiKey={TINYMCE_KEY}
            value={templateVersion.wysiwyg_html}
            disabled
            init={{
              height: 800,
              minHeight: 400,
              content_style: stylesheet,
              init_instance_callback: this.handleEditorInit,
              menubar: false,
              skin: false,
              inline: true,
              toolbar: false,
              theme: 'modern',
            }} />
        </EditorWrapper>
      </WithPageBreak>
    );
  }
}

export default editorSetupHoc(TemplateEditor);
