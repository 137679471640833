import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import Logo from 'components/Logo';
import { withAcl } from 'services/acl';
import IconButton from 'components/IconButton';
import { Creators } from 'ducks/loggedInUser/actions';
import { ADMIN_ROLE, PRODUCER_ROLE } from 'shared/constants/user/roles';

import styles from './index.css';

const links = [
  {
    url: '/templates',
    text: 'top_navbar.templates',
    permissions: [ADMIN_ROLE, PRODUCER_ROLE],
    dataTest: 'TopNavbar-templatesLink',
  },
  {
    url: '/documents',
    text: 'top_navbar.documents',
    dataTest: 'TopNavbar-documentsLink',
  },
  {
    url: '/members',
    text: 'top_navbar.team',
    permissions: [ADMIN_ROLE, PRODUCER_ROLE],
    dataTest: 'TopNavbar-teamLink',
  },
  {
    url: '/settings/document_templates/edit',
    text: 'top_navbar.template_styles',
    permissions: [ADMIN_ROLE, PRODUCER_ROLE],
    dataTest: 'TopNabar-documentStylesLink',
  },
  {
    url: '/settings/edit',
    text: 'top_navbar.profile_settings',
    dataTest: 'UserMenu-profileLink',
  },
];

export class MobileNavigationBarComponent extends PureComponent {
  static propTypes = {
    hasRole: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
  }

  state = { menuOpened: false }

  toggleMenu = () => {
    this.setState(state => ({ menuOpened: !state.menuOpened }));
  }

  render() {
    const { hasRole, signOut } = this.props;

    return (
      <div className={styles.component}>
        <Logo />
        <div className={styles.row}>
          <a
            data-test-id="TopNavbar-helpLink"
            className={styles.navLink}
            target="_blank"
            href="/freshdesk/help">
            <FormattedMessage id="top_navbar.help" />
          </a>
          <IconButton onClick={this.toggleMenu} className={styles.toggleMenuButton}>
            <MenuIcon />
          </IconButton>
        </div>
        <Drawer open={this.state.menuOpened} onClose={this.toggleMenu}>
          {links.map(l => (!l.permissions || hasRole(l.permissions) ? (
            <NavLink
              data-test-id={`${l.dataTest}-button`}
              key={l.url}
              to={l.url}
              className={styles.navLink}
              activeClassName={styles.navLinkActive}>
              <FormattedMessage id={l.text} />
            </NavLink>
          ) : null))}
          <NavLink to="" className={styles.navLink} onClick={signOut}>
            <FormattedMessage id="top_navbar.log_out" />
          </NavLink>
        </Drawer>
      </div>
    );
  }
}

const mapDispatchToProps = { signOut: Creators.signOut };

export default compose(
  withAcl,
  connect(null, mapDispatchToProps),
)(MobileNavigationBarComponent);
