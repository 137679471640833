import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Editor as MceEditor } from '@tinymce/tinymce-react';
import { TINYMCE_KEY } from 'shared/helpers/constants';
import { EditorFilePicker } from 'containers/EditorFilePicker';
import { editorSkinLoader } from './editorSkinLoader';
import stickyToolbar from './plugins/stickyToolbar';
import tableBordersPlugin from './plugins/tableBorders';
import styles from './index.css';

/* istanbul ignore next */
editorSkinLoader();

class Editor extends PureComponent {
  static propTypes = {
    init: PropTypes.func.isRequired,
    initialValue: PropTypes.string.isRequired,
    onEditorChange: PropTypes.func.isRequired,
    onFocusOut: PropTypes.func,
    onKeyUp: PropTypes.func,
    onMouseUp: PropTypes.func,
    onSelectionChange: PropTypes.func,
    setupEditor: PropTypes.func,
    value: PropTypes.string.isRequired,
    editorId: PropTypes.string.isRequired,
    editorSettings: PropTypes.shape({}).isRequired,
    plugins: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = { plugins: [] }

  setTestId = testId => (e) => {
    e.control.$el[0].setAttribute('data-test-id', testId);
  };

  setEditorClassname = (editor) => {
    const { className } = editor.bodyElement;
    editor.bodyElement.className = `${className} __document_template_dynamic_stylesheet editor__question-wrapper`;
  }

  setupEditor = (editor) => {
    editor.editorManager.PluginManager.add('stickytoolbar', stickyToolbar);
    editor.editorManager.PluginManager.add('tableborders', tableBordersPlugin);
    // This prevents the blur event from hiding the toolbar
    editor.on('blur', false);
    editor.on('init', this.setEditorClassname.bind(null, editor));

    if (this.props.setupEditor) {
      this.props.setupEditor(editor);
    }
  }

  render() {
    const {
      init,
      initialValue,
      onEditorChange,
      onFocusOut,
      onKeyUp,
      onMouseUp,
      onSelectionChange,
      value,
      editorId,
      editorSettings,
      plugins,
    } = this.props;

    return (
      <Fragment>
        <div className={styles.toolbarContainer} id="fixed-toolbar-container" />
        <EditorFilePicker>
          {({ onFilePick }) => (
            <MceEditor
              id={editorId}
              apiKey={TINYMCE_KEY}
              value={value}
              initialValue={initialValue}
              onEditorChange={onEditorChange}
              onSelectionChange={onSelectionChange}
              onKeyUp={onKeyUp}
              onMouseUp={onMouseUp}
              onFocusOut={onFocusOut}
              init={{
                height: 800,
                minHeight: 400,
                menubar: false,
                skin: false,
                inline: true,
                browser_spellcheck: true,
                fixed_toolbar_container: '#fixed-toolbar-container',
                init_instance_callback: init,
                setup: this.setupEditor,
                autosave_ask_before_unload: false,
                theme: 'modern',
                plugins: [
                  'stickytoolbar autolink autosave lists link image charmap hr anchor paste',
                  'insertdatetime media nonbreaking save table directionality',
                  'emoticons template textcolor colorpicker textpattern imagetools toc pagebreak tableborders',
                  ...plugins,
                ],
                table_appearance_options: false,
                table_advtab: false,
                table_cell_advtab: false,
                table_row_advtab: false,
                table_controls: 'table',
                table_toolbar:
              'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | tablemergecells tablesplitcells',
                formats: {
                  paragraph: { block: 'p', attributes: { class: '' } },
                  title: { block: 'p', attributes: { class: 'title' } },
                  heading1: { block: 'h1', attributes: { class: '' } },
                  heading2: { block: 'h2', attributes: { class: '' } },
                  heading3: { block: 'h3', attributes: { class: '' } },
                  heading4: { block: 'h4', attributes: { class: '' } },
                },
                block_formats: 'Paragraph=paragraph;Title=title;Header 1=heading1;Header 2=heading2;Header 3=heading3;Header 4=heading4',
                toolbar1:
              'formatselect | undo redo | bold italic underline forecolor backcolor image | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | pageBreak | table tableborders',
                valid_styles: {
                  '*': 'color,padding-left,padding-right,text-align,background-color,text-decoration',
                  table: 'width,height',
                  tr: 'width,height',
                  td: 'width,height',
                },
                paste_retain_style_properties: 'color,padding-left,padding-right',
                paste_webkit_styles: 'color,padding-left,padding-right',
                paste_block_drop: true,
                valid_children: '*[*]',
                valid_elements: '*[*]',
                paste_data_images: true,
                file_picker_types: 'image',
                file_picker_callback: onFilePick,
                ...editorSettings,
              }} />
          )}
        </EditorFilePicker>
      </Fragment>
    );
  }
}

export default Editor;
