import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button, { SECONDARY, DANGER } from 'components/Button';
import Modal, { ModalFooter } from 'components/Modal';
import { Creators } from '../actions';
import styles from './styles.css';

class EmptyTrashDialog extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    emptyTrash: PropTypes.func,
  };

  handleDelete = () => {
    const { emptyTrash, onClose } = this.props;
    emptyTrash();
    onClose();
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <Modal
        size="medium"
        onClose={onClose}
        toggleModal={onClose}
        open={isOpen}
        modalTitle={<FormattedMessage id="panel.trash.delete_item_modal.delete_forever" />}
        testId="TrashEmpty-dialog">
        <DialogContent classes={{ root: styles.dialogContent }}>
          <Typography align="center" gutterBottom variant="caption" classes={{ caption: 'deleteModal--caption' }}>
            <FormattedMessage id="panel.trash.empty_trash_modal.are_you_sure" values={{ newLine: <br /> }} />
          </Typography>
        </DialogContent>
        <ModalFooter>
          <Button onClick={onClose} variant={SECONDARY} testId="TrashEmpty-cancelButton">
            <FormattedMessage id="panel.trash.delete_item_modal.cancel" />
          </Button>
          <Button onClick={this.handleDelete} color={DANGER} testId="TrashEmpty-deleteButton">
            <FormattedMessage id="panel.trash.delete_item_modal.delete" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const mapDispatchToProps = dispatch => ({ emptyTrash: () => dispatch(Creators.emptyTrash()) });

export default connect(null, mapDispatchToProps)(EmptyTrashDialog);
