import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';

import OwnerCell from 'components/table/OwnerCell';
import DateCell from 'components/table/DateCell';
import StatusCell from 'components/table/StatusCell';
import TableRow from '@material-ui/core/TableRow';
import ActionCell from '../../../containers/ActionCell';
import TemplateCell from '../../TableCells/TemplateCell';
import styles from './index.css';

class TemplateTableRow extends PureComponent {
  static propTypes = {
    template: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      sharingActive: PropTypes.bool,
      sharingSlug: PropTypes.string,
      status: PropTypes.string,
    }),
    detailsRoute: PropTypes.string,
    moveToTrash: PropTypes.func,
    moveToFolder: PropTypes.func,
    folderId: PropTypes.string,
    testIdPrefix: PropTypes.string,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = { testIdPrefix: '' };

  render() {
    const { template, detailsRoute, moveToTrash, moveToFolder, folderId, testIdPrefix, push } = this.props;
    const owner = {
      ...template.user,
      role: template.user.roles[0],
    };
    return (
      <TableRow hover data-test-id="TemplatesTableRow-row" classes={{ root: styles.component }}>
        <TableCell width="40%" data-test-id="TemplatesTableRow-data">
          <Link to={detailsRoute}>
            <TemplateCell template={template} folderId={folderId} />
          </Link>
        </TableCell>
        <TableCell width="10%">
          <Link to={detailsRoute}>
            <DateCell value={template.createdAt} />
          </Link>
        </TableCell>
        <TableCell width="10%">
          <Link to={detailsRoute}>
            <DateCell value={template.updatedAt} />
          </Link>
        </TableCell>
        <TableCell width="12%" data-test-id="TemplatesTableRow-status">
          <Link to={detailsRoute}>
            <StatusCell value={template.status} />
          </Link>
        </TableCell>
        <TableCell width="23%">
          <Link to={detailsRoute}>
            <OwnerCell owner={owner} />
          </Link>
        </TableCell>
        <TableCell>
          <ActionCell
            templateId={template.id}
            moveToTrash={moveToTrash}
            moveToFolder={moveToFolder}
            sharingActive={template.sharingActive}
            sharingSlug={template.sharingSlug}
            templateStatus={template.status}
            lastQtvDetails={template.lastQtvDetails}
            testId={testIdPrefix === '' ? 'Templates-actions' : `${testIdPrefix}-actions`}
            push={push} />
        </TableCell>
      </TableRow>
    );
  }
}

export default TemplateTableRow;
