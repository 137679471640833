import React, { Fragment } from 'react';

import GridItem from 'components/Grid/Item';
import PropTypes from 'prop-types';
import DownloadPdf from '../modules/DownloadPdf';

const Signed = ({ isMobile }) => (
  <Fragment>
    <GridItem xs="auto" sm="auto">
      <DownloadPdf isMobile={isMobile} />
    </GridItem>
  </Fragment>
);

Signed.propTypes = { isMobile: PropTypes.bool };

export default Signed;
