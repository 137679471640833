import { put, call, select, take } from 'redux-saga/effects';
import map from 'lodash/fp/map';
import flow from 'lodash/flow';
import { snakeToCamelCase } from 'shared/helpers/transforms';
import { currentPageSelector } from 'utils/currentPageSelector';
import { Creators as TrashActions, Types as TrashTypes } from './actions';

const _setItemType = (item) => {
  switch (item.trashableType) {
    case 'QuestionnaireTemplate':
      return { ...item, isTemplate: true, isDocument: false };
    case 'UserQuestionnaire':
      return { ...item, isTemplate: false, isDocument: true };
    default:
      return item;
  }
};

export default function createSagas({ trashService, notificationService }) {
  function* fetchTrashSaga() {
    while (true) {
      const { payload: { page }, toast } = yield take(TrashTypes.FETCH_TRASH_REQUEST);

      try {
        const { data } = yield call([trashService, trashService.fetchPage], page);
        const deletedItems = flow(
          map(snakeToCamelCase),
          map(_setItemType),
        )(data.deleted_items);
        yield put(TrashActions.fetchTrashSuccess(deletedItems, data.deleted_items_count));
        if (toast) {
          yield put(notificationService.show({
            ...toast,
            position: 'bl',
            useTranslate: true,
          }, toast.level));
        }
      } catch (e) {
        yield put(TrashActions.fetchTrashFailure(e));
      }
    }
  }

  function* restoreItemSaga() {
    while (true) {
      const { id } = yield take(TrashTypes.RESTORE_ITEM);
      const page = yield select(currentPageSelector);

      try {
        const { data: { deleted_item: { name } } } = yield call([trashService, trashService.restoreItem], id);
        yield put(TrashActions.restoreItemSuccess());
        yield put(TrashActions.fetchTrashRequest({ page }, {
          message: 'shared.statuses.restored_from_trash',
          level: 'info',
          values: { name },
        }));
      } catch (e) {
        yield put(TrashActions.restoreItemFailure(e));
      }
    }
  }

  function* deleteItemSaga() {
    while (true) {
      const { id } = yield take(TrashTypes.DELETE_ITEM);
      const page = yield select(currentPageSelector);

      try {
        yield call([trashService, trashService.deleteItem], id);
        yield put(TrashActions.deleteItemSuccess());
        yield put(TrashActions.fetchTrashRequest({ page }));
      } catch (e) {
        yield put(TrashActions.deleteItemFailure(e));
      }
    }
  }

  function* deleteAllSaga() {
    while (true) {
      yield take(TrashTypes.EMPTY_TRASH);
      const page = yield select(currentPageSelector);

      try {
        yield call([trashService, trashService.deleteAll]);
        yield put(TrashActions.emptyTrashSuccess());
        yield put(TrashActions.fetchTrashRequest({ page }));
      } catch (e) {
        yield put(TrashActions.emptyTrashFailure(e));
      }
    }
  }

  return {
    fetchTrashSaga,
    restoreItemSaga,
    deleteItemSaga,
    deleteAllSaga,
  };
}
