import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { templatePropertiesSelector } from 'ducks/questionnaire/selectors';
import GridItem from 'components/Grid/Item';
import AbortSigning from '../modules/AbortSigning';
import DownloadPdf, { SECONDARY } from '../modules/DownloadPdf';

const SendForSigning = ({ esigningModule, signingEnabled, isMobile }) => {
  const downloadButtonVariant = (esigningModule && signingEnabled) ? SECONDARY : '';

  return (
    <Fragment>
      <GridItem xs="auto" sm="auto">
        <DownloadPdf variant={downloadButtonVariant} isMobile={isMobile} />
      </GridItem>
      <GridItem xs="auto" sm="auto">
        <AbortSigning isMobile={isMobile} />
      </GridItem>
    </Fragment>
  );
};

SendForSigning.propTypes = {
  esigningModule: PropTypes.bool.isRequired,
  signingEnabled: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
};

const mapStateToProps = state => ({
  esigningModule: _get(state, 'questionnaire.company.esigning_module'),
  signingEnabled: templatePropertiesSelector(state).signing_enabled,
});

export default connect(mapStateToProps)(SendForSigning);
