import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import styles from './index.css';

const InfoBox = (props) => {
  const { testId, content } = props;
  return (
    <div className={styles.infoBox} data-test-id={testId}>
      <InfoIcon className={styles.infoBoxIcon} />
      <div className={styles.content}>
        {content}
      </div>
    </div>
  );
};

InfoBox.propTypes = {
  testId: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

export default InfoBox;
