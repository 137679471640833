exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-Tooltip-___index__tooltip___1rxWR {\n  background-color: rgba(0, 0, 0, 0.9) !important;\n  font-size: 0.8rem !important;\n  max-width: 360px !important;\n}\n\n.app-components-Tooltip-___index__triangle___2ybUa {\n  position: absolute;\n  top: -4px;\n  left: calc(50% - 4px);\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 0 4px 4px 4px;\n  border-color: transparent transparent rgba(0, 0, 0, 0.9) transparent;\n}\n", "", {"version":3,"sources":["/workspace/app/components/Tooltip/index.css"],"names":[],"mappings":"AAAA;EACE,gDAA0D;EAC1D,6BAA0B;EAC1B,4BAA2B;CAC7B;;AAEA;EACE,mBAAkB;EAClB,UAAS;EACT,sBAAqB;EACrB,SAAQ;EACR,UAAS;EACT,oBAAmB;EACnB,4BAA2B;EAC3B,qEAA+E;CACjF","file":"index.css","sourcesContent":[".tooltip {\n  background-color: color(var(--c-bw-100) a(90%)) !important;\n  font-size: 12px !important;\n  max-width: 360px !important;\n}\n\n.triangle {\n  position: absolute;\n  top: -4px;\n  left: calc(50% - 4px);\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 0 4px 4px 4px;\n  border-color: transparent transparent color(var(--c-bw-100) a(90%)) transparent;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"tooltip": "app-components-Tooltip-___index__tooltip___1rxWR",
	"triangle": "app-components-Tooltip-___index__triangle___2ybUa"
};