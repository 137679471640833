import assert from 'assert';

import ApiClient from '../ApiClient';

export default class TrashService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  fetchPage(page) {
    assert.ok(page, 'TashService fetchPage page<string> is required');

    return this.apiClient.get(`/deleted_items?page=${page}`);
  }

  deleteItem(id) {
    assert.ok(id, 'TrashService deleteItem id<string> is required');

    return this.apiClient.delete(`/deleted_items/${id}`);
  }

  restoreItem(id) {
    assert.ok(id, 'TrashService restoreItem id<string> is required');

    return this.apiClient.put(`/deleted_items/${id}/restore`);
  }

  deleteAll() {
    return this.apiClient.post('/deleted_items/empty_trash');
  }
}
