import React, { Component } from 'react';
import { Field } from 'formik';

import FieldRow from 'components/form/FieldRow';
import InputText from 'components/form/InputText';
import Label from 'components/form/Label';

import { FormattedMessage } from 'react-intl';
import FormHeader from './FormHeader';
import styles from './Margins.css';

export default class Margins extends Component {
  render() {
    return (
      <div className={styles.component}>
        <FormHeader><FormattedMessage id="document_templates.margins" /></FormHeader>
        <FieldRow>
          <Label htmlFor="margin_top">
            <FormattedMessage id="document_templates.top" />
          </Label>
          <Field
            name="margin_top">
            {({ field, form }) => (
              <InputText {...field} errorMessage={form.errors[field.name]} />
            )}
          </Field>
        </FieldRow>

        <FieldRow>
          <Label htmlFor="margin_right">
            <FormattedMessage id="document_templates.right" />
          </Label>
          <Field
            name="margin_right">
            {({ field, form }) => (
              <InputText {...field} errorMessage={form.errors[field.name]} />
            )}
          </Field>
        </FieldRow>

        <FieldRow>
          <Label htmlFor="margin_bottom">
            <FormattedMessage id="document_templates.bottom" />
          </Label>
          <Field
            name="margin_bottom">
            {({ field, form }) => (
              <InputText {...field} errorMessage={form.errors[field.name]} />
            )}
          </Field>
        </FieldRow>

        <FieldRow>
          <Label htmlFor="margin_left">
            <FormattedMessage id="document_templates.left" />
          </Label>
          <Field
            name="margin_left">
            {({ field, form }) => (
              <InputText {...field} errorMessage={form.errors[field.name]} />
            )}
          </Field>
        </FieldRow>
      </div>
    );
  }
}
