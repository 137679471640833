/* eslint-disable no-param-reassign */
import cloneDeep from 'lodash/cloneDeep';
import _difference from 'lodash/difference';
import ANSWER_STATUSES from '../shared/constants/answerStatuses';
import { CHECKBOX, RADIO } from '../shared/constants/answerKind';

export const copyFlow = flow => cloneDeep(flow);

export const bindQuestions = (flow, questions) => flow.map((flowItem) => {
  const linkedQuestion = questions.find(question => question.id === flowItem.questionId);

  flowItem.question = linkedQuestion;

  if (flowItem.children && flowItem.children.length > 0) {
    flowItem.children = bindQuestions(flowItem.children, questions);
  }
  return flowItem;
});

export const bindAnswers = (flow, answers) => flow.map((flowItem) => {
  const linkedAnswer = answers.find(answer => answer.question_id === flowItem.questionId);
  if (linkedAnswer) {
    flowItem.answer = linkedAnswer;
  }

  if (flowItem.children && flowItem.children.length > 0) {
    flowItem.children = bindAnswers(flowItem.children, answers);
  }
  return flowItem;
});

export const filterDuplicates = flatFlow => flatFlow.filter(flowItem => flowItem.duplicate !== true);

/*
  * Filters flow leaving only those items which user can actually answer
  */
export const filterUnanswerable = (flow, answeredOptionIds) => flow
  .filter((question) => {
    if (!question.dependsOnOptions.length) return true;
    return _difference(question.dependsOnOptions, answeredOptionIds).length === 0;
  })
  .map((question) => {
    // Question depends on answers from other questions.
    if (
      question.dependsOnOptions.length
    && _difference(question.dependsOnOptions, answeredOptionIds).length !== 0) {
      return null;
    }

    // Question is not answered, then children cannot be established
    if (question.answer === undefined || question.answer.status !== ANSWER_STATUSES.ANSWERED) {
      return {
        ...question,
        children: [],
      };
    }
    // Question is answered...

    // Additonal step if question is a checkbox
    if ((question.answer.kind === CHECKBOX || question.answer.kind === RADIO)) {
      // We want to hide children which are not selected by the checkbox
      const selected = question.answer.input_id ? question.answer.input_id.split(';') : [];
      question.children = question.children.filter(child => selected.includes(child.parentOptionId));
    }

    // Check the same for children (if any)
    question.children = question.children ? filterUnanswerable(question.children, answeredOptionIds) : [];
    return question;
  });

/*
  * Leaves only flow items which are answered or skipped
  * If second argument - ignoredQuestion is provided then flow will leave it unchanged
  * this can be used to include currentQuestion in the flow, even though it doesn't meet description
  */
export const filterUnvisited = (flow, ignoredQuestion = {}) => copyFlow(flow)
  .filter(
    question => question.questionId === ignoredQuestion.id
        || (question.answer !== undefined
          && (question.answer.status === ANSWER_STATUSES.ANSWERED
            || question.answer.status === ANSWER_STATUSES.SKIPPED
            || question.answer.status === ANSWER_STATUSES.DRAFT)),
  )
  .map((question) => {
    question.children = question.children ? filterUnvisited(question.children, ignoredQuestion) : [];
    return question;
  });

/*
 * Simple tree search
 */
export const findInFlow = (flow, predicate) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const item of flow) {
    // Check item
    if (predicate(item)) {
      return item;
    }

    // Check it's children if any
    if (item.children) {
      const childResult = findInFlow(item.children, predicate);
      if (childResult) {
        return childResult;
      }
    }
  }
  return undefined;
};

export const markDuplicates = (flow, parentFlow) => flow.map((flowItem) => {
  const foundFlowItem = findInFlow(
    parentFlow || flow,
    searchFlowItem => searchFlowItem.questionId === flowItem.questionId,
  );

  // If there was a flow item with the same id before that means it's a duplicate
  if (
    foundFlowItem.questionIndex !== flowItem.questionIndex
      || foundFlowItem.parentOptionId !== flowItem.parentOptionId
  ) {
    flowItem.duplicate = true;
  }

  flowItem.children = flowItem.children ? markDuplicates(flowItem.children, flow) : [];
  return flowItem;
});

export const findUnfinishedQuestion = (flow) => {
  const nextFlowItem = findInFlow(
    flow,
    flowItem => !flowItem.duplicate
      && (flowItem.answer === undefined
        || flowItem.answer.status === ANSWER_STATUSES.DRAFT
        || flowItem.answer.status === ANSWER_STATUSES.SKIPPED),
  );

  return nextFlowItem && nextFlowItem.question;
};

export const findNextQuestion = (currentQuestion, flow) => {
  const unfinishedQuestion = findUnfinishedQuestion(flow);

  // If there is unfinished question -> show "Continue" button
  // so we need to actually find a sequentially next item
  if (unfinishedQuestion) {
    if (currentQuestion !== undefined && currentQuestion.id !== undefined) {
      // Get questionIndex of current question
      const currentQuestionFlowItem = findInFlow(flow, flowItem => flowItem.questionId === currentQuestion.id);
      if (currentQuestionFlowItem !== undefined) {
        const currentQuestionIndex = currentQuestionFlowItem.questionIndex;
        // Find next sequentially item
        const nextFlowItem = findInFlow(
          flow,
          flowItem => flowItem.questionIndex > currentQuestionIndex && !flowItem.duplicate,
        );
        return nextFlowItem && nextFlowItem.question;
      }
      console.error("This shouldn't be possible", currentQuestion, flow);
    }
  }
  return unfinishedQuestion;
};
