import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import InfoIcon from '@material-ui/icons/Info';
import SettingsIcon from '@material-ui/icons/Settings';
import Button, { SECONDARY } from 'components/Button';
import IconButton from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import PublishTemplate from 'shared/PublishUnpublish/PublishTemplate';
import { getTemplateDetailEditPath, getTemplatesListPath, getTemplateDetailDocumentPreviewPath } from 'MainApp/routesPaths';
import { PreviewIcon, StyledFlexCenter } from './styled-components';

const Buttons = ({
  previewMode,
  editorIsSaving,
  templateVersionId,
  templateVersionChangeNote,
  onClickSettings,
  onClickTemplateDetails,
  templateId,
  push,
}) => {
  const SettingsAndInfoButtons = () => (
    <>
      <Tooltip title={<FormattedMessage id="api.panel.questionnaire_templates.actions.menu_template_settings" />}>
        <IconButton
          onClick={onClickSettings}
          testId="TopToolbar-templateSettings">
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={<FormattedMessage id="api.panel.questionnaire_templates.actions.menu_template_information" />}>
        <IconButton
          onClick={onClickTemplateDetails}
          testId="TopToolbar-templateInfo">
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  if (previewMode) {
    return (
      <StyledFlexCenter>
        <SettingsAndInfoButtons />
        <PublishTemplate
          templateVersionId={templateVersionId}
          templateVersionChangeNote={templateVersionChangeNote}
          redirectTo={() => push(getTemplatesListPath())} />
        <Button testId="edit-draft" onClick={() => push(getTemplateDetailEditPath(templateId))}>
          <FormattedMessage id="visualization.top_toolbar.edit_draft" />
        </Button>
      </StyledFlexCenter>
    );
  }
  return (
    <StyledFlexCenter>
      <SettingsAndInfoButtons />
      <Button
        variant={SECONDARY}
        testId="questionnaire-preview"
        onClick={() => push(getTemplateDetailDocumentPreviewPath(templateId))}
        disabled={editorIsSaving}
        glyph={PreviewIcon}>
        <FormattedMessage id="visualization.top_toolbar.questionnaire_preview" />
      </Button>
      <PublishTemplate
        templateVersionId={templateVersionId}
        templateVersionChangeNote={templateVersionChangeNote}
        redirectTo={() => push(getTemplatesListPath())} />
    </StyledFlexCenter>
  );
};

Buttons.propTypes = {
  previewMode: PropTypes.bool.isRequired,
  templateVersionId: PropTypes.string.isRequired,
  templateVersionChangeNote: PropTypes.string,
  editorIsSaving: PropTypes.bool,
  onClickSettings: PropTypes.func.isRequired,
  onClickTemplateDetails: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
};

export default Buttons;
