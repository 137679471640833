import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import isEqual from 'lodash/isEqual';
import getFlowFromHtml from 'shared/utils/getFlowFromHtml';
import { optionsSelector, questionsSelector } from 'Visualization/Editor-v2/Questions/QuestionsRedux';
import removeUnusedQuestionTagsFromDom from 'Visualization/helpers/removeUnusedQuestionTagsFromDom';
import { TEMPLATE_EDITOR_ID } from 'appRoot/constants';
import { Creators, Types } from './actions';
import { selectFlow } from './selectors';


export default function createSagas() {
  function* regenerateFlowSaga() {
    yield call(delay, 100);

    const flow = yield select(selectFlow);
    const questions = yield select(questionsSelector);
    const options = yield select(optionsSelector);
    removeUnusedQuestionTagsFromDom(options);
    const html = tinymce.get(TEMPLATE_EDITOR_ID).getContent(); // eslint-disable-line

    const newFlow = getFlowFromHtml(html, questions);

    if (!isEqual(flow, newFlow)) {
      yield put(Creators.setFlow(newFlow));
    }
  }

  function* root() {
    yield all([
      takeLatest(Types.REGENERATE_FLOW, regenerateFlowSaga),
    ]);
  }

  return { flowSagas: root };
}
