import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';
import { Types } from './actions';


export const initialState = {
  error: null,
  inProgress: false,
  finished: false,
  uploadedFiles: {},
};

const setUploadRequest = state => update(state, {
  error: { $set: null },
  inProgress: { $set: true },
  finished: { $set: false },
});

const setUploadError = (state, { error }) => update(state, {
  error: { $set: error },
  inProgress: { $set: false },
  finished: { $set: false },
});

const setUploadSuccess = (state, { oldFileName, newFileName }) => update(state, {
  error: { $set: null },
  inProgress: { $set: false },
  finished: { $set: true },
  uploadedFiles: { $set: { [oldFileName]: newFileName } },
});

const setConvertRequest = state => update(state, {
  error: { $set: null },
  inProgress: { $set: true },
  finished: { $set: false },
});

const setConvertError = (state, { error }) => update(state, {
  error: { $set: error },
  inProgress: { $set: false },
  finished: { $set: false },
});

const setConvertSuccess = (state, { oldFileName, newFileName }) => update(state, {
  error: { $set: null },
  inProgress: { $set: false },
  finished: { $set: true },
  uploadedFiles: { $set: { [oldFileName]: newFileName } },
});

export default createReducer(initialState, {
  [Types.UPLOAD_REQUEST]: setUploadRequest,
  [Types.UPLOAD_FAILURE]: setUploadError,
  [Types.UPLOAD_SUCCESS]: setUploadSuccess,
  [Types.CONVERT_REQUEST]: setConvertRequest,
  [Types.CONVERT_FAILURE]: setConvertError,
  [Types.CONVERT_SUCCESS]: setConvertSuccess,
});
