exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-pages-ForgotPasswordConfirmation-___styles__component___2yyBU {\n  max-width: 550px;\n  width: 100%;\n  margin: 0 auto;\n  flex: 1 0 auto;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n\n.app-pages-ForgotPasswordConfirmation-___styles__link___1QZhK {\n  color: #2d866e;\n  color: var(--c-brand);\n  font-size: 1rem;\n}\n\n.app-pages-ForgotPasswordConfirmation-___styles__icon___2NwGp {\n  margin-bottom: 24px;\n}\n\n.app-pages-ForgotPasswordConfirmation-___styles__description___27YNM {\n  margin-bottom: 20px;\n  color: #565656;\n  color: var(--c-bw-60);\n}\n", "", {"version":3,"sources":["/workspace/app/pages/ForgotPasswordConfirmation/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAgB;EAChB,YAAW;EACX,eAAc;EACd,eAAc;EACd,cAAa;EACb,kBAAiB;EACjB,oBAAmB;EACnB,wBAAuB;EACvB,mBAAkB;CACpB;;AAEA;EACE,eAAqB;EAArB,sBAAqB;EACrB,gBAAe;CACjB;;AAEA;EACE,oBAAmB;CACrB;;AAEA;EACE,oBAAmB;EACnB,eAAqB;EAArB,sBAAqB;CACvB","file":"styles.css","sourcesContent":[".component {\n  max-width: 550px;\n  width: 100%;\n  margin: 0 auto;\n  flex: 1 0 auto;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n\n.link {\n  color: var(--c-brand);\n  font-size: 1rem;\n}\n\n.icon {\n  margin-bottom: 24px;\n}\n\n.description {\n  margin-bottom: 20px;\n  color: var(--c-bw-60);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-pages-ForgotPasswordConfirmation-___styles__component___2yyBU",
	"link": "app-pages-ForgotPasswordConfirmation-___styles__link___1QZhK",
	"icon": "app-pages-ForgotPasswordConfirmation-___styles__icon___2NwGp",
	"description": "app-pages-ForgotPasswordConfirmation-___styles__description___27YNM"
};