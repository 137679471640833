import cheerio from 'cheerio';
import { NODE_BLOCKS } from 'Visualization/helpers/constants';
import NodesHelper from '../LogicsManager/LogicsTypes/TriggersFn/utils/nodesHelper';

export default function forbidUserToPasteInInlineQuestion(content) {
  if (pastedContentHasOnlyInlineNodes(content)) {
    return false;
  }

  const { leftNodeNearestInlineQuestion, rightNodeNearestInlineQuestion } = new NodesHelper();
  const anyInlineQuestionsInTree = leftNodeNearestInlineQuestion || rightNodeNearestInlineQuestion;

  return anyInlineQuestionsInTree;
}

function pastedContentHasOnlyInlineNodes(content) {
  const $$ = cheerio.load(content);
  return !$$([...NODE_BLOCKS, 'BR'].join(',')).length;
}
