import { createReducer } from 'reduxsauce';
import _get from 'lodash/get';
import { Types } from './actions';

export const INITIAL_STATE = {
  redirectOnErrorPath: '',
  errorType: '',
};

const setError = (state, { error, redirectOnErrorPath }) => ({
  ...state,
  errorType: _get(error, 'data.message', ''),
  redirectOnErrorPath,
});

export default createReducer(INITIAL_STATE, {
  [Types.REDIRECT_ERROR]: setError,
  [Types.UNAUTHORIZED]: setError,
});
