import assert from 'assert';
import queryString from 'qs';

import ApiClient from '../ApiClient';

export default class UserService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  changeLanguage(language) {
    assert.ok(language, 'language<string> is required');

    return this.apiClient.request('/settings', {
      method: 'patch',
      data: { user: { language } },
    });
  }

  fetchProfile() {
    return this.apiClient.request('/me', { method: 'get' });
  }

  savePersonalInformation({ firstName, lastName, email } = {}) {
    assert.ok(firstName, 'firstName<string> is required');
    assert.ok(lastName, 'lastName<string> is required');
    assert.ok(email, 'email<string> is required');

    return this.apiClient.request('/settings', {
      method: 'patch',
      data: {
        user: {
          first_name: firstName,
          last_name: lastName,
          email,
        },
      },
    });
  }

  savePassword({ currentPassword, newPassword, confirmPassword } = {}) {
    assert.ok(currentPassword, 'currentPassword<string> is required');
    assert.ok(newPassword, 'newPassword<string> is required');
    assert.ok(confirmPassword, 'confirmPassword<string> is required');

    return this.apiClient.request('/settings', {
      method: 'patch',
      data: {
        user: {
          current_password: currentPassword,
          password: newPassword,
          password_confirmation: confirmPassword,
        },
      },
    });
  }

  fetchAllUsers() {
    return this.apiClient.request('/users', { method: 'get' });
  }

  fetchUser(id) {
    assert.ok(id, 'id<string> is required');

    return this.apiClient.request(`/users/${id}`, { method: 'get' });
  }

  updateUser(id, data) {
    assert.ok(id, 'id<string> is required');
    assert.ok(data, 'data<object> is required');

    return this.apiClient.request(`/users/${id}`, { method: 'patch', data: { user: data } });
  }

  reinviteUser(id) {
    assert.ok(id, 'id<string> is required');

    return this.apiClient.request(`/users/invite_employees/${id}/reinvite`, { method: 'post' });
  }

  fetchUsersByQueryParams({ page, q } = {}) {
    if (!(page || q)) {
      return this.fetchAllUsers();
    }

    return this.apiClient.request(`/users?${queryString.stringify({ page, q })}`, { method: 'get' });
  }

  inviteUsersByEmail(emails, role) {
    assert.ok(Array.isArray(emails), 'emails<array> are required');
    assert.ok(role, 'role<string> is required');

    return this.apiClient.request('/users/invite_employees', {
      method: 'post',
      data: {
        user: {
          emails,
          role,
        },
      },
    });
  }
}
