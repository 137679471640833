import React from 'react';

export function EmptyBucket(props) {
  return (
    <svg
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 262.07 258.281"
      height={256}
      width={260}
      {...props}>
      <g transform="translate(1 1)">
        <ellipse
          ry={37.162}
          rx={43.733}
          cy={165.218}
          cx={130.548}
          fill="#3E94F6"
          opacity={0.25}
          vectorEffect="non-scaling-stroke" />
        <path
          d="M0 128.141C0 57.389 58.222 0 130.034 0 201.855 0 260.07 57.389 260.07 128.141c0 70.77-58.216 128.141-130.036 128.141C58.222 256.281 0 198.91 0 128.141"
          fill="#E1E8EF"
          vectorEffect="non-scaling-stroke" />
        <path
          d="M191.091 153.724c-2.832-20.709-27.687-34.173-55.513-30.072-27.826 4.099-48.088 24.21-45.256 44.92 2.832 20.708 27.686 34.171 55.514 30.072 27.826-4.1 48.087-24.211 45.255-44.92z"
          fill="#D0DAE2"
          vectorEffect="non-scaling-stroke" />
        <path
          d="M172.695 171.29c.67-1.609 1.24-3.27 1.725-4.965l13.297-38.269h-19.13c-8.127-12.284-21.962-20.373-37.665-20.373s-29.538 8.089-37.666 20.373H74.129l13.06 37.444h.002c5.203 19.514 22.805 33.877 43.731 33.877 18.797.001 34.916-11.586 41.773-28.087z"
          fill="#FFF"
          vectorEffect="non-scaling-stroke" />
        <g fill="#9EABBA">
          <path
            d="M149.738 156.031c-4.355-3.978-16.416-3.5-18.815 2.591l1.008 33.71c7.058 2.532 12.368.893 14.563-2.44l3.244-33.861zM109.683 186.156l-.197.021c3.002 6.634 9.877 7.438 14.691 5.654l-1.009-33.209c0-4.091-4.854-6.633-7.323-7.348-2.842-.824-8.517.923-8.517 4.104l2.355 30.778zM156.586 155.604l-2.193 30.129c7.548-1.493 9.439-6.813 10.978-11.021 0 0 4.118-19.736 4.118-23.641-.001-7.082-12.501-1.338-12.903 4.533zM98.155 149.733c-2.5-7.202-9.76-5.702-9.76-3.418l4.368 22.643c.726 7.689 8.769 14.602 8.769 9.961"
            opacity={0.08}
            vectorEffect="non-scaling-stroke" />
        </g>
        <ellipse
          ry={50.497}
          rx={65.969}
          cy={102.839}
          cx={130.549}
          fill="#FFF"
          vectorEffect="non-scaling-stroke" />
        <ellipse
          ry={43.477}
          rx={58.474}
          cy={102.839}
          cx={130.548}
          fill="#E1E8EF"
          vectorEffect="non-scaling-stroke" />
        <defs>
          <ellipse
            ry={43.477}
            rx={56.798}
            cy={102.839}
            cx={130.548}
            id="trash_svg__a"
            vectorEffect="non-scaling-stroke" />
        </defs>
        <clipPath id="trash_svg__b" vectorEffect="non-scaling-stroke">
          <use
            overflow="visible"
            xlinkHref="#trash_svg__a"
            vectorEffect="non-scaling-stroke" />
        </clipPath>
        <ellipse
          ry={37.681}
          rx={40.801}
          cy={156.002}
          cx={130.548}
          fill="#D0DAE2"
          clipPath="url(#trash_svg__b)"
          vectorEffect="non-scaling-stroke" />
        <path
          d="M168.155 172.471l8.167-30.272s2.788-7.028 4.811-6.848-10.645 37.12-10.645 37.12l-2.333 2.394v-2.394z"
          fill="#9EABBA"
          opacity={0.08}
          vectorEffect="non-scaling-stroke" />
      </g>
    </svg>
  );
}
