export default (
  currentQuestion,
  userQuestionnaireId,
  idToReplace,
  value = '',
) => ({
  question_id: currentQuestion.id,
  user_questionnaire_id: userQuestionnaireId,
  kind: currentQuestion.kind,
  input_id: idToReplace,
  details: {
    question_name: currentQuestion.name,
    // name: node.options[node.selectedIndex].text,
    value,
  },
});
