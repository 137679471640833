/**
 * Utility to ease handling localStorage.
 */

export default class LocalStore {
  constructor(key, localStorage) {
    this._key = key;
    this._localStorage = localStorage;
  }

  /**
   * Returns the stored value for this store.
   *
   * @param {(Object|Array|string|number)} [defaultValue] - Value returned if the object can't be retrieved.
   * @return {?(Object|Array|string|number)} Value stored or null
   */
  load() {
    const defaultValue = null;

    try {
      const value = this._localStorage.getItem(this._key);

      if (value) {
        try {
          return Promise.resolve(JSON.parse(value));
        } catch (e) {
          console.warn('This value is not JSON.parse-able', e);
        }
      }

      return Promise.resolve(defaultValue);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Error getting access to storage. Are cookies blocked?', e);
      return Promise.resolve(defaultValue);
    }
  }

  /**
   * Change the value stored for this key.
   *
   * @param {(Object|Array.|string|number)} [value] - New value, will replace any previously stored.
   */
  save(value) {
    try {
      this._localStorage.setItem(this._key, JSON.stringify(value));

      return Promise.resolve();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Error getting access to storage. Are cookies blocked?', e);
      return Promise.reject(e);
    }
  }
}
