import React from 'react';
import GridContainer from 'components/Grid/Container';

const ButtonCollection = ({ children, ...props }) => (
  <GridContainer justify="flex-end" alignItems="center" direction="row" spacing={8} {...props}>
    {children}
  </GridContainer>
);

export default ButtonCollection;
