import assert from 'assert';
import StackdriverErrorReporter from 'stackdriver-errors-js';

export default class LoggingService {
  constructor({ isEnabled, apiKey, env } = {}) {
    this._reporter = new StackdriverErrorReporter();
    this._isEnabled = isEnabled;

    try {
      if (this._isEnabled) {
        this._reporter.start({
          key: apiKey,
          projectId: env,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  log(error) {
    assert.ok(error, 'error<Error> is required');
    if (this._isEnabled) {
      const reportedError = !error.stack && error.data ? new Error(`${error.data.message} at ${error.url}`) : error;

      this._reporter.report(reportedError);
    }
  }
}
