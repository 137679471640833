import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';
import { FAILED, SAVED, SAVING } from '../../shared/constants/saveStatus';

/* ------------- Initial state ------------- */
export const INITIAL_STATE = {
  isSaving: undefined,
  editorInitialized: false,
  editorPreviewInitialized: false,
  templateVersion: {
    id: '',
    name: '',
    version: 0,
    status: '',
    wysiwyg_html: '',
    wysiwyg_html_used_answers: [],
    change_note: '',
    folder_breadcrumbs: '',
    flow_timestamp: 0,
    questions: [],
  },
  template: {
    id: '',
    name: '',
    status: '',
    company_id: null,
    created_at: '',
    updated_at: '',
    user: {},
    has_drafts: false,
    questionnaire_template_versions: [],
    sharing_active: false,
    sharing_slug: '',
  },
  loaded: false,
  error: false,
  savingStatus: '',
  html: '',
  stylesheet: '',
};

/* ------------- Types and Action Creators ------------- */
export const { Types, Creators } = createActions({
  initializeEditor: ['templateId'], // saga
  initializeEditorPreview: ['templateId', 'version'], // saga

  saveTemplateName: ['name'], // saga
  saveTemplateNameSuccess: null,
  saveTemplateNameFailure: ['error'],

  toggleSharingTemplateRequest: ['sharingActive'],
  toggleSharingTemplateSuccess: ['sharingActive'],
  toggleSharingTemplateFailure: ['error'],

  saveEditorContent: ['html'],
  saveEditorContentSuccess: ['params'],
  saveEditorContentFailure: ['error'],
  fetchQuestionnaireTemplateVersion: null, // saga
  saveQuestionnaireTemplateName: ['name'],
  saveQuestionnaireTemplateNameSuccess: ['params'],
  saveQuestionnaireTemplateNameFailure: ['error'],

  initializeEditorSuccess: null,
  setTemplateData: ['data'],
  setTemplateVersionData: ['data'],
  setStylesheet: ['stylesheet'],
  editorInitialized: null,
  editorUnmount: null,
  editorPreviewInitialized: null,
  initializeEditorFailure: ['error'],
  editorPreviewUnmount: null,
});

/* ------------- Reducers ------------- */

const setSavingEditorStatus = (state, status) => update(state, { savingStatus: { $set: status } });

const setStylesheet = (state, { stylesheet }) => update(state, { stylesheet: { $set: stylesheet } });

const setTemplateData = (state, { data }) => update(state, { template: { $set: data } });

const setSharingTemplate = (state, { sharingActive }) => update(state, { template: { $merge: { sharing_active: sharingActive } } });

const setTemplateVersionData = (state, { data }) => update(state, { templateVersion: { $set: data } });

const setEditorInitialized = (state, initialized) => update(state, { editorInitialized: { $set: initialized } });

const setEditorFailed = (state, { error }) => update(state, { error: { $set: (error.status || true) } });

const setEditorPreviewInitialized = (state, initialized) => update(state, { editorPreviewInitialized: { $set: initialized } });

const clearData = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
export default createReducer(INITIAL_STATE, {
  [Types.SET_STYLESHEET]: setStylesheet,
  [Types.SET_TEMPLATE_DATA]: setTemplateData,
  [Types.EDITOR_INITIALIZED]: state => setEditorInitialized(state, true),
  [Types.EDITOR_UNMOUNT]: state => setEditorInitialized(state, false),
  [Types.EDITOR_PREVIEW_INITIALIZED]: state => setEditorPreviewInitialized(state, true),
  [Types.INITIALIZE_EDITOR_FAILURE]: setEditorFailed,
  [Types.EDITOR_PREVIEW_UNMOUNT]: state => setEditorPreviewInitialized(state, false),
  [Types.SET_TEMPLATE_VERSION_DATA]: setTemplateVersionData,
  [Types.INITIALIZE_EDITOR_PREVIEW]: clearData,
  [Types.INITIALIZE_EDITOR]: clearData,
  [Types.SAVE_EDITOR_CONTENT]: state => setSavingEditorStatus(state, SAVING),
  [Types.SAVE_EDITOR_CONTENT_SUCCESS]: state => setSavingEditorStatus(state, SAVED),
  [Types.SAVE_EDITOR_CONTENT_FAILURE]: state => setSavingEditorStatus(state, FAILED),
  [Types.TOGGLE_SHARING_TEMPLATE_SUCCESS]: setSharingTemplate,
  [Types.SAVE_QUESTIONNAIRE_TEMPLATE_NAME]: state => setSavingEditorStatus(state, SAVING),
  [Types.SAVE_QUESTIONNAIRE_TEMPLATE_NAME_SUCCESS]: state => setSavingEditorStatus(state, SAVED),
  [Types.SAVE_QUESTIONNAIRE_TEMPLATE_NAME_FAILURE]: state => setSavingEditorStatus(state, FAILED),
  [Types.SAVE_TEMPLATE_NAME]: state => setSavingEditorStatus(state, SAVING),
  [Types.SAVE_TEMPLATE_NAME_SUCCESS]: state => setSavingEditorStatus(state, SAVED),
  [Types.SAVE_TEMPLATE_NAME_FAILURE]: state => setSavingEditorStatus(state, FAILED),
});
