exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "button.app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-___action__component___2GgQ9 {\n  padding-left: 0 !important;\n  padding-right: 0 !important;\n}\n", "", {"version":3,"sources":["/workspace/app/bundles/Visualization/Editor-v2/LogicsManager/LogicsTypes/action.css"],"names":[],"mappings":"AAAA;EACE,2BAA0B;EAC1B,4BAA2B;CAC7B","file":"action.css","sourcesContent":["button.component {\n  padding-left: 0 !important;\n  padding-right: 0 !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-___action__component___2GgQ9"
};