import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EllipsisMenu from '../EllipsisMenu/index';

const FolderMenu = ({ domEl, isOpen, handleClose, renameFolder, deleteFolder, testIdPrefix }) => {
  const menuItems = [
    {
      dataTestId: testIdPrefix ? `${testIdPrefix}RenameFolder` : 'FolderItem-renameFolder',
      onClick: renameFolder,
      divider: true,
      icon: <EditIcon />,
      text: <FormattedMessage id="panel.folders.folder.rename" />,
    },
    {
      dataTestId: testIdPrefix ? `${testIdPrefix}DeleteFolder` : 'FolderItem-deleteFolder',
      onClick: deleteFolder,
      icon: <DeleteIcon />,
      text: <FormattedMessage id="panel.folders.folder.delete" />,
    },
  ];
  return <EllipsisMenu domEl={domEl} isOpen={isOpen} handleClose={handleClose} menuItems={menuItems} />;
};

FolderMenu.propTypes = {
  domEl: PropTypes.instanceOf(Element),
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  renameFolder: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired,
  testIdPrefix: PropTypes.string,
};

export default FolderMenu;
