import React from 'react';

export default function NameTheContract(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="186.098px"
      height="182px"
      viewBox="0 0 186.098 182"
      enableBackground="new 0 0 186.098 182"
      xmlSpace="preserve"
      {...props}>
      <g opacity="0.15">
        <g>
          <path
            fill="currentColor"
            d="M145.278,145.346c0,4.791-3.886,8.678-8.677,8.678h-84.6c-4.792,0-8.677-3.887-8.677-8.678V34.548 c0-4.792,3.884-8.677,8.677-8.677h84.6c4.791,0,8.677,3.884,8.677,8.677V145.346z" />
        </g>
      </g>
      <g opacity="0.08">
        <g>
          <ellipse fill="currentColor" cx="93.026" cy="158.371" rx="93.026" ry="17.061" />
        </g>
      </g>
      <g>
        <path
          fill="none"
          stroke="#A7BBD1"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeDasharray="4,4"
          d="M72.391,104.453" />
      </g>
      <g>
        <path
          fill="none"
          stroke="#A7BBD1"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeDasharray="4,4"
          d="M99.61,100.088" />
      </g>
      <g>
        <g>
          <path fill="#FFFFFF" d="M128.654,109.695" />
        </g>
        <g>
          <path
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M128.654,109.695" />
        </g>
      </g>
      <g>
        <g>
          <line fill="none" x1="118.58" y1="73.875" x2="71.033" y2="73.875" />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M118.58,75.269H71.033c-0.771,0-1.395-0.624-1.395-1.395s0.624-1.395,1.395-1.395h47.546 c0.77,0,1.395,0.624,1.395,1.395S119.349,75.269,118.58,75.269z" />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M130.699,135.023l-14.077,9.609L92.298,109l14.077-9.604L130.699,135.023z M129.627,156.393l6.279-4.285 c2.424-1.654,3.05-4.971,1.396-7.404l-4.105-6.014l-14.078,9.607l4.786,7.018C125.193,157.193,127.745,157.676,129.627,156.393z M90.895,90.06c-0.929-0.399-1.964,0.312-1.91,1.326l0.711,13.8l14.075-9.6L90.895,90.06z" />
        </g>
      </g>
      <g>
        <g>
          <line fill="none" x1="104.087" y1="82.883" x2="85.526" y2="82.883" />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M104.087,84.278H85.526c-0.771,0-1.395-0.624-1.395-1.395s0.624-1.395,1.395-1.395h18.561 c0.771,0,1.396,0.624,1.396,1.395S104.858,84.278,104.087,84.278z" />
        </g>
      </g>
    </svg>
  );
}
