import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { error } from 'react-notification-system-redux';
import { injectIntl, intlShape } from 'react-intl';
import proptypes from 'utils/proptypes';
import { ICONS_URLS } from 'Visualization/helpers/constants';
import { Creators as FlowActions } from 'containers/Flow/actions';
import { Creators as LogicsManagerActions } from './LogicsManager/LogicsManagerRedux';
import { optionsSelector } from './Questions/QuestionsRedux';
import { Creators as EditorActions } from './reducer';
import preventDelete from './utils/preventDelete';
import enterInsideQuestion from './utils/enterInsideQuestion';
import stickyToolbar from './utils/stickyToolbar';
import pageBreak from './utils/pageBreak';
import tableBordersPlugin from './utils/tableBordersPlugin';

const setTestId = testId => (e) => {
  e.control.$el[0].setAttribute('data-test-id', testId);
};

export default (EditorComponent) => {
  class EditorComponentWithSetup extends PureComponent {
    static propTypes = {
      options: proptypes.optionsScheme,
      questions: proptypes.questionsScheme,
      openLogicsManager: PropTypes.func.isRequired,
      templateVersion: proptypes.templateVersionScheme,
      intl: intlShape.isRequired,
    };

    setupEditor = (editor) => {
      const { intl } = this.props;
      /* eslint-disable */
      tinymce.PluginManager.add('preventdelete', preventDelete.call({}));
      tinymce.PluginManager.add('enterinsidequestion', enterInsideQuestion);
      tinymce.PluginManager.add('stickytoolbar', stickyToolbar);
      tinymce.PluginManager.add('tableborders', tableBordersPlugin);
      /* eslint-enable */

      editor.addButton('multipleChoiceQuestion', {
        tooltip: intl.formatMessage({ id: 'visualization.editor.choice' }),
        image: ICONS_URLS.choiceShowIfType,
        onPostRender: setTestId('EditorToolbar-multipleChoiceButton'),
        onClick: () => {
          this.props.openLogicsManager('choiceShowIfType', {});
        },
      });

      editor.addButton('textInputQuestion', {
        tooltip: intl.formatMessage({ id: 'visualization.editor.text_input' }),
        image: ICONS_URLS.inputReplaceType,
        onPostRender: setTestId('EditorToolbar-textInputButton'),
        onClick: () => {
          this.props.openLogicsManager('inputReplaceType', {});
        },
      });

      editor.addButton('showIf', {
        tooltip: intl.formatMessage({ id: 'visualization.editor.reuse_question' }),
        image: ICONS_URLS.anchorType,
        onPostRender: setTestId('EditorToolbar-showIfButton'),
        onClick: () => {
          this.props.openLogicsManager('anchorType', {});
        },
      });

      editor.addButton('pageBreak', {
        tooltip: 'Page break',
        icon: 'pagebreak',
        onPostRender: setTestId('EditorToolbar-pageBreakButton'),
        onClick: () => pageBreak(editor),
      });

      // This prevents the blur event from hiding the toolbar
      editor.on('blur', () => false);
      editor.on('init', () => {
        const { className } = editor.bodyElement;
        // eslint-disable-next-line
        editor.bodyElement.className = `${className} __document_template_dynamic_stylesheet editor__question-wrapper`;
      });
    };

    render() {
      return <EditorComponent {...this.props} setupEditor={this.setupEditor} />;
    }
  }

  const mapStateToProps = state => ({
    options: optionsSelector(state),
    questions: state.questions.questions,
    stylesheet: state.editor.stylesheet,
    initialized: state.editor.initialized,
    templateVersion: state.editor.templateVersion,
    documentVersion: state.editor.documentVersion || { wysiwyg_html: '' },
  });

  const mapDispatchToProps = dispatch => ({
    openLogicsManager: (kind, options) => dispatch(LogicsManagerActions.openLogicsManager(kind, options)),
    saveEditorContent: html => dispatch(EditorActions.saveEditorContent(html)),
    regenerateFlow: templateEditorId => dispatch(FlowActions.regenerateFlow(templateEditorId)),
    displayError: msg => dispatch(error(msg)),
  });

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
  )(EditorComponentWithSetup);
};
