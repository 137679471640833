import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PREVIEW, EDIT, WHITE_LABEL } from 'shared/constants/questionnaireModes';
import StyledTopToolbar from 'shared/TopToolbar/StyledTopToolbar';
import MobileView from 'components/MobileView';
import {
  SENT_FOR_SIGNING,
  SIGNED,
  COMPLETED,
  EXPIRED,
  REJECTED,
  ERROR,
} from 'shared/constants/questionnaireStatus';
import SignDocument from 'containers/SignDocument'; // eslint-disable-line import/no-named-as-default
import GridContainer from 'components/Grid/Container';
import GridItem from 'components/Grid/Item';
import VersionPicker from 'components/VersionPicker';
import { Creators as documentDetailsActions } from 'pages/DocumentDetails/actions';
import { templatePropertyEditDocumentEnabledSelector } from 'ducks/questionnaire/selectors';

import {
  selectDocumentId,
  selectIsCurrentVersionLatest,
  selectLatestDocumentVersion,
  selectDocumentHasVersions,
} from 'pages/DocumentDetails/selectors';
import SaveNotificationContainer from './SaveNotificationContainer';

import DocumentName from './DocumentName';
import WhiteLabelLogo from './WhiteLabelLogo';
import Status from './Status';
import ButtonCollection from './endStates/ButtonCollection';
import Completed from './endStates/Completed';
import Edit from './endStates/Edit';
import Signed from './endStates/Signed';
import Expired from './endStates/Expired';
import Rejected from './endStates/Rejected';
import ErrorState from './endStates/ErrorState';
import SentForSigning from './endStates/SentForSigning';
import PreviewCompleted from './endStates/PreviewCompleted';
import PreviousVersion from './endStates/PreviousVersion';

import styles from './TopToolbar.css';


export class TopToolbarComponent extends Component {
  static propTypes = {
    finished: PropTypes.bool.isRequired,
    questionnaireMode: PropTypes.string.isRequired,
    questionnaireStatus: PropTypes.string.isRequired,
    documentVersions: PropTypes.arrayOf(PropTypes.shape({ revision: PropTypes.number })).isRequired,
    isCurrentVersionLatest: PropTypes.bool.isRequired,
    documentHasVersions: PropTypes.bool,
  };

  getStatus = () => {
    const { currentVersion, documentHasVersions, questionnaireStatus } = this.props;
    if (!documentHasVersions) {
      return questionnaireStatus;
    }
    if (currentVersion.completed === false) {
      return 'draft';
    }
    return questionnaireStatus;
  }

  onVersionChange = (revision) => {
    const { history, match, documentId, onVersionChange, questionnaireMode } = this.props;
    onVersionChange(revision);

    if (match.url.endsWith('/edit') && questionnaireMode !== WHITE_LABEL) {
      history.push(`/documents/${documentId}`);
    }
  }

  renderStatus = () => {
    const {
      finished,
      questionnaireMode,
      documentVersions,
      currentVersion,
      isEditEnabled,
    } = this.props;
    const showVersionPicker = isEditEnabled && questionnaireMode !== PREVIEW;

    if (!finished) {
      return null;
    }

    return (
      <div className={styles.documentStatusWrapper}>
        { showVersionPicker
          ? (
            <VersionPicker
              versions={documentVersions}
              currentVersion={currentVersion}
              onVersionChange={this.onVersionChange} />
          )
          : null }
        <Status className={styles.documentStatus} status={this.getStatus()} />
      </div>
    );
  };

  isSignDocumentDisabled = () => (this.props.questionnaireMode === WHITE_LABEL
    ? false
    : (this.props.questionnaireMode === EDIT || !_get(this.props.currentVersion, 'completed', false)));

  isDownloadDisabled = () => (this.props.questionnaireMode === WHITE_LABEL ? false : !_get(this.props.currentVersion, 'completed', false));

  goToLatestVersion = () => this.props.onVersionChange(_get(this.props.latestVersion, 'revision', 1))

  endStateActionButtons(isMobile) {
    const { questionnaireMode, questionnaireStatus, isCurrentVersionLatest } = this.props;
    if (!isCurrentVersionLatest && questionnaireMode !== PREVIEW) {
      return <PreviousVersion isMobile={isMobile} goToLatestVersion={this.goToLatestVersion} />;
    }
    if (questionnaireMode === EDIT) {
      return <Edit isMobile={isMobile} disabled={this.isDownloadDisabled()} />;
    } if (questionnaireMode === PREVIEW) {
      return <PreviewCompleted isMobile={isMobile} disabled={this.isDownloadDisabled()} />;
    } if (questionnaireStatus === COMPLETED) {
      return <Completed isMobile={isMobile} disabled={this.isDownloadDisabled()} />;
    } if (questionnaireStatus === SENT_FOR_SIGNING) {
      return <SentForSigning isMobile={isMobile} />;
    } if (questionnaireStatus === SIGNED) {
      return <Signed isMobile={isMobile} />;
    } if (questionnaireStatus === EXPIRED) {
      return <Expired isMobile={isMobile} />;
    } if (questionnaireStatus === ERROR) {
      return <ErrorState isMobile={isMobile} />;
    } if (questionnaireStatus === REJECTED) {
      return <Rejected isMobile={isMobile} />;
    }
    return null;
  }

  render() {
    const {
      finished,
      questionnaireStatus,
      questionnaireMode,
      isCurrentVersionLatest,
      isWhiteLabelCompany,
      v2Experience,
    } = this.props;

    const isDocumentEligableForSigning = [COMPLETED, ERROR].includes(questionnaireStatus)
      && isCurrentVersionLatest
      && questionnaireMode !== PREVIEW;
    return (
      <MobileView>
        {({ isMobile }) => (
          <StyledTopToolbar
            v2Experience={v2Experience}
            isWhiteLabel={isWhiteLabelCompany}
            questionnaireMode={questionnaireMode}
            mobileColumnLayout>
            <GridItem xs={12} sm={4}>
              <WhiteLabelLogo isWhiteLabelCompany={isWhiteLabelCompany} />
              <DocumentName disabled={questionnaireStatus === SIGNED} />
              {isMobile ? null : this.renderStatus()}
            </GridItem>
            <GridItem xs={12} sm="auto">
              <GridContainer alignItems="center" spacing={0}>
                <GridItem xs={false} sm>
                  <SaveNotificationContainer />
                </GridItem>
                <GridItem xs={12} sm="auto">
                  {finished && (
                  <div className={styles.toolbarButtons}>
                    <ButtonCollection>
                      {this.endStateActionButtons(isMobile)}
                      <GridItem xs="auto" sm="auto" classes={{ item: isDocumentEligableForSigning ? '' : styles.hidden }}>
                        <SignDocument
                          isMobile={isMobile}
                          displayButton={isDocumentEligableForSigning}
                          disabled={this.isSignDocumentDisabled()} />
                      </GridItem>
                    </ButtonCollection>
                  </div>
                  )}
                </GridItem>
              </GridContainer>
            </GridItem>
          </StyledTopToolbar>
        )}
      </MobileView>
    );
  }
}

const mapStateToProps = state => ({
  documentId: selectDocumentId(state),
  isEditEnabled: templatePropertyEditDocumentEnabledSelector(state),
  isCurrentVersionLatest: selectIsCurrentVersionLatest(state),
  latestVersion: selectLatestDocumentVersion(state),
  documentHasVersions: selectDocumentHasVersions(state),
  isWhiteLabelCompany: state.questionnaire.company.white_label_module,
  v2Experience: state.appState.v2Experience,
});
const mapDispatchToProps = { onVersionChange: documentDetailsActions.changeCurrentDocumentVersion };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TopToolbarComponent);
