import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import queryString from 'qs';
import isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import AddNoteIcon from '@material-ui/icons/NoteAdd';
import { Creators } from 'pages/Templates/reducer';
import { removeEmptyQueryParams } from 'utils/url';
import Breadcrumbs from 'components/Breadcrumbs';
import Search from 'shared/Components/Search';
import Button from 'components/Button';
import { isLoading as isLoadingUtil } from 'utils/statusSelectors';
import { folderScheme } from 'utils/proptypes';
import searchParams from 'shared/constants/queryParams/search';
import HeaderSubmenu from './HeaderSubmenu';

const Header = styled(Grid).attrs({
  container: true,
  spacing: 0,
  wrap: 'nowrap',
})`
  padding: 15px 100px;
  background-color: #fff;
  width: 100%;
`;

const GridWrapper = styled(Grid).attrs({
  container: true,
  item: true,
  wrap: 'nowrap',
  alignItems: 'center',
})`
  display: inline-flex !important;
  width: auto !important;

  &:first-child {
    flex: 1;
  }

  &:last-child {
    padding-left: 24px;
  }
`;

class TemplatePageHeader extends PureComponent {
  static propTypes = {
    currentFolder: folderScheme,
    match: ReactRouterPropTypes.match.isRequired,
    intl: intlShape.isRequired,
    isLoading: PropTypes.bool,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    parentFolderId: PropTypes.string,
    createNewTemplate: PropTypes.func.isRequired,
    createNewFolder: PropTypes.func.isRequired,
  };

  onSearch = (value) => {
    const { history, location } = this.props;
    const params = queryString.parse(location.search, { ignoreQueryPrefix: true });

    let newSearchParams = { ...params, [searchParams.name]: value, page: 1 };
    newSearchParams = removeEmptyQueryParams(newSearchParams);

    history.push({ search: queryString.stringify(newSearchParams) });
  };

  getPaths = () => {
    const { intl, match, currentFolder } = this.props;
    const paths = [
      {
        title: intl.formatMessage({ id: 'panel.folders.breadcrumbs.templates' }),
        route: '/templates',
      },
    ];

    if (match.params.folderId !== undefined && !isEmpty(currentFolder)) {
      const pathToCurrent = (currentFolder.parents || []).map(parent => ({
        title: parent.name,
        route: `/templates/folders/${parent.id}`,
      }));
      paths.push(...pathToCurrent);
      paths.push({
        title: currentFolder.name,
        route: `/templates/folders/${currentFolder.id}`,
      });
    }
    return paths;
  };

  togglePopover = (e) => {
    e.preventDefault();
    this.setState(state => ({ popoverOpened: !state.popoverOpened }));
  };

  createNewTemplate = () => {
    const { folderId } = this.props.match.params;
    this.props.createNewTemplate(folderId);
  };

  render() {
    const { isLoading, parentFolderId, createNewFolder } = this.props;
    return (
      <Header>
        <GridWrapper justify="space-between">
          <Grid item xs={12} sm={6}>
            <Breadcrumbs paths={this.getPaths()} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Search onSearch={this.onSearch} isLoading={isLoading} />
          </Grid>
        </GridWrapper>
        <GridWrapper justify="flex-end">
          <Button
            glyph={AddNoteIcon}
            onClick={this.createNewTemplate}
            testId="Templates-createNew">
            <FormattedMessage id="panel.questionnaire_templates.index.create_template" />
          </Button>
          <HeaderSubmenu parentFolderId={parentFolderId} createNewFolder={createNewFolder} />
        </GridWrapper>
      </Header>
    );
  }
}

const mapStateToProps = state => ({
  folders: state.templates.folders,
  currentFolder: state.templates.currentFolder,
  parentFolderId: _get(state, 'templates.currentFolder.id'),
  isLoading: isLoadingUtil(state, 'fetchTemplatesInfo'),
});

export const mapDispatchToProps = dispatch => ({
  createNewTemplate: folderId => dispatch(Creators.createNewTemplate(folderId)),
  createNewFolder: (folderName, folderId) => dispatch(Creators.createTemplateFolder(folderName, folderId)),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
)(TemplatePageHeader);
