import { all, call, put, takeLatest } from 'redux-saga/effects';
import assert from 'assert';
import ANSWER_STATUSES from 'shared/constants/answerStatuses';
import { Creators as QuestionnaireActions, Types } from '../actions';

export default function createSagas({ answerService, trackingService }) {
  assert.ok(answerService, 'answerService is required');
  assert.ok(trackingService, 'trackingService is required');

  function* saveAnswerSaga({ params: answer }) {
    // Log event
    if (answer.status === ANSWER_STATUSES.SKIPPED) {
      trackingService.track('SkipQuestion', 'Questionnaire', { questionName: answer.details.question_name });
    } else {
      trackingService.track('UserAnsweredQuestion', 'Questionnaire', { questionName: answer.details.question_name });
    }

    try {
      const response = yield call([answerService, answerService.save], { questionnaireId: answer.user_questionnaire_id, answer });
      yield put(QuestionnaireActions.saveAnswerSuccess(response.data.answer));
    } catch (e) {
      yield put(QuestionnaireActions.saveAnswerFailure(e));
    }
  }

  function* updateAnswerSaga({ params: answer }) {
    // Log event
    if (answer.status === ANSWER_STATUSES.SKIPPED) {
      trackingService.track('SkipQuestion', 'Questionnaire', { questionName: answer.details.question_name });
    }

    try {
      const response = yield call([answerService, answerService.save], { questionnaireId: answer.user_questionnaire_id, answer });

      yield put(QuestionnaireActions.updateAnswerSuccess(response.data.answer));
    } catch (e) {
      yield put(QuestionnaireActions.updateAnswerFailure(e));
    }
  }

  return function* root() {
    yield all([
      takeLatest(Types.SAVE_ANSWER, saveAnswerSaga),
      takeLatest(Types.UPDATE_ANSWER, updateAnswerSaga),
    ]);
  };
}
