import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import { Creators } from 'pages/Templates/reducer';

export default function (WrappedComponent) {
  class PublishTemplateContainer extends React.PureComponent {
    static propTypes = {
      templateId: PropTypes.string,
      templateVersionId: PropTypes.string.isRequired,
      templateStatus: PropTypes.string,
      templateSettings: PropTypes.shape,
      redirectTo: PropTypes.func,
      templateVersionChangeNote: PropTypes.string,
      publishTemplate: PropTypes.func.isRequired,
      patchAndPublishTemplate: PropTypes.func.isRequired,
      unpublishTemplate: PropTypes.func.isRequired,
      match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }),
    };

    static defaultProps = { redirectTo: undefined, templateSettings: {} };

    state = { open: false };

    toggleModal = () => {
      const { handleToggleModal } = this.props;
      if (handleToggleModal) { handleToggleModal(!this.state.open); }
      this.setState(state => ({ open: !state.open }));
    };

    getTemplateId = () => this.props.templateId;

    publishTemplate = () => {
      const {
        publishTemplate,
        redirectTo,
        templateVersionId,
        values,
      } = this.props;
      const templateId = this.getTemplateId();
      publishTemplate(templateId, templateVersionId, values, redirectTo);
      this.setState({ open: false });
    };

    unpublishTemplate = () => {
      const { unpublishTemplate, redirectTo, templateVersionId } = this.props;
      const templateId = this.getTemplateId();
      unpublishTemplate(templateId, templateVersionId, redirectTo);
      this.setState({ open: false });
    };

    patchAndPublishTemplate = () => {
      const {
        toggleParentModal,
        patchAndPublishTemplate,
        templateSettings,
        templateDetails,
        values,
        redirectTo,
      } = this.props;
      patchAndPublishTemplate(
        templateDetails,
        templateSettings,
        values,
        redirectTo,
      );
      this.setState({ open: false }, toggleParentModal());
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          templateStatus={this.props.templateStatus}
          toggleModal={this.toggleModal}
          publishTemplate={this.publishTemplate}
          patchAndPublishTemplate={this.patchAndPublishTemplate}
          unpublishTemplate={this.unpublishTemplate}
          open={this.state.open} />
      );
    }
  }

  const mapDispatchToProps = ({
    publishTemplate: Creators.publishTemplate,
    patchAndPublishTemplate: Creators.patchAndPublishTemplate,
    unpublishTemplate: Creators.unpublishTemplate,
  });

  const formikConfig = {
    mapPropsToValues: props => ({ message: props.templateVersionChangeNote }),
    isInitialValid: true,
  };

  return compose(
    connect(null, mapDispatchToProps),
    withFormik(formikConfig),
    withRouter,
  )(PublishTemplateContainer);
}
