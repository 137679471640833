import assert from 'assert';

import ApiClient from '../ApiClient';

export class CompanyService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  fetchCompany() {
    return this.apiClient.request(
      `/companies`,
      { method: 'get' },
    );
  }
}
