exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "input.app-components-form-AutoWidthInput-___index__input___3yPjg {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  padding: 0 !important;\n  font-size: 1.33333rem !important;\n}\n\n.app-components-form-AutoWidthInput-___index__inputRoot___38QEQ {\n  padding-right: 8px;\n  border: 0 !important;\n}\n\n.app-components-form-AutoWidthInput-___index__underline___12oad::after {\n  border-radius: 0 !important;\n}\n", "", {"version":3,"sources":["/workspace/app/components/form/AutoWidthInput/index.css"],"names":[],"mappings":"AAAA;EACE,wBAAuB;EACvB,oBAAmB;EACnB,iBAAgB;EAChB,sBAAqB;EACrB,iCAA0B;CAC5B;;AAEA;EACE,mBAAkB;EAClB,qBAAoB;CACtB;;AAEA;EACE,4BAA2B;CAC7B","file":"index.css","sourcesContent":["input.input {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  padding: 0 !important;\n  font-size: 20px !important;\n}\n\n.inputRoot {\n  padding-right: 8px;\n  border: 0 !important;\n}\n\n.underline::after {\n  border-radius: 0 !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"input": "app-components-form-AutoWidthInput-___index__input___3yPjg",
	"inputRoot": "app-components-form-AutoWidthInput-___index__inputRoot___38QEQ",
	"underline": "app-components-form-AutoWidthInput-___index__underline___12oad"
};