import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import GridContainer from 'components/Grid/Container';
import GridItem from 'components/Grid/Item';
import Button from 'components/Button';
import styles from './index.css';

const goToFeedbackForm = () => window.location.assign('https://forms.gle/Bpwa1iY4vrkUSVMc7');

const SigningSuccess = () => (
  <section className={styles.component}>
    <GridContainer className={styles.header}>
      <GridItem xs={6} md={2}>
        <img className={styles.logo} src="/assets/images/logo-with-text.png" alt="" />
      </GridItem>
    </GridContainer>

    <GridContainer xs={12} className={styles.content} wrap="no-wrap" alignItems="center">
      <GridItem xs={false} md={2} />
      <GridItem xs={12} md={4} className={styles.contentText}>
        <h1 className={styles.heading}>
          <FormattedMessage id="panel.user_questionnaires.signing.success.heading" />
        </h1>
        <p className={styles.description}>
          <FormattedMessage id="panel.user_questionnaires.signing.success.description_1" />
        </p>
        <p className={cn(styles.description, styles.descriptionSpacing)}>
          <FormattedMessage id="panel.user_questionnaires.signing.success.description_2" />
        </p>
        <strong className={styles.description}>
          <FormattedMessage id="panel.user_questionnaires.signing.success.feedback_title" />
        </strong>
        <p className={cn(styles.description, styles.feedbackDescription)}>
          <FormattedMessage id="panel.user_questionnaires.signing.success.feedback_description" />
        </p>
        <Button onClick={goToFeedbackForm}>
          <FormattedMessage id="panel.user_questionnaires.signing.success.feedback_button" />
        </Button>
      </GridItem>
      <GridItem xs={12} md={4}>
        <img className={styles.signingSuccessImage} src="/assets/images/SigningSuccess.svg" alt="Signing successfull" />
      </GridItem>
    </GridContainer>

    <GridContainer className={styles.footer} justify="center">
      <GridItem sm={12}>
        Lawbotics AS, Org. no. 918 567 852
      </GridItem>
    </GridContainer>
  </section>
);

export default SigningSuccess;
