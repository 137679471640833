/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import axios from 'axios';
import { DASHBOARD_API } from 'appRoot/constants';
import { options as optionTemplate } from 'Visualization/helpers/templates';

import styles from './index.module.css';

const SuggestProperty = (props) => {
  const { children, question, onChange } = props;
  const [loading, setLoading] = React.useState(false);
  const [properties, setProperties] = React.useState([]);
  const isMultipleChoice = ['radio', 'checkbox'].includes(question.kind);
  const queryMatch = question.name.match(/{{([^}]*)}{0,2}/);
  const query = queryMatch && !queryMatch[0].endsWith('}') && queryMatch[1];

  React.useEffect(() => {
    if (!loading) {
      setLoading(true);
      axios.get(`${DASHBOARD_API}/api/known-properties`)
        .then((response) => {
          setProperties(response.data);
          setLoading(false);
        });
    }
  }, []);

  const suggestions = properties.filter(property => (isMultipleChoice ? property.possibleValues.length : !property.possibleValues.length)).filter(property => typeof query === 'string' && property.name.includes(query));


  const handleClick = React.useCallback((event) => {
    const { propertyName } = event.currentTarget.dataset;
    const property = properties.find(it => it.name === propertyName);

    if (property) {
      const name = question.name.replace((/{{([^}]*)}{0,2}/), `{{${property.name}}}`);

      onChange({
        target: {
          name: 'name',
          value: name,
        },
      });

      if (isMultipleChoice) {
        const { details } = question;
        let updated = false;

        for (const value of property.possibleValues) {
          const exists = details.options.some(it => it.name === value);

          if (!exists) {
            const option = optionTemplate().select;

            option.name = value;
            details.options.push(option);
            updated = true;
          }
        }

        if (updated) {
          onChange({
            target: {
              name: 'details',
              value: details,
            },
          });
        }
      }
    }
  }, [question, properties]);

  return (
    <div className={styles.root}>
      {children}
      {suggestions.length > 0 && (
      <ul className={styles.options}>
        {suggestions.map(property => (
          <li key={property.name} data-property-name={property.name} onClick={handleClick}>
            {property.name}
            <br />
            <small>{property.question}</small>
          </li>
        ))}
      </ul>
      )}
    </div>
  );
};


export default SuggestProperty;
