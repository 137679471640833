import React from 'react';
import PropTypes from 'prop-types';
import proptypes from 'utils/proptypes';
import cn from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import { createClassNameModifiers } from 'utils/componentUtils';
import styles from './index.css';

export const ERROR = 'error';

export default class InputCheckbox extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    testId: PropTypes.string.isRequired,
    modifiers: proptypes.arrayOfModifiers([ERROR]),
    onChange: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.string,
  };

  static defaultProps = { modifiers: [] };

  render() {
    const {
      className,
      modifiers,
      testId,
      label,
      value,
      ...otherProps
    } = this.props;

    const formattedModifiersForCheckbox = createClassNameModifiers({ modifiers, styles });
    const classStringForCheckbox = cn(styles.component, ...formattedModifiersForCheckbox, className);

    return (
      <FormControlLabel
        value={value}
        classes={{ root: styles.formControl, label: styles.label, disabled: styles.disabledLabel }}
        label={label}
        control={
          (
            <Checkbox
              {...otherProps}
              className={classStringForCheckbox}
              classes={{ checked: styles.checked }}
              onChange={this.props.onChange ? this.props.onChange : undefined}
              icon={<CheckBoxOutlineBlank classes={{ root: styles.checkboxIcon }} />}
              checkedIcon={<CheckBox classes={{ root: styles.checkboxIcon }} />}
              color="primary"
              disableRipple
              data-test-id={testId} />
          )
        } />
    );
  }
}
