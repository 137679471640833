import assert from 'assert';

import { put, call, take, select } from 'redux-saga/effects';
import map from 'lodash/map';
import _get from 'lodash/get';
import { push } from 'react-router-redux';
import { snakeToCamelCase } from 'shared/helpers/transforms';
import { Creators as UserActions } from 'ducks/loggedInUser/actions';
import { Creators, Types } from './actions';
import { getCurrentFolderId } from './selectors';
import documentListSagasCreator from './sagas/documentList.sagas';
import moveDocumentSagasCreator from './sagas/moveDocument.sagas';
import duplicateDocumentSagasCreator from './sagas/duplicateDocument.sagas';
import { ROOT_DIR } from './constants';

export default function createSagas({
  documentService,
  newDocumentService,
  documentFoldersService,
  notificationService,
} = {}) {
  assert.ok(documentService, 'documentService is required');
  assert.ok(documentFoldersService, 'documentFoldersService is required');
  assert.ok(notificationService, 'notificationService is required');


  function* fetchFoldersSaga() {
    while (true) {
      yield take(Types.FETCH_FOLDERS_REQUEST);

      try {
        const { data } = yield call([documentService, documentService.fetchFolders]);
        const folders = map(data.document_folders, snakeToCamelCase);

        yield put(Creators.fetchFoldersSuccess(folders));
      } catch (e) {
        yield put(Creators.fetchFoldersFailure(e));
      }
    }
  }

  function* searchDocumentsSaga() {
    while (true) {
      const { folderSlug, query, page } = yield take(Types.SEARCH_DOCUMENTS_REQUEST);
      try {
        const { data } = yield call(
          [documentService, documentService.searchDocuments],
          query,
          folderSlug,
          page,
        );
        const response = {
          duplicateDocumentEnabled: data.duplicate_document_enabled,
          documents: data.user_questionnaires.map(uq => ({
            name: uq.name,
            owner: {
              firstName: uq.created_by.first_name,
              lastName: uq.created_by.last_name,
              email: uq.created_by.email,
              type: uq.owner_type,
              role: uq.created_by.roles[0],
            },
            slug: uq.id,
            state: uq.status,
            templateName: uq.based_on_template,
            templateVersion: uq.based_on_version,
            basedOnLatestTemplateVersion: uq.based_on_latest_template_version,
            created: uq.created_at,
            updated: uq.updated_at,
          })),
          count: data.user_questionnaires_count,
          folders: data.document_folders.map(f => ({
            folderSlug: f.id,
            name: f.name,
            parents: f.parents,
          })),
        };
        response.currentFolder = data.document_folder ? {
          folderSlug: data.document_folder.id,
          name: data.document_folder.name,
          parents: data.document_folder.parents,
        } : ROOT_DIR;
        yield put(Creators.searchDocumentsSuccess(response));
      } catch (e) {
        yield put(Creators.searchDocumentsFailure(e));
      }
    }
  }

  function* createDocumentFolderSaga() {
    while (true) {
      const { name, parentId } = yield take(Types.CREATE_DOCUMENT_FOLDER_REQUEST);

      try {
        const { data } = yield call([documentService, documentService.createDocumentFolder], { name, parentId });
        yield put(Creators.createDocumentFolderSuccess(snakeToCamelCase(data.document_folder)));
      } catch (e) {
        yield put(Creators.createDocumentFolderFailure(e));
      }
    }
  }

  function* updateFolderNameSaga() {
    while (true) {
      const { folderId, folderName } = yield take(Types.UPDATE_FOLDER_NAME_REQUEST);
      const folder = { id: folderId, name: folderName };

      try {
        yield call([documentService, documentService.updateFolder], folder);
        yield put(Creators.updateFolderNameSuccess(folderId, folderName));
      } catch (e) {
        yield put(notificationService.error({
          message: 'folder_list.rename_folder.error',
          position: 'bl',
          useTranslate: true,
        }));
        yield put(Creators.updateFolderNameFailure(e));
      }
    }
  }

  function* deleteFolderSaga() {
    while (true) {
      const { folderId } = yield take(Types.DELETE_FOLDER_REQUEST);

      try {
        yield call([documentService, documentService.deleteFolder], folderId);
        yield put(Creators.deleteFolderSuccess(folderId));
      } catch (e) {
        yield put(notificationService.error({
          message: 'folder_list.delete_folder.error',
          position: 'bl',
          useTranslate: true,
        }));
        yield put(Creators.deleteFolderFailure(e));
      }
    }
  }

  function* selectTemplateForDocumentSaga() {
    while (true) {
      const { templateId, folderId } = yield take(Types.SELECT_TEMPLATE_FOR_DOCUMENT_REQUEST);

      try {
        const { data: versionData } = yield call([documentService, documentService.getVersionPreviews], templateId);
        const { data } = yield call([documentService, documentService.createDocument], {
          id: versionData.questionnaire_template_version.id,
          folderId,
        });

        yield put(push(`/documents/${data.user_questionnaire.id}`));
        yield put(Creators.selectTemplateForDocumentSuccess());
      } catch (e) {
        yield put(Creators.selectTemplateForDocumentFailure(e));
      }
    }
  }

  function* createDocumentFromSharingTemplateSaga() {
    while (true) {
      const { documentName, email, templateSharingSlug } = yield take(Types.CREATE_GENERIC_ACCESS_DOCUMENT_REQUEST);
      try {
        const { data } = yield call([documentService, documentService.createGenericAccessDocument], {
          documentName,
          email,
          templateSharingSlug,
        });
        yield put(UserActions.signInSuccess(data.access_token, ''));
        yield put(push(`/generic-access/documents/${data.user_questionnaire.id}/edit`));
        yield put(Creators.createGenericAccessDocumentSuccess(data.user_questionnaire));
      } catch (e) {
        yield put(Creators.createGenericAccessDocumentFailure(e));
      }
    }
  }

  function* deleteDocumentSaga() {
    while (true) {
      const { id } = yield take(Types.DELETE_DOCUMENT_REQUEST);

      try {
        const { data: { id: trashId } } = yield call([documentService, documentService.deleteDocument], id);
        const currentFolderId = yield select(getCurrentFolderId);
        yield put(Creators.fetchDocumentsRequest(currentFolderId));
        yield put(notificationService.info({
          message: 'shared.statuses.moved_to_trash',
          action: {
            label: 'shared.statuses.undo',
            callback: Creators.restoreFromTrashRequest(trashId),
          },
          position: 'bl',
          useTranslate: true,
        }));
      } catch (e) {
        const errorMessage = _get(e, 'data.message', 'generic');

        yield put(Creators.deleteDocumentFailure(e));
        yield put(notificationService.error({
          message: `shared.errors.${errorMessage}`,
          position: 'bl',
          useTranslate: true,
        }));
      }
    }
  }

  function* restoreFromTrashSaga() {
    while (true) {
      const { trashId } = yield take(Types.RESTORE_FROM_TRASH_REQUEST);

      try {
        const { data: { deleted_item: { name } } } = yield call([documentService, documentService.restoreFromTrash], trashId);
        const currentFolderId = yield select(getCurrentFolderId);
        yield put(Creators.fetchDocumentsRequest(currentFolderId));
        yield put(notificationService.info({
          message: 'shared.statuses.restored_from_trash',
          position: 'bl',
          values: { name },
          useTranslate: true,
        }));
      } catch (e) {
        const errorMessage = _get(e, 'data.message', 'generic');
        yield put(notificationService.error({
          message: `shared.errors.${errorMessage}`,
          position: 'bl',
          useTranslate: true,
        }));
      }
    }
  }

  return {
    fetchFoldersSaga,
    createDocumentFolderSaga,
    updateFolderNameSaga,
    deleteFolderSaga,
    selectTemplateForDocumentSaga,
    deleteDocumentSaga,
    createDocumentFromSharingTemplateSaga,
    restoreFromTrashSaga,
    searchDocumentsSaga,
    ...documentListSagasCreator({ documentService: newDocumentService }),
    ...moveDocumentSagasCreator({ documentService, documentFoldersService }),
    ...duplicateDocumentSagasCreator({ documentService: newDocumentService }),
  };
}
