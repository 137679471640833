import React, { PureComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';

import NavigationBar from 'components/NavigationBar';
import Page from 'components/Page';
import NotificationSystem from 'shared/NotificationSystem';
import { WHITE_LABEL, GENERIC_ACCESS, PREVIEW } from 'shared/constants/questionnaireModes';
import { STARTED } from 'shared/constants/questionnaireStatus';
import TopToolbar from 'Questionnaire2/TopToolbar/TopToolbar';
import QuestionnaireContainer from 'Questionnaire2/QuestionnaireContainer';
import { templatePropertyEditDocumentEnabledSelector } from 'ducks/questionnaire/selectors';
import { Creators as QuestionnaireActions } from 'ducks/questionnaire/actions';
import { stylesheetSelector } from 'shared/utils/questionnairePreviewSelector';
import DocumentPreview from './components/DocumentPreview';
import { Creators } from './actions';
import { selectCurrentDocumentVersion, selectDocumentVersions, selectDocumentStatus } from './selectors';
import DocumentNotification from './components/DocumentNotification';

export class DocumentDetails extends PureComponent {
  static defaultProps = { documentVersions: [] }

  componentDidMount() {
    const {
      mode,
      match: { params },
      fetchDocumentDetails,
      fetchUserQuestionnairePreviewForTemplate,
      fetchDocumentStyles,
    } = this.props;

    fetchDocumentStyles();
    if (mode === PREVIEW) {
      fetchUserQuestionnairePreviewForTemplate(params.templateId, mode);
    } else {
      fetchDocumentDetails(params.documentId, mode);
    }
  }

  componentWillUnmount() {
    this.props.clearDocumentDetails();
    this.props.clearUserQuestionnaire();
  }

  hideNavigationBar = () => ![WHITE_LABEL, GENERIC_ACCESS].includes(this.props.mode)

  isQuestionnaireFinished = () => {
    const { questionnaireStatus } = this.props;
    return questionnaireStatus !== undefined && questionnaireStatus !== STARTED;
  }

  render() {
    const {
      questionnaireStatus,
      documentVersions,
      currentVersion,
      isEditEnabled,
      templateStyles,
      mode,
    } = this.props;
    const showQuestionnaire = mode === PREVIEW || _get(currentVersion, 'isQuestionnaire', false);
    const showDocumentPreview = !showQuestionnaire && !!currentVersion;

    return (
      <>
        { this.hideNavigationBar() && <NavigationBar /> }
        <ReactTooltip />
        <NotificationSystem />
        <style dangerouslySetInnerHTML={{ __html: templateStyles }} />
        <TopToolbar
          finished={this.isQuestionnaireFinished()}
          documentVersions={documentVersions}
          currentVersion={currentVersion}
          questionnaireStatus={questionnaireStatus}
          questionnaireMode={mode}
          isEditEnabled={isEditEnabled} />
        <Page fullWidth={questionnaireStatus === STARTED}>
          { questionnaireStatus && questionnaireStatus !== STARTED && <DocumentNotification /> }
          { showDocumentPreview && <DocumentPreview html={_get(currentVersion, 'html', '')} /> }
          { showQuestionnaire && <QuestionnaireContainer mode={mode} /> }
        </Page>
      </>
    );
  }
}

const mapStateToProps = state => ({
  questionnaireStatus: selectDocumentStatus(state),
  currentVersion: selectCurrentDocumentVersion(state),
  documentVersions: selectDocumentVersions(state),
  isEditEnabled: templatePropertyEditDocumentEnabledSelector(state),
  templateStyles: stylesheetSelector(state),
});

const mapDispatchToProps = {
  clearUserQuestionnaire: QuestionnaireActions.clearUserQuestionnaire,
  clearDocumentDetails: Creators.clearDocumentDetails,
  fetchDocumentDetails: Creators.fetchDocumentDetailsRequest,
  fetchUserQuestionnairePreviewForTemplate: QuestionnaireActions.fetchUserQuestionnairePreviewForTemplate,
  fetchUserQuestionnaire: QuestionnaireActions.fetchUserQuestionnaire,
  fetchDocumentStyles: QuestionnaireActions.fetchDocumentStylesRequest,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentDetails);
