import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import MuTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from 'components/Pagination';
import withPagination from 'hocs/withPagination';
import { withRouter } from 'react-router-dom';
import { StyledFooterCell } from './styled-components';

const Table = (props) => {
  const {
    columnHeaders,
    data,
    getCurrentPageIndex,
    handleChangePage,
    EmptyComponent,
    renderRow,
    total,
    testId,
  } = props;

  if (total < 1) {
    return EmptyComponent;
  }

  return (
    <MuTable data-test-id={testId}>
      <TableHead>
        <TableRow>{columnHeaders}</TableRow>
      </TableHead>
      <TableBody>{data.map(renderRow)}</TableBody>
      <TableFooter>
        <TableRow>
          <StyledFooterCell colSpan="5">
            <Pagination initialPage={getCurrentPageIndex()} pageCount={total} onPageChange={handleChangePage} />
          </StyledFooterCell>
        </TableRow>
      </TableFooter>
    </MuTable>
  );
};

Table.propTypes = {
  EmptyComponent: PropTypes.node.isRequired,
  columnHeaders: PropTypes.arrayOf(PropTypes.node).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  getCurrentPageIndex: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  renderRow: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  testId: PropTypes.string,
};

export { Table, TableCell };

export default compose(
  withRouter,
  withPagination,
)(Table);
