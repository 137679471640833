import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import queryString from 'qs';
import ReactRouterPropTypes from 'react-router-prop-types';
import { FormattedMessage } from 'react-intl';
import NotificationSystem from 'shared/NotificationSystem';
import FlexCenter from 'shared/Components/FlexCenter';
import { Sticky } from 'react-sticky';
import { NOT_FOUND_PATH } from 'appRoot/constants';
import {
  EditorViewContainer,
  EditorSide,
  EditorWithFlow,
  EditorWithToolbar,
  StickyRightSidebarContainer,
} from './styled-components';
import Editor from '../index';
import EditorPreview from '../Preview';
import RightSidebar from '../RightSidebar';
import { Creators as EditorActions } from '../reducer';

class EditorView extends Component {
  static propTypes = {
    editorInitialized: PropTypes.bool.isRequired,
    editorPreviewInitialized: PropTypes.bool.isRequired,
    initializeEditor: PropTypes.func.isRequired,
    initializeEditorPreview: PropTypes.func.isRequired,
    editorUnmount: PropTypes.func.isRequired,
    editorPreviewUnmount: PropTypes.func.isRequired,
    previewMode: PropTypes.bool,
    location: ReactRouterPropTypes.location.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  };

  static defaultProps = { previewMode: false, error: false };

  componentDidMount() {
    const { previewMode, location, match } = this.props;
    const { templateId } = match.params;
    const { version } = queryString.parse(location.search, { ignoreQueryPrefix: true });
    if (previewMode) {
      this.props.initializeEditorPreview(templateId, version);
    } else {
      this.props.initializeEditor(templateId);
    }
  }

  componentWillUnmount() {
    const { previewMode, editorUnmount, editorPreviewUnmount } = this.props;
    if (previewMode) {
      editorPreviewUnmount();
    } else {
      editorUnmount();
    }
  }

  render() {
    const { editorInitialized, editorPreviewInitialized, previewMode, error } = this.props;
    if (error === 404) {
      return (
        <Redirect to={{ pathname: NOT_FOUND_PATH }} />
      );
    }

    if (error) {
      return (
        <FlexCenter style={{ height: '100%', flexDirection: 'column' }}>
          <FormattedMessage tagName="h1" id="errors.template_preview" />
        </FlexCenter>
      );
    }

    if ((!editorInitialized && !previewMode) || (!editorPreviewInitialized && previewMode)) {
      return null;
    }

    return (
      <EditorViewContainer>
        <EditorWithFlow>
          <EditorSide>
            <EditorWithToolbar id="editor-content">{previewMode ? <EditorPreview /> : <Editor />}</EditorWithToolbar>
          </EditorSide>
          {
            !previewMode && (
              <StickyRightSidebarContainer>
                <Sticky>
                  {({ style }) => (
                    <RightSidebar style={style} />
                  )}
                </Sticky>
              </StickyRightSidebarContainer>
            )
          }

        </EditorWithFlow>
        <ReactTooltip />
        <NotificationSystem />
      </EditorViewContainer>
    );
  }
}
const mapStateToProps = state => ({
  editorInitialized: state.editor.editorInitialized,
  editorPreviewInitialized: state.editor.editorPreviewInitialized,
});

const mapDispatchToProps = dispatch => ({
  initializeEditor: templateId => dispatch(EditorActions.initializeEditor(templateId)),
  initializeEditorPreview: (templateId, version) => dispatch(EditorActions.initializeEditorPreview(templateId, version)),
  editorUnmount: () => dispatch(EditorActions.editorUnmount()),
  editorPreviewUnmount: () => dispatch(EditorActions.editorPreviewUnmount()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditorView);
