import last from 'lodash/last';
import getAllNodesInTree from './getAllNodesInTree';

export default function getNearestQuestionTagFromNode(
  node,
  logicType = undefined,
) {
  const options = { withLogicType: logicType, isQuestion: true };
  const questionNodes = getAllNodesInTree(node, ['SPAN', 'DIV'], options);
  return last(questionNodes); // last element in array of all nodes is nearest
}
