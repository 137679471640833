import React, { PureComponent } from 'react';
import { Field } from 'formik';
import { FormattedMessage, intlShape } from 'react-intl';
import cn from 'classnames';
import DoneIcon from '@material-ui/icons/Done';
import ChoiceCard from 'components/ChoiceCard';
import InputText from 'components/form/InputText';
import TextArea from 'components/form/TextArea';
import FieldRow from 'components/form/FieldRow';
import BankIdLogo from 'icons/BankIdLogo';
import EmailSigningLogo from 'icons/EmailSigningLogo';
import PropTypes from 'prop-types';
import MobileView from 'components/MobileView';
import { ModalContent } from 'components/Modal';
import Typography from 'components/Typography';
import LabelWrapper from './LabelWrapper';

import styles from './styles.css';

const signingMethods = {
  email: { getLogo: () => <EmailSigningLogo />, label: <FormattedMessage id="template_settings.email" /> },
  'bankid-no': { getLogo: checked => <BankIdLogo className={cn({ [styles.checkedBankIdLogo]: checked })} />, label: <FormattedMessage id="template_settings.bankID" /> },
};

const textAreaRows = {
  desktop: 3,
  maxDesktop: 4,
  mobile: 5,
  maxMobile: 20,
};

export class EsignFormInvitationStep extends PureComponent {
  handleFormInputChange = (e) => {
    const { senderHasToSign, setFieldValue, values, emailSenderIndex } = this.props;
    this.props.handleChange(e);
    if (senderHasToSign) {
      const { emails } = values;
      const tagsWithUpdatedSender = emails.tags.map((tag, index) => (index === emailSenderIndex ? e.target.value : tag));
      setFieldValue('emails', { tags: tagsWithUpdatedSender, inputValue: emails.inputValue });
    }
  };

  render() {
    const { values, intl, questionnaireSigningMethods } = this.props;
    return (
      <div className={styles.container}>
        <ModalContent>
          <Typography variant="h4" className={styles.invitationnStepTitle}>
            <FormattedMessage id="questionnaire.esign.form.invitation_to_sign" />
          </Typography>
          <FieldRow className={styles.fieldRow}>
            <LabelWrapper htmlFor="from" helpText={<FormattedMessage id="questionnaire.esign.form.sent_from_tooltip" />} required>
              <FormattedMessage id="questionnaire.esign.form.sent_from" />
            </LabelWrapper>
            <Field>
              {({ field, form }) => (
                <InputText
                  {...field}
                  name="from"
                  testId="EsignFormInvitationStep-from"
                  value={values.from}
                  onChange={this.handleFormInputChange}
                  errorMessage={form.touched.from && form.errors.from}
                  endAdornment={!form.errors.from && <DoneIcon className={styles.doneIcon} />}
                  className={styles.invitationFrom}
                  placeholder={intl.formatMessage({ id: 'questionnaire.esign.form.placeholder.sent_from' })} />
              )
            }
            </Field>
          </FieldRow>
          <FieldRow className={styles.fieldRow}>
            <LabelWrapper htmlFor="userQuestionnaireName" description={<FormattedMessage id="questionnaire.esign.form.document_name_description" />} required>
              <FormattedMessage id="questionnaire.esign.form.document_name" />
            </LabelWrapper>
            <Field>
              {({ field, form }) => (
                <InputText
                  {...field}
                  testId="EsignFormInvitationStep-userQuestionnaireName"
                  name="userQuestionnaireName"
                  value={values.userQuestionnaireName}
                  errorMessage={form.touched.userQuestionnaireName && form.errors.userQuestionnaireName}
                  endAdornment={!form.errors.userQuestionnaireName && <DoneIcon className={styles.doneIcon} />}
                  placeholder={intl.formatMessage({ id: 'questionnaire.esign.form.placeholder.document_name' })} />
              )
            }
            </Field>
          </FieldRow>
          <FieldRow className={styles.fieldRow}>
            <LabelWrapper htmlFor="message">
              <FormattedMessage id="questionnaire.esign.form.custom_message" />
            </LabelWrapper>
            <MobileView>
              {({ isMobile }) => (
                <Field>
                  {({ field, form }) => (
                    <TextArea
                      {...field}
                      rows={isMobile ? textAreaRows.mobile : textAreaRows.desktop}
                      rowsMax={isMobile ? textAreaRows.maxMobile : textAreaRows.maxDesktop}
                      errorMessage={form.errors.message}
                      value={values.message}
                      name="message"
                      testId="EsignFormInvitationStep-message"
                      inputMultilineClassName={styles.customMessage}
                      placeholder={intl.formatMessage({ id: 'questionnaire.esign.form.placeholder.custom_message' }, { email: values.from })} />
                  )}
                </Field>
              )}
            </MobileView>
          </FieldRow>

          <FieldRow>
            <LabelWrapper htmlFor="signingMethod" helpText={<FormattedMessage id="questionnaire.esign.form.signing_method_tooltip" />}>
              <FormattedMessage id="questionnaire.esign.form.signing_method" />
            </LabelWrapper>
            <Field name="signingMethod">
              {({ field, form }) => (
                <div className={styles.signingMethods}>
                  {questionnaireSigningMethods.map(s => (
                    <ChoiceCard
                      key={s}
                      testId={`EsignFormInvitationStep-method-${s}`}
                      name="signingMethod"
                      className={styles.signingMethodCard}
                      checked={field.value === s}
                      onChange={() => form.setFieldValue('signingMethod', s)}
                      label={signingMethods[s].label}>
                      {signingMethods[s].getLogo(field.value === s)}
                    </ChoiceCard>
                  ))}
                </div>
              )}
            </Field>
          </FieldRow>
        </ModalContent>
      </div>
    );
  }
}

EsignFormInvitationStep.propTypes = {
  values: PropTypes.shape({
    userQuestionnaireName: PropTypes.string,
    emails: PropTypes.shape({
      inputValue: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
    message: PropTypes.string,
    from: PropTypes.string,
  }),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  senderHasToSign: PropTypes.bool.isRequired,
  emailSenderIndex: PropTypes.number,
  onNextClick: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  questionnaireSigningMethods: PropTypes.arrayOf(PropTypes.string),
  intl: intlShape.isRequired,
};
