import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import MaterialTableRow from '@material-ui/core/TableRow';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import DateCell from 'components/table/DateCell';
import OwnerCell from 'components/table/OwnerCell';
import { Restore as RestoreIcon } from 'icons/Restore';
import TrashName from './TrashName';
import styles from './styles.css';

class TableRow extends PureComponent {
  static propTypes = {
    item: PropTypes.shape({
      name: PropTypes.string,
      ownerType: PropTypes.string,
      deletedAt: PropTypes.string,
      lastEdited: PropTypes.string,
      createdBy: PropTypes.shape({
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        disabled: PropTypes.bool,
        id: PropTypes.string,
      }),
    }),
    onRestore: PropTypes.func,
  };

  restore = () => {
    const { item, onRestore } = this.props;
    onRestore(item.id);
  };

  render() {
    const { item } = this.props;
    const { name, deletedAt, lastEdited, createdBy, ownerType } = item;
    const owner = {
      ...createdBy,
      role: createdBy.roles[0],
      type: ownerType,
    };
    return (
      <MaterialTableRow hover onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <TableCell width="30%">
          <TrashName name={name} item={item} data-test-id="Trash-templateName" />
        </TableCell>
        <TableCell width="10%">
          <DateCell value={deletedAt} />
        </TableCell>
        <TableCell width="15%">
          <DateCell value={lastEdited} />
        </TableCell>
        <TableCell width="25%">
          <OwnerCell owner={owner} />
        </TableCell>
        <TableCell width="20%" classes={{ paddingNone: 'actionTableCell' }}>
          <Button className={styles.restoreButton} onClick={this.restore} testId="Trash-restoreButton">
            <RestoreIcon className={styles.restoreIcon} />
            <FormattedMessage id="panel.trash.deleted_item.restore" />
          </Button>
        </TableCell>
      </MaterialTableRow>
    );
  }
}

export default TableRow;
