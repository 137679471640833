import { createReducer, createActions } from 'reduxsauce';
import { createSelector } from 'reselect';
import uniq from 'lodash/uniq';
import each from 'lodash/each';
import every from 'lodash/every';
import update from 'immutability-helper';
import getOptionsFromQuestions from 'utils/getOptionsFromQuestions';

export const INITIAL_STATE = {
  selectedQuestion: {},
  questions: [],
  usedOptions: [],
};

/* ------------- Types and Action Creators ------------- */
export const { Types, Creators } = createActions({
  setCurrentQuestion: ['question'],

  setQuestions: ['questions'],
  setUsedOptions: ['options'],

  addNewQuestion: ['params'],
  addNewQuestionSuccess: ['question'],
  addNewQuestionFailure: ['error'],

  updateQuestion: ['questionId', 'params'],
  updateQuestionSuccess: ['questionId', 'question'],
  updateQuestionFailure: ['error'],

  removeQuestion: ['questionId'],
  removeQuestionSuccess: ['questionId'],
  removeQuestionFailure: ['error'],
});

/* ------------- Reducers ------------- */
const setCurrentQuestion = (state, { question }) => update(state, { selectedQuestion: { $set: question } });

const setQuestions = (state, { questions }) => update(state, { questions: { $set: questions } });

const setUsedOptions = (state, { options }) => update(state, { usedOptions: { $set: options } });

const addNewQuestionSuccess = (state, { question }) => update(state, { questions: { $push: [question] } });

const updateQuestionSuccess = (state, { questionId, question }) => {
  const questionIndex = state.questions.findIndex(q => q.id === questionId);

  return update(state, { questions: { [questionIndex]: { $merge: question } } });
};

const removeQuestionSuccess = (state, { questionId }) => {
  const questionIndex = state.questions.findIndex(question => question.id === questionId);
  return update(state, { questions: { $splice: [[questionIndex, 1]] } });
};

/* ------------- Hookup Reducers To Types ------------- */
export default createReducer(INITIAL_STATE, {
  [Types.SET_CURRENT_QUESTION]: setCurrentQuestion,

  [Types.SET_QUESTIONS]: setQuestions,
  [Types.SET_USED_OPTIONS]: setUsedOptions,

  [Types.ADD_NEW_QUESTION_SUCCESS]: addNewQuestionSuccess,
  [Types.UPDATE_QUESTION_SUCCESS]: updateQuestionSuccess,
  [Types.REMOVE_QUESTION_SUCCESS]: removeQuestionSuccess,
});

const getQuestions = state => state.questions.questions;
const getUsedOptions = state => state.questions.usedOptions;

export const questionsSelector = getQuestions;

export const optionsSelector = createSelector(
  getQuestions,
  getOptionsFromQuestions,
);
export const choiceQuestionOptions = createSelector(
  // needs to be refactored
  // atm we are creating all possible options that belongs to questions
  // - select/radio/checkbox has their options in details.options
  // but input has only one possible option in details
  getQuestions,
  (questions) => {
    const arr = [];
    questions
      .filter(q => q.kind.match(/select|radio|checkbox/))
      .map((q) => {
        q.details.options.map(f => Object.assign({}, f, { question_id: q.id }));
        return q;
      })
      .map(q => arr.push(...q.details.options));
    return uniq(arr);
  },
);

export const draftQuestionsSelector = createSelector(
  getQuestions,
  getUsedOptions,
  (questions, usedOptions) => {
    const result = [];
    each(questions, (q) => {
      if (q.kind === 'input') {
        if (!usedOptions.includes(q.details.id)) result.push(q);
        return;
      }
      const noneOptionsAssigned = every(
        q.details.options,
        option => !usedOptions.includes(option.id),
      );
      if (noneOptionsAssigned) result.push(q);
    });
    return result;
  },
);
