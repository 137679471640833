import assert from 'assert';

export function getAppTypeValues({ apiPaths, appTypes, accessTypes, currentPathname = '' } = {}) {
  assert.ok(apiPaths instanceof Object, 'apiPaths are required');
  assert.ok(appTypes instanceof Object, 'appTypes are required');
  assert.ok(accessTypes instanceof Object, 'accessTypes are required');
  assert.ok(typeof currentPathname === 'string', 'currentPathname<string> is required');

  let appType = appTypes.DEFAULT;
  let apiPath = apiPaths.DEFAULT;
  let accessType = accessTypes.DEFAULT;
  if (currentPathname.startsWith('/external')) {
    appType = appTypes.WHITE_LABEL;
    apiPath = apiPaths.WHITE_LABEL;
    accessType = accessTypes.WHITE_LABEL;
  }
  if (currentPathname.startsWith('/generic-access')) {
    appType = appTypes.GENERIC_ACCESS;
    apiPath = apiPaths.GENERIC_ACCESS;
    accessType = accessTypes.GENERIC_ACCESS;
  }

  return {
    appType,
    apiPath,
    accessType,
  };
}
