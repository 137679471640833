import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { sortWithUnicodeBy } from 'utils/sortBy';
import FolderItem from './FolderItem';

export class FolderListComponent extends PureComponent {
  static propTypes = {
    folders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    hasAction: PropTypes.bool,
    updateFolderNameRequest: PropTypes.func,
    fetchFoldersRequest: PropTypes.func.isRequired,
    onFolderClick: PropTypes.func,
    deleteFolder: PropTypes.func,
    getFolderSubheader: PropTypes.func,
    disableLoadingFolder: PropTypes.bool,
    testIdPrefix: PropTypes.string,
    intl: intlShape.isRequired,
  };

  static defaultProps = { testIdPrefix: '' };

  componentDidMount() {
    if (!this.props.disableLoadingFolder) {
      this.props.fetchFoldersRequest();
    }
  }

  render() {
    const {
      folders,
      updateFolderNameRequest,
      onFolderClick,
      deleteFolder,
      hasAction,
      getFolderSubheader,
      testIdPrefix,
      intl,
    } = this.props;
    const foldersSorted = sortWithUnicodeBy(folders, 'name', intl.locale);

    return (
      <Grid container spacing={24}>
        {foldersSorted.map(f => (
          <Grid key={f.id || f.folderSlug} item xs={12} lg={3} md={4} sm={6}>
            <FolderItem
              folder={f}
              getFolderSubheader={getFolderSubheader}
              fetchFolderDetails={onFolderClick}
              hasAction={hasAction}
              updateFolderNameRequest={updateFolderNameRequest}
              deleteFolder={deleteFolder}
              testId={testIdPrefix ? 'FolderList' : testIdPrefix} />
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default injectIntl(FolderListComponent);
