import React, { Fragment, PureComponent } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';
import PageSpinner from 'components/PageSpinner';

import PageHeader from 'MainApp/PageHeaders';
import { Creators } from 'ducks/loggedInUser/actions';
import mapStateToProps from './selectors';

class GenericAccessRoute extends PureComponent {
  static propTypes = {
    hasFetchedUser: PropTypes.bool.isRequired,
    mode: PropTypes.string,
    fetchProfile: PropTypes.func.isRequired,
    error: PropTypes.shape({ message: PropTypes.string }).isRequired,
  };

  componentDidMount() {
    document.title = this.props.title;
    if (!this.props.hasFetchedUser) {
      this.props.fetchProfile();
    }
  }

  isWrongToken = () => {
    const { error } = this.props;
    return error && error.message === 'wrong_token';
  };

  _getTemplateIdFromUrl = (pathname = '') => {
    const uuidRegexp = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/;
    const result = pathname.match(uuidRegexp);
    return result && result[0];
  };

  _renderRoute = (props) => {
    const { component: Component, hasFetchedUser, isUserAuthorized } = this.props;
    if (!isUserAuthorized) {
      const templateId = this.isWrongToken() ? null : this._getTemplateIdFromUrl(window.location.href);
      return (
        <Redirect
          to={{
            pathname: '/template/error',
            state: { from: props.location, templateId },
          }} />
      );
    }

    if (!hasFetchedUser) {
      return <PageSpinner />;
    }

    return (
      <Fragment>
        <PageHeader />
        <Component {...props} mode={this.props.mode} />
      </Fragment>
    );
  };

  render() {
    const routeProps = _omit(this.props, ['component', 'isUserAuthorized', 'hasFetchedUser']);
    return <Route {...routeProps} render={this._renderRoute} />;
  }
}

const connector = connect(
  mapStateToProps,
  { fetchProfile: Creators.fetchProfileRequest },
);
export default withRouter(connector(GenericAccessRoute));
