exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-pages-UserEdit-components-___UserEditForm__form___242re {\n  display: flex;\n  flex-direction: column;\n}\n\n.app-pages-UserEdit-components-___UserEditForm__nameRow___pB9x6 {\n  display: flex;\n}\n\n.app-pages-UserEdit-components-___UserEditForm__leftSpacing___1-UAj {\n  padding-right: 16px;\n}\n\n.app-pages-UserEdit-components-___UserEditForm__submitButton___2ii43 {\n  align-self: flex-start;\n  margin-left: 15%;\n}\n", "", {"version":3,"sources":["/workspace/app/pages/UserEdit/components/UserEditForm.css"],"names":[],"mappings":"AAAA;EACE,cAAa;EACb,uBAAsB;CACxB;;AAEA;EACE,cAAa;CACf;;AAEA;EACE,oBAAmB;CACrB;;AAEA;EACE,uBAAsB;EACtB,iBAAgB;CAClB","file":"UserEditForm.css","sourcesContent":[".form {\n  display: flex;\n  flex-direction: column;\n}\n\n.nameRow {\n  display: flex;\n}\n\n.leftSpacing {\n  padding-right: 16px;\n}\n\n.submitButton {\n  align-self: flex-start;\n  margin-left: 15%;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"form": "app-pages-UserEdit-components-___UserEditForm__form___242re",
	"nameRow": "app-pages-UserEdit-components-___UserEditForm__nameRow___pB9x6",
	"leftSpacing": "app-pages-UserEdit-components-___UserEditForm__leftSpacing___1-UAj",
	"submitButton": "app-pages-UserEdit-components-___UserEditForm__submitButton___2ii43"
};