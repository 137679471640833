import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Page from 'components/Page';
import { EDIT } from 'shared/constants/questionnaireModes';
import WithPageBreak from 'shared/Components/WithPageBreak';
import NotificationSystem from 'shared/NotificationSystem';
import Editor from 'components/Editor';
import ToolbarContainer from 'Visualization/Editor-v2/ToolbarContainer';
import { Creators as documentDetailsActions } from 'pages/DocumentDetails/actions';
import { Creators as QuestionnaireActions } from 'ducks/questionnaire/actions';
import TopToolbar from 'bundles/Questionnaire2/TopToolbar/TopToolbar';
import { selectCurrentDocumentVersion, selectDocumentId, selectDocumentStatus } from 'pages/DocumentDetails/selectors';
import { stylesheetSelector } from 'shared/utils/questionnairePreviewSelector';
import styles from './styles.css';

export class DocumentEditor extends PureComponent {
  static propTypes = {
    fetchDocumentDetails: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match,
    questionnaireStatus: PropTypes.string,
    setupEditor: PropTypes.func,
    fetchDocumentStyles: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { match: { params }, fetchDocumentDetails, fetchDocumentStyles } = this.props;
    fetchDocumentDetails(params.documentId, EDIT);
    fetchDocumentStyles();
  }

  componentWillUnmount() {
    this.props.clearDocumentDetails();
    this.props.clearUserQuestionnaire();
  }

  handleEditorInit = (editor) => {
    this.editor = editor;
    // This will trick the editor into thinking it was focused
    // without actually focusing it (causing the toolbar to appear)
    editor.fire('focus');
  };

  handleEditorChange = (value) => {
    const { currentVersion, updateCurrentVersionContent } = this.props;
    if (value !== currentVersion.html) {
      // this if is here because the onEditorChange is triggered too often
      // - there is a change in the tinymce-react, but not release yet
      updateCurrentVersionContent(value, currentVersion);
    }
  }

  render() {
    const { setupEditor, documentVersions, questionnaireStatus, currentVersion, templateStyles } = this.props;

    return (
      <>
        <ReactTooltip />
        <NotificationSystem />
        <style dangerouslySetInnerHTML={{ __html: templateStyles }} />
        <TopToolbar
          finished
          documentVersions={documentVersions}
          currentVersion={currentVersion}
          questionnaireMode={EDIT}
          questionnaireStatus={questionnaireStatus} />
        <Page>
          <div className={styles.editor}>
            { currentVersion && (
              <WithPageBreak>
                <ToolbarContainer id="mytoolbar" />
                <Editor
                  init={this.handleEditorInit}
                  onEditorChange={this.handleEditorChange}
                  setupEditor={setupEditor}
                  initialValue={currentVersion.html}
                  value={currentVersion.html} />
              </WithPageBreak>
            ) }
          </div>
        </Page>
      </>
    );
  }
}

const mapStateToProps = state => ({
  documentVersions: state.documentDetails.versions,
  currentVersion: selectCurrentDocumentVersion(state),
  questionnaireStatus: selectDocumentStatus(state),
  documentId: selectDocumentId(state),
  templateStyles: stylesheetSelector(state),
});

const mapDispatchToProps = {
  clearUserQuestionnaire: QuestionnaireActions.clearUserQuestionnaire,
  clearDocumentDetails: documentDetailsActions.clearDocumentDetails,
  fetchDocumentDetails: documentDetailsActions.fetchDocumentDetailsRequest,
  updateCurrentVersionContent: documentDetailsActions.updateCurrentVersionContentRequest,
  fetchDocumentStyles: QuestionnaireActions.fetchDocumentStylesRequest,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(DocumentEditor);
