import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';
import _findIndex from 'lodash/findIndex';
import { Types as EditorTypes } from 'Visualization/Editor-v2/reducer';

/* ------------- Initial state ------------- */
export const INITIAL_STATE = {
  templates: {
    data: [],
    total: 0,
  },
  currentTemplate: {},
  templatesHistory: [],
  documents: [],
  selectedFolderId: '',
  currentFolder: {},
  folders: [],
  templateSharingSlug: '',
  templateSharingValid: false,
  folderDialogData: { currentFolder: { id: '0' } },
};

/* ------------- Types and Action Creators ------------- */
export const { Types, Creators } = createActions({
  setTemplates: ['templates', 'count'],
  setFolders: ['folders'],
  setCurrentFolder: ['folder'],

  fetchTemplatesInfoRequest: ['payload', 'publishedOnly', 'toast'],
  fetchTemplatesInfoSuccess: ['payload'],
  fetchTemplatesInfoFailure: ['error'],

  fetchCurrentTemplateRequest: ['id'],
  fetchCurrentTemplateSuccess: ['template'],
  fetchCurrentTemplateFailure: ['error'],

  fetchTemplatesVersionHistoryRequest: ['templateId'],
  fetchTemplatesVersionHistorySuccess: ['templatesHistory'],
  fetchTemplatesVersionHistoryFailure: ['error'],

  fetchDocumentsForTemplateRequest: ['templateId'],
  fetchDocumentsForTemplateSuccess: ['documents'],
  fetchDocumentsForTemplateFailure: ['error'],

  toggleSharingTemplateFromListRequest: ['sharingActive', 'templateId'],
  toggleSharingTemplateFromListSuccess: null,
  toggleSharingTemplateFromListFailure: ['error'],

  createNewTemplate: ['folderId'],
  createNewTemplateSuccess: ['template'],
  createNewTemplateFailure: ['error'],

  publishTemplate: ['templateId', 'templateVersionId', 'formData', 'redirectTo'],
  publishTemplateSuccess: null,
  publishTemplateFailure: ['error'],

  patchAndPublishTemplate: ['templateDetails', 'templateSettings', 'changeNote', 'redirectTo'],
  patchAndPublishTemplateSuccess: null,
  patchAndPublishTemplateFailure: ['error'],

  unpublishTemplate: ['templateId', 'templateVersionId', 'redirectTo'],
  unpublishTemplateSuccess: null,
  unpublishTemplateFailure: ['error'],

  moveToTrash: ['templateId'],
  moveToTrashSuccess: null,
  moveToTrashFailure: ['error'],


  selectFolder: ['folderId'],
  moveToFolder: ['templateId', 'folderId'],
  moveToFolderSuccess: null,
  moveToFolderFailure: ['error'],

  goToFolderRequest: ['folderId'],
  goToFolderSuccess: ['folders', 'currentFolder'],
  goToFolderFailure: ['error'],

  createTemplateFolder: ['folderName', 'parentId'],
  createTemplateFolderSuccess: ['folder'],
  createTemplateFolderFailure: ['error'],

  updateFolderName: ['folderId', 'folderName'],
  updateFolderNameSuccess: ['folderId', 'folderName'],
  updateFolderNameFailure: ['error'],

  deleteFolder: ['folderId'],
  deleteFolderSuccess: ['folderId'],
  deleteFolderFailure: ['error'],

  templateSettingsRequest: ['templateId', 'settings'],
  templateSettingsSuccess: ['settings'],
  templateSettingsFailure: ['error'],
  templateSettingsProgress: ['inProgress'],

  validateSharingTemplateRequest: ['templateSharingSlug'],
  validateSharingTemplateSuccess: ['templateSharingSlug', 'name'],
  validateSharingTemplateFailure: ['error'],

  deleteDocumentRequest: ['id'],
  deleteDocumentSuccess: ['id'],
  deleteDocumentFailure: ['error'],

  restoreFromTrashRequest: ['trashId'],
  restoreFromTrashSuccess: ['trashId'],
  restoreFromTrashFailure: ['error'],

  clearTemplatesState: null,
});

/* ------------- Reducers ------------- */

const clearTemplatesState = () => ({ ...INITIAL_STATE });

const setTemplates = (state, { templates, count }) => update(state, {
  templates: {
    $set: {
      data: templates,
      total: count,
    },
  },
});

const setCurrentTemplate = (state, { template }) => update(state, { currentTemplate: { $set: template } });

const setTemplatesVersionHistory = (state, { templatesHistory }) => update(state, { templatesHistory: { $set: templatesHistory } });

const setEmptyTemplatesVersionHistory = state => update(state, { templatesHistory: { $set: [] } });

const setDocumentsForTemplate = (state, { documents }) => update(state, { documents: { $set: documents } });

const setFolders = (state, { folders }) => update(state, { folders: { $set: folders } });

const setCurrentFolder = (state, { folder }) => update(state, { currentFolder: { $set: folder } });

const setSelectedFolder = (state, { folderId }) => update(state, {
  selectedFolderId: { $set: folderId },
  templates: { folderDialogData: { selectedFolderId: { $set: folderId } } },
});

const setTemplateSettings = (state, { settings }) => update(state, { settings: { $set: settings } });

const setTemplateSettingsProgress = (state, { inProgress }) => update(state, { inProgress: { $set: inProgress } });

const setSharingTemplateData = (state, { templateSharingSlug, name }) => update(state, {
  templateSharingSlug: { $set: templateSharingSlug },
  templateSharingValid: { $set: true },
  name: { $set: name },
});

const setInvalidSharingTemplate = state => update(state, { templateSharingValid: { $set: false } });

const setFolderName = (state, { folderId, folderName }) => update(state, {
  folders: {
    $apply: folders => folders.map((f) => {
      if (f.id === folderId) {
        return {
          ...f,
          name: folderName,
        };
      }
      return f;
    }),
  },
});

const setSharingTemplate = (state, { templateId, sharingActive }) => update(state, {
  templates: {
    data: {
      $apply: data => data.map((template) => {
        if (template.id === templateId) {
          return {
            ...template,
            sharingActive,
          };
        }
        return template;
      }),
    },
  },
});

const deleteFolder = (state, { folderId }) => update(state, { folders: { $apply: folders => folders.filter(f => f.id !== folderId) } });

const setTemplatesData = (state, { payload: { templates, totalCount, folders, currentFolder } }) => update(state, {
  templates: {
    $set: {
      data: templates,
      total: totalCount,
    },
  },
  folders: { $set: folders },
  currentFolder: { $set: currentFolder },
  folderDialogData: {
    $set: {
      folders,
      currentFolder,
    },
  },
});


const addFolder = (state, { folder }) => update(state, {
  folders: { $unshift: [folder] },
  folderDialogData: { folders: { $unshift: [folder] } },
});

const goToFolder = (state, { folders, currentFolder }) => update(state, {
  folderDialogData: {
    $set: {
      folders,
      currentFolder,
    },
  },
});

const deleteDocumentById = (state, { id: documentId }) => {
  const documentIndex = _findIndex(state.documents, doc => doc.id === documentId);
  return update(state, { documents: { $splice: [[documentIndex, 1]] } });
};

/* ------------- Hookup Reducers To Types ------------- */
export default createReducer(INITIAL_STATE, {
  [Types.SET_CURRENT_FOLDER]: setCurrentFolder,
  [Types.SET_FOLDERS]: setFolders,
  [Types.SET_TEMPLATES]: setTemplates,
  [Types.FETCH_CURRENT_TEMPLATE_SUCCESS]: setCurrentTemplate,
  [Types.FETCH_TEMPLATES_VERSION_HISTORY_REQUEST]: setEmptyTemplatesVersionHistory,
  [Types.FETCH_TEMPLATES_VERSION_HISTORY_SUCCESS]: setTemplatesVersionHistory,
  [Types.FETCH_DOCUMENTS_FOR_TEMPLATE_SUCCESS]: setDocumentsForTemplate,
  [Types.SELECT_FOLDER]: setSelectedFolder,
  [Types.TEMPLATE_SETTINGS_SUCCESS]: setTemplateSettings,
  [Types.TEMPLATE_SETTINGS_PROGRESS]: setTemplateSettingsProgress,
  [Types.CREATE_TEMPLATE_FOLDER_SUCCESS]: addFolder,
  [Types.UPDATE_FOLDER_NAME_SUCCESS]: setFolderName,
  [Types.DELETE_FOLDER_SUCCESS]: deleteFolder,
  [Types.FETCH_TEMPLATES_INFO_SUCCESS]: setTemplatesData,
  [Types.VALIDATE_SHARING_TEMPLATE_SUCCESS]: setSharingTemplateData,
  [Types.VALIDATE_SHARING_TEMPLATE_FAILURE]: setInvalidSharingTemplate,
  [EditorTypes.SET_TEMPLATE_VERSION_DATA]: ((state, { data }) => setTemplateSettings(state, { settings: data.qtv_property })),
  [Types.GO_TO_FOLDER_SUCCESS]: goToFolder,
  [Types.TOGGLE_SHARING_TEMPLATE_FROM_LIST_REQUEST]: setSharingTemplate,
  [Types.TOGGLE_SHARING_TEMPLATE_FROM_LIST_FAILURE]: setSharingTemplate,
  [Types.DELETE_DOCUMENT_SUCCESS]: deleteDocumentById,
  [Types.CLEAR_TEMPLATES_STATE]: clearTemplatesState,
});
