import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const StyledCursorNotification = styled.div`
  position: absolute;
  padding: 10px;
  background-color: ${props => props.theme.mainColor};
  z-index: 4;
  text-align: center;
  color: ${props => props.theme.font.white};
  opacity: 0.8;
`;

export default class CursorNotification extends React.Component {
  state = { x: 0, y: 0 };

  componentDidMount() {
    $('body').on('mousemove.cursor-notification', this.trackMousePosition);
    $('body').on('mouseleave.cursor-notification', () => this.setState({ display: 'none' }));
    $('body').on('mouseenter.cursor-notification', () => this.setState({ display: 'block' }));
  }

  componentWillUnmount() {
    $('body').off('mousemove.cursor-notification');
    $('body').off('mouseleave.cursor-notification');
    $('body').off('mouseenter.cursor-notification');
  }

  trackMousePosition = (e) => {
    this.setState({
      x: e.pageX,
      y: e.pageY,
    });
  };

  render() {
    return (
      <StyledCursorNotification
        style={{
          top: this.state.y + 20,
          left: this.state.x,
          display: this.state.display,
        }}>
        <FormattedMessage id="visualization.logics_manager.cursor_notification" />
      </StyledCursorNotification>
    );
  }
}
