import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import styled from 'styled-components';
import { legacyMedia } from 'shared/styled-helpers';
import DefaultIntroduction from './DefaultIntroduction';
import styles from './index.css';

const StyledButton = styled(Button)`
  ${legacyMedia.sm`
      width: 100%;
  `}
`;

const QuestionnaireIntroduction = ({
  className,
  documentName,
  signingEnabled,
  onStartClick,
  introductionContent,
  ...props
}) => {
  const classString = cn(styles.component, className);

  return (
    <div className={classString} {...props}>
      {introductionContent ? (
        <div dangerouslySetInnerHTML={{ __html: introductionContent }} />
      ) : (
        <DefaultIntroduction
          documentName={documentName}
          signingEnabled={signingEnabled} />
      )}
      <StyledButton
        onClick={onStartClick}
        testId="QuestionnaireIntroduction-start">
        <FormattedMessage id="questionnaire.questions.buttons.begin" />
      </StyledButton>
    </div>
  );
};

QuestionnaireIntroduction.propTypes = {
  onStartClick: PropTypes.func.isRequired,
  introductionContent: PropTypes.string,
  documentName: PropTypes.string.isRequired,
  signingEnabled: PropTypes.bool.isRequired,
};

export default QuestionnaireIntroduction;
