exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-containers-ErrorTemplateNotActive-___index__component___yo2bF {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.app-containers-ErrorTemplateNotActive-___index__logo___KLjvz {\n  width: 280px;\n  height: 90px;\n  margin-bottom: 45px;\n}\n\n.app-containers-ErrorTemplateNotActive-___index__heading___3ttay {\n  color: #171717;\n  color: var(--c-bw-90);\n}\n\n.app-containers-ErrorTemplateNotActive-___index__paragraph___3Caf6 {\n  color: #767676;\n  color: var(--c-bw-50);\n}\n\n.app-containers-ErrorTemplateNotActive-___index__picture___3LRNG {\n  max-width: 300px;\n  margin-bottom: 45px;\n  color: #2d866e;\n  color: var(--c-brand);\n}\n", "", {"version":3,"sources":["/workspace/app/containers/ErrorTemplateNotActive/index.css"],"names":[],"mappings":"AAAA;EACE,cAAa;EACb,YAAW;EACX,cAAa;EACb,uBAAsB;EACtB,wBAAuB;EACvB,oBAAmB;CACrB;;AAEA;EACE,aAAY;EACZ,aAAY;EACZ,oBAAmB;CACrB;;AAEA;EACE,eAAqB;EAArB,sBAAqB;CACvB;;AAEA;EACE,eAAqB;EAArB,sBAAqB;CACvB;;AAEA;EACE,iBAAgB;EAChB,oBAAmB;EACnB,eAAqB;EAArB,sBAAqB;CACvB","file":"index.css","sourcesContent":[".component {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.logo {\n  width: 280px;\n  height: 90px;\n  margin-bottom: 45px;\n}\n\n.heading {\n  color: var(--c-bw-90);\n}\n\n.paragraph {\n  color: var(--c-bw-50);\n}\n\n.picture {\n  max-width: 300px;\n  margin-bottom: 45px;\n  color: var(--c-brand);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-containers-ErrorTemplateNotActive-___index__component___yo2bF",
	"logo": "app-containers-ErrorTemplateNotActive-___index__logo___KLjvz",
	"heading": "app-containers-ErrorTemplateNotActive-___index__heading___3ttay",
	"paragraph": "app-containers-ErrorTemplateNotActive-___index__paragraph___3Caf6",
	"picture": "app-containers-ErrorTemplateNotActive-___index__picture___3LRNG"
};