import assert from 'assert';
import previewHtmlSelector, { stylesheetSelector } from 'bundles/shared/utils/questionnairePreviewSelector';
import { FILE_TYPE } from 'ducks/exportFile/constants';
import { questionnaireSelector } from 'ducks/questionnaire/selectors';
import { selectCurrentDocumentVersion } from 'pages/DocumentDetails/selectors';
import {
  headerAndFooterHeightSelector,
  headerAndFooterHtmlSelector,
  pageMarginsSelector,
} from 'pages/DocumentStyles/selectors';
import { call, put, select, take } from 'redux-saga/effects';
import openUrl from 'shared/helpers/openUrl';
import { fixQuestionHighlightingStyle } from 'utils/exportDOCXutils';
import {
  appendMmToFilteredValues,
  convertPtFontSizesToPxValue,
  mmToPx,
  replacePaginationForPuppeteer,
} from 'utils/exportPdfUtils';
import { Creators, Types } from '../actions';

export default function createSagas({ questionnaireService, documentExportService, trackingService }) {
  assert.ok(questionnaireService, 'questionnaireService is required');
  assert.ok(documentExportService, 'documentExportService is required');
  assert.ok(trackingService, 'trackingService is required');

  // For now, this saga is only being used to download document versions as PDFs. Eventually, all PDF generation will be moved here.
  function* exportPdfSaga() {
    while (true) {
      try {
        const { fileName } = yield take(Types.EXPORT_PDF_REQUEST);
        // eslint-disable-next-line camelcase
        const { userQuestionnaire: { id, questionnaire_template_version } } = yield select(questionnaireSelector);
        const { html, options } = yield call(prepareContentAndPageOptionsForPdf);

        const { data: { access_token: accessToken } } = yield call([questionnaireService, questionnaireService.getDownloadAttachmentToken], id);
        const { data: fileBlob } = yield call(
          [documentExportService, documentExportService.htmlToPdf],
          html,
          options,
          accessToken,
        );
        const blobURL = yield call(window.URL.createObjectURL, fileBlob);
        trackingService.track('DocumentDownloaded', { templateName: questionnaire_template_version.name });
        openUrl(blobURL, `${fileName}.${FILE_TYPE.PDF.toLowerCase()}`);
        yield put(Creators.exportPdfSuccess(blobURL));
      } catch (e) {
        yield put(Creators.exportPdfFailure(e));
      }
    }
  }

  return { exportPdfSaga };
}

export function* prepareContentAndPageOptionsForPdf() {
  const { isQuestionnaire, html: currentVersionHtml } = yield select(selectCurrentDocumentVersion);
  const { settings: { document_highlighting_enabled: documentHighlightingEnabled } } = yield select(questionnaireSelector);
  let html = isQuestionnaire ? yield select(previewHtmlSelector) : currentVersionHtml;
  const styles = yield select(stylesheetSelector);
  const { header, footer } = yield select(headerAndFooterHtmlSelector);
  const displayHeaderFooter = !!header || !!footer;
  if (documentHighlightingEnabled && isQuestionnaire) {
    html = yield call(fixQuestionHighlightingStyle, html);
  }

  /* eslint-disable camelcase */
  const { margin_top, margin_bottom, margin_left, margin_right } = appendMmToFilteredValues(
    yield select(pageMarginsSelector),
  );

  const { header_height, footer_height } = appendMmToFilteredValues(yield select(headerAndFooterHeightSelector));

  const margin = {
    top: header ? header_height : margin_top,
    bottom: footer ? footer_height : margin_bottom,
    left: margin_left,
    right: margin_right,
  };
  /* eslint-enable camelcase */

  // Puppeteer will render default header or footer if passed an empty string.
  // To prevent this, empty strings should be converted to contain some invisible content.
  const sanitiseEmptyContent = el => el || '&nbsp;';

  const fonts = `<link href="https://fonts.googleapis.com/css?family=Arimo|Lato|Open+Sans|Raleway|Roboto|Tinos&display=swap" rel="stylesheet">`;

  const styledHtml = content => `
        <!DOCTYPE html>
        <html>
        <head>${fonts}
        ${!isQuestionnaire ? `<style>${styles}</style>` : ``}
        </head>
        <body>${content}</body>
        </html>`;

  const styledHeaderOrFooter = (content, contentId) => `
      <style>${styles}</style>
      <div id="${contentId}" class="__document_template_dynamic_stylesheet" style="width:100%; margin-left: ${mmToPx(
  margin_left,
)}px; margin-right: ${mmToPx(margin_right)}px;">${replacePaginationForPuppeteer(content)}</div>`;

  const options = {
    format: 'A4',
    margin,
    displayHeaderFooter,
    headerTemplate: sanitiseEmptyContent(
      convertPtFontSizesToPxValue(styledHeaderOrFooter(header, 'document-template-header')),
    ),
    footerTemplate: sanitiseEmptyContent(
      convertPtFontSizesToPxValue(styledHeaderOrFooter(footer, 'document-template-footer')),
    ),
  };

  return {
    html: styledHtml(html),
    options,
  };
}
