import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { Field } from 'formik';
import Switch from 'components/form/Switch';
import Tooltip from 'components/Tooltip';
import Typography, { VARIANTS } from 'components/Typography';
import HelpIcon from 'components/HelpIcon';
import InputCheckbox from 'components/form/InputCheckbox';

import styles from './TemplateSettings.css';

export const EnableDocumentEditing = ({ values }) => (
  <div className={cn(styles.fieldWrapper, styles.column)}>
    <div>
      <Field name="edit_document_enabled">
        {({ field }) => (
          <Fragment>
            <Typography variant={VARIANTS.LABEL}>
              <FormattedMessage id="template_settings.edit_document_enabled" />
            </Typography>
            <Switch
              checked={field.value}
              testId="EnableDocumentEditing-switch"
              {...field} />
            <Tooltip title={<FormattedMessage id="template_settings.edit_document_tooltip" />}>
              <HelpIcon />
            </Tooltip>
          </Fragment>
        )}
      </Field>
    </div>
    { values.edit_document_enabled && (
    <div>
      <Field name="edit_document_enabled_for_consumer">
        {({ field }) => (
          <Fragment>
            <InputCheckbox
              {...field}
              checked={field.value}
              key="edit_document_enabled_for_consumer"
              testId="EnableDocumentEditingForConsumer-checkbox"
              label={(
                <Typography variant={VARIANTS.LABEL}>
                  <FormattedMessage id="template_settings.edit_document_enabled_for_consumer" />
                </Typography>
              )} />
          </Fragment>
        )}
      </Field>
    </div>
    ) }
  </div>
);
