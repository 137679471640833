import assert from 'assert';
import { all, put, call, takeLatest } from 'redux-saga/effects';
import { Creators, Types } from './actions';

export default function createSagas({ genericAccessService, notificationService }) {
  assert.ok(genericAccessService, 'genericAccessService is required');
  assert.ok(notificationService, ' notificationService is required');

  function* resendLinkSaga({ id }) {
    try {
      yield call([genericAccessService, genericAccessService.resendLink], id);
      yield put(notificationService.success({
        message: 'resend_link.success',
        position: 'bl',
        useTranslate: true,
      }));
      yield put(Creators.resendLinkSuccess());
    } catch (e) {
      const { data, status } = e;
      const messageId = (status === 404 || (data && data.message) === 'record_not_found')
        ? 'shared.errors.document_in_trash.title'
        : 'resend_link.error';
      yield put(notificationService.error({
        message: messageId,
        position: 'bl',
        useTranslate: true,
      }));
      yield put(Creators.resendLinkFailure(e));
    }
  }

  function* root() {
    yield all([
      takeLatest(Types.RESEND_LINK_REQUEST, resendLinkSaga),
    ]);
  }

  return { errorTemplateNotActiveSagas: root };
}
