import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';

const PageBreakWrapper = styled.section`
  width: inherit;
  .page-break {
    display: block;
    height: 20px;
    width: 100%;
    position: relative;
    text-align: center;
    &::before {
      color: ${p => p.theme.lightGray};
      content: '${p => p.pageBreak}';
      background: white;
      padding: 5px 15px;
      position: relative;
      z-index: 2;
      }
    &::after {
        content: '';
        border-bottom: 2px dotted ${p => p.theme.lightGray};
        width: 100%;
        top: 50%;
        margin-top: -1px;
        left: 0;
        z-index: 0;
        position: absolute;
      }
}
`;

const WithPageBreak = ({ intl, children }) => (
  <PageBreakWrapper pageBreak={intl.formatMessage({ id: 'shared_components.page_break' })}>
    {children}
  </PageBreakWrapper>
);

WithPageBreak.propTypes = {
  children: PropTypes.node,
  intl: intlShape.isRequired,
};

export default injectIntl(WithPageBreak);
