import React from 'react';
import PropTypes from 'prop-types';
import proptypes from 'utils/proptypes';
import FlexCenter from 'shared/Components/FlexCenter';
import Option from './Option';
import styles from './question.css';

const optionAssigned = optionId => $(`[data-showif="${optionId}"]`).length > 0;

const Question = ({ question, index, enableTriggersFn, selectedNodeWithOption }) => (
  <div className={styles.component}>
    <div className={styles.header} data-test-id="LogicsManager-LogicsTypes-AnchorType-Question">
      <FlexCenter className={styles.index}>
        {index}
      </FlexCenter>
      <div className={styles.title}>{question.name}</div>
    </div>
    <div className={styles.options}>
      {question.details.options.filter(option => optionAssigned(option.id)).map((option, i) => (
        <Option
          option={option}
          index={i}
          selectedNodeWithOption={selectedNodeWithOption}
          key={option.id}
          onClick={() => enableTriggersFn(option.id, question)} />
      ))}
    </div>
  </div>
);

Question.propTypes = {
  question: proptypes.questionScheme,
  enableTriggersFn: PropTypes.func.isRequired,
  selectedNodeWithOption: proptypes.domElement,
  index: PropTypes.number.isRequired,
};
export default Question;
