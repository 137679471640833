import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'qs';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import Modal, { ModalContent, ModalFooter } from 'components/Modal';
import Button, { SECONDARY } from 'components/Button';
import InputText from 'components/form/InputText';
import Typography, { VARIANTS } from 'components/Typography/index';
import { Creators as DocumentsActions, Types as DocumentsActionTypes } from 'pages/Documents/actions';
import { isLoading as isLoadingSelector, getAction } from 'utils/statusSelectors';

import styles from './index.css';


export const DuplicateDocument = ({
  open,
  documentId,
  documentName,
  basedOnLatestTemplateVersion,
  duplicateDocument,
  toggleModal,
  documentDuplicateCompleted,
  match,
  location,
  history,
  fetchDocuments,
}) => {
  const [newDocumentName, setNewDocumentName] = useState(`${documentName} - copy`);
  const [errorMessage, setErrorMessage] = useState('');
  const onDocumentNameChange = (e) => {
    setNewDocumentName(e.target.value);
  };
  const onSubmit = () => {
    if (!newDocumentName || newDocumentName.length === 0) {
      setErrorMessage('required');
      return;
    }
    duplicateDocument(documentId, newDocumentName);
  };

  useEffect(() => {
    if (documentDuplicateCompleted) {
      const query = queryString.parse(location.search, { ignoreQueryPrefix: true });
      // the if here is because the fetching data for documents is done through DataProvider - this component watches location change
      //  and if search params change then it fetches data. So in this case, if user is on page 1 and does a push to page 1 again, the fetch won't work
      // that's why the manual fetch.
      if (query.page && query.page !== 1) {
        history.push(match.url, { page: 1 });
      } else {
        fetchDocuments(match.params.folderId);
      }
      toggleModal();
    }
  }, [documentDuplicateCompleted]);

  return (
    <Modal
      open={open}
      toggleModal={toggleModal}
      size="medium"
      hiddenCloseButton>
      <ModalContent>
        <Typography variant={VARIANTS.H2} className={styles.title}>
          <FormattedMessage id="documents_list.duplicate_document.title" />
        </Typography>
        <div className={styles.subheading}>
          <Typography variant={VARIANTS.BODY_SMALL}>
            <FormattedMessage id="documents_list.duplicate_document.subheading" />
          </Typography>
          { !basedOnLatestTemplateVersion && (
          <Typography variant={VARIANTS.BODY_SMALL} className={styles.italic}>
            <FormattedMessage id="documents_list.duplicate_document.copying_from_old_template_version" />
          </Typography>
          )}
        </div>
        <div>
          <Typography variant={VARIANTS.LABEL}>
            <FormattedMessage id="documents_list.duplicate_document.label" />
          </Typography>
          <InputText
            value={newDocumentName}
            onChange={onDocumentNameChange}
            errorMessage={errorMessage}
            testId="DuplicateDocument-name" />
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={toggleModal}
          variant={SECONDARY}
          testId="DuplicateDocument-cancel">
          <FormattedMessage id="shared.cancel" />
        </Button>
        <Button
          type="submit"
          onClick={onSubmit}
          testId="DuplicateDocument-duplicate">
          <FormattedMessage id="documents_list.duplicate_document.copy" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DuplicateDocument.propTypes = {
  duplicateDocument: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  documentName: PropTypes.string,
  open: PropTypes.bool,
  basedOnLatestTemplateVersion: PropTypes.bool,
};

const mapDispatchToProps = {
  duplicateDocument: DocumentsActions.duplicateDocumentRequest,
  fetchDocuments: DocumentsActions.fetchDocumentsRequest,
};
const mapStateToProps = state => ({ documentDuplicateCompleted: isLoadingSelector(state, getAction(DocumentsActionTypes.DUPLICATE_DOCUMENT_REQUEST)) });

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(DuplicateDocument);
