import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import previewHtmlSelector from 'shared/utils/questionnairePreviewSelector';
import Tooltip from 'tooltip.js';
import getNearestQuestionTagFromNode from 'Visualization/Editor-v2/LogicsManager/LogicsTypes/TriggersFn/utils/getNearestQuestionTagFromNode';
import WithPageBreak from 'shared/Components/WithPageBreak';
import { withAcl } from 'services/acl';
import { Creators as QuestionnaireActions } from 'ducks/questionnaire/actions';
import { selectDocumentStatus, selectDocumentVersions } from 'pages/DocumentDetails/selectors';
import {
  ERROR,
  EXPIRED,
  REJECTED,
  STARTED,
  COMPLETED,
  SIGNED,
  SENT_FOR_SIGNING,
} from 'shared/constants/questionnaireStatus';
import { Wrapper, PreviewContainerWithTooltip } from './styled-components';

class BigPreview extends Component {
  static propTypes = {
    html: PropTypes.string,
    intl: intlShape.isRequired,
    goToQuestion: PropTypes.func.isRequired,
    startEditQuestion: PropTypes.func.isRequired,
    userQuestionnaire: PropTypes.shape({
      status: PropTypes.string,
      error_details: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.string) }).isRequired,
      questionnaire_template_version: PropTypes.shape({
        questions: PropTypes.shape({ name: PropTypes.string }),
        version: PropTypes.number,
      }),
    }),
    userQuestionnaireStatus: PropTypes.oneOf([ERROR, EXPIRED, REJECTED, STARTED, SIGNED, SENT_FOR_SIGNING, COMPLETED]),
  };

  componentDidMount() {
    const disabledTooltip = [SENT_FOR_SIGNING, SIGNED].includes(this.props.userQuestionnaire.status)
      || this.props.documentVersions.length > 1;
    if (disabledTooltip) {
      return;
    }
    let tooltip;
    const { createQuestionTooltip, getQuestionFromRelatedTarget } = this;
    const container = $('#document-template')[0];
    $('[data-logictype="choiceShowIfType"], [data-logictype="inputReplaceType"]')
      .each(function tooltipHandler() {
        const questionElement = $(this);
        questionElement.mouseenter((event) => {
          event.stopPropagation();
          if (tooltip) {
            tooltip.dispose();
          }
          tooltip = createQuestionTooltip(questionElement, container);
        });
        questionElement.mouseleave((event) => {
          const { target, targetDataLogicType } = getQuestionFromRelatedTarget(event);
          if (targetDataLogicType === 'choiceShowIfType' || targetDataLogicType === 'inputReplaceType') {
            if (tooltip) {
              tooltip.dispose();
            }
            tooltip = createQuestionTooltip(target, container);
            tooltip.show();
          }
        });
      });
  }

  isQuestionnaireEditable = questionnaireStatus => [ERROR, COMPLETED, EXPIRED, REJECTED, STARTED]
    .includes(questionnaireStatus)
    && this.props.documentVersions.length <= 1; // questionnaire is the only version

  getQuestionFromRelatedTarget = (event) => {
    const relatedTarget = $(event.relatedTarget);
    const parentTarget = relatedTarget.parent();
    let targetDataLogicType;
    let target;
    const relatedTargetDataLogicType = relatedTarget.attr('data-logictype');
    const parentDataLogicType = parentTarget.attr('data-logictype');
    if (parentDataLogicType) {
      target = parentTarget;
      targetDataLogicType = parentDataLogicType;
    } else if (relatedTargetDataLogicType) {
      target = relatedTarget;
      targetDataLogicType = relatedTargetDataLogicType;
    }
    return { target, targetDataLogicType };
  };

  onPreviewClick = (e) => {
    const { userQuestionnaire, startEditQuestion, goToQuestion } = this.props;
    if (this.isQuestionnaireEditable(userQuestionnaire.status)) {
      const nearestQuestionTag = getNearestQuestionTagFromNode(e.target);
      const dataQuestionId = nearestQuestionTag && nearestQuestionTag.getAttribute('data-question-id');
      if (dataQuestionId) {
        e.preventDefault();
        startEditQuestion();
        goToQuestion(dataQuestionId);
      }
    }
  };

  createQuestionTooltip = (target, container) => {
    const { intl, userQuestionnaire, userQuestionnaireStatus } = this.props;
    const { questions } = userQuestionnaire.questionnaire_template_version;
    const question = questions.find(q => q.id === target.attr('data-question-id')).name;
    return new Tooltip(target, {
      placement: 'top',
      container,
      title: userQuestionnaireStatus === SENT_FOR_SIGNING ? intl.formatMessage({ id: 'questionnaire.document_sent_for_signing_answer_tooltip_message' }) : `${intl.formatMessage({ id: 'questionnaire.open' })} "${question}"`,
      boundariesElement: container,
    });
  };


  render() {
    const { userQuestionnaire } = this.props;
    return (
      <WithPageBreak>
        <Wrapper>
          <PreviewContainerWithTooltip
            isClickablePreview={this.isQuestionnaireEditable(userQuestionnaire.status)}
            id="document-template"
            dangerouslySetInnerHTML={{ __html: this.props.html }}
            onClick={this.onPreviewClick}
            isVisible />
        </Wrapper>
      </WithPageBreak>
    );
  }
}

const mapStateToProps = state => ({
  html: previewHtmlSelector(state),
  userQuestionnaire: state.questionnaire.userQuestionnaire,
  documentVersions: selectDocumentVersions(state),
  userQuestionnaireStatus: selectDocumentStatus(state),
});

const mapDispatchToProps = dispatch => ({
  goToQuestion: questionId => dispatch(QuestionnaireActions.goToQuestion(questionId)),
  startEditQuestion: () => dispatch(QuestionnaireActions.startEditQuestion()),
});

export default compose(injectIntl, withAcl, connect(mapStateToProps, mapDispatchToProps))(BigPreview);
