import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { createClassNameModifiers } from 'utils/componentUtils';
import proptypes from 'utils/proptypes';

import styles from './index.css';

export const INLINE = 'inline';

class FieldRow extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    modifiers: proptypes.arrayOfModifiers([INLINE]),
  }

  static defaultProps = { modifiers: [] }

  render() {
    const {
      className,
      children,
      modifiers,
      ...otherProps
    } = this.props;

    const formattedModifiers = createClassNameModifiers({ modifiers, styles });
    const classString = cn(className, ...formattedModifiers);

    return (
      <div {...otherProps} className={cn(classString, styles.component)}>
        {children}
      </div>
    );
  }
}

export default FieldRow;
