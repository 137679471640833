import React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { FormattedMessage } from 'react-intl';
import GoToPreviousQuestionLogic from 'Questionnaire2/GoToPreviousQuestionLogic';
import Button, { TEXT, SMALL } from 'components/Button';
import styles from './PreviousQuestion.css';

const PreviousQuestion = () => (
  <GoToPreviousQuestionLogic
    render={goToPreviousQuestion => (
      <Button
        glyph={ArrowBack}
        onClick={goToPreviousQuestion}
        variant={TEXT}
        size={SMALL}
        testId="Questionnaire-previousQuestion-button"
        className={styles.component}>
        <FormattedMessage id="questionnaire.previous_question" />
      </Button>
    )} />
);
export default PreviousQuestion;
