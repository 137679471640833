import { createStructuredSelector } from 'reselect';
import {
  selectIsUserAuthorized,
  selectHasFetchedUser,
  selectUserRoles,
  selectError,
  selectUserData,
} from 'ducks/loggedInUser/selectors';
import { selectQuestionnaireFeedbackModule } from 'ducks/questionnaire/selectors';

export default createStructuredSelector({
  user: selectUserData,
  isUserAuthorized: selectIsUserAuthorized,
  hasFetchedUser: selectHasFetchedUser,
  userRoles: selectUserRoles,
  error: selectError,
  isChatEnabled: selectQuestionnaireFeedbackModule,
});
