import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Folder from '@material-ui/icons/Folder';
import styles from './DocumentCell.css';

const getFolderBreadcrumbs = (folder, folderId) => {
  let { name } = folder;
  if (folder.parents != null && folder.parents.length > 0 && folder.parents[0].id !== folderId) {
    name = `${getFolderBreadcrumbs(folder.parents[0], folderId)} / ${name}`;
  }
  return name;
};

export const DocumentCellComponent = ({ document, folderId, intl }) => {
  const showFolderBreadcrumbs = document.documentFolders && document.documentFolders.id !== folderId;

  return (
    <div className={styles.component}>
      <div className={styles.name}>
        <strong>{document.name || document.templateName}</strong>
      </div>
      <div className={styles.documentSubheader}>
        <div>
          {`${intl.formatMessage({ id: 'documents_list.based_on' })}: ${document.templateName}`}
          /
          {`${intl.formatMessage({ id: 'documents_list.version' })}: ${document.templateVersion}`}
        </div>
        {showFolderBreadcrumbs && (
          <div className={styles.breadcrumbContainer}>
            <Folder className={styles.folderIcon} />
            {getFolderBreadcrumbs(document.documentFolders, folderId)}
          </div>
        )}
      </div>
    </div>
  );
};

DocumentCellComponent.propTypes = {
  document: PropTypes.shape({
    name: PropTypes.string,
    basedOnTemplate: PropTypes.string,
    basedOnVersion: PropTypes.number,
    documentFolders: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
  intl: intlShape.isRequired,
  folderId: PropTypes.string,
};

export default injectIntl(DocumentCellComponent);
