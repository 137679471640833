import { createActions } from 'reduxsauce';

const REDUX_NAMESPACE = 'app/questionnaire/';
const REDUXACTIONS_STRUCTURE = {
  init: ['userQuestionnaire'], // saga
  createUserQuestionnaire: ['templateId'], // saga
  createUserQuestionnaireSuccess: null,
  createUserQuestionnaireFailure: ['error'],
  fetchUserQuestionnaire: ['userQuestionnaireId', 'mode'], // saga
  fetchUserQuestionnaireSuccess: ['userQuestionnaire'],
  fetchUserQuestionnaireFailure: ['error', 'redirectOnErrorPath'],
  fetchUserQuestionnaireFinish: null,

  downloadAttachmentRequest: ['format'], // saga
  downloadAttachmentSuccess: null,
  downloadAttachmentFailure: ['error'],
  downloadSignedDocument: null,
  downloadSignedDocumentSuccess: null,
  downloadSignedDocumentFailure: ['error'],

  fetchDocumentStylesRequest: null, // saga
  fetchDocumentStylesSuccess: ['stylesheet'],
  fetchDocumentStylesFailure: ['error'],

  fetchUserQuestionnairePreviewForTemplate: ['qtId'], // saga
  fetchUserQuestionnairePreviewForTemplateSuccess: null, // saga
  fetchUserQuestionnairePreviewForTemplateFailure: ['error'], // saga
  findNextQuestion: null, // saga
  findNextQuestionFailure: ['error'],
  findPreviousQuestion: ['answerIndex'], // saga
  findPreviousQuestionFailure: ['error'],

  goToQuestion: ['questionId'],

  updateUserQuestionnaireName: ['userQuestionnaireId', 'name'], // saga
  updateUserQuestionnaireRequest: ['userQuestionnaireId', 'params'], // saga
  updateUserQuestionnaireSuccess: ['params'],
  updateUserQuestionnaireFailure: ['error'],
  clearUserQuestionnaire: null,

  setFinished: ['bool'],
  setMode: ['mode'],
  setQuestionsFlow: ['questionsFlow'],

  startSavingAnswer: null,
  saveAnswer: ['params'], // saga
  saveAnswerSuccess: ['answer'],
  saveAnswerFailure: ['error'],

  updateAnswer: ['params'], // saga
  updateAnswerSuccess: ['answer'],
  updateAnswerFailure: ['error'],

  resetFieldValue: ['id'],

  setCurrentQuestion: ['question'],
  storeRemovedAnswers: ['remainingAnswers', 'removedAnswers'],

  removeAllAnswersFailure: ['error'],
  getPreviewFailure: ['error'],

  fetchDocumentPreviewPdfRequest: ['questionnaireId', 'format'],
  fetchDocumentPreviewPdfSuccess: ['data'],
  fetchDocumentPreviewPdfFailure: ['error'],

  signDocumentRequest: ['values'], // saga
  signDocumentRequestSuccess: null,
  signDocumentRequestFailure: ['error'],

  fetchCompany: null, // saga,
  fetchCompanySuccess: ['company'],
  fetchCompanyFailure: ['error'],

  setDocumentCompleted: null,
  setDocumentCompletedSuccess: null,
  setDocumentCompletedFailure: ['error'],
  setDocumentStarted: null,
  setDocumentStartedSuccess: null,
  setDocumentStartedFailure: ['error'],
  cancelSigningRequest: ['questionnaireId'], // saga
  cancelSigningSuccess: null, // saga
  cancelSigningFailure: ['error'], // saga
  setDocumentNotReady: ['has'],

  startEditQuestion: null,
  logToRollbar: ['meta'],

  trackTogglePreview: ['destination'],
  updateHtmlContent: ['html'],
  saveVersionRequest: null,
};

export const { Types, Creators } = createActions(REDUXACTIONS_STRUCTURE, { prefix: REDUX_NAMESPACE });
