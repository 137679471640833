import { createActions } from 'reduxsauce';

export const REDUX_NAMESPACE = 'app/Documents/';

export const REDUXACTIONS_STRUCTURE = {

  fetchDocumentsRequest: ['documentFolderSlug', 'searchParams'],
  fetchDocumentsSuccess: ['data'],
  fetchDocumentsFailure: ['error'],

  // this is to handle search with the lexolve-server api, as the questionnaire-manager doesn't support search currently
  searchDocumentsRequest: ['query', 'folderSlug', 'page'],
  searchDocumentsSuccess: ['data'],
  searchDocumentsFailure: ['error'],

  // remove the next three actions after new document api is added - these are the current actions for the three above
  fetchDocumentsInfoRequest: ['payload', 'toast'],
  fetchDocumentsInfoSuccess: ['payload'],
  fetchDocumentsInfoFailure: ['error'],

  fetchDocumentsPageRequest: ['link'],
  fetchDocumentsPageSuccess: ['data'],
  fetchDocumentsPageFailure: ['error'],

  deleteDocumentRequest: ['id'],
  deleteDocumentSuccess: ['id'],
  deleteDocumentFailure: ['error'],

  restoreFromTrashRequest: ['trashId'],
  restoreFromTrashSuccess: ['trashId'],
  restoreFromTrashFailure: ['error'],

  selectTemplateForDocumentRequest: ['templateId', 'folderId'],
  selectTemplateForDocumentSuccess: null,
  selectTemplateForDocumentFailure: ['error'],

  createGenericAccessDocumentRequest: ['documentName', 'email', 'templateSharingSlug'],
  createGenericAccessDocumentSuccess: ['userQuestionnaire'],
  createGenericAccessDocumentFailure: ['error'],

  duplicateDocumentRequest: ['documentId', 'documentName'],
  duplicateDocumentSuccess: null,
  duplicateDocumentFailure: ['error'],

  /* Folder actions */
  setFolders: ['folders'],

  fetchFoldersRequest: null,
  fetchFoldersSuccess: ['folders'],
  fetchFoldersFailure: ['error'],

  createDocumentFolderRequest: ['name', 'parentId'],
  createDocumentFolderSuccess: ['folder'],
  createDocumentFolderFailure: ['error'],

  updateFolderNameRequest: ['folderId', 'folderName'],
  updateFolderNameSuccess: ['folderId', 'folderName'],
  updateFolderNameFailure: ['error'],

  deleteFolderRequest: ['folderId'],
  deleteFolderSuccess: ['folderId'],
  deleteFolderFailure: ['error'],

  /* Move to folder actions */
  selectFolder: ['folderId'],

  goToFolderRequest: ['folderId'],
  goToFolderSuccess: ['folders', 'currentFolder'],
  goToFolderFailure: ['error'],

  moveToFolderRequest: ['documentId', 'folderId', 'currentFolderId'],
  moveToFolderSuccess: null,
  moveToFolderFailure: ['error'],
};

export const { Types, Creators } = createActions(REDUXACTIONS_STRUCTURE, { prefix: REDUX_NAMESPACE });
