import { createActions } from 'reduxsauce';

const REDUX_NAMESPACE = 'app/errorTemplateNotActive/';
const REDUXACTIONS_STRUCTURE = {
  resendLinkRequest: ['id'],
  resendLinkSuccess: null,
  resendLinkFailure: ['error'],
};

export const { Types, Creators } = createActions(REDUXACTIONS_STRUCTURE, { prefix: REDUX_NAMESPACE });
