import { call, put, take } from 'redux-saga/effects';
import { Creators as FlowActions } from 'containers/Flow/actions';
import { TEMPLATE_EDITOR_ID } from 'appRoot/constants';
import { Creators as QuestionActions, Types as QuestionsTypes } from './QuestionsRedux';
import { Creators as EditorActions } from '../reducer';

export default function createSagas({ questionService }) {
  function* createQuestion() {
    while (true) {
      const { params } = yield take(QuestionsTypes.ADD_NEW_QUESTION);
      try {
        const { data: { question } } = yield call(questionService.createQuestion, params);
        yield put(QuestionActions.addNewQuestionSuccess(question));
        yield put(QuestionActions.setCurrentQuestion(question));
      } catch (e) {
        yield put(QuestionActions.addNewQuestionFailure(e));
        yield put(EditorActions.saveEditorContentFailure(e));
      }
    }
  }

  function* updateQuestion() {
    while (true) {
      const { questionId, params } = yield take(QuestionsTypes.UPDATE_QUESTION);
      try {
        const response = yield call(questionService.updateQuestion, questionId, params);
        yield put(QuestionActions.updateQuestionSuccess(questionId, response.data.question));
        yield put(QuestionActions.setCurrentQuestion(response.data.question));
        // TODO: when adding new question popup with another editor block main editor on change event
        const templateEditorContent = tinymce.get(TEMPLATE_EDITOR_ID).getContent(); // eslint-disable-line
        yield put(EditorActions.saveEditorContent(templateEditorContent));
      } catch (e) {
        yield put(QuestionActions.updateQuestionFailure(e));
        yield put(EditorActions.saveEditorContentFailure(e));
      }
    }
  }

  function* removeQuestion() {
    while (true) {
      const { questionId } = yield take(QuestionsTypes.REMOVE_QUESTION);
      try {
        yield call(questionService.deleteQuestion, questionId);
        yield put(QuestionActions.removeQuestionSuccess(questionId));
        yield put(FlowActions.regenerateFlow());
      } catch (e) {
        yield put(QuestionActions.removeQuestionFailure(e));
      }
    }
  }

  return {
    createQuestion,
    updateQuestion,
    removeQuestion,
  };
}
