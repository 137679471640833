import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';

const StyledCommandButton = styled.button`
  background-color: ${props => props.theme.background.dark};
  border-right: 1px solid ${props => props.theme.mainColor};
  border: 0;
  padding: 11px 8px;
  color: ${props => props.theme.font.white};
  position: relative;
  &:hover {
    color: ${props => props.theme.font.white};
    background: ${props => props.theme.mainColor};
  }
  &:first-child {
    padding-left: 15px;
  }
  &:last-child {
    padding-right: 15px;
  }
  .command-title {
    white-space: nowrap;
    font-size: 80%;
  }
  ${props => props.disabled
    && css`
      cursor: not-allowed !important;
    `};
`;

const StyledIcon = styled.div`
  width: 16px;
  height: 16px;
  display: inline-block;
  margin: 2px;
  ${props => css`
      mask: url(${props.url});
    `} mask-size: cover;
  background: white;
`;

class CommandButton extends PureComponent {
  onClick = () => {
    const { disabled, type, openQuestionManager } = this.props;
    if (disabled) return;
    openQuestionManager(type);
  };

  getTranslations = () => {
    const { intl } = this.props;
    return {
      choiceShowIfType: intl.formatMessage({ id: 'visualization.editor.choice' }),
      inputReplaceType: intl.formatMessage({ id: 'visualization.editor.text_input' }),
      anchorType: intl.formatMessage({ id: 'visualization.editor.reuse_question' }),
    };
  };

  render() {
    const { type, disabled, icon } = this.props;
    const translations = this.getTranslations();
    const { testId } = this.props;

    return (
      <StyledCommandButton disabled={disabled} onClick={this.onClick} data-test-id={testId}>
        <StyledIcon url={icon} />
        <div className="command-title">{translations[type]}</div>
      </StyledCommandButton>
    );
  }
}

CommandButton.propTypes = {
  icon: PropTypes.string.isRequired,
  openQuestionManager: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

export default injectIntl(CommandButton);
