/* eslint no-param-reassign: 0 */
import { NODE_BLOCKS } from 'Visualization/helpers/constants';

export default function getNearestBlockNode(node) {
  if (isBlockNode(node)) return node;
  return getNearestBlockNode(node.parentElement);
}

function isBlockNode(node) {
  // check if node is <p>, <ul> etc...
  return NODE_BLOCKS.includes(node.tagName);
}
