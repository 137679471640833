import union from 'lodash/union';
import flatMap from 'lodash/flatMap';

export default function getOptionsFromQuestions(questions) {
  const multipleChoiceQuestions = questions.filter(isMultipleChoice);
  const inputQuestions = questions.filter(isInput);

  const multipleChoiceQuestionsOptions = flatMap(multipleChoiceQuestions, q => q.details.options.map(option => ({
    ...option,
    question_id: q.id,
  })));

  const inputQuestionsOptions = inputQuestions.map(({ details, id }) => ({
    ...details,
    question_id: id,
  }));
  return union(multipleChoiceQuestionsOptions, inputQuestionsOptions);
}

function isInput(q) {
  return q.kind === 'input';
}

function isMultipleChoice(q) {
  return q.kind.match(/select|radio|checkbox/);
}
