import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';

import { Types } from './actions';

/* ------------- Initial state ------------- */
export const initialState = {
  items: [],
  total: 0,
};

/* ------------- Reducers ------------- */

const setTrash = (state, { trash, totalCount }) => update(state, { items: { $set: trash }, total: { $set: totalCount } });

/* ------------- Hookup Reducers To Types ------------- */
export default createReducer(initialState, { [Types.FETCH_TRASH_SUCCESS]: setTrash });
