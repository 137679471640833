import React from 'react';
import styles from './index.css';

const ButtonGroup = ({ children, ...props }) => (
  <div className={styles.component} {...props}>
    {children}
  </div>
);

export default ButtonGroup;
