import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid';
import AddNoteIcon from '@material-ui/icons/NoteAdd';
import queryString from 'qs';
import Breadcrumbs from 'components/Breadcrumbs';
import Search from 'shared/Components/Search';
import Button from 'components/Button';
import { folderScheme } from 'utils/proptypes';
import { removeEmptyQueryParams } from 'utils/url';
import searchParams from 'shared/constants/queryParams/search';

import { isLoading as isLoadingUtil } from 'utils/statusSelectors';

import { Creators } from 'pages/Documents/actions';
import NewDocumentDialog from 'pages/Documents/components/NewDocumentDialog';
import { getCurrentFolderId } from 'pages/Documents/selectors';
import HeaderSubmenu from './HeaderSubmenu';

const Header = styled(Grid).attrs({
  container: true,
  spacing: 0,
  wrap: 'nowrap',
})`
  padding: 15px 100px;
  background-color: #fff;
  width: 100%;
`;

const GridWrapper = styled(Grid).attrs({
  container: true,
  item: true,
  wrap: 'nowrap',
  alignItems: 'center',
})`
  display: inline-flex !important;
  width: auto !important;

  &:first-child {
    flex: 1;
  }

  &:last-child {
    padding-left: 24px;
  }
`;

class DocumentsPageHeader extends PureComponent {
  static propTypes = {
    currentFolder: folderScheme,
    intl: intlShape.isRequired,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    match: ReactRouterPropTypes.match,
    isLoading: PropTypes.bool,
    createNewFolder: PropTypes.func.isRequired,
    parentFolderId: PropTypes.string,
  };

  state = { newDocumentDialogOpen: false };

  onSearch = (value) => {
    const { history, location } = this.props;
    const params = queryString.parse(location.search, { ignoreQueryPrefix: true });

    let newSearchParams = { ...params, [searchParams.name]: value, page: 1 };
    newSearchParams = removeEmptyQueryParams(newSearchParams);

    history.push({ search: queryString.stringify(newSearchParams) });
  };

  getPaths = () => {
    const { intl, currentFolder, match } = this.props;

    const paths = [
      {
        title: intl.formatMessage({ id: 'panel.layouts.panel_navbar.documents' }),
        route: '/documents',
      },
    ];
    if (match.params.folderId !== undefined && !isEmpty(currentFolder)) {
      const pathToCurrent = (currentFolder.parents || []).map(parent => ({
        title: parent.name,
        route: `/documents/folders/${parent.id || parent.folderSlug}`,
      }));
      paths.push(...pathToCurrent.reverse());
      paths.push({
        title: currentFolder.name,
        route: `/documents/folders/${currentFolder.id || currentFolder.folderSlug}`,
      });
    }

    return paths;
  };

  togglePopover = (e) => {
    e.preventDefault();
    this.setState(state => ({ popoverOpened: !state.popoverOpened }));
  };

  openNewDocumentDialog = () => {
    this.setState({ newDocumentDialogOpen: true });
  };

  closeNewDocumentDialog = () => {
    this.setState({ newDocumentDialogOpen: false });
  };

  render() {
    const { isLoading, createNewFolder, parentFolderId } = this.props;

    return (
      <Header>
        <GridWrapper justify="space-between">
          <Grid item xs={12} sm={6}>
            <Breadcrumbs paths={this.getPaths()} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Search onSearch={this.onSearch} isLoading={isLoading} />
          </Grid>
        </GridWrapper>
        <GridWrapper justify="flex-end">
          <Button
            glyph={AddNoteIcon}
            onClick={this.openNewDocumentDialog}
            testId="Documents-createNew">
            <FormattedMessage id="panel.templates_library.folders.show.create_template" />
          </Button>
          <HeaderSubmenu parentFolderId={parentFolderId} createNewFolder={createNewFolder} />
          <NewDocumentDialog onClose={this.closeNewDocumentDialog} isOpen={this.state.newDocumentDialogOpen} />
        </GridWrapper>
      </Header>
    );
  }
}

const mapStateToProps = state => ({
  currentFolder: state.documents.currentFolder,
  parentFolderId: getCurrentFolderId(state),
  isLoading: isLoadingUtil(state, 'documentsFetchDocumentsInfo'),
});

const mapDispatchToProps = dispatch => ({ createNewFolder: (folderName, parentId) => dispatch(Creators.createDocumentFolderRequest(folderName, parentId)) });

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(DocumentsPageHeader);
