import cn from 'classnames';
import GridContainer from 'components/Grid/Container';
import WhiteLabelTop from 'MainApp/containers/TopNavbar/WhiteLabelTop';
import PropTypes from 'prop-types';
import React from 'react';
import { GENERIC_ACCESS, WHITE_LABEL } from 'shared/constants/questionnaireModes';
import { createClassNameModifiers } from 'utils/componentUtils';
import styles from './StyledTopToolbar.css';

const StyledTopToolbar = (props) => {
  const { isWhiteLabel, questionnaireMode, v2Experience } = props;
  const modifiers = [];

  if (props.isWhiteLabel) {
    modifiers.push('isWhiteLabel');
  }
  if (props.mobileColumnLayout) {
    modifiers.push('mobileColumnLayout');
  }

  const formattedModifiers = createClassNameModifiers({ modifiers, styles });
  const classString = cn(
    styles.component,
    ...formattedModifiers,
    props.className,
  );

  const isExternal = window.location.pathname.startsWith('/external');
  const displayWhiteLabelTop = isWhiteLabel
    && (questionnaireMode === WHITE_LABEL || questionnaireMode === GENERIC_ACCESS || isExternal);
  return (
    <>
      {displayWhiteLabelTop && !v2Experience ? <WhiteLabelTop /> : null}
      <GridContainer
        className={classString}
        justify="space-between"
        alignItems="center"
        spacing={0}>
        {props.children}
      </GridContainer>
    </>
  );
};

StyledTopToolbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isWhiteLabel: PropTypes.bool,
  mobileColumnLayout: PropTypes.bool,
  v2Experience: PropTypes.bool,
};

export default StyledTopToolbar;
