import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import EyeIcon from '@material-ui/icons/Visibility';
import NotEyeIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { calculateStrength, checkRequirements } from 'utils/password';
import proptypes from 'utils/proptypes';

import styles from './index.css';
import { PasswordStrengthIndicator } from './components/PasswordStrengthIndicator';
import InputText from '../InputText';

class InputPassword extends PureComponent {
  static propTypes = {
    showStrengthIndicator: PropTypes.bool,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    errorMessage: PropTypes.node,
    testId: PropTypes.string.isRequired,
    autocomplete: proptypes.autocomplete,
  };

  static defaultProps = { autocomplete: 'off' };

  state = {
    isPasswordVisible: true,
    showRequirements: false,
  };

  showPasswordRequirementsWindow = (event) => {
    const { onFocus } = this.props;

    if (typeof onFocus === 'function') {
      onFocus(event);
    }
    this.setState({ showRequirements: true });
  };

  hidePasswordRequirementsWindow = (event) => {
    const { onBlur } = this.props;

    if (typeof onBlur === 'function') {
      onBlur(event);
    }
    this.setState({ showRequirements: false });
  };

  switchPasswordVisibility = () => {
    this.setState(state => ({ isPasswordVisible: !state.isPasswordVisible }));
  };

  render() {
    const { className, errorMessage, showStrengthIndicator, value, testId, autocomplete, ...otherProps } = this.props;
    const { isPasswordVisible, showRequirements } = this.state;
    const strength = calculateStrength(value);
    const errors = checkRequirements(value);
    const EndAdornment = isPasswordVisible ? EyeIcon : NotEyeIcon;

    return (
      <div className={cn(styles.component, className)}>
        <InputText
          {...otherProps}
          className={cn(styles.input, { [styles['input--error']]: errorMessage })}
          errorMessage={errorMessage}
          onBlur={this.hidePasswordRequirementsWindow}
          onFocus={this.showPasswordRequirementsWindow}
          testId={testId}
          type={isPasswordVisible ? 'password' : 'text'}
          value={value}
          autocomplete={autocomplete}
          endAdornment={(
            <IconButton
              data-test-id="InputPassword-endAdornment"
              classes={{ root: styles['end-adornment'] }}
              onClick={this.switchPasswordVisibility}>
              <EndAdornment />
            </IconButton>
          )} />

        {showStrengthIndicator && (
          <PasswordStrengthIndicator
            errors={errors}
            strength={strength}
            showRequirements={showRequirements} />
        )}
      </div>
    );
  }
}

export default InputPassword;
