import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { withFormik, Field } from 'formik';
import DoneIcon from '@material-ui/icons/Done';

import proptypes from 'utils/proptypes';
import * as v from 'shared/validation';
import ANSWER_STATUS from 'shared/constants/answerStatuses';
import FieldRow from 'components/form/FieldRow';
import InputText from 'components/form/InputText';
import wasThisAnswerRemoved from './helpers/wasThisAnswerRemoved';
import styles from './EmailQuestion.css';

const validator = fieldName => v.createValidator({ [fieldName]: [v.required, v.email] });
const isValid = fieldName => values => isEmpty(validator(fieldName)(values));

export class EmailQuestionComponent extends Component {
  static propTypes = {
    saveAnswer: PropTypes.func.isRequired,
    question: proptypes.inputQuestionScheme.isRequired,
    answer: proptypes.answerScheme,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.shape({ email: PropTypes.string }).isRequired,
    touched: PropTypes.objectOf(PropTypes.bool).isRequired,
    startSavingAnswer: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  };

  _saveAnswer = (value) => {
    const { saveAnswer, question } = this.props;
    const idToReplace = get(question, 'details.id', '');
    const params = isValid(question.id)(this.props.values) ? undefined : { status: ANSWER_STATUS.DRAFT };
    saveAnswer(idToReplace, value, params);
  };

  componentDidMount() {
    const { defaultValue, answer } = this.props;
    // if defaultValue is filled and answer id is undefined then it means we should save that answer
    // as those values are from removedAnswers array and we need to
    // auto save it to be able to clikc NextQuestion
    if (wasThisAnswerRemoved(answer)) {
      this._saveAnswer(defaultValue);
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      touched,
      question,
      values,
    } = this.props;
    return values[question.id] !== nextProps.values[question.id] || touched.email !== nextProps.touched.email;
  }

  componentDidUpdate = (prevProps) => {
    const { question } = this.props;
    const emailBefore = prevProps.values[question.id];
    const nextInput = this.props.values[question.id];

    if (emailBefore === nextInput) return;
    this.props.startSavingAnswer();
    this._saveAnswer(nextInput);
  };

  render() {
    const { handleBlur, handleChange, question } = this.props;
    return (
      <Field name={question.id}>
        {({ form, field }) => (
          <FieldRow>
            <InputText
              {...field}
              className={styles.input}
              autoFocus
              placeholder="e.g. tony.stark@avengers.com"
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={form.touched[field.name] && form.errors[field.name]}
              type="email"
              endAdornment={(field.value && !form.errors[field.name]) && <DoneIcon classes={{ root: styles.doneIcon }} />} />
          </FieldRow>
        )}
      </Field>
    );
  }
}

export const formikConfig = {
  mapPropsToValues: props => ({ [props.name]: props.defaultValue }),
  isInitialValid: props => isValid(props.name)(props.initialValues),
  validate: (values, props) => validator(props.name)(values),
};

export default withFormik(formikConfig)(EmailQuestionComponent);
