import { errorTypes } from './constants';

export function redirectError(errorType) {
  return [
    errorTypes.CREATE_DOCUMENT_FAILED,
    errorTypes.NO_PUBLISHED_VERSION,
    errorTypes.COMPANY_NOT_AUTHORIZED,
    errorTypes.DOCUMENT_IN_TRASH,
  ].includes(errorType);
}
