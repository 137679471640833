import assert from 'assert';

const DEFAULT_TRACKING_ID = 'no-tracking-id';

class MixpanelService {
  constructor({ mixpanel, logger } = {}) {
    assert.ok(mixpanel, 'mixpanel is required');
    assert.ok(logger, 'logger is required');

    this._mixpanel = mixpanel;
    this._logger = logger;
    this._isTrackingEnabled = Boolean(this._isTrackingEnabled);

    this._logger.info('[MIXPANEL] class instance has initialised');
  }

  /**
   * Initializesed the MixpanelService for tracking by using the token
   * @param {string} [token] Does not enable if empty or 'no-tracking-id'
   */
  init(token) {
    this._logger.info('[MIXPANEL] init');
    if (!token || token === DEFAULT_TRACKING_ID) {
      assert.ok(token, 'token is required');
      this._isTrackingEnabled = false;
      this._logger.info('[MIXPANEL] is not tracking');
    } else {
      this._isTrackingEnabled = true;
      this._mixpanel.init(token);
      this._logger.info('[MIXPANEL] is tracking');
    }
  }

  /**
   * Track an event in mixpanel
   * mixpanel.track('eventName', properties)
   *
   * @param {string} eventName
   * @param {object} properties
   *
   */
  track(eventName, properties) {
    assert.ok(eventName, 'eventName is required');
    if (this.isEnabled()) {
      this._logger.info('[MIXPANEL] track', { eventName, properties });
      this._mixpanel.track(eventName, properties);
    }
  }

  /**
   * Identify a user
   * mixpanel.alias(userId)
   *
   * @param {string} userId
   */
  alias(userId) {
    assert.ok(userId, 'userId is required');
    if (this.isEnabled()) {
      this._logger.info('[MIXPANEL] alias', { userId });
      this._mixpanel.alias(userId);
    }
  }

  people(email) {
    if (this.isEnabled()) {
      this._logger.info('[MIXPANEL] alias', { email });
      this._mixpanel.people.set({ $email: email });
    }
  }

  identify(userEmail) {
    assert.ok(userEmail, 'userEmail is required');
    if (this.isEnabled()) {
      this._logger.info('[MIXPANEL] identify', { userEmail });
      this._mixpanel.identify(userEmail);
    }
  }

  /**
   * Disable event tracking
   *
   * @returns {void}
   */
  disableTracking() {
    this._logger.info('[MIXPANEL] disableTracking');
    this._isTrackingEnabled = false;
  }

  /**
   * Get status of analytics tracking
   *
   * @returns {boolean}
   */
  isEnabled() {
    this._logger.info('[MIXPANEL] isEnabled:', this._isTrackingEnabled);
    return this._isTrackingEnabled;
  }
}

export default MixpanelService;
