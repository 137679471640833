import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { rem } from 'polished/lib/index';
import Button, { SECONDARY } from 'components/Button';
import Modal, { ModalContent, ModalFooter } from 'components/Modal';
import TextArea from 'components/form/TextArea';

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;
const Intro = styled.p`
  margin: 0 2rem 4rem 2rem;
  text-align: center;
  color: ${p => p.theme.font.label};
  font-size: ${rem('15px')};
`;

class PublishModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.shape({ message: PropTypes.string }).isRequired,
    publishTemplate: PropTypes.func.isRequired,
    patchAndPublishTemplate: PropTypes.func.isRequired,
    isPatchAndPublish: PropTypes.bool,
    intl: intlShape.isRequired,
  };

  defaultProps = { isPatchAndPublish: false };

  render() {
    const {
      handleChange,
      values,
      intl,
      open,
      toggleModal,
      publishTemplate,
      patchAndPublishTemplate,
      isPatchAndPublish,
    } = this.props;
    return (
      <Modal size="medium" toggleModal={toggleModal} open={open} modalTitle={<FormattedMessage id="visualization.publish_unpublish.header" />}>
        <Styled data-test-id="publish-modal-container">
          <ModalContent>
            <Intro>
              <FormattedMessage id="visualization.publish_unpublish.intro" />
            </Intro>
            <Field
              component={TextArea}
              name="message"
              id="message"
              autofocus
              label={intl.formatMessage({ id: 'visualization.publish_unpublish.what_did_you_change' })}
              onChange={handleChange}
              value={values.message}
              rows="10"
              testId="publish-template-message"
              placeholder={intl.formatMessage({ id: 'visualization.publish_unpublish.textarea_placeholder' })} />
          </ModalContent>
          <ModalFooter>
            <Button onClick={toggleModal} variant={SECONDARY} testId="cancel-publish-template">
              <FormattedMessage id="visualization.publish_unpublish.cancel" />
            </Button>
            {isPatchAndPublish ? (
              <Button testId="publish-template" onClick={patchAndPublishTemplate} type="button">
                <FormattedMessage id="template_settings.patch_and_publish" />
              </Button>
            ) : (
              <Button testId="publish-template" onClick={publishTemplate} type="button">
                <FormattedMessage id="visualization.publish_unpublish.publish" />
              </Button>
            )}

          </ModalFooter>
        </Styled>
      </Modal>
    );
  }
}

export default injectIntl(PublishModal);
