import rangy from 'rangy';
import isQuestion from 'Visualization/helpers/isQuestion';
import NodesHelper from './nodesHelper';
import getNearestQuestionTagFromNode from './getNearestQuestionTagFromNode';

export default function checkIfAnyQuestionsWithinSelection() {
  const nodesHelper = new NodesHelper();
  if (
    getNearestQuestionTagFromNode(nodesHelper.leftNode, 'inputReplaceType')
    || getNearestQuestionTagFromNode(nodesHelper.rightNode, 'inputReplaceType')
  ) {
    return true;
  }
  return rangy
    .getSelection()
    .getRangeAt(0)
    .getNodes()
    .find(el => isQuestion(el));
}
