import React from 'react';
import PropTypes from 'prop-types';
import { folderScheme } from 'utils/proptypes';
import { sortWithUnicodeBy } from 'utils/sortBy';
import Folder from './Folder';
import styles from './styles.css';

const FolderView = ({ folders, isNested, selectedFolderId, selectFolder }) => {
  const foldersSorted = sortWithUnicodeBy(folders, 'name');

  return (
    <section className={styles.folderViewContainer}>
      {foldersSorted.map((f, index) => (
        <Folder
          key={f.id || f.folderSlug}
          name={f.name}
          folderId={f.id || f.folderSlug}
          subfolders={f.children}
          isSelected={(f.id || f.folderSlug) === selectedFolderId}
          selectedFolderId={selectedFolderId}
          isNested={isNested}
          index={index}
          selectFolder={selectFolder} />
      ))}
    </section>
  );
};

FolderView.propTypes = {
  isNested: PropTypes.bool,
  selectedFolderId: PropTypes.string,
  folders: PropTypes.arrayOf(folderScheme),
  selectFolder: PropTypes.func,
};

FolderView.defaultProps = { folders: [] };

export default FolderView;
