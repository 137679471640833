import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _times from 'lodash/times';
import styles from './index.css';


function NavigationDots({ count, current, onChange }) {
  return (
    <div className={styles.component}>
      {_times(count, n => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          className={cn(styles.dot, { [styles.dotActive]: n === current })}
          key={n}
          role="option"
          aria-selected={n === current}
          tabIndex={0}
          onClick={() => onChange(n)} />
      ))}
    </div>
  );
}

NavigationDots.defaultProps = { current: 0 };

NavigationDots.propTypes = {
  count: PropTypes.number.isRequired,
  current: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default NavigationDots;
