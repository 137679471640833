import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Typography, { VARIANTS } from 'components/Typography';
import styles from './styles.css';

const ForgotPasswordConfirmation = () => (
  <div className={styles.component}>
    <Typography variant={VARIANTS.H2}>
      <FormattedMessage id="forgot_password.verified.title" />
    </Typography>
    <img src="/assets/icons/account_verification.png" alt="" className={styles.icon} />
    <Typography variants={VARIANTS.BODY_LONG} className={styles.description}>
      <FormattedHTMLMessage id="forgot_password.verified.paragraph" />
    </Typography>

    <Link className={styles.link} data-test-id="AccountVerified-signInLink" to="/auth/sign-in">
      <FormattedMessage id="forgot_password.verified.log_in" />
    </Link>
  </div>
);

export default ForgotPasswordConfirmation;
