import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Flow from 'containers/Flow';
import LogicsManager from '../LogicsManager/LogicsManager';
import { Creators as LogicsManagerActions } from '../LogicsManager/LogicsManagerRedux';

class RightSidebar extends Component {
  static propTypes = {
    logicsManagerKind: PropTypes.string,
    style: PropTypes.shape({
      position: PropTypes.string,
      transform: PropTypes.string,
      top: PropTypes.number,
      width: PropTypes.number,
      left: PropTypes.number,
    }),
    closeLogicsManager: PropTypes.func.isRequired,
  };

  static defaultProps = { logicsManagerKind: '' };

  componentWillUnmount() {
    this.props.closeLogicsManager();
  }

  render() {
    const { logicsManagerKind, style } = this.props;
    return (
      <div style={{ ...style }}>
        {logicsManagerKind ? <LogicsManager /> : <Flow />}
      </div>
    );
  }
}

const mapStateToProps = state => ({ logicsManagerKind: state.logicsManager.logicsManagerKind });
const mapDispatchToProps = { closeLogicsManager: LogicsManagerActions.closeLogicsManager };

export default connect(mapStateToProps, mapDispatchToProps)(RightSidebar);
