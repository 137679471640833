import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Close from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';

export const StyledTitle = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 10px 12px 20px',
    fontSize: '17px',
    fontWeight: 'bold',
  },
})(DialogTitle);

export const CloseIcon = styled(Close)`
  margin-left: auto;
  cursor: pointer;
`;

export const ArrowIcon = styled(ArrowBack)`
  margin: 0 15px 0 5px;
  color: ${({ theme }) => theme.lightGray};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.mainColorHover};
  }
`;

export const Img = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 7px;
  top: -1px;
  position: relative;
`;
