import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import ProgressBar from '../ProgressBar/Containers/ProgressBarContainer';
import SkippedQuestionsIcon from './SkippedQuestionIcon';
import styles from './index.css';

const FinishedWithSkipped = ({
  isPreviewVisible,
  toggleLeftToolbar,
  isLeftToolbarVisible,
}) => (
  <div
    className={cn(styles.wrapper, {
      [styles.previewHidden]: !isPreviewVisible,
      [styles.leftToolbarHidden]: !isLeftToolbarVisible,
    })}>
    <ProgressBar
      onProgressClick={toggleLeftToolbar}
      isLeftToolbarVisible={isLeftToolbarVisible}
      hideProgressBar={false}
      className={styles.progressBar} />
    <section className={styles.section}>
      <SkippedQuestionsIcon />
      <h2 className={styles.heading}>
        <FormattedMessage id="questionnaire.finished_with_skipped.heading" />
      </h2>
      <p className={styles.description}>
        <FormattedMessage id="questionnaire.finished_with_skipped.description" />
      </p>
    </section>
  </div>
);

FinishedWithSkipped.propTypes = {
  isPreviewVisible: PropTypes.bool,
  toggleLeftToolbar: PropTypes.func.isRequired,
  isLeftToolbarVisible: PropTypes.func.isRequired,
};

export default FinishedWithSkipped;
