exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-MenuList-components-___index__menuItem___2JGpq:hover * {\n  color: #2d866e;\n  color: var(--c-brand);\n}\n", "", {"version":3,"sources":["/workspace/app/components/MenuList/components/index.css"],"names":[],"mappings":"AAAA;EACE,eAAqB;EAArB,sBAAqB;CACvB","file":"index.css","sourcesContent":[".menuItem:hover * {\n  color: var(--c-brand);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"menuItem": "app-components-MenuList-components-___index__menuItem___2JGpq"
};