exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "button.app-components-EllipsisMenu-___index__activeIcon___kR5Xu {\n  background-color: rgba(0, 0, 0, 0.08);\n}\n", "", {"version":3,"sources":["/workspace/app/components/EllipsisMenu/index.css"],"names":[],"mappings":"AAAA;EACE,sCAAqC;CACvC","file":"index.css","sourcesContent":["button.activeIcon {\n  background-color: rgba(0, 0, 0, 0.08);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"activeIcon": "app-components-EllipsisMenu-___index__activeIcon___kR5Xu"
};