import intersection from 'lodash/intersection';
import findKey from 'lodash/findKey';

const SPECIAL_CHARACTERS = ' !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

export const calculateStrength = (password) => {
  if (password == null || password.length === 0) {
    return undefined;
  }

  let strength = 0;
  if (password.length < 10) {
    return strength;
  }

  if (password.length >= 10) {
    strength += 1;
  }

  // If password contains both lower and uppercase characters, increase strength value.
  if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
    strength += 1;
  }

  // If it has numbers and characters, increase strength value.
  if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
    strength += 1;
  }

  const specialCharacters = intersection(
    SPECIAL_CHARACTERS.split(''),
    password.split(''),
  );

  // If it has one or more special characters, increase strength value.
  if (specialCharacters.length >= 1) {
    strength += 1;
  }

  return strength;
};

export const checkRequirements = (password) => {
  const errors = {
    length: true,
    upperAndLowerCase: true,
    lettersAndNumbers: true,
    specialCharacter: true,
  };

  if (password == null) {
    return errors;
  }

  if (password.length >= 10) {
    errors.length = false;
  }

  // Check if has not both upper and lowercase characters
  if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
    errors.upperAndLowerCase = false;
  }

  // Check if has not numbers and characters
  if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
    errors.lettersAndNumbers = false;
  }

  // Check if has not any special character
  if (
    intersection(SPECIAL_CHARACTERS.split(''), password.split('')).length > 0
  ) {
    errors.specialCharacter = false;
  }

  return errors;
};

export const isPasswordStrong = (password) => {
  const strength = calculateStrength(password);
  const errors = checkRequirements(password);
  return !!(
    strength
    && strength > 3
    && findKey(errors, error => !!error) == null
  );
};

export default { calculateStrength, checkRequirements, isPasswordStrong };
