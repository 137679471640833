import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Fill } from 'react-slot-fill';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';

import proptypes from 'utils/proptypes';
import Button, { SECONDARY, SMALL, LINK } from 'components/Button';
import { logicTypes } from 'appRoot/constants';

import ChoiceShowIfType from './LogicsTypes/ChoiceShowIfType';
import InputReplaceType from './LogicsTypes/InputReplaceType';
import AnchorType from './LogicsTypes/AnchorType';

import { Creators as LogicsManagerActions } from './LogicsManagerRedux';
import styles from './LogicsManager.css';

const components = {
  [logicTypes.CHOICE_SHOW_IF]: ChoiceShowIfType,
  [logicTypes.INPUT_REPLACE_TYPE]: InputReplaceType,
  [logicTypes.ANCHOR_TYPE]: AnchorType,
};

class LogicManager extends React.PureComponent {
  static propTypes = {
    currentQuestion: proptypes.questionScheme,
    closeLogicsManager: PropTypes.func.isRequired,
    logicsManagerKind: PropTypes.oneOf([
      logicTypes.CHOICE_SHOW_IF,
      logicTypes.INPUT_REPLACE_TYPE,
      logicTypes.ANCHOR_TYPE,
    ]).isRequired,
  };

  shouldDisplay = () => this.props.logicsManagerKind

  render() {
    const { logicsManagerKind, closeLogicsManager, currentQuestion } = this.props;

    const LogicType = components[logicsManagerKind];

    /* eslint-disable no-return-assign */
    return (
      <div className={styles.component}>
        <h4>
          <FormattedMessage id="visualization.logics_manager.edit_question" tagName="span" />
          <Button testId="LogicsManager-closeCross" onClick={closeLogicsManager} variant={LINK}>
            <CloseIcon fontSize="small" />
          </Button>
        </h4>
        <Fill name="LogicsManager.Close">
          <div className={styles.component__footer}>
            <Button testId="LogicsManager-closeButton" onClick={closeLogicsManager} variant={SECONDARY} size={SMALL}>
              <FormattedMessage id="visualization.logics_manager.close" />
            </Button>
          </div>
        </Fill>
        <div className={styles.component__content} data-test-id="LogicsManager-content">
          <div className={styles.content__inner}>
            {LogicType ? (
              <LogicType closeLogicsManager={closeLogicsManager} currentQuestion={currentQuestion} />
            ) : null}
          </div>
        </div>
      </div>
    );
    /* eslint-enable */
  }
}

const mapStateToProps = state => ({
  logicsManagerKind: state.logicsManager.logicsManagerKind,
  currentQuestion: state.questions.selectedQuestion,
});

const mapDispatchToProps = { closeLogicsManager: LogicsManagerActions.closeLogicsManager };

export default connect(mapStateToProps, mapDispatchToProps)(LogicManager);
