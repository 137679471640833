import React from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { hot } from 'react-hot-loader';

import { ADMIN_ROLE, PRODUCER_ROLE } from 'shared/constants/user/roles';
import { DOCUMENT, PREVIEW, EDIT, WHITE_LABEL, GENERIC_ACCESS } from 'shared/constants/questionnaireModes';

// Errors
import Error500 from 'containers/Error500';
import Error404 from 'containers/Error404';
import ErrorTemplateNotActive from 'containers/ErrorTemplateNotActive';
import ErrorSessionExpired from 'containers/ErrorSessionExpired';

// SSO
import FreshdeskHelp from 'Sso/FreshdeskHelp';

// White label
import QuestionnaireCreator from 'Questionnaire2/QuestionnaireCreator';

// Auth
import SignInPage from 'pages/SignIn';
import ForgotPasswordPage from 'pages/ForgotPassword';
import ForgotPasswordConfirmation from 'pages/ForgotPasswordConfirmation';
import ResetPasswordPage from 'pages/ResetPassword';

// Templates
import Templates from 'pages/Templates/views/Templates';
import TemplateDetails from 'pages/Templates/views/TemplateDetails';
import TemplatePreview from 'pages/Templates/views/TemplatePreview';
import TemplateEdit from 'pages/Templates/views/TemplateEdit';

// Questionnaire
import EditDocument from 'pages/EditDocument';
import DocumentDetailsPage from 'pages/DocumentDetails';

// Static
import SigningSuccess from 'pages/SigningSuccess';

// Trash
import Trash from 'pages/Trash';

// Users
import UserProfile from 'pages/UserProfile';

// Invitations
import UserInvitationPage from 'pages/UserInvitation';

// Generic Access
import GenericAccessLinkPage from 'pages/GenericAccessLink';

// Documents
import DocumentStyles from 'pages/DocumentStyles/views/DocumentStyles';
import Documents from 'pages/Documents';

import { routeTypes } from 'appRoot/constants';
import ErrorUserDisabled from 'containers/ErrorUserDisabled';
import PrivateRoute from '../../containers/Route/PrivateRoute';
import PrivatePartialRoute from '../../containers/Route/PrivatePartialRoute';
import PublicRoute from '../../containers/Route/PublicRoute';
import WhiteLabelRoute from '../../containers/Route/WhiteLabelRoute';
import GenericAccessRoute from '../../containers/Route/GenericAccessRoute';

import * as routesPaths from './routesPaths';
import UserListPage from '../../pages/UserList';
import UserEdit from '../../pages/UserEdit';

function generateRoutes() {
  return [
    // SSO
    {
      path: '/freshdesk/help',
      partial: true,
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [],
      component: FreshdeskHelp,
      wideContainer: true,
    },
    {
      path: '/auth/sso/new', // URL set in freshdesk to reauthenticate user
      partial: true,
      type: routeTypes.PRIVATE,
      permissions: [],
      component: FreshdeskHelp,
      wideContainer: true,
    },
    // White label
    {
      title: 'New document',
      path: '/external/documents/:templateId/new',
      partial: true,
      exact: false,
      permissions: [],
      component: QuestionnaireCreator,
      type: routeTypes.WHITE_LABEL,
      mode: WHITE_LABEL,
      wideContainer: true,
    },
    {
      title: 'Edit document',
      path: '/external/documents/:documentId/edit',
      exact: false,
      permissions: [],
      component: DocumentDetailsPage,
      type: routeTypes.WHITE_LABEL,
      mode: WHITE_LABEL,
    },
    {
      title: 'Document edit',
      exact: true,
      permissions: [],
      path: '/external/documents/:documentId/edit-version',
      type: routeTypes.WHITE_LABEL,
      component: EditDocument,
      mode: WHITE_LABEL,
    },
    // Public pages
    {
      title: 'Edit document',
      path: '/generic-access/documents/:documentId/edit',
      exact: false,
      permissions: [],
      component: DocumentDetailsPage,
      type: routeTypes.GENERIC_ACCESS,
      mode: GENERIC_ACCESS,
    },
    {
      title: 'Sharing',
      path: '/generic-access/:templateSharingSlug',
      exact: true,
      withLogoHeader: false,
      permissions: [],
      component: GenericAccessLinkPage,
      type: routeTypes.PUBLIC,
    },
    {
      title: 'Sign in',
      path: '/auth/sign-in',
      partial: true,
      exact: true,
      permissions: [],
      component: SignInPage,
      type: routeTypes.PUBLIC,
      shouldRedirectAuthorizedUser: true,
    },
    {
      title: 'Forgotten password',
      path: '/auth/forgot-password',
      partial: true,
      exact: true,
      permissions: [],
      component: ForgotPasswordPage,
      type: routeTypes.PUBLIC,
      shouldRedirectAuthorizedUser: true,
    },
    {
      title: 'Reset password',
      path: '/auth/reset-password/:token?',
      partial: true,
      exact: true,
      permissions: [],
      component: ResetPasswordPage,
      type: routeTypes.PUBLIC,
      shouldRedirectAuthorizedUser: true,
    },
    {
      title: 'Forgotten password sent',
      path: '/auth/forgot-password/sent',
      partial: true,
      exact: true,
      permissions: [],
      component: ForgotPasswordConfirmation,
      type: routeTypes.PUBLIC,
      shouldRedirectAuthorizedUser: true,
    },
    {
      title: 'Invitation',
      path: '/invitations/:invitationId',
      partial: true,
      exact: true,
      permissions: [],
      component: UserInvitationPage,
      type: routeTypes.PUBLIC,
      shouldRedirectAuthorizedUser: true,
    },
    {
      title: 'Error',
      path: '/external/error',
      partial: true,
      exact: true,
      permissions: [],
      component: Error500,
      type: routeTypes.PUBLIC,
    },
    {
      title: 'Error',
      path: '/template/error',
      partial: true,
      exact: true,
      permissions: [],
      component: ErrorTemplateNotActive,
      type: routeTypes.PUBLIC,
    },
    {
      title: 'Error',
      path: '/error/session-expired',
      partial: true,
      exact: true,
      permissions: [],
      component: ErrorSessionExpired,
      type: routeTypes.PUBLIC,
    },
    {
      title: 'Error Disabled user',
      path: '/error/no-access',
      partial: true,
      exact: true,
      permissions: [],
      component: ErrorUserDisabled,
      type: routeTypes.PUBLIC,
    },
    {
      title: 'Signing success',
      path: '/documents/signing-success',
      permissions: [],
      withLogoHeader: false,
      type: routeTypes.PUBLIC,
      component: SigningSuccess,
    },

    // Full page width
    {
      title: 'Edit template',
      path: routesPaths.getTemplateDetailEditPath(':templateId'),
      partial: true,
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [ADMIN_ROLE, PRODUCER_ROLE],
      component: TemplateEdit,
      wideContainer: true,
    },
    {
      title: 'Preview template',
      path: '/templates/:templateId/preview',
      partial: true,
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [ADMIN_ROLE, PRODUCER_ROLE],
      component: TemplatePreview,
      wideContainer: true,
    },
    {
      title: 'Documents listing',
      path: '/documents',
      partial: true,
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [],
      component: Documents,
    },
    {
      title: 'Documents folder',
      path: '/documents/folders/:folderId',
      partial: true,
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [],
      component: Documents,
    },
    {
      title: 'Document template settings',
      path: '/settings/document_templates/edit',
      partial: true,
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [ADMIN_ROLE],
      component: DocumentStyles,
      wideContainer: true,
    },
    {
      title: 'Document template preview',
      exact: true,
      permissions: [ADMIN_ROLE, PRODUCER_ROLE],
      path: routesPaths.getTemplateDetailDocumentPreviewPath(':templateId'),
      type: routeTypes.PRIVATE,
      component: DocumentDetailsPage,
      mode: PREVIEW,
    },
    {
      title: 'Document details',
      exact: true,
      permissions: [],
      path: '/documents/:documentId',
      type: routeTypes.PRIVATE,
      component: DocumentDetailsPage,
      mode: DOCUMENT,
    },
    {
      title: 'Document edit',
      exact: true,
      permissions: [],
      path: '/documents/:documentId/edit',
      partial: true,
      type: routeTypes.PRIVATE,
      component: EditDocument,
      mode: EDIT,
      wideContainer: true,
    },

    // Page
    {
      title: 'Home',
      path: '/',
      partial: true,
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [],
      component: () => <Redirect to="/documents" />,
    },
    {
      title: 'Templates listing',
      path: routesPaths.getTemplatesListPath(),
      partial: true,
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [ADMIN_ROLE, PRODUCER_ROLE],
      component: Templates,
    },
    {
      title: 'Templates detail',
      path: routesPaths.getTemplateDetailPath(':templateId'),
      partial: true,
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [ADMIN_ROLE, PRODUCER_ROLE],
      component: TemplateDetails,
    },
    {
      title: 'Templates folder',
      path: '/templates/folders/:folderId',
      partial: true,
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [ADMIN_ROLE, PRODUCER_ROLE],
      component: Templates,
    },

    // Users
    {
      title: 'Members',
      path: '/members',
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [ADMIN_ROLE],
      component: UserListPage,
    },
    {
      title: 'Member edit',
      path: '/members/:memberId/edit',
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [ADMIN_ROLE],
      component: UserEdit,
    },
    {
      title: 'Settings edit',
      path: '/settings/edit',
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [],
      component: UserProfile,
    },
    {
      title: 'Trash',
      path: '/trash',
      type: routeTypes.PRIVATE,
      exact: true,
      permissions: [],
      component: Trash,
    },

    {
      title: 'Not found',
      path: '/error/not-found',
      partial: true,
      exact: true,
      permissions: [],
      component: Error404,
      type: routeTypes.PUBLIC,
    },
    {
      path: '*',
      title: 'Not found',
      partial: true,
      permissions: [],
      component: Error404,
      type: routeTypes.PUBLIC,
    },
  ];
}

const renderRoute = (route) => {
  const routeProps = {
    exact: true,
    path: route.path,
    component: route.component,
    key: route.path,
    mode: route.mode,
    wideContainer: route.wideContainer,
    withLogoHeader: route.withLogoHeader,
    shouldRedirectAuthorizedUser: Boolean(route.shouldRedirectAuthorizedUser),
    title: `${route.title} - Lexolve`,
    permissions: route.permissions,
  };

  let RouteComponent = Route;

  switch (route.type) {
    case routeTypes.PUBLIC:
      RouteComponent = PublicRoute;
      break;
    case routeTypes.WHITE_LABEL:
      RouteComponent = WhiteLabelRoute;
      break;
    case routeTypes.GENERIC_ACCESS:
      RouteComponent = GenericAccessRoute;
      break;
    default:
      RouteComponent = route.partial ? PrivatePartialRoute : PrivateRoute;
      break;
  }

  return (<RouteComponent {...routeProps} />);
};

const routes = generateRoutes();

function createRoutes(history) {
  const Routes = () => (
    <Router history={history}>
      <Switch>
        {routes.map(renderRoute)}
      </Switch>
    </Router>
  );

  return compose(hot(module))(Routes);
}

export default createRoutes;
