export const SENT_FOR_SIGNING = 'sent_for_signing';
export const SIGNED = 'signed';
export const STARTED = 'started';
export const COMPLETED = 'completed';
export const EXPIRED = 'expired';
export const REJECTED = 'rejected';
export const ERROR = 'error';


// New statuses as defined in the new questionnaire api

export const SIGNING_REQUESTED = 'signing_requested';
export const SIGNING_FAILED = 'signing_failed';
export const SIGNING_ABORTED = 'signing_aborted';
export const SIGNING_EXPIRED = 'signing_expired';
export const UNKNOWN = 'unknown';
