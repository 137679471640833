import { Component } from 'react';
import proptypes from 'utils/proptypes';
import each from 'lodash/each';
import answerStatuses from 'shared/constants/answerStatuses';

export default class AddQuestionColorsToPreview extends Component {
  static propTypes = {
    currentQuestion: proptypes.questionScheme,
    answers: proptypes.answersScheme,
  };

  componentDidMount = () => {
    this.setColors();
  };

  componentDidUpdate = () => {
    this.setColors();
  };

  setColors = () => {
    const { currentQuestion, answers } = this.props;
    $('[data-question]').removeClass('current-question-highlight');
    $(`[data-question-id='${currentQuestion.id}']`).addClass('current-question-highlight');

    const answered = answers.filter(answer => answer.status === answerStatuses.ANSWERED);

    each(answered, (q) => {
      $(`[data-question-id='${q.questionId}']`).addClass('answered-question-highlight');
    });
  };

  render() {
    return null;
  }
}
