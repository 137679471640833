import NodesHelper from './nodesHelper';
import getAllNodesInTree from './getAllNodesInTree';

export default function checkIfSelectionIsInsideQuestion(logicType) {
  const nodesHelper = new NodesHelper();
  const { questionNodeFullySelected, leftNode, rightNode } = nodesHelper;

  if (questionNodeFullySelected(logicType)) {
    // this is case when we select WHOLE text inside replace logic
    // and we want it to be wrapped by other logic, like choice
    // case 3
    // https://lawbotics.atlassian.net/wiki/spaces/PD/pages/9011201/Triggers+functionality+edgecase
    return false;
  }

  const isLeftNodeInsideLogicType = getAllNodesInTree(leftNode, ['DIV', 'SPAN'], {
    withLogicType: logicType,
    isQuestion: true,
  });

  const isRightNodeInsideLogicType = getAllNodesInTree(rightNode, ['DIV', 'SPAN'], {
    withLogicType: logicType,
    isQuestion: true,
  });

  return !!(isLeftNodeInsideLogicType.length || isRightNodeInsideLogicType.length);
}
