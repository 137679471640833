/* eslint class-methods-use-this: 0 */
import rangy from 'rangy';
import { NODE_BLOCKS } from 'Visualization/helpers/constants';
import each from 'lodash/each';
import last from 'lodash/last';
import first from 'lodash/first';
import { store } from 'MainApp';
import getNearestQuestionTagFromNode from './getNearestQuestionTagFromNode';
import getNearestExpandableNode from './getNearestExpandableNode';
import getNearestBlockNode from './getNearestBlockNode';
import getQuestionBlockNodesTree from './getQuestionBlockNodesTree';
import getQuestionInlineNodesTree from './getQuestionInlineNodesTree';
import getAllNodesInTree from './getAllNodesInTree';

export default class NodesHelper {
  constructor() {
    this.leftNode = this._getLeftNode();
    this.leftNodeNearestBlock = getNearestBlockNode(this.leftNode);
    this.leftNodeNearestExpandable = getNearestExpandableNode(this.leftNode);
    this.leftNodeNearestQuestion = getNearestQuestionTagFromNode(this.leftNode);
    this.leftNodeFarrestInlineQuestion = first(getQuestionInlineNodesTree(this.leftNode));
    this.leftNodeNearestInlineQuestion = last(getQuestionInlineNodesTree(this.leftNode));
    this.leftNodeFarrestQuestionBlock = first(getQuestionBlockNodesTree(this.leftNode));
    this.leftNodeNearestQuestionBlock = last(getQuestionBlockNodesTree(this.leftNode));

    this.rightNode = this._getRightNode();
    this.rightNodeNearestBlock = getNearestBlockNode(this.rightNode);
    this.rightNodeNearestExpandable = getNearestExpandableNode(this.rightNode);
    this.rightNodeNearestQuestion = getNearestQuestionTagFromNode(this.rightNode);
    this.rightNodeFarrestInlineQuestion = first(getQuestionInlineNodesTree(this.rightNode));
    this.rightNodeNearestInlineQuestion = last(getQuestionInlineNodesTree(this.rightNode));
    this.rightNodeFarrestQuestionBlock = first(getQuestionBlockNodesTree(this.rightNode));
    this.rightNodeNearestQuestionBlock = last(getQuestionBlockNodesTree(this.rightNode));

    this.multipleBlocksSelected = this.multipleBlocksSelected();
    this.findNodeInTreeOf = (node, tagName) => getAllNodesInTree(node, tagName);
    this.findFirstQuestionBlockAfter = (node, questionBlock) => this._findFirstQuestionBlockAfter(node, questionBlock);
    this.sharedContainer = this._sharedContainer();
  }

  selectionNode = () => this.leftNode;

  multipleBlocksSelected() {
    const range = rangy.getSelection().getRangeAt(0);
    const selectedContent = range.cloneContents();
    return selectedContent.querySelectorAll(NODE_BLOCKS.join(',')).length > 0;
  }

  questionNodeFullySelected = (logicType) => {
    const allowAsNodeText = (logicType !== 'choiceShowIfType');
    const leftNode = this._getLeftNode(allowAsNodeText);
    const rightNode = this._getRightNode(allowAsNodeText);

    if (leftNode !== rightNode) {
      return false;
    }
    try {
      const selectedText = rangy.getSelection().text();
      const nearestQuestionTag = this.leftNodeNearestQuestion;
      // left and right question are same

      if (!nearestQuestionTag) return false;

      return selectedText === nearestQuestionTag.textContent;
    } catch (e) {
      store.dispatch({
        type: '',
        meta: {
          analytics: {
            type: 'nodesHelper',
            payload: {
              command: 'error',
              error: e,
            },
          },
        },
      });
      return false;
    }
  };

  _getLeftNode(allowNodeAsText = false) {
    // allowNodeAsText means that node can be text node,
    // not only proper DOM tag as <p> <b> <question>
    const selection = rangy.getSelection();

    if (selection.isBackwards()) {
      const node = selection.focusNode;
      return node.tagName ? node : node.parentNode;
    }
    const node = selection.anchorNode;
    if (allowNodeAsText) {
      return node;
    }
    return node.tagName ? node : node.parentNode;
  }

  _getRightNode(allowNodeAsText = false) {
    const selection = rangy.getSelection();

    if (selection.isBackwards()) {
      const node = selection.anchorNode;
      return node.tagName ? node : node.parentNode;
    }
    const node = selection.focusNode;
    if (allowNodeAsText) {
      return node;
    }
    return node.tagName ? node : node.parentNode;
  }

  _findFirstQuestionBlockAfter(node, questionBlock) {
    const blocks = getQuestionBlockNodesTree(node);
    const index = blocks.indexOf(questionBlock);
    return blocks[index + 1];
  }

  /* eslint-disable consistent-return */
  _sharedContainer() {
    const leftNodeParents = $(this.leftNode).parents();
    const rightNodeParents = $(this.rightNode).parents();

    let sharedContainer = null;

    each(leftNodeParents, (leftParent) => {
      each(rightNodeParents, (rightParent) => {
        if (leftParent === rightParent && !leftParent.hasAttribute('data-question-container')) {
          sharedContainer = rightParent;
          return false;
        }
      });
      if (sharedContainer) return false;
    });
    return sharedContainer;
  }
  /* eslint-enable consistent-return */
}
