import styled from 'styled-components';
import { rem } from 'polished/lib/index';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Button from 'components/Button';

export const CopyInput = styled.input`
  flex: 1;
  height: 36px;
  border: 1px solid ${p => p.theme.border.dark};
  border-right: none;
  border-radius: 4px 0 0 4px;
  padding: 10px;
  font-size: ${rem('14px')};
  color: #878787;
  outline: 0;
  ${p => p.blocked && `
  background-color: #f7f7f7;
  color: ${p.theme.font.darkGrey};
  `};
`;

export const CopyButton = styled(Button)`
  height: 36px;
  border-radius: 0 4px 4px 0 !important;
  border: 1px solid #e3e3e3;
  color: #858585;
  background-color: #f0f0f0;
  line-height: 1;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);

    &:hover {
      border-color: #e3e3e3;
    }
  }
`;

export const CopyButtonWrapper = styled.div`
  display:flex;
  margin-bottom: 20px;
  border-radius: 4px;
`;

export const Subtitle = styled.label`
  font-size: ${rem('14px')};
  margin-top: 25px;
  color: #545454;
`;

export const EnableLabel = styled.label`
  font-size: ${rem('14px')};
  color: #787878;
  font-weight: 500;
  margin-right: 10px;
`;

export const EnableTemplateWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 20px 0;
`;

export const InformationLabel = styled.span`
  font-size: ${rem('13px')};
  color: #7d7d7d;
`;

export const InformationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  font-size: ${rem('19px')}; !important;
  color: #ababab;
  margin: 0 10px;
  `;
