import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import styles from './index.css';

function StrengthResults({ strength }) {
  let fillClassName;
  let textNode;

  if (typeof strength !== 'number') {
    fillClassName = 'grey';
  } else if (strength < 3) {
    fillClassName = 'red';
    textNode = (
      <FormattedMessage id="shared_components.password_strength_indicator.weak">
        {text => <strong data-test-id="InputPassword-strengthWeak">{text}</strong>}
      </FormattedMessage>
    );
  } else if (strength === 3) {
    fillClassName = 'yellow';
    textNode = (
      <FormattedMessage id="shared_components.password_strength_indicator.ok">
        {text => <strong data-test-id="InputPassword-strengthOk">{text}</strong>}
      </FormattedMessage>
    );
  } else {
    fillClassName = 'green';
    textNode = (
      <FormattedMessage id="shared_components.password_strength_indicator.good">
        {text => <strong data-test-id="InputPassword-strengthGood">{text}</strong>}
      </FormattedMessage>
    );
  }

  return (
    <div className={styles.passwordStrengthIndicator} data-test-id="InputPassword-passwordStrengthIndicator">
      <div className={styles.bar}>
        <div className={cn(styles.fill, styles[`fill--${fillClassName}`])} />
      </div>
      <div
        className={cn(styles.result, styles[`result--${fillClassName}`])}
        data-test-id="InputPassword-strengthResults">
        {textNode}
      </div>
    </div>
  );
}

StrengthResults.propTypes = { strength: PropTypes.number };

const StrengthBar = ({ className, strength }) => (
  <div className={cn(styles.strengthBar, className)}>
    <div className={styles.label}>
      <FormattedMessage id="shared_components.password_strength_indicator.strength" />
    </div>

    <StrengthResults strength={strength} />
  </div>
);

StrengthBar.propTypes = {
  strength: PropTypes.number,
  className: PropTypes.string,
};

const OkIcon = () => (
  <div className={cn(styles.icon, styles['icon--ok'])}>
    <DoneIcon />
  </div>
);

const ErrorIcon = () => (
  <div className={cn(styles.icon, styles['icon--error'])}>
    <CloseIcon />
  </div>
);

function PasswordStrengthIndicator({ errors, showRequirements, strength }) {
  return (
    <div data-test-id="InputPassword-strengthIndicator">
      <StrengthBar strength={strength} />

      {showRequirements && (
        <div
          data-test-id="InputPassword-strengthRequirements"
          className={cn(styles.checkModal, styles['checkModal--fromBottom'])}>
          <span>
            <FormattedMessage id="shared_components.password_input.password_must_contain" />
            <span>:</span>
          </span>
          <ul>
            <li className={styles.listItem}>
              {errors.length ? <ErrorIcon /> : <OkIcon />}
              <FormattedMessage id="shared_components.password_input.length_requirement" />
            </li>
            <li className={styles.listItem}>
              {errors.lettersAndNumbers ? <ErrorIcon /> : <OkIcon />}
              <FormattedMessage id="shared_components.password_input.letters_and_numbers" />
            </li>
            <li className={styles.listItem}>
              {errors.upperAndLowerCase ? <ErrorIcon /> : <OkIcon />}
              <FormattedMessage id="shared_components.password_input.mix_lower_and_uppercase" />
            </li>
            <li className={styles.listItem}>
              {errors.specialCharacter ? <ErrorIcon /> : <OkIcon />}
              <FormattedMessage id="shared_components.password_input.special_character" />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

PasswordStrengthIndicator.propTypes = {
  errors: PropTypes.shape({
    length: PropTypes.bool.isRequired,
    lettersAndNumbers: PropTypes.bool.isRequired,
    upperAndLowerCase: PropTypes.bool.isRequired,
    specialCharacter: PropTypes.bool.isRequired,
  }).isRequired,
  showRequirements: PropTypes.bool,
  strength: PropTypes.number,
};

export { PasswordStrengthIndicator };
