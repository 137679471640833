import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import FieldRow from 'components/form/FieldRow';
import InputRadio from 'components/form/InputRadio';

import { SkipHeading } from './styled-components';

const SKIP_QUESTION_ID = 'skip-question-btn';

class SkipQuestion extends PureComponent {
  static propTypes = { onClick: PropTypes.func.isRequired };

  render() {
    const { onClick } = this.props;
    return (
      <div>
        <SkipHeading>
          <FormattedMessage id="questionnaire.questions.not_sure" />
        </SkipHeading>
        <FieldRow>
          <InputRadio
            checked={false}
            name="radioInput"
            testId="SkipQuestion-radio"
            onChange={onClick}
            id={SKIP_QUESTION_ID}
            label={<FormattedMessage id="questionnaire.questions.skip" />} />
        </FieldRow>
      </div>
    );
  }
}

export default SkipQuestion;
