import assert from 'assert';
import uuid from 'uuid/v4';

import { CONSTRAIN_TYPE, CONSTRAIN_KEY } from 'Visualization/helpers/constants';

export const options = () => ({
  select: {
    id: uuid(),
    name: '',
  },
  radio: {
    id: uuid(),
    name: '',
  },
});

export const questions = (qtvId) => {
  assert.ok(qtvId, 'qtvId<string> is required');

  return {
    choiceShowIfType: {
      name: '',
      kind: 'radio',
      questionnaire_template_version_id: qtvId,
      details: {
        help_text: '',
        options: [options().radio],
      },
    },
    inputReplaceType: {
      name: '',
      kind: 'input',
      questionnaire_template_version_id: qtvId,
      details: {
        id: uuid(),
        help_text: '',
        [CONSTRAIN_KEY]: CONSTRAIN_TYPE.NONE,
      },
    },
  };
};
