import _intersection from 'lodash/intersection';
import queryString from 'qs';

export const getBrowserLocale = (translations, defaultLang) => {
  const { lang } = queryString.parse(window.location.search.slice(1));
  const browserPreferred = window.navigator.languages || [
    window.navigator.language || window.navigator.userLanguage,
  ];
  const preferred = [lang] || browserPreferred;
  const matched = _intersection(preferred, Object.keys(translations));
  if (matched.length > 0) {
    return matched[0];
  }
  return defaultLang;
};
