import cheerio from 'cheerio';

export default function changeBrToParagraphs(html) {
  const $$ = cheerio.load(html);
  if (!$$('br').length) return html;

  $$('p').each((i, paragraph) => {
    if (!$$(paragraph).children('br').length) return;

    $$(paragraph)
      .contents()
      .each((ii, item) => {
        const { tagName, parentNode } = item;

        // we find direct childs of paragraph that are text nodes and and direct child of body
        if (isTextNode(item) && parentNode === paragraph) {
          $$(item).replaceWith(`<p>${$$(item).text()}</p>`);
        }

        // we find and remove direct paragraph br children and remove those
        if (tagName === 'br' && parentNode === paragraph) {
          $$(item).remove();
        }
      });
    $$(paragraph).replaceWith($$(paragraph).html());
  });
  return $$('body').html();
}

function isTextNode(node) {
  return node.nodeType === 3;
}
