import styled from 'styled-components';
import EyeIcon from '@material-ui/icons/Visibility';
import FlexCenter from 'shared/Components/FlexCenter';
import ShareIcon from '@material-ui/icons/Share';
import { rem } from 'polished/lib/index';

export const StyledFlexCenter = styled(FlexCenter)`
  & > button,
  & > a {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const PreviewIcon = styled(EyeIcon)`
  margin-right: 5px;
  max-height: 1rem;
`;

export const StyledShareIcon = styled(ShareIcon)`
  margin-right: 10px;
  font-size: ${rem('20px')} !important;
`;
