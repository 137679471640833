import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';

import { Types } from './actions';

/* ------------- Initial state ------------- */
export const initialState = {
  users: [],
  usersCount: 0,
  error: {},
};

/* ------------- Reducers ------------- */
const setUsers = (state, { users, usersCount }) => update(state, {
  users: { $set: users },
  usersCount: { $set: usersCount },
});

const inviteUserFailure = (state, action) => update(state, { error: { $set: action.error } });

/* ------------- Hookup Reducers To Types ------------- */
export default createReducer(initialState, {
  [Types.FETCH_USERS_SUCCESS]: setUsers,

  [Types.INVITE_USERS_FAILURE]: inviteUserFailure,
});
