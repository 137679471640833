exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-pages-Questionnaire-containers-QuestionList-___index__component___nekAT {\n  padding: 0 16px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  transition-property: opacity;\n  transition-delay: 0.6s;\n  opacity: 0;\n}\n\n.app-pages-Questionnaire-containers-QuestionList-___index__visible____B9lf {\n  transition-delay: 0s;\n  opacity: 1;\n}\n", "", {"version":3,"sources":["/workspace/app/pages/Questionnaire/containers/QuestionList/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAe;EACf,iBAAgB;EAChB,mBAAkB;EAClB,6BAA4B;EAC5B,uBAAsB;EACtB,WAAU;CACZ;;AAEA;EACE,qBAAoB;EACpB,WAAU;CACZ","file":"index.css","sourcesContent":[".component {\n  padding: 0 16px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  transition-property: opacity;\n  transition-delay: 0.6s;\n  opacity: 0;\n}\n\n.visible {\n  transition-delay: 0s;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-pages-Questionnaire-containers-QuestionList-___index__component___nekAT",
	"visible": "app-pages-Questionnaire-containers-QuestionList-___index__visible____B9lf"
};