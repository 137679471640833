import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { enableBatching } from 'redux-batched-actions';
import { REDUX_LOGGER_ENABLED } from 'appRoot/constants/services';
import { createAnalyticsMiddleware } from './middlewares/analytics';
import logger from './middlewares/logger';
import reducers from './redux.js';

function configureStore(initialState = {}, history, { trackingService, loggingReporter }) {
  const sagaMiddleware = createSagaMiddleware();
  const analyticsMiddleware = createAnalyticsMiddleware({ trackingService, loggingReporter });
  const middleware = [routerMiddleware(history), sagaMiddleware, analyticsMiddleware];
  let composeEnhancers = compose;

  if (REDUX_LOGGER_ENABLED) {
    middleware.push(logger);
  }

  if (typeof global === 'object' && global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }

  const enhancers = applyMiddleware(...middleware);
  const store = {
    ...createStore(enableBatching(connectRouter(history)(reducers)), initialState, composeEnhancers(enhancers)),
    runSaga: sagaMiddleware.run,
  };
  return store;
}

export default configureStore;
