import styled from 'styled-components';

export default styled.div`
  & > div {
    display: ${p => (p.previewMode ? 'none' : 'block')} !important;
    width: 100% !important;
    border: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  }
`;
