import React from 'react';
import { rem } from 'polished';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { selectWhiteLabelCompanyUrl } from 'containers/App/selectors';

import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

const Link = styled.a`
  color: inherit;
`;

const Top = styled.div`
  font-size: ${rem('12px', 15)};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  vertical-align: center;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  width: 100%;
  height: 40px;
  text-decoration: none solid rgb(125, 125, 125);
  text-align: right;
  color: #7d7d7d;
  text-transform: uppercase;
`;

const StyledCloseIcon = styled(CloseIcon)`
  font-size: ${rem('16px', 15)};
  margin-left: 8px;
  margin-right: 8px;
  vertical-align: middle;
  transform: translate(0, -1px);
`;

const WhiteLabelTop = (props) => {
  const { companyUrl } = props;
  return (
    <Top>
      <Link href={companyUrl}>
        <FormattedMessage id="questionnaire.close" />
        <StyledCloseIcon />
      </Link>
    </Top>
  );
};

function mapStateToProps(state) {
  return { companyUrl: selectWhiteLabelCompanyUrl(state) };
}
export default connect(mapStateToProps)(WhiteLabelTop);
