import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import debounce from 'lodash/debounce';
import SearchIcon from '@material-ui/icons/Search';
import Autorenew from '@material-ui/icons/Autorenew';
import styled from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';

import InputText from 'components/form/InputText';
import Rotate from 'shared/Components/Rotate';

const StyledAutorenew = styled(Autorenew)`
  animation: ${Rotate} 2s linear infinite;
`;

const StyledInput = styled(({ activeStyling, ...props }) => <InputText {...props} />)`
  && {
    height: 42px;
    border-radius: 4px;
  }
  ${props => props.activeStyling && 'color: #0f427d'};
`;

const SearchIconWrapper = styled(({ activeStyling, ...props }) => <div {...props} />)`
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  display: flex;
  ${props => props.activeStyling && `color: ${props.theme.blueLight}`};
`;

class Search extends PureComponent {
  state = { isSearching: false, value: '' };

  onSearch = debounce((value) => {
    if (this.props.onSearch) {
      this.props.onSearch(value);
    }
  }, 500);

  onChange = (e) => {
    const newValue = e.target.value;
    this.setState({ isSearching: newValue.length > 0, value: newValue });

    if (!this.props.onSearch) {
      return false;
    }
    this.onSearch(newValue);
    return true;
  };

  removeSearch = () => {
    this.setState({ isSearching: false, value: '' });
    this.props.onSearch('');
  };

  render() {
    const { isLoading, intl } = this.props;
    const { isSearching, value } = this.state;

    return (
      <FormControl fullWidth>
        <StyledInput
          activeStyling={isSearching}
          placeholder={intl.formatMessage({ id: 'top_navbar.search' })}
          onChange={this.onChange}
          value={value}
          type="search"
          testId="Search-input"
          endAdornment={(
            <Fragment>
              {isLoading
                ? <StyledAutorenew />
                : (
                  <SearchIconWrapper activeStyling={isSearching}>
                    <SearchIcon />
                  </SearchIconWrapper>
                )
              }
            </Fragment>
        )} />
      </FormControl>
    );
  }
}
Search.propTypes = {
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default injectIntl(Search);
