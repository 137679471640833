import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EllipsisMenu, { EllipsisMenuButton } from 'components/EllipsisMenu';

function ActionCell({ testId, getMenuItems }) {
  const menuAnchorEl = React.useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openMenu = (e) => {
    e.stopPropagation();
    setIsMenuOpen(true);
  };
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <>
      <EllipsisMenuButton
        ref={menuAnchorEl}
        onClick={openMenu}
        isOpen={isMenuOpen}
        testId={testId} />
      <EllipsisMenu
        domEl={menuAnchorEl.current}
        isOpen={isMenuOpen}
        handleClose={closeMenu}
        menuItems={getMenuItems({ closeMenu })} />
    </>
  );
}

ActionCell.propTypes = {
  getMenuItems: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

export default ActionCell;
