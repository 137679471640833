exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-form-FieldRow-___index__component___EGTIh {\n  margin: 10px 0;\n  width: 100%;\n}\n\n.app-components-form-FieldRow-___index__component--inline___3eudo {\n  display: flex;\n}\n\n.app-components-form-FieldRow-___index__component--inline___3eudo :nth-child(n + 2) {\n    margin-top: 5px;\n    margin-left: 15px;\n  }\n", "", {"version":3,"sources":["/workspace/app/components/form/FieldRow/index.css"],"names":[],"mappings":"AAAA;EACE,eAAc;EACd,YAAW;CACb;;AAEA;EACE,cAAa;CAMf;;AAJE;IACE,gBAAe;IACf,kBAAiB;GACnB","file":"index.css","sourcesContent":[".component {\n  margin: 10px 0;\n  width: 100%;\n}\n\n.component--inline {\n  display: flex;\n\n  :nth-child(n + 2) {\n    margin-top: 5px;\n    margin-left: 15px;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-components-form-FieldRow-___index__component___EGTIh",
	"component--inline": "app-components-form-FieldRow-___index__component--inline___3eudo"
};