import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { isLoading as isLoadingSelector } from 'utils/statusSelectors';
import Modal from 'components/Modal';
import Button, { DANGER, SMALL, LARGE } from 'components/Button';
import { Block } from 'icons/Block';
import { Creators as userQuestionnaireActions, Types as userQuestionnaireActionTypes } from 'ducks/questionnaire/actions';
import { Creators as documentActions, Types as documentActionTypes } from 'pages/DocumentDetails/actions';
import { selectCurrentDocumentVersion } from 'pages/DocumentDetails/selectors';

import AbortSigningModal from './AbortSigningModal';

class AbortSigning extends Component {
  static propTypes = {
    cancelUserQuestionnaireSigning: PropTypes.func.isRequired,
    cancelDocumentVersionSigning: PropTypes.func.isRequired,
    esigningModule: PropTypes.bool.isRequired,
    userQuestionnaireId: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool,
    currentVersion: PropTypes.shape({ slug: PropTypes.string }),
  };

  state = { isOpen: false };

  toggleModal = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  cancelSigning = () => {
    const {
      currentVersion,
      cancelUserQuestionnaireSigning,
      cancelDocumentVersionSigning,
      userQuestionnaireId,
    } = this.props;
    if (currentVersion.isQuestionnaire) {
      cancelUserQuestionnaireSigning(userQuestionnaireId);
    } else {
      cancelDocumentVersionSigning(currentVersion.slug);
    }
    return this.toggleModal();
  };

  render() {
    const { esigningModule, isSubmitting, isMobile } = this.props;
    const { isOpen } = this.state;

    if (!esigningModule) return null;

    return (
      <React.Fragment>
        <Button
          onClick={this.toggleModal}
          color={DANGER}
          size={isMobile ? SMALL : LARGE}
          glyph={Block}
          testId="AbortSigning-open">
          <FormattedMessage id="questionnaire.abort_signing.button_text" />
        </Button>
        <Modal
          size="medium"
          hiddenCloseButton
          toggleModal={this.toggleModal}
          open={isOpen}>
          <AbortSigningModal
            toggleModal={this.toggleModal}
            cancelSigning={this.cancelSigning}
            isSubmitting={isSubmitting} />
        </Modal>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    cancelUserQuestionnaireSigning: userQuestionnaireId => dispatch(userQuestionnaireActions.cancelSigningRequest(userQuestionnaireId)),
    cancelDocumentVersionSigning: documentVersionSlug => dispatch(documentActions.cancelDocumentVersionSigningRequest(documentVersionSlug)),
  };
}
export const iSubmittingSelectorWrapper = state => (
  isLoadingSelector(state, userQuestionnaireActionTypes.CANCEL_SIGNING_REQUEST.replace(/\//g, '_').replace('_REQUEST', ''))
  || isLoadingSelector(state, documentActionTypes.CANCEL_DOCUMENT_VERSION_SIGNING_REQUEST.replace(/\//g, '_').replace('_REQUEST', ''))
);

function mapStateToProps(state) {
  return {
    currentVersion: selectCurrentDocumentVersion(state),
    esigningModule: get(state, 'questionnaire.company.esigning_module'),
    userQuestionnaireId: get(state, 'questionnaire.userQuestionnaire.id'),
    isSubmitting: iSubmittingSelectorWrapper(state),
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AbortSigning);
