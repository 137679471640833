import React from 'react';
import PropTypes from 'prop-types';
import Warning from '@material-ui/icons/Warning';
import BaseNotification from './BaseNotification';

const ErrorNotification = ({ message, values }) => (
  <BaseNotification message={message} values={values} icon={Warning} variant="error" />
);

ErrorNotification.propTypes = {
  message: PropTypes.string.isRequired,
  values: PropTypes.instanceOf(Object),
};

export default ErrorNotification;
