import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalFooter, ModalContent } from 'components/Modal';
import Button, { SECONDARY } from 'components/Button';
import { FormattedMessage } from 'react-intl';

export const ActivateUserModal = ({ open, closeModal, toggleUserActivation, userDisabled, userEmail }) => (
  <Modal
    size="small"
    toggleModal={closeModal}
    open={open}
    modalTitle={<FormattedMessage id={!userDisabled ? 'user_edit.deactivate_user' : 'user_edit.activate_user'} />}>
    <ModalContent>
      <FormattedMessage
        tagName="span"
        id={!userDisabled ? 'user_edit.deactivate_user_description' : 'user_edit.activate_user_description'}
        values={{ userEmail }} />
    </ModalContent>

    <ModalFooter>
      <Button onClick={closeModal} variant={SECONDARY}>
        <FormattedMessage id="user_edit.cancel" />
      </Button>
      <Button onClick={toggleUserActivation}>
        <FormattedMessage id="user_edit.confirm" />
      </Button>
    </ModalFooter>
  </Modal>
);

ActivateUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleUserActivation: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  userDisabled: PropTypes.bool.isRequired,
  userEmail: PropTypes.string.isRequired,
};
