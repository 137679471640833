import React, { Fragment, Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import DataProvider from 'shared/DataProvider';
import RecordsTable, { TableCell } from 'shared/Components/RecordsTable';
import EmptyBucket from 'components/EmptyBucket';
import { EmptyBucket as EmptyBucketIcon } from 'icons/EmptyBucket';
import TableRow from './TableRow';
import { Creators } from '../actions';

class Trash extends Component {
  static propTypes = {
    fetchTrash: PropTypes.func.isRequired,
    restoreItem: PropTypes.func.isRequired,
    intl: intlShape,
  };

  render() {
    const { fetchTrash, intl, restoreItem } = this.props;

    return (
      <DataProvider
        fetchData={fetchTrash}
        folderId={null}
        itemKey="trash"
        statusKey="appTrashFetchTrash">
        {
          ({ data }) => (
            <Fragment>
              <RecordsTable
                testId="TrashTemplate-table"
                data={data.items}
                total={data.total}
                columnHeaders={[
                  <TableCell width="40%" key="template_name">
                    <FormattedMessage id="panel.trash.show.template_name" />
                  </TableCell>,
                  <TableCell width="10%" key="deleted_at">
                    <FormattedMessage id="panel.trash.show.deleted_at" />
                  </TableCell>,
                  <TableCell width="10%" key="edited_at">
                    <FormattedMessage id="panel.trash.show.edited_at" />
                  </TableCell>,
                  <TableCell width="12%" key="created_by">
                    <FormattedMessage id="panel.trash.show.created_by" />
                  </TableCell>,
                  <TableCell key="actions" />,
                ]}
                EmptyComponent={[
                  <EmptyBucket
                    heading={intl.formatMessage({ id: 'panel.trash.empty_bucket.heading' })}
                    paragraph={intl.formatMessage({ id: 'panel.trash.empty_bucket.paragraph' })}
                    icon={<EmptyBucketIcon />}
                    key="trash-empty-bucket" />,
                ]}
                renderRow={item => (
                  <TableRow
                    key={item.trashableId}
                    item={item}
                    openDeleteDialog={this.openDeleteDialog}
                    onRestore={restoreItem} />
                )} />
            </Fragment>
          )
        }
      </DataProvider>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    null,
    {
      fetchTrash: Creators.fetchTrashRequest,
      restoreItem: Creators.restoreItem,
    },
  ),
)(Trash);
