import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styled from 'styled-components';
import { Slot } from 'react-slot-fill';
import AddIcon from '@material-ui/icons/Add';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import proptypes from 'utils/proptypes';
import Separator from 'shared/Components/Separator';
import InputText from 'components/form/InputText';
import Label from 'components/form/Label';
import FieldRow from 'components/form/FieldRow';
import CheckboxInput from './CheckboxInput';
import TriggersFn from '../TriggersFn';
import Action from '../Action';
import Option from './Option';
import withTriggersFnHoc from '../withTriggersFnHoc';
import withEditableOptionsHoc from '../withEditableOptionsHoc';
import HelpTextEditor from '../HelpTextEditor';
import SuggestProperty from '../SuggestProperty';

const OptionsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  .options-header {
    display: flex;
    flex-direction: row;
  }
`;

class ChoiceShowIfType extends Component {
  static propTypes = {
    enableTriggersFn: PropTypes.func.isRequired,
    stopTriggersFn: PropTypes.func.isRequired,
    refreshOptions: PropTypes.func.isRequired,
    updateQuestion: PropTypes.func.isRequired,
    updateQuestionDetails: PropTypes.func.isRequired,
    updateOption: PropTypes.func.isRequired,
    addNewOption: PropTypes.func.isRequired,
    removeOption: PropTypes.func.isRequired,
    question: proptypes.questionScheme,

    assignAndKeepOpen: PropTypes.func.isRequired,
    triggerImmediately: PropTypes.bool.isRequired,
    triggersFnEnabled: PropTypes.bool.isRequired,
    triggeredOptionId: PropTypes.string,
    intl: intlShape.isRequired,
  };

  componentDidMount() {
    this.mainTextField.focus();
  }

  updateQuestionKind = (e) => {
    let newVal = '';
    if (this.props.question.kind === 'radio') {
      newVal = 'checkbox';
    } else {
      newVal = 'radio';
    }
    const fakeEvent = {
      target: {
        value: newVal,
        name: e.target.name,
      },
    };
    this.props.updateQuestion(fakeEvent);
  };

  render() {
    const { intl, question } = this.props;

    /* eslint-disable */
    return (
      <div>
        {this.props.triggersFnEnabled === false ? null : (
          <TriggersFn
            logicType="choiceShowIfType"
            triggerImmediately={this.props.triggerImmediately}
            stopTriggersFn={this.props.stopTriggersFn}
            onSuccess={this.props.assignAndKeepOpen}
            questionId={question.id}
            optionId={this.props.triggeredOptionId} />
        )}
        <FieldRow>
          <Label>
            <FormattedMessage id="visualization.logics_manager.question_label" />
          </Label>
          <SuggestProperty question={this.props.question} onChange={this.props.updateQuestion}>
            <InputText
              value={this.props.question.name}
              testId="LogicsManager-questionName"
              name="name"
              autoFocus
              placeholder={intl.formatMessage({ id: 'visualization.logics_manager.question_placeholder' })}
              inputRef={x => (this.mainTextField = x)}
              onChange={this.props.updateQuestion} />
          </SuggestProperty>
        </FieldRow>
        <HelpTextEditor
          value={question.details.help_text}
          name="help_text"
          label={intl.formatMessage({ id: 'visualization.logics_manager.help_text_label' })}
          onChangeFn={this.props.updateQuestionDetails} />
        <OptionsContainer data-test-id="choise-options-container">
          <Label>
            <FormattedMessage id="visualization.logics_manager.choice.options" />
          </Label>
          {question.details.options.map((option, index) => (
            <Option
              key={option.id}
              value={option.name}
              index={index}
              onChangeFn={this.props.updateOption}
              removeOption={this.props.removeOption}
              triggersFnEnabled={this.props.triggersFnEnabled}
              option={option}
              enableTriggersFn={() => this.props.enableTriggersFn(option.id, question)}
              stopTriggersFn={this.props.stopTriggersFn}
              refreshOptions={this.props.refreshOptions}
              placeholder={intl.formatMessage({ id: 'visualization.logics_manager.choice.option_placeholder' })}
              linkTranslation={intl.formatMessage({ id: 'visualization.logics_manager.link' })}
              unlinkTranslation={intl.formatMessage({ id: 'visualization.logics_manager.unlink' })}
              removeTranslation={intl.formatMessage({ id: 'visualization.logics_manager.remove' })} />
          ))}
        </OptionsContainer>
        <Action
          onClick={this.props.addNewOption}
          title={<FormattedMessage id="visualization.logics_manager.choice.add_option" />}
          testId="LogicsManager-addOption"
          icon={AddIcon} />
        <Separator />
        <CheckboxInput
          onChangeFn={this.updateQuestionKind}
          value={question.kind}
          name="kind"
          label={intl.formatMessage({ id: 'visualization.logics_manager.choice.allow_multiple' })}
          labelCaption={intl.formatMessage({ id: 'visualization.logics_manager.choice.allow_multiple_caption' })}
          removeTranslation={intl.formatMessage({ id: 'visualization.logics_manager.remove' })} />
        <Separator />
        <Slot name="LogicsManager.Close" />
      </div>
    );
    /* eslint-enable */
  }
}

export default compose(
  withTriggersFnHoc,
  withEditableOptionsHoc,
  injectIntl,
)(ChoiceShowIfType);
