import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import styles from './UserEditPageHeader.css';

export const UserEditPageHeader = () => (
  <Link to="/members" className={styles.component} data-test-id="UserEdit-goBack">
    <ChevronLeftIcon className={styles.icon} />
    <FormattedMessage id="user_edit.go_back" />
  </Link>
);
