exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-containers-DuplicateDocument-___index__title___2Q8aE {\n  text-align: center;\n}\n\n.app-containers-DuplicateDocument-___index__subheading___JMuGB {\n  margin-bottom: 32px;\n}\n\n.app-containers-DuplicateDocument-___index__italic___3Xc8M {\n  font-style: italic;\n}\n", "", {"version":3,"sources":["/workspace/app/containers/DuplicateDocument/index.css"],"names":[],"mappings":"AAAA;EACE,mBAAkB;CACpB;;AAEA;EACE,oBAAmB;CACrB;;AAEA;EACE,mBAAkB;CACpB","file":"index.css","sourcesContent":[".title {\n  text-align: center;\n}\n\n.subheading {\n  margin-bottom: 32px;\n}\n\n.italic {\n  font-style: italic;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"title": "app-containers-DuplicateDocument-___index__title___2Q8aE",
	"subheading": "app-containers-DuplicateDocument-___index__subheading___JMuGB",
	"italic": "app-containers-DuplicateDocument-___index__italic___3Xc8M"
};