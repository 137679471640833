import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import cn from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import Initials from 'shared/Components/Initials';
import Button from 'components/Button';
import Switch from 'components/form/Switch';
import getInitials from 'utils/getInitials';
import proptypes from 'utils/proptypes';

import styles from './UserEdit.css';
import { ReinviteUserModal } from './ReinviteUserModal';
import { ActivateUserModal } from './ActivateUserModal';
import UserEditForm from './UserEditForm';

export class UserEdit extends PureComponent {
  static propTypes = {
    user: proptypes.user.isRequired,
    memberId: PropTypes.string.isRequired,
    fetchUser: PropTypes.func.isRequired,
    saveUser: PropTypes.func.isRequired,
    reinviteUser: PropTypes.func.isRequired,
  };

  state = {
    activateUserModalOpened: false,
    reinviteUserModalOpened: false,
  };

  componentDidMount() {
    this.props.fetchUser(this.props.memberId);
  }

  openActivateUserModal = () => {
    this.setState({ activateUserModalOpened: true });
  };

  closeActivateUserModal = () => {
    this.setState({ activateUserModalOpened: false });
  };

  openReinviteUserModal = () => {
    this.setState({ reinviteUserModalOpened: true });
  };

  closeReinviteUserModal = () => {
    this.setState({ reinviteUserModalOpened: false });
  };

  toggleUserActivation = () => {
    this.closeActivateUserModal();
    this.props.saveUser({ disabled: !this.props.user.disabled });
  };

  reinviteUser = () => {
    this.closeReinviteUserModal();
    this.props.reinviteUser();
  };

  render() {
    const {
      user: {
        disabled,
        roles,
        firstName,
        lastName,
        email,
        invitationAccepted,
      },
      saveUser,
    } = this.props;
    const { reinviteUserModalOpened, activateUserModalOpened } = this.state;
    return (
      <Fragment>
        <div className={styles.component}>
          <div className={styles.content}>
            <Initials className={styles.initials} type={_get(roles, '0')}>{getInitials(firstName, lastName, email)}</Initials>
            <UserEditForm user={this.props.user} onSubmit={saveUser} />
          </div>
          <div className={styles.footer}>
            <div className={cn(styles.footerSection, styles.footerLeftSection)}>
              <div className={styles.activateUserLabel}>
                <FormattedMessage id="user_edit.active_user" />
                :
              </div>
              <Switch checked={!disabled} onChange={this.openActivateUserModal} />
            </div>
            <div className={cn(styles.footerSection, styles.footerRightSection)}>
              {!invitationAccepted && (
              <Button onClick={this.openReinviteUserModal} glyph={AddIcon}>
                <FormattedMessage id="user_edit.reinvite_user" />
              </Button>
              )}
            </div>
          </div>
        </div>
        <ActivateUserModal
          open={activateUserModalOpened}
          closeModal={this.closeActivateUserModal}
          toggleUserActivation={this.toggleUserActivation}
          userDisabled={disabled}
          userEmail={email} />
        <ReinviteUserModal
          open={reinviteUserModalOpened}
          closeModal={this.closeReinviteUserModal}
          reinviteUser={this.reinviteUser}
          userEmail={email} />
      </Fragment>
    );
  }
}
