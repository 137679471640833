import React, { Fragment } from 'react';
import Switch from 'components/form/Switch';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import Typography from 'components/Typography';
import InfoBox from 'components/InfoBox';
import styles from './TemplateSettings.css';

const contactAddress = 'mailto:support@lexolve.com';

const infoBoxDescription = (
  <>
    <FormattedMessage id="template_settings.signing_upsell_description" />
    <a className={styles.infoBoxLink} href={contactAddress}>
      <FormattedMessage id="template_settings.signing_upsell_email_link_description" />
    </a>
  </>
);
export const SigningEnabledComponent = ({ esigningModule }) => (
  <div className={styles.container}>
    <div className={styles.fieldWrapper}>
      <Field name="signing_enabled">
        {({ field }) => (
          <Fragment>
            <Typography variant="label">
              <FormattedMessage id="template_settings.signing_enabled" />
            </Typography>
            <Switch
              checked={field.value}
              testId="SigningEnabled-switch"
              disabled={!esigningModule}
              {...field} />
          </Fragment>
        )}
      </Field>
    </div>
    {!esigningModule
      && (
        <InfoBox testId="SigningEnabled-infoBox" content={infoBoxDescription} />
      )
    }
  </div>
);
