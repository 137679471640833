import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { withFormik, Field } from 'formik';

import FieldRow from 'components/form/FieldRow';
import InputText from 'components/form/InputText';
import Label from 'components/form/Label';
import Button, { SECONDARY } from 'components/Button';
import * as v from 'shared/validation';
import Modal, { ModalFooter, ModalContent } from 'components/Modal';

const validator = v.createValidator({ folderName: [v.required] });
class FolderModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    onModalClose: PropTypes.func,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.shape({ folderName: PropTypes.string }),
    name: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    submitForm: PropTypes.func.isRequired,
    modalTitleId: PropTypes.string.isRequired,
  };

  render() {
    const { isOpen, onModalClose, handleChange, handleBlur, values, submitForm, modalTitleId } = this.props;
    return (
      <Modal
        open={isOpen}
        onClose={onModalClose}
        testId="FolderModal-dialog"
        size="medium"
        modalTitle={<FormattedMessage id={modalTitleId} />}
        toggleModal={onModalClose}>
        <form onSubmit={submitForm}>
          <ModalContent>
            <FieldRow>
              <Label htmlFor="folderName">
                <FormattedMessage id="panel.folders.folder_modal.folder_name" />
              </Label>
              <Field
                name="folderName"
                onChange={handleChange}
                onBlur={handleBlur}>
                {({ form, field }) => (
                  <InputText
                    {...field}
                    autoFocus
                    errorMessage={form.errors[field.name]}
                    testId="FolderModal-input" />
                )}
              </Field>
            </FieldRow>
          </ModalContent>
          <ModalFooter>
            <Button testId="FolderModal-cancelButton" onClick={onModalClose} variant={SECONDARY} type="button">
              <FormattedMessage id="shared.cancel" />
            </Button>
            <Button testId="FolderModal-okButton" disabled={!values.folderName} type="submit">
              <FormattedMessage id="shared.ok" />
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

const formikConfig = {
  mapPropsToValues: props => ({ folderName: props.name }),
  isInitialValid: true,
  validate: validator,
  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true);
    props.onSubmit(values.folderName);
    props.onModalClose();
  },
};
export default compose(
  withFormik(formikConfig),
)(FolderModal);
