import assert from 'assert';

/**
 * Unified tracking service
 */
class TrackingService {
  constructor(amplitudeService, googleAnalyticsService, mixpanelService, logger) {
    assert.ok(amplitudeService, 'amplitudeService is required');
    assert.ok(googleAnalyticsService, 'googleAnalyticsService is required');
    assert.ok(mixpanelService, 'mixpanelService is required');
    assert.ok(logger, 'logger is required');
    this._amplitudeService = amplitudeService;
    this._googleAnalyticsService = googleAnalyticsService;
    this._mixpanelService = mixpanelService;
    this._logger = logger;
  }

  /**
   * Unified event log / tracking
   *
   * @param {string} eventName - event name
   * @param {string} context - context for the event fe. "Questionnaire"
   * @param {object} properties - object of properties related to that event
   */
  track(eventName, context = '', properties = {}) {
    this._logger.info('[TRACK] Tracking:', { eventName, context, properties });
    this._amplitudeService.logEvent(eventName, { context, ...properties });

    let value;
    if (Object.keys(properties).length === 1) {
      value = properties[Object.keys(properties)[0]];
    } else {
      value = properties;
    }

    this._googleAnalyticsService.sendEvent({
      action: eventName,
      category: context,
      value,
    });

    this._mixpanelService.track(eventName, { context, ...properties });
  }
}

export default TrackingService;
