import React, { Fragment, Component } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import * as v from 'shared/validation';

import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import TagsInput from 'components/form/TagsInput';
import { SEQUENTIAL, PARALLEL } from 'shared/constants/signingOrder';
import { ModalContent } from 'components/Modal';
import FieldRow from 'components/form/FieldRow';
import MobileView from 'components/MobileView';
import Typography from 'components/Typography';
import IconButton, { MINIMAL } from 'components/IconButton';
import LabelWrapper from './LabelWrapper';

import styles from './styles.css';

const signingOrderData = {
  [SEQUENTIAL]: {
    title: 'questionnaire.esign.form.signing_sequential',
    description: 'questionnaire.esign.form.signing_sequential_description',
  },
  [PARALLEL]: {
    title: 'questionnaire.esign.form.signing_parallel',
    description: 'questionnaire.esign.form.signing_parallel_description',
  },
};

export class EsignFormSigneesStep extends Component {
  static propTypes = {
    values: PropTypes.shape({
      userQuestionnaireName: PropTypes.string,
      emails: PropTypes.shape({
        inputValue: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
      }),
      message: PropTypes.string,
      from: PropTypes.string,
    }),
    errors: PropTypes.shape({ emails: PropTypes.string }),
    handleChange: PropTypes.func.isRequired,
    senderHasToSign: PropTypes.bool.isRequired,
    handleBlur: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    setEmailSenderIndex: PropTypes.func.isRequired,
    emailSenderIndex: PropTypes.number,
    signingOrder: PropTypes.oneOf([SEQUENTIAL, PARALLEL]),
  };

  validateTag = (tag, tags) => {
    if (tags.some(email => email.toLowerCase() === tag.toLowerCase())) {
      return 'shared_components.tags_area.errors.email_exist';
    }
    if (v.email(tag)) {
      return 'shared_components.tags_area.errors.invalid_email';
    }
    return '';
  };

  moveRecipientTag = (moveTag, index, toIndex) => {
    const { senderHasToSign, setEmailSenderIndex, emailSenderIndex } = this.props;
    if (senderHasToSign) {
      if (index === emailSenderIndex) {
        setEmailSenderIndex(toIndex);
      }
      if (toIndex === emailSenderIndex) {
        setEmailSenderIndex(index);
      }
    }
    moveTag(index, toIndex);
  };

  removeRecipientTag = (removeTag, event) => {
    const { senderHasToSign, setEmailSenderIndex, emailSenderIndex } = this.props;
    const { index } = event.currentTarget.dataset;
    if (senderHasToSign && index < emailSenderIndex) {
      setEmailSenderIndex(emailSenderIndex - 1);
    }
    removeTag(event);
  };

  onRecipientTagAdd = () => {
    this.props.setEmailSenderIndex(this.props.emailSenderIndex + 1);
  };

  getRecipients = (removeTag, moveTag) => {
    const { signingOrder, senderHasToSign, emailSenderIndex, values: { emails: { tags } } } = this.props;

    return (
      <div className={styles.recipientContainer}>
        {tags.map((tag, index) => (
          <span className={styles.recipient} key={uniqueId(tag)}>
            <span className={styles.recipientsEmail}>{`${index + 1}. ${tag}`}</span>
            <span className={styles.recipientIconContainer}>
              {signingOrder === SEQUENTIAL && (
                <Fragment>
                  <IconButton
                    testId="EsignFormSigneesStep-moveUpButton"
                    disabled={index === 0}
                    size={MINIMAL}
                    onClick={() => this.moveRecipientTag(moveTag, index, index - 1)}>
                    <ArrowDropUp />
                  </IconButton>
                  <IconButton
                    testId="EsignFormSigneesStep-moveDownButton"
                    disabled={index === tags.length - 1}
                    size={MINIMAL}
                    onClick={() => this.moveRecipientTag(moveTag, index, index + 1)}>
                    <ArrowDropDown />
                  </IconButton>
                </Fragment>
              )}
              {!(senderHasToSign && index === emailSenderIndex) && (
                <IconButton
                  testId="EsignFormSigneesStep-removeTagButton"
                  classes={{ root: styles.iconButton }}
                  data-index={index}
                  size={MINIMAL}
                  onClick={event => this.removeRecipientTag(removeTag, event)}>
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              )}
            </span>
          </span>
        ))}
      </div>
    );
  };


  render() {
    const { values, handleChange, handleBlur, intl, signingOrder } = this.props;
    const signingOrderProperties = signingOrderData[signingOrder];

    return (
      <div className={styles.recipientFormContainer}>
        <ModalContent>
          <p className={styles.stepTitle}><FormattedMessage id="questionnaire.esign.form.signees_title" /></p>
          <FieldRow className={styles.fieldRow}>
            <div className={styles.addSigneeContainer}>
              <div className={styles.signeesContainer}>
                <MobileView>
                  {({ isMobile }) => (
                    <Field
                      reversedOrder
                      addManually
                      buttonTestId="EsignFormSigneesStep-addSignee"
                      buttonTextId={`questionnaire.esign.form.${isMobile ? 'signees_add_short' : 'signees_add'}`}
                      component={TagsInput}
                      testId="EsignFormSigneesStep-emails"
                      name="emails"
                      onChange={handleChange}
                      onTagAdd={this.onRecipientTagAdd}
                      validators={[this.validateTag]}
                      onBlur={handleBlur}
                      placeholder={intl.formatMessage({ id: 'questionnaire.esign.form.placeholder.signee' })}
                      value={values.emails}
                      renderCustomTags={this.getRecipients}
                      label={(
                        <LabelWrapper htmlFor="emails">
                          <FormattedMessage id="questionnaire.esign.form.email_addresses" />
                        </LabelWrapper>
                      )} />
                  )}
                </MobileView>
              </div>
            </div>
          </FieldRow>
          <div className={styles.signingOrderContainer}>
            <InfoIcon className={styles.signingOrderInfoIcon} />
            <div>
              <Typography variant="h5" className={styles.signingOrderTitle}>
                <FormattedMessage id={signingOrderProperties.title} />
              </Typography>
              <Typography variant="bodySmall" className={styles.signingOrderDescription}>
                <FormattedMessage id={signingOrderProperties.description} />
              </Typography>
            </div>
          </div>
        </ModalContent>
      </div>
    );
  }
}
