import React from 'react';

export default function Document(props) {
  return (
    <svg
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 15.718999862670898 21.75"
      height="23"
      width="15"
      y="0px"
      x="0px"
      {...props}>
      <g transform="translate(1, 1)">
        <g>
          <g opacity="0.12">
            <polyline
              points="6.506,0 0,0 0,17.152 13.012,17.152 13.012,5.718"
              fill="currentColor"
              vectorEffect="non-scaling-stroke" />
          </g>
          <g opacity="0.4">
            <polyline
              points="6.506,0 6.506,5.718 13.012,5.718"
              fill="currentColor"
              vectorEffect="non-scaling-stroke" />
          </g>
        </g>
        <rect
          height="1.083"
          width="3.125"
          fill="currentColor"
          opacity="0.4"
          y="8.751"
          x="2.235"
          vectorEffect="non-scaling-stroke" />
        <rect
          height="1.083"
          width="8.917"
          fill="currentColor"
          opacity="0.4"
          y="11.418"
          x="2.235"
          vectorEffect="non-scaling-stroke" />
        <rect
          height="1.083"
          width="8.917"
          fill="currentColor"
          opacity="0.4"
          y="14.084"
          x="2.235"
          vectorEffect="non-scaling-stroke" />
      </g>
    </svg>
  );
}
