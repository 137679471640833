import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { MenuItem } from 'components/MenuList';
import SelectedTags from 'components/form/TagsInput/SelectedTags';
import InputCheckbox from 'components/form/InputCheckbox';
import Typography from 'components/Typography';

import styles from './index.css';

export class TagsSelectList extends Component {
  static propTypes = {
    tagsList: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.node })),
    selectedTags: PropTypes.arrayOf(PropTypes.node),
    onChange: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    name: PropTypes.string,
    error: PropTypes.string,
    testId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    selectedTags: [],
    tagsList: [],
    name: '',
    error: '',
  };

  removeTag = (event) => {
    event.stopPropagation();
    this.props.remove(event);
  };

  getSelectedTagsLabel = () => this.props.selectedTags.map(selectedTag => this.props.tagsList.find(tag => tag.value === selectedTag).label);

  renderSelectedTags = () => (
    <SelectedTags
      tags={this.getSelectedTagsLabel()}
      removeTag={this.removeTag} />
  );

  render() {
    const { selectedTags, tagsList, onChange, name, error, testId } = this.props;
    return (
      <>
        <div className={styles.component}>
          <Select
            multiple
            value={selectedTags}
            name={name}
            onChange={onChange}
            classes={{ select: styles.selectSelected }}
            inputProps={{ 'data-test-id': testId }}
            renderValue={this.renderSelectedTags}>
            {tagsList.map(tag => (
              <MenuItem value={tag.value} key={tag.value} classes={{ root: styles.menuItemRoot, selected: styles.menuItemSelected }}>
                <InputCheckbox testId={`${testId}-checkbox`} checked={selectedTags.indexOf(tag.value) > -1} label={tag.label} />
              </MenuItem>
            ))}
          </Select>
        </div>
        <Typography variant="helpText" color="error">
          {error}
        </Typography>
      </>
    );
  }
}
