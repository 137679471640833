import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { LEXOLVE_LANDING_PAGE_PATH } from 'appRoot/constants';
import { selectRedirectOnErrorPath, selectErrorType } from 'ducks/error/selectors';
import Logo from 'components/Logo';
import styles from './index.css';

const getRedirectLabel = (redirectOnErrorPath, error404Key) => (
  redirectOnErrorPath === LEXOLVE_LANDING_PAGE_PATH
    ? 'shared.errors.go_lexolve'
    : `shared.errors.${error404Key}.button`);

export const Error404Component = ({ errorType, redirectOnErrorPath }) => {
  const error404Key = errorType || 'record_not_found';
  return (
    <div className={styles.component}>
      <Logo className={styles.logo} />
      <h1 className={styles.title} data-test-id="Error404-title"><FormattedMessage id={`shared.errors.${error404Key}.title`} /></h1>
      <p className={styles.message} data-test-id="Error404-message"><FormattedMessage id={`shared.errors.${error404Key}.message`} /></p>
      <Link to={redirectOnErrorPath} className={styles.goBack} data-test-id="Error404-button">
        <ChevronLeftIcon className={styles.icon} />
        <FormattedMessage id={getRedirectLabel(redirectOnErrorPath, error404Key)} />
      </Link>
    </div>
  );
};

Error404Component.propTypes = {
  errorType: PropTypes.string,
  redirectOnErrorPath: PropTypes.string,
};

const mapStateToProps = state => ({
  errorType: selectErrorType(state),
  redirectOnErrorPath: selectRedirectOnErrorPath(state),
});

export default connect(mapStateToProps)(Error404Component);
