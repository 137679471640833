import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './index.css';

const PRIMARY = 'primary';
const SECONDARY = 'secondary';
const ERROR = 'error';

export const COLORS = { PRIMARY, SECONDARY, ERROR };

const H1 = 'h1';
const H2 = 'h2';
const H3 = 'h3';
const H4 = 'h4';
const H5 = 'h5';
const BODY_SHORT = 'bodyShort';
const BODY_LONG = 'bodyLong';
const BODY_SMALL = 'bodySmall';
const BUTTON_TEXT = 'buttonText';
const HELP_TEXT = 'helpText';
const CAPTION = 'caption';
const LABEL = 'label';

export const VARIANTS = {
  H1,
  H2,
  H3,
  H4,
  H5,
  BODY_LONG,
  BODY_SHORT,
  BODY_SMALL,
  LABEL,
  CAPTION,
  HELP_TEXT,
  BUTTON_TEXT,
};

const variantsComponent = {
  [H1]: 'h1',
  [H2]: 'h2',
  [H3]: 'h3',
  [H4]: 'h4',
  [H5]: 'h5',
  [BODY_SHORT]: 'p',
  [BODY_LONG]: 'p',
  [BODY_SMALL]: 'p',
  [BUTTON_TEXT]: 'span',
  [HELP_TEXT]: 'span',
  [CAPTION]: 'span',
  [LABEL]: 'label',
};

const Typography = ({ variant, color, className, ...otherProps }) => {
  const Component = variantsComponent[variant];
  const classArray = [styles[variant], styles[color], className].filter(e => e !== 'undefined');
  const classes = cn(...classArray);
  return <Component {...otherProps} className={classes} />;
};

Typography.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  color: PropTypes.oneOf(Object.values(COLORS)),
};

Typography.defaultProps = { variant: BODY_SHORT };

export default Typography;
