import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import DocumentIcon from 'icons/Document';
import AutoWidthInput from 'components/form/AutoWidthInput';

import { Creators as QuestionnaireActions } from 'ducks/questionnaire/actions';
import { selectDocumentName, selectCurrentDocumentVersion } from 'pages/DocumentDetails/selectors';
import { Creators as documentDetailsActions } from 'pages/DocumentDetails/actions';

import styles from './TopToolbar.css';

export class DocumentNameComponent extends React.Component {
  static propTypes = {
    updateUserQuestionnaireName: PropTypes.func.isRequired,
    updateDocumentVersionName: PropTypes.func.isRequired,
    updateDocumentName: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    userQuestionnaireName: PropTypes.string,
    userQuestionnaireId: PropTypes.string,
    disabled: PropTypes.bool,
  };

  state = { name: undefined };

  componentDidUpdate(prevProps) {
    const stateNotUpdated = this.state.name === undefined && this.props.userQuestionnaireName !== undefined;
    if (stateNotUpdated || prevProps.userQuestionnaireName !== this.props.userQuestionnaireName) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ name: this.props.userQuestionnaireName });
    }
  }

  updateName = (name) => {
    const {
      userQuestionnaireId,
      updateUserQuestionnaireName,
      updateDocumentName,
      updateDocumentVersionName,
      currentDocumentVersion,
    } = this.props;

    if (currentDocumentVersion.isQuestionnaire) {
      updateUserQuestionnaireName(userQuestionnaireId, name);
      updateDocumentName(name);
    } else {
      updateDocumentVersionName(name, currentDocumentVersion);
    }
  }

  onNameChange = (e) => {
    const name = e.target.value;
    this.setState({ name });
  };

  onBlur = () => {
    const { userQuestionnaireName } = this.props;
    const { name } = this.state;
    if (name) {
      this.updateName(name);
    } else {
      this.setState({ name: userQuestionnaireName });
    }
  }

  render() {
    const { disabled, intl } = this.props;
    const { name } = this.state;
    return (
      <div className={styles.documentName}>
        <DocumentIcon className={styles.documentIcon} />
        <div className={styles.documentInputWrapper}>
          <AutoWidthInput
            testId="DocumentName-input"
            inputClassName={styles.documentNameInput}
            rootClassName={styles.documentNameInputRoot}
            readOnly={disabled}
            disableUnderline={disabled}
            data-tip={intl.formatMessage({ id: 'questionnaire.top_toolbar.rename_tooltip' })}
            value={name}
            onBlur={this.onBlur}
            onChange={this.onNameChange} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateDocumentName: documentDetailsActions.updateDocumentName,
  updateDocumentVersionName: documentDetailsActions.updateDocumentVersionNameRequest,
  updateUserQuestionnaireName: QuestionnaireActions.updateUserQuestionnaireName,
};

const mapStateToProps = state => ({
  userQuestionnaireName: selectDocumentName(state),
  currentDocumentVersion: selectCurrentDocumentVersion(state),
  userQuestionnaireId: state.questionnaire.userQuestionnaire.id,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(DocumentNameComponent);
