exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-pages-UserProfile-components-___styles__languageSelector___98p8O {\n  padding: 20px;\n}\n\n.app-pages-UserProfile-components-___styles__languageUnselected___3QnEN {\n  color: #242424;\n  color: var(--c-bw-80);\n}\n", "", {"version":3,"sources":["/workspace/app/pages/UserProfile/components/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAa;CACf;;AAEA;EACE,eAAqB;EAArB,sBAAqB;CACvB","file":"styles.css","sourcesContent":[".languageSelector {\n  padding: 20px;\n}\n\n.languageUnselected {\n  color: var(--c-bw-80);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"languageSelector": "app-pages-UserProfile-components-___styles__languageSelector___98p8O",
	"languageUnselected": "app-pages-UserProfile-components-___styles__languageUnselected___3QnEN"
};