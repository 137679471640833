import React, { Component } from 'react';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import FormHeader from './FormHeader';
import DocumentStylesEditor from './DocumentStylesEditor';
import styles from './HeaderAndFooter.css';

export default class HeaderAndFooter extends Component {
  onEditorChange = (form, field) => (e) => {
    form.setFieldValue(field.name, e);
  };

  renderEditor = ({ field, form }) => (
    <DocumentStylesEditor value={field.value} onEditorChange={this.onEditorChange(form, field)} />
  );

  render() {
    return (
      <div className={styles.component}>
        <FormHeader>
          <FormattedMessage id="document_templates.header" />
        </FormHeader>
        <Field name="header_html" render={this.renderEditor} />

        <FormHeader>
          <FormattedMessage id="document_templates.footer" />
        </FormHeader>
        <Field name="footer_html" render={this.renderEditor} />
      </div>
    );
  }
}
