import React from 'react';
import PropTypes from 'prop-types';

import { ItemStyle } from './styled-components';

/**
 * Faux <option /> element
 *
 * @param {Object} obj
 * @param {Node} obj.children
 * @param {Boolean} obj.isSelected
 * @param {function} obj.onMouseDown - Triggers onMouseDown event
 * @param {string|number} [obj.value]
 * @return {ReactElement}
 */
function SelectItem({ children, isSelected, onMouseDown, value }) {
  return (
    <ItemStyle
      className="js-SelectItem"
      onMouseDown={onMouseDown}
      aria-label={`Select ${value}`}
      isSelected={isSelected}>
      {children}
    </ItemStyle>
  );
}

SelectItem.propTypes = {
  children: PropTypes.node.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSelected: PropTypes.bool.isRequired,
};

export default SelectItem;
