import assert from 'assert';

import ApiClient from '../ApiClient';

export default class FoldersService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  fetchFoldersRequest() {
    return this.apiClient.request('/document_folders', { method: 'get' });
  }

  fetchFolders(folderId) {
    assert.ok(folderId, 'folderId<string> is required');

    return this.apiClient.request(`/document_folders/${folderId}`, { method: 'get' });
  }
}
