import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { withFormik } from 'formik';
import Views from 'shared/Components/Views';
import Tabs, { Tab } from 'shared/Components/Tabs';
import { isLoading as isLoadingUtil } from 'utils/statusSelectors';
import debounce from 'lodash/debounce';
import DocumentPreview from '../components/DocumentPreview';
import Styles from '../components/Styles';
import Margins from '../components/Margins';
import HeaderAndFooter from '../components/HeaderAndFooter';
import { Creators } from '../actions';
import calculateHeight from '../utils/calculateHeight';
import styles from './DocumentStyles.css';

export class DocumentStylesComponent extends Component {
  static propTypes = {
    fetchDocumentStyles: PropTypes.func.isRequired,
    updateDocumentStyles: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dirty: PropTypes.bool,
    values: PropTypes.objectOf(PropTypes.any),
    isLoading: PropTypes.bool.isRequired,
    documentStyles: PropTypes.shape({}),
  };

  constructor(props) {
    super(props);
    this.saveDocument = debounce(this.saveDocument, 500);
    this.state = { currentTab: 0 };
  }

  componentDidMount() {
    this.props.fetchDocumentStyles();
  }

  componentDidUpdate(prevProps) {
    if (prevProps && this.props.dirty && this.props.values !== prevProps.values) {
      this.saveDocument();
    }
  }

  saveDocument = () => {
    const { values } = this.props;
    this.props.updateDocumentStyles({
      ...values,
      footer_height: calculateHeight(values.footer_html, values),
      header_height: calculateHeight(values.header_html, values),
    });
  };

  changeTab = (e, tabNumber) => {
    this.setState({ currentTab: tabNumber });
  };

  render() {
    const { currentTab } = this.state;
    const { values, handleSubmit, intl, documentStyles, isLoading } = this.props;
    return (
      <div className={styles.component}>
        <form onSubmit={handleSubmit} noValidate>
          <Tabs indicatorPosition="bottom" onChange={this.changeTab} currentTabIndex={currentTab}>
            <Tab label={intl.formatMessage({ id: 'document_templates.styles' })} />
            <Tab label={intl.formatMessage({ id: 'document_templates.header_and_footer' })} />
            <Tab label={intl.formatMessage({ id: 'document_templates.margins' })} />
          </Tabs>
          <Views index={currentTab}>
            <Styles values={values} />
            <HeaderAndFooter />
            <Margins />
          </Views>
        </form>
        <DocumentPreview previewUrl={documentStyles.preview_url} previewLoading={isLoading} />
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  documentStyles: state.documentStyles.data,
  isLoading: isLoadingUtil(state, ['fetchDocumentStyles', 'updateDocumentStyles']),
});

export const mapDispatchToProps = dispatch => bindActionCreators(
  {
    updateDocumentStyles: Creators.updateDocumentStylesRequest,
    fetchDocumentStyles: Creators.fetchDocumentStylesRequest,
  },
  dispatch,
);

export const formConfig = {
  mapPropsToValues: props => props.documentStyles,
  enableReinitialize: true,
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
  withFormik(formConfig),
)(DocumentStylesComponent);
