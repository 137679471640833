import { getEnv } from 'utils/env';

const GOOGLE_ANALYTICS_TRACKING_ID = getEnv('GOOGLE_ANALYTICS_TRACKING_ID');
const GOOGLE_ANALYTICS_APP2_TRACKING_ID = getEnv('GOOGLE_ANALYTICS_APP2_TRACKING_ID');
const AMPLITUDE_TRACKING_ID = getEnv('AMPLITUDE_TRACKING_ID');
const AMPLITUDE_APP2_TRACKING_ID = getEnv('AMPLITUDE_APP2_TRACKING_ID');
const AMPLITUDE_APP_PROJECTNAME = null;
const AMPLITUDE_APP2_PROJECTNAME = getEnv('AMPLITUDE_APP2_PROJECTNAME');
const MIXPANEL_TOKEN = getEnv('MIXPANEL_TOKEN');
const HUBSPOT_PORTAL_ID = getEnv('HUBSPOT_PORTAL_ID');

const LOG_REPORTING_KEY = getEnv('LOG_REPORTING_KEY');
const LOG_REPORTING_ENV = getEnv('LOG_REPORTING_ENV');
const LOG_REPORTING_ENABLED = getEnv('LOG_REPORTING_ENABLED');
const LOGGER_ENABLED = getEnv('LOGGING');
const REDUX_LOGGER_ENABLED = LOGGER_ENABLED;

const GOOGLE_ANALYTICS_ENABLED = GOOGLE_ANALYTICS_TRACKING_ID !== undefined && GOOGLE_ANALYTICS_APP2_TRACKING_ID !== undefined;
const AMPLITUDE_TRACKING_ENABLED = AMPLITUDE_TRACKING_ID !== undefined;
const MIXPANEL_ENABLED = MIXPANEL_TOKEN !== undefined;

export {
  LOG_REPORTING_ENABLED,
  LOG_REPORTING_ENV,
  LOG_REPORTING_KEY,
  GOOGLE_ANALYTICS_ENABLED,
  GOOGLE_ANALYTICS_TRACKING_ID,
  GOOGLE_ANALYTICS_APP2_TRACKING_ID,
  AMPLITUDE_TRACKING_ENABLED,
  AMPLITUDE_TRACKING_ID,
  AMPLITUDE_APP2_TRACKING_ID,
  AMPLITUDE_APP_PROJECTNAME,
  AMPLITUDE_APP2_PROJECTNAME,
  MIXPANEL_ENABLED,
  MIXPANEL_TOKEN,
  LOGGER_ENABLED,
  REDUX_LOGGER_ENABLED,
  HUBSPOT_PORTAL_ID,
};
