import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button, { SECONDARY, PRIMARY } from 'components/Button';
import PublishModal from './PublishModal';
import withPublishUnpublishHoc from './withPublishUnpublishHoc';

class PublishTemplate extends React.PureComponent {
  static propTypes = {
    toggleModal: PropTypes.func.isRequired,
    buttonVariant: PropTypes.oneOf([SECONDARY, PRIMARY]),
  };

  static defaultProps = { buttonVariant: SECONDARY }

  render() {
    const { buttonVariant, buttonDisabled, toggleModal } = this.props;
    return (
      <React.Fragment>
        <Button
          testId="publish"
          variant={buttonVariant}
          disabled={buttonDisabled}
          onClick={toggleModal}>
          <FormattedMessage id="visualization.publish_unpublish.publish" />
        </Button>
        <PublishModal {...this.props} />
      </React.Fragment>
    );
  }
}

export default withPublishUnpublishHoc(PublishTemplate);
