import React from 'react';
import { FormattedMessage } from 'react-intl';

import Page from 'components/Page';
import Typography, { VARIANTS } from 'components/Typography';
import UserInvitationForm from './components/UserInvitationForm';
import styles from './index.css';

const UserInvitation = () => (
  <Page>
    <div className={styles.component}>
      <Typography variant={VARIANTS.H2}>
        <FormattedMessage id="user_invitations.title" />
      </Typography>
      <Typography variant={VARIANTS.CAPTION} className={styles.caption}>
        <FormattedMessage id="user_invitations.subtitle" />
      </Typography>
      <UserInvitationForm />
    </div>
  </Page>
);

export default UserInvitation;
