import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import styles from './styles.css';

const preserved = ['active', 'started', 'completed', 'sent_for_signing', 'signing_requested'];
const danger = ['expired', 'error', 'rejected', 'signing_expired', 'signing_failed', 'signing_aborted'];
const signed = ['signed'];
const inactive = ['inactive'];
const inProgress = ['draft'];

const Status = ({ status, className }) => (status ? (
  <div className={cn(styles.wrapper, className)}>
    <div className={cn(styles.circle, {
      [styles.preserved]: preserved.includes(status),
      [styles.danger]: danger.includes(status),
      [styles.signed]: signed.includes(status),
      [styles.inactive]: inactive.includes(status),
      [styles.inProgress]: inProgress.includes(status),
    })} />
    <FormattedMessage id={`shared.statuses.${status}`} />
  </div>
) : null);

Status.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Status;
