import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalFooter, ModalContent } from 'components/Modal';
import Button, { SECONDARY } from 'components/Button';
import { FormattedMessage } from 'react-intl';

export const ReinviteUserModal = ({ open, reinviteUser, closeModal, userEmail }) => (
  <Modal
    size="small"
    toggleModal={closeModal}
    open={open}
    modalTitle={<FormattedMessage id="user_edit.reinvite_user" />}>
    <ModalContent>
      <FormattedMessage tagName="span" id="user_edit.reinvite_user_description" values={{ userEmail }} />
    </ModalContent>

    <ModalFooter>
      <Button onClick={closeModal} variant={SECONDARY}>
        <FormattedMessage id="user_edit.cancel" />
      </Button>
      <Button onClick={reinviteUser}>
        <FormattedMessage id="user_edit.confirm" />
      </Button>
    </ModalFooter>
  </Modal>
);

ReinviteUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  reinviteUser: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
};
