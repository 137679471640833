import styled from 'styled-components';
import styleConstants from 'appRoot/style-constants';

export const ItemStyle = styled.button`
  display: flex;
  background: none;
  border: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: ${props => (props.isSelected ? styleConstants.colors.blueLight : styleConstants.colors.grey)};
  padding: 10px 15px;
  text-align: left;
  width: 100%;

  &:hover {
    background-color: ${styleConstants.colors.offWhite};
  }

  &:focus {
    background-color: ${styleConstants.colors.offWhite};
  }
`;
