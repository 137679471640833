import React, { PureComponent } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import cn from 'classnames';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Info from '@material-ui/icons/Info';
import ToggleButton from 'pages/Questionnaire/components/ToggleButton';
import Typography from 'components/Typography';
import Button, { TEXT, SMALL } from 'components/Button';
import QuestionList from 'pages/Questionnaire/containers/QuestionList';
import { StyledLeftToolbar, LeftToolbarHeader } from './styled-components';
import styles from './LeftToolbar.css';

class LeftToolbar extends PureComponent {
  static propTypes = {
    previewMode: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    isLeftToolbarVisible: PropTypes.bool.isRequired,
    onToggleToolbar: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    isIntroductionVisible: PropTypes.bool.isRequired,
    showIntroduction: PropTypes.func.isRequired,
    hideIntroduction: PropTypes.func.isRequired,
  };

  goToTemplate = () => {
    const { match, history } = this.props;
    history.push(`/templates/${match.params.templateId}/edit`);
  };

  render() {
    const { previewMode, intl, onToggleToolbar, isLeftToolbarVisible, showIntroduction, hideIntroduction, isIntroductionVisible } = this.props;

    return (
      <StyledLeftToolbar data-test-id="left-toolbar" isVisible={isLeftToolbarVisible}>
        <LeftToolbarHeader isVisible={isLeftToolbarVisible}>
          <Typography variant="h4" className={styles.headerTitle}>
            <FormattedMessage id="questionnaire.left_toolbar.question_list" />
          </Typography>
          <ToggleButton
            caption={intl.formatMessage({ id: 'questionnaire.left_toolbar.question_list' })}
            rtl
            checked={isLeftToolbarVisible}
            onToggle={onToggleToolbar} />
        </LeftToolbarHeader>
        {previewMode && (
        <Button
          glyph={ArrowBack}
          variant={TEXT}
          size={SMALL}
          onClick={this.goToTemplate}
          className={styles.textBtn}
          data-test-id="Questionnaire-goBack">
          <FormattedMessage id="questionnaire.left_toolbar.go_back_editor" />
        </Button>
        ) }
        {!previewMode && (
          <Button
            glyph={isIntroductionVisible ? ArrowForward : Info}
            variant={TEXT}
            size={SMALL}
            onClick={showIntroduction}
            className={cn(styles.textBtn, { [styles.textBtnGreyed]: !isIntroductionVisible })}
            data-test-id="Questionnaire-introduction">
            <FormattedMessage id="questionnaire.left_toolbar.introduction" />
          </Button>
        )}
        <QuestionList
          isVisible={isLeftToolbarVisible}
          hideIntroduction={hideIntroduction}
          isIntroductionVisible={isIntroductionVisible} />
      </StyledLeftToolbar>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
)(LeftToolbar);
