import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  acceptInvitationRequest: ['payload'],
  acceptInvitationSuccess: ['payload'],
  acceptInvitationFailure: ['error'],
  checkInvitationRequest: ['payload'],
  checkInvitationSuccess: ['payload'],
  checkInvitationFailure: ['error'],
});
