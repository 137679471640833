import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import {
  templatePropertiesSelector,
  templatePropertyEditDocumentEnabledSelector,
} from 'ducks/questionnaire/selectors';

import GridItem from 'components/Grid/Item';
import DownloadPdf, { SECONDARY } from '../modules/DownloadPdf';
import SaveVersion from '../modules/SaveVersion';
import styles from './index.css';


const Edit = ({ isMobile, esigningModule, signingEnabled, disabled, isDocumentEditEnabled }) => {
  const downloadButtonVariant = (esigningModule && signingEnabled) ? SECONDARY : '';
  return (
    <Fragment>
      <GridItem xs="auto" sm="auto">
        {isDocumentEditEnabled && <SaveVersion isMobile={isMobile} />}
      </GridItem>
      <GridItem xs={false} sm="auto">
        <div className={styles.seperator} />
      </GridItem>
      <GridItem xs="auto" sm="auto">
        <DownloadPdf variant={downloadButtonVariant} isMobile={isMobile} disabled={disabled} />
      </GridItem>
    </Fragment>
  );
};

Edit.propTypes = {
  esigningModule: PropTypes.bool.isRequired,
  signingEnabled: PropTypes.bool.isRequired,
  isDocumentEditEnabled: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  disabled: PropTypes.bool,
};

const mapStateToProps = state => ({
  isDocumentEditEnabled: templatePropertyEditDocumentEnabledSelector(state),
  esigningModule: _get(state, 'questionnaire.company.esigning_module'),
  signingEnabled: templatePropertiesSelector(state).signing_enabled,
});

export default connect(mapStateToProps)(Edit);
