exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-pages-Templates-components-ButtonGroup-___index__component___89f6O {\n  margin-left: 5%;\n  display: inline-block;\n  font-size: 1.2em;\n  text-transform: uppercase;\n}\n\n  .app-pages-Templates-components-ButtonGroup-___index__component___89f6O > button,\n  .app-pages-Templates-components-ButtonGroup-___index__component___89f6O > a {\n    margin-right: 5px;\n  }\n", "", {"version":3,"sources":["/workspace/app/pages/Templates/components/ButtonGroup/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAe;EACf,sBAAqB;EACrB,iBAAgB;EAChB,0BAAyB;CAM3B;;EAJE;;IAEE,kBAAiB;GACnB","file":"index.css","sourcesContent":[".component {\n  margin-left: 5%;\n  display: inline-block;\n  font-size: 1.2em;\n  text-transform: uppercase;\n\n  & > button,\n  & > a {\n    margin-right: 5px;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-pages-Templates-components-ButtonGroup-___index__component___89f6O"
};