import queryString from 'qs';
import api from 'shared/utils/api';

const updateQuestionnaireTemplateVersion = (questionnaireTemplateVersionId, params) => api().patch(`/questionnaire_template_versions/${questionnaireTemplateVersionId}`, { data: params });

const updateQuestionnaireTemplate = (questionnaireTemplateId, params) => api().patch(`/questionnaire_templates/${questionnaireTemplateId}`, { data: params });

export const publishQuestionnaireTemplateVersion = (questionnaireTemplateVersionId, params) => api().put(
  `/questionnaire_template_versions/${questionnaireTemplateVersionId}/publish`,
  { data: params },
);

export const unpublishQuestionnaireTemplateVersion = questionnaireTemplateVersionId => api().put(`/questionnaire_template_versions/${questionnaireTemplateVersionId}/unpublish`);

const addNewQuestion = params => api().post('/questions', { data: { question: params } });

const updateQuestion = (questionId, params) => api().patch(`/questions/${questionId}`, { data: { question: params } });

const removeQuestion = questionId => api().delete(`/questions/${questionId}`);

export const fetchTemplatesInfo = queryParams => api().get(`/questionnaire_templates?${queryString.stringify(queryParams)}`);

export const fetchPublishedTemplatesInfo = queryParams => api().get(`/published_templates?${queryString.stringify(queryParams)}`);

export const fetchTemplateVersionHistory = templateId => api().get(`/questionnaire_templates/${templateId}/questionnaire_template_versions`);

const fetchDocumentTemplateStylesheet = () => api().get('/document_templates');

export const moveToTrash = templateId => api().post(`/questionnaire_templates/${templateId}/trash`);

export const fetchDocumentsForTemplate = templateId => api().get(`/questionnaire_templates/${templateId}/user_questionnaires`);

// moved to api/QuestionnaireService but keeped here to support legacy in Editor-v2/sagas.js
export const getDraftTemplateVersion = templateId => api().get(`/questionnaire_templates/${templateId}/edit`);

export const getTemplate = templateId => api().get(`/questionnaire_templates/${templateId}`);

export const validateTemplate = templateSharingSlug => api().get(`/api/v1/questionnaire_templates/${templateSharingSlug}/correctness`);

export const patchTemplateSettings = (templateId, settings) => api().patch(`/questionnaire_template_versions/${templateId}`, { data: { questionnaire_template_version: { qtv_property_attributes: settings } } });

export const getTemplateVersionPreview = (templateId, version) => api().get(`/questionnaire_templates/${templateId}/version_previews/?version=${version}`);

export default {
  updateQuestionnaireTemplateVersion,
  updateQuestionnaireTemplate,
  addNewQuestion,
  updateQuestion,
  removeQuestion,
  publishQuestionnaireTemplateVersion,
  unpublishQuestionnaireTemplateVersion,
  fetchTemplatesInfo,
  fetchPublishedTemplatesInfo,
  fetchDocumentTemplateStylesheet,
};
