exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-containers-ErrorSessionExpired-___styles__c___yrggg {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.app-containers-ErrorSessionExpired-___styles__logo___12-4Z {\n  height: 90px;\n  width: 280px;\n  margin-bottom: 45px;\n}\n\n.app-containers-ErrorSessionExpired-___styles__picture___30-tg {\n  max-width: 300px;\n  margin-bottom: 45px;\n  color: #2d866e;\n  color: var(--c-brand);\n}\n\n.app-containers-ErrorSessionExpired-___styles__heading___2dC4R {\n  color: #242424;\n  color: var(--c-bw-80);\n}\n\n.app-containers-ErrorSessionExpired-___styles__paragraph___2q38N {\n  color: #767676;\n  color: var(--c-bw-50);\n}\n", "", {"version":3,"sources":["/workspace/app/containers/ErrorSessionExpired/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAa;EACb,YAAW;EACX,cAAa;EACb,uBAAsB;EACtB,wBAAuB;EACvB,oBAAmB;CACrB;;AAEA;EACE,aAAY;EACZ,aAAY;EACZ,oBAAmB;CACrB;;AAEA;EACE,iBAAgB;EAChB,oBAAmB;EACnB,eAAqB;EAArB,sBAAqB;CACvB;;AAEA;EACE,eAAqB;EAArB,sBAAqB;CACvB;;AAEA;EACE,eAAqB;EAArB,sBAAqB;CACvB","file":"styles.css","sourcesContent":[".c {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.logo {\n  height: 90px;\n  width: 280px;\n  margin-bottom: 45px;\n}\n\n.picture {\n  max-width: 300px;\n  margin-bottom: 45px;\n  color: var(--c-brand);\n}\n\n.heading {\n  color: var(--c-bw-80);\n}\n\n.paragraph {\n  color: var(--c-bw-50);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"c": "app-containers-ErrorSessionExpired-___styles__c___yrggg",
	"logo": "app-containers-ErrorSessionExpired-___styles__logo___12-4Z",
	"picture": "app-containers-ErrorSessionExpired-___styles__picture___30-tg",
	"heading": "app-containers-ErrorSessionExpired-___styles__heading___2dC4R",
	"paragraph": "app-containers-ErrorSessionExpired-___styles__paragraph___2q38N"
};