exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-SuggestProperty-___index-module__root___2fxpv {\n  position: relative;\n}\n\n.app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-SuggestProperty-___index-module__options___2A8rl {\n  position: absolute;\n  z-index: 1;\n  background-color: white;\n  border: 1px solid gray;\n  border-radius: 5px;\n  width: 100%;\n  margin-top: 10px;\n  box-shadow: 2px 2px 5px grey;\n  max-height: 300px;\n  overflow-x: hidden;\n  overflow-y: scroll;\n  padding: 0;\n}\n\n.app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-SuggestProperty-___index-module__options___2A8rl li {\n  padding: 7px 20px;\n  cursor: pointer;\n}\n\n.app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-SuggestProperty-___index-module__options___2A8rl li:hover {\n  background-color: lightgray;\n}\n", "", {"version":3,"sources":["/workspace/app/bundles/Visualization/Editor-v2/LogicsManager/LogicsTypes/SuggestProperty/index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAkB;CACpB;;AAEA;EACE,mBAAkB;EAClB,WAAU;EACV,wBAAuB;EACvB,uBAAsB;EACtB,mBAAkB;EAClB,YAAW;EACX,iBAAgB;EAChB,6BAA4B;EAC5B,kBAAiB;EACjB,mBAAkB;EAClB,mBAAkB;EAClB,WAAU;CACZ;;AAEA;EACE,kBAAiB;EACjB,gBAAe;CACjB;;AAEA;EACE,4BAA2B;CAC7B","file":"index.module.css","sourcesContent":[".root {\n  position: relative;\n}\n\n.options {\n  position: absolute;\n  z-index: 1;\n  background-color: white;\n  border: 1px solid gray;\n  border-radius: 5px;\n  width: 100%;\n  margin-top: 10px;\n  box-shadow: 2px 2px 5px grey;\n  max-height: 300px;\n  overflow-x: hidden;\n  overflow-y: scroll;\n  padding: 0;\n}\n\n.options li {\n  padding: 7px 20px;\n  cursor: pointer;\n}\n\n.options li:hover {\n  background-color: lightgray;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-SuggestProperty-___index-module__root___2fxpv",
	"options": "app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-SuggestProperty-___index-module__options___2A8rl"
};