import assert from 'assert';

import { put, call, take } from 'redux-saga/effects';
import { Creators, Types } from '../actions';
import { ROOT_DIR, ROOT_FOLDER_ID } from '../constants';

export default function createSagas({ documentService, documentFoldersService }) {
  assert.ok(documentService, 'documentService is required');
  assert.ok(documentFoldersService, 'documentFoldersService is required');

  function* moveToFolderSaga() {
    while (true) {
      const { documentId, folderId, currentFolderId } = yield take(Types.MOVE_TO_FOLDER_REQUEST);
      try {
        yield call([documentService, documentService.moveDocumentToFolder], documentId, folderId || ROOT_FOLDER_ID);
        yield put(Creators.moveToFolderSuccess());
        yield put(Creators.fetchDocumentsRequest(currentFolderId)); // this is done to refresh the documents in the list
      } catch (e) {
        yield put(Creators.moveToFolderFailure(e));
      }
    }
  }

  function* goToFolderSaga() {
    while (true) {
      const { folderId } = yield take(Types.GO_TO_FOLDER_REQUEST);
      if (folderId) {
        yield* _goToSubfolder(folderId);
      } else {
        yield* _goToRootFolder();
      }
    }
  }

  function* _goToRootFolder() {
    try {
      // eslint-disable-next-line camelcase
      const { data: { document_folders } } = yield call(
        [documentFoldersService, documentFoldersService.fetchFoldersRequest],
      );
      yield put(Creators.goToFolderSuccess(document_folders, ROOT_DIR));
    } catch (e) {
      yield put(Creators.goToFolderFailure(e));
    }
  }

  function* _goToSubfolder(folderId) {
    try {
      // eslint-disable-next-line camelcase
      const { data: { document_folder } } = yield call(
        [documentFoldersService, documentFoldersService.fetchFolders],
        folderId,
      );
      yield put(Creators.goToFolderSuccess(
        document_folder.children,
        {
          id: document_folder.id,
          name: document_folder.name,
          parents: document_folder.parents,
        },
      ));
    } catch (e) {
      yield put(Creators.goToFolderFailure(e));
    }
  }

  return {
    moveToFolderSaga,
    goToFolderSaga,
  };
}
