import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import { Wrapper } from './styled-components';

const Carousel = ({ index, className, swipingDisabled, children }) => (
  <Wrapper className={className}>
    <SwipeableViews index={index} disabled={swipingDisabled}>
      {children}
    </SwipeableViews>
  </Wrapper>
);

Carousel.propTypes = {
  index: PropTypes.number,
  swipingDisabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

Carousel.defaultProps = { swipingDisabled: true };

export default Carousel;
