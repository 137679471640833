import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputText from '../InputText';
import styles from './index.css';

export default class AutoWidthInput extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    testId: PropTypes.string.isRequired,
  };

  state = { width: 100 };

  componentDidMount() {
    const mirror = document.createElement('span');
    const inputStyles = window.getComputedStyle(this.input);
    Object.assign(mirror.style, {
      position: 'absolute',
      top: '-9999px',
      left: '-9999px',
      width: 'auto',
      whiteSpace: 'pre',
      opacity: 0,
      border: inputStyles.getPropertyValue('border'),
      fontSize: inputStyles.getPropertyValue('font-size'),
      fontFamily: inputStyles.getPropertyValue('font-family'),
      fontWeight: inputStyles.getPropertyValue('font-weight'),
      fontStyle: inputStyles.getPropertyValue('font-style'),
      fontFeatureSettings: inputStyles.getPropertyValue('font-feature-settings'),
      fontKerning: inputStyles.getPropertyValue('font-kerning'),
      fontStretch: inputStyles.getPropertyValue('font-stretch'),
      fontVariant: inputStyles.getPropertyValue('font-variant'),
      fontVariantCaps: inputStyles.getPropertyValue('font-variant-caps'),
      fontVariantLigatures: inputStyles.getPropertyValue('font-variant-ligatures'),
      fontVariantNumeric: inputStyles.getPropertyValue('font-variant-numeric'),
      letterSpacing: inputStyles.getPropertyValue('letter-spacing'),
      padding: inputStyles.getPropertyValue('padding'),
      textTransform: inputStyles.getPropertyValue('text-transform'),
      ariaHidden: true,
    });
    document.body.appendChild(mirror);
    this.mirror = mirror;
    this.checkWidth(this.props.value);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.checkWidth(this.props.value);
    }
  }

  componentWillUnmount() {
    this.mirror.remove();
  }

  checkWidth = (inputValue) => {
    const { mirror } = this;
    const containerWidth = this.input ? Math.min(this.input.parentElement.parentElement.offsetWidth, 800) : 800;
    while (mirror.firstChild) {
      mirror.removeChild(mirror.firstChild);
    }
    mirror.appendChild(document.createTextNode(inputValue));
    const newWidth = Math.min(
      containerWidth,
      Math.max(100, mirror.getBoundingClientRect().width + 20),
    );
    this.setState({ width: newWidth });
  };

  render() {
    return (
      <InputText
        {...this.props}
        style={{ width: `${this.state.width}px` }}
        inputRef={(el) => {
          this.input = el;
        }}
        rootClassName={cn(this.props.rootClassName, styles.inputRoot)}
        inputClassName={cn(this.props.inputClassName, styles.input)}
        underlineClassName={styles.underline}
        type="text"
        value={this.props.value}
        testId={this.props.testId}
        onChange={this.props.onChange} />
    );
  }
}
