import React from 'react';
import PropTypes from 'prop-types';
import { InformationIcon } from 'icons/Information';
import BaseNotification from './BaseNotification';

const InfoNotification = ({ message, values, className }) => (
  <BaseNotification message={message} values={values} icon={InformationIcon} variant="info" className={className} />
);

InfoNotification.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  values: PropTypes.instanceOf(Object),
};

export default InfoNotification;
