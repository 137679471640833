import assert from 'assert';
import { call, put, take } from 'redux-saga/effects';
import { Types, Creators } from './actions';

export default function createSagas({ documentStylesService }) {
  assert.ok(documentStylesService, 'documentStylesService is required');

  function* fetchDocumentStylesSaga() {
    while (true) {
      yield take(Types.FETCH_DOCUMENT_STYLES_REQUEST);

      try {
        // eslint-disable-next-line camelcase
        const { data: { document_template } } = yield call(
          [documentStylesService, documentStylesService.fetchDocumentStyles],
        );

        yield put(Creators.fetchDocumentStylesSuccess(document_template));
      } catch (e) {
        yield put(Creators.fetchDocumentStylesFailure(e));
      }
    }
  }
  function* updateDocumentStylesSaga() {
    while (true) {
      const action = yield take(Types.UPDATE_DOCUMENT_STYLES_REQUEST);

      try {
        // eslint-disable-next-line camelcase
        const { data: { document_template } } = yield call(
          [documentStylesService, documentStylesService.updateDocumentStyles],
          action.payload,
        );

        yield put(Creators.updateDocumentStylesSuccess(document_template));
      } catch (e) {
        yield put(Creators.updateDocumentStylesFailure(e));
      }
    }
  }

  return {
    fetchDocumentStylesSaga,
    updateDocumentStylesSaga,
  };
}
