const theme = {
  baseFontSize: 16,

  flowColors: [
    'rgba(52, 152, 219,1)',
    'rgba(155, 89, 182,1)',
    'rgba(46, 204, 113,1)',
    'rgba(188, 41, 26, 1)',
    'rgba(186, 188, 26, 1)',
  ],
  flowSidebar: {
    nestBackground: '#f1f4f8',
    nestBorder: '#dae1ed',
    border: '#f1f4f8',
    input: '#f7fafc',
    optionLabel: '#7783a1',
    headerBackground: '#f7fafc',
    badgeBackground: '#a9b5c7',
    header: '#304555',
  },
  mainColor: '#4392f8',
  mainColorHover: '#0a6ff1',
  gray: '#212223',
  lightGray: '#A4AEB5',
  grayLightest: '#aabacb',
  logicManagerGray: '#DFE6F0',
  white: '#ecf0f1',
  subtitleColor: '#7c8d9d',
  blueLight: '#3e94f6',
  blueLighter: '#f2f9ff',
  redLightest: '#fef5f5',
  red: '#f55050',
  border: {
    normal: '#e9edf1',
    dark: '#d6dbe2',
    lightBlue: '#bdd9f9',
    lightGrey: '#f5f5f5',
  },

  errors: {
    color: '#e54949',
    background: '#fef5f5',
    borderColor: '#f9bdbd',
  },

  font: {
    normal: '#0d2d42',
    dark: '#212223',
    darkGrey: '#565656',
    light: '#a2b4c6',
    white: '#ecf0f1',
    label: '#9e9e9e',
    placeholder: '#b3b3b3',
    lightGray: '#7c8d9d',
    lightBlue: '#8cabc8',
    darkRed: '#8a5c58',
    blueGray: '#5d676e',
    logicLabel: '#a9b5c7',
  },

  buttons: {
    primary: '#4392f8',
    primaryHover: '#246ef0',
    red: '#c0392b',
    redHover: '#8c291f',
    green: '#2ecc71',
    greenHover: '#27ae60',
  },

  background: {
    main: '#f1f4f8',
    darkWhite: '#f8f9fb',
    white: '#fff',
    disabled: '#cfd0d2',
    lightBlue: '#f5faff',
    dark: '#212223',
    lightGreen: '#dff9ee',
    lightRed: '#f7d2cd',
  },

  fontFamily: { primary: 'Roboto, sans-serif' },

  alerts: {
    red: '#e54949',
    yellow: '#de9f29',
    green: '#68bb6e',
  },

  rolesColors: {
    admin: '#e3a6a6',
    white_label_user: '#e3a6a6',
    generic_access_user: '#e3a6a6',
    consumer: '#8ecde1',
    producer: '#b4b4df',
  },

  rolesBgs: {
    admin: '#fff0f0',
    white_label_user: '#fff0f0',
    generic_access_user: '#fff0f0',
    consumer: '#eaf5f8',
    producer: '#efeff8',
  },

  status: {
    active: '#3e94f6',
    inactive: '#7c8d9d',
    started: '#3e94f6',
    completed: '#3e94f6',
    expired: '#D86568',
    error: '#D86568',
    signing_failed: '#D86568',
    rejected: '#D86568',
    signed: '#50c788',
    sent_for_signing: '#3e94f6',
    signing_requested: '#3e94f6',
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

export default theme;
