exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-form-TagsInput-SelectedTags-___index__tagWrapper___Ao9Ek {\n  flex-wrap: wrap;\n  position: relative;\n  display: inline-flex;\n  font-size: 1rem;\n  color: #a4a4a4;\n  color: var(--c-bw-30);\n  font-weight: normal;\n}\n\n.app-components-form-TagsInput-SelectedTags-___index__tag___1K-_X {\n  display: flex;\n  align-items: center;\n  padding: 5px 10px;\n  margin: 3px;\n  background-color: #f3f3f3;\n  background-color: var(--c-bw-05);\n  color: #767676;\n  color: var(--c-bw-50);\n  position: relative;\n  border-radius: 4px;\n}\n\n.app-components-form-TagsInput-SelectedTags-___index__clearIcon___2Yk-U {\n  margin-left: 10px;\n  cursor: pointer;\n}\n\n.app-components-form-TagsInput-SelectedTags-___index__emailIcon___3vdJD {\n  margin-right: 5px;\n}\n", "", {"version":3,"sources":["/workspace/app/components/form/TagsInput/SelectedTags/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAe;EACf,mBAAkB;EAClB,qBAAoB;EACpB,gBAAe;EACf,eAAqB;EAArB,sBAAqB;EACrB,oBAAmB;CACrB;;AAEA;EACE,cAAa;EACb,oBAAmB;EACnB,kBAAiB;EACjB,YAAW;EACX,0BAAgC;EAAhC,iCAAgC;EAChC,eAAqB;EAArB,sBAAqB;EACrB,mBAAkB;EAClB,mBAAkB;CACpB;;AAEA;EACE,kBAAiB;EACjB,gBAAe;CACjB;;AAEA;EACE,kBAAiB;CACnB","file":"index.css","sourcesContent":[".tagWrapper {\n  flex-wrap: wrap;\n  position: relative;\n  display: inline-flex;\n  font-size: 15px;\n  color: var(--c-bw-30);\n  font-weight: normal;\n}\n\n.tag {\n  display: flex;\n  align-items: center;\n  padding: 5px 10px;\n  margin: 3px;\n  background-color: var(--c-bw-05);\n  color: var(--c-bw-50);\n  position: relative;\n  border-radius: 4px;\n}\n\n.clearIcon {\n  margin-left: 10px;\n  cursor: pointer;\n}\n\n.emailIcon {\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"tagWrapper": "app-components-form-TagsInput-SelectedTags-___index__tagWrapper___Ao9Ek",
	"tag": "app-components-form-TagsInput-SelectedTags-___index__tag___1K-_X",
	"clearIcon": "app-components-form-TagsInput-SelectedTags-___index__clearIcon___2Yk-U",
	"emailIcon": "app-components-form-TagsInput-SelectedTags-___index__emailIcon___3vdJD"
};