import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import TemplateHistoryTable from '../components/TemplateHistoryTable';
import { Creators } from '../reducer';

export const mapStateToProps = state => ({ data: state.templates.templatesHistory });
export const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TemplateHistoryTable);
