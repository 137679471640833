import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash/isEqual';

import proptypes from 'utils/proptypes';
import Item from './Item';
import styles from './styles.css';

class Flow extends Component {
  static propTypes = { flow: PropTypes.arrayOf(proptypes.flowItem).isRequired };

  shouldComponentUpdate(nextProps) {
    if (!isEqual(nextProps.flow, this.props.flow)) {
      return true;
    }
    return false;
  }

  renderItem = item => (
    <div className="col s12" key={item.questionId}>
      <Item nestingLevel={0} flowItem={item} />
    </div>
  );

  render() {
    const { flow } = this.props;

    return (
      <div className={styles.component} id="flow-container">
        <h4>
          <FormattedMessage id="visualization.flow.header" tagName="span" />
        </h4>
        <div className={styles.component__content}>
          {flow.map(this.renderItem)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ flow: state.flow });

export { Flow as FlowRaw };

export default connect(mapStateToProps)(Flow);
