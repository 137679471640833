import union from 'lodash/union';
import isQuestion from 'Visualization/helpers/isQuestion';
import rangy from 'rangy';
import getAllNodesInTree from './getAllNodesInTree';
import NodesHelper from './nodesHelper';

export default function checkIfOnlyOneOptionFromQuestionInSelection(logicType, questionId) {
  if (logicType === 'inputReplaceType') return false;
  const { leftNode, rightNode } = new NodesHelper();
  const options = { isQuestion: true };
  const leftQuestionNodes = getAllNodesInTree(leftNode, ['SPAN', 'DIV'], options);
  const rightQuestionNodes = getAllNodesInTree(rightNode, ['SPAN', 'DIV'], options);
  const questionNodesInsideSelection = rangy
    .getSelection()
    .getRangeAt(0)
    .getNodes([1], isQuestion);

  const questionNodes = union(leftQuestionNodes, rightQuestionNodes, questionNodesInsideSelection);
  const questionIds = questionNodes.map(q => q.getAttribute('data-question-id'));
  return questionIds.includes(questionId);
}
