export default function openUrl(url, fileName = null) {
  const a = document.createElement('a');
  a.href = url;
  if (fileName) {
    a.setAttribute('download', fileName);
  }
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
