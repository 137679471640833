import PageSpinner from 'components/PageSpinner';
import { Creators } from 'ducks/loggedInUser/actions';
import jwtDecode from 'jwt-decode';
import _omit from 'lodash/omit';
import PropTypes from 'prop-types';
import queryString from 'qs';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { getEnv } from 'utils/env';
import { LOCAL_STORAGE_STORE_NAME } from '../../constants';
import { Creators as AppCreators } from '../App/actions';
import mapStateToProps from './selectors';

class WhiteLabelRoute extends PureComponent {
  static propTypes = {
    hasFetchedUser: PropTypes.bool.isRequired,
    mode: PropTypes.string,
    wideContainer: PropTypes.bool,
    fetchProfile: PropTypes.func.isRequired,
    setV2Experience: PropTypes.func.isRequired,
  };

  componentDidMount() {
    document.title = this.props.title;
    const { v2Experience } = queryString.parse(window.location.search, { ignoreQueryPrefix: true });
    if (v2Experience) {
      this.props.setV2Experience();
    }
    if (!this.props.hasFetchedUser) {
      this.props.fetchProfile();
    }
  }

  getWhiteLabelAuthenticationURL(accessToken) {
    let payload;
    try {
      payload = jwtDecode(accessToken);
    } catch (e) {
      payload = {};
    }
    const url = payload.authentication_url || getEnv('FRONTEND_URL');

    const encoded = global.encodeURIComponent(`${window.location.href.split('?')[0]}?token=`);
    return `${url}${encoded}`;
  }

  getCredentialsDirectlyFromStore() {
    return JSON.parse(global.localStorage.getItem(LOCAL_STORAGE_STORE_NAME) || '{}');
  }

  _renderPage = (Component, componentProps) => <Component {...componentProps} mode={this.props.mode} />;

  _renderRoute = (props) => {
    const { component: Component, hasFetchedUser, isUserAuthorized } = this.props;
    if (!isUserAuthorized) {
      const { accessToken } = this.getCredentialsDirectlyFromStore();
      const whiteLabelAuthorizationURL = this.getWhiteLabelAuthenticationURL(accessToken);
      return (
        <Redirect
          to={{
            pathname: '/error/session-expired',
            state: {
              from: props.location,
              delayRedirectUrl: whiteLabelAuthorizationURL,
            },
          }} />
      );
    }

    if (!hasFetchedUser) {
      return <PageSpinner />;
    }

    return <Fragment>{this._renderPage(Component, props)}</Fragment>;
  };

  render() {
    const routeProps = _omit(this.props, ['component', 'isUserAuthorized', 'hasFetchedUser', 'wideContainer', 'mode']);

    return <Route {...routeProps} render={this._renderRoute} />;
  }
}

const connector = connect(
  mapStateToProps,
  {
    fetchProfile: Creators.fetchProfileRequest,
    setV2Experience: AppCreators.setV2Experience,
  },
);
export default withRouter(connector(WhiteLabelRoute));
