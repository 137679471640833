import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { selectDuplicateDocumentEnabled } from 'pages/Documents/selectors';
import DocumentsTable from '../components/DocumentsTable';
import { Creators } from '../reducer';

export const mapStateToProps = state => ({
  data: state.templates.documents,
  duplicateDocumentEnabled: selectDuplicateDocumentEnabled(state),
});
export const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTable);
