import { createActions } from 'reduxsauce';

const REDUX_NAMESPACE = 'app/loggedInUserState/';
const REDUXACTIONS_STRUCTURE = {
  fetchProfileRequest: null,
  fetchProfileSuccess: ['user'],
  fetchProfileFailure: ['error'],

  signInRequest: ['email', 'password'],
  signInSuccess: ['accessToken', 'refreshToken'],
  signInFailure: ['error'],

  forgotPasswordRequest: ['email'],
  forgotPasswordSuccess: null,
  forgotPasswordFailure: ['error'],

  resetPasswordRequest: ['password', 'confirmPassword', 'token'],
  resetPasswordSuccess: null,
  resetPasswordFailure: ['error'],

  changeLanguageRequest: ['language'],
  changeLanguageSuccess: ['language'],
  changeLanguageFailure: ['error'],

  changePersonalInformationRequest: ['firstName', 'lastName', 'email'],
  changePersonalInformationSuccess: ['user'],
  changePersonalInformationFailure: ['error'],

  changePasswordRequest: ['currentPassword', 'newPassword', 'confirmPassword'],
  changePasswordSuccess: ['user'],
  changePasswordFailure: ['error'],

  setAppType: ['appType'],
  setIsUserAuthorized: ['isUserAuthorized'],

  signOut: null,
};

export const { Types, Creators } = createActions(REDUXACTIONS_STRUCTURE, { prefix: REDUX_NAMESPACE });
