import styled from 'styled-components';
import styleConstants from 'appRoot/style-constants';

const verticalPadding = 12;
const horizontalPadding = 15;

export const ComponentStyle = styled.div`
  background: ${styleConstants.colors.white};
  border: 1px solid ${styleConstants.colors.greyLight};
  border-radius: 4px;
  font-size: 1rem;
  position: relative;
  z-index: ${props => (props.isOpen ? styleConstants.zIndexHighest : 1)};
`;

export const PlaceholderStyle = styled.div`
  color: #9e9e9e;
  padding: ${p => (p.theme.small ? Math.floor(verticalPadding / 3) : verticalPadding)}px
    ${p => (p.theme.small ? Math.floor(horizontalPadding / 3) : horizontalPadding)}px;
`;

export const ValueStyle = styled.div`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${p => (p.theme.small ? Math.floor(verticalPadding / 3) : verticalPadding)}px
    ${p => (p.theme.small ? Math.floor(horizontalPadding / 3) : horizontalPadding)}px;
`;

export const DropdownStyle = styled.div`
  background-color: ${styleConstants.colors.white};
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  max-height: 240px;
  overflow: auto;
  min-width: 120px;
  padding: 10px;
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
`;

export const DropdownArrowStyle = styled.div`
  border: 5px solid ${styleConstants.colors.black};
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  top: 3px;
  position: relative;
  margin-right: 15px;
`;

export const DisplayAreaStyle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export default {
  nativeInput: {
    height: 0,
    padding: 0,
    margin: 0,
    border: 0,
    position: 'absolute',
    left: '-9999px',
  },
};
