import React from 'react';

export function Send(props) {
  return (
    <svg
      width="22"
      height="22"
      overflow="visible"
      preserveAspectRatio="none"
      version="1.2"
      viewBox="0 -2.001 50 50"
      {...props}>
      <path
        fill="#f7fafc"
        d="M50 0L0 18.6l16.1 12v7.9c0 .2.1.4.2.5 0 .1 0 .1.1.2.2.3.5.5.9.5.2 0 .4 0 .5-.1l5.7-3.5L36.6 46 50 0zm-5.5 4.2l-26.9 25-13.4-10 40.3-15zM18.1 37v-4.8l3.5 2.7-3.5 2.1zm1.1-6.6L46.4 5.2 35.5 42.6 19.2 30.4z"
        vectorEffect="non-scaling-stroke" />
    </svg>
  );
}
