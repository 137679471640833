exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-form-RadioGroup-___index__component___1KvvV {\n  padding: 0;\n  border: 0;\n}\n\n.app-components-form-RadioGroup-___index__wrapper___q00CF {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n\n.app-components-form-RadioGroup-___index__label___1NaSO {\n  margin-top: 11px;\n  cursor: pointer;\n}\n\n.app-components-form-RadioGroup-___index__title___3p9l6 {\n  display: block;\n  font-size: 0.93333rem;\n  font-size: var(--base-font-size);\n  line-height: 1.29;\n  color: #171717;\n  color: var(--c-bw-90);\n}\n\n.app-components-form-RadioGroup-___index__subtitle___VvOPt {\n  display: block;\n  font-size: 0.8rem;\n  font-weight: 400;\n  font-weight: var(--font-weight-normal);\n  font-style: italic;\n  line-height: 1.35;\n  color: #171717;\n  color: var(--c-bw-90);\n}\n", "", {"version":3,"sources":["/workspace/app/components/form/RadioGroup/index.css"],"names":[],"mappings":"AAAA;EACE,WAAU;EACV,UAAS;CACX;;AAEA;EACE,cAAa;EACb,wBAAuB;EACvB,4BAA2B;CAC7B;;AAEA;EACE,iBAAgB;EAChB,gBAAe;CACjB;;AAEA;EACE,eAAc;EACd,sBAAgC;EAAhC,iCAAgC;EAChC,kBAAiB;EACjB,eAAqB;EAArB,sBAAqB;CACvB;;AAEA;EACE,eAAc;EACd,kBAAe;EACf,iBAAsC;EAAtC,uCAAsC;EACtC,mBAAkB;EAClB,kBAAiB;EACjB,eAAqB;EAArB,sBAAqB;CACvB","file":"index.css","sourcesContent":[".component {\n  padding: 0;\n  border: 0;\n}\n\n.wrapper {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n\n.label {\n  margin-top: 11px;\n  cursor: pointer;\n}\n\n.title {\n  display: block;\n  font-size: var(--base-font-size);\n  line-height: 1.29;\n  color: var(--c-bw-90);\n}\n\n.subtitle {\n  display: block;\n  font-size: 12px;\n  font-weight: var(--font-weight-normal);\n  font-style: italic;\n  line-height: 1.35;\n  color: var(--c-bw-90);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-components-form-RadioGroup-___index__component___1KvvV",
	"wrapper": "app-components-form-RadioGroup-___index__wrapper___q00CF",
	"label": "app-components-form-RadioGroup-___index__label___1NaSO",
	"title": "app-components-form-RadioGroup-___index__title___3p9l6",
	"subtitle": "app-components-form-RadioGroup-___index__subtitle___VvOPt"
};