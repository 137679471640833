import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from 'components/Typography';
import Button, { LINK } from 'components/Button';
import cn from 'classnames';
import styles from './index.css';

const introductionStepsWithSigning = [
  {
    key: 'questionnaire.introduction.steps.one',
    description: <FormattedMessage id="questionnaire.introduction.stepsWithSigning.one" />,
    icon: <img className={styles.stepIcon} src="/assets/images/QuestionnaireIntroduction-Questionnaire.svg" alt="" />,
  },
  {
    key: 'questionnaire.introduction.steps.two',
    description: <FormattedMessage id="questionnaire.introduction.stepsWithSigning.two" />,
    icon: <img className={styles.stepIcon} src="/assets/images/QuestionnaireIntroduction-DocumentEmail.svg" alt="" />,
  },
  {
    key: 'questionnaire.introduction.steps.three',
    description: <FormattedMessage id="questionnaire.introduction.stepsWithSigning.three" />,
    icon: <img className={styles.stepIcon} src="/assets/images/QuestionnaireIntroduction-DocumentWithTick.svg" alt="" />,
  },
];

const introductionStepsWithoutSigning = [
  {
    key: 'questionnaire.introduction.steps.one',
    description: <FormattedMessage id="questionnaire.introduction.stepsWithoutSigning.one" />,
    icon: <img className={styles.stepIcon} src="/assets/images/QuestionnaireIntroduction-Questionnaire.svg" alt="" />,
  },
  {
    key: 'questionnaire.introduction.steps.two',
    description: <FormattedMessage id="questionnaire.introduction.stepsWithoutSigning.two" />,
    icon: <img className={styles.stepIcon} src="/assets/images/QuestionnaireIntroduction-DocumentWithTick.svg" alt="" />,
  },
  {
    key: 'questionnaire.introduction.steps.three',
    description: <FormattedMessage id="questionnaire.introduction.stepsWithoutSigning.three" />,
    icon: <img className={styles.stepIcon} src="/assets/images/QuestionnaireIntroduction-DocumentEmail.svg" alt="" />,
  },
];
export default function DefaultIntroduction({ documentName, signingEnabled }) {
  const introductionSteps = signingEnabled ? introductionStepsWithSigning : introductionStepsWithoutSigning;
  const [hasFcWidget, setHasFcWidget] = React.useState(false);

  const chatWidgetToggleHandler = () => {
    if (window.fcWidget) {
      window.fcWidget.open();
    }
  };

  React.useLayoutEffect(() => {
    if (typeof window.fcWidget === 'function') {
      setHasFcWidget(true);
    }
  }, []);

  return (
    <div>
      <Typography variant="h2">
        <FormattedMessage id="questionnaire.introduction.title" />
      </Typography>
      <Typography variant="bodySmall" className={styles.mediumSpacing}>
        <FormattedMessage
          id="questionnaire.introduction.primary_description"
          values={{ documentName: <strong>{documentName}</strong> }} />
      </Typography>
      <div className={cn(styles.steps, styles.largeSpacing)}>
        {introductionSteps
          .map((step, index) => (
            <div className={styles.step} key={step.key}>
              {step.icon}
              <Typography variant="bodySmall">
                {index + 1}
                .
                {' '}
                {step.description}
              </Typography>
            </div>
          ))}
      </div>
      <Typography variant="bodySmall">
        <FormattedMessage id="questionnaire.introduction.secondary_description" />
      </Typography>
      {hasFcWidget && (
      <div className={cn(styles.helpTextWrapper, styles.largeSpacing)}>
        <Typography variant="bodySmall">
          <FormattedMessage id="questionnaire.introduction.help_description" />
        </Typography>

        <Button
          className={styles.chatToggleButton}
          onClick={chatWidgetToggleHandler}
          testId="DefaultIntroduction-Chat-Toggle"
          variant={LINK}>
          <Typography variant="buttonText">
            <FormattedMessage id="questionnaire.introduction.help_chat_toggle_button_label" />
          </Typography>
        </Button>
      </div>
      )}
    </div>
  );
}

DefaultIntroduction.propTypes = { documentName: PropTypes.string.isRequired };
