exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "div.app-components-form-TextArea-___index__wrapper___19KGr {\n  padding: 0;\n}\n\ntextarea.app-components-form-TextArea-___index__textarea___JHZQx {\n  resize: vertical;\n}\n", "", {"version":3,"sources":["/workspace/app/components/form/TextArea/index.css"],"names":[],"mappings":"AAAA;EACE,WAAU;CACZ;;AAEA;EACE,iBAAgB;CAClB","file":"index.css","sourcesContent":["div.wrapper {\n  padding: 0;\n}\n\ntextarea.textarea {\n  resize: vertical;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "app-components-form-TextArea-___index__wrapper___19KGr",
	"textarea": "app-components-form-TextArea-___index__textarea___JHZQx"
};