import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import queryString from 'qs';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import withPagination from 'hocs/withPagination';
import RecordsTable, { TableCell } from 'shared/Components/RecordsTable';
import searchParams from 'shared/constants/queryParams/search';
import propTypes from 'utils/proptypes';
import { Creators as userListActions } from '../actions';
import { UserRow } from './UserRow';

export class UserListComponent extends PureComponent {
  static propTypes = {
    fetchUsers: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(propTypes.user).isRequired,
    currentUser: propTypes.currentUser.isRequired,
    usersCount: PropTypes.number.isRequired,
    getCurrentPage: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
  }

  componentDidMount() {
    this.fetchUsers();
  }

  componentDidUpdate(prevProps) {
    const hasSearchChanged = prevProps.location.search !== this.props.location.search;

    if (hasSearchChanged) {
      this.fetchUsers();
    }
  }

  fetchUsers = () => {
    const { fetchUsers, getCurrentPage } = this.props;
    const params = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });
    fetchUsers({
      [searchParams.page]: getCurrentPage(),
      q: { [searchParams.fullName]: params[searchParams.fullName] },
    });
  };

  onUserRowClick = (userId) => {
    this.props.history.push(`/members/${userId}/edit`);
  };

  renderHeaders() {
    return [
      <TableCell key="document">
        <FormattedMessage id="panel.users.index.user" />
      </TableCell>,
      <TableCell key="created_at">
        <FormattedMessage id="panel.users.index.role" />
      </TableCell>,
    ];
  }

  render() {
    const { users, currentUser, usersCount } = this.props;
    return (
      <RecordsTable
        data={users}
        testId="UserList-table"
        total={usersCount}
        columnHeaders={this.renderHeaders()}
        EmptyComponent={<div />}
        renderRow={user => (
          <UserRow
            key={user.id}
            user={user}
            currentUser={currentUser}
            onUserRowClick={this.onUserRowClick} />
        )} />
    );
  }
}

export const mapStateToProps = state => ({
  currentUser: state.loggedInUserState.user,
  users: state.usersList.users,
  usersCount: state.usersList.usersCount,
});

export const mapDispatchToProps = { fetchUsers: userListActions.fetchUsersRequest };

export default compose(
  withRouter,
  withPagination,
  connect(mapStateToProps, mapDispatchToProps),
)(UserListComponent);
