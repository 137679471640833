exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-NavigationDots-___index__component___2UOoh {\n  display: flex;\n  flex-direction: row;\n}\n\n  .app-components-NavigationDots-___index__component___2UOoh > * + * {\n    margin-left: 8px;\n  }\n\n.app-components-NavigationDots-___index__dot___1VC9t {\n  width: 9px;\n  height: 9px;\n  border-radius: 50%;\n  background: #f3f3f3;\n  background: var(--c-bw-05);\n  cursor: pointer;\n  outline: none;\n}\n\n.app-components-NavigationDots-___index__dotActive___ViOxn {\n  background: #2d866e;\n  background: var(--c-brand);\n}\n", "", {"version":3,"sources":["/workspace/app/components/NavigationDots/index.css"],"names":[],"mappings":"AAAA;EACE,cAAa;EACb,oBAAmB;CAKrB;;EAHE;IACE,iBAAgB;GAClB;;AAGF;EACE,WAAU;EACV,YAAW;EACX,mBAAkB;EAClB,oBAA0B;EAA1B,2BAA0B;EAC1B,gBAAe;EACf,cAAa;CACf;;AAEA;EACE,oBAA0B;EAA1B,2BAA0B;CAC5B","file":"index.css","sourcesContent":[".component {\n  display: flex;\n  flex-direction: row;\n\n  > * + * {\n    margin-left: 8px;\n  }\n}\n\n.dot {\n  width: 9px;\n  height: 9px;\n  border-radius: 50%;\n  background: var(--c-bw-05);\n  cursor: pointer;\n  outline: none;\n}\n\n.dotActive {\n  background: var(--c-brand);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-components-NavigationDots-___index__component___2UOoh",
	"dot": "app-components-NavigationDots-___index__dot___1VC9t",
	"dotActive": "app-components-NavigationDots-___index__dotActive___ViOxn"
};