import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import PreviewLink from './PreviewLink';
import { HeaderContainer, HeaderTitle } from '../styled-components';
import ToggleButton from '../../../pages/Questionnaire/components/ToggleButton';

class PreviewHeader extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    intl: intlShape.isRequired,
    toggleModal: PropTypes.func.isRequired,
    previewMode: PropTypes.bool.isRequired,
    isPreviewVisible: PropTypes.bool.isRequired,
    onTogglePreview: PropTypes.func.isRequired,
  };

  render() {
    const { intl, isPreviewVisible, onTogglePreview, className, toggleModal, previewMode } = this.props;
    return (
      <HeaderContainer isVisible={isPreviewVisible}>
        <ToggleButton
          onToggle={onTogglePreview}
          checked={isPreviewVisible}
          caption={intl.formatMessage({ id: 'questionnaire.preview.heading' })} />
        <HeaderTitle hideOnMd textCenter>
          <FormattedMessage id="questionnaire.preview.heading" />
        </HeaderTitle>
        {previewMode && <PreviewLink className={className} toggleModal={toggleModal} />}
      </HeaderContainer>
    );
  }
}
export default injectIntl(PreviewHeader);
