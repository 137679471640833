import { createActions } from 'reduxsauce';

export const REDUX_NAMESPACE = 'app/exportFile';

export const REDUXACTIONS_STRUCTURE = {
  exportDocxRequest: ['fileName'],
  exportDocxSuccess: ['file'],
  exportDocxFailure: ['error'],
  exportPdfRequest: ['fileName'],
  exportPdfSuccess: ['file'],
  exportPdfFailure: ['error'],
};

export const { Types, Creators } = createActions(REDUXACTIONS_STRUCTURE, { prefix: REDUX_NAMESPACE });
