import React from 'react';
import MaterialGrid from '@material-ui/core/Grid';

const GridItem = ({ children, ...props }) => (
  <MaterialGrid item {...props}>
    {children}
  </MaterialGrid>
);

export default GridItem;
