import React from 'react';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/Container';
import GridItem from 'components/Grid/Item';
import cn from 'classnames';
import styles from './index.css';
import mutationObserver from '../../utils/mutationObserver';

class ModalFooter extends React.PureComponent {
  static propTypes = { children: PropTypes.node };

  state = { modalIsScrollable: false };

  mutationObserver = null;

  componentDidMount() {
    if (this.modalEl) {
      this.mutationObserver = mutationObserver(this.updateScrollableState);
      this.mutationObserver.observe(this.modalEl, {
        characterData: false,
        childList: true,
        subtree: true,
        attributeOldValue: false,
        characterDataOldValue: false,
      });
    }
  }

  componentWillUnmount() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  get modalEl() {
    return document.getElementById('modal');
  }

  isScrollable = (el = this.modalEl) => (el ? el.scrollHeight > el.clientHeight : false);

  updateScrollableState = () => this.setState({ modalIsScrollable: this.isScrollable() });

  render() {
    const { children } = this.props;
    const { modalIsScrollable } = this.state;

    return (
      <GridContainer
        className={cn(styles.footer, { [styles.footerShadow]: modalIsScrollable })}
        spacing={16}
        justify="flex-end">
        {React.Children.map(children, child => (
          <GridItem>{child}</GridItem>
        ))}
      </GridContainer>
    );
  }
}

export default ModalFooter;
