import { ASSET_STORE_URL } from 'appRoot/constants';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GENERIC_ACCESS, WHITE_LABEL } from 'shared/constants/questionnaireModes';
import styled from 'styled-components';
import { selectWhiteLabelCompanyUrl } from 'containers/App/selectors';


const Logo = styled.img`
  height: ${props => (props.large ? '100%' : '36px')}
`;

const Separator = styled.div`
  border-right: 2px solid ${p => p.theme.border.normal};
  margin: 0 2.25vw;
  align-self: stretch;
`;

class WhiteLabelLogo extends Component {
  static propTypes = {
    questionnaireMode: PropTypes.string.isRequired,
    v2Experience: PropTypes.bool,
  };

  render() {
    const { questionnaireMode, companyId, companyName, companyUrl, isWhiteLabelCompany, large, v2Experience } = this.props;
    const logoUrl = `${ASSET_STORE_URL}/company/${companyId}/wl-logo.png`;
    const isWhiteLabelWithGenAccess = questionnaireMode === GENERIC_ACCESS && isWhiteLabelCompany;
    const isExternal = window.location.pathname.startsWith('/external');

    if (v2Experience) return null;

    if (questionnaireMode === WHITE_LABEL || isWhiteLabelWithGenAccess || isExternal) {
      return (
        <React.Fragment>
          <a href={companyUrl}>
            <Logo
              alt={companyName}
              src={logoUrl}
              large={large} />
          </a>
          <Separator />
        </React.Fragment>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    questionnaireMode: state.questionnaire.mode,
    companyId: state.questionnaire.company.id,
    companyName: state.questionnaire.company.name,
    // companyUrl: state.questionnaire.company.white_label_redirect_url,
    companyUrl: selectWhiteLabelCompanyUrl(state),
    v2Experience: state.appState.v2Experience,
  };
}
export default connect(mapStateToProps)(WhiteLabelLogo);
