import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import UsersIcon from '@material-ui/icons/People';
import ReactRouterPropTypes from 'react-router-prop-types';
import queryString from 'qs';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Search from 'shared/Components/Search';
import searchParams from 'shared/constants/queryParams/search';
import NotificationSystem from 'shared/NotificationSystem';
import Modal from 'components/Modal';
import { isLoading as isLoadingUtil } from 'utils/statusSelectors';
import UsersInviteForm from './UserInviteForm';
import styles from './UserListPageHeader.css';

export class UserListPageHeader extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
  };

  state = { isOpen: false };

  onSearch = (value) => {
    const { history, location } = this.props;
    const params = queryString.parse(location.search, { ignoreQueryPrefix: true });
    history.push({
      search: queryString.stringify({
        ...params,
        [searchParams.fullName]: value,
        page: 1,
      }),
    });
  };

  toggleModal = () => this.setState(state => ({ isOpen: !state.isOpen }));

  render() {
    const { isLoading } = this.props;
    return (
      <Grid spacing={0} container className={styles.component}>
        <Grid item xs={12} sm={5}>
          <div className={styles.content}>
            <UsersIcon className={styles.icon} />
            <div>
              <div className={styles.title}>
                <FormattedMessage id="users_list.title" />
              </div>
              <div className={styles.subtitle}>
                <FormattedMessage id="users_list.subTitle" />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Search onSearch={this.onSearch} isLoading={isLoading} />
        </Grid>
        <Grid container item xs={12} sm={2} alignItems="center" justify="flex-end">
          <Button onClick={this.toggleModal} glyph={AddIcon} testId="UserListPageHeader-invite">
            <FormattedMessage id="users_list.invite" />
          </Button>
        </Grid>
        <Modal toggleModal={this.toggleModal} size="medium" open={this.state.isOpen} modalTitle={<FormattedMessage id="users_list.new_invitation" />}>
          <UsersInviteForm toggleModal={this.toggleModal} />
        </Modal>
        <NotificationSystem />
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  folders: state.templates.folders,
  isLoading: isLoadingUtil(state, 'fetchUsers'),
});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(UserListPageHeader);
