import React from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import FieldRow from 'components/form/FieldRow';
import InputPassword from 'components/form/InputPassword';
import Typography, { VARIANTS } from 'components/Typography';
import { isPasswordStrong } from 'utils/password';
import { isLoading as isLoadingSelector } from 'utils/statusSelectors';
import Button from 'components/Button';
import { Creators } from 'ducks/loggedInUser/actions';

import styles from './styles.css';

export const ResetPassword = ({ handleSubmit, isLoading }) => (
  <div className={styles.component}>
    <form className={styles.form} onSubmit={handleSubmit} noValidate>
      <Typography variant={VARIANTS.H2}>
        <FormattedMessage id="reset_password.title" />
      </Typography>
      <FieldRow className={styles.fieldSpacing}>
        <Typography variant={VARIANTS.LABEL} htmlFor="password" className={styles.label}>
          <FormattedMessage id="reset_password.labels.password" />
        </Typography>
        <Field name="password">
          {({ field, form }) => (
            <InputPassword
              autocomplete="new-password"
              errorMessage={form.errors[field.name]}
              testId="ResetPassword-passwordField"
              id="password"
              required
              showStrengthIndicator
              {...field} />
          )}
        </Field>
      </FieldRow>

      <FieldRow>
        <Typography variant={VARIANTS.LABEL} htmlFor="confirmPassword" className={styles.label}>
          <FormattedMessage id="reset_password.labels.confirm_password" />
        </Typography>

        <Field name="confirmPassword">
          {({ field, form }) => (
            <InputPassword
              autocomplete="new-password"
              errorMessage={form.errors[field.name]}
              required
              id="confirmPassword"
              testId="ResetPassword-confirmPasswordField"
              {...field} />
          )}
        </Field>
      </FieldRow>
      <div className={styles.buttonGroup}>
        <Link data-test-id="ResetPassword-signInLink" to="/auth/sign-in" className={styles.link}>
          <Typography variant={VARIANTS.HELP_TEXT}>
            <FormattedMessage id="reset_password.labels.already_logged" />
          </Typography>
        </Link>
        <Button testId="ResetPassword-submit" isLoading={isLoading} type="submit">
          <FormattedMessage id="reset_password.labels.submit" />
        </Button>
      </div>
    </form>
  </div>
);

ResetPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    password: PropTypes.string,
    confirm_password: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};

export const formConfig = {
  mapPropsToValues: () => ({
    password: '',
    confirmPassword: '',
  }),
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = <FormattedMessage id="reset_password.errors.must_be_the_same" />;
    }
    if (!isPasswordStrong(values.password)) {
      errors.password = <FormattedMessage id="reset_password.errors.password_not_strong_enough" />;
    }
    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    const { token } = props.match.params;
    const { password, confirmPassword } = values;
    props.resetPassword(password, confirmPassword, token);
    setSubmitting(false);
  },
};
export const mapStateToProps = state => ({ isLoading: isLoadingSelector(state, 'appLoggedInUserStateResetPassword') });
export const mapDispatchToProps = { resetPassword: Creators.resetPasswordRequest };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik(formConfig),
)(ResetPassword);
