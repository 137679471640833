import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';

export const INITIAL_STATE = {
  maxQuestionsLength: 0,
  remainingQuestionsLength: 0,
};

export const { Types, Creators } = createActions({
  setProgressBar: ['html'], // saga
  setRemainingQuestionsLength: ['num'],
  setMaxQuestionsLength: ['num'],
});

const setRemainingQuestionsLength = (state, { num }) => update(state, { remainingQuestionsLength: { $set: num } });
const setMaxQuestionsLength = (state, { num }) => update(state, { maxQuestionsLength: { $set: num } });

export default createReducer(INITIAL_STATE, {
  [Types.SET_REMAINING_QUESTIONS_LENGTH]: setRemainingQuestionsLength,
  [Types.SET_MAX_QUESTIONS_LENGTH]: setMaxQuestionsLength,
});
