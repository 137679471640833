import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { UnmountClosed } from 'react-collapse';

import { optionsSelector } from 'Visualization/Editor-v2/Questions/QuestionsRedux';
import { Creators as LogicsManagerActions } from 'Visualization/Editor-v2/LogicsManager/LogicsManagerRedux';
import { logicTypes } from 'appRoot/constants';
import proptypes from 'utils/proptypes';
import RangeHelper from 'Visualization/helpers/rangeHelper';
import Tooltip from 'components/Tooltip';
import Button, { LINK } from 'components/Button';
import InputCheckbox from 'components/form/InputCheckbox';
import InputRadio from 'components/form/InputRadio';

import styles from './styles.css';

const icons = {
  [logicTypes.CHOICE_SHOW_IF]: '/assets/icons/dot-circle-o.svg',
  [logicTypes.INPUT_REPLACE_TYPE]: '/assets/icons/text-field.svg',
  [logicTypes.ANCHOR_TYPE]: '/assets/icons/shuffle.svg',
};

const inputs = {
  radio: InputRadio,
  checkbox: InputCheckbox,
};

class Question extends Component {
  static propTypes = {
    openLogicsManager: PropTypes.func.isRequired,
    nestingLevel: PropTypes.number.isRequired,
    flowItem: proptypes.flowItem.isRequired,
    options: proptypes.optionsScheme.isRequired,
    questions: PropTypes.arrayOf(proptypes.questionScheme),
  };

  state = {
    isOpened: false,
    showTooltip: false,
  };

  questionRef = React.createRef();

  componentDidMount() {
    ReactTooltip.hide();
    ReactTooltip.rebuild();
    this.tooltipBuilder();
  }

  tooltipBuilder = () => {
    this.questionClone = this.questionRef.current.cloneNode(true);

    this.questionClone.style.display = 'inline-block';
    this.questionClone.style.height = '0';
    this.questionClone.style.visibility = 'hidden';
    this.questionClone.style.overflow = 'hidden';
    this.questionClone.style.color = 'transparent';

    document.body.appendChild(this.questionClone);
    if (this.questionRef.current.offsetWidth < this.questionClone.offsetWidth + 10) { // take 10px padding into account
      this.setState({ showTooltip: true });
    }
    this.questionClone.remove();
  }

  getQuestionOptions = () => this.props.options.filter(q => q.question_id === this.props.flowItem.questionId);

  getQuestion = () => this.props.questions.find(q => q.id === this.props.flowItem.questionId);

  openQuestionManager = () => {
    const questionTagNode = this.findFirstFlowItemNode();
    const question = this.getQuestion();
    new RangeHelper().selectElementContents(questionTagNode);

    this.props.openLogicsManager(questionTagNode.getAttribute('data-logictype'), {
      initialValidations: false,
      question,
    });
  };

  findFirstFlowItemNode = () => {
    const { optionId, type } = this.props.flowItem;
    // type showif/replace
    return $(`[data-${type}=${optionId}]`)[0];
  };

  isExpandable = logicType => logicType === logicTypes.CHOICE_SHOW_IF;

  renderIcon = logicType => <img src={icons[logicType]} className={styles.card__img} alt=" " />;

  renderInput = (questionId) => {
    const { kind } = this.props.questions.find(q => q.id === questionId);
    const RenderedField = inputs[kind];
    return <RenderedField name={questionId} disabled testId="Question-renderedInput" /> || null;
  };

  toggleOpen = () => {
    this.setState(({ isOpened }) => ({ isOpened: !isOpened }));
  };

  renderButton = (ref) => {
    const { flowItem } = this.props;
    return (
      <Button className={styles.card__trigger} variant={LINK} onClick={this.openQuestionManager} testId="Flow-questionButton">
        <span ref={ref}>{`${flowItem.questionIndex}. ${flowItem.questionName}`}</span>
      </Button>
    );
  };

  /* eslint-disable */
  render() {
    const { nestingLevel, flowItem } = this.props;
    const { isOpened } = this.state;
    const options = this.getQuestionOptions();

    return (
      <div
        id={`${flowItem.questionIndex}_${flowItem.questionId}`}
        className={styles.question}
        style={{ paddingLeft: `${nestingLevel * 12 }px` }}
      >
        <div className={styles.card}>
          <div className={styles.card__icon}>
            {this.renderIcon(flowItem.logictype)}
          </div>
          <div className={styles.card__name}>
            {this.state.showTooltip
              ? (
              <Tooltip title={flowItem.questionName} enterDelay={500} leaveDelay={100}>
                {this.renderButton()}
              </Tooltip>
              ) : this.renderButton(this.questionRef)
            }
          </div>
          {this.isExpandable(flowItem.logictype) &&
            <div className={styles.card__toggler}>
              <Button testId="Question-toggleExpandable" className={styles.toggler} variant={LINK} onClick={this.toggleOpen}>
                <div className={styles.triangle} />
              </Button>
            </div>}
        </div>
        {this.isExpandable(flowItem.logictype) &&
          <UnmountClosed isOpened={isOpened}>
            <ul className={styles.options} nestingLevel={nestingLevel}>
              {options.map((option, index) => (
                <li className={styles.option} id={`${flowItem.questionIndex}_${option.id}`} key={option.id}>
                  <div className={styles.option__input}>
                    {this.renderInput(flowItem.questionId)}
                  </div>
                  <div className={styles.option__label}>
                    {option.name || 'client value'}
                  </div>
                </li>
              ))}
            </ul>
          </UnmountClosed>
        }
      </div>
    );
    /* eslint-disable */
  }
}

const mapStateToProps = state => ({
  questions: state.questions.questions,
  options: optionsSelector(state),
});

const mapDispatchToProps = {
  openLogicsManager: LogicsManagerActions.openLogicsManager,
};

export { Question as QuestionRaw };

export default connect(mapStateToProps, mapDispatchToProps)(Question);
