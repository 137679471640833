const NODE_BLOCKS = ['P', 'DIV', 'OL', 'UL', 'TABLE', 'IMG', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'HR'];
const QUESTION_CLASS = '[data-question]';
const EXTENDABLE_NODES = [...NODE_BLOCKS, 'QUESTION'];
export const EDITOR_CONTENT_CLASS = 'mce-content-body'; // class where editor content is stored
export { NODE_BLOCKS };
export { QUESTION_CLASS };
export { EXTENDABLE_NODES };

// http://astronautweb.co/snippet/font-awesome/
export const ICONS_URLS = {
  inputReplaceType: '/assets/icons/text-field.svg',
  anchorType: '/assets/icons/shuffle.svg',
  choiceShowIfType: '/assets/icons/dot-circle-o.svg',
};

export const QUESTION_TYPES = {
  choiceShowIfType: 'showif',
  anchorType: 'showif',
  inputReplaceType: 'replace',
};

export const CONSTRAIN_TYPE = {
  NONE: 'NONE',
  DATE: 'DATE',
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  UINT: 'UINT',
  TIME: 'TIME',
  EMAIL: 'EMAIL',
};

export const CONSTRAIN_KEY = 'constrain';
