import React from 'react';
import PropTypes from 'prop-types';
import StyledInformations from 'shared/TopToolbar/StyledInformations';
import FolderIcon from '@material-ui/icons/Folder';
import { FormattedMessage } from 'react-intl';

const Informations = ({ templateFolder, templateVersion, templateStatus }) => (
  <StyledInformations>
    {templateFolder && (
      <div className="folder">
        <FolderIcon />
        <span className="folder__text" title={templateFolder}>
          {templateFolder}
        </span>
      </div>
    )}
    <div className="version">
      <FormattedMessage id="visualization.top_toolbar.version.title" values={{ val: templateVersion }} />
    </div>
    <div className="status">
      <FormattedMessage id="visualization.top_toolbar.status" values={{ val: templateStatus }} />
    </div>
  </StyledInformations>
);

Informations.propTypes = {
  templateVersion: PropTypes.number.isRequired,
  templateStatus: PropTypes.string.isRequired,
  templateFolder: PropTypes.string.isRequired,
};
export default Informations;
