import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from 'components/Tooltip';
import FolderView from 'components/FolderView';
import SubmitButton from './SubmitButton';
import { StyledTitle, CloseIcon, ArrowIcon } from './styled-components';

class MoveToFolderDialog extends Component {
  static propTypes = {
    currentFolder: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      root: PropTypes.bool,
    }).isRequired,
    currentViewFolder: PropTypes.shape({ id: PropTypes.string }).isRequired,
    goToFolder: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    moveToFolder: PropTypes.func.isRequired,
    folders: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })),
  };

  goToParent = () => {
    const { currentFolder: { parents }, goToFolder } = this.props;
    const parent = parents[parents.length - 1] || {};
    goToFolder(parent.id || parent.folderSlug);
  };

  isMovingDisabled = () => {
    const { currentFolder, currentViewFolder } = this.props;
    const currentViewFolderId = currentViewFolder.id || currentViewFolder.folderSlug;
    const currentFolderId = currentFolder.id || currentFolder.folderSlug;

    if (
      (currentFolder.root && !currentViewFolderId)
      || (currentFolderId === currentViewFolderId)
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { isOpen, onClose, moveToFolder, currentFolder, folders, goToFolder } = this.props;
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <StyledTitle disableTypography>
          <Fragment>
            {
              currentFolder.root
                || (
                <Tooltip title={<FormattedMessage id="api.panel.questionnaire_templates.back" />}>
                  <ArrowIcon
                    fontSize="small"
                    onClick={this.goToParent}
                    data-test-id="MoveTo-arrowButton" />
                </Tooltip>
                )
            }
            <span data-test-id="MoveTo-folderName">
              {currentFolder.root
                ? <FormattedMessage id={currentFolder.name} />
                : currentFolder.name
              }
            </span>
            <CloseIcon fontSize="small" onClick={onClose} />
          </Fragment>
        </StyledTitle>
        <div>
          <FolderView folders={folders} selectFolder={goToFolder} />
        </div>
        <DialogActions>
          <SubmitButton onClick={moveToFolder} disabled={this.isMovingDisabled()} />
        </DialogActions>
      </Dialog>
    );
  }
}

export default MoveToFolderDialog;
