import assert from 'assert';

const DEFAULT_TRACKING_ID = 'no-tracking-id';

class GoogleAnalyticsService {
  constructor({ gtag, isTrackingEnabled = true, logger, trackingId = DEFAULT_TRACKING_ID } = {}) {
    assert.ok(gtag, 'gtag is required');
    assert.ok(logger, 'logger is required');

    this._gtag = gtag;
    this._logger = logger;
    this._trackingId = trackingId;
    this._isTrackingEnabled = Boolean(isTrackingEnabled);

    this._logger.info('[GA] has initialised');

    // This is for the rare case when we are in a production like
    // environment (when logging is disabled) and a tracking ID does
    // not exist. This enables logger to log this potential error and
    // then disables logger it again.
    if (
      (!trackingId || trackingId === DEFAULT_TRACKING_ID)
      && !logger.isEnabled()
    ) {
      logger.enable();
      this._logger.info('[GA] Warning: noTrackingId has been provided');
      logger.disable();
    }
  }

  /**
   * Static event to intialise a tracking ID before class instantiation
   *
   * @param {string} [trackingId] - Google analytics trackingId
   * @returns {Function} gtag - Google analytics gtag tracking function
   */
  static init(trackingId) {
    if (trackingId && !window.dataLayer) {
      const script = document.createElement('script');
      const head = document.getElementsByTagName('head')[0];
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
      head.appendChild(script);
    }

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', trackingId);

    return gtag;
  }

  /**
   * Disable event tracking
   *
   * @returns {void}
   */
  disableTracking() {
    this._logger.info('[GA] disableTracking');
    this._isTrackingEnabled = false;
  }

  /**
   * Enable event tracking
   *
   * @returns {void}
   */
  enableTracking() {
    this._logger.info('[GA] enableTracking');
    this._isTrackingEnabled = true;
  }

  /**
   * Get status of analytics tracking
   *
   * @returns {boolean}
   */
  isEnabled() {
    this._logger.info('[GA] isEnabled:', this._isTrackingEnabled);
    return this._isTrackingEnabled;
  }

  /**
   * Send a pageview event
   *
   * @param {Object} obj - An object
   * @param {string} obj.path - Relative page path
   * @param {string} [obj.title] - Page title
   * @returns {Promise<void>}
   */
  sendPageview({ path, title } = {}) {
    assert.ok(path, 'path<string> is required');
    assert.ok(path[0] === '/', 'path<string> is a relative path and should begin with "/"');

    const payload = {
      page_title: title,
      page_path: path,
    };

    this._logger.info('[GA] sendPageview:', {
      isTrackingEnabled: this._isTrackingEnabled,
      trackingId: this._trackingId,
      payload,
    });

    // Don't log if tracking is disabled
    if (!this._isTrackingEnabled) {
      return;
    }

    this._gtag('config', this._trackingId, payload);
  }

  /**
   * Send a pageview event
   *
   * @param {Object} obj - An object.
   * @param {string} obj.action - The type of interaction (e.g. 'play')
   * @param {string} obj.category - Typically the object that was interacted with (e.g. 'Video')
   * @param {string} [obj.label] - Useful for categorizing events (e.g. 'Fall Campaign')
   * @param {number} [obj.value] - A numeric value associated with the event (e.g. 42)
   * @param {boolean} [obj.nonInteraction] - Non interaction flag (https://support.google.com/analytics/answer/1033068#NonInteractionEvents)
   * @returns {Promise<void>}
   */
  sendEvent({ action, category, label, value, nonInteraction } = {}) {
    assert.ok(action, 'action<string> is required');
    assert.ok(category, 'category<string> is required');

    const payload = {
      event_category: category,
      event_label: label,
      value,
    };

    if (nonInteraction) {
      payload.non_interaction = true;
    }

    this._logger.info('[GA] send event:', {
      action,
      isTrackingEnabled: this._isTrackingEnabled,
      payload,
    });

    // Don't log if tracking is disabled
    if (!this._isTrackingEnabled) {
      return;
    }
    this._gtag('event', action, payload);
  }
}

export default GoogleAnalyticsService;
