import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ICONS_URLS } from 'Visualization/helpers/constants';
import Portal from 'shared/Portal';
import CommandButton from './CommandButton';

const StyledToolbar = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  background-color: ${props => props.theme.gray};
  .triangle {
    position: relative;
  }
  .triangle::after {
    content: '';
    position: absolute;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${props => props.theme.gray}
      transparent;
    top: -19px;
    left: calc(50% - 10px);
  }
  .lm-toolbar-container {
    display: flex;
  }
`;

export default class Toolbar extends Component {
  static propTypes = {
    openQuestionManager: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
  };

  onMouseDown = (e) => {
    e.preventDefault(); // keeps toolbar from collapsing
  };

  render() {
    const { onOpen } = this.props;

    /* eslint-disable */
    return (
      <Portal
        refFn={portalObject => (this.portalObject = portalObject)}
        onOpen={() => onOpen(this.portalObject)}>
        <StyledToolbar onMouseDown={this.onMouseDown}>
          <div className="triangle">
            <div className="lm-toolbar-container">
              <CommandButton
                type="inputReplaceType"
                openQuestionManager={this.props.openQuestionManager}
                icon={ICONS_URLS.inputReplaceType}
                testId="ToolbarContainer-inputButton" />
              <CommandButton
                openQuestionManager={this.props.openQuestionManager}
                type="choiceShowIfType"
                icon={ICONS_URLS.choiceShowIfType} 
                testId="ToolbarContainer-choiceButton" />
              <CommandButton
                type="anchorType"
                openQuestionManager={this.props.openQuestionManager}
                icon={ICONS_URLS.anchorType} 
                testId="ToolbarContainer-showIfButton" />
            </div>
          </div>
        </StyledToolbar>
      </Portal>
    );
    /* eslint-enable */
  }
}
