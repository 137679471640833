import rangy from 'rangy';
import { put, takeLatest, select } from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import { questions as questionTemplate } from 'Visualization/helpers/templates';
import { INPUT_REPLACE_TYPE } from 'shared/constants/logicTypes';
import { Types, Creators as LogicManagerActions } from './LogicsManagerRedux';
import { Creators as QuestionsActions } from '../Questions/QuestionsRedux';
import checkIfSelectionIsInsideQuestion from './LogicsTypes/TriggersFn/utils/checkIfSelectionIsInsideQuestion';
import checkIfAnyQuestionsWithinSelection from './LogicsTypes/TriggersFn/utils/checkIfAnyQuestionsWithinSelection';

function* openLogicsManager({ kind, options }) {
  const { question } = options;
  let { initialValidations } = options;

  // we are resetting this reducer to be sure we do not have old data
  // that can corrupt our logic manager, for example:
  // before we could have enabled trigger fn and we could change logicsManagerKind
  // which was causing bugs
  yield put(LogicManagerActions.reset());
  if (
    ![true, false].includes(initialValidations) // if initialValidations is not set
    && kind === INPUT_REPLACE_TYPE
    && rangy.getSelection().getAllRanges().length
    && !rangy.getSelection().isCollapsed
  ) {
    // if we have ranges
    // and we have selected more than 1 char (selection is not collapsed)
    // perform validations before opening manager
    initialValidations = true;
  }

  if (initialValidations) {
    try {
      // TODO nodeshelper is throwing errors, can we fix nodehelper ?
      if (
        checkIfSelectionIsInsideQuestion(INPUT_REPLACE_TYPE)
        || (kind === INPUT_REPLACE_TYPE && checkIfAnyQuestionsWithinSelection())
      ) {
        yield put(LogicManagerActions.setSelectionErrorMessage('.errors.no_additional_logic_to_text_that_will_be_replaced'));
        return;
      }
    } catch (e) {
      yield put(LogicManagerActions.openLogicsManagerFailure(e));
    }
  }

  const initialQuestion = yield* setInitialQuestion(kind, question);
  yield put(batchActions([
    LogicManagerActions.setLogicsManagerKind(kind),
    QuestionsActions.setCurrentQuestion(initialQuestion),
  ]));
}

function* setInitialQuestion(type, question = undefined) {
  let initialQuestion = question;
  if (!question) {
    const templateVersion = yield select(state => state.editor.templateVersion.id);
    initialQuestion = questionTemplate(templateVersion)[type];
  }
  return initialQuestion;
}

export default [takeLatest(Types.OPEN_LOGICS_MANAGER, openLogicsManager)];
