import React from 'react';
import PropTypes from 'prop-types';
import CheckCircle from '@material-ui/icons/CheckCircle';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty';
import Warning from '@material-ui/icons/Warning';
import { injectIntl, intlShape } from 'react-intl';

import { FAILED, SAVED, SAVING } from 'shared/constants/saveStatus';
import Notification from './Notification';

const notificationDictionary = {
  [SAVING]: intl => (
    <Notification
      testId="Notifications-saving"
      text={intl.formatMessage({ id: 'shared_components.save_notification.saving' })}
      icon={<HourglassEmpty />} />
  ),
  [SAVED]: intl => (
    <Notification
      testId="Notifications-saved"
      text={intl.formatMessage({ id: 'shared_components.save_notification.saved' })}
      icon={<CheckCircle />} />
  ),
  [FAILED]: intl => (
    <Notification
      testId="Notifications-failed"
      text={intl.formatMessage({ id: 'shared_components.save_notification.failed' })}
      icon={<Warning />}
      warning />
  ),
};

const SaveNotification = ({ savingStatus, intl }) => {
  const notification = notificationDictionary[savingStatus];
  return notification ? notification(intl) : null;
};

SaveNotification.propTypes = {
  savingStatus: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SaveNotification);
