import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import MoveToFolderDialog from 'shared/MoveToFolderDialog';
import { folderScheme } from 'utils/proptypes';
import SharedLinkModal from 'Visualization/Editor-v2/SharedLinkModal/index';
import TemplateSettingsModal from 'Visualization/Editor-v2/TemplateSettingsModal';
import { CreateDocumentIcon } from 'icons/CreateDocument';

import { CreateDocumentLinkNotAvailableModal } from 'components/CreateDocumentLink/NotAvailableModal';

import Info from '@material-ui/icons/Info';
import Settings from '@material-ui/icons/Settings';
import Folder from '@material-ui/icons/Folder';
import DelIcon from '@material-ui/icons/Delete';
import EllipsisMenu, { EllipsisMenuButton } from 'components/EllipsisMenu';

export class ActionCell extends PureComponent {
  static propTypes = {
    templateId: PropTypes.string,
    folders: PropTypes.arrayOf(folderScheme),
    moveToTrash: PropTypes.func,
    selectFolder: PropTypes.func,
    moveToFolder: PropTypes.func,
    testId: PropTypes.string,
    goToFolderRequest: PropTypes.func,
    currentFolder: folderScheme,
    currentViewFolder: folderScheme,
    sharingActive: PropTypes.bool,
    sharingSlug: PropTypes.string,
    toggleSharingTemplateFromListRequest: PropTypes.func,
    templateStatus: PropTypes.string,
    lastQtvDetails: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.string,
      version: PropTypes.number,
      qtv_property: PropTypes.shape({
        date_format: PropTypes.string,
        document_highlighting_enabled: PropTypes.bool,
        edit_document_enabled: PropTypes.bool,
        language: PropTypes.string,
        sender_has_to_sign: PropTypes.bool,
        signing_enabled: PropTypes.bool,
        signing_methods: PropTypes.arrayOf(PropTypes.string),
        signing_order: PropTypes.string,
        time_zone: PropTypes.string,
      }),
    }),
  };

  state = {
    menuAnchorEl: null,
    moveToFolderDialogOpened: false,
    isSharedLinkModalOpen: false,
    isTemplateSettingsModalOpen: false,
  };

  componentWillUnmount() {
    this.handleCloseMenu();
  }

  handleClick = (event) => {
    event.stopPropagation();
    this.setState({ menuAnchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ menuAnchorEl: null });
  };

  handleToggleSharedLinkModal = () => {
    this.setState(({ isSharedLinkModalOpen }) => ({ isSharedLinkModalOpen: !isSharedLinkModalOpen }), () => {
      if (this.state.isSharedLinkModalOpen) {
        this.handleCloseMenu();
      }
    });
  };

  handleToggleTemplateSettingsModal = () => {
    this.setState(({ isTemplateSettingsModalOpen }) => ({ isTemplateSettingsModalOpen: !isTemplateSettingsModalOpen }), () => {
      if (this.state.isTemplateSettingsModalOpen) {
        this.handleCloseMenu();
      }
    });
  };

  handleToggleSharingTemplate = (sharingActive) => {
    this.props.toggleSharingTemplateFromListRequest(sharingActive, this.props.templateId);
  };

  handleMoveToFolderClick = (event) => {
    event.stopPropagation();
    this.setState({ moveToFolderDialogOpened: true });
    this.handleCloseMenu();
  };

  closeMoveToFolderDialog = () => {
    this.props.goToFolderRequest(this.props.currentViewFolder.id);
    this.setState({ moveToFolderDialogOpened: false });
  };

  moveToTrash = (event) => {
    event.stopPropagation();
    this.props.moveToTrash(this.props.templateId);
    this.handleCloseMenu();
  };

  moveToFolder = () => {
    const { templateId, currentFolder } = this.props;
    this.props.moveToFolder(templateId, currentFolder.id);
    this.closeMoveToFolderDialog();
  };

  render() {
    const { menuAnchorEl, moveToFolderDialogOpened, isSharedLinkModalOpen, isTemplateSettingsModalOpen } = this.state;
    const {
      currentFolder,
      folders,
      selectFolder,
      goToFolderRequest,
      currentViewFolder,
      templateId,
      testId,
      sharingSlug,
      sharingActive,
      templateStatus,
      lastQtvDetails,
      push,
    } = this.props;

    const menuItems = [
      {
        dataTestId: `${testId}-settings`,
        onClick: this.handleToggleTemplateSettingsModal,
        icon: <Settings />,
        text: <FormattedMessage id="api.panel.questionnaire_templates.actions.menu_template_settings" />,
      },
      {
        dataTestId: `${testId}-info`,
        linkTo: `/templates/${templateId}`,
        icon: <Info />,
        text: <FormattedMessage id="api.panel.questionnaire_templates.actions.menu_template_information" />,
      },
      {
        dataTestId: `${testId}-moveTo`,
        icon: <Folder />,
        onClick: this.handleMoveToFolderClick,
        text: <FormattedMessage id="api.panel.questionnaire_templates.actions.menu_move_to" />,
      },
      {
        dataTestId: `${testId}-sharedLink`,
        icon: <CreateDocumentIcon />,
        onClick: this.handleToggleSharedLinkModal,
        divider: true,
        text: <FormattedMessage id="api.panel.questionnaire_templates.actions.share" />,
      },
      {
        dataTestId: `${testId}-moveToTrash`,
        icon: <DelIcon />,
        onClick: this.moveToTrash,
        text: <FormattedMessage id="api.panel.questionnaire_templates.actions.trash" />,
      },
    ];

    return (
      <Fragment>
        <EllipsisMenuButton
          isOpen={Boolean(menuAnchorEl)}
          onClick={this.handleClick}
          testId={testId} />
        <EllipsisMenu
          domEl={menuAnchorEl}
          isOpen={Boolean(menuAnchorEl)}
          handleClose={this.handleCloseMenu}
          menuItems={menuItems} />
        {
          templateStatus !== 'active'
            ? (
              <CreateDocumentLinkNotAvailableModal
                open={isSharedLinkModalOpen}
                toggleModal={this.handleToggleSharedLinkModal}
                templateId={templateId} />
            )
            : (
              <SharedLinkModal
                open={isSharedLinkModalOpen}
                toggleModal={this.handleToggleSharedLinkModal}
                sharingActive={sharingActive}
                sharingSlug={sharingSlug}
                toggleSharingTemplate={this.handleToggleSharingTemplate} />
            )
        }

        <TemplateSettingsModal
          open={isTemplateSettingsModalOpen}
          toggleModal={this.handleToggleTemplateSettingsModal}
          templateVersionId={lastQtvDetails.id}
          templateId={templateId}
          templateSettings={lastQtvDetails.qtv_property}
          templateStatus={lastQtvDetails.status}
          push={push}
          publishFromTemplateList />

        <MoveToFolderDialog
          isOpen={moveToFolderDialogOpened}
          onClose={this.closeMoveToFolderDialog}
          moveToFolder={this.moveToFolder}
          currentFolder={currentFolder}
          folders={folders}
          selectFolder={selectFolder}
          goToFolder={goToFolderRequest}
          currentViewFolder={currentViewFolder} />
      </Fragment>
    );
  }
}
