import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import DateCell from 'components/table/DateCell';
import styles from './styles.css';

export class TemplatesLibraryTableComponent extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      createdAt: PropTypes.string,
    })),
    onSelectTemplate: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match,
  };

  selectTemplate = id => () => {
    const { folderId } = this.props.match.params;
    this.props.onSelectTemplate(id, folderId);
  };

  render() {
    const { data } = this.props;
    return (
      <Table data-test-id="TemplatesLibrary-table">
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="panel.templates_library.index.template" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="panel.templates_library.index.created_at" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(template => (
            <TableRow
              className={styles.templatesTableRow}
              key={template.id}
              data-test-id="TemplatesLibrary-row"
              hover
              onClick={this.selectTemplate(template.id)}>
              <TableCell width="75%" data-test-id="TemplatesLibrary-data">
                <Typography variant="h6">{template.name}</Typography>
              </TableCell>
              <TableCell width="25%">
                <DateCell value={template.createdAt} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default withRouter(TemplatesLibraryTableComponent);
