import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StyleCheckbox from './StyleCheckbox';
import styles from './HeadlineStyles.css';

export default class HeadlineStyles extends Component {
  static propTypes = { headerType: PropTypes.string.isRequired };

  render() {
    const { headerType } = this.props;
    return (
      <div className={styles.component}>
        <StyleCheckbox
          headerType={headerType}
          leftRadiusBorder
          styleType="bold" />
        <StyleCheckbox headerType={headerType} styleType="italic" />
        <StyleCheckbox
          headerType={headerType}
          rightRadiusBorder
          styleType="underline" />
      </div>
    );
  }
}
