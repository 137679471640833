import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';

/*
 * Flattens flows so it doesn't contain nested flow items (children)
 */
export const flowToFlatFlow = flow => flatten(flow.map(flowItem => [{ ...flowItem, children: [] }, ...flowToFlatFlow(flowItem.children)]));

export const createFlowToFlatFlowSelector = flowSelector => createSelector(flowSelector, flow => flowToFlatFlow(flow));

export const createFlowToFlatFlowSelectorWithFilter = (flowSelector, filter) => createSelector(flowSelector, flow => filter(flowToFlatFlow(flow)));

/*
 * Converts flow item to question (backwards compatibility) with additional property answer (if exists)
 */
export const cleanName = name => name && name.replace(/ *{{[^}]*}}/, '');

export const cleanQuestionName = flowItem => ({
  ...flowItem,
  name: cleanName(flowItem.name),
});
export const flatFlowItemToQuestion = flowItem => cleanQuestionName({ ...flowItem.question, answer: flowItem.answer });
export const flatFlowItemsToQuestions = flow => flow.map(flowItem => flatFlowItemToQuestion(flowItem));

export const createFlatFlowToQuestionsSelector = flatFlowSelector => createSelector(flatFlowSelector, flow => flatFlowItemsToQuestions(flow));
