/* eslint class-methods-use-this: 0 */
import rangy from 'rangy';

export default class RangeHelper {
  clearSelection() {
    const sel = window.document.getSelection();
    // check if has selection, required for IE
    if (sel.rangeCount > 0 && sel.getRangeAt(0).getClientRects().length > 0) {
      sel.removeAllRanges();
    }
    return true;
  }

  selectElementContents(el) {
    const range = rangy.createRange();
    const selection = rangy.getSelection();
    range.selectNodeContents(el);
    selection.setSingleRange(range);
    return true;
  }

  selectionText() {
    const selection = rangy.getSelection();
    return selection.text();
  }

  selectionLength() {
    return this.selectionText().trim().length;
  }
}
