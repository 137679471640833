import { MIXPANEL_TOKEN } from 'appRoot/constants/services';
import { Creators as userCreators, Types as userTypes } from 'ducks/loggedInUser/actions';
import _get from 'lodash/get';
import { call, put, select, take } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import { Creators, Types } from './actions';
import { selectIsV2Experience } from './selectors';

export default function createSagas({ amplitudeService, credentialsService, mixpanelService }) {
  function* initAppSaga() {
    const { appType } = yield take(Types.INIT_APP);

    yield put(userCreators.setAppType(appType));
    const credentials = yield call([credentialsService, credentialsService.fetchCredentials]);
    const accessToken = _get(credentials, 'accessToken');

    try {
      const payload = jwtDecode(accessToken);
      yield put(Creators.setWhiteLabelCompanyUrl(payload.redirect_url));
    } catch (e) {
      console.error(e.message);
    }

    yield put(userCreators.setIsUserAuthorized(!!accessToken));
    yield put(Creators.initAppSuccess());
  }

  function* initAmplitude() {
    const { user } = yield take(userTypes.FETCH_PROFILE_SUCCESS);
    amplitudeService.initTracking();
    amplitudeService.identifyUser(user.email);
    const v2Experience = yield select(selectIsV2Experience);
    if (!v2Experience) {
      amplitudeService.setUserProperties({ isLoggedIn: true, userType: user.roles[0], companyId: user.companyId });
    }
  }

  function* initMixpanel() {
    const { user } = yield take(userTypes.FETCH_PROFILE_SUCCESS);
    const token = MIXPANEL_TOKEN;

    mixpanelService.init(token);
    mixpanelService.track(`PageView ${document.title}`, { pageUrl: global.location.pathname + global.location.search });
    mixpanelService.identify(String(user.email));
    mixpanelService.alias(String(user.id));
    mixpanelService.people(user.email);
  }

  return { initAmplitude, initAppSaga, initMixpanel };
}
