import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Creators } from './actions';

class FreshdeskHelp extends Component {
  static propTypes = { openFreshdesk: PropTypes.func.isRequired }

  componentDidMount = () => {
    this.props.openFreshdesk();
  }

  render() {
    return null;
  }
}

export default connect(null, { openFreshdesk: Creators.openFreshdesk })(FreshdeskHelp);
