import React from 'react';
import PropTypes from 'prop-types';
import proptypes from 'utils/proptypes';
import styled from 'styled-components';
import Question from './Question';
import styles from './index.css';

/** Used as references for various `Number` constants. */

const NoQuestions = styled.div`
  height: 100px;
  text-align: center;
`;

const deepFlattenChildren = (questions, acc = []) => {
  questions.forEach((question) => {
    acc.push(question);

    if (question.children.length) {
      deepFlattenChildren(question.children, acc);
    }
  });

  return acc;
};

const QuestionsList = (props) => {
  const flattenFlow = deepFlattenChildren(props.flow);

  if (props.questions.length === 0) {
    return (
      <NoQuestions>
        {' '}
        {props.noQuestions}
        {' '}
      </NoQuestions>
    );
  }

  return (
    <div className={styles.questionList}>
      {props.questions.map((question) => {
        const flowQuestion = flattenFlow.find(e => e.questionId === question.id);

        if (!flowQuestion) {
          return null;
        }

        return (
          <Question
            key={question.id}
            index={flowQuestion.questionIndex}
            question={question}
            enableTriggersFn={props.enableTriggersFn}
            selectedNodeWithOption={props.selectedNodeWithOption} />
        );
      })}
    </div>
  );
};

QuestionsList.propTypes = {
  questions: proptypes.questionsScheme,
  enableTriggersFn: PropTypes.func.isRequired,
  selectedNodeWithOption: proptypes.domElement,
  noQuestions: PropTypes.string.isRequired,
  flow: PropTypes.arrayOf(proptypes.flowItem).isRequired,
};

export default QuestionsList;
