import PropTypes from 'prop-types';

const Views = ({ index, children }) => {
  const elementToRender = Array.isArray(children) ? children[index] : children;
  return elementToRender;
};

Views.propTypes = { index: PropTypes.number, children: PropTypes.node.isRequired };

Views.defaultProps = { index: 0 };

export default Views;
