import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';
import { Types } from '../actions';
import { ROOT_DIR } from '../constants';

export const initialState = {
  currentFolder: ROOT_DIR,
  selectedFolderId: null,
  folders: [],
};

const goToFolder = (state, { folders, currentFolder }) => update(state, {
  folders: { $set: folders },
  currentFolder: { $set: currentFolder },
});

const selectFolder = (state, { folderId }) => update(state, { selectedFolderId: { $set: folderId } });

const addFolder = (state, { folder }) => update(state, { folders: { $unshift: [folder] } });

const syncFolders = (state, { data: { folders, currentFolder } }) => update(state, {
  folders: { $set: folders },
  currentFolder: { $set: currentFolder },
});

export default createReducer(initialState, {
  [Types.GO_TO_FOLDER_SUCCESS]: goToFolder,
  [Types.SELECT_FOLDER]: selectFolder,
  [Types.CREATE_DOCUMENT_FOLDER_SUCCESS]: addFolder,
  [Types.FETCH_DOCUMENTS_SUCCESS]: syncFolders,
});
