import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import FolderIcon from '@material-ui/icons/Folder';
import theme from 'Visualization/theme';
import cn from 'classnames';
import ArrowForward from '@material-ui/icons/ArrowForward';
import FolderView from '../index';
import styles from '../styles.css';

class Folder extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    folderId: PropTypes.string,
    isSelected: PropTypes.bool,
    isNested: PropTypes.bool,
    index: PropTypes.number,
    subfolders: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    selectFolder: PropTypes.func,
  };

  static defaultProps = { subfolders: [] };

  handleSelectFolder = () => {
    const { folderId, selectFolder } = this.props;

    selectFolder(folderId);
  };

  render() {
    const { name, isSelected, subfolders, isNested } = this.props;
    const hasSubfolders = !!this.props.subfolders.length;

    const RenderFolderIcon = FolderIcon;
    const folderContainerClassNames = cn(styles.folderContainer, {
      [styles.folderContainerNested]: isNested,
      [styles.folderContainerWithSubfolders]: hasSubfolders,
    });
    const folderItemClassNames = cn(styles.folderItem, { [styles.folderItemNested]: isNested });
    const nameClassNames = cn(styles.name, { [styles.selectedName]: isSelected });

    return (
      <div className={folderContainerClassNames} data-test-id="FolderView-folderItem">
        <div aria-hidden className={folderItemClassNames} onClick={this.handleSelectFolder}>
          <RenderFolderIcon nativeColor={isSelected ? '#fff' : theme.font.light} />
          <span className={nameClassNames} data-test-id="FolderView-folderName">{name}</span>
          <ArrowForward className={styles.arrowIcon} fontSize="small" />
        </div>
        <AnimateHeight duration={300} height={0}>
          <FolderView {...this.props} folders={subfolders} isNested />
        </AnimateHeight>
      </div>
    );
  }
}

export default Folder;
