import { all, takeLatest, call, put, select, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import assert from 'assert';
import _get from 'lodash/get';
import getFlowFromHtml from 'shared/utils/getFlowFromHtml';
import openUrl from 'shared/helpers/openUrl';
import { replace } from 'connected-react-router';
import { PREVIEW, WHITE_LABEL } from 'shared/constants/questionnaireModes';
import { Creators as ProgressBarActions } from 'Questionnaire2/ProgressBar/redux';
import { Creators as DocumentCreators } from 'pages/DocumentDetails/actions';
import { STARTED, SENT_FOR_SIGNING, COMPLETED } from 'shared/constants/questionnaireStatus';
import { Creators as DocumentStylesActions } from 'pages/DocumentStyles/actions';
import { selectCurrentDocumentVersion } from 'pages/DocumentDetails/selectors';
import { prepareContentAndPageOptionsForPdf } from 'ducks/exportFile/sagas/exportPdf';
import {
  answerableFlow,
  nextQuestionSelector,
  unfinishedQuestionSelector,
  visitedAnswersSelector,
  lastQuestionSelector,
  questionnaireSelector,
} from '../selectors';
import { Creators as QuestionnaireActions, Types } from '../actions';

export default function createSagas({
  companyService,
  credentialsService,
  documentService,
  notificationService,
  questionnaireService,
  signDocumentService,
  trackingService,
}) {
  assert.ok(companyService, 'companyService is required');
  assert.ok(credentialsService, 'credentialsService is required');
  assert.ok(documentService, 'signDocumentService is required');
  assert.ok(notificationService, 'notificationService is required');
  assert.ok(questionnaireService, 'questionnaireService is required');
  assert.ok(signDocumentService, 'signDocumentService is required');
  assert.ok(signDocumentService, 'signDocumentService is required');
  assert.ok(trackingService, 'trackingService is required');

  function* fetchStylesheetsSaga() {
    while (true) {
      yield take(Types.FETCH_DOCUMENT_STYLES_REQUEST);
      try {
        const { data: { document_template: documentTemplate } } = yield call(
          [documentService, documentService.fetchStylesheets],
        );
        yield put(DocumentStylesActions.fetchDocumentStylesSuccess(documentTemplate));
        yield put(QuestionnaireActions.fetchDocumentStylesSuccess(documentTemplate.stylesheet));
      } catch (e) {
        yield put(QuestionnaireActions.fetchDocumentStylesFailure(e));
      }
    }
  }

  function* _setQuestionsFlow() {
    const { questions, wysiwygHtml } = yield select(questionnaireSelector);
    const questionsFlow = getFlowFromHtml(wysiwygHtml, questions);
    yield put.resolve(QuestionnaireActions.setQuestionsFlow(questionsFlow));
  }

  function* _updateQuestionnaire(timestamp, id) {
    try {
      const params = { flow_timestamp: timestamp, status: STARTED };
      yield call([questionnaireService, questionnaireService.updateUserQuestionnaire], id, params);
      yield put(QuestionnaireActions.updateUserQuestionnaireSuccess({ status: STARTED }));
      yield* _removeAllAnswers();
    } catch (e) {
      yield put(QuestionnaireActions.updateUserQuestionnaireFailure(e));
    }
  }

  function* _initializeActiveQuestion() {
    const questionnaireData = yield select(questionnaireSelector);
    const questionsFlow = yield select(answerableFlow);
    if (questionnaireData.userQuestionnaire.status === STARTED) {
      // No questionsFlow, no way to actually complete the document
      if (questionsFlow.length === 0) {
        yield put(QuestionnaireActions.setCurrentQuestion({}));
        yield put(QuestionnaireActions.setDocumentCompleted());
        return;
      }

      // Check if has unanwered/skipped question
      const nextQuestion = yield select(nextQuestionSelector);
      const unfinishedQuestion = yield select(unfinishedQuestionSelector);
      const lastQuestion = yield select(lastQuestionSelector);

      yield put(QuestionnaireActions.setDocumentNotReady(unfinishedQuestion !== undefined));

      // Go for nextQuestion if it is found, otherwise look for skippedQuestion
      // if all are not found that means questionnaire is fully answered and need
      // last click to complete (go for last question)
      yield put(QuestionnaireActions.setCurrentQuestion(nextQuestion || unfinishedQuestion || lastQuestion || {}));
    } else {
      // Questionnaire is completed
      yield put(QuestionnaireActions.setCurrentQuestion({}));
    }
    yield put(QuestionnaireActions.fetchUserQuestionnaireFinish());
  }

  function* _removeAllAnswers() {
    try {
      const { userQuestionnaire: { id } } = yield select(questionnaireSelector);
      const { data } = yield call([questionnaireService, questionnaireService.removeAllAnswersFromQuestionnaire], id);
      yield put(QuestionnaireActions.storeRemovedAnswers(data.remaining_answers, data.removed_answers));
    } catch (e) {
      yield put(QuestionnaireActions.removeAllAnswersFailure(e));
    }
  }

  function* init({ userQuestionnaire }) {
    const templateVersionFlowTimestamp = _get(userQuestionnaire, ['questionnaire_template_version', 'flow_timestamp']);
    const userQuestionnaireFlowTimestamp = _get(userQuestionnaire, 'flow_timestamp');
    const { mode } = yield select(questionnaireSelector);
    yield put.resolve(QuestionnaireActions.fetchUserQuestionnaireSuccess(userQuestionnaire));
    if (userQuestionnaire.error_details) {
      yield put(DocumentCreators.fetchDocumentErrorsSuccess({
        errorType: userQuestionnaire.error_details.error_type,
        data: userQuestionnaire.error_details.data,
      }));
    }
    yield* _setQuestionsFlow();
    if (mode === PREVIEW && templateVersionFlowTimestamp !== userQuestionnaireFlowTimestamp) {
      yield* _updateQuestionnaire(templateVersionFlowTimestamp, userQuestionnaire.id);
    }
    yield* _initializeActiveQuestion();
    yield put(QuestionnaireActions.fetchUserQuestionnaireFinish());
    if (mode === WHITE_LABEL) {
      const questionnaire = {
        // eslint-disable-next-line camelcase
        name: userQuestionnaire.name || userQuestionnaire.questionnaire_template_version.name,
        completed: userQuestionnaire.status !== 'started',
        slug: userQuestionnaire.id,
      };
      yield put(DocumentCreators.addQuestionnaireToDocumentVersions(questionnaire));
      yield put(DocumentCreators.updateDocumentName(questionnaire.name));
    }
  }

  function* createUserQuestionnaire() {
    while (true) {
      try {
        const { templateId } = yield take(Types.CREATE_USER_QUESTIONNAIRE);
        const {
          data: {
            user_questionnaire: {
              id,
              // eslint-disable-next-line camelcase
              questionnaire_template_version,
            },
          },
        } = yield call([questionnaireService, questionnaireService.create], templateId);
        // eslint-disable-next-line camelcase
        trackingService.track('UserStartedDocument', 'Questionaire', { documentId: id, templateName: questionnaire_template_version.name });
        const { accessToken } = yield call([credentialsService, credentialsService.fetchCredentials]);
        yield put(replace(`/external/documents/${id}/edit?token=${accessToken}`));
        yield put(QuestionnaireActions.createUserQuestionnaireSuccess());
      } catch (e) {
        yield put(QuestionnaireActions.createUserQuestionnaireFailure(e));
      }
    }
  }

  function* fetchUserQuestionnaire({ userQuestionnaireId, mode }) {
    yield put.resolve(QuestionnaireActions.setMode(mode));
    try {
      // eslint-disable-next-line camelcase
      const { data: { user_questionnaire } } = yield call([questionnaireService, questionnaireService.fetch], userQuestionnaireId);
      // eslint-disable-next-line camelcase
      trackingService.track('DocumentOpened', 'Questionaire', { documentId: user_questionnaire.id, templateName: user_questionnaire.questionnaire_template_version.name });
      yield put(QuestionnaireActions.fetchCompany());
      // eslint-disable-next-line camelcase
      yield put(QuestionnaireActions.init(user_questionnaire));
    } catch (e) {
      yield put(QuestionnaireActions.fetchUserQuestionnaireFailure(e, '/documents'));
    }
  }

  function* updateUserQuestionnaireNameSaga({ userQuestionnaireId, name }) {
    yield put(QuestionnaireActions.updateUserQuestionnaireRequest(userQuestionnaireId, { name }));
  }

  function* updateUserQuestionnaireRequestSaga({ userQuestionnaireId, params }) {
    try {
      yield call(delay, 200);
      const response = yield call([questionnaireService, questionnaireService.update], userQuestionnaireId, params);
      // eslint-disable-next-line camelcase
      yield put(QuestionnaireActions.updateUserQuestionnaireSuccess(response.data.user_questionnaire));
    } catch (e) {
      yield put(
        notificationService.error({
          message: 'panel.user_questionnaires.notifications.rename_failure',
          useTranslate: true,
        }),
      );
      yield put(QuestionnaireActions.updateUserQuestionnaireFailure(e));
    }
  }

  function* downloadSignedDocument() {
    try {
      const { userQuestionnaire: { id } } = yield select(questionnaireSelector);
      const { data: { url } } = yield call([signDocumentService, signDocumentService.downloadSignedDocument], 'user_questionnaire', id);
      yield put(QuestionnaireActions.downloadSignedDocumentSuccess());
      trackingService.track('DownloadPDF', 'Questionnaire', { documentId: id });
      openUrl(url);
    } catch (e) {
      yield put(QuestionnaireActions.downloadSignedDocumentFailure(e));
    }
  }

  function* findNextQuestion() {
    try {
      const nextQuestion = yield select(nextQuestionSelector);

      const documentTemplateEl = yield call([document, document.getElementById], 'document-template');
      // TODO: Store and obtain from Redux

      // Check if has unanwered/skipped question
      const unfinishedQuestion = yield select(unfinishedQuestionSelector);
      yield put(QuestionnaireActions.setDocumentNotReady(unfinishedQuestion !== undefined));

      yield put(ProgressBarActions.setProgressBar(documentTemplateEl.innerHTML));

      if (!nextQuestion && !unfinishedQuestion) {
        // Log event
        // eslint-disable-next-line camelcase
        const { userQuestionnaire: { id, questionnaire_template_version } } = yield select(questionnaireSelector);
        // eslint-disable-next-line camelcase
        trackingService.track('DocumentCompleted', 'Questionnaire', { documentId: id, templateName: questionnaire_template_version.name });

        yield put(QuestionnaireActions.setDocumentCompleted());
      }
      // We should never set skipped questions as a result of an action.
      yield put(QuestionnaireActions.setCurrentQuestion(nextQuestion || {}));
    } catch (e) {
      yield put(QuestionnaireActions.findNextQuestionFailure(e));
    }
  }

  function* findPreviousQuestion({ answerIndex }) {
    try {
      const { questions } = yield select(questionnaireSelector);
      const visitedAnswers = yield select(visitedAnswersSelector);
      const previousQuestionId = (visitedAnswers[answerIndex] || {}).question_id;
      const previousQuestion = questions.find(q => q.id === previousQuestionId);
      yield put.resolve(QuestionnaireActions.setCurrentQuestion(previousQuestion));
      yield put(QuestionnaireActions.setDocumentStarted());
    } catch (e) {
      yield put(QuestionnaireActions.findPreviousQuestionFailure());
    }
  }

  function* goToQuestionSaga({ questionId }) {
    const { questions, userQuestionnaire: { id } } = yield select(questionnaireSelector);
    const question = questions.find(q => q.id === questionId);
    trackingService.track('GoToQuestion', 'Questionnaire', { documentId: id });
    yield put.resolve(QuestionnaireActions.setCurrentQuestion(question));
    yield put(QuestionnaireActions.setDocumentStarted());
  }

  function* setDocumentCompletedSaga() {
    try {
      yield put(QuestionnaireActions.setDocumentNotReady(false));
      yield put(QuestionnaireActions.setCurrentQuestion({}));
      const { userQuestionnaire: { id } } = yield select(questionnaireSelector);
      yield call([questionnaireService, questionnaireService.updateStatus], id, COMPLETED);
    } catch (e) {
      yield put(QuestionnaireActions.setDocumentCompletedFailure(e));
    }
  }

  function* setDocumentStartedSaga() {
    try {
      const { userQuestionnaire: { id } } = yield select(questionnaireSelector);
      yield call([questionnaireService, questionnaireService.updateStatus], id, STARTED);
      yield put(QuestionnaireActions.setDocumentStartedSuccess());
    } catch (e) {
      yield put(QuestionnaireActions.setDocumentStartedFailure(e));
    }
  }

  function* signDocumentRequestSaga({ values }) {
    const messageKey = 'questionnaire.esign.notifications';
    // eslint-disable-next-line camelcase
    const { userQuestionnaire: { id: questionnaireId, questionnaire_template_version } } = yield select(questionnaireSelector);
    const { isQuestionnaire, slug } = yield select(selectCurrentDocumentVersion);

    const { html: documentHtml, options: exportOptions } = yield call(prepareContentAndPageOptionsForPdf);

    const signingInfo = {
      ...values,
      documentType: isQuestionnaire ? 'user_questionnaire' : 'document_version',
      documentSlug: slug,
      documentHtml,
      exportOptions,
    };

    try {
      yield call(
        [signDocumentService, signDocumentService.signDocument],
        { questionnaireId, signingInfo },
      );
      yield put(
        QuestionnaireActions.updateUserQuestionnaireSuccess({
          name: signingInfo.userQuestionnaireName,
          status: SENT_FOR_SIGNING,
        }),
      );

      yield put(
        notificationService.success({
          message: `${messageKey}.success`,
          useTranslate: true,
        }),
      );
      yield put(QuestionnaireActions.signDocumentRequestSuccess());
      // eslint-disable-next-line camelcase
      trackingService.track('SentForSigning', 'Questionnaire', { documentId: questionnaireId, templateName: questionnaire_template_version.name, numberOfRecipients: signingInfo.emails.tags.length });
    } catch (err) {
      yield put(QuestionnaireActions.signDocumentRequestFailure(err));
      yield put(
        notificationService.error({
          message: `${messageKey}.error`,
          useTranslate: true,
        }),
      );
    }
  }

  function* fetchCompanySaga() {
    try {
      const { data: { company } } = yield call([companyService, companyService.fetchCompany]);
      yield put(QuestionnaireActions.fetchCompanySuccess(company));
    } catch (e) {
      yield put(QuestionnaireActions.fetchCompanyFailure(e));
    }
  }

  function* cancelSigningRequestSaga({ questionnaireId }) {
    try {
      yield call([signDocumentService, signDocumentService.cancelSigning], questionnaireId);
      trackingService.track('CancelSigning', 'Questionnaire', { documentId: questionnaireId });
      yield put(QuestionnaireActions.updateUserQuestionnaireSuccess({ status: COMPLETED }));
      yield put(QuestionnaireActions.cancelSigningSuccess());
    } catch (err) {
      // message:
      // signing_still_in_progress
      // cant_cancel_signed_envelope
      yield put(
        notificationService.error({
          message: `questionnaire.esign.notifications.${err.data.message}`,
          useTranslate: true,
        }),
      );
      yield put(QuestionnaireActions.updateUserQuestionnaireFailure(err));
      yield put(QuestionnaireActions.cancelSigningFailure(err));
    }
  }


  function* _preview(id) {
    try {
      const { data } = yield call([questionnaireService, questionnaireService.getUserQuestionnairePreviewForTemplate], id);
      yield put(QuestionnaireActions.init(data.user_questionnaire));
    } catch (e) {
      yield put(QuestionnaireActions.getPreviewFailure(e));
    }
  }

  function* fetchUserQuestionnairePreviewForTemplate({ qtId }) {
    try {
      const response = yield call([questionnaireService, questionnaireService.getDraftTemplateVersion], qtId);
      const { id } = response.data.questionnaire_template_version;
      yield put(QuestionnaireActions.fetchUserQuestionnairePreviewForTemplateSuccess());
      yield* _preview(id);
    } catch (e) {
      yield put(QuestionnaireActions.fetchUserQuestionnairePreviewForTemplateFailure(e));
    }
  }

  function* trackTogglePreviewSaga({ destination }) {
    yield call([trackingService, trackingService.track], 'Toggle Preview', 'Questionnaire', { destination });
  }

  function* fetchDocumentPreviewPdfSaga() {
    while (true) {
      try {
        const { questionnaireId, format } = yield take(Types.FETCH_DOCUMENT_PREVIEW_PDF_REQUEST);
        const { data } = yield call(
          [questionnaireService, questionnaireService.previewDocument],
          questionnaireId,
          format,
        );
        yield put(QuestionnaireActions.fetchDocumentPreviewPdfSuccess(data));
      } catch (e) {
        yield put(QuestionnaireActions.fetchDocumentPreviewPdfFailure(e));
      }
    }
  }

  return function* root() {
    yield all([
      takeLatest(Types.INIT, init),
      createUserQuestionnaire(),
      fetchDocumentPreviewPdfSaga(),
      fetchStylesheetsSaga(),
      takeLatest(Types.FETCH_USER_QUESTIONNAIRE, fetchUserQuestionnaire),
      takeLatest(Types.UPDATE_USER_QUESTIONNAIRE_REQUEST, updateUserQuestionnaireRequestSaga),
      takeLatest(Types.UPDATE_USER_QUESTIONNAIRE_NAME, updateUserQuestionnaireNameSaga),
      takeLatest(Types.DOWNLOAD_SIGNED_DOCUMENT, downloadSignedDocument),
      takeLatest(Types.FIND_NEXT_QUESTION, findNextQuestion),
      takeLatest(Types.FIND_PREVIOUS_QUESTION, findPreviousQuestion),
      takeLatest(Types.SET_DOCUMENT_COMPLETED, setDocumentCompletedSaga),
      takeLatest(Types.SET_DOCUMENT_STARTED, setDocumentStartedSaga),
      takeLatest(Types.SIGN_DOCUMENT_REQUEST, signDocumentRequestSaga),
      takeLatest(Types.FETCH_COMPANY, fetchCompanySaga),
      takeLatest(Types.CANCEL_SIGNING_REQUEST, cancelSigningRequestSaga),
      takeLatest(Types.GO_TO_QUESTION, goToQuestionSaga),
      takeLatest(Types.FETCH_USER_QUESTIONNAIRE_PREVIEW_FOR_TEMPLATE, fetchUserQuestionnairePreviewForTemplate),
      takeLatest(Types.TRACK_TOGGLE_PREVIEW, trackTogglePreviewSaga),
    ]);
  };
}
