import camelCase from 'lodash/camelCase';

const apiCallStatusReducer = (state = {}, action) => {
  const { type, error } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // Not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [camelCase(requestName)]: {
      isLoading: requestState === 'REQUEST',
      isFinished: requestState === 'SUCCESS' || requestState === 'FAILURE',
      hasResponseError: requestState === 'FAILURE',
      responseError: requestState === 'FAILURE' ? error : null,
    },
  };
};

export default apiCallStatusReducer;
