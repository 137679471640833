exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-form-Label-___index__component___3ogtU {\n  color: #767676;\n  color: var(--c-bw-50);\n  display: block;\n  font-weight: 700;\n  font-weight: var(--font-weight-bold);\n  font-size: 0.8rem;\n  margin: 10px 0 8px;\n}\n\n  .app-components-form-Label-___index__component___3ogtU:first-child {\n    margin-top: 0;\n  }\n\n  .app-components-form-Label-___index__component___3ogtU[for] {\n    cursor: pointer;\n  }\n", "", {"version":3,"sources":["/workspace/app/components/form/Label/index.css"],"names":[],"mappings":"AAAA;EACE,eAAqB;EAArB,sBAAqB;EACrB,eAAc;EACd,iBAAoC;EAApC,qCAAoC;EACpC,kBAAiB;EACjB,mBAAkB;CASpB;;EAPE;IACE,cAAa;GACf;;EAEA;IACE,gBAAe;GACjB","file":"index.css","sourcesContent":[".component {\n  color: var(--c-bw-50);\n  display: block;\n  font-weight: var(--font-weight-bold);\n  font-size: 0.8rem;\n  margin: 10px 0 8px;\n\n  &:first-child {\n    margin-top: 0;\n  }\n\n  &[for] {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-components-form-Label-___index__component___3ogtU"
};