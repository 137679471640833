import { call, put, take } from 'redux-saga/effects';

import { snakeToCamelCase } from 'shared/helpers/transforms';
import _map from 'lodash/map';

import { Creators, Types } from './actions';

export default function createSagas({ userService, notificationService }) {
  function* fetchUsersSaga() {
    while (true) {
      const { payload: { page, q } = {} } = yield take(Types.FETCH_USERS_REQUEST);

      try {
        // eslint-disable-next-line camelcase
        const { data: { users, users_count } } = yield call(
          [userService, userService.fetchUsersByQueryParams],
          { page, q },
        );
        const formattedUsers = _map(users, snakeToCamelCase);

        yield put(Creators.fetchUsersSuccess(formattedUsers, users_count));
      } catch (e) {
        yield put(Creators.fetchUsersFailure(e));
      }
    }
  }

  function* inviteUsersSaga() {
    while (true) {
      const { emails, role } = yield take(Types.INVITE_USERS_REQUEST);

      try {
        yield call([userService, userService.inviteUsersByEmail], emails, role);
        yield put(Creators.fetchUsersRequest());
        yield put(Creators.inviteUsersSuccess());
        yield put(notificationService.success({
          title: 'users_invite.success.title',
          message: 'users_invite.success.description',
          position: 'bl',
          useTranslate: true,
        }));
      } catch (e) {
        yield put(Creators.inviteUsersFailure(e));
        yield put(notificationService.error({
          title: 'users_invite.failure.title',
          message: 'users_invite.failure.description',
          position: 'bl',
          useTranslate: true,
        }));
      }
    }
  }

  return { fetchUsersSaga, inviteUsersSaga };
}
