import { NODE_BLOCKS } from 'Visualization/helpers/constants';
import isQuestion from 'Visualization/helpers/isQuestion';

export default function getNearestExpandableNode(node) {
  if (isQuestion(node)) return node;
  if (isInline(node)) {
    return getNearestExpandableNode(node.parentElement);
  }
  return node;
}

function isInline(node) {
  // check if node cannot be splitted,
  // only block nodes and question nodes are not supposed to be spliited
  return !NODE_BLOCKS.includes(node.tagName);
}
