import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import BrightnessIcon from '@material-ui/icons/Brightness1';

import styles from './index.css';

const StatusCell = ({ value }) => {
  const iconClasses = cn({
    [styles.iconActive]: ['active', 'started', 'completed', 'sent_for_signing', 'signing_requested'].indexOf(value) !== -1,
    [styles.iconSigned]: value === 'signed',
    [styles.iconError]: ['expired', 'error', 'signing_expired', 'signing_failed', 'signing_aborted', 'rejected'].indexOf(value) !== -1,
  });

  return (
    <div className={styles.component}>
      <BrightnessIcon className={iconClasses} classes={{ root: styles.icon }} />
      <FormattedMessage id={`shared.statuses.${value}`} />
    </div>
  );
};

StatusCell.propTypes = { value: PropTypes.string.isRequired };

export default StatusCell;
