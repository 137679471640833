import assert from 'assert';
import { push } from 'connected-react-router';
import { Creators as QuestionnaireCreators } from 'ducks/questionnaire/actions';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { delay } from 'redux-saga';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { REJECTED, SIGNING_ABORTED, SIGNING_EXPIRED, SIGNING_FAILED } from 'shared/constants/questionnaireStatus';
import openUrl from 'shared/helpers/openUrl';
import { Creators, Types } from './actions';
import { selectDocumentVersionByRevision } from './selectors';

export default function createSagas({ documentVersionsService, signDocumentService, notificationService }) {
  assert.ok(documentVersionsService, 'documentVersionsService is required');
  assert.ok(signDocumentService, 'signDocumentService is required');

  const getSelfLink = v => _find(v.links, l => l.rel === 'self').href;

  function* fetchDocumentDetailsSaga() {
    while (true) {
      const { documentId, mode } = yield take(Types.FETCH_DOCUMENT_DETAILS_REQUEST);
      try {
        const { data } = yield call(
          [documentVersionsService, documentVersionsService.fetchDocumentDetails],
          documentId,
        );
        const versions = _get(data, 'content', []).reverse();
        yield put(Creators.fetchDocumentDetailsSuccess(documentId, {
          name: data.name,
          state: data.state,
          locked: data.locked,
          versions,
        }));

        if (data.questionnaire) {
          yield put(Creators.addQuestionnaireToDocumentVersions(data.questionnaire));
        }
        if (versions.length) {
          yield* fetchDocumentVersion(versions[0]);
        }
        if ([SIGNING_ABORTED, SIGNING_EXPIRED, SIGNING_FAILED, REJECTED].includes(data.state)) {
          const documentSlug = versions.length > 0 ? _get(versions, '[0].slug') : documentId;
          const documentType = versions.length > 0 ? 'document_version' : 'user_questionnaire';
          if (documentType === 'document_version') {
            yield put(Creators.fetchDocumentErrorsRequest(documentSlug, documentType));
          }
        }
      } catch (e) {
        yield put(Creators.fetchDocumentDetailsFailure(e));
      } finally {
        yield put(QuestionnaireCreators.fetchUserQuestionnaire(documentId, mode));
      }
    }
  }

  function* fetchDocumentErrorsSaga() {
    while (true) {
      const { documentId, documentType } = yield take(Types.FETCH_DOCUMENT_ERRORS_REQUEST);
      try {
        const { data } = yield call(
          [signDocumentService, signDocumentService.getErrors],
          documentId,
          documentType,
        );
        yield put(Creators.fetchDocumentErrorsSuccess(data));
      } catch (error) {
        yield put(Creators.fetchDocumentErrorsFailure(error));
      }
    }
  }

  function* changeCurrentDocumentVersionSaga() {
    while (true) {
      const { revision } = yield take(Types.CHANGE_CURRENT_DOCUMENT_VERSION);
      const version = yield select(selectDocumentVersionByRevision(revision));
      if (!version.isQuestionnaire || version.html === undefined) {
        yield* fetchDocumentVersion(version);
      }
    }
  }

  function* fetchDocumentVersion(version) {
    try {
      const { data } = yield call(
        [documentVersionsService, documentVersionsService.fetchDocumentVersion],
        getSelfLink(version),
      );
      yield put(Creators.fetchDocumentVersionSuccess(data));
    } catch (e) {
      yield put(Creators.fetchDocumentVersionFailure(e));
    }
  }

  function* createDocumentVersionSaga() {
    while (true) {
      const { documentId, name, html } = yield take(Types.CREATE_DOCUMENT_VERSION_REQUEST);
      const { pathname, search } = window.location;

      try {
        const { data } = yield call(
          [documentVersionsService, documentVersionsService.createDocumentVersion],
          documentId,
          name,
          html,
        );
        yield put(Creators.createDocumentVersionSuccess(data));

        if (pathname.includes('/external')) {
          yield put(push(`/external/documents/${documentId}/edit-version${search}`));
        } else {
          yield put(push(`/documents/${documentId}/edit`));
        }
      } catch (e) {
        yield put(Creators.createDocumentVersionFailure(e));
      }
    }
  }

  function* updateCurrentVersionContentSaga() {
    // the takeLatest and delay is instead of debounce, which is in the v1.x of redux-saga
    yield takeLatest(Types.UPDATE_CURRENT_VERSION_CONTENT_REQUEST, updateCurrentVersionContent);
  }

  function* updateCurrentVersionContent({ content, version }) {
    try {
      yield call(delay, 300);
      const documentVersion = {
        ...version,
        html: content,
      };
      yield call(
        [documentVersionsService, documentVersionsService.updateDocumentVersion],
        getSelfLink(documentVersion),
        documentVersion,
      );
      yield put(Creators.updateCurrentVersionContentSuccess());
    } catch (e) {
      yield put(Creators.updateCurrentVersionContentFailure(e));
    }
  }

  function* updateDocumentVersionNameSaga() {
    while (true) {
      const { name, version } = yield take(Types.UPDATE_DOCUMENT_VERSION_NAME_REQUEST);
      yield* _updateDocumentVersionName(name, version);
    }
  }

  function* _updateDocumentVersionName(name, version) {
    try {
      yield call(
        [documentVersionsService, documentVersionsService.updateDocumentVersionName],
        getSelfLink(version),
        name,
      );
      yield put(Creators.updateDocumentName(name));
      yield put(Creators.updateDocumentVersionNameSuccess(version));
    } catch (e) {
      yield put(Creators.updateDocumentVersionNameFailure(e));
    }
  }

  function* saveDocumentVersionSaga() {
    while (true) {
      const { documentId, versionName, version } = yield take(Types.SAVE_DOCUMENT_VERSION_REQUEST);
      const { pathname, search } = window.location;
      try {
        yield* _updateDocumentVersionName(versionName, version);
        yield call(
          [documentVersionsService, documentVersionsService.saveDocumentVersion],
          documentId,
          version.slug,
        );

        if (pathname.includes('/external')) {
          yield put(push(`/external/documents/${documentId}/edit${search}`));
        } else {
          yield put(push(`/documents/${documentId}`));
        }
        yield put(Creators.saveDocumentVersionSuccess(version));
      } catch (e) {
        yield put(Creators.saveDocumentVersionFailure(e));
      }
    }
  }

  function* downloadSignedDocumentPdfSaga() {
    while (true) {
      try {
        const { documentType, documentId } = yield take(Types.DOWNLOAD_SIGNED_DOCUMENT_PDF_REQUEST);

        const { data: { url } } = yield call(
          [signDocumentService, signDocumentService.downloadSignedDocument],
          documentType, documentId,
        );
        yield put(Creators.downloadSignedDocumentPdfSuccess());
        yield call(openUrl, url);
      } catch (e) {
        yield put(Creators.downloadSignedDocumentPdfFailure(e));
      }
    }
  }

  function* cancelDocumentVersionSigningSaga() {
    while (true) {
      try {
        const { documentId } = yield take(Types.CANCEL_DOCUMENT_VERSION_SIGNING_REQUEST);

        yield call(
          [signDocumentService, signDocumentService.cancelSigning],
          documentId,
          'document_version',
        );
        yield put(Creators.cancelDocumentVersionSigningSuccess());
      } catch (e) {
        yield put(
          notificationService.error({
            message: `questionnaire.esign.notifications.${_get(e, 'data.message.message')}`,
            useTranslate: true,
          }),
        );
        yield put(Creators.cancelDocumentVersionSigningFailure(e));
      }
    }
  }

  return {
    fetchDocumentDetailsSaga,
    createDocumentVersionSaga,
    updateCurrentVersionContentSaga,
    changeCurrentDocumentVersionSaga,
    saveDocumentVersionSaga,
    updateDocumentVersionNameSaga,
    downloadSignedDocumentPdfSaga,
    cancelDocumentVersionSigningSaga,
    fetchDocumentErrorsSaga,
  };
}
