/**
 * @flow
 *
 * Analytics tracking with Amplitude or Rollbar.
 * `meta` objects are Flux Standard Actions: https://github.com/redux-utilities/flux-standard-action
 *
 * How to do `amplitudeService.logEvent()`:
 *
 * dispatch({
 *   type: 'SOME_ACTION_TYPE',
 *   meta: {
 *       analytics: {
 *           type<string>,
 *           payload: {
 *              userId: 1234,
 *              displayName: 'Foo',
 *           },
 *       },
 *   },
 * });
 *
 * How to do `loggingReporter.log()`:
 *
 * dispatch({
 *   type: '', // this field is required, but redundant for our purpose
 *   meta: {
 *       analytics: {
 *           type<string>,
 *           payload: {
  *             command: 'error',
 *              error<Error>,
 *              info<string | Object>,
 *           },
 *       },
 *   },
 * });
 *
 * more examples: https://github.com/amplitude/Amplitude-Javascript/blob/master/test/browser/amplitudejs.html
 */
import assert from 'assert';
import reduxAnalytics from 'redux-analytics';
import omit from 'lodash/omit';

const DEFAULT_ANALYTICS_COMMAND = 'event';

export const createAnalyticsMiddleware = ({ trackingService, loggingReporter }) => {
  const log = {
    event(type, payload = {}) {
      return trackingService.track(type, payload.context || '', omit(payload, ['context']));
    },

    error(type, { error }) {
      assert.ok(error, 'error<Error> is required');

      return loggingReporter.log(error);
    },
  };

  return reduxAnalytics(({ type, payload = {} }) => {
    const validPayloadCommand = Boolean(payload.command && log[payload.command]);
    const command = validPayloadCommand ? payload.command : DEFAULT_ANALYTICS_COMMAND;
    const formattedPayload = omit(payload, ['command']);

    return log[command](type, formattedPayload);
  });
};
