import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styled from 'styled-components';
import { Slot } from 'react-slot-fill';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import proptypes from 'utils/proptypes';
import { logicTypes } from 'appRoot/constants';
import Separator from 'shared/Components/Separator';
import Button, { SECONDARY } from 'components/Button';
import FlexCenter from 'shared/Components/FlexCenter';
import removeQuestionFromDom from 'Visualization/helpers/removeQuestionFromDom';
import RangeHelper from 'Visualization/helpers/rangeHelper';
import FieldRow from 'components/form/FieldRow';
import InputText from 'components/form/InputText';
import Label from 'components/form/Label';
import InputCheckbox from 'components/form/InputCheckbox';
import QuestionConstraints from 'components/QuestionConstraints';
import { CONSTRAIN_TYPE } from 'Visualization/helpers/constants';
import TriggersFn from '../TriggersFn';
import withTriggersFnHoc from '../withTriggersFnHoc';
import withEditableOptionsHoc from '../withEditableOptionsHoc';
import HelpTextEditor from '../HelpTextEditor';
import ExtendSelection from '../TriggersFn/utils/extendSelection';
import SuggestProperty from '../SuggestProperty';

import styles from './index.module.css';

const LinkQuestionButton = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  line-height: 13px;
  span {
    margin: 0;
  }
  small {
    color: ${props => props.theme.font.light};
    margin-bottom: 10px;
    padding: 2px;
  }
  strong {
    color: ${props => props.theme.font.light};
    padding: 2px;
  }
`;

class InputReplaceType extends Component {
  static propTypes = {
    enableTriggersFn: PropTypes.func.isRequired,
    enableImmediatelyTriggersFn: PropTypes.func.isRequired,
    stopTriggersFn: PropTypes.func.isRequired,
    updateQuestion: PropTypes.func.isRequired,
    updateQuestionDetails: PropTypes.func.isRequired,
    question: proptypes.questionScheme,
    currentQuestion: proptypes.questionScheme,
    assignAndClose: PropTypes.func.isRequired,
    assignAndKeepOpen: PropTypes.func.isRequired,
    triggersFnEnabled: PropTypes.bool.isRequired,
    triggerImmediately: PropTypes.bool.isRequired,
    triggeredOptionId: PropTypes.string,
    intl: intlShape.isRequired,
  };

  componentDidMount() {
    this.linkQuestion();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentQuestion.details.id !== this.props.currentQuestion.details.id) {
      this.linkQuestion();
    }
  }

  linkQuestion = () => {
    const { question } = this.props;
    const rangeHelper = new RangeHelper();

    if (rangeHelper.selectionLength() && !question.id) {
      // initialy create question and assign it to selected text
      new ExtendSelection(logicTypes.INPUT_REPLACE_TYPE).call(); // eslint-disable-line
      this.props.enableImmediatelyTriggersFn(question, () => {
        this.forceUpdate();
        this.mainTextField.focus();
      });
    } else {
      this.mainTextField.focus();
    }
  };

  removeTriggers = () => {
    const optionId = this.props.question.details.id;

    $(`[data-replace="${optionId}"]`).each((i, node) => {
      removeQuestionFromDom(node);
    });
    this.forceUpdate();
  };

  handleConstraintChange = (e) => {
    const { name, value } = e.target;
    this.props.updateQuestionDetails(name, value);
  };

  handleFormattingChange = (e) => {
    const { name, checked } = e.target;
    this.props.updateQuestionDetails(name, checked);
  };

  render() {
    const { question, intl, triggersFnEnabled } = this.props;
    const optionAssigned = $(`[data-replace="${question.details.id}"]`).length > 0;
    const onSuccess = this.props.triggerImmediately ? this.props.assignAndKeepOpen : this.props.assignAndClose;
    /* eslint-disable */
    return (
      <div>
        {triggersFnEnabled === false ? null : (
          <TriggersFn
            logicType="inputReplaceType"
            triggerImmediately={this.props.triggerImmediately}
            stopTriggersFn={this.props.stopTriggersFn}
            onSuccess={onSuccess}
            questionId={question.id}
            optionId={this.props.triggeredOptionId} />
        )}
        <FieldRow>
          <Label>
            {intl.formatMessage({ id: 'visualization.logics_manager.question_label' })}
          </Label>
          <SuggestProperty question={this.props.question} onChange={this.props.updateQuestion}>
            <InputText
              value={question.name}
              name="name"
              testId="InputReplaceType-name"
              placeholder={intl.formatMessage({ id: 'visualization.logics_manager.question_placeholder' })}
              inputRef={x => (this.mainTextField = x)}
              onChange={this.props.updateQuestion} />
          </SuggestProperty>
        </FieldRow>
        <HelpTextEditor
          value={question.details.help_text}
          name="help_text"
          testId="InputReplaceType-helpText"
          label={intl.formatMessage({ id: 'visualization.logics_manager.help_text_label' })}
          placeholder={intl.formatMessage({ id: 'visualization.logics_manager.help_text_placeholder' })}
          onChangeFn={this.props.updateQuestionDetails} />
        <Separator />
        <FlexCenter>
          {optionAssigned ? (
            <LinkQuestionButton>
              <strong>
                <FormattedMessage id="visualization.logics_manager.input.unlinking" />
              </strong>
              <small>
                <FormattedMessage id="visualization.logics_manager.input.unlinking_caption" />
              </small>
              <Button testId="InputReplaceType-unlinkButton" onClick={this.removeTriggers}>
                <FormattedMessage id="visualization.logics_manager.unlink" />
              </Button>
            </LinkQuestionButton>
          ) : (
            <LinkQuestionButton>
              <strong>
                <FormattedMessage id="visualization.logics_manager.input.linking" />
              </strong>
              <small>
                <FormattedMessage id="visualization.logics_manager.input.linking_caption" />
              </small>
              <Button
                testId="InputReplaceType-linkButton"
                variant={SECONDARY}
                onClick={() => this.props.enableTriggersFn(question.details.id, question)}>
                <FormattedMessage id="visualization.logics_manager.link" />
              </Button>
            </LinkQuestionButton>
          )}
        </FlexCenter>
        <h3 className={styles.settingsHeader}>
          <FormattedMessage id="visualization.logics_manager.settings" />
        </h3>
        <QuestionConstraints onChange={this.handleConstraintChange} selected={question.details.constrain} />
        {( question.details.constrain === CONSTRAIN_TYPE.NONE ?
          <div className={styles.formattingOptionsWrapper}>
            <Label>
              <FormattedMessage id="visualization.logics_manager.formatting_title" />
            </Label>
            <InputCheckbox
              className={styles.multilineInputCheckbox}
              label={intl.formatMessage({ id: 'visualization.logics_manager.allow_long_answer_checkbox' })}
              name="multiline_input"
              checked={question.details.multiline_input}
              onChange={this.handleFormattingChange}/>
          </div>
            :
            null
        )}
        <Slot name="LogicsManager.Close" />
      </div>
    );
    /* eslint-enable */
  }
}
export default compose(
  withTriggersFnHoc,
  withEditableOptionsHoc,
  injectIntl,
)(InputReplaceType);
