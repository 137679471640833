import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import DocumentIcon from 'icons/Document';
import StyledName from 'shared/TopToolbar/StyledName';
import AutoWidthInput from 'components/form/AutoWidthInput';
import { injectIntl, intlShape } from 'react-intl';
import { Creators as EditorActions } from '../../reducer';
import Informations from './Informations';

class TemplateName extends React.Component {
  static propTypes = {
    previewMode: PropTypes.bool.isRequired,
    saveTemplateName: PropTypes.func.isRequired,
    templateName: PropTypes.string.isRequired,
    templateVersion: PropTypes.number.isRequired,
    templateStatus: PropTypes.string.isRequired,
    templateFolder: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
  };

  state = { templateName: this.props.templateName }

  updateName = (e) => {
    this.setState({ templateName: e.target.value }, () => {
      this.props.saveTemplateName(this.state.templateName);
    });
  };

  render() {
    const tooltipContent = this.props.intl.formatMessage({ id: 'visualization.top_toolbar.rename_template' });
    return (
      <StyledName>
        <DocumentIcon className="document-icon" />
        <div className="top-toolbar-content">
          <AutoWidthInput
            className="template-name"
            testId="TemplateName-input"
            rootClassName="template-name-font"
            data-tip={tooltipContent}
            readOnly={this.props.previewMode}
            disableUnderline={this.props.previewMode}
            value={this.state.templateName}
            onChange={this.updateName} />
          <Informations
            templateVersion={this.props.templateVersion}
            templateStatus={this.props.templateStatus}
            templateFolder={this.props.templateFolder} />
        </div>
      </StyledName>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { saveTemplateName: debounce(name => dispatch(EditorActions.saveTemplateName(name)), 200) };
}
function mapStateToProps(state) {
  return {
    templateName: state.editor.templateVersion.name,
    templateVersion: state.editor.templateVersion.version,
    templateStatus: state.editor.templateVersion.status,
    templateFolder: state.editor.templateVersion.folder_breadcrumbs,
  };
}
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
)(TemplateName);
