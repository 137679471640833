import React, { PureComponent, Fragment } from 'react';
import Page from 'components/Page';
import NavigationBar from 'components/NavigationBar';
import PageHeader from './components/UserListPageHeader';
import UserList from './components/UserList';

export default class UserListPage extends PureComponent {
  render() {
    return (
      <Fragment>
        <NavigationBar />
        <PageHeader />
        <Page>
          <UserList />
        </Page>
      </Fragment>
    );
  }
}
