// hoc used to compose methods for updating logics linked to question
// so logics is linked to question object and to DOM element

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import proptypes from 'utils/proptypes';
import { options as optionTemplate } from 'Visualization/helpers/templates';
import { Creators as QuestionsActions } from 'Visualization/Editor-v2/Questions/QuestionsRedux';

export default function (WrappedComponent) {
  class withEditableOptionsHoc extends React.Component {
    static propTypes = {
      currentQuestion: proptypes.questionScheme,
      updateQuestion: PropTypes.func.isRequired,
      addNewQuestion: PropTypes.func.isRequired,
    };

    apiUpdateQuestion = debounce(() => {
      const questionParams = this.state.question;
      if (!isEmpty(questionParams.id)) {
        this.props.updateQuestion(questionParams.id, questionParams);
      }
    }, 300)

    state = { question: this.props.currentQuestion }

    static getDerivedStateFromProps(props, state) {
      if (!isEqual(props.currentQuestion.id, state.question.id)) {
        return {
          ...state,
          question: props.currentQuestion,
        };
      }

      return state;
    }

    updateQuestion = (e) => {
      const newState = update(this.state, { question: { [e.target.name]: { $set: e.target.value } } });

      this.setState(newState, this.apiUpdateQuestion);
    };

    updateQuestionDetails = (name, value) => {
      const newState = update(this.state, { question: { details: { [name]: { $set: value } } } });

      this.setState(newState, this.apiUpdateQuestion);
    };

    updateOption = (optionId, e) => {
      const { question } = this.state;
      const { options } = question.details;
      const optionIndex = options.findIndex(opt => opt.id === optionId);
      const newOptions = update(options, { [optionIndex]: { name: { $set: e.target.value } } });

      this.setState(
        state => update(state, { question: { details: { options: { $set: newOptions } } } }),
        this.apiUpdateQuestion,
      );
    };

    addNewOption = () => {
      const { question } = this.state;
      const newOptions = question.details.options;
      newOptions.push(optionTemplate().select);
      question.options = newOptions;
      this.setState({ question }, this.apiUpdateQuestion);
    };

    removeOption = (optionId) => {
      const { question } = this.state;
      const { options } = question.details;
      const newOptions = options.filter(opt => opt.id !== optionId);
      this.setState(
        state => update(state, { question: { details: { options: { $set: newOptions } } } }),
        this.apiUpdateQuestion,
      );
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          updateQuestion={this.updateQuestion}
          updateQuestionDetails={this.updateQuestionDetails}
          addNewOption={this.addNewOption}
          removeOption={this.removeOption}
          updateOption={this.updateOption} />
      );
    }
  }
  function mapDispatchToProps(dispatch) {
    return {
      updateQuestion: (questionId, params) => dispatch(QuestionsActions.updateQuestion(questionId, params)),
      addNewQuestion: params => dispatch(QuestionsActions.addNewQuestion(params)),
    };
  }

  return connect(
    null,
    mapDispatchToProps,
  )(withEditableOptionsHoc);
}
