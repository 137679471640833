import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';

export const INITIAL_STATE = {
  logicsManagerKind: '',
  triggersFnEnabled: false,
  triggeredOptionId: '',
  triggerImmediately: false,
};

export const { Types, Creators } = createActions({
  openLogicsManager: ['kind', 'options'],
  openLogicsManagerFailure: ['error'],
  setLogicsManagerKind: ['kind'],
  closeLogicsManager: null,
  toggleTriggersActive: ['bool'],
  setTriggerImmediately: ['bool'],
  setTriggersOptionId: ['optionId'],
  validateCanAddQuestion: ['logicType', 'optionId', 'onSuccess', 'questionId'],
  validateCanAddQuestionFailure: ['error'],
  setSelectionErrorMessage: ['errorMessage'],
  reset: null,
});

const setLogicsManagerKind = (state, { kind }) => update(state, { logicsManagerKind: { $set: kind } });

const closeLogicsManager = state => update(state, { logicsManagerKind: { $set: '' } });

const toggleTriggersActive = (state, { bool }) => update(state, { triggersFnEnabled: { $set: bool } });

const setTriggersOptionId = (state, { optionId }) => update(state, { triggeredOptionId: { $set: optionId } });

const setTriggerImmediately = (state, { bool }) => update(state, { triggerImmediately: { $set: bool } });

const reset = state => update(state, {
  logicsManagerKind: { $set: '' },
  triggersFnEnabled: { $set: false },
  triggeredOptionId: { $set: '' },
  triggerImmediately: { $set: false },
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_LOGICS_MANAGER_KIND]: setLogicsManagerKind,
  [Types.CLOSE_LOGICS_MANAGER]: closeLogicsManager,
  [Types.TOGGLE_TRIGGERS_ACTIVE]: toggleTriggersActive,
  [Types.SET_TRIGGERS_OPTION_ID]: setTriggersOptionId,
  [Types.SET_TRIGGER_IMMEDIATELY]: setTriggerImmediately,
  [Types.RESET]: reset,
});
