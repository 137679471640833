import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'components/Button';
import FolderIcon from '@material-ui/icons/Folder';
import AddIcon from '@material-ui/icons/Add';

import styles from './index.css';

const EmptyBucket = ({
  onClick,
  heading,
  paragraph,
  buttonLabel,
  icon,
  className,
  ...props
}) => {
  const classString = cn(styles.component, className);

  return (
    <div className={classString} {...props}>
      <div className={styles.folderIconWrapper}>
        {icon || <FolderIcon className={styles.folderIcon} />}
      </div>
      <h3 className={styles.heading}>{heading}</h3>
      <p className={styles.paragraph}>{paragraph}</p>
      {onClick && (
        <Button onClick={onClick}>
          <AddIcon className={styles.addIcon} />
          {buttonLabel}
        </Button>
      )}
    </div>
  );
};

EmptyBucket.propTypes = {
  onClick: PropTypes.func,
  heading: PropTypes.node,
  paragraph: PropTypes.node,
  buttonLabel: PropTypes.node,
};

export default EmptyBucket;
