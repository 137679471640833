import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProgressBar from '../Components/ProgressBar';

class ProgressBarContainer extends Component {
  static propTypes = {
    maxQuestionsLength: PropTypes.number.isRequired,
    remainingQuestionsLength: PropTypes.number.isRequired,
    onProgressClick: PropTypes.func.isRequired,
    isLeftToolbarVisible: PropTypes.bool.isRequired,
    hideProgressBar: PropTypes.bool.isRequired,
    className: PropTypes.string,
  };

  render() {
    const {
      hideProgressBar,
      maxQuestionsLength,
      remainingQuestionsLength,
      onProgressClick,
      isLeftToolbarVisible,
      className,
    } = this.props;
    if (!maxQuestionsLength) return null;
    const percentFinished = (1 - remainingQuestionsLength / maxQuestionsLength) * 100;
    return (
      <ProgressBar
        isHidden={hideProgressBar}
        onProgressClick={onProgressClick}
        isLeftToolbarVisible={isLeftToolbarVisible}
        percentFinished={parseInt(percentFinished.toFixed(0), 10)}
        className={className} />
    );
  }
}

const mapStateToProps = state => ({
  maxQuestionsLength: state.progressBar.maxQuestionsLength,
  remainingQuestionsLength: state.progressBar.remainingQuestionsLength,
});

export default connect(mapStateToProps)(ProgressBarContainer);
