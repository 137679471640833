import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button, { SECONDARY } from 'components/Button';
import withPublishUnpublishHoc from './withPublishUnpublishHoc';

class UnpublishTemplate extends React.PureComponent {
  static propTypes = { unpublishTemplate: PropTypes.func.isRequired };

  render() {
    return (
      <Button testId="unpublish" variant={SECONDARY} onClick={this.props.unpublishTemplate}>
        <FormattedMessage id="visualization.publish_unpublish.unpublish" />
      </Button>
    );
  }
}

export default withPublishUnpublishHoc(UnpublishTemplate);
