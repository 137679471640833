import React from 'react';
import PropTypes from 'prop-types';
import Portal from 'shared/Portal';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from 'components/IconButton';
import GridContainer from 'components/Grid/Container';
import GridItem from 'components/Grid/Item';
import cn from 'classnames';
import $ from 'jquery';
import ModalFooter from './ModalFooter';
import styles from './index.css';

const SMALL = 'small';
const MEDIUM = 'medium';
const BIG = 'big';
class Modal extends React.PureComponent {
  static propTypes = {
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    long: PropTypes.bool,
    size: PropTypes.oneOf([SMALL, MEDIUM, BIG]),
    modalTitle: PropTypes.node,
    hiddenCloseButton: PropTypes.bool,
    testId: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    modalFadeRef: PropTypes.instanceOf(Element),
    modalContentRef: PropTypes.instanceOf(Element),
  };

  static defaultProps = {
    modalFadeRef: undefined,
    modalContentRef: undefined,
  };

  componentDidMount() {
    this.setViewPortCssReference();
    window.removeEventListener('resize', this.setViewPortCssReference); // TODO: remove these footer placement codes when the whole app redesigned to be used on mobile.
    window.addEventListener('resize', this.setViewPortCssReference);
  }

  setViewPortCssReference = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  zoomDisable = () => {
    // TODO: remove these footer placement codes when the whole app redesigned to be used on mobile.
    $('head meta[name=viewport]').remove();
    $('head').prepend(
      '<meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0" />',
    );
  };

  zoomEnable = () => {
    // TODO: remove these footer placement codes when the whole app redesigned to be used on mobile.
    $('head meta[name=viewport]').remove();
    $('head').prepend('<meta name="viewport" content="width=device-width, user-scalable=1" />');
  };

  onOpen = () => {
    const { onOpen } = this.props;

    this.zoomDisable();
    if (onOpen) {
      onOpen();
    }
  };

  onClose = () => {
    const { onClose } = this.props;

    this.zoomEnable();
    if (onClose) {
      onClose();
    }
  };

  render() {
    const {
      modalTitle: ModalTitle,
      open,
      toggleModal,
      size,
      long,
      testId,
      className,
      children,
      hiddenCloseButton,
      modalFadeRef,
      modalContentRef,
    } = this.props;
    if (!open) return null;

    return (
      <Portal defaultOpen onOpen={this.onOpen} onClose={this.onClose}>
        <div aria-hidden="true" className={styles.fade} onClick={toggleModal} ref={modalFadeRef} />
        <div className={styles.component} ref={modalContentRef}>
          <div
            className={cn(
              styles.modal,
              {
                [styles.long]: long,
                [styles[size]]: size,
              },
              className,
            )}
            data-test-id={testId}
            id="modal"
            onScroll={this.setViewPortCssReference}>
            <GridContainer spacing={0}>
              {ModalTitle && (
                <GridItem xs={12} className={styles.title}>
                  <span>{ModalTitle}</span>
                </GridItem>
              )}
              {!hiddenCloseButton && (
                <IconButton className={styles.close} testId={`${testId}-toggle`} onClick={toggleModal}>
                  <CloseIcon />
                </IconButton>
              )}
            </GridContainer>
            <GridContainer spacing={0} className={styles.contentWrapper}>
              <GridItem xs={12}>{children}</GridItem>
            </GridContainer>
          </div>
        </div>
      </Portal>
    );
  }
}

const ModalContent = ({ children, className, fullHeight }) => (
  <GridContainer className={cn(styles.content, { [styles.contentFullHeight]: fullHeight }, className)} spacing={0}>
    <GridItem xs={12}>{children}</GridItem>
  </GridContainer>
);

ModalContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fullHeight: PropTypes.bool,
};

export { ModalContent, ModalFooter, SMALL, MEDIUM, BIG };

export default Modal;
