import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import cn from 'classnames';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Circle from '@material-ui/icons/RadioButtonUnchecked';
import Help from '@material-ui/icons/Help';
import ANSWER_STATUSES from 'shared/constants/answerStatuses';
import proptypes from 'utils/proptypes';
import Typography, { VARIANTS } from 'components/Typography';
import styles from './index.css';

export default function Question({ question, active, goToQuestion }) {
  useEffect(() => {
    scrollMe();
  }, [active]);

  function getIcon() {
    if (active) {
      return <ArrowForward />;
    }

    const status = _get(question, 'answer.status');
    switch (status) {
      case ANSWER_STATUSES.SKIPPED:
        return <Help className={styles.helpIcon} />;
      case ANSWER_STATUSES.UNANSWERED:
        return <Circle />;
      default:
        return <CheckCircle />;
    }
  }


  function scrollMe() {
    if (!active) return;
    const $container = $('#question-list-container');
    const currentQuestion = $(`[name="${question.id}"]`);
    const targetOffset = currentQuestion.offset().top - $container.offset().top + $container.scrollTop() - 100;
    $container.animate({ scrollTop: targetOffset }, 500);
  }
  return (
    <Typography
      className={cn(styles.component, { [styles.active]: active })}
      name={question.id}
      variant={VARIANTS.BUTTON_TEXT}
      onClick={() => goToQuestion(question)}
      data-test-id="Questionnaire-questionName">
      {getIcon()}
      {question.name}
    </Typography>
  );
}

Question.propTypes = {
  active: PropTypes.bool,
  question: proptypes.questionScheme.isRequired,
  goToQuestion: PropTypes.func.isRequired,
};

Question.defaultProps = { active: false };
