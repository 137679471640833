import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, withFormik } from 'formik';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Typography, { VARIANTS } from 'components/Typography';
import FieldRow from 'components/form/FieldRow';
import InputText from 'components/form/InputText';
import Button from 'components/Button';
import { Creators } from 'ducks/loggedInUser/actions';
import { isLoading as isLoadingSelector } from 'utils/statusSelectors';
import { email as emailRegex } from 'utils/regex';

import styles from './styles.css';

export const SignIn = ({ handleSubmit, isLoading }) => (
  <div className={styles.component}>
    <form className={styles.form} onSubmit={handleSubmit} noValidate>
      <Typography variant={VARIANTS.H2} className={styles.heading}>
        <FormattedMessage id="sign_in.heading" />
      </Typography>
      <Typography variant={VARIANTS.H5} className={styles.subheading}>
        <FormattedMessage id="sign_in.subheading" />
      </Typography>
      <FieldRow className={styles.fieldSpacing}>
        <Typography variant={VARIANTS.LABEL} htmlFor="email" className={styles.label}>
          <FormattedMessage id="sign_in.labels.email" />
        </Typography>
        <Field name="email">
          {({ field, form }) => (
            <InputText
              {...field}
              autoFocus
              type="email"
              required
              errorMessage={form.touched[field.name] && form.errors[field.name]}
              testId="SignIn-emailField" />
          )}
        </Field>
      </FieldRow>
      <FieldRow>
        <Typography variant={VARIANTS.LABEL} htmlFor="password" className={styles.label}>
          <FormattedMessage id="sign_in.labels.password" />
        </Typography>
        <Field name="password">
          {({ field, form }) => (
            <InputText
              {...field}
              type="password"
              required
              errorMessage={form.touched[field.name] && form.errors[field.name]}
              testId="SignIn-passwordField"
              autocomplete="off" />
          )}
        </Field>
      </FieldRow>
      <div className={styles.buttonGroup}>
        <Link data-test-id="SignIn-forgotPasswordLink" to="/auth/forgot-password" className={styles.link}>
          <Typography variant={VARIANTS.HELP_TEXT}>
            <FormattedMessage id="sign_in.labels.forgot_password" />
          </Typography>
        </Link>
        <Button testId="SignIn-submit" isLoading={isLoading} type="submit">
          <FormattedMessage id="sign_in.labels.submit" />
        </Button>
      </div>
    </form>
  </div>
);

SignIn.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

const requiredValues = ['password', 'email'];

export const formConfig = {
  enableReinitialize: true,
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  validate: (values) => {
    const errors = {};
    if (!emailRegex.test(values.email)) {
      errors.email = <FormattedMessage id="sign_in.errors.invalid_email" />;
    }

    requiredValues.forEach((name) => {
      if (!values[name]) errors[name] = <FormattedMessage id="sign_in.errors.cant_be_blank" />;
    });

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    props.signIn(values.email, values.password);
    setSubmitting(false);
  },
};

const mapStateToProps = state => ({ isLoading: isLoadingSelector(state, 'appLoggedInUserStateSignIn') });
const mapDispatchToProps = { signIn: Creators.signInRequest };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik(formConfig),
)(SignIn);
