import React, { Component } from 'react';
import styles from './index.css';

class DocumentPreview extends Component {
  render() {
    const { html } = this.props;
    return (
      <div data-test-id="DocumentDetails-documentContent" className={styles.component} dangerouslySetInnerHTML={{ __html: html }} />
    );
  }
}


export default DocumentPreview;
