import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { CONSTRAIN_TYPE, CONSTRAIN_KEY } from 'Visualization/helpers/constants';

import styles from './index.css';

const createEvent = id => ({
  target: {
    name: CONSTRAIN_KEY,
    value: id,
  },
});

const ConstraintOption = ({ id, label, className, active, onChange }) => (
  <button
    type="button"
    onClick={() => onChange(createEvent(id))}
    className={cn(styles.option, className, { [styles.active]: active })}
    data-test-id={id}>
    {label}
  </button>
);

ConstraintOption.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const QuestionConstraints = ({ className, selected, onChange, ...props }) => {
  const classString = cn(styles.component, className);

  return (
    <>
      <div className={styles.heading}><FormattedMessage id="visualization.logics_manager.constrains.heading" /></div>
      <div className={classString} {...props}>
        <ConstraintOption
          id={CONSTRAIN_TYPE.NONE}
          className={styles.left}
          active={CONSTRAIN_TYPE.NONE === selected}
          onChange={onChange}
          label={<FormattedMessage id="visualization.logics_manager.constrains.none" />} />
        <ConstraintOption
          id={CONSTRAIN_TYPE.UINT}
          active={CONSTRAIN_TYPE.UINT === selected}
          onChange={onChange}
          label={<FormattedMessage id="visualization.logics_manager.constrains.uint" />} />
        <ConstraintOption
          id={CONSTRAIN_TYPE.EMAIL}
          active={CONSTRAIN_TYPE.EMAIL === selected}
          onChange={onChange}
          label={<FormattedMessage id="visualization.logics_manager.constrains.email" />} />
        <ConstraintOption
          id={CONSTRAIN_TYPE.DATE}
          className={styles.right}
          active={CONSTRAIN_TYPE.DATE === selected}
          onChange={onChange}
          label={<FormattedMessage id="visualization.logics_manager.constrains.date" />} />
      </div>
    </>
  );
};

QuestionConstraints.propTypes = {
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

export default injectIntl(QuestionConstraints);
