import React from 'react';
import _filter from 'lodash/filter';
import _has from 'lodash/has';
import PropTypes from 'prop-types';
import { EnableDocumentEditing } from './TemplateSettings/EnableDocumentEditing';
import { EnableDocumentHighlighting } from './TemplateSettings/EnableDocumentHighlighting';
import { SigningEnabledComponent } from './TemplateSettings/SigningEnabled';

const templateSettingsToComponentsMapping = {
  document_highlighting_enabled: () => <EnableDocumentHighlighting />,
  edit_document_enabled: ({ values }) => <EnableDocumentEditing values={values} />,
  signing_enabled: ({ esigningModule }) => <SigningEnabledComponent esigningModule={esigningModule} />,
};

export const templateSettingsFactory = templateSettings => _filter(templateSettingsToComponentsMapping, (val, key) => _has(templateSettings, key));

templateSettingsToComponentsMapping.signing_enabled.propTypes = { esigningModule: PropTypes.bool.isRequired };
