exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-pages-DocumentStyles-components-___HeadlineStyles__component___1-Tmp {\n  display: flex;\n  margin-left: 10px;\n  justify-content: flex-start;\n  align-items: center;\n  flex: 1;\n  align-self: stretch;\n}\n", "", {"version":3,"sources":["/workspace/app/pages/DocumentStyles/components/HeadlineStyles.css"],"names":[],"mappings":"AAAA;EACE,cAAa;EACb,kBAAiB;EACjB,4BAA2B;EAC3B,oBAAmB;EACnB,QAAO;EACP,oBAAmB;CACrB","file":"HeadlineStyles.css","sourcesContent":[".component {\n  display: flex;\n  margin-left: 10px;\n  justify-content: flex-start;\n  align-items: center;\n  flex: 1;\n  align-self: stretch;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-pages-DocumentStyles-components-___HeadlineStyles__component___1-Tmp"
};