import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { cleanName } from 'Questionnaire2/flowSelectorsUtils';


import PropTypes from 'prop-types';
import HelpText from './HelpText';

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    margin: 0;
  }
  i {
    line-height: 110%;
    font-size: 20px;
    margin-left: 5px;
    color: ${props => props.theme.mainColor};
  }
`;

const StyledHeader = styled.h2`
  font-weight: 400;
  color: #202020;
  margin: 20px 0 0;
  word-break: break-word;
  font-size: ${rem('25px')};
`;

class QuestionName extends PureComponent {
  render() {
    const { name, helpText } = this.props;

    return (
      <Styled>
        <StyledHeader data-test-id="Questionnaire-currentQuestionName">{cleanName(name)}</StyledHeader>
        {helpText && <HelpText helpText={helpText} />}
      </Styled>
    );
  }
}

QuestionName.propTypes = { name: PropTypes.string.isRequired, helpText: PropTypes.string };

export default QuestionName;
