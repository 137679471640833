exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-CreateDocumentLink-___NotAvailableModal__text___WnEXa {\n  display: flex;\n  align-items: center;\n  font-size: 1rem;\n  color: #787878;\n  margin-bottom: 0;\n}\n\n.app-components-CreateDocumentLink-___NotAvailableModal__infoIcon___3E-Vg {\n  margin-right: 5px;\n}\n", "", {"version":3,"sources":["/workspace/app/components/CreateDocumentLink/NotAvailableModal.css"],"names":[],"mappings":"AAAA;EACE,cAAa;EACb,oBAAmB;EACnB,gBAAe;EACf,eAAc;EACd,iBAAgB;CAClB;;AAEA;EACE,kBAAiB;CACnB","file":"NotAvailableModal.css","sourcesContent":[".text {\n  display: flex;\n  align-items: center;\n  font-size: 15px;\n  color: #787878;\n  margin-bottom: 0;\n}\n\n.infoIcon {\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"text": "app-components-CreateDocumentLink-___NotAvailableModal__text___WnEXa",
	"infoIcon": "app-components-CreateDocumentLink-___NotAvailableModal__infoIcon___3E-Vg"
};