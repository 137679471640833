import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import styles from './index.css';

const Pagination = ({ pageCount, onPageChange, initialPage }) => {
  const count = Math.ceil(pageCount / 10);
  if (count <= 1) {
    return null;
  }
  return (
    <ReactPaginate
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      pageCount={count}
      onPageChange={onPageChange}
      initialPage={initialPage}
      previousLabel="<"
      nextLabel=">"
      breakClassName={styles.paginationBreak}
      containerClassName={styles.pagination}
      pageClassName={styles.paginationPage}
      previousClassName={styles.paginationPage}
      nextClassName={styles.paginationPage}
      disabledClassName={styles.paginationDisabled}
      activeClassName={styles.paginationActive} />
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number,
  onPageChange: PropTypes.func,
  initialPage: PropTypes.number,
};

export default Pagination;
