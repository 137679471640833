import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import ArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Typography from 'components/Typography';

import cn from 'classnames';
import styles from './index.css';

const Index = ({ paths }) => paths.map(p => (
  <React.Fragment key={p.route}>
    {p.lastBreadcrumb ? (
      <Typography variant="h3" className={cn(styles.title, styles.currentPath)}>
        {p.title}
      </Typography>
    ) : (
      <>
        <Link to={p.route}>
          <Typography variant="h3" className={styles.title}>
            {p.title}
          </Typography>
        </Link>
        <ArrowRight classes={{ root: styles.iconItem }} />
      </>
    )}
  </React.Fragment>
));

Index.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.shape({
    route: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    lastBreadcrumb: PropTypes.bool,
  })).isRequired,
};

export default Index;
