import queryString from 'qs';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';

export const isSearching = (location) => {
  const params = queryString.parse(location.search, { ignoreQueryPrefix: true });
  delete params.page; // Remove parameter from pagination
  // In the future you may need to add another cleaners
  return Object.keys(params).length > 0;
};

export const constructFolderQueryParams = folderId => ({ folder_id: folderId });

export const constructDocumentFolderQueryParams = folderId => ({ document_folder_id: folderId, page: 1 });

export const removeEmptyQueryParams = queryParams => pickBy(queryParams, param => !isEmpty(param));

export const parseSearchToObject = search => queryString.parse(search, { ignoreQueryPrefix: true });

export default isSearching;
