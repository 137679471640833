import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import DataProvider from 'shared/DataProvider';
import FolderList from 'components/FolderList';
import RecordsTable, { TableCell } from 'shared/Components/RecordsTable';
import EmptyBucket from 'components/EmptyBucket';
import { injectIntl, intlShape } from 'react-intl';
import TemplateTableRow from '../components/TableRows/TemplateTableRow';
import { Creators } from '../reducer';
import styles from './styles.css';

class Templates extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
  };

  getFolderSubheader = folder => this.props.intl.formatMessage(
    { id: 'panel.templates_library.folders.subfolders.templates_count_non_ruby' },
    { val: folder.questionnaireTemplatesCount },
  );

  getFolderId = () => (this.props.match.params.folderId ? this.props.match.params.folderId : undefined);

  handleFolderClick = folderId => this.props.history.push(`/templates/folders/${folderId}`);

  render() {
    const { deleteFolder, fetchTemplatesInfoRequest, history, moveToTrash, intl, updateFolderName } = this.props;
    const folderId = this.getFolderId();

    return (
      <DataProvider
        fetchData={fetchTemplatesInfoRequest}
        folderId={folderId}
        itemKey="templates"
        statusKey="fetchTemplatesInfo">
        {({ data }) => (
          <Fragment>
            <article className={styles.folderListContainer}>
              <FolderList
                deleteFolder={deleteFolder}
                disableLoadingFolder
                folders={data.folders}
                getFolderSubheader={this.getFolderSubheader}
                onFolderClick={this.handleFolderClick}
                updateFolderNameRequest={updateFolderName}
                testIdPrefix="TemplatesFolders" />
            </article>
            <RecordsTable
              {...data.templates}
              testId="Templates-table"
              columnHeaders={[
                <TableCell key="template_name">
                  {intl.formatMessage({ id: 'panel.questionnaire_templates.index.template_name' })}
                </TableCell>,
                <TableCell key="created_at">
                  {intl.formatMessage({ id: 'panel.questionnaire_templates.index.created_at' })}
                </TableCell>,
                <TableCell key="edited_at">
                  {intl.formatMessage({ id: 'panel.questionnaire_templates.index.edited_at' })}
                </TableCell>,
                <TableCell key="status">
                  {intl.formatMessage({ id: 'panel.questionnaire_templates.index.status' })}
                </TableCell>,
                <TableCell key="created_by">
                  {intl.formatMessage({ id: 'panel.questionnaire_templates.index.created_by' })}
                </TableCell>,
                <TableCell key="actions" />,
              ]}
              EmptyComponent={(
                <EmptyBucket
                  onClick={() => this.props.createNewTemplate(folderId)}
                  heading={
                    intl.formatMessage({ id: 'panel.questionnaire_templates.questionnaire_template.empty_bucket.heading' })
                  }
                  paragraph={
                    intl.formatMessage({ id: 'panel.questionnaire_templates.questionnaire_template.empty_bucket.paragraph' })
                  }
                  buttonLabel={
                    intl.formatMessage({ id: 'panel.questionnaire_templates.questionnaire_template.empty_bucket.buttonLabel' })
                  }
                  key="documents-empty-bucket" />
              )}
              renderRow={(template, index) => (
                <TemplateTableRow
                  detailsRoute={`/templates/${template.id}/preview`}
                  folderId={folderId}
                  key={template.id}
                  moveToTrash={moveToTrash}
                  onClick={history.push}
                  template={template}
                  itemIndex={index}
                  testIdPrefix="TemplatesList"
                  push={history.location} />
              )} />
          </Fragment>
        )}
      </DataProvider>
    );
  }
}

export default compose(
  connect(null, Creators),
  injectIntl,
)(Templates);
