exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-table-StatusCell-___index__component___3lvGO {\n  display: flex;\n  align-items: center;\n}\n\n.app-components-table-StatusCell-___index__icon___tMWqp {\n  font-size: 1rem !important;\n  margin-right: 5px;\n  color: #767676;\n  color: var(--c-bw-50);\n}\n\n.app-components-table-StatusCell-___index__iconActive___16DcB {\n  color: #3e94f6;\n  color: var(--c-document-status-active);\n}\n\n.app-components-table-StatusCell-___index__iconSigned___3lBl3 {\n  color: #50c788;\n  color: var(--c-document-status-signed);\n}\n\n.app-components-table-StatusCell-___index__iconError___Bv-Ww {\n  color: #ee4723;\n  color: var(--c-document-status-error);\n}\n", "", {"version":3,"sources":["/workspace/app/components/table/StatusCell/index.css"],"names":[],"mappings":"AAAA;EACE,cAAa;EACb,oBAAmB;CACrB;;AAEA;EACE,2BAA0B;EAC1B,kBAAiB;EACjB,eAAqB;EAArB,sBAAqB;CACvB;;AAEA;EACE,eAAsC;EAAtC,uCAAsC;CACxC;;AAEA;EACE,eAAsC;EAAtC,uCAAsC;CACxC;;AAEA;EACE,eAAqC;EAArC,sCAAqC;CACvC","file":"index.css","sourcesContent":[".component {\n  display: flex;\n  align-items: center;\n}\n\n.icon {\n  font-size: 1rem !important;\n  margin-right: 5px;\n  color: var(--c-bw-50);\n}\n\n.iconActive {\n  color: var(--c-document-status-active);\n}\n\n.iconSigned {\n  color: var(--c-document-status-signed);\n}\n\n.iconError {\n  color: var(--c-document-status-error);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-components-table-StatusCell-___index__component___3lvGO",
	"icon": "app-components-table-StatusCell-___index__icon___tMWqp",
	"iconActive": "app-components-table-StatusCell-___index__iconActive___16DcB",
	"iconSigned": "app-components-table-StatusCell-___index__iconSigned___3lBl3",
	"iconError": "app-components-table-StatusCell-___index__iconError___Bv-Ww"
};