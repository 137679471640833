import React, { Component } from 'react';
import PropTypes from 'prop-types';
import memoize from 'lodash/memoize';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { isLoading as isLoadingSelector } from 'utils/statusSelectors';
import previewHtmlSelector from 'shared/utils/questionnairePreviewSelector';
import { snakeToCamelCase } from 'shared/helpers/transforms';
import { PREVIEW } from 'shared/constants/questionnaireModes';
import proptypes from 'utils/proptypes';
import { Creators as QuestionnaireActions } from 'ducks/questionnaire/actions';
import Preview from './Preview';
import { Creators as ProgressBarActions } from '../ProgressBar/redux';

const createKey = memoize(answers => answers.map(a => a.details.value).join(''));

class PreviewContainer extends Component {
  static propTypes = {
    base64File: PropTypes.string,
    mode: PropTypes.string.isRequired,
    userQuestionnaireId: PropTypes.string.isRequired,
    isLeftToolbarVisible: PropTypes.bool.isRequired,
    setProgressBar: PropTypes.func.isRequired,
    fetchDocumentPdf: PropTypes.func.isRequired,
    onTogglePreview: PropTypes.func.isRequired,
    isPreviewVisible: PropTypes.bool.isRequired,
    answers: proptypes.answersScheme,
    isPreviewLoading: PropTypes.bool.isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    const answersCacheKey = createKey(props.answers);
    if (answersCacheKey !== state.answersCacheKey) {
      return {
        ...state,
        answersCacheKey,
      };
    }
    return null;
  }

  state = {
    open: false,
    answersCacheKey: '',
  };

  componentDidMount = () => {
    const { previewHtml } = this.props;
    const answersCacheKey = createKey(this.props.answers);
    this.setState({ answersCacheKey }, () => this.props.setProgressBar(previewHtml));
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextProps.previewHtml !== this.props.previewHtml) {
      return true;
    }

    return this.state.open !== nextState.open
    || this.state.answersCacheKey !== nextState.answersCacheKey
    || this.props.isPreviewLoading !== nextProps.isPreviewLoading
    || this.props.isPreviewVisible !== nextProps.isPreviewVisible;
  }

  componentDidUpdate = () => this.props.setProgressBar(this.props.previewHtml);

  toggleModal = () => this.setState(({ open }) => ({ open: !open }));

  fetchPdf = () => {
    const { userQuestionnaireId, fetchDocumentPdf } = this.props;
    fetchDocumentPdf(userQuestionnaireId, 'pdf');
  };

  render() {
    return (
      <Preview
        fetchPdf={this.fetchPdf}
        toggleModal={this.toggleModal}
        previewMode={this.props.mode === PREVIEW}
        onTogglePreview={this.props.onTogglePreview}
        isPreviewVisible={this.props.isPreviewVisible}
        isLeftToolbarVisible={this.props.isLeftToolbarVisible}
        open={this.state.open}
        answers={this.props.answers}
        previewLoading={this.props.isPreviewLoading}
        base64File={this.props.base64File}
        {...this.state}
        html={this.props.previewHtml} />
    );
  }
}

function mapStateToProps(state) {
  return {
    userQuestionnaireId: state.questionnaire.userQuestionnaire.id,
    previewHtml: previewHtmlSelector(state),
    answers: snakeToCamelCase(state.questionnaire.answers),
    base64File: state.questionnaire.previewDocumentPdf,
    isPreviewLoading: isLoadingSelector(state, 'appQuestionnaireFetchDocumentPreviewPdf') || false,
  };
}
const mapDispatchToProps = {
  setProgressBar: ProgressBarActions.setProgressBar,
  fetchDocumentPdf: QuestionnaireActions.fetchDocumentPreviewPdfRequest,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PreviewContainer);
