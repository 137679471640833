export function setSticky(container, stickyOffset) {
  if (container && container.offsetParent) {
    const topPart = container.querySelector('#mytoolbar');
    if (!topPart) return;
    const { left } = topPart.getBoundingClientRect();
    const editorPosition = container.getBoundingClientRect().top;

    if (isSticky(editorPosition, stickyOffset)) {
      container.style.paddingTop = `${topPart.offsetHeight}px`;
      topPart.style.bottom = null;
      topPart.style.top = `${stickyOffset}px`;
      topPart.style.left = `${left}px`;
      topPart.style.position = 'fixed';
      topPart.style.zIndex = '1';
      topPart.style.width = `${
        container.querySelector('.mce-content-body').clientWidth
      }px`;
    } else {
      container.style.paddingTop = 0;
      topPart.style.left = null;
      topPart.style.position = 'relative';
      topPart.style.top = null;
      topPart.style.width = null;
      topPart.style.borderBottom = null;
    }
  }
}

export function isSticky(editorPosition, offset) {
  return editorPosition < offset;
}

const plugin = (editor) => {
  const container = document.getElementById('editor-content');
  const stickyOffset = editor.settings.sticky_offset
    ? editor.settings.sticky_offset
    : 0;

  editor.on('init', setSticky.bind(null, container, stickyOffset));
  window.addEventListener('scroll', setSticky.bind(null, container, stickyOffset));
};

export default plugin;
