import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SelectedIcon from './SelectedIcon';

import styles from './index.css';

const ChoiceCard = ({
  className,
  children,
  checked,
  onChange,
  label,
  name,
  value,
  testId,
}) => {
  const classString = cn(styles.component, className);

  return (
    <label className={classString}>
      <div className={cn(styles.box, { [styles.boxChecked]: checked })}>
        {children}
        <input
          checked={checked}
          value={value}
          onChange={onChange}
          name={name}
          data-test-id={testId}
          className={styles.hiddenCheckbox}
          type="checkbox" />
        {checked && (
          <div className={styles.selectedIcon}>
            <SelectedIcon />
          </div>
        )}
      </div>
      <span className={styles.label}>{label}</span>
    </label>
  );
};

ChoiceCard.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
};

export default ChoiceCard;
