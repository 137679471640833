import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import FolderIcon from '@material-ui/icons/Folder';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import { EllipsisMenuButton } from 'components/EllipsisMenu';
import { RenameFolderModal } from 'shared/Components/FolderModal/RenameFolderModal';
import FolderMenu from './Menu';
import styles from './styles.css';

class FolderItem extends PureComponent {
  static propTypes = {
    folder: PropTypes.shape({
      templatesCount: PropTypes.number,
      name: PropTypes.string,
      id: PropTypes.string,
      folderSlug: PropTypes.string,
    }),
    hasAction: PropTypes.bool,
    updateFolderNameRequest: PropTypes.func,
    deleteFolder: PropTypes.func,
    getFolderSubheader: PropTypes.func,
    fetchFolderDetails: PropTypes.func,
    testId: PropTypes.string,
  };

  static defaultProps = { hasAction: true };

  state = {
    isMenuOpen: false,
    isRenameFolderModalOpen: false,
  };

  cardHeaderRef = React.createRef();

  componentDidMount() {
    this.cardHeaderDomNode = ReactDOM.findDOMNode(this.cardHeaderRef.current);
  }

  renameFolder = () => {
    this.setState({ isRenameFolderModalOpen: true, isMenuOpen: false });
  };

  closeRenameFolderModal = () => {
    this.setState({ isRenameFolderModalOpen: false });
  };

  handleClick = (event) => {
    event.stopPropagation();
    this.setState({ isMenuOpen: true });
  };

  handleClose = () => {
    this.setState({ isMenuOpen: false });
  };

  deleteFolder = () => {
    const { folder, deleteFolder } = this.props;
    deleteFolder(folder.id || folder.folderSlug);
  };

  fetchFolderDetails = () => {
    const { folder, fetchFolderDetails } = this.props;
    fetchFolderDetails(folder.id || folder.folderSlug);
  };

  render() {
    const { folder, updateFolderNameRequest, hasAction, getFolderSubheader, testId } = this.props;
    const { isMenuOpen, isRenameFolderModalOpen } = this.state;

    return (
      <Card elevation={0} classes={{ root: styles.cardRoot }}>
        <CardHeader
          classes={{
            action: styles.folderItemAction,
            content: styles.folderItemContent,
            subheader: styles.folderItemSubheader,
            title: styles.folderItemTitle,
          }}
          avatar={<FolderIcon className={styles.folderIcon} />}
          onClick={this.fetchFolderDetails}
          data-test-id={`${testId}-folderItemOpenFolder`}
          action={
            hasAction ? (
              <EllipsisMenuButton
                classes={{ label: styles.folderItemLabel }}
                onClick={this.handleClick}
                isOpen={isMenuOpen}
                testId={`${testId}-folderItemActions`}
                ref={this.cardHeaderRef} />
            ) : null
          }
          title={<span data-test-id={`${testId}-folderName`}>{folder.name}</span>}
          subheader={getFolderSubheader && <span data-test-id={`${testId}-templateCount`}>{getFolderSubheader(folder)}</span>} />
        <FolderMenu
          isOpen={isMenuOpen}
          domEl={this.cardHeaderDomNode}
          handleClose={this.handleClose}
          renameFolder={this.renameFolder}
          deleteFolder={this.deleteFolder}
          testIdPrefix={`${testId}-folderItemActions`} />
        <RenameFolderModal
          name={folder.name}
          folderId={folder.folderSlug || folder.id}
          isOpen={isRenameFolderModalOpen}
          onModalClose={this.closeRenameFolderModal}
          onSubmit={updateFolderNameRequest} />
      </Card>
    );
  }
}

export default FolderItem;
