import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './index.css';

function Spinner({ className, ...otherProps }) {
  return (
    <div {...otherProps} className={cn(className, styles.spinner)} data-test-id="App-spinnerLoader">
      <div className={styles.circle}>
        <div className={cn(styles.circle1, styles.child)} />
        <div className={cn(styles.circle2, styles.child)} />
        <div className={cn(styles.circle3, styles.child)} />
        <div className={cn(styles.circle4, styles.child)} />
        <div className={cn(styles.circle5, styles.child)} />
        <div className={cn(styles.circle6, styles.child)} />
        <div className={cn(styles.circle7, styles.child)} />
        <div className={cn(styles.circle8, styles.child)} />
        <div className={cn(styles.circle9, styles.child)} />
        <div className={cn(styles.circle10, styles.child)} />
        <div className={cn(styles.circle11, styles.child)} />
        <div className={cn(styles.circle12, styles.child)} />
      </div>
    </div>
  );
}

Spinner.propTypes = { className: PropTypes.string };

export default Spinner;
