import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Manager, Target, Popper } from 'react-popper';
import { injectIntl, intlShape } from 'react-intl';
import getInitials from 'utils/getInitials';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import { MenuList, MenuItem } from 'components/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from 'components/Typography';

import styles from './index.css';

class UserMenu extends PureComponent {
  static propTypes = {
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      avatar: PropTypes.string,
    }),
    goToProfileSettings: PropTypes.func,
    signOut: PropTypes.func,
    renderCustomMenu: PropTypes.func,
    intl: intlShape.isRequired,
  };

  static defaultProps = { renderCustomMenu: () => null };

  state = { open: false, userNameRef: undefined };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event) => {
    const { userNameRef } = this.state;

    if (userNameRef.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  goToProfileSettings = () => {
    this.setState({ open: false });
    this.props.goToProfileSettings();
  };

  render() {
    const { user, signOut, renderCustomMenu, intl } = this.props;
    const { open } = this.state;
    const fullName = `${user.firstName} ${user.lastName}`;

    return (
      <Fragment>
        <Manager>
          <Target>
            <div className={styles.userMenuWrapper}>
              {user.avatar ? (
                <img alt={fullName} src={user.avatar} className={styles.userAvatar} />
              ) : (
                <div className={styles.userAvatarPlaceholder}>{getInitials(user.firstName, user.lastName)}</div>
              )}
              <div
                className={styles.userName}
                ref={(el) => { this.setState({ userNameRef: el }); }}
                data-test-id="UserMenu-toggleButton"
                onClick={this.handleToggle}
                role="presentation">
                <Typography variant="label" className={styles.userMenuFullName}>
                  {fullName}
                </Typography>
                <ExpandMoreIcon classes={{ root: styles.userMenuExpandMore }} />
              </div>
            </div>
          </Target>
          <Popper
            style={{
              zIndex: 10,
              marginTop: '5px',
              pointerEvents: open ? '' : 'none',
            }}
            placement="bottom-end"
            positionFixed
            eventsEnabled={open}>
            <ClickAwayListener onClickAway={this.handleClose}>
              <Grow in={open} style={{ transformOrigin: '0 0 0' }}>
                <Paper>
                  <MenuList role="menu" disablePadding>
                    <MenuItem data-test-id="UserMenu-profileLink" onClick={this.goToProfileSettings} dense>
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText
                        inset
                        primary={intl.formatMessage({ id: 'top_navbar.profile_settings' })}
                        classes={{ root: styles.listItemText }} />
                    </MenuItem>
                    <Divider />
                    {renderCustomMenu({ close: this.handleClose })}
                    <MenuItem data-test-id="UserMenu-signoutButton" onClick={signOut} dense>
                      <ListItemIcon>
                        <ExitIcon />
                      </ListItemIcon>
                      <ListItemText
                        inset
                        primary={intl.formatMessage({ id: 'top_navbar.log_out' })}
                        classes={{ root: styles.listItemText }} />
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Grow>
            </ClickAwayListener>
          </Popper>
        </Manager>
      </Fragment>
    );
  }
}

export default injectIntl(UserMenu);
