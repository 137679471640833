import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowDown from './ArrowDown';
import styles from './ProgressBar.css';

const ProgressBar = ({ isHidden, percentFinished, isLeftToolbarVisible, onProgressClick, className }) => (
  <div className={cn(styles.component, className)}>
    {!isHidden && <LinearProgress variant="determinate" value={percentFinished} classes={{ bar1Determinate: styles.progressBar }} />}
    <ArrowDown onClick={onProgressClick} isListOpen={isLeftToolbarVisible} />
  </div>
);

ProgressBar.propTypes = {
  percentFinished: PropTypes.number.isRequired,
  onProgressClick: PropTypes.func.isRequired,
  isLeftToolbarVisible: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default ProgressBar;
