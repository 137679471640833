export default class Response {
  constructor(initialValues = {}) {
    const defaultValues = {
      data: undefined,
      headers: {},
      status: 200,
      statusText: 'OK',
      url: undefined,
      config: {},
      request: {},
    };
    Object.assign(this, defaultValues, initialValues);
  }
}
