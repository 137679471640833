exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-Logo-___index__component___XwsBU {\n  background-image: url('/assets/lexolve-logo-digital-positive.svg');\n  background-repeat: no-repeat;\n  background-size: cover;\n  width: 100px;\n  height: 32px;\n}\n", "", {"version":3,"sources":["/workspace/app/components/Logo/index.css"],"names":[],"mappings":"AAAA;EACE,mEAAkE;EAClE,6BAA4B;EAC5B,uBAAsB;EACtB,aAAY;EACZ,aAAY;CACd","file":"index.css","sourcesContent":[".component {\n  background-image: url('/assets/lexolve-logo-digital-positive.svg');\n  background-repeat: no-repeat;\n  background-size: cover;\n  width: 100px;\n  height: 32px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-components-Logo-___index__component___XwsBU"
};