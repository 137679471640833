import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Provider as SlotProvider } from 'react-slot-fill';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import assignWith from 'lodash/assignWith';
import partialRight from 'lodash/partialRight';

import { appTypes } from 'appRoot/constants';
import { EN_LANG } from 'constants/language';
import NotificationSystem from 'shared/NotificationSystem';

import 'styles/main.css';
import styles from './index.css';

import { Creators } from './actions';
import mapStateToProps from './selectors';
import LoggingService from '../../modules/LoggingService';

/**
 * This class is the bootstrap controller of the app.
 * It decides whether the regular app or white label white label
 * be initialised.
 * This container also controls all non-redux react components
 *
 * @extends React.PureComponent
 */
export class AppComponent extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    initApp: PropTypes.func.isRequired,
    appType: PropTypes.oneOf([appTypes.DEFAULT, appTypes.WHITE_LABEL, appTypes.GENERIC_ACCESS]),
    loggingReporter: PropTypes.instanceOf(LoggingService).isRequired,
    materialTheme: PropTypes.instanceOf(Object).isRequired,
    appTheme: PropTypes.instanceOf(Object).isRequired,
    translations: PropTypes.instanceOf(Object).isRequired,
    userLanguage: PropTypes.string.isRequired,
    browserLocale: PropTypes.string.isRequired,
    appInitialized: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.initApp(this.props.appType);
  }

  componentDidCatch(error) {
    this.props.loggingReporter.log(error);
  }

  render() {
    const { appTheme, children, materialTheme, translations, userLanguage, browserLocale, appInitialized } = this.props;
    const defaultLocale = EN_LANG;
    const locale = userLanguage || browserLocale;
    const defaults = partialRight(assignWith, (objVal, srcVal) => (objVal === null || objVal === '' ? srcVal : objVal));
    const messages = defaults(translations[locale], translations[defaultLocale]);

    return (
      <ThemeProvider theme={appTheme}>
        <MuiThemeProvider theme={materialTheme}>
          <SlotProvider>
            <IntlProvider
              locale={locale}
              defaultLocale={defaultLocale}
              messages={messages}
              key={locale}>
              <main className={styles.app}>
                <NotificationSystem />
                {appInitialized && children}
              </main>
            </IntlProvider>
          </SlotProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = { initApp: Creators.initApp };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AppComponent);
