import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import proptypes from 'utils/proptypes';
import PageSpinner from 'components/PageSpinner';
import Modal, { ModalContent } from 'components/Modal';
import { legacyMedia } from 'shared/styled-helpers';

import DocumentPreview from './DocumentPreview';
import AddQuestionColorsToPreview from './AddQuestionColorsToPreview';
import PreviewHeader from './PreviewHeader';

const Styled = styled.div`
  background-color: #f9fafb;
  display: flex;
  flex-direction: column;
  transition: all 0.6s ease-in;
  max-height: 100vh;
  max-width: 50%;
  width: 50%;

  ${({ isVisible }) => isVisible === false
    && `
    transform: translateX(100%) translateX(-90px);
    background-color: #fff;
  `};

  ${legacyMedia.sm`display: none;`};
`;

class Preview extends Component {
  static propTypes = {
    className: PropTypes.string,
    base64File: PropTypes.string,
    fetchPdf: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    html: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    previewLoading: PropTypes.bool.isRequired,
    previewMode: PropTypes.bool.isRequired,
    isPreviewVisible: PropTypes.bool.isRequired,
    isLeftToolbarVisible: PropTypes.bool.isRequired,
    onTogglePreview: PropTypes.func.isRequired,
    currentQuestion: proptypes.questionScheme,
    answers: proptypes.answersScheme,
  };

  componentDidUpdate() {
    const { currentQuestion } = this.props;
    const $currentQuestionEl = document.querySelector(
      `[data-question-id="${currentQuestion.id}"]`,
    );

    if ($currentQuestionEl) {
      const $container = $('#document-template');
      const uniqId = new Date().valueOf();
      $currentQuestionEl.id = uniqId;
      const targetOffset = $(`#${uniqId}`).offset().top
        - $container.offset().top
        + $container.scrollTop()
        - 100;
      $container.animate({ scrollTop: targetOffset }, 500);
    }
  }

  render() {
    const {
      className,
      toggleModal,
      html,
      open,
      fetchPdf,
      previewLoading,
      base64File,
      previewMode,
      onTogglePreview,
      isPreviewVisible,
      isLeftToolbarVisible,
      currentQuestion,
      answers,
    } = this.props;
    return (
      <Styled
        data-test-id="preview"
        isVisible={isPreviewVisible}
        isLeftToolbarVisible={isLeftToolbarVisible}>
        <PreviewHeader
          isPreviewVisible={isPreviewVisible}
          onTogglePreview={onTogglePreview}
          className={className}
          toggleModal={toggleModal}
          previewMode={previewMode} />
        <AddQuestionColorsToPreview
          currentQuestion={currentQuestion}
          answers={answers} />
        <DocumentPreview html={html} isVisible={isPreviewVisible} />
        <Modal
          size="big"
          toggleModal={toggleModal}
          open={open}
          long
          onOpen={fetchPdf}
          modalTitle={
            <FormattedMessage id="questionnaire.preview.modal.header" />
          }>
          <ModalContent fullHeight>
            {previewLoading && <PageSpinner />}
            <iframe
              title="preview"
              src={`data:application/pdf;base64,${base64File}`}
              width="100%"
              height="100%" />
          </ModalContent>
        </Modal>
      </Styled>
    );
  }
}

const mapStateToProps = state => ({ currentQuestion: state.questionnaire.currentQuestion });

export default compose(connect(mapStateToProps))(Preview);
