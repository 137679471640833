/**
 *
 * Helpers for Redux Sagas
 *
 */

import assert from 'assert';

/** @function runSagas
 *
 * Takes an array of sagas and runs them each through `store.runSaga`
 *
 * @param {ReduxStore} store - Redux store object.
 * @param {Array<{}>} sagaArr - Array of sagas.
 * @return undefined
 */
export function runSagas(store, sagaArr) {
  assert.ok(store, 'store<object> is required');
  assert.ok(Array.isArray(sagaArr), 'sagaArr<array> is required');

  sagaArr.forEach((sagaObj) => {
    Object.values(sagaObj).forEach((saga) => {
      store.runSaga(saga);
    });
  });
}
