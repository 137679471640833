import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import SettingsIcon from '@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import PublishTemplate from 'shared/PublishUnpublish/PublishTemplate';
import { getTemplateDetailEditPath, getTemplatesListPath } from 'MainApp/routesPaths';
import { StyledFlexCenter } from './styled-components';

const Buttons = ({
  editorIsSaving,
  templateVersionId,
  templateHasDrafts,
  templateVersionChangeNote,
  push,
  templateId,
  onClickSettings,
}) => (
  <StyledFlexCenter>
    <Tooltip title={<FormattedMessage id="api.panel.questionnaire_templates.actions.menu_template_settings" />}>
      <IconButton
        onClick={onClickSettings}
        testId="TopToolbar-templateSettings">
        <SettingsIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title={<FormattedMessage id="api.panel.questionnaire_templates.actions.menu_template_information" />}>
      <IconButton
        component={Link}
        testId="TopToolbar-templateInfo"
        to={`/templates/${templateId}`}>
        <InfoIcon />
      </IconButton>
    </Tooltip>
    <PublishTemplate
      templateVersionChangeNote={templateVersionChangeNote}
      templateVersionId={templateVersionId}
      redirectTo={() => push(getTemplatesListPath())} />
    <Button
      testId="edit-draft"
      onClick={() => push(getTemplateDetailEditPath(templateId))}
      disabled={editorIsSaving}>
      {templateHasDrafts
        ? (<FormattedMessage id="visualization.top_toolbar.edit_draft" />)
        : (<FormattedMessage id="visualization.top_toolbar.create_draft" />)
      }
    </Button>
  </StyledFlexCenter>
);

Buttons.propTypes = {
  editorIsSaving: PropTypes.bool,
  templateVersionId: PropTypes.string.isRequired,
  templateVersionChangeNote: PropTypes.string,
  templateHasDrafts: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
};

export default Buttons;
