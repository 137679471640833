import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import Note from '@material-ui/icons/Note';

import { EN_LANG, NB_LANG } from 'shared/constants/languages';
import PageHeader from 'components/PageHeader';
import Button, { LINK } from 'components/Button';
import UserProfileForm from './UserProfileForm';
import styles from './styles.css';

const UserProfileView = ({
  changeLanguage,
  changePassword,
  changePersonalInformation,
  isUpdatingUser,
  userPersonalInformation,
  userLanguage,
}) => (
  <React.Fragment>
    <PageHeader
      title={<FormattedMessage id="user_profile.user_profile_header.account_settings" />}
      subtitle={<FormattedMessage id="user_profile.user_profile_header.subheading" />}
      iconElement={<Note />}>
      <React.Fragment>
        <Button
          className={cn(styles.languageSelector, { [styles.languageUnselected]: userLanguage !== EN_LANG })}
          testId="UserProfile--switchLanguage--en"
          variant={LINK}
          onClick={() => changeLanguage(EN_LANG)}>
          EN
        </Button>
        |
        <Button
          className={cn(styles.languageSelector, { [styles.languageUnselected]: userLanguage !== NB_LANG })}
          testId="UserProfile--switchLanguage--no"
          variant={LINK}
          onClick={() => changeLanguage(NB_LANG)}>
          NO
        </Button>
      </React.Fragment>
    </PageHeader>
    <UserProfileForm
      changePassword={changePassword}
      changePersonalInformation={changePersonalInformation}
      isUpdatingUser={isUpdatingUser}
      userPersonalInformation={userPersonalInformation} />
  </React.Fragment>
);

UserProfileView.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  changePersonalInformation: PropTypes.func.isRequired,
  isUpdatingUser: PropTypes.bool.isRequired,
  userLanguage: PropTypes.string.isRequired,
  userPersonalInformation: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default UserProfileView;
