import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { FormattedMessage } from 'react-intl';
import _flow from 'lodash/flow';
import { SENT_FOR_SIGNING, SIGNING_REQUESTED, ERROR, EXPIRED } from 'shared/constants/questionnaireStatus';
import DateCell from 'components/table/DateCell';
import MoveToFolderDialog from 'shared/MoveToFolderDialog';
import StatusCell from 'components/table/StatusCell';
import OwnerCell from 'components/table/OwnerCell';
import DuplicateDocumentModal from 'containers/DuplicateDocument';
import ActionCell from 'components/table/ActionCell';
import DocumentCell from '../TableCells/DocumentCell';
import DeleteDocumentConfirmationModal from './DeleteDocumentConfirmationModal';

class DocumentTableRow extends PureComponent {
  static propTypes = {
    document: PropTypes.shape({
      id: PropTypes.string.isRequired,
      trashableId: PropTypes.string,
      name: PropTypes.string,
      ownerType: PropTypes.string,
      status: PropTypes.string,
    }).isRequired,
    moveToTrash: PropTypes.func.isRequired,
    goToRoute: PropTypes.func,
    folderId: PropTypes.string,
    currentViewFolder: PropTypes.shape({ id: PropTypes.string }).isRequired,
    currentFolder: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      root: PropTypes.bool,
    }).isRequired,
    moveToFolder: PropTypes.func,
    goToFolderRequest: PropTypes.func.isRequired,
    folders: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })),
  };

  state = {
    isDeleteDocumentModalOpen: false,
    isDuplicateDocumentModalOpen: false,
    isMoveToFolderModalOpen: false,
  };

  testId = 'Documents-actions';

  goToDocument = () => {
    const { document, goToRoute } = this.props;
    goToRoute(`/documents/${document.slug}`);
  };

  toggleDeleteDocumentModal = () => {
    this.setState(prevState => ({ isDeleteDocumentModalOpen: !prevState.isDeleteDocumentModalOpen }));
  };

  onDocumentDelete = () => {
    if ([SENT_FOR_SIGNING, SIGNING_REQUESTED, ERROR, EXPIRED].includes(this.props.document.state)) {
      this.setState({ isDeleteDocumentModalOpen: true });
    } else {
      this.moveToTrash();
    }
  };

  moveToTrash = () => {
    const documentId = this.props.document.slug || this.props.document.id;
    this.props.moveToTrash(documentId);
    this.setState({ isDeleteDocumentModalOpen: false });
  };

  openMoveToFolderModal = () => {
    this.setState({ isMoveToFolderModalOpen: true });
  }

  closeMoveToFolderModal = () => {
    this.setState({ isMoveToFolderModalOpen: false });
  }

  moveToFolder = () => {
    const { document, currentFolder, currentViewFolder } = this.props;
    this.props.moveToFolder(document.slug, currentFolder.id || currentFolder.folderSlug, currentViewFolder.folderSlug);
  }

  openDuplicateDocumentModal = () => {
    this.setState({ isDuplicateDocumentModalOpen: true });
  }

  closeDuplicateDocumentModal = () => {
    this.setState({ isDuplicateDocumentModalOpen: false });
  }

  getMenuItems = ({ closeMenu }) => {
    const { duplicateDocumentEnabled, moveToFolder } = this.props;

    const menuItems = [];

    if (duplicateDocumentEnabled) {
      menuItems.push({
        dataTestId: `${this.testId}-duplicate`,
        onClick: _flow(
          this.openDuplicateDocumentModal,
          closeMenu,
        ),
        icon: <FileCopyIcon />,
        divider: false,
        text: <FormattedMessage id="api.panel.questionnaire_templates.actions.duplicate" />,
      });
    }
    if (moveToFolder) {
      menuItems.push({
        dataTestId: `${this.testId}-moveTo`,
        onClick: _flow(
          this.openMoveToFolderModal,
          closeMenu,
        ),
        icon: <FolderIcon />,
        divider: true,
        text: <FormattedMessage id="api.panel.questionnaire_templates.actions.menu_move_to" />,
      });
    }
    menuItems.push({
      dataTestId: `${this.testId}-moveToTrash`,
      icon: <DeleteIcon />,
      onClick: _flow(
        this.moveToTrash,
        closeMenu,
      ),
      text: <FormattedMessage id="api.panel.questionnaire_templates.actions.trash" />,
    });

    return menuItems;
  }

  render() {
    const { document, folderId, currentFolder, currentViewFolder, folders, goToFolderRequest } = this.props;
    const {
      isDeleteDocumentModalOpen,
      isDuplicateDocumentModalOpen,
      isMoveToFolderModalOpen,
    } = this.state;
    return (
      <TableRow hover data-test-id="Documents-row">
        <TableCell width="35" onClick={this.goToDocument} data-test-id="Documents-data">
          <DocumentCell document={document} folderId={folderId} />
        </TableCell>
        <TableCell width="10%">
          <DateCell value={document.created} />
        </TableCell>
        <TableCell width="10%">
          <DateCell value={document.updated} />
        </TableCell>
        <TableCell width="15%" data-test-id="Documents-row-status">
          <StatusCell value={document.state} />
        </TableCell>
        <TableCell width="28%">
          <OwnerCell owner={document.owner} />
        </TableCell>
        <TableCell>
          <ActionCell
            getMenuItems={this.getMenuItems}
            testId={this.testId} />
        </TableCell>
        {isDeleteDocumentModalOpen
        && (
        <DeleteDocumentConfirmationModal
          open={isDeleteDocumentModalOpen}
          toggleModal={this.toggleDeleteDocumentModal}
          deleteDocument={this.moveToTrash} />
        )}
        {isMoveToFolderModalOpen && (
        <MoveToFolderDialog
          isOpen={isMoveToFolderModalOpen}
          onClose={this.closeMoveToFolderModal}
          moveToFolder={this.moveToFolder}
          currentFolder={currentFolder}
          folders={folders}
          goToFolder={goToFolderRequest}
          currentViewFolder={currentViewFolder} />
        )}
        {isDuplicateDocumentModalOpen && (
        <DuplicateDocumentModal
          documentName={document.name}
          documentId={document.slug || document.id}
          toggleModal={this.closeDuplicateDocumentModal}
          open={isDuplicateDocumentModalOpen}
          basedOnLatestTemplateVersion={document.basedOnLatestTemplateVersion}
          isSubmitting={document.basedOnLatestTemplateVersion} />
        )}
      </TableRow>
    );
  }
}

export default DocumentTableRow;
