import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageSpinner from 'components/PageSpinner';
import styles from './DocumentPreview.css';

export default class DocumentPreview extends Component {
  static propTypes = {
    previewLoading: PropTypes.bool.isRequired,
    previewUrl: PropTypes.string,
  };

  render() {
    return (
      <div className={styles.component}>
        {this.props.previewLoading && <PageSpinner />}
        <iframe
          title="preview"
          src={`${this.props.previewUrl}`}
          width="100%"
          height="100%" />
      </div>
    );
  }
}
