import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import proptypes from 'utils/proptypes';
import OwnerCell from 'components/table/OwnerCell';
import DateCell from 'components/table/DateCell';
import Button, { SECONDARY, TINY } from 'components/Button';
import PublishUnpublishToggle from 'shared/PublishUnpublish/PublishUnpublishToggle';
import ButtonGroup from '../../ButtonGroup';

class TemplateTableRow extends PureComponent {
  static propTypes = {
    templateVersion: proptypes.templateVersionScheme.isRequired,
    templateId: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    unpublishTemplate: PropTypes.func.isRequired,
    publishTemplate: PropTypes.func.isRequired,
    editRoute: PropTypes.string.isRequired,
    previewRoute: PropTypes.string.isRequired,
  };

  state = { isHovered: false };

  onMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const {
      templateVersion,
      templateId,
      index,
      editRoute,
      previewRoute,
      unpublishTemplate,
      publishTemplate,
    } = this.props;

    const { isHovered } = this.state;
    const canEdit = templateVersion.status.match(/^draft|^unpublished/);
    const canCreateDraft = index === 0 && templateVersion.status === 'published';
    const owner = {
      ...templateVersion.user,
      role: templateVersion.user.roles[0],
    };
    return (
      <TableRow key={templateVersion.id} hover onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} data-test-id="TemplateHistoryTableRow-row">
        <TableCell width="15%" data-test-id="TemplateHistoryTableRow-date">
          <DateCell value={templateVersion.createdAt} />
        </TableCell>
        <TableCell width="10%" data-test-id="TemplateHistoryTableRow-version">{templateVersion.version}</TableCell>
        <TableCell width="15%">
          <PublishUnpublishToggle
            templateId={templateId}
            templateVersionId={templateVersion.id}
            templateStatus={templateVersion.status}
            templateVersionChangeNote={templateVersion.changeNote}
            unpublishTemplate={unpublishTemplate}
            publishTemplate={publishTemplate} />
        </TableCell>
        <TableCell width="25%">
          {templateVersion.changeNote}
          {isHovered && (
            <ButtonGroup>
              <Link to={previewRoute} data-test-id="TemplatesInformation-view">
                <Button variant={SECONDARY} size={TINY} testId="Templates-viewDraft">
                  <FormattedMessage id="panel.questionnaire_templates.show.view" />
                </Button>
              </Link>
              {canCreateDraft && (
                <Link to={editRoute}>
                  <Button size={TINY} testId="Templates-createDraft">
                    <FormattedMessage id="panel.questionnaire_templates.show.create_draft" />
                  </Button>
                </Link>
              )}
              {canEdit && (
                <Link to={editRoute} data-test-id="TemplatesInformation-edit">
                  <Button variant={SECONDARY} size={TINY} testId="Templates-edit">
                    <FormattedMessage id="panel.questionnaire_templates.show.edit" />
                  </Button>
                </Link>
              )}
            </ButtonGroup>
          )}
        </TableCell>
        <TableCell width="35%">
          <OwnerCell owner={owner} />
        </TableCell>
      </TableRow>
    );
  }
}

export default TemplateTableRow;
