import { createReducer } from 'reduxsauce';
import _findIndex from 'lodash/findIndex';
import update from 'immutability-helper';
import { Types } from './actions';
import { ROOT_DIR } from './constants';

export const INITIAL_STATE = {
  documents: [],
  count: 0,
  folders: [],
  _links: {},
  currentFolder: ROOT_DIR,
  duplicateDocumentEnabled: false,
};

const setFolders = (state, { folders }) => update(state, { folders: { $set: folders } });

const updateFolderName = (state, { folderId, folderName }) => {
  const folderIndex = _findIndex(state.folders, f => f.folderSlug === folderId || f.id === folderId);
  return update(state, { folders: { [folderIndex]: { $merge: { name: folderName } } } });
};

const addFolder = (state, { folder }) => update(state, { folders: { $unshift: [folder] } });

const deleteFolder = (state, { folderId }) => {
  const folderIndex = _findIndex(state.folders, f => f.folderSlug === folderId || f.id === folderId);

  return update(state, { folders: { $splice: [[folderIndex, 1]] } });
};

const deleteDocumentById = (state, { id: documentId }) => {
  const documentIndex = _findIndex(state.documents, doc => doc.slug === documentId);

  return update(state, { documents: { data: { $splice: [[documentIndex, 1]] } } });
};

const fetchDocumentsSuccess = (state, { data }) => update(state, {
  documents: { $set: data.documents },
  count: { $set: data.count },
  folders: { $set: data.folders },
  currentFolder: { $set: data.currentFolder },
  duplicateDocumentEnabled: { $set: data.duplicateDocumentEnabled },
  _links: { $set: data._links },
});

const searchDocumentsSuccess = (state, { data }) => update(state, {
  documents: { $set: data.documents },
  count: { $set: data.count },
  folders: { $set: data.folders },
  currentFolder: { $set: data.currentFolder },
  duplicateDocumentEnabled: { $set: data.duplicateDocumentEnabled },
});


export default createReducer(INITIAL_STATE, {
  [Types.FETCH_DOCUMENTS_SUCCESS]: fetchDocumentsSuccess,
  [Types.DELETE_DOCUMENT_SUCCESS]: deleteDocumentById,
  [Types.SEARCH_DOCUMENTS_SUCCESS]: searchDocumentsSuccess,

  [Types.FETCH_FOLDERS_SUCCESS]: setFolders,
  [Types.CREATE_DOCUMENT_FOLDER_SUCCESS]: addFolder,
  [Types.UPDATE_FOLDER_NAME_SUCCESS]: updateFolderName,
  [Types.DELETE_FOLDER_SUCCESS]: deleteFolder,
});
