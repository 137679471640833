import { createActions } from 'reduxsauce';

export const REDUX_NAMESPACE = 'app/DocumentDetails/';
export const REDUXACTIONS_STRUCTURE = {
  fetchDocumentDetailsRequest: ['documentId', 'mode'],
  fetchDocumentDetailsSuccess: ['documentId', 'data'],
  fetchDocumentDetailsFailure: ['error'],

  fetchDocumentVersionRequest: ['version'],
  fetchDocumentVersionSuccess: ['version'],
  fetchDocumentVersionFailure: ['error'],

  updateCurrentVersionContentRequest: ['content', 'version'],
  updateCurrentVersionContentSuccess: ['version'],
  updateCurrentVersionContentFailure: ['error'],

  updateDocumentVersionNameRequest: ['name', 'version'],
  updateDocumentVersionNameSuccess: ['version'],
  updateDocumentVersionNameFailure: ['error'],

  saveDocumentVersionRequest: ['documentId', 'versionName', 'version'],
  saveDocumentVersionSuccess: ['version'],
  saveDocumentVersionFailure: ['error'],

  createDocumentVersionRequest: ['documentId', 'name', 'html'],
  createDocumentVersionSuccess: ['version'],
  createDocumentVersionFailure: ['error'],

  downloadSignedDocumentPdfRequest: ['documentType', 'documentId'],
  downloadSignedDocumentPdfSuccess: null,
  downloadSignedDocumentPdfFailure: ['error'],

  cancelDocumentVersionSigningRequest: ['documentId'],
  cancelDocumentVersionSigningSuccess: null,
  cancelDocumentVersionSigningFailure: ['error'],

  fetchDocumentErrorsRequest: ['documentId', 'documentType'],
  fetchDocumentErrorsSuccess: ['documentErrors'],
  fetchDocumentErrorsFailure: ['error'],

  addQuestionnaireToDocumentVersions: ['questionnaire'],
  changeCurrentDocumentVersion: ['revision'],
  updateDocumentName: ['name'],
  clearDocumentDetails: null,
};

export const { Types, Creators } = createActions(REDUXACTIONS_STRUCTURE, { prefix: REDUX_NAMESPACE });
