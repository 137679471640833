import React from 'react';
import PropTypes from 'prop-types';

import PageHeaderView from './components/PageHeaderView';

const PageHeader = ({ children, iconElement, subtitle, title }) => (
  <PageHeaderView
    iconElement={iconElement}
    subtitle={subtitle}
    title={title}>
    {children}
  </PageHeaderView>
);

PageHeader.propTypes = {
  children: PropTypes.node,
  iconElement: PropTypes.element.isRequired,
  subtitle: PropTypes.node,
  title: PropTypes.node,
};

export default PageHeader;
