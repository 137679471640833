/* eslint-disable no-param-reassign */
export default function calculateHeight(html, data) {
  const ifrm = document.createElement('iframe');
  ifrm.id = 'document-template-iframe';
  document.body.appendChild(ifrm);
  const iframeDocument = ifrm.contentWindow.document;
  const temp1mmHeight = document.createElement('div');
  const a4page = document.createElement('div');
  const styles = document.createElement('style');
  const pxToMm = px => Math.ceil(px / temp1mmHeight.offsetHeight);
  setupStyles(iframeDocument, styles, data);
  setupHeightElement(iframeDocument, temp1mmHeight);
  setupA4Page(iframeDocument, a4page, data, html);
  const height = a4page.offsetHeight;
  const heightInMM = pxToMm(height) * 1.8;

  ifrm.remove();
  return heightInMM;
}

function setupA4Page(iframeDocument, a4page, data, html) {
  // we create a4 page and fill it with text so we have 1:1 representation of that header/footer
  a4page.id = 'document-template-header';
  a4page.style.cssText = `
      width: 21cm;
      opacity: 0;
      position: fixed;
      top:0;
      left: 0;
      background: red;
      padding-left: ${data.margin_left}mm;
      padding-right: ${data.margin_right}mm;
      padding-top: 8px;
      padding-bottom: 0;
      margin: 0;`;
  a4page.innerHTML = html;
  iframeDocument.body.appendChild(a4page);
}

function setupStyles(iframeDocument, styles, data) {
  styles.type = 'text/css';
  styles.innerHTML = data.stylesheet;
  iframeDocument.head.appendChild(styles);
}

function setupHeightElement(iframeDocument, temp1mmHeight) {
  temp1mmHeight.style.cssText = 'height: 1mm; opacity: 0';
  iframeDocument.body.appendChild(temp1mmHeight);
}
