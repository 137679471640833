import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { createClassNameModifiers } from 'utils/componentUtils';
import proptypes from 'utils/proptypes';
import Input from '@material-ui/core/Input';
import Typography, { COLORS, VARIANTS } from 'components/Typography';

import styles from './index.css';

export const ERROR = 'error';

export default class InputText extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    inputMultilineClassName: PropTypes.string,
    rootClassName: PropTypes.string,
    underlineClassName: PropTypes.string,
    endAdornment: PropTypes.node,
    startAdornment: PropTypes.node,
    errorMessage: PropTypes.node,
    inputRef: PropTypes.func,
    isDisabled: PropTypes.bool,
    modifiers: proptypes.arrayOfModifiers([ERROR]),
    testId: PropTypes.string.isRequired,
    autoComplete: proptypes.autocomplete,
  };

  static defaultProps = {
    modifiers: [],
    autoComplete: 'off',
  };

  render() {
    const {
      className,
      isDisabled,
      inputRef,
      endAdornment,
      startAdornment,
      modifiers,
      errorMessage,
      inputClassName,
      multilineClassName,
      inputMultilineClassName,
      rootClassName,
      underlineClassName,
      testId,
      autoComplete,
      value,
      ...otherProps
    } = this.props;

    const formattedModifiers = createClassNameModifiers({
      modifiers: modifiers.concat(
        isDisabled ? ['disabled'] : [],
        errorMessage ? ['error'] : [],
      ),
      styles,
    });

    const classString = cn(styles.component, ...formattedModifiers, className);
    const rootBorderClassName = value && !errorMessage ? styles.rootFilledInputBorder : styles.rootDefaultBorder;

    return (
      <div className={classString}>
        <Input
          type="text"
          fullWidth
          {...otherProps}
          endAdornment={endAdornment ? <span className={styles.endAdornment}>{endAdornment}</span> : undefined}
          startAdornment={startAdornment ? <span className={styles.startAdornment}>{startAdornment}</span> : undefined}
          disabled={isDisabled}
          disableUnderline={isDisabled}
          error={!!errorMessage}
          autoComplete={autoComplete}
          value={value}
          classes={{
            root: cn(styles.root, rootBorderClassName, rootClassName),
            input: cn(styles.input, inputClassName),
            multiline: multilineClassName,
            inputMultiline: cn(styles.inputMultiline, inputMultilineClassName),
            inputType: styles.inputType,
            disabled: styles.inputDisabled,
            focused: styles.focused,
            error: styles.error,
            underline: cn(styles.underline, underlineClassName),
          }}
          inputProps={{ 'data-test-id': testId }}
          inputRef={inputRef} />
        {errorMessage && (
          <Typography variant={VARIANTS.LABEL} color={COLORS.ERROR} className={styles.errorMessage}>
            {errorMessage}
          </Typography>
        )}
      </div>
    );
  }
}
