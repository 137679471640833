import React from 'react';
import PropTypes from 'prop-types';
import MuiMenuList from '@material-ui/core/MenuList';

const MenuList = ({ subheader, children, ...otherProps }) => (
  <MuiMenuList subheader={subheader} {...otherProps}>
    {children}
  </MuiMenuList>
);

MenuList.propTypes = {
  subheader: PropTypes.node,
  children: PropTypes.node,
};

export default MenuList;
