import assert from 'assert';

import ApiClient from '../ApiClient';

export default class AnswerService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  /**
   * Saves or updates an answer to a questionnaire
   *
   * @param {object} obj
   * @param {string} obj.questionnaireId
   * @param {Answer} obj.answer - an Answer instance
   */
  save({ questionnaireId, answer } = {}) {
    assert.ok(questionnaireId, 'questionnaireId<string> is required');
    assert.ok(answer, 'answer is required');

    return this.apiClient.request('/answers', {
      method: 'patch',
      data: {
        answer,
        user_questionnaire_id: questionnaireId,
      },
    });
  }
}
