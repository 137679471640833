import React from 'react';

export default function NoAccess(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 334.929 241.036"
      width="334.929"
      height="241.036"
      {...props}>
      <path
        fill="currentColor"
        d="M334.929 210.323c0 16.963-74.973 30.713-167.465 30.713C74.979 241.036 0 227.286 0 210.323c0-16.959 74.979-30.713 167.464-30.713 92.494 0 167.465 13.754 167.465 30.713z"
        opacity=".08" />
      <path
        fill="currentColor"
        d="M230.804 105.507l-3.582-.588v-15.59c0-28.835-24.621-56.958-55.395-62.515-.842-.152-2.521-.428-3.363-.55-12.455-1.833-23.902.363-33.117 5.537 9.284-2.535 18.952-3.335 27.692-1.997.808.123 2.422.398 3.229.549 29.562 5.553 53.208 33.653 53.208 62.465v15.577l3.445.587c5.307.904 9.633 6.937 9.633 13.472v78.133c0 2.676-.734 5.018-1.957 6.801l.207.033c5.525.906 10.031-3.701 10.031-10.236V118.99c0-6.542-4.505-12.579-10.031-13.483z"
        opacity=".15" />
      <path
        fill="currentColor"
        d="M214.175 112.685l-3.422-.604V96.063c0-29.625-23.51-58.52-52.896-64.229-.803-.156-2.407-.439-3.21-.565-29.386-4.653-52.895 15.95-52.895 45.575v16.018l-3.424-.604c-5.273-.929-9.576 3.753-9.576 10.476v80.338c0 6.715 4.303 12.967 9.577 13.896l115.845 20.426c5.273.932 9.576-3.803 9.576-10.518v-80.338c.001-6.721-4.299-12.924-9.575-13.853zm-47.07 43.952v24.275c0 2.779-2.325 4.721-5.107 4.23l-11.488-2.027c-2.781-.488-5.11-3.252-5.11-6.031v-24.275c-2.697-3.131-4.268-7.082-4.268-11.145 0-7.697 5.95-13.264 13.517-12.234.803.109 2.408.391 3.211.564 7.565 1.641 13.516 9.307 13.516 17.004-.001 4.063-1.572 7.46-4.271 9.639zm21.092-48.533l-30.339-5.35-3.21-.565-30.339-5.35V80.82c0-17.647 14.344-29.71 31.944-26.606 17.6 3.104 31.943 20.224 31.943 37.872v16.018h.001z"
        opacity=".15" />
      <linearGradient id="a" x1="163.293" x2="160.918" y1="177.575" y2="170.45" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="currentColor" stopOpacity=".4" />
        <stop offset="1" stopColor="currentColor" stopOpacity=".9" />
      </linearGradient>
      <path
        fill="url(#a)"
        d="M166.438 156.303v24.275c0 2.781-2.325 4.721-5.107 4.23l-11.488-2.025c-2.781-.49-5.11-3.252-5.11-6.033v-24.275c-2.697-3.131-4.268-7.08-4.268-11.145 0-7.697 5.95-13.264 13.517-12.234.803.109 2.408.392 3.211.565 7.565 1.64 13.517 9.306 13.517 17.003-.003 4.063-1.574 7.46-4.272 9.639z" />
      <defs>
        <path
          id="b"
          d="M166.438 156.303v24.275c0 2.781-2.325 4.721-5.107 4.23l-11.488-2.025c-2.781-.49-5.11-3.252-5.11-6.033v-24.275c-2.697-3.131-4.268-7.08-4.268-11.145 0-7.697 5.95-13.264 13.517-12.234.803.109 2.408.392 3.211.565 7.565 1.64 13.517 9.306 13.517 17.003-.003 4.063-1.574 7.46-4.272 9.639z" />
      </defs>
      <clipPath id="c">
        <use overflow="visible" xlinkHref="#b" />
      </clipPath>
      <path
        fill="currentColor"
        d="M176.787 153.776v21.551c0 2.469-2.242 4.191-4.924 3.756l-11.076-1.799c-2.681-.434-4.928-2.887-4.928-5.355v-21.551c-2.602-2.779-4.114-6.285-4.114-9.893 0-6.834 5.736-11.775 13.033-10.862a58.42 58.42 0 013.095.502c7.295 1.456 13.033 8.261 13.033 15.095-.002 3.605-1.516 6.622-4.119 8.556z"
        clipPath="url(#c)" />
    </svg>
  );
}
