exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-bundles-Visualization-Editor-v2-TemplateSettingsModal-___SigningSettings__controlsWrapper___1q4mm label {\n    margin-right: 40px;\n  }\n", "", {"version":3,"sources":["/workspace/app/bundles/Visualization/Editor-v2/TemplateSettingsModal/SigningSettings.css"],"names":[],"mappings":"AACE;IACE,mBAAkB;GACpB","file":"SigningSettings.css","sourcesContent":[".controlsWrapper {\n  label {\n    margin-right: 40px;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"controlsWrapper": "app-bundles-Visualization-Editor-v2-TemplateSettingsModal-___SigningSettings__controlsWrapper___1q4mm"
};