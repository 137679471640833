exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-pages-UserEdit-components-___UserEditPageHeader__component___3fxr5 {\n  display: flex;\n  align-items: center;\n  color: #2d866e;\n  color: var(--c-brand);\n  text-transform: uppercase;\n  margin-bottom: 10px;\n}\n\n  .app-pages-UserEdit-components-___UserEditPageHeader__component___3fxr5 .app-pages-UserEdit-components-___UserEditPageHeader__icon___1LRAV {\n    transition: transform 0.2s ease-in-out;\n  }\n\n  .app-pages-UserEdit-components-___UserEditPageHeader__component___3fxr5:hover .app-pages-UserEdit-components-___UserEditPageHeader__icon___1LRAV {\n    transform: translateX(-5px);\n  }\n", "", {"version":3,"sources":["/workspace/app/pages/UserEdit/components/UserEditPageHeader.css"],"names":[],"mappings":"AAAA;EACE,cAAa;EACb,oBAAmB;EACnB,eAAqB;EAArB,sBAAqB;EACrB,0BAAyB;EACzB,oBAAmB;CASrB;;EAPE;IACE,uCAAsC;GACxC;;EAEA;IACE,4BAA2B;GAC7B","file":"UserEditPageHeader.css","sourcesContent":[".component {\n  display: flex;\n  align-items: center;\n  color: var(--c-brand);\n  text-transform: uppercase;\n  margin-bottom: 10px;\n\n  .icon {\n    transition: transform 0.2s ease-in-out;\n  }\n\n  &:hover .icon {\n    transform: translateX(-5px);\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-pages-UserEdit-components-___UserEditPageHeader__component___3fxr5",
	"icon": "app-pages-UserEdit-components-___UserEditPageHeader__icon___1LRAV"
};