import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.font.label};
  font-weight: 300;
  .folder {
    margin-left: 20px;
    color: ${props => props.theme.font.light};
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
  .folder__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 240px;
  }
  .version {
    margin-left: 20px;
    border-radius: 3px;
    border: 1px solid ${props => props.theme.border.dark};
    background-color: ${props => props.theme.background.lightBlue};
    padding: 0 10px;
    display: table;
  }
  .status {
    margin-left: 20px;
  }
`;
