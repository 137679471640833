import { COMPLETED, ERROR, SENT_FOR_SIGNING, STARTED } from 'bundles/shared/constants/questionnaireStatus';
import { Types as QuestionnaireActionTypes } from 'ducks/questionnaire/actions';
import update from 'immutability-helper';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import { createReducer } from 'reduxsauce';
import { Types } from './actions';

export const initialState = {
  documentId: undefined,
  documentName: undefined,
  state: undefined,
  locked: false,
  versions: [],
  currentRevision: 1,
  errors: { data: [], errorType: undefined },
};

const fetchDocumentDetails = (state, { documentId, data }) => update(state, {
  documentId: { $set: documentId },
  documentName: { $set: data.name },
  state: { $set: data.state },
  locked: { $set: data.locked },
  versions: { $set: data.versions },
  currentRevision: { $set: _get(data, 'versions[0].revision', state.currentRevision) },
});

const addQuestionnaireToVersions = (state, { questionnaire }) => update(state, {
  versions: {
    $push: [{
      completed: true,
      revision: 1,
      isQuestionnaire: true,
      ...questionnaire,
    }],
  },
});

const updateCurrentVersionContent = (state, { content }) => {
  const currentVersionIndex = _findIndex(state.versions, v => v.revision === state.currentRevision);
  return update(state, { versions: { [currentVersionIndex]: { html: { $set: content } } } });
};

const updateDocumentVersionName = (state, { version }) => {
  const versionIndex = _findIndex(state.versions, v => v.revision === version.revision);
  return update(state, { versions: { [versionIndex]: { name: { $set: version.name } } } });
};

const saveDocumentVersionSuccess = (state, { version }) => {
  const currentVersionIndex = _findIndex(state.versions, v => v.slug === version.slug);
  if (currentVersionIndex !== -1) {
    return update(state, { versions: { [currentVersionIndex]: { completed: { $set: true } } } });
  }
  return state;
};

const createDocumentVersion = (state, { version }) => update(state, {
  versions: { $unshift: [version] },
  currentRevision: { $set: version.revision },
});

const fetchDocumenVersionSuccess = (state, { version }) => {
  const currentVersionIndex = version.user_questionnaire
    ? state.versions.length - 1
    : _findIndex(state.versions, v => v.revision === version.revision);
  const data = version.user_questionnaire
    ? {
      completed: true,
      revision: 1,
      isQuestionnaire: true,
      ...version.user_questionnaire,
    }
    : version;

  return update(state, { versions: { [currentVersionIndex]: { $set: data } } });
};

const changeCurrentDocumentVersion = (state, { revision }) => update(state, { currentRevision: { $set: revision } });

const updateDocumentName = (state, { name }) => update(state, { documentName: { $set: name } });

const updateDocumentStatus = (state, { documentStatus }) => update(state, { state: { $set: documentStatus } });

const updateDocumentErrors = (state, { documentErrors }) => update(state, { errors: { $set: documentErrors } });

const clearDocumentDetails = () => initialState;

export default createReducer(initialState, {
  [Types.FETCH_DOCUMENT_DETAILS_SUCCESS]: fetchDocumentDetails,
  [Types.UPDATE_CURRENT_VERSION_CONTENT_REQUEST]: updateCurrentVersionContent,
  [Types.CREATE_DOCUMENT_VERSION_SUCCESS]: createDocumentVersion,
  [Types.ADD_QUESTIONNAIRE_TO_DOCUMENT_VERSIONS]: addQuestionnaireToVersions,
  [Types.CHANGE_CURRENT_DOCUMENT_VERSION]: changeCurrentDocumentVersion,
  [Types.FETCH_DOCUMENT_VERSION_SUCCESS]: fetchDocumenVersionSuccess,
  [Types.CLEAR_DOCUMENT_DETAILS]: clearDocumentDetails,
  [Types.UPDATE_DOCUMENT_NAME]: updateDocumentName,
  [Types.UPDATE_DOCUMENT_VERSION_NAME_SUCCESS]: updateDocumentVersionName,
  [Types.SAVE_DOCUMENT_VERSION_SUCCESS]: saveDocumentVersionSuccess,
  [QuestionnaireActionTypes.SET_DOCUMENT_COMPLETED]: state => updateDocumentStatus(state, { documentStatus: COMPLETED }),
  [QuestionnaireActionTypes.SET_DOCUMENT_STARTED_SUCCESS]: state => updateDocumentStatus(state, { documentStatus: STARTED }),
  [QuestionnaireActionTypes.CANCEL_SIGNING_SUCCESS]: state => updateDocumentStatus(state, { documentStatus: COMPLETED }),
  [Types.CANCEL_DOCUMENT_VERSION_SIGNING_SUCCESS]: state => updateDocumentStatus(state, { documentStatus: COMPLETED }),
  [QuestionnaireActionTypes.SIGN_DOCUMENT_REQUEST_SUCCESS]: state => updateDocumentStatus(state, { documentStatus: SENT_FOR_SIGNING }),
  [QuestionnaireActionTypes.SIGN_DOCUMENT_REQUEST_FAILURE]: state => updateDocumentStatus(state, { documentStatus: ERROR }),
  [Types.FETCH_DOCUMENT_ERRORS_SUCCESS]: updateDocumentErrors,
});
