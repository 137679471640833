import assert from 'assert';
import createExportDocxSagas from './sagas/exportDocx';
import createExportPdfSagas from './sagas/exportPdf';

/* istanbul ignore file */
export default function createSagas({ questionnaireService, documentExportService, trackingService } = {}) {
  assert.ok(questionnaireService, 'questionnaireService is required');
  assert.ok(documentExportService, 'documentExportService is required');
  assert.ok(trackingService, 'trackingService is required');

  return {
    ...createExportDocxSagas({
      questionnaireService,
      documentExportService,
    }),
    ...createExportPdfSagas({
      questionnaireService,
      documentExportService,
      trackingService,
    }),
  };
}
