import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import styles from './index.css';

export const EMAIL = 'email';

const renderIcon = (icon) => {
  if (icon === EMAIL) {
    return <EmailIcon fontSize="inherit" className={styles.emailIcon} />;
  }
  return null;
};

const SelectedTags = ({ tags, removeTag, icon }) => (
  <div className={styles.tagWrapper}>
    {tags.map((tag, index) => (
      <div className={styles.tag} key={`input-tag-${index + 1}`}>
        {renderIcon(icon)}
        {tag}
        {removeTag && <ClearIcon fontSize="inherit" onClick={removeTag} data-index={index} className={styles.clearIcon} />}
      </div>
    ))}
  </div>
);

SelectedTags.propTypes = {
  removeTag: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.node),
  icon: PropTypes.string,
};

export default SelectedTags;
