import styled from 'styled-components';

const Initials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  width: 46px;
  height: 46px;
  border-radius: 4px;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 14px;
  color: ${p => p.theme.rolesColors[p.type]};
  background-color: ${p => p.theme.rolesBgs[p.type]};
`;

export default Initials;
