import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Select from '@material-ui/core/Select';
import { MenuItem } from 'components/MenuList';
import styles from './styles.css';

const VersionPicker = ({ currentVersion, versions, onVersionChange }) => (
  <Select
    value={currentVersion.revision}
    renderValue={val => <FormattedMessage id="visualization.top_toolbar.version.title" values={{ val }} />}
    className={styles.component}
    classes={{ select: styles.selectValue, icon: styles.arrowIcon }}
    onChange={e => onVersionChange(e.target.value)}
    MenuProps={{
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }}>
    {versions.map(version => (
      <MenuItem
        key={version.revision}
        classes={{
          root: styles.listItem,
          selected: styles.listItemActive,
        }}
        value={version.revision}>
        <FormattedMessage id="visualization.top_toolbar.version.title" values={{ val: version.revision }} />
        <span className={styles.listItemHelp}>
          <FormattedMessage
            id={`visualization.top_toolbar.version.${currentVersion.revision === version.revision ? 'viewing' : 'view'}`} />
        </span>
      </MenuItem>
    ))}
    <MenuItem disabled value="" classes={{ root: styles.helpText }}>
      <FormattedMessage id="visualization.top_toolbar.version.help_text" />
    </MenuItem>
  </Select>
);

VersionPicker.propTypes = {
  currentVersion: PropTypes.shape({ revision: PropTypes.number }),
  versions: PropTypes.arrayOf(PropTypes.shape({ revision: PropTypes.number })),
  onVersionChange: PropTypes.func.isRequired,
};

VersionPicker.defaultProps = {
  currentVersion: {},
  versions: [],
};

export default VersionPicker;
