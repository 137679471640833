import assert from 'assert';

import ApiClient from '../ApiClient';

export default class UploadService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  upload(extension) {
    assert.ok(typeof extension === 'string', 'extension is required');
    assert.ok(extension, 'extension should be of type string');

    return this.apiClient.request('/uploads', { method: 'post', data: { extension } });
  }
}
