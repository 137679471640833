const styleConstants = {
  colors: {
    black: '#000',
    blueLight: '#3e94f6',
    grey: '#696969',
    greyLight: '#dbdbdb',
    offWhite: '#f5f5f5',
    offWhite2: '#ecf0f1',
    white: '#fff',
  },
  zIndexHighest: 10,
};

export default styleConstants;
