import get from 'lodash/get';
import some from 'lodash/some';
import camelCase from 'lodash/camelCase';

export const getAPICallStatus = (state, action) => get(state, `status.${camelCase(action)}`);

export const getAction = actionName => actionName.replace(/\//g, '_').replace('_REQUEST', '');
/**
 * Checks if any of given actions is being loaded.
 * Returns false if all actions are finished or simply not started.
 * @param actions - string or array of strings representing names of actions to be checked
 */
export const isLoading = (state, actions) => {
  if (Array.isArray(actions)) {
    return some(actions, action => get(state, `status.${camelCase(action)}.isLoading`));
  }
  return get(state, `status.${camelCase(actions)}.isLoading`);
};

export default getAPICallStatus;
