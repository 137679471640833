const hasParent = (node, hasParentCompare) => {
  if (node.tagName === 'BODY') return false;
  const isCorrectParrent = hasParentCompare(node);

  if (!isCorrectParrent) {
    return hasParent(node.parentNode, hasParentCompare);
  }
  return isCorrectParrent;
};

export default hasParent;
