import assert from 'assert';
import queryString from 'qs';

import ApiClient from '../ApiClient';

export default class DocumentService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  createDocument({ id, folderId } = {}) {
    assert.ok(id, 'id<string> is required');

    return this.apiClient.request(`/questionnaire_template_versions/${id}/user_questionnaires`, {
      method: 'post',
      data: { folder_id: folderId },
    });
  }

  createGenericAccessDocument({ documentName, email, templateSharingSlug } = {}) {
    assert.ok(documentName, 'documentName<string> is required');
    assert.ok(email, 'email<string> is required');
    assert.ok(templateSharingSlug, 'templateSharingSlug<string> is required');

    return this.apiClient.request(`/user_questionnaires?sharing_slug=${templateSharingSlug}`, {
      method: 'post',
      data: { generic_access_user: { email, document_name: documentName } },
    });
  }

  createDocumentFolder({ name, parentId } = {}) {
    assert.ok(name, 'name<string> is required');

    return this.apiClient.request('/document_folders', {
      method: 'post',
      data: { name, parent_id: parentId },
    });
  }

  deleteFolder(id) {
    assert.ok(id, 'id<string> is required');

    return this.apiClient.request(`/document_folders/${id}`, { method: 'delete' });
  }

  // eslint-disable-next-line camelcase
  fetchDocumentsInfo({ document_folder_id, page, q } = {}) {
    assert.ok(page, 'page<string> is required');

    const params = queryString.stringify({ document_folder_id, page, q });
    return this.apiClient.request(`/user_questionnaires?${params}`, { method: 'get' });
  }

  searchDocuments(query, folderSlug, page) {
    assert.ok(query, 'query<string> is required');

    const params = queryString.stringify({ document_folder_id: folderSlug, page, q: query });
    return this.apiClient.get(`/user_questionnaires?${params}`);
  }

  fetchFolders() {
    return this.apiClient.request('/document_folders', { method: 'get' });
  }

  getVersionPreviews(templateId) {
    assert.ok(templateId, 'templateId<string> is required');

    return this.apiClient.request(
      `/questionnaire_templates/${templateId}/version_previews`,
      { method: 'get' },
    );
  }

  updateFolder({ id, name } = {}) {
    assert.ok(id, 'id<string> is required');
    assert.ok(name, 'name<string> is required');

    return this.apiClient.request(`/document_folders/${id}`, {
      method: 'patch',
      data: { document_folder: { id, name } },
    });
  }

  deleteDocument(id) {
    assert.ok(id, 'id<string> is required');

    return this.apiClient.request(`/user_questionnaires/${id}/trash`, { method: 'post' });
  }

  restoreFromTrash(trashId) {
    assert.ok(trashId, 'trashId<string> is required');

    return this.apiClient.request(`/deleted_items/${trashId}/restore`, { method: 'put' });
  }

  fetchStylesheets() {
    return this.apiClient.request('/document_templates', { method: 'get' });
  }

  moveDocumentToFolder(documentId, folderId) {
    assert.ok(documentId, 'documentId<string> is required');
    assert.ok(folderId, 'folderId<string> is required');

    return this.apiClient.request(`/user_questionnaires/${documentId}/move_to_folder/${folderId}`, { method: 'patch' });
  }
}
