import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

export default class Portal extends React.Component {
  static propTypes = {
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    refFn: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    testId: PropTypes.string,
  };

  state = { opened: true };

  componentDidMount() {
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  }

  componentWillUnmount() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    if (!this.state.opened) {
      return null;
    }
    return createPortal(
      <div ref={this.props.refFn} data-test-id={this.props.testId}>
        {this.props.children}
      </div>,
      document.getElementsByTagName('BODY')[0],
    );
  }
}
