import React from 'react';
import PropTypes from 'prop-types';
import MaterialGrid from '@material-ui/core/Grid';

const GridContainer = ({ children, spacing, ...props }) => (
  <MaterialGrid container spacing={spacing} {...props}>
    {children}
  </MaterialGrid>
);

GridContainer.propTypes = { spacing: PropTypes.oneOf([0, 8, 16, 24, 32, 40]) };

GridContainer.defaultProps = { spacing: 8 };

export default GridContainer;
