import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const StyledGrid = styled(Grid)`
  background-color: #fff;

  .profile-heading {
    margin-bottom: 25px;
  }

  .item--padding {
    padding: 5% 10% !important;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
`;
