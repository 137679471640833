exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-bundles-Questionnaire2-ProgressBar-Components-___ProgressBar__component___2TocE {\n  min-width: 320px;\n  min-height: 68px;\n  display: flex;\n  align-items: center;\n  padding: 0 7.5%;\n}\n\n.app-bundles-Questionnaire2-ProgressBar-Components-___ProgressBar__component___2TocE > div {\n  flex: 1;\n  background-color: rgba(45, 134, 110, 0.25);\n}\n\ndiv.app-bundles-Questionnaire2-ProgressBar-Components-___ProgressBar__progressBar___3NUgw {\n  background-color: #2d866e;\n  background-color: var(--c-brand);\n}\n\n@media (max-width: 599px) {\n  .app-bundles-Questionnaire2-ProgressBar-Components-___ProgressBar__component___2TocE {\n    z-index: 1001;\n    background-color: #fff;\n    background-color: var(--c-bw-00);\n    padding: 0 16px;\n  }\n}\n", "", {"version":3,"sources":["/workspace/app/bundles/Questionnaire2/ProgressBar/Components/ProgressBar.css"],"names":[],"mappings":"AAAA;EACE,iBAAgB;EAChB,iBAAgB;EAChB,cAAa;EACb,oBAAmB;EACnB,gBAAe;CACjB;;AAEA;EACE,QAAO;EACP,2CAA8C;CAChD;;AAEA;EACE,0BAAgC;EAAhC,iCAAgC;CAClC;;AAEA;EACE;IACE,cAAa;IACb,uBAAgC;IAAhC,iCAAgC;IAChC,gBAAe;GACjB;CACF","file":"ProgressBar.css","sourcesContent":[".component {\n  min-width: 320px;\n  min-height: 68px;\n  display: flex;\n  align-items: center;\n  padding: 0 7.5%;\n}\n\n.component > div {\n  flex: 1;\n  background-color: color(var(--c-brand) a(25%));\n}\n\ndiv.progressBar {\n  background-color: var(--c-brand);\n}\n\n@media (--for-small-down) {\n  .component {\n    z-index: 1001;\n    background-color: var(--c-bw-00);\n    padding: 0 16px;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-bundles-Questionnaire2-ProgressBar-Components-___ProgressBar__component___2TocE",
	"progressBar": "app-bundles-Questionnaire2-ProgressBar-Components-___ProgressBar__progressBar___3NUgw"
};