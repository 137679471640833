import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

import EditorWrapper from 'Visualization/Editor-v2/EditorWrapper';
import { TINYMCE_KEY } from 'shared/helpers/constants';
import { EditorFilePicker } from 'containers/EditorFilePicker';
import contentStyles from '../utils/contentStyles';

export default class DocumentStylesEditor extends PureComponent {
  static propTypes = {
    onEditorChange: PropTypes.func,
    value: PropTypes.node,
  };

  state = {
    contentChanged: false,
    editorValue: '',
  };

  componentDidMount() {
    this.setState({ editorValue: this.props.value });
  }

  setupEditor = (editor) => {
    editor.addButton('pagination', {
      tooltip: 'Add paginations',
      icon: 'plus',
      onClick: () => {
        editor.insertContent(`
        <span contenteditable='false' class="page tc__pagination-wrapper">
          <button class='tc__pagination'>[page]</button>
        </span>
      `);
      },
    });

    editor.on('click', ({ target }) => {
      if (target.classList.contains('tc__pagination')) {
        target.parentElement.remove();
      }
    });

    editor.on('blur', () => {
      if (this.state.contentChanged) {
        this.props.onEditorChange(editor.getContent());
        this.setState({ contentChanged: false });
      }
    });
  };

  handleEditorChange = (e) => {
    if (this.state.editorValue !== e) {
      this.setState({ contentChanged: true, editorValue: e });
    }
  };

  render() {
    const { editorValue } = this.state;
    return (
      <EditorWrapper>
        <EditorFilePicker convertFileToBase64>
          {
            ({ onFilePick }) => (
              <Editor
                apiKey={TINYMCE_KEY}
                value={editorValue}
                onEditorChange={this.handleEditorChange}
                init={{
                  browser_spellcheck: true,
                  height: 200,
                  setup: this.setupEditor,
                  menubar: false,
                  statusbar: false,
                  resize: false,
                  skin: false,
                  content_style: contentStyles,
                  autosave_ask_before_unload: false,
                  theme: 'modern',
                  image_description: false,
                  image_dimensions: true,
                  file_picker_types: 'image',
                  file_picker_callback: onFilePick,
                  paste_data_images: true,
                  extended_valid_elements: 'span[*]',
                  plugins: ['lists image imagetools paste table textcolor colorpicker'],
                  toolbar1:
              'formatselect | bold italic strikethrough forecolor backcolor | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | table | image | pagination',
                }} />
            )
          }
        </EditorFilePicker>
      </EditorWrapper>
    );
  }
}
