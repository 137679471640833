import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ModalContent } from 'components/Modal';
import Typography from 'components/Typography';
import styles from './styles.css';

const signingMethods = {
  email: 'Email',
  'bankid-no': 'BankID',
};

const EsignConfirmationStep = ({ emails, signingMethod }) => (
  <div className={styles.container}>
    <ModalContent>
      <Typography variant="h4" className={styles.confirmationStepTitle}>
        <FormattedMessage id="questionnaire.esign.form.confirmation_title" />
      </Typography>
      <Typography variant="bodySmall" className={styles.confirmationDescriptionContainer}>
        <FormattedMessage
          id="questionnaire.esign.form.confirmation_description"
          values={{ sendingMethod: <span className={styles.confirmationSigningMethod}>{signingMethods[signingMethod]}</span> }} />
      </Typography>
      <ul className={styles.confirmationList}>
        {emails.map(email => (
          <li className={styles.confirmationItem}>
            <span className={styles.confirmationItemLabel}>
              <FormattedMessage id="questionnaire.esign.form.signee" />
            </span>
            <span className={styles.confirmationItemEmail}>{email}</span>
          </li>
        ))}
      </ul>
    </ModalContent>
  </div>
);

EsignConfirmationStep.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  signingMethod: PropTypes.string.isRequired,
};

export default EsignConfirmationStep;
