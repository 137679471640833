exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-pages-DocumentStyles-views-___DocumentStyles__component___1mwfj {\n  display: flex;\n  flex-direction: row;\n}\n\n  .app-pages-DocumentStyles-views-___DocumentStyles__component___1mwfj form {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    margin: 20px 40px;\n    width: 50%;\n  }\n", "", {"version":3,"sources":["/workspace/app/pages/DocumentStyles/views/DocumentStyles.css"],"names":[],"mappings":"AAAA;EACE,cAAa;EACb,oBAAmB;CASrB;;EAPE;IACE,cAAa;IACb,uBAAsB;IACtB,QAAO;IACP,kBAAiB;IACjB,WAAU;GACZ","file":"DocumentStyles.css","sourcesContent":[".component {\n  display: flex;\n  flex-direction: row;\n\n  form {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    margin: 20px 40px;\n    width: 50%;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-pages-DocumentStyles-views-___DocumentStyles__component___1mwfj"
};