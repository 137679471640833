exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "svg.app-components-HelpIcon-___index__component___394oi {\n  margin-left: 5px;\n  font-size: 0.93333rem;\n  color: #3d3d3d;\n  color: var(--c-bw-70);\n}\n", "", {"version":3,"sources":["/workspace/app/components/HelpIcon/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAgB;EAChB,sBAAe;EACf,eAAqB;EAArB,sBAAqB;CACvB","file":"index.css","sourcesContent":["svg.component {\n  margin-left: 5px;\n  font-size: 14px;\n  color: var(--c-bw-70);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-components-HelpIcon-___index__component___394oi"
};