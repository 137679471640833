import React, { PureComponent } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';
import _intersection from 'lodash/intersection';

import { LOGIN_PATH, NOT_FOUND_PATH } from 'appRoot/constants';
import { HUBSPOT_PORTAL_ID } from 'appRoot/constants/services';

import PageSpinner from 'components/PageSpinner';
import { useChat, setChatUser } from 'utils/useChat';
import { Creators } from 'ducks/loggedInUser/actions';
import mapStateToProps from './selectors';

export class PrivateRouteComponent extends PureComponent {
  static propTypes = {
    hasFetchedUser: PropTypes.bool.isRequired,
    isUserAuthorized: PropTypes.bool.isRequired,
    mode: PropTypes.string,
    wideContainer: PropTypes.bool,
    userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
    fetchProfile: PropTypes.func.isRequired,
  };

  componentDidMount() {
    document.title = this.props.title;
    const { isChatEnabled } = this.props;
    if (isChatEnabled) {
      useChat(HUBSPOT_PORTAL_ID);
    }
    if (!this.props.hasFetchedUser) {
      this.props.fetchProfile();
    }
  }

  componentDidUpdate(prevProps) {
    const { user, isChatEnabled } = this.props;
    const isUserLoaded = !prevProps.user.email && user.email;
    let userData;
    if (isUserLoaded) {
      userData = { email: user.email, uid: user.id, displayName: `${user.firstName} ${user.lastName}` };
    }
    if (!prevProps.isChatEnabled && isChatEnabled) {
      useChat(HUBSPOT_PORTAL_ID, userData);
    }
    if (isUserLoaded && isChatEnabled) {
      setChatUser(userData);
    }
  }

  hasAccess = () => {
    const { userRoles, permissions } = this.props;
    return permissions.length === 0 || _intersection(userRoles, permissions).length > 0;
  };

  _renderRoute = (props) => {
    const { component: Component, hasFetchedUser, isUserAuthorized } = this.props;
    if (!isUserAuthorized) {
      // This is triggered for users not logged in as well as
      // any server errors
      return (
        <Redirect
          to={{
            pathname: LOGIN_PATH,
            state: { from: props.location },
          }} />
      );
    }

    if (!hasFetchedUser) {
      return <PageSpinner />;
    }

    if (!this.hasAccess()) {
      return (
        <Redirect
          to={{
            pathname: NOT_FOUND_PATH,
            state: { from: props.location },
          }} />
      );
    }

    return (
      <>
        <Component {...props} mode={this.props.mode} />
      </>
    );
  };

  render() {
    const routeProps = _omit(this.props, [
      'component',
      'isUserAuthorized',
      'hasFetchedUser',
      'wideContainer',
      'mode',
      'title',
      'permissions',
      'isChatEnabled',
    ]);
    return <Route {...routeProps} render={this._renderRoute} />;
  }
}

const connector = connect(
  mapStateToProps,
  { fetchProfile: Creators.fetchProfileRequest },
);
export default withRouter(connector(PrivateRouteComponent));
