import assert from 'assert';

import createAnswersSagas from './sagas/answers';
import createQuestionnaireSagas from './sagas/questionnaire';

export default function createSagas({
  answerService,
  companyService,
  credentialsService,
  documentService,
  notificationService,
  questionnaireService,
  signDocumentService,
  trackingService,
} = {}) {
  assert.ok(answerService, 'answerService is required');
  assert.ok(documentService, 'documentService is required');
  assert.ok(notificationService, 'notificationService is required');
  assert.ok(companyService, 'companyService is required');
  assert.ok(questionnaireService, 'questionnaireService is required');
  assert.ok(signDocumentService, 'signDocumentService is required');
  assert.ok(trackingService, 'trackingService is required');

  return {
    answersSagas: createAnswersSagas({
      answerService,
      trackingService,
    }),
    questionnaireSagas: createQuestionnaireSagas({
      companyService,
      credentialsService,
      documentService,
      notificationService,
      questionnaireService,
      signDocumentService,
      trackingService,
    }),
  };
}
