import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';

import Switch from 'components/form/Switch';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import HelpIcon from 'components/HelpIcon';

import styles from './TemplateSettings.css';

export const EnableDocumentHighlighting = () => (
  <Field name="document_highlighting_enabled">
    {({ field }) => (
      <div className={styles.fieldWrapper}>
        <Typography variant="label">
          <FormattedMessage id="template_settings.document_highlighting" />
        </Typography>
        <Switch checked={field.value} testId="EnableDocumentHighlighting-switch" {...field} />
        <Tooltip title={<FormattedMessage id="template_settings.document_highlighting_tooltip" />}>
          <HelpIcon />
        </Tooltip>
      </div>
    )}
  </Field>
);
