exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-bundles-Visualization-Editor-v2-TopToolbar-___index__root___Vo3UV {\n  padding-bottom: 20px;\n}\n", "", {"version":3,"sources":["/workspace/app/bundles/Visualization/Editor-v2/TopToolbar/index.css"],"names":[],"mappings":"AAAA;EACE,qBAAoB;CACtB","file":"index.css","sourcesContent":[".root {\n  padding-bottom: 20px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "app-bundles-Visualization-Editor-v2-TopToolbar-___index__root___Vo3UV"
};