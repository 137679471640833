import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { PREVIEW } from 'shared/constants/questionnaireModes';
import { questionnaireSelector, questionnaireStatusSelector } from 'ducks/questionnaire/selectors';
import {
  SENT_FOR_SIGNING,
  EXPIRED,
  REJECTED,
  ERROR,
  SIGNING_REQUESTED,
  SIGNING_ABORTED,
  SIGNING_EXPIRED,
  SIGNING_FAILED,
} from 'bundles/shared/constants/questionnaireStatus';

export const selectDocumentId = state => state.documentDetails.documentId || state.questionnaire.userQuestionnaire.id;

export const selectDocumentVersions = (state) => {
  const versions = [];


  // eslint-disable-next-line no-restricted-syntax
  for (const version of state.documentDetails.versions) {
    const exists = versions.some(({ revision }) => revision === version.revision);

    if (!exists) {
      versions.push(version);
    }
  }

  return versions;
};

export const selectLatestDocumentVersion = state => state.documentDetails.versions[0];

export const selectDocumentVersionByRevision = revision => createSelector(
  selectDocumentVersions,
  versions => versions.find(v => v.revision === revision),
);

export const selectCurrentDocumentRevision = state => state.documentDetails.currentRevision;

export const selectCurrentDocumentVersion = createSelector(
  selectDocumentVersions,
  selectCurrentDocumentRevision,
  (versions, currentRevision) => versions.find(v => v.revision === currentRevision),
);

export const selectDocumentName = createSelector(
  state => state.documentDetails.documentName,
  questionnaireSelector,
  (documentName, questionnaireState) => {
    const questionnaireName = _get(questionnaireState, 'userQuestionnaire.name');
    return questionnaireState.mode === PREVIEW ? questionnaireName : documentName;
  },
);

export const selectIsCurrentVersionLatest = createSelector(
  selectCurrentDocumentRevision,
  selectLatestDocumentVersion,
  (currentRevision, latestVersion) => currentRevision === _get(latestVersion, 'revision'),
);

export const selectDocumentHasVersions = createSelector(
  selectDocumentVersions,
  documentVersions => documentVersions.length > 1,
);

export const selectDocumentErrors = state => state.documentDetails.errors;

export const selectDocumentStatus = (state) => {
  if (state.documentDetails.state) {
    return _mapDocumentState(state.documentDetails.state);
  }
  // the questionnaire state is required here as document api doesn't handle WL documents
  return questionnaireStatusSelector(state) || undefined;
};

function _mapDocumentState(documentState) {
  switch (documentState) {
    case SIGNING_REQUESTED:
      return SENT_FOR_SIGNING;
    case SIGNING_FAILED:
      return ERROR;
    case SIGNING_ABORTED:
      return REJECTED;
    case SIGNING_EXPIRED:
      return EXPIRED;
    default:
      return documentState;
  }
}
