import { Creators as QuestionnaireActions } from 'ducks/questionnaire/actions';
import { isFinishedWithSkippedSelector } from 'ducks/questionnaire/selectors';
import _get from 'lodash/get';
import {
  selectDocumentName,
  selectDocumentStatus,
} from 'pages/DocumentDetails/selectors';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  DOCUMENT,
  EDIT,
  GENERIC_ACCESS,
  PREVIEW,
  WHITE_LABEL,
} from 'shared/constants/questionnaireModes';
import { STARTED } from 'shared/constants/questionnaireStatus';
import { fillHeight } from 'shared/utils/styles';
import styled from 'styled-components';
import theme from 'Visualization/theme';
import { legacyMedia } from 'shared/styled-helpers';
import FinishedWithSkipped from './FinishedWithSkipped';
import LeftToolbar from './LeftToolbar/LeftToolbar';
import PreviewContainer from './Preview/PreviewContainer';
import Questionnaire from './Questionnaire';
import QuestionnaireCompleted from './QuestionnaireCompleted';

function calculateQuestionnaireHeight(mode, shouldFillHeight) {
  const fixedHeight = shouldFillHeight && [WHITE_LABEL, GENERIC_ACCESS].indexOf(mode) !== -1;
  if (fixedHeight) {
    return 'height: 100vh';
  }
  return shouldFillHeight ? fillHeight : 'height: auto';
}

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  .main-content {
    overflow: hidden;
    display: flex;
    flex: auto;
  }
  ${legacyMedia.sm`
    .main-content {
      flex-direction: column;
      overflow: auto;
    }
  `};
  ${props => calculateQuestionnaireHeight(props.mode, props.fillHeight)};
`;

class QuestionnaireContainer extends React.Component {
  static propTypes = {
    loaded: PropTypes.bool.isRequired,
    mode: PropTypes.oneOf([
      PREVIEW,
      EDIT,
      DOCUMENT,
      WHITE_LABEL,
      GENERIC_ACCESS,
    ]).isRequired,
    signDocumentRequest: PropTypes.func.isRequired,
    isFinishedWithSkipped: PropTypes.bool,
    questionnaireStatus: PropTypes.string,
    signingEnabled: PropTypes.bool.isRequired,
    hasAnswers: PropTypes.bool.isRequired,
    documentName: PropTypes.string,
    trackTogglePreview: PropTypes.func.isRequired,
    v2Experience: PropTypes.bool,
  };

  static defaultProps = {
    questionnaireStatus: STARTED,
    isFinishedWithSkipped: false,
  };

  state = {
    isPreviewVisible: true,
    isLeftToolbarVisible: this.props.v2Experience
      ? false
      : !(window.outerWidth <= theme.breakpoints.sm),
    isIntroductionVisible: false,
  };

  componentDidMount() {
    this.setInitialIntroductionVisibility();
    this.state.isPreviewVisible = this.props.defaultPreviewVisibility;
  }

  componentDidUpdate(prevProps) {
    if (this.props.loaded && !prevProps.loaded) {
      this.setInitialIntroductionVisibility();
    }
  }

  togglePreview = () => {
    this.setState(
      state => ({ isPreviewVisible: !state.isPreviewVisible }),
      () => {
        const destination = this.state.open ? 'toOpen' : 'toClose';
        this.props.trackTogglePreview(destination);
      },
    );
  };

  toggleToolbar = () => {
    this.setState(state => ({ isLeftToolbarVisible: !state.isLeftToolbarVisible }));
  };

  setInitialIntroductionVisibility = () => {
    this.setState({ isIntroductionVisible: !this.props.hasAnswers && !this.isPreviewMode() });
  };

  hideIntroduction = () => {
    this.setState({ isIntroductionVisible: false });
  };

  showIntroduction = () => {
    this.setState({ isIntroductionVisible: true });
  };

  isPreviewMode = () => this.props.mode === PREVIEW;

  render() {
    if (!this.props.loaded) return null;

    const {
      mode,
      signDocumentRequest,
      isFinishedWithSkipped,
      questionnaireStatus,
      signingEnabled,
      documentName,
    } = this.props;

    const finished = questionnaireStatus !== STARTED;

    return (
      <Styled fillHeight={!finished} mode={mode}>
        {finished && (
          <QuestionnaireCompleted
            isFinishedWithSkipped={isFinishedWithSkipped} />
        )}
        {!finished && (
          <div className="main-content" data-disable-exactly="true">
            <LeftToolbar
              previewMode={this.isPreviewMode()}
              isLeftToolbarVisible={this.state.isLeftToolbarVisible}
              showIntroduction={this.showIntroduction}
              hideIntroduction={this.hideIntroduction}
              isIntroductionVisible={this.state.isIntroductionVisible}
              onToggleToolbar={this.toggleToolbar} />
            {isFinishedWithSkipped && !this.state.isIntroductionVisible ? (
              <FinishedWithSkipped
                isPreviewVisible={this.state.isPreviewVisible}
                isLeftToolbarVisible={this.state.isLeftToolbarVisible}
                toggleLeftToolbar={this.toggleToolbar} />
            ) : (
              <Questionnaire
                isPreviewVisible={this.state.isPreviewVisible}
                isLeftToolbarVisible={this.state.isLeftToolbarVisible}
                toggleLeftToolbar={this.toggleToolbar}
                finished={finished}
                signingEnabled={signingEnabled}
                isIntroductionVisible={this.state.isIntroductionVisible}
                hideIntroduction={this.hideIntroduction}
                documentName={documentName}
                signDocument={signDocumentRequest} />
            )}
            <PreviewContainer
              onTogglePreview={this.togglePreview}
              isPreviewVisible={this.state.isPreviewVisible}
              isLeftToolbarVisible={this.state.isLeftToolbarVisible}
              mode={mode} />
          </div>
        )}
      </Styled>
    );
  }
}

function mapStateToProps(state) {
  return {
    v2Experience: state.appState.v2Experience,
    loaded: state.questionnaire.loaded,
    isFinishedWithSkipped: isFinishedWithSkippedSelector(state),
    questionnaireStatus: selectDocumentStatus(state),
    signingEnabled: _get(
      state,
      'questionnaire.settings.signing_enabled',
      false,
    ),
    hasAnswers: _get(state, 'questionnaire.answers.length', 0) > 0,
    documentName: selectDocumentName(state),
    defaultPreviewVisibility: !_get(
      state,
      'questionnaire.company.default_document_preview_collapsed',
      false,
    ),
  };
}

const mapDispatchToProps = {
  signDocumentRequest: QuestionnaireActions.signDocumentRequest,
  trackTogglePreview: QuestionnaireActions.trackTogglePreview,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionnaireContainer);
