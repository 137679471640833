import assert from 'assert';
import _intersection from 'lodash/intersection';
import _size from 'lodash/size';

import ApiClient from '../ApiClient';

export default class DocumentService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  fetchDocumentStyles() {
    return this.apiClient.request('/document_templates', { method: 'get' });
  }

  updateDocumentStyles(settingsObj) {
    assert.ok(settingsObj, 'settingsObj is required');

    const validProperties = [
      'default_size',
      'font_family',
      'footer_html',
      'h1_bold',
      'h1_italic',
      'h1_size',
      'h1_underline',
      'h2_bold',
      'h2_italic',
      'h2_size',
      'h2_underline',
      'h3_bold',
      'h3_italic',
      'h3_size',
      'h3_underline',
      'h4_bold',
      'h4_italic',
      'h4_size',
      'h4_underline',
      'title_bold',
      'title_italic',
      'title_size',
      'title_underline',
      'header_html',
      'headlines_font_family',
      'margin_bottom',
      'margin_left',
      'margin_right',
      'margin_top',
      'preview_url',
      'stylesheet',
      'header_height',
      'footer_height',
      'section_numbering_enabled',
    ];
    const validSettingsProperties = _intersection(validProperties, Object.keys(settingsObj));
    const data = validSettingsProperties.reduce((acc, propertyName) => {
      if (typeof settingsObj[propertyName] !== 'undefined') {
        acc[propertyName] = settingsObj[propertyName];
      }

      return acc;
    }, {});

    assert.ok(
      Boolean(_size(data)),
      'DocumentService.updateDocumentStyles: No valid properties have been received',
    );

    return this.apiClient.request(
      '/document_templates',
      { method: 'patch', data },
    );
  }
}
