import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button';
import FieldRow from 'components/form/FieldRow';
import InputText from 'components/form/InputText';
import Label from 'components/form/Label';
import RadioGroup from 'components/form/RadioGroup';
import * as validator from 'shared/validation';
import propTypes from 'utils/proptypes';

import styles from './UserEditForm.css';

const roleOptions = [
  {
    value: 'admin',
    label: <FormattedMessage id="users.roles.admin" />,
    subtitle: <FormattedMessage id="user_edit.roles_permissions.admin" />,
  },
  {
    value: 'producer',
    label: <FormattedMessage id="users.roles.producer" />,
    subtitle: <FormattedMessage id="user_edit.roles_permissions.producer" />,
  },
  {
    value: 'consumer',
    label: <FormattedMessage id="users.roles.consumer" />,
    subtitle: <FormattedMessage id="user_edit.roles_permissions.consumer" />,
  },
];

class UserEditForm extends PureComponent {
  static propTypes = {
    user: propTypes.user.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  requiredValues = ['first_name', 'last_name', 'email'];

  validate = (values) => {
    const errors = {};
    if (validator.email(values.email)) {
      errors.email = <FormattedMessage id="user_profile.personal_information_form.invalid_email" />;
    }

    this.requiredValues.forEach((name) => {
      if (!values[name]) errors[name] = <FormattedMessage id="user_profile.personal_information_form.cant_be_blank" />;
    });
    return errors;
  }

  getInitialValues = () => {
    const { firstName, lastName, email, roles } = this.props.user;
    return {
      first_name: firstName || '',
      last_name: lastName || '',
      email: email || '',
      role: roles && roles[0],
    };
  }

  isInitialValid = () => !Object.keys(this.validate(this.getInitialValues())).length

  render() {
    return (
      <Formik
        onSubmit={this.props.onSubmit}
        enableReinitialize
        initialValues={this.getInitialValues()}
        isInitialValid={this.isInitialValid}
        validate={this.validate}>
        {({ isValid, isSubmitting, values }) => (
          <Form className={styles.form} noValidate>
            <div className={styles.nameRow}>
              <FieldRow className={styles.leftSpacing}>
                <Label htmlFor="first_name">
                  <FormattedMessage id="panel.users.edit.first_name" />
                </Label>
                <Field name="first_name">
                  {({ form, field }) => (
                    <InputText
                      {...field}
                      errorMessage={form.errors[field.name]}
                      testId="UserEdit-firstName"
                      required />
                  )}
                </Field>
              </FieldRow>
              <FieldRow>
                <Label htmlFor="last_name">
                  <FormattedMessage id="panel.users.edit.last_name" />
                </Label>
                <Field name="last_name">
                  {({ form, field }) => (
                    <InputText
                      {...field}
                      errorMessage={form.errors[field.name]}
                      testId="UserEdit-lastName"
                      required />
                  )}
                </Field>
              </FieldRow>
            </div>
            <FieldRow>
              <Label htmlFor="email">
                <FormattedMessage id="panel.users.edit.email" />
              </Label>
              <Field name="email">
                {({ form, field }) => (
                  <InputText
                    {...field}
                    type="email"
                    errorMessage={form.errors[field.name]}
                    testId="UserEdit-email"
                    required />
                )}
              </Field>
            </FieldRow>
            <FieldRow>
              <Label><FormattedMessage id="panel.users.edit.role" /></Label>
              <Field
                name="role"
                value={values.role}>
                {({ field }) => (
                  <RadioGroup options={roleOptions} {...field} />
                )}
              </Field>
            </FieldRow>
            <Button
              className={styles.submitButton}
              isDisabled={!isValid || isSubmitting}
              type="submit"
              testId="UserEdit-save">
              <FormattedMessage id="user_edit.save" />
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default UserEditForm;
