import React, { Fragment } from 'react';

import GridItem from 'components/Grid/Item';
import PropTypes from 'prop-types';
import Editing from '../modules/Editing';
import DownloadPdf from '../modules/DownloadPdf';
import styles from './index.css';

const Expired = ({ isMobile }) => (
  <Fragment>
    <GridItem xs="auto" sm="auto">
      <Editing isMobile={isMobile} />
    </GridItem>
    <GridItem xs={false} sm="auto">
      <div className={styles.seperator} />
    </GridItem>
    <GridItem xs="auto" sm="auto">
      <DownloadPdf isMobile={isMobile} />
    </GridItem>
  </Fragment>
);

Expired.propTypes = { isMobile: PropTypes.bool };

export default Expired;
