import DocumentIcon from '@material-ui/icons/InsertDriveFile';
import Button from 'components/Button';
import InputText from 'components/form/InputText';
import Typography from 'components/Typography';
import { Field } from 'formik';
import NameTheContract from 'icons/NameTheContract';
import _lowerCase from 'lodash/lowerCase';
import _snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import styles from './index.css';


/**
 * Get the translation for the description element.
 * The formatMessage will return the key back when there is no translation for the company.
 * Then we reuse the default message.
 *
 * @param {string} companyName
 * @param {*} formatMessage
 * @returns {string} the translated description
 */
function getDescriptionTranslation(companyName, formatMessage) {
  const companyNameKey = _lowerCase(_snakeCase(companyName));
  const formattedKey = `share_wizard.name_contract_description.${companyNameKey}`;
  let translation = formatMessage({ id: formattedKey });
  if (translation === formattedKey) {
    translation = formatMessage({ id: `share_wizard.name_contract_description.default` });
  }
  return translation;
}

const FirstStep = (props) => {
  const {
    onDocumentNameKeyDown,
    documentNamePlaceHolder,
    errors,
    touched,
    next,
    companyName,
    intl,
  } = props;

  return (
    <Fragment>
      <NameTheContract className={styles.picture} />
      <Typography variant="h3">
        <FormattedMessage id="share_wizard.name_contract" />
      </Typography>
      <Typography variant="bodySmall" className={styles.paragraph}>
        <span>{getDescriptionTranslation(companyName, intl.formatMessage)}</span>
      </Typography>
      <Field
        name="documentName"
        render={({ field }) => (
          <InputText
            {...field}
            className={styles.styledInput}
            onKeyDown={onDocumentNameKeyDown}
            autoFocus
            placeholder={documentNamePlaceHolder}
            errorMessage={touched.documentName && errors.documentName}
            startAdornment={(<DocumentIcon />)}
            testId="Page-GenericAccessLink-FirstStep-documentName-Input" />
        )} />
      <Button
        onClick={next}
        disabled={!!errors.documentName}
        className={styles.buttonNext}
        testId="Page-GenericAccessLink-FirstStep-Next-Button">
        <FormattedMessage id="shared.next" />
      </Button>
    </Fragment>
  );
};

FirstStep.propTypes = {
  onDocumentNameKeyDown: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  errors: PropTypes.shape({ documentName: PropTypes.string }).isRequired,
  touched: PropTypes.shape({ documentName: PropTypes.bool }).isRequired,
  documentNamePlaceHolder: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default FirstStep;
