import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compose } from 'redux';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withAcl } from 'services/acl';
import { Creators } from 'ducks/loggedInUser/actions';
import { ADMIN_ROLE, PRODUCER_ROLE } from 'shared/constants/user/roles';

import { MenuList, MenuItem } from 'components/MenuList';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import PeopleIcon from '@material-ui/icons/People';
import NoteIcon from '@material-ui/icons/Note';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Logo from 'components/Logo';
import UserMenu from 'components/UserMenu';

import styles from './index.css';

const links = [
  {
    url: '/templates',
    text: 'top_navbar.templates',
    permissions: [ADMIN_ROLE, PRODUCER_ROLE],
    dataTest: 'TopNavbar-templatesLink',
  },
  {
    url: '/documents',
    text: 'top_navbar.documents',
    dataTest: 'TopNavbar-documentsLink',
  },
];

export class NavigationBar extends PureComponent {
  static propTypes = { className: PropTypes.string };

  goToProfileSettings = () => {
    this.props.history.push('/settings/edit');
  };

  goToTeam = () => {
    this.props.history.push('/members');
  };

  goToTemplateStyles = () => {
    this.props.history.push('/settings/document_templates/edit');
  };

  renderLinks() {
    const { hasRole } = this.props;

    return (
      <>
        {links.map(l => (!l.permissions || hasRole(l.permissions) ? (
          <NavLink
            data-test-id={`${l.dataTest}-button`}
            key={l.url}
            to={l.url}
            className={styles.navLink}
            activeClassName={styles.navLinkActive}>
            <FormattedMessage id={l.text} />
          </NavLink>
        ) : null))}
      </>
    );
  }

  renderRoleMenu = ({ close }) => {
    const { hasRole } = this.props;

    if (!hasRole([ADMIN_ROLE])) {
      return null;
    }

    return (
      <MenuList subheader={(
        <ListSubheader classes={{ root: styles.navbarSubheader }}>
          <FormattedMessage id="top_navbar.company" />
        </ListSubheader>
      )}>
        <MenuItem
          data-test-id="TopNabar-teamLink"
          onClick={compose(
            this.goToTeam,
            close,
          )}
          dense>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText
            inset
            primary={<FormattedMessage id="top_navbar.team" />}
            classes={{ root: styles.listItemText }} />
        </MenuItem>
        <MenuItem
          data-test-id="TopNabar-documentStylesLink"
          onClick={compose(
            this.goToTemplateStyles,
            close,
          )}
          dense>
          <ListItemIcon>
            <NoteIcon />
          </ListItemIcon>
          <ListItemText
            inset
            primary={<FormattedMessage id="top_navbar.template_styles" />}
            classes={{ root: styles.listItemText }} />
        </MenuItem>
        <Divider />
      </MenuList>
    );
  };

  render() {
    const {
      className,
      startSignOut,
      userProfile: { firstName, lastName },
      hasRole,
      ...otherProps
    } = this.props;
    const classString = cn(styles.component, className);

    return (
      <div className={classString} {...otherProps}>
        <div className={styles.row}>
          <Logo />
          {this.renderLinks()}
        </div>
        <div className={styles.row}>
          <a
            data-test-id="TopNavbar-helpLink"
            className={styles.navLink}
            target="_blank"
            href="/freshdesk/help">
            <FormattedMessage id="top_navbar.help" />
          </a>
          <div className={styles.userMenuDelimiter}>
            <UserMenu
              renderCustomMenu={this.renderRoleMenu}
              goToProfileSettings={this.goToProfileSettings}
              signOut={startSignOut}
              user={{
                firstName,
                lastName,
              }} />
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = ({ loggedInUserState }) => ({ userProfile: loggedInUserState.user });
const mapDispatchToProps = { startSignOut: Creators.signOut };

export default compose(
  withRouter,
  withAcl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NavigationBar);
