import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MaterialTooltip from '@material-ui/core/Tooltip';

import styles from './index.css';

const Tooltip = ({ title, children, ...props }) => (
  <MaterialTooltip
    classes={{ tooltip: styles.tooltip }}
    title={(
      <Fragment>
        {title}
        <span className={styles.triangle} />
      </Fragment>
    )}
    placement="bottom"
    {...props}>
    {children}
  </MaterialTooltip>
);

Tooltip.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
