import assert from 'assert';
import { take, call, put } from 'redux-saga/effects';
import { lookup } from 'mime-types';
import { convertToBase64Url } from 'utils/convertFileUtils';
import { Types, Creators } from './actions';

export default function createUploadSagas({ uploadService, externalHttpClient, storageConfig }) {
  assert.ok(uploadService, 'uploadService is required');
  assert.ok(externalHttpClient, 'externalHttpClient is required');
  assert.ok(storageConfig, 'storageConfig is required');

  function* uploadSaga() {
    while (true) {
      const { filename, file } = yield take(Types.UPLOAD_REQUEST);
      try {
        const fileExt = filename.match(/\.([^.]*)$/)[1] || 'png';
        const contentType = lookup(fileExt);
        const { data } = yield call([uploadService, uploadService.upload], fileExt);
        yield externalHttpClient.put(data.url, file, { headers: { 'content-type': contentType } });
        const newFileName = `https://${storageConfig.editorAttachments.bucketName}.s3-${storageConfig.editorAttachments.bucketRegion}.amazonaws.com/${data.key}`;
        yield put(Creators.uploadSuccess(filename, newFileName));
      } catch (e) {
        yield put(Creators.uploadFailure(e));
      }
    }
  }

  function* convertSaga() {
    while (true) {
      const { filename, file } = yield take(Types.CONVERT_REQUEST);
      try {
        const newFileName = yield convertToBase64Url(file);
        yield put(Creators.convertSuccess(filename, newFileName));
      } catch (e) {
        yield put(Creators.convertFailure(e));
      }
    }
  }

  return { uploadSaga, convertSaga };
}
