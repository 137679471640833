import { put, call, take } from 'redux-saga/effects';
import assert from 'assert';
import { push } from 'connected-react-router';
import { snakeToCamelCase } from 'shared/helpers/transforms';

import { Creators, Types } from './actions';

export default function createSagas({ userService, notificationService }) {
  assert.ok(userService, 'userService is required');
  assert.ok(notificationService, 'notificationService is required');

  function* fetchUserSaga() {
    while (true) {
      const { userId } = yield take(Types.FETCH_USER_REQUEST);

      try {
        const { data } = yield call([userService, userService.fetchUser], userId);
        const user = snakeToCamelCase(data.user);
        yield put(Creators.fetchUserSuccess(user));
      } catch (e) {
        yield put(Creators.fetchUserFailure(e));
      }
    }
  }

  function* updateUserSaga() {
    while (true) {
      const { userId, userData } = yield take(Types.UPDATE_USER_REQUEST);

      try {
        const { data } = yield call([userService, userService.updateUser], userId, userData);
        const user = snakeToCamelCase(data.user);
        yield put(Creators.updateUserSuccess(user));
        yield put(push('/members'));
      } catch (e) {
        yield put(Creators.updateUserFailure(e));
        if (e.data.email) {
          yield put(notificationService.error({
            message: 'shared_components.tags_area.errors.email_exist',
            useTranslate: true,
          }));
        }
      }
    }
  }

  function* reinviteUserSaga() {
    while (true) {
      const { userId } = yield take(Types.REINVITE_USER_REQUEST);

      try {
        yield call([userService, userService.reinviteUser], userId);
        yield put(Creators.reinviteUserSuccess());
      } catch (e) {
        yield put(Creators.reinviteUserFailure(e));
      }
    }
  }

  return {
    fetchUserSaga,
    updateUserSaga,
    reinviteUserSaga,
  };
}
