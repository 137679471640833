import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { MOBILE_BREAKPOINT } from 'constants/viewBreakpoints';

class MobileView extends PureComponent {
  static propTypes = { children: PropTypes.func.isRequired }

  state = { isMobile: false }

  componentDidMount() {
    window.addEventListener('resize', this.setIsMobile);
    this.setIsMobile();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setIsMobile);
  }

  setIsMobile = () => {
    this.setState({ isMobile: window.innerWidth < MOBILE_BREAKPOINT });
  }

  render() {
    return this.props.children({ isMobile: this.state.isMobile });
  }
}

export default MobileView;
