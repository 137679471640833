exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-pages-Templates-components-TableRows-TemplateTableRow-___index__component___166Jb {\n  cursor: pointer;\n}\n\n  .app-pages-Templates-components-TableRows-TemplateTableRow-___index__component___166Jb td {\n    padding: 0;\n  }\n\n  .app-pages-Templates-components-TableRows-TemplateTableRow-___index__component___166Jb td > a {\n      padding: 15px 24px;\n      display: block;\n    }\n", "", {"version":3,"sources":["/workspace/app/pages/Templates/components/TableRows/TemplateTableRow/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAe;CAUjB;;EARE;IACE,WAAU;GAMZ;;EAJE;MACE,mBAAkB;MAClB,eAAc;KAChB","file":"index.css","sourcesContent":[".component {\n  cursor: pointer;\n\n  td {\n    padding: 0;\n\n    & > a {\n      padding: 15px 24px;\n      display: block;\n    }\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-pages-Templates-components-TableRows-TemplateTableRow-___index__component___166Jb"
};