import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import keycode from 'keycode';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withFormik } from 'formik';
import * as v from 'shared/validation';
import { Creators as DocumentsActions } from 'pages/Documents/actions';
import { Creators as TemplatesActions } from 'pages/Templates/reducer';
import Carousel from 'shared/Components/Carousel';
import NavigationDots from 'components/NavigationDots';
import PageSpinner from 'components/PageSpinner';
import Logo from 'components/Logo';
import WhiteLabelLogo from 'bundles/Questionnaire2/TopToolbar/WhiteLabelLogo';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

import styles from './index.css';

const WhiteLabelLogoWrapper = () => (<div className={styles.logo}><WhiteLabelLogo isWhiteLabelCompany large /></div>);

export class GenericAccessWizard extends React.PureComponent {
  static propTypes = {
    templateSharingValid: PropTypes.bool,
    validateSharingTemplate: PropTypes.func,
    validateForm: PropTypes.func,
    createGenericAccessDocument: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    documentName: PropTypes.string.isRequired,
    values: PropTypes.shape(),
    intl: intlShape.isRequired,
  };

  state = { index: 0 };

  componentDidMount() {
    const { validateForm, match: { params }, validateSharingTemplate } = this.props;
    validateSharingTemplate(params.templateSharingSlug);
    // TODO: Remove this validation when PR gets merged and formik updated, move initial errors to formik config https://github.com/jaredpalmer/formik/pull/622
    validateForm();
  }

  moveTo = index => () => this.setState({ index });

  handleSubmit = (event) => {
    const { values, submitForm, createGenericAccessDocument, match: { params } } = this.props;
    const { email, documentName } = values;

    event.preventDefault();
    createGenericAccessDocument(documentName, email, params.templateSharingSlug);
    submitForm();
  };

  onDocumentNameKeyDown = (event) => {
    const { errors } = this.props;

    if (event.keyCode === keycode('enter') && !errors.documentName) {
      this.moveTo(1)();
    }
  };

  navigationChanged = n => this.setState({ index: n });

  render() {
    const { documentName, errors, isValid, templateSharingValid, isWhiteLabel } = this.props;
    const { index } = this.state;

    if (!templateSharingValid) {
      return <PageSpinner />;
    }
    return (
      <form onSubmit={this.handleSubmit} className={styles.wrapper}>
        { isWhiteLabel ? <WhiteLabelLogoWrapper /> : <Logo className={styles.logo} /> }
        <div className={styles.modal}>
          <Carousel index={index}>
            <div className={styles.slide}>
              <FirstStep
                {...this.props}
                errors={errors}
                onDocumentNameKeyDown={this.onDocumentNameKeyDown}
                next={this.moveTo(1)}
                documentNamePlaceHolder={documentName} />
            </div>
            <div className={styles.slide}>
              <SecondStep
                {...this.props}
                autofocus={index === 1}
                isValid={isValid}
                submit={this.handleSubmit} />
            </div>
          </Carousel>
          <NavigationDots
            current={index}
            count={2}
            onChange={this.navigationChanged} />
        </div>
      </form>
    );
  }
}

export const formikConfig = {
  mapPropsToValues: () => ({ documentName: '', email: '', acceptedTerms: false }),
  isInitialValid: false,
  validate: (values, props) => {
    const errors = {};
    const { documentName, email, acceptedTerms } = values;
    if (v.required(documentName)) {
      errors.documentName = props.intl.formatMessage({ id: 'share_wizard.errors.no_document_title' });
    }
    if (v.email(email) || v.required(email)) {
      errors.email = props.intl.formatMessage({ id: 'share_wizard.errors.invalid_email' });
    }
    if (v.required(acceptedTerms)) {
      errors.acceptedTerms = 'required';
    }
    return errors;
  },
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(true);
  },
};

const mapStateToProps = state => ({
  templateSharingValid: state.templates.templateSharingValid,
  documentName: state.templates.name,
  isWhiteLabel: state.questionnaire.company.white_label_module,
});

const mapDispatchToProps = {
  validateSharingTemplate: TemplatesActions.validateSharingTemplateRequest,
  createGenericAccessDocument: DocumentsActions.createGenericAccessDocumentRequest,
};

export const GenericAccessWizardWithFormik = compose(injectIntl, withFormik(formikConfig))(GenericAccessWizard);

export default compose(connect(mapStateToProps, mapDispatchToProps))(GenericAccessWizardWithFormik);
