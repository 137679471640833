import assert from 'assert';
import ApiClient from '../ApiClient';

export default class QuestionService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  createQuestion = params => this.apiClient.request('/questions', {
    method: 'post',
    data: { question: params },
  })

  updateQuestion = (questionId, params) => {
    assert.ok(questionId, 'questionId<string> is required');

    return this.apiClient.request(`/questions/${questionId}`, {
      method: 'patch',
      data: { question: params },
    });
  }

  deleteQuestion = (questionId) => {
    assert.ok(questionId, 'questionId<string> is required');

    return this.apiClient.request(`/questions/${questionId}`, { method: 'delete' });
  }
}
