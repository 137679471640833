import cheerio from 'cheerio';

const CHANGE_TAGS = { h5: 'h4', h6: 'h4' };
export default function changeTags(html) {
  const $$ = cheerio.load(html);
  $$('*').each((index, item) => {
    const changeTo = CHANGE_TAGS[item.tagName];
    if (changeTo) item.tagName = changeTo; // eslint-disable-line no-param-reassign
    if (
      item.tagName === 'div'
      && !item.attribs['data-question']
      && !item.attribs['data-question-container']
    ) {
      item.tagName = 'p'; // eslint-disable-line no-param-reassign
    }
  });
  return $$('body').html();
}
