/* eslint-disable consistent-return,no-throw-literal */
import rangy from 'rangy';
import 'rangy/lib/rangy-textrange.js';
import isQuestion from 'Visualization/helpers/isQuestion';
import removeEmptyQuestionNodes from 'Visualization/helpers/removeEmptyQuestionNodes';
import getQuestionInlineNodesTree from './getQuestionInlineNodesTree';
import NodesHelper from './nodesHelper';

export default class ExtendSelection {
  constructor(logicType) {
    this.logicType = logicType;
    this.nodesHelper = new NodesHelper();
  }

  call() {
    try {
      this.handleQuestionFullySelected();
      this.handleLists();
      this.handleSameExpandableNodes();
      this.handleMultipleBlocks();
      this.handleInlineSelection();
      return true;
    } catch (msg) {
      // delete contents might leave empty question tag, we should remove it as it causes problems
      removeEmptyQuestionNodes();
      return true;
    }
  }

  handleQuestionFullySelected = () => {
    const { questionNodeFullySelected, leftNode, rightNode } = this.nodesHelper;
    if (this.logicType !== 'inputReplaceType' && questionNodeFullySelected(this.logicType)) {
      // case 3
      // https://lawbotics.atlassian.net/wiki/spaces/PD/pages/9011201/Triggers+functionality+edgecase
      const selection = rangy.getSelection();
      const range = selection.getRangeAt(0);
      range.setStartBefore(leftNode);
      range.setEndAfter(rightNode);
      selection.setSingleRange(range);
      throw 'handleQuestionFullySelected';
    }
  };

  handleLists = () => {
    const { leftNode, rightNode, findNodeInTreeOf } = this.nodesHelper;
    const leftLiNode = findNodeInTreeOf(leftNode, 'LI')[0];
    const rightLiNode = findNodeInTreeOf(rightNode, 'LI')[0];
    if (leftLiNode && rightLiNode && leftLiNode !== rightLiNode) {
      // if nodes are list elements we should exand selection
      // to their parent UL/OL
      const olOrUlElement = leftLiNode.parentNode;
      wrapWholeList(olOrUlElement);
      throw 'handleLists';
    }
  };

  handleSameExpandableNodes = () => {
    const { leftNodeNearestExpandable, rightNodeNearestExpandable } = this.nodesHelper;
    if (leftNodeNearestExpandable === rightNodeNearestExpandable) {
      extendSelectionOnBothEdges();
      throw 'handleSameExpandableNodes';
    }
  };

  handleMultipleBlocks = () => {
    const {
      multipleBlocksSelected,
      leftNodeNearestBlock,
      leftNodeNearestQuestionBlock,
      leftNodeFarrestQuestionBlock,
      rightNodeNearestBlock,
      rightNodeNearestQuestionBlock,
      rightNodeFarrestQuestionBlock,
      sharedContainer,
      findFirstQuestionBlockAfter,
      leftNode,
      rightNode,
    } = this.nodesHelper;

    if (!multipleBlocksSelected) return;
    const selectionIsInsideOneQuestionBlock = leftNodeNearestQuestionBlock === rightNodeNearestQuestionBlock;
    const bothNodesAreQuestionBlocks = leftNodeNearestQuestionBlock && rightNodeNearestQuestionBlock;

    if (bothNodesAreQuestionBlocks) {
      if (selectionIsInsideOneQuestionBlock) {
        extendSelectionLeftEdge(leftNodeNearestBlock);
        extendSelectionRightEdge(rightNodeNearestBlock);
        throw 'handleMultipleBlocks - extend to nearest blocks';
      }
      if (isQuestion(sharedContainer)) {
        // if first common container for left and right node is question
        // we should find penultimate question block for each left and rightNode
        // and extend selection to wrap those penultimate question blocks
        // we added leftNodeNearestBlock
        // cos its possible that in left node tree there is only one question block
        // so we cant find any penulimate, so next valid block to extend is leftNodeNearestBlock
        const leftNearestBlockToExtend = findFirstQuestionBlockAfter(leftNode, sharedContainer) || leftNodeNearestBlock;
        const rightNearestBlockToExtend = findFirstQuestionBlockAfter(rightNode, sharedContainer) || rightNodeNearestBlock;
        extendSelectionLeftEdge(leftNearestBlockToExtend);
        extendSelectionRightEdge(rightNearestBlockToExtend);
        throw 'handleMultipleBlocks - common anscestor is question ';
      } else {
        extendSelectionLeftEdge(leftNodeFarrestQuestionBlock);
        extendSelectionRightEdge(rightNodeFarrestQuestionBlock);
        throw 'handleMultipleBlocks - common anscestor is not question';
      }
    }

    if (leftNodeNearestQuestionBlock) {
      // case
      // partialy selected question block and
      // partialy selected non question block text
      extendSelectionLeftEdge(leftNodeFarrestQuestionBlock);
      extendSelectionRightEdge(rightNodeNearestBlock);
      throw 'handleMultipleBlocks 2';
    }

    if (rightNodeNearestQuestionBlock) {
      // case
      // partialy selected question block and
      // partialy selected non question block text
      extendSelectionRightEdge(rightNodeFarrestQuestionBlock);
      extendSelectionLeftEdge(leftNodeNearestBlock);
      throw 'handleMultipleBlocks 3';
    }

    // case
    // partialy selected two text blocks
    const newRange = rangy.createRange();
    newRange.setStartBefore(leftNodeNearestBlock);
    newRange.setEndAfter(rightNodeNearestBlock);
    rangy.getSelection().setSingleRange(newRange);
    throw 'handleMultipleBlocks 4';
  };

  handleInlineSelection = () => {
    const {
      leftNode,
      rightNode,

      leftNodeNearestInlineQuestion,
      rightNodeNearestInlineQuestion,

      leftNodeFarrestInlineQuestion,
      rightNodeFarrestInlineQuestion,
    } = this.nodesHelper;
    const selectionIsInsideInlineQuestion = leftNodeNearestInlineQuestion && rightNodeNearestInlineQuestion;

    if (selectionIsInsideInlineQuestion) {
      const leftNodeNestLevel = getQuestionInlineNodesTree(leftNode).length;
      const rightNodeNestLevel = getQuestionInlineNodesTree(rightNode).length;
      if (leftNodeNestLevel > rightNodeNestLevel) {
        // if left node has more children then it means
        // that it is higher in dom hierarchy, so we should expand selection
        // to wrap this node
        extendSelectionLeftEdge(leftNodeNearestInlineQuestion);
        throw 'handleInlineSelection - extend to left';
      }
      if (leftNodeNestLevel < rightNodeNestLevel) {
        extendSelectionRightEdge(rightNodeNearestInlineQuestion);
        throw 'handleInlineSelection - extend to right';
      }
      throw 'handleInlineSelection - done nothing';
    }
    if (leftNodeNearestInlineQuestion) {
      extendSelectionLeftEdge(leftNodeFarrestInlineQuestion);
      throw 'handleInlineSelection - 2';
    }
    if (rightNodeNearestInlineQuestion) {
      extendSelectionRightEdge(rightNodeFarrestInlineQuestion);
      throw 'handleInlineSelection - 3';
    }

    throw 'handleInlineSelection';
  };
}

function wrapWholeList(listNode) {
  const sel = rangy.getSelection();
  const range = rangy.createRange();
  range.selectNode(listNode);
  sel.setSingleRange(range);
}

function extendSelectionOnBothEdges() {
  // CASE 6
  // https://lawbotics.atlassian.net/wiki/spaces/PD/pages/9011201/Triggers+functionality+edgecase
  const sel = rangy.getSelection();
  const range = sel.getRangeAt(0);
  const tempContainer = document.createElement('div');
  let lastNode;
  // we are wrapping range html contents with temp elements so we can extend selection
  tempContainer.appendChild(createTempElement());
  tempContainer.appendChild(range.cloneContents());
  tempContainer.appendChild(createTempElement());
  range.deleteContents();

  const frag = document.createDocumentFragment();

  while (tempContainer.firstChild) {
    const node = tempContainer.firstChild;
    lastNode = node;
    frag.appendChild(node);
  }
  const firstNode = frag.firstChild;
  range.insertNode(frag);

  // Preserve the selection
  if (lastNode) {
    const sRange = range.cloneRange();
    sRange.setStartAfter(lastNode);
    sRange.setStartBefore(firstNode);
    sel.setSingleRange(sRange);
  }
}

function extendSelectionLeftEdge(node) {
  const sel = rangy.getSelection();
  const range = sel.getRangeAt(0);
  const extendedRange = range.cloneRange();
  const whitespace = createTempElement();
  $(whitespace).insertBefore(node);
  extendedRange.setStart(whitespace, 0);

  if (!range.containsNode(node) && extendedRange.containsNode(node)) {
    // https://lawbotics.atlassian.net/wiki/spaces/PD/pages/9011201/Triggers+functionality+edgecase
    // case 2
    // if selection ends on edge of rightnode range should not contain that node,
    // but if we add 1 char and it is true then we are sure it was on edge
    // so add whitespace to allow editor wrap that selection with question tag
    sel.setSingleRange(extendedRange);
  }
}

function extendSelectionRightEdge(node) {
  const sel = rangy.getSelection();
  const range = sel.getRangeAt(0);
  const extendedRange = range.cloneRange();
  const whitespace = createTempElement();
  $(whitespace).insertAfter(node);
  extendedRange.setEnd(whitespace, 1);

  if (!range.containsNode(node) && extendedRange.containsNode(node)) {
    // https://lawbotics.atlassian.net/wiki/spaces/PD/pages/9011201/Triggers+functionality+edgecase
    // case 1
    // if selection ends on edge of rightnode we get false,
    // but if we add 1 char and it is true then we are sure it was on edge
    // so add whitespace to allow editor wrap that selection with question tag
    sel.setSingleRange(extendedRange);
  }
}
function createTempElement() {
  const whitespace = document.createElement('span');
  whitespace.innerHTML = '&nbsp;';
  whitespace.className = 'temp-whitespace-for-selection-extend';
  return whitespace;
}
