import React, { PureComponent, Fragment } from 'react';
import NavigationBar from 'components/NavigationBar';
import Page from 'components/Page';
import PageHeader from './components/PageHeader';
import TrashTable from './components/Table';

export default class TrashPage extends PureComponent {
  render() {
    return (
      <Fragment>
        <NavigationBar />
        <PageHeader />
        <Page>
          <TrashTable />
        </Page>
      </Fragment>
    );
  }
}
