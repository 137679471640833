import styled from 'styled-components';
import Rotate from 'shared/Components/Rotate';

const Styled = styled.div`
  color: ${props => (props.warning ? props.theme.buttons.red : props.theme.font.light)};
  align-self: center;
  margin-right: 20px;
  align-self: ${p => (p.questionnaire ? 'auto' : 'center')};
  svg {
    vertical-align: middle;
    margin-right: 5px;
  }
  .animate {
    animation: ${Rotate} 2s linear infinite;
  }
  ${props => props.warning && `
  padding: 10px;
  border: 1px solid #e8cac3;
  border-radius: 4px;
  background-color: #f7d2cd;
  line-height: 14px;
  margin-right: 10px;
  `};
`;

export default Styled;
