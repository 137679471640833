import React from 'react';

export default function FindBlackContract(props) {
  return (
    <svg
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 188.0970001220703 184"
      xmlSpace="preserve"
      height="182"
      width="186"
      y="0px"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1_1538986129507"
      {...props}>
      <g transform="translate(1, 1)">
        <path
          d="M145.314,145.295c0,4.794-3.886,8.679-8.68,8.679H52.014c-4.792,0-8.679-3.885-8.679-8.679&#10;&#9;V34.47c0-4.793,3.887-8.679,8.679-8.679h84.621c4.794,0,8.68,3.886,8.68,8.679V145.295z"
          fill="currentColor"
          opacity="0.15"
          vectorEffect="non-scaling-stroke" />
        <path
          d="M186.097,158.323c0,9.426-41.657,17.066-93.048,17.066C41.661,175.389,0,167.749,0,158.323&#10;&#9;c0-9.422,41.661-17.064,93.049-17.064C144.439,141.259,186.097,148.901,186.097,158.323z"
          fill="currentColor"
          opacity="0.08"
          vectorEffect="non-scaling-stroke" />
        <path
          d="M46.856,74.739"
          strokeDasharray="4,4"
          strokeMiterlimit="10"
          strokeWidth="2"
          stroke="#A7BBD1"
          fill="none"
          vectorEffect="non-scaling-stroke" />
        <path
          d="M159.417,45.003"
          strokeDasharray="4,4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeWidth="2"
          stroke="#A7BBD1"
          fill="none"
          vectorEffect="non-scaling-stroke" />
        <path
          d="M76.131,70.046"
          strokeDasharray="4,4"
          strokeMiterlimit="10"
          strokeWidth="2"
          stroke="#A7BBD1"
          fill="none"
          vectorEffect="non-scaling-stroke" />
        <path
          d="M131.366,80.378"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeWidth="2"
          stroke="#FFFFFF"
          fill="#FFFFFF"
          vectorEffect="non-scaling-stroke" />
        <line
          y2="47.154"
          x2="123.294"
          y1="47.154"
          x1="65.354"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeWidth="3"
          stroke="#FFFFFF"
          fill="#FFFFFF"
          vectorEffect="non-scaling-stroke" />
        <line
          y2="81.571"
          x2="123.294"
          y1="81.571"
          x1="65.354"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeWidth="3"
          stroke="#FFFFFF"
          fill="#FFFFFF"
          vectorEffect="non-scaling-stroke" />
        <line
          y2="63.895"
          x2="123.294"
          y1="63.895"
          x1="65.354"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeWidth="3"
          stroke="#FFFFFF"
          fill="#FFFFFF"
          vectorEffect="non-scaling-stroke" />
        <path
          d="M127.663,106.922c-8.911-8.912-23.359-8.912-32.271,0c-8.91,8.911-8.91,23.36,0,32.272&#10;&#9;c7.748,7.748,19.679,8.755,28.52,3.032l18.233,18.232c2.135,2.136,5.598,2.136,7.732,0c2.137-2.135,2.136-5.597,0-7.733&#10;&#9;l-18.468-18.468C136.315,125.558,135.071,114.328,127.663,106.922z M121.065,132.595c-5.268,5.268-13.809,5.268-19.075,0&#10;&#9;c-5.267-5.267-5.267-13.807,0-19.075c5.268-5.268,13.808-5.268,19.075,0C126.333,118.788,126.333,127.327,121.065,132.595z"
          fill="#FFFFFF"
          vectorEffect="non-scaling-stroke" />
        <line
          y2="100.238"
          x2="80.304"
          y1="100.238"
          x1="64.099"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeWidth="3"
          stroke="#FFFFFF"
          fill="#FFFFFF"
          vectorEffect="non-scaling-stroke" />
        <line
          y2="118"
          x2="76.384"
          y1="118"
          x1="63.769"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeWidth="3"
          stroke="#FFFFFF"
          fill="#FFFFFF"
          vectorEffect="non-scaling-stroke" />
      </g>
    </svg>
  );
}
