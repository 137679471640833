import getEnviromentConfig from '../../config/enviroments';
/**
 * Gets a value of environment variable with a given name.
 * If value contains only whitespaces or is equal to environment substitution
 * (dollar sign and name fe. $ENV) this function returns undefined.
 * If the value is a string 'true' or 'false' a proper boolean value will be returned.
 *
 * $ENV for name=ENV will result in undefined
 * " " will result in undefined
 * "true" will result in true
 * "false" will result in false
 *
 * @param {string} name The name of environment variable
 * @return {string} Value of environment variable
 */

export const getEnv = (name) => {
  const appConfig = getEnviromentConfig((window._appConfig || {}).APP_ENV);

  const value = appConfig[name];
  if (value === undefined || value.trim().length === 0 || value === `$${name}`) {
    return undefined;
  } if (value === 'false') {
    return false;
  } if (value === 'true') {
    return true;
  }
  return value;
};
