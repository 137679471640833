import { connect } from 'react-redux';
import _get from 'lodash/get';
import SaveNotification from 'shared/TopToolbar/SaveNotification';
import { FAILED, SAVED, SAVING, NONE } from 'shared/constants/saveStatus';

import { Types } from 'pages/DocumentDetails/actions';
import { selectCurrentDocumentVersion } from 'pages/DocumentDetails/selectors';
import { selectIsQuestionnairePreview } from 'ducks/questionnaire/selectors';
import { getAPICallStatus } from '../../../utils/statusSelectors';

function getDocumentVersionSavingStatus(actionStatus) {
  if (!actionStatus) {
    return NONE;
  } if (actionStatus.isFinished) {
    return SAVED;
  } if (actionStatus.hasResponseError) {
    return FAILED;
  }
  return SAVING;
}

function mapStateToProps(state) {
  const currentVersion = selectCurrentDocumentVersion(state);
  const isQuestionnairePreview = selectIsQuestionnairePreview(state);
  const isCurrentVersionQuestionnaire = _get(currentVersion, 'isQuestionnaire', false);

  if (isCurrentVersionQuestionnaire || isQuestionnairePreview) {
    return { savingStatus: state.questionnaire.savingStatus };
  }
  const action = Types.UPDATE_CURRENT_VERSION_CONTENT_REQUEST.replace('/', '_').replace('_REQUEST', '');
  const documentVersionUpdateActionStatus = getAPICallStatus(state, action);
  const savingStatus = getDocumentVersionSavingStatus(documentVersionUpdateActionStatus);
  return { savingStatus };
}
export default connect(mapStateToProps)(SaveNotification);
