import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import getInitials from 'utils/getInitials';
import Tooltip from 'components/Tooltip';

import styles from './index.css';

const ownerTypes = {
  WhiteLabelUser: {
    messageId: 'panel.questionnaire_templates.template_documents.white_label',
    imageSrc: '/assets/icons/white-label.svg',
    imageSize: 16,
  },
  GenericAccessUser: {
    messageId: 'panel.questionnaire_templates.template_documents.generic_access_link',
    imageSrc: '/assets/icons/generic-access.svg',
    imageSize: 20,
  },
};

const OwnerCell = ({ owner: { firstName, lastName, email, role, type } }) => {
  const ownerTypeValues = ownerTypes[type];
  const fullName = type === 'GenericAccessUser' ? email : `${firstName} ${lastName}`;
  const avatarClassname = cn(styles.avatar, {
    [styles.avatarProducer]: role === 'producer',
    [styles.avatarConsumer]: role === 'consumer',
  });

  return (
    <div className={styles.component}>
      <div className={avatarClassname}>
        {getInitials(firstName, lastName, email)}
        { ownerTypeValues && (
        <Tooltip title={<FormattedMessage id={ownerTypeValues.messageId} />}>
          <img
            alt=""
            className={styles.ownerTypeIcon}
            src={ownerTypeValues.imageSrc}
            height={ownerTypeValues.imageSize}
            width={ownerTypeValues.imageSize} />
        </Tooltip>
        )}
      </div>
      <div className={styles.userDetails}>
        <div className={styles.name}>{fullName}</div>
        <div className={styles.email}>{email}</div>
      </div>
    </div>
  );
};

OwnerCell.propTypes = {
  owner: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default OwnerCell;
