import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { rem } from 'polished';
import { HelpTextEditorContainer, HelpTextEditorWrapper, Label } from './styled-components';

class HelpTextEditor extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.string,
    onChangeFn: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: '',
    value: '',
  };

  componentDidMount() {
    document.body.classList.add('lex-hide-mce-tooltips');
  }

  componentWillUnmount() {
    document.body.classList.remove('lex-hide-mce-tooltips');
  }

  handleChange = (value) => {
    const { onChangeFn, name } = this.props;
    onChangeFn(name, value);
  };

  render() {
    const { value, name, label } = this.props;
    return (
      <HelpTextEditorContainer>
        {label ? <Label htmlFor={`logics_manager_${name}`}>{label}</Label> : null}
        <HelpTextEditorWrapper>
          <Editor
            value={value}
            id={`logics_manager_${name}`}
            name={name}
            onEditorChange={this.handleChange}
            init={{
              browser_spellcheck: true,
              theme: 'modern',
              menubar: false,
              statusbar: false,
              skin: false,
              toolbar: 'bold italic underline link',
              plugins: 'link autolink autoresize',
              link_assume_external_targets: true,
              default_link_target: 'blank',
              invalid_styles: 'color font-size background-color',
              link_title: false,
              target_list: false,
              anchor_bottom: false,
              anchor_top: false,
              link_context_toolbar: true,
              autoresize_max_height: 150,
              autoresize_min_height: 50,
              autoresize_bottom_margin: 0,
              width: '100%',
              content_style: `
                .mce-content-body {
                  font-size:${rem('14px')};
                  font-family:sans-serif;
                } 
                p { 
                  margin-bottom: 10px; 
                  line-height: 20px; 
                }
              `,
            }} />
        </HelpTextEditorWrapper>
      </HelpTextEditorContainer>
    );
  }
}

export default HelpTextEditor;
