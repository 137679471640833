import React, { Fragment } from 'react';

import GridItem from 'components/Grid/Item';
import PropTypes from 'prop-types';
import Editing from '../modules/Editing';
import DownloadPdf from '../modules/DownloadPdf';

const Rejected = ({ isMobile }) => (
  <Fragment>
    <GridItem xs="auto" sm="auto">
      <Editing isMobile={isMobile} />
    </GridItem>
    <GridItem xs="auto" sm="auto">
      <DownloadPdf isMobile={isMobile} />
    </GridItem>
  </Fragment>
);

Rejected.propTypes = { isMobile: PropTypes.bool };

export default Rejected;
