import keycode from 'keycode';
import removeEmptyQuestionNodes from 'Visualization/helpers/removeEmptyQuestionNodes';
import mergeSimilarNodes from 'Visualization/helpers/mergeSimilarNodes';

const normalizeHtmlContentOnKeyup = (e) => {
  if (e.keyCode === keycode('backspace') || e.keyCode === keycode('enter')) {
    removeEmptyQuestionNodes();
    mergeSimilarNodes();
  }
  return true;
};

export default normalizeHtmlContentOnKeyup;
