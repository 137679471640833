import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


import { ActionCell } from '../components/TableCells/ActionCell';
import { Creators } from '../reducer';

export const mapStateToProps = state => ({
  folders: state.templates.folderDialogData.folders,
  currentFolder: state.templates.folderDialogData.currentFolder,
  currentViewFolder: state.templates.currentFolder,
});
export const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ActionCell);
