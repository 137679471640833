import 'tinymce/tinymce';
import 'tinymce/themes/modern';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/save';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/table';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/template';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/toc';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/autoresize';

/* istanbul ignore file */
export function editorSkinLoader() {
  const skinLoader = require.context('tinymce/skins', true, /.*(css|gif)/);

  skinLoader
    .keys()
    .filter(key => ['./lightgray/content.min.css'].indexOf(key) === -1)
    .map(key => skinLoader(key));
}
