import React, { Component } from 'react';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { VALIDATION_CODES } from 'shared/validation/constants';
import moment, { toTimestamp, defaultLocale, defaultFormat } from 'shared/date';
import ClickAway from 'shared/Components/ClickAway';
import DatepickerInput from './DatepickerInput';
import styles from './styles.css';

export class DatepickerComponent extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    form: PropTypes.shape({
      setFieldValue: PropTypes.func.isRequired,
      setFieldError: PropTypes.func.isRequired,
      errors: PropTypes.object, // eslint-disable-line
    }),
    field: PropTypes.shape({ name: PropTypes.string }),
    defaultValue: PropTypes.instanceOf(Date),
    lang: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    resetValue: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = { lang: defaultLocale };

  state = { isOpen: false };

  handleDateChange = (date) => {
    this.updateDate(date);
    this.closeDatepicker();
  };

  handleInputChange = (e) => {
    const { value } = e.target;
    this.props.form.setFieldValue(this.props.field.name, value);
  };

  handleInputBlur = (e) => {
    const { value } = e.target;
    const date = moment(value, defaultFormat, this.props.lang);
    if (date.isValid()) {
      this.updateDate(date);
    } else {
      this.props.resetValue(this.props.field.name);
      this.props.form.setFieldError(
        this.props.field.name,
        this.props.intl.formatMessage(
          { id: VALIDATION_CODES.DATE },
          { format: this.getDateFormat() },
        ),
      );
    }
  };

  getDateFormat = () => moment.localeData(this.props.lang).longDateFormat('L').toLowerCase();

  maskFactory = () => this.getDateFormat().replace(/[a-z]/g, '9');

  updateDate = (date) => {
    const timestamp = toTimestamp(date);
    this.props.form.setFieldValue(this.props.field.name, timestamp);
    this.props.form.setFieldError(this.props.field.name, '');
    this.props.onChange(timestamp);
  };

  closeDatepicker = () => {
    this.setState({ isOpen: false });
  };

  toggleDatepicker = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  render() {
    const { defaultValue, field } = this.props;
    const dateValue = defaultValue || (field && new Date(field.value));
    const errorMessage = this.props.form.errors[field.name];

    return (
      <ClickAway onClickAway={this.closeDatepicker}>
        <div className={styles.component}>
          <DatepickerInput
            onBlur={this.handleInputBlur}
            onChange={this.handleInputChange}
            onClick={this.closeDatepicker}
            placeholder={this.props.placeholder}
            format={defaultFormat}
            lang={this.props.lang}
            open={this.state.isOpen}
            field={this.props.field}
            mask={this.maskFactory()}
            toggleDatepicker={this.toggleDatepicker} />
          {errorMessage && (
            <div className={styles.errorMessage}>
              {errorMessage}
            </div>
          )}
          <Datetime
            className="date-time-component"
            dateFormat={defaultFormat}
            value={dateValue}
            input={false}
            locale={this.props.lang}
            onChange={this.handleDateChange}
            open={this.state.isOpen}
            timeFormat={false} />
        </div>
      </ClickAway>
    );
  }
}

export default injectIntl(DatepickerComponent);
