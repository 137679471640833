import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { HeaderWrapper, HeaderText, StyledHeaderCard } from './styled-components';

const PageHeaderView = ({ children, title, subtitle, iconElement }) => (
  <HeaderWrapper>
    <HeaderText>
      <StyledHeaderCard>
        {iconElement}
      </StyledHeaderCard>
      <div>
        <Typography variant="h5" data-test-id="PageHeader-title">
          {title}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" data-test-id="PageHeader-subtitle">
          {subtitle}
        </Typography>
      </div>
    </HeaderText>
    <div>
      {children}
    </div>
  </HeaderWrapper>
);

PageHeaderView.propTypes = {
  children: PropTypes.node,
  iconElement: PropTypes.element.isRequired,
  subtitle: PropTypes.node,
  title: PropTypes.node,
};

export default PageHeaderView;
