exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-bundles-Questionnaire2-___PreviousQuestion__component___3Ltmy {\n  align-self: flex-start;\n  margin-bottom: 12px;\n}\n", "", {"version":3,"sources":["/workspace/app/bundles/Questionnaire2/PreviousQuestion.css"],"names":[],"mappings":"AAAA;EACE,uBAAsB;EACtB,oBAAmB;CACrB","file":"PreviousQuestion.css","sourcesContent":[".component {\n  align-self: flex-start;\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-bundles-Questionnaire2-___PreviousQuestion__component___3Ltmy"
};