import React from 'react';

const SkippedQuestionIcon = () => (
  <svg
    version="1.1"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 200.097 191.602"
    className="ng-element"
    data-id="184ec78429504f399ef52446e2d0c4a0"
    style={{
      mixBlendMode: 'normal',
      overflow: 'visible',
      width: '178px',
      height: '171px',
      filter: 'none',
    }}
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill="#FFFFFF"
        d="M163.405,169.354c0,5.879-4.767,10.645-10.646,10.645H48.972c-5.879,0-10.646-4.766-10.646-10.645V16.727&#10;&#9;&#9;c0-5.879,4.767-10.645,10.646-10.645H152.76c5.879,0,10.646,4.766,10.646,10.645V169.354z"
        vectorEffect="non-scaling-stroke" />
      <path
        fill="#92B8DD"
        d="M152.76,180.998H48.972c-6.421,0-11.646-5.224-11.646-11.645V16.727c0-6.421,5.224-11.645,11.646-11.645&#10;&#9;&#9;H152.76c6.421,0,11.646,5.224,11.646,11.645v152.626C164.405,175.774,159.181,180.998,152.76,180.998z M48.972,7.082&#10;&#9;&#9;c-5.318,0-9.646,4.327-9.646,9.645v152.626c0,5.318,4.327,9.645,9.646,9.645H152.76c5.318,0,9.646-4.326,9.646-9.645V16.727&#10;&#9;&#9;c0-5.318-4.327-9.645-9.646-9.645H48.972z"
        vectorEffect="non-scaling-stroke" />
    </g>
    <g opacity="0.15">
      <g>
        <path
          fill="#419AF2"
          d="M163.405,169.354c0,5.879-4.767,10.645-10.646,10.645H48.972c-5.879,0-10.646-4.766-10.646-10.645V16.727&#10;&#9;&#9;&#9;c0-5.879,4.767-10.645,10.646-10.645H152.76c5.879,0,10.646,4.766,10.646,10.645V169.354z"
          vectorEffect="non-scaling-stroke" />
      </g>
    </g>
    <g opacity="0.08">
      <g>
        <ellipse fill="#3E94F6" cx="100.048" cy="173.253" rx="100.048" ry="18.349" vectorEffect="non-scaling-stroke" />
      </g>
    </g>
    <g>
      <g>
        <path
          fill="#FFFFFF"
          d="M138.366,170.319h-87.45c-2.388,0-4.321-1.934-4.321-4.318V18.418c0-2.384,1.934-4.317,4.321-4.317h98.268&#10;&#9;&#9;&#9;c2.385,0,4.319,1.934,4.319,4.317v131.237"
          vectorEffect="non-scaling-stroke" />
      </g>
      <g>
        <path
          fill="#304555"
          d="M138.366,171.319h-87.45c-2.934,0-5.321-2.386-5.321-5.318V18.418c0-2.932,2.387-5.317,5.321-5.317h98.268&#10;&#9;&#9;&#9;c2.933,0,5.319,2.385,5.319,5.317v131.237h-2V18.418c0-1.829-1.489-3.317-3.319-3.317H50.916c-1.832,0-3.321,1.488-3.321,3.317&#10;&#9;&#9;&#9;v147.583c0,1.83,1.49,3.318,3.321,3.318h87.45V171.319z"
          vectorEffect="non-scaling-stroke" />
      </g>
    </g>
    <g>
      <path
        fill="#3E94F6"
        d="M73.039,108.689c-4.111,0-7.455-3.345-7.455-7.455c0-4.111,3.344-7.455,7.455-7.455&#10;&#9;&#9;c4.111,0,7.456,3.344,7.456,7.455C80.495,105.345,77.15,108.689,73.039,108.689z M73.039,95.779c-3.008,0-5.455,2.447-5.455,5.455&#10;&#9;&#9;s2.447,5.455,5.455,5.455c3.008,0,5.456-2.447,5.456-5.455S76.047,95.779,73.039,95.779z"
        vectorEffect="non-scaling-stroke" />
    </g>
    <g>
      <g>
        <line fill="#FFFFFF" x1="96.009" y1="39.951" x2="133.515" y2="39.951" vectorEffect="non-scaling-stroke" />
      </g>
      <g>
        <rect x="96.009" y="38.951" fill="#304555" width="37.506" height="2" vectorEffect="non-scaling-stroke" />
      </g>
    </g>
    <g>
      <g>
        <line fill="#FFFFFF" x1="96.009" y1="69.201" x2="133.515" y2="69.201" vectorEffect="non-scaling-stroke" />
      </g>
      <g>
        <rect x="96.009" y="68.201" fill="#304555" width="37.506" height="2" vectorEffect="non-scaling-stroke" />
      </g>
    </g>
    <g>
      <g>
        <line fill="#FFFFFF" x1="96.009" y1="99.457" x2="133.515" y2="99.457" vectorEffect="non-scaling-stroke" />
      </g>
      <g>
        <rect x="96.009" y="98.457" fill="#304555" width="37.506" height="2" vectorEffect="non-scaling-stroke" />
      </g>
    </g>
    <g>
      <path
        fill="#3E94F6"
        d="M72.809,76.245L72.809,76.245c-0.265,0-0.52-0.105-0.707-0.293l-5.79-5.792&#10;&#9;&#9;c-0.391-0.391-0.391-1.024,0-1.414c0.391-0.391,1.023-0.391,1.414,0l5.083,5.085l9.452-9.45c0.391-0.391,1.023-0.391,1.414,0&#10;&#9;&#9;s0.391,1.024,0,1.414L73.516,75.952C73.328,76.139,73.074,76.245,72.809,76.245z"
        vectorEffect="non-scaling-stroke" />
    </g>
    <g>
      <path
        fill="#3E94F6"
        d="M72.809,46.029L72.809,46.029c-0.265,0-0.52-0.105-0.707-0.293l-5.79-5.792&#10;&#9;&#9;c-0.391-0.391-0.391-1.024,0-1.414c0.391-0.391,1.023-0.391,1.414,0l5.083,5.084l9.452-9.45c0.391-0.391,1.023-0.391,1.414,0&#10;&#9;&#9;s0.391,1.024,0,1.414L73.516,45.736C73.328,45.923,73.074,46.029,72.809,46.029z"
        vectorEffect="non-scaling-stroke" />
    </g>
    <g>
      <g>
        <line fill="#FFFFFF" x1="96.009" y1="131.12" x2="133.515" y2="131.12" vectorEffect="non-scaling-stroke" />
      </g>
      <g>
        <rect x="96.009" y="130.12" fill="#304555" width="37.506" height="2" vectorEffect="non-scaling-stroke" />
      </g>
    </g>
    <g>
      <path
        fill="#3E94F6"
        d="M72.809,138.162c-0.256,0-0.512-0.098-0.707-0.293l-5.79-5.791c-0.391-0.391-0.391-1.023,0-1.414&#10;&#9;&#9;s1.023-0.391,1.414,0l5.083,5.084l9.452-9.448c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-10.159,10.155&#10;&#9;&#9;C73.32,138.064,73.064,138.162,72.809,138.162z"
        vectorEffect="non-scaling-stroke" />
    </g>
    <g>
      <path
        fill="none"
        stroke="#A7BBD1"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeDasharray="4,4"
        d="M53.856,80.325"
        vectorEffect="non-scaling-stroke" />
    </g>
    <g>
      <path
        fill="none"
        stroke="#A7BBD1"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeDasharray="4,4"
        d="M166.417,50.589"
        vectorEffect="non-scaling-stroke" />
    </g>
    <g>
      <path
        fill="none"
        stroke="#A7BBD1"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeDasharray="4,4"
        d="M83.131,75.632"
        vectorEffect="non-scaling-stroke" />
    </g>
    <g>
      <polygon
        fill="#304555"
        points="138.366,170.319 136.465,152.914 153.503,149.655 &#9;"
        vectorEffect="non-scaling-stroke" />
    </g>
    <g>
      <g>
        <polygon
          fill="#F1F4F8"
          points="125.604,117.174 182.805,71.743 187.353,72.729 189.838,75.765 189.352,79.736 132.15,125.168 &#10;&#9;&#9;&#9;121.063,128.408 &#9;&#9;"
          vectorEffect="non-scaling-stroke" />
      </g>
      <g>
        <path
          fill="#304555"
          d="M119.362,129.947l5.415-13.393l0.206-0.164l57.571-45.726l5.352,1.161l2.978,3.636l-0.589,4.802&#10;&#9;&#9;&#9;l-0.32,0.255l-57.354,45.554L119.362,129.947z M126.433,117.793l-3.669,9.076l8.918-2.606l56.728-45.055l0.384-3.14l-1.993-2.435&#10;&#9;&#9;&#9;l-3.744-0.813L126.433,117.793z"
          vectorEffect="non-scaling-stroke" />
      </g>
    </g>
    <g>
      <g>
        <path
          fill="#F1F4F8"
          d="M130.269,9.826c0,4.874-3.951,8.825-8.826,8.825H80.288c-4.873,0-8.825-3.951-8.825-8.825l0,0&#10;&#9;&#9;&#9;C71.463,4.951,75.415,1,80.288,1h41.154C126.317,1,130.269,4.951,130.269,9.826L130.269,9.826z"
          vectorEffect="non-scaling-stroke" />
      </g>
      <g>
        <path
          fill="#304555"
          d="M121.442,19.651H80.288c-5.417,0-9.825-4.408-9.825-9.825C70.463,4.408,74.871,0,80.288,0h41.154&#10;&#9;&#9;&#9;c5.418,0,9.826,4.408,9.826,9.826C131.269,15.244,126.86,19.651,121.442,19.651z M80.288,2c-4.315,0-7.825,3.511-7.825,7.826&#10;&#9;&#9;&#9;c0,4.315,3.51,7.825,7.825,7.825h41.154c4.315,0,7.826-3.51,7.826-7.825c0-4.315-3.511-7.826-7.826-7.826H80.288z"
          vectorEffect="non-scaling-stroke" />
      </g>
    </g>
  </svg>
);

export default SkippedQuestionIcon;
