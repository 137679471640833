import cheerio from 'cheerio';
import uniq from 'lodash/uniq';
import map from 'lodash/map';

export default function calculateQuestionsLeft(html, onlyNotAnswered = true) {
  const $$ = cheerio.load(html);
  const query = onlyNotAnswered
    ? '[data-question-id].not_ready'
    : '[data-question-id]';
  const remainingQuestions = $$(query);
  const questionIds = uniq(map(remainingQuestions, question => question.attribs['data-question-id']));
  return questionIds.length;
}
