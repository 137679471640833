/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'qs';
import withPagination from 'hocs/withPagination';
import SEARCH_PARAMS from 'shared/constants/queryParams/search';
import { isLoading as isLoadingSelector } from 'utils/statusSelectors';
import PageSpinner from 'components/PageSpinner';

const folderIdKeys = { templates: 'folder_id' };

class DataProvider extends Component {
  state = { initialized: false };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const searchQueryChanged = prevProps.location.search !== this.props.location.search;
    if (searchQueryChanged) {
      const prevSearchParams = queryString.parse(prevProps.location.search, { ignoreQueryPrefix: true });
      const searchParams = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });
      if (prevSearchParams.query !== searchParams.query) {
        this.search();
      } else {
        this.fetchData();
      }
    } else if (prevProps.folderId !== this.props.folderId) {
      this.fetchData();
    }
  }

  static getDerivedStateFromProps(props, state) {
    return (!state.initialized && props.isLoading) ? { ...state, initialized: true } : null;
  }

  getParams = () => {
    const { folderId, itemKey, location, getCurrentPage } = this.props;
    const searchParams = queryString.parse(location.search, { ignoreQueryPrefix: true });
    return itemKey === 'documents' ? [folderId, searchParams] : [{
      [SEARCH_PARAMS.page]: getCurrentPage(),
      [folderIdKeys[itemKey]]: folderId,
      q: searchParams,
    }];
  }

  fetchData = () => {
    const { fetchData } = this.props;
    const params = this.getParams();
    fetchData(...params);
  };

  search = () => {
    const { search, fetchData, getCurrentPage } = this.props;
    const params = this.getParams();
    if (search) {
      return search(params[1], params[0], getCurrentPage());
    }
    return fetchData(...params);
  }

  render() {
    const { data, children, isLoading } = this.props;

    if (isLoading || !this.state.initialized) {
      return <PageSpinner />;
    }

    return children({
      data,
      fetchData: this.fetchData,
    });
  }
}

DataProvider.propTypes = {
  children: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  getCurrentPage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  itemKey: PropTypes.oneOf(['templates', 'documents', 'trash']).isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  folderId: PropTypes.string,
  data: PropTypes.instanceOf(Object),
};

DataProvider.defaultProps = { isLoading: false };

const mapStateToProps = (state, { itemKey, statusKey }) => ({
  data: state[itemKey],
  isLoading: isLoadingSelector(state, statusKey),
});

export default compose(
  withRouter,
  withPagination,
  connect(mapStateToProps),
)(DataProvider);
