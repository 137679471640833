import { createActions } from 'reduxsauce';

export const REDUX_NAMESPACE = 'app/';
export const REDUXACTIONS_STRUCTURE = {
  initApp: ['appType'],
  initAppSuccess: null,
  initAppFailure: ['error'],
  setV2Experience: null,
  setWhiteLabelCompanyUrl: ['whiteLabelCompanyUrl'],
};

export const { Types, Creators } = createActions(REDUXACTIONS_STRUCTURE, { prefix: REDUX_NAMESPACE });
