import styled from 'styled-components';
import { legacyMedia } from 'shared/styled-helpers';
import Help from '@material-ui/icons/Help';
import { HeaderContainer } from '../styled-components';

export const LeftToolbarHeader = styled(HeaderContainer)`
  ${legacyMedia.sm`
    display: none;
  `};
`;

export const StyledLeftToolbar = styled.div`
  width: 260px;
  background: ${p => p.theme.background.white};
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in;
  max-height: 100vh;
  z-index: 1;
  ${legacyMedia.sm`
    width: 100vw;
    max-width: 100vw;

    ${({ isVisible }) => isVisible === false
      && `
      display: none;
    `};
  `};

  ${({ isVisible }) => isVisible === false
    && `
     transform: translateX(-100%) translateX(90px);
  `};
`;

export const HelpIcon = styled(Help)`
  fill: #ffe39e !important;
`;
