import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import _toNumber from 'lodash/toNumber';
import _isNaN from 'lodash/isNaN';
import * as v from 'shared/validation';
import proptypes from 'utils/proptypes';
import createAnswerParams from 'Questionnaire2/helpers/createAnswerParams';
import { CONSTRAIN_TYPE, CONSTRAIN_KEY } from 'Visualization/helpers/constants';
import ANSWER_STATUS from 'shared/constants/answerStatuses';
import { SAVED, SAVING, NONE, FAILED } from 'shared/constants/saveStatus';
import { Creators as QuestionnaireActions } from 'ducks/questionnaire/actions';
import {
  currentAnswerSelector,
  unfinishedQuestionSelector,
} from 'ducks/questionnaire/selectors';
import EmailQuestion from 'components/Question/EmailQuestion';
import RadioQuestion from 'components/Question/RadioQuestion';
import CheckboxQuestion from 'components/Question/CheckboxQuestion';
import InputQuestion from 'components/Question/InputQuestion';
import UnsignedIntegerQuestion from 'components/Question/UnsignedIntegerQuestion';
import DateQuestion from 'components/Question/DateQuestion';
import SkipQuestion from 'Questionnaire2/Question/SkipQuestion';
import QuestionName from 'Questionnaire2/Question/QuestionName';
import OkButton from 'Questionnaire2/OkButton';
import debounce from 'lodash/debounce';


const components = {
  radio: RadioQuestion,
  input: InputQuestion,
  checkbox: CheckboxQuestion,
  // TODO: Delete above one when we will implement number, radio, checkbox etc. in constraints
  [CONSTRAIN_TYPE.NONE]: InputQuestion,
  [CONSTRAIN_TYPE.DATE]: DateQuestion,
  [CONSTRAIN_TYPE.EMAIL]: EmailQuestion,
  [CONSTRAIN_TYPE.UINT]: UnsignedIntegerQuestion,
};

export class QuestionContainer extends React.Component {
  static propTypes = {
    currentQuestion: proptypes.questionScheme,
    saveAnswer: PropTypes.func.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    currentAnswer: proptypes.answerScheme,
    userQuestionnaireId: PropTypes.string.isRequired,
    goToNextQuestion: PropTypes.func.isRequired,
    hasUnfinishedQuestion: PropTypes.bool.isRequired,
    savingStatus: PropTypes.string.isRequired,
    templateSettings: proptypes.templateSettingsScheme.isRequired,
    startSavingAnswer: PropTypes.func.isRequired,
    resetFieldValue: PropTypes.func.isRequired,
  };

  state = {
    wantToContinue: false,
    tempAnswer: undefined,
  };

  liveUpdate = debounce((answer) => {
    const {
      updateAnswer,
      saveAnswer,
      currentAnswer,
    } = this.props;

    if (currentAnswer.id) {
      updateAnswer(answer);
    } else {
      saveAnswer(answer);
    }
  }, 300, { leading: (this.props.currentQuestion.kind !== 'input') }); // if question is not input type enable immediate first call for debounce.

  componentDidUpdate(prevProps) {
    const { goToNextQuestion, savingStatus } = this.props;

    // Continue button or skip question button was clicked
    // answer was saved or wasn't changed at all
    // and now we should move further
    if (
      this.state.wantToContinue
      && ((prevProps.savingStatus === SAVING && savingStatus === SAVED)
      || savingStatus === NONE)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ wantToContinue: false, tempAnswer: undefined }, goToNextQuestion);
    }
  }

  saveAnswer = (
    idToReplace,
    value,
    params = { status: ANSWER_STATUS.ANSWERED },
  ) => {
    const {
      currentQuestion,
      userQuestionnaireId,
    } = this.props;
    const answerParams = createAnswerParams(
      currentQuestion,
      userQuestionnaireId,
      idToReplace,
      value,
    );

    const answer = { ...answerParams, ...params };

    this.setState({ tempAnswer: answer });
    this.liveUpdate(answer);
  };

  skipQuestion = () => {
    const { savingStatus, currentAnswer } = this.props;
    const defaultValue = get(currentAnswer, 'details.value', '');

    this.setState({ wantToContinue: true }, () => {
      if ([SAVED, NONE].includes(savingStatus)) {
        this.saveAnswer(currentAnswer.input_id, defaultValue, { status: ANSWER_STATUS.SKIPPED });
      }
    });
  };

  handleClickContinue = () => {
    const { savingStatus, currentAnswer, goToNextQuestion } = this.props;
    const { tempAnswer } = this.state;
    const lastSavedValue = get(currentAnswer, 'details.value', '');
    const lastAnswer = get(tempAnswer, 'details.value', lastSavedValue);
    const lastSavedAnswerStatus = get(currentAnswer, 'status', ANSWER_STATUS.UNANSWERED);

    if (savingStatus === FAILED) return;

    if (lastSavedAnswerStatus === ANSWER_STATUS.SKIPPED) {
      // this condition case: user goes back to a skipped but already answered question and clicks continue button without touching a question
      this.setState({ wantToContinue: true }, () => {
        this.saveAnswer(currentAnswer.input_id, lastAnswer);
      });
    } else if (String(lastAnswer) !== String(lastSavedValue)) {
      this.setState({ wantToContinue: true });
    } else {
      this.setState({ tempAnswer: undefined }, goToNextQuestion);
    }
  };

  isFieldValid = () => {
    const { currentQuestion, currentAnswer } = this.props;
    const { tempAnswer } = this.state;
    const constrain = get(currentAnswer, 'question_constrain', get(currentQuestion, 'details.constrain', ''));
    const value = get(tempAnswer, 'details.value', get(currentAnswer, 'details.value', ''));
    // validate for email
    if (constrain === CONSTRAIN_TYPE.EMAIL) {
      return !v.required(value) && !v.email(value);
    }
    // validate for uint
    if (constrain === CONSTRAIN_TYPE.UINT) {
      return !v.required(value) && !v.uint(value);
    }
    // validate for date
    if (constrain === CONSTRAIN_TYPE.DATE) {
      const ms = _toNumber(value);
      return !_isNaN(ms) && !v.date(ms);
    }
    // default validation
    return !v.required(value);
  };

  render() {
    const {
      currentAnswer,
      currentQuestion,
      hasUnfinishedQuestion,
    } = this.props;
    const defaultValue = get(currentAnswer, 'details.value', '');
    const componentType = (currentQuestion.details && currentQuestion.details[CONSTRAIN_KEY])
      || currentQuestion.kind;
    const Question = components[componentType];
    const nextDisabled = !this.isFieldValid();
    const clickOnEnter = (componentType === CONSTRAIN_TYPE.NONE ? !currentQuestion.details.multiline_input : true);

    return (
      <Fragment>
        <div>
          <QuestionName
            key={`${currentQuestion.id}-${currentQuestion.name}`}
            name={currentQuestion.name}
            helpText={currentQuestion.details.help_text} />
          <Question
            key={currentQuestion.id}
            name={currentQuestion.id}
            question={this.props.currentQuestion}
            answer={this.props.currentAnswer}
            saveAnswer={this.saveAnswer}
            resetValue={this.props.resetFieldValue}
            invalid={nextDisabled}
            defaultValue={defaultValue}
            startSavingAnswer={this.props.startSavingAnswer}
            templateSettings={this.props.templateSettings} />
        </div>
        <OkButton
          key="ok_button"
          asComplete={!hasUnfinishedQuestion}
          onClick={this.handleClickContinue}
          nextDisabled={nextDisabled}
          clickOnEnter={clickOnEnter} />
        <SkipQuestion key="skip_question" onClick={this.skipQuestion} />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    savingStatus: state.questionnaire.savingStatus,
    userQuestionnaireId: state.questionnaire.userQuestionnaire.id,
    currentAnswer: currentAnswerSelector(state),
    hasUnfinishedQuestion: unfinishedQuestionSelector(state) !== undefined,
    currentQuestion: state.questionnaire.currentQuestion,
    templateSettings: state.questionnaire.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startSavingAnswer: () => dispatch(QuestionnaireActions.startSavingAnswer()),
    saveAnswer: params => dispatch(QuestionnaireActions.saveAnswer(params)),
    updateAnswer: params => dispatch(QuestionnaireActions.updateAnswer(params)),
    resetFieldValue: id => dispatch(QuestionnaireActions.resetFieldValue(id)),
    goToNextQuestion: () => dispatch(QuestionnaireActions.findNextQuestion()),
    updateAnswerSuccess: params => dispatch(QuestionnaireActions.updateAnswerSuccess(params)),
    saveAnswerSuccess: params => dispatch(QuestionnaireActions.saveAnswerSuccess(params)),
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(QuestionContainer);
