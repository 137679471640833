exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-pages-DocumentStyles-components-___DocumentPreview__component___317Pp {\n  flex: 1;\n  position: relative;\n  height: 90vh;\n  margin: 20px 40px;\n}\n", "", {"version":3,"sources":["/workspace/app/pages/DocumentStyles/components/DocumentPreview.css"],"names":[],"mappings":"AAAA;EACE,QAAO;EACP,mBAAkB;EAClB,aAAY;EACZ,kBAAiB;CACnB","file":"DocumentPreview.css","sourcesContent":[".component {\n  flex: 1;\n  position: relative;\n  height: 90vh;\n  margin: 20px 40px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-pages-DocumentStyles-components-___DocumentPreview__component___317Pp"
};