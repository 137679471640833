import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { injectIntl, intlShape } from 'react-intl';

import proptypes from 'utils/proptypes';
import Option from 'shared/Components/Form/Select/Option';
import FieldRow from 'components/form/FieldRow';
import InputRadio from 'components/form/InputRadio';
import Select from 'shared/Components/Form/Select';
import wasThisAnswerRemoved from './helpers/wasThisAnswerRemoved';
import styles from './RadioQuestion.css';

export class RadioQuestionComponent extends Component {
  static propTypes = {
    question: proptypes.radioQuestionScheme.isRequired,
    defaultValue: PropTypes.string,
    saveAnswer: PropTypes.func.isRequired,
    answer: proptypes.answerScheme,
    intl: intlShape.isRequired,
  };

  state = { value: this.props.defaultValue };

  componentDidMount() {
    const { defaultValue, answer } = this.props;
    // if defaultValue is filled and answer id is undefined then it means we should save that answer
    // as those values are from removedAnswers array and we need to auto save it to be able to clikc NextQuestion
    if (wasThisAnswerRemoved(answer)) {
      this.updateValue(defaultValue);
    }
  }

  onSelectChange = (e) => {
    const { target: { value } } = e;

    this.updateValue(value);
  };

  updateValue = (fieldId) => {
    const { saveAnswer } = this.props;
    this.setState({ value: fieldId }, saveAnswer(fieldId, fieldId));
  };

  onRadioChange = fieldId => () => this.updateValue(fieldId);

  render() {
    const { question, intl } = this.props;
    const options = get(question, 'details.options', []);
    const [value] = this.state.value.split(';'); // this is fix for preventing multiple checkbox choice answers to get in to value param, instead get the first option only if its exist

    return options.length < 10 ? (
      <React.Fragment>
        {options.map(field => (
          <FieldRow key={field.id} data-test-id="Questionnaire-questionRadio">
            <InputRadio
              checked={value === field.id}
              name="radioInput"
              testId={`QuestionRadio-${field.id}`}
              onChange={this.onRadioChange(field.id)}
              value={field.id}
              key={field.id}
              id={field.id}
              label={field.name} />
          </FieldRow>
        ))}
      </React.Fragment>
    ) : (
      <Select
        className={styles.select}
        name={`${question.name}-inputSelect`}
        id={`${question.name}-inputSelect`}
        value={value}
        onChange={this.onSelectChange}
        placeholder={intl.formatMessage({ id: 'helpers.select.prompt' })}>
        {options.map(field => (
          <Option key={field.id} value={field.id}>
            {field.name}
          </Option>
        ))}
      </Select>
    );
  }
}

export default injectIntl(RadioQuestionComponent);
