import assert from 'assert';
import { put, call, take } from 'redux-saga/effects';
import map from 'lodash/map';
import { snakeToCamelCase } from 'shared/helpers/transforms';

import { Creators, Types } from '../reducer';

import { ROOT_DIR } from '../constants';

export default function createSagas({ templatesService, foldersService, documentService, notificationService } = {}) {
  assert.ok(templatesService, 'templatesService is required');
  assert.ok(foldersService, 'foldersService is required');
  assert.ok(documentService, 'documentService is required');
  assert.ok(notificationService, 'notificationService is required');

  function* fetchTemplatesInfoSaga() {
    while (true) {
      const action = yield take(Types.FETCH_TEMPLATES_INFO_REQUEST);
      try {
        const fetchTemplates = action.publishedOnly
          ? templatesService.fetchPublishedTemplatesInfo
          : templatesService.fetchTemplatesInfo;
        const { data } = yield call([templatesService, fetchTemplates], action.payload);

        const questionnaireTemplates = data.questionnaire_templates;
        const totalCount = data.questionnaire_templates_count;
        const currentFolder = data.folder || ROOT_DIR;

        const folders = map(data.folders, snakeToCamelCase);
        const templates = questionnaireTemplates.map((template) => {
          const originalQtvDetails = template.last_qtv_details;
          const transformedTemplateObj = snakeToCamelCase(template);
          transformedTemplateObj.lastQtvDetails = { ...originalQtvDetails };
          return transformedTemplateObj;
        });

        yield put(Creators.fetchTemplatesInfoSuccess({
          templates,
          totalCount,
          folders,
          currentFolder,
        }));
        if (action.toast) {
          yield put(notificationService.show({
            ...action.toast,
            position: 'bl',
            useTranslate: true,
          }, action.toast.level));
        }
      } catch (e) {
        yield put(Creators.fetchTemplatesInfoFailure(e));
      }
    }
  }

  function* fetchTemplateVersionHistorySaga() {
    while (true) {
      const { templateId } = yield take(Types.FETCH_TEMPLATES_VERSION_HISTORY_REQUEST);
      try {
        const { data } = yield call([templatesService, templatesService.fetchTemplateVersionHistory], templateId);
        const templatesHistory = map(data.questionnaire_template_versions, snakeToCamelCase);
        yield put(Creators.fetchTemplatesVersionHistorySuccess(templatesHistory));
      } catch (e) {
        yield put(Creators.fetchTemplatesVersionHistoryFailure(e));
      }
    }
  }

  function* fetchDocumentsForTemplateSaga() {
    while (true) {
      const { templateId } = yield take(Types.FETCH_DOCUMENTS_FOR_TEMPLATE_REQUEST);
      try {
        const { data } = yield call([templatesService, templatesService.fetchDocumentsForTemplate], templateId);
        const documents = map(data.user_questionnaires, snakeToCamelCase);
        const documentsWithDefaultName = map(documents, d => ({
          ...d,
          name: d.name || d.basedOnTemplate,
        }));
        yield put(Creators.fetchDocumentsForTemplateSuccess(documentsWithDefaultName));
      } catch (e) {
        yield put(Creators.fetchDocumentsForTemplateFailure(e));
      }
    }
  }

  function* fetchCurrentTemplateSaga() {
    while (true) {
      const { id } = yield take(Types.FETCH_CURRENT_TEMPLATE_REQUEST);
      try {
        const response = yield call([templatesService, templatesService.fetchTemplateDetails], id);
        yield put(Creators.fetchCurrentTemplateSuccess(response.data.questionnaire_template));
      } catch (e) {
        yield put(Creators.fetchCurrentTemplateFailure(e));
      }
    }
  }

  return {
    fetchTemplatesInfoSaga,
    fetchCurrentTemplateSaga,
    fetchTemplateVersionHistorySaga,
    fetchDocumentsForTemplateSaga,
  };
}
