exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-Breadcrumbs-components-LinearBreadcrumbs-___index__title___1JDXm {\n  padding: 0 4px;\n  margin: 0;\n  height: 26px;\n}\n\n  .app-components-Breadcrumbs-components-LinearBreadcrumbs-___index__title___1JDXm:not(.app-components-Breadcrumbs-components-LinearBreadcrumbs-___index__currentPath___1d4Y5):hover {\n    background-color: #f3f3f3;\n    background-color: var(--c-bw-05);\n    border-radius: 4px;\n  }\n\n.app-components-Breadcrumbs-components-LinearBreadcrumbs-___index__currentPath___1d4Y5 {\n  color: #171717;\n  color: var(--c-bw-90);\n}\n\nsvg.app-components-Breadcrumbs-components-LinearBreadcrumbs-___index__iconItem___2JgqS {\n  margin: 0 4px;\n}\n", "", {"version":3,"sources":["/workspace/app/components/Breadcrumbs/components/LinearBreadcrumbs/index.css"],"names":[],"mappings":"AAAA;EACE,eAAc;EACd,UAAS;EACT,aAAY;CAMd;;EAJE;IACE,0BAAgC;IAAhC,iCAAgC;IAChC,mBAAkB;GACpB;;AAGF;EACE,eAAqB;EAArB,sBAAqB;CACvB;;AAEA;EACE,cAAa;CACf","file":"index.css","sourcesContent":[".title {\n  padding: 0 4px;\n  margin: 0;\n  height: 26px;\n\n  &:not(.currentPath):hover {\n    background-color: var(--c-bw-05);\n    border-radius: 4px;\n  }\n}\n\n.currentPath {\n  color: var(--c-bw-90);\n}\n\nsvg.iconItem {\n  margin: 0 4px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"title": "app-components-Breadcrumbs-components-LinearBreadcrumbs-___index__title___1JDXm",
	"currentPath": "app-components-Breadcrumbs-components-LinearBreadcrumbs-___index__currentPath___1d4Y5",
	"iconItem": "app-components-Breadcrumbs-components-LinearBreadcrumbs-___index__iconItem___2JgqS"
};