import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { CONSUMER_ROLE } from 'shared/constants/user/roles';
import EllipsisMenu, { EllipsisMenuButton } from 'components/EllipsisMenu';
import { withAcl } from 'services/acl';
import { CreateFolderModal } from 'shared/Components/FolderModal/CreateFolderModal';
import { TrashIcon } from '../TrashIcon';

export class HeaderSubmenuComponent extends PureComponent {
  static propTypes = {
    hasRole: PropTypes.func.isRequired,
    createNewFolder: PropTypes.func.isRequired,
    parentFolderId: PropTypes.string,
  };

  state = {
    submenuOpened: false,
    newFolderDialogOpen: false,
  };

  constructor(props) {
    super(props);
    this.dotsBtnRef = React.createRef();
  }

  componentDidMount() {
    this.dotsBtnDomNode = ReactDOM.findDOMNode(this.dotsBtnRef.current);
  }

  toggleSubmenu = (e) => {
    e.preventDefault();
    this.setState(state => ({ submenuOpened: !state.submenuOpened }));
  };

  closeSubmenu = () => {
    this.setState({ submenuOpened: false });
  };

  openNewFolderDialog = () => {
    this.setState({ newFolderDialogOpen: true });
    this.closeSubmenu();
  };

  closeNewFolderDialog = () => {
    this.setState({ newFolderDialogOpen: false });
  };

  render() {
    const { hasRole, createNewFolder, parentFolderId } = this.props;
    const { newFolderDialogOpen } = this.state;
    const isConsumer = hasRole([CONSUMER_ROLE]);
    const menuItems = [
      {
        dataTestId: 'PageHeader-submenu-trash',
        icon: <TrashIcon />,
        linkTo: '/trash',
        text: <FormattedMessage id="panel.trash.show.trash" />,
      },
    ];
    if (!isConsumer) {
      menuItems.unshift(
        {
          dataTestId: 'PageHeader-submenu-newFolder',
          onClick: this.openNewFolderDialog,
          icon: <CreateNewFolderIcon />,
          text: <FormattedMessage id="panel.templates_library.folders.show.folder" />,
        },
      );
    }

    return (
      <React.Fragment>
        <EllipsisMenuButton
          ref={this.dotsBtnRef}
          onClick={this.toggleSubmenu}
          testId="PageHeader-submenuButton"
          isOpen={this.state.submenuOpened} />
        <EllipsisMenu
          isOpen={this.state.submenuOpened}
          domEl={this.dotsBtnDomNode}
          handleClose={this.closeSubmenu}
          menuItems={menuItems}
          dataTestId="PageHeader-submenu" />
        <CreateFolderModal
          isOpen={newFolderDialogOpen}
          onModalClose={this.closeNewFolderDialog}
          parentFolderId={parentFolderId}
          onSubmit={createNewFolder} />
      </React.Fragment>
    );
  }
}

export default compose(withAcl)(HeaderSubmenuComponent);
