exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-bundles-Questionnaire2-LeftToolbar-___LeftToolbar__headerTitle___2tju1 {\n  flex: 1;\n  font-weight: 500;\n  font-weight: var(--font-weight-medium);\n}\n\n.app-bundles-Questionnaire2-LeftToolbar-___LeftToolbar__textBtn___2BzVP {\n  margin-bottom: 20px;\n  margin-top: 10px;\n  margin-left: 10px;\n  color: #2d866e;\n  color: var(--c-brand);\n}\n\n.app-bundles-Questionnaire2-LeftToolbar-___LeftToolbar__textBtn___2BzVP svg {\n    font-size: 1.2rem;\n  }\n\n.app-bundles-Questionnaire2-LeftToolbar-___LeftToolbar__textBtn___2BzVP div {\n    justify-content: flex-start;\n  }\n\n.app-bundles-Questionnaire2-LeftToolbar-___LeftToolbar__textBtnGreyed___Gk_0t div {\n  color: #a4a4a4;\n  color: var(--c-bw-30);\n}\n", "", {"version":3,"sources":["/workspace/app/bundles/Questionnaire2/LeftToolbar/LeftToolbar.css"],"names":[],"mappings":"AAAA;EACE,QAAO;EACP,iBAAsC;EAAtC,uCAAsC;CACxC;;AAEA;EACE,oBAAmB;EACnB,iBAAgB;EAChB,kBAAiB;EACjB,eAAqB;EAArB,sBAAqB;CASvB;;AAPE;IACE,kBAAe;GACjB;;AAEA;IACE,4BAA2B;GAC7B;;AAGF;EACE,eAAqB;EAArB,sBAAqB;CACvB","file":"LeftToolbar.css","sourcesContent":[".headerTitle {\n  flex: 1;\n  font-weight: var(--font-weight-medium);\n}\n\n.textBtn {\n  margin-bottom: 20px;\n  margin-top: 10px;\n  margin-left: 10px;\n  color: var(--c-brand);\n\n  svg {\n    font-size: 18px;\n  }\n\n  div {\n    justify-content: flex-start;\n  }\n}\n\n.textBtnGreyed div {\n  color: var(--c-bw-30);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"headerTitle": "app-bundles-Questionnaire2-LeftToolbar-___LeftToolbar__headerTitle___2tju1",
	"textBtn": "app-bundles-Questionnaire2-LeftToolbar-___LeftToolbar__textBtn___2BzVP",
	"textBtnGreyed": "app-bundles-Questionnaire2-LeftToolbar-___LeftToolbar__textBtnGreyed___Gk_0t"
};