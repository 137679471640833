// hoc used to compose methods for updating logics not linked to question,
// so logics is linked only to DOM element
// anchorType
import React from 'react';
import rangy from 'rangy';
import NodesHelper from './TriggersFn/utils/nodesHelper';
import getNearestQuestionTagFromNode from './TriggersFn/utils/getNearestQuestionTagFromNode';

export default function (WrappedComponent) {
  return class withStaticOptionsHoc extends React.Component {
    constructor(props) {
      super(props);
      this.state = { selectedNodeWithOption: null };
    }

    componentDidMount() {
      if (rangy.getSelection().rangeCount === 0) return; // if no selection we should do nothing

      const nodesHelper = new NodesHelper();
      if (!nodesHelper.selectionNode()) return;
      const selectedNodeWithOption = getNearestQuestionTagFromNode(
        nodesHelper.selectionNode(),
        'anchorType',
      );
      this.setSelectedNodeWithOption(selectedNodeWithOption);
    }

    setSelectedNodeWithOption = (node = null) => {
      this.setState({ selectedNodeWithOption: node });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          setSelectedNodeWithOption={this.setSelectedNodeWithOption} />
      );
    }
  };
}
