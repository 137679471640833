import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field, withFormik } from 'formik';
import { Link } from 'react-router-dom';
import FieldRow from 'components/form/FieldRow';
import InputText from 'components/form/InputText';
import Button from 'components/Button';
import Typography, { VARIANTS } from 'components/Typography';
import { Creators } from 'ducks/loggedInUser/actions';
import { email as emailRegex } from 'utils/regex';
import { isLoading as isLoadingSelector } from 'utils/statusSelectors';

import styles from './styles.css';

export const ForgotPassword = ({ handleSubmit, isValid, isLoading }) => (
  <div className={styles.component}>
    <form onSubmit={handleSubmit} noValidate className={styles.form}>
      <Typography variant={VARIANTS.H2} className={styles.heading}>
        <FormattedMessage id="forgot_password.title" />
      </Typography>
      <Typography variant={VARIANTS.H5} className={styles.subheading}>
        <FormattedMessage id="forgot_password.subtitle" />
      </Typography>
      <FieldRow>
        <Typography variant={VARIANTS.LABEL} htmlFor="email" className={styles.label}>
          <FormattedMessage id="forgot_password.labels.email" />
        </Typography>
        <Field name="email">
          {({ field, form }) => (
            <InputText
              errorMessage={form.errors[field.name]}
              id="email"
              required
              testId="ForgotPassword-emailField"
              type="email"
              {...field} />
          )}
        </Field>
      </FieldRow>
      <div className={styles.buttonGroup}>
        <Link data-test-id="ForgotPassword-signInLink" to="/auth/sign-in" className={styles.link}>
          <Typography variant={VARIANTS.HELP_TEXT}>
            <FormattedMessage id="forgot_password.labels.already_logged" />
          </Typography>
        </Link>
        <Button testId="ForgotPassword-submit" isLoading={isLoading} disabled={!isValid} type="submit">
          <FormattedMessage id="forgot_password.labels.submit" />
        </Button>
      </div>
    </form>
  </div>
);

ForgotPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const requiredValues = ['email'];

export const formConfig = {
  mapPropsToValues: () => ({ email: '' }),
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (!emailRegex.test(values.email)) {
      errors.email = <FormattedMessage id="forgot_password.errors.invalid_email" />;
    }

    requiredValues.forEach((name) => {
      if (!values[name]) errors[name] = <FormattedMessage id="forgot_password.errors.cant_be_blank" />;
    });

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    props.forgotPassword(values.email);
    setSubmitting(false);
  },
};

export const mapStateToProps = state => ({ isLoading: isLoadingSelector(state, 'appLoggedInUserStateForgotPassword') });
export const mapDispatchToProps = { forgotPassword: Creators.forgotPasswordRequest };


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik(formConfig),
)(ForgotPassword);
