import * as React from 'react';
import cn from 'classnames';

import styles from './index.css';

export default function Spinner({ className, ...otherProps }) {
  return (
    <div {...otherProps} className={cn(styles.component, className)}>
      <div className={styles.spinner} />
    </div>
  );
}
