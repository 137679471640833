import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { withFormik, Field } from 'formik';
import { injectIntl, intlShape } from 'react-intl';

import proptypes from 'utils/proptypes';
import * as v from 'shared/validation';
import ANSWER_STATUS from 'shared/constants/answerStatuses';
import FieldRow from 'components/form/FieldRow';
import InputText from 'components/form/InputText';
import TextArea from 'components/form/TextArea';

import wasThisAnswerRemoved from './helpers/wasThisAnswerRemoved';
import styles from './InputQuestion.css';

const validator = fieldName => v.createValidator({ [fieldName]: [v.required] });
const isValid = fieldName => values => isEmpty(validator(fieldName)(values));

export class InputQuestionComponent extends Component {
  static propTypes = {
    saveAnswer: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    question: proptypes.inputQuestionScheme.isRequired,
    answer: proptypes.answerScheme,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.shape({ input: PropTypes.string }).isRequired,
    touched: PropTypes.objectOf(PropTypes.bool).isRequired,
    startSavingAnswer: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  };

  _saveAnswer = (value) => {
    const { saveAnswer, question, values, name } = this.props;
    const params = isValid(name)(values) ? undefined : { status: ANSWER_STATUS.DRAFT };
    const idToReplace = get(question, 'details.id', '');

    saveAnswer(idToReplace, value, params);
  };

  componentDidMount() {
    const { defaultValue, answer } = this.props;
    // if defaultValue is filled and answer id is undefined then it means we should save that answer
    // as those values are from removedAnswers array and we need to
    // auto save it to be able to clikc NextQuestion
    if (wasThisAnswerRemoved(answer)) {
      this._saveAnswer(defaultValue);
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      touched,
      values,
      name,
    } = this.props;
    return values[name] !== nextProps.values[name] || touched[name] !== nextProps.touched[name];
  }

  componentDidUpdate(prevProps) {
    const { values, name } = this.props;
    const inputBefore = prevProps.values[name];
    const nextInput = values[name];

    if (inputBefore !== nextInput) {
      this.props.startSavingAnswer();
      this._saveAnswer(nextInput);
    }
  }

  render() {
    const { intl, name, handleBlur, handleChange, question } = this.props;
    return (
      <Field name={name}>
        {({ form, field }) => (
          <FieldRow className={styles.fieldRow}>
            {(
              question.details.multiline_input
                ? (
                  <TextArea
                    {...field}
                    autoFocus
                    placeholder={intl.formatMessage({ id: 'questionnaire.questions.input_placeholder' })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={form.errors[field.name]}
                    rows={5}
                    rowsMax={15}
                    testId="InputQuestion" />
                )
                : (
                  <InputText
                    {...field}
                    autoFocus
                    placeholder={intl.formatMessage({ id: 'questionnaire.questions.input_placeholder' })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={form.errors[field.name]}
                    testId="InputQuestion" />
                )
            )}
          </FieldRow>
        )}
      </Field>
    );
  }
}
export const formikConfig = {
  mapPropsToValues: props => ({ [props.name]: props.defaultValue }),
  isInitialValid: props => isValid(props.name)(props.initialValues),
  validate: (values, props) => validator(props.name)(values),
};

export default compose(
  injectIntl,
  withFormik(formikConfig),
)(InputQuestionComponent);
