import { createActions } from 'reduxsauce';

const REDUX_NAMESPACE = 'app/Errors/';
const REDUXSAUCE_STRUCTURE = {
  setError: ['error', 'redirectOnErrorPath', 'actionType'],
  setErrorProperties: ['title', 'message', 'redirectTo'],
  logToRollbar: ['meta'],
  error: ['error', 'redirectOnErrorPath', 'actionType'],
  redirectError: ['error', 'redirectOnErrorPath'],
  unauthorized: ['error', 'redirectOnErrorPath', 'actionType'],
};

export const { Types, Creators } = createActions(REDUXSAUCE_STRUCTURE, { prefix: REDUX_NAMESPACE });
