import { reducer as notifications } from 'react-notification-system-redux';
import { combineReducers } from 'redux';

import loggedInUserReducer from 'ducks/loggedInUser/reducer';
import uploadReducer from 'ducks/upload/reducer';

import trashReducer from 'pages/Trash/reducer';
import editorReducer from 'Visualization/Editor-v2/reducer';
import LogicsManagerReducer from 'Visualization/Editor-v2/LogicsManager/LogicsManagerRedux';
import FlowReducer from 'containers/Flow/reducer';
import questionsReducer from 'Visualization/Editor-v2/Questions/QuestionsRedux';
import questionnaireReducer from 'ducks/questionnaire/reducer';
import progressBarReducer from 'Questionnaire2/ProgressBar/redux';
import errorsReducer from 'ducks/error/reducer';
import templatesReducer from 'pages/Templates/reducer';
import userListReducer from 'pages/UserList/reducer';
import userEditReducer from 'pages/UserEdit/reducer';
import documentsReducer from 'pages/Documents/reducer';
import moveDocumentReducer from 'pages/Documents/reducers/moveDocument.reducer';
import documentStylesReducer from 'pages/DocumentStyles/reducer';
import invitationsReducer from 'pages/UserInvitation//reducer';
import documentDetailsReducer from 'pages/DocumentDetails/reducer';
import errorTemplateNotActiveReducer from 'containers/ErrorTemplateNotActive/reducer';
import exportFileReducer from 'ducks/exportFile/reducer';
import appState from '../../containers/App/reducer';

import apiCallStatusReducer from './apiCallStatusReducer';

export default combineReducers({
  loggedInUserState: loggedInUserReducer,
  appState,
  status: apiCallStatusReducer,
  notifications,
  progressBar: progressBarReducer,
  templates: templatesReducer,
  usersList: userListReducer,
  userData: userEditReducer,
  questionnaire: questionnaireReducer,
  documentStyles: documentStylesReducer,
  trash: trashReducer,
  documents: documentsReducer,
  editor: editorReducer,
  logicsManager: LogicsManagerReducer,
  flow: FlowReducer,
  questions: questionsReducer,
  invitations: invitationsReducer,
  error: errorsReducer,
  upload: uploadReducer,
  errorTemplateNotActive: errorTemplateNotActiveReducer,
  documentDetails: documentDetailsReducer,
  exportFile: exportFileReducer,
  moveDocument: moveDocumentReducer,
});
