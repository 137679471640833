import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Modal, { ModalContent, ModalFooter } from 'components/Modal';
import Button, { SECONDARY } from 'components/Button';
import { InformationIcon } from 'icons/Information';
import styles from './NotAvailableModal.css';

export class CreateDocumentLinkNotAvailableModal extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    templateId: PropTypes.string.isRequired,
  }

  render() {
    const { open, toggleModal, templateId } = this.props;
    return (
      <Modal
        modalTitle={<FormattedMessage id="share_template.title" />}
        open={open}
        toggleModal={toggleModal}>
        <ModalContent>
          <p className={styles.text}>
            <FormattedMessage id="share_template.not_available.description" />
          </p>
          <p className={styles.text}>
            <InformationIcon className={styles.infoIcon} />
            <FormattedMessage id="share_template.not_available.info" />
          </p>
        </ModalContent>
        <ModalFooter>
          <Button onClick={toggleModal} variant={SECONDARY} testId="NotAvailableModal-cancel">
            <FormattedMessage id="shared.cancel" />
          </Button>
          <Link to={`/templates/${templateId}/edit`}>
            <Button testId="NotAvailableModal-edit">
              <FormattedMessage id="share_template.not_available.action_button" />
            </Button>
          </Link>
        </ModalFooter>
      </Modal>
    );
  }
}
