import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';

import Button, { SMALL, LARGE, SECONDARY } from 'components/Button';
import IconButton from 'components/IconButton';
import { Download } from 'icons/Download';
import { Creators as QuestionnaireActions, Types as QuestionnaireTypes } from 'ducks/questionnaire/actions';
import { Types as DocumentDetailsTypes, Creators as DocumentDetailsActions } from 'pages/DocumentDetails/actions';
import DownloadDocumentModal from 'components/DownloadDocumentModal';
import Tooltip from 'components/Tooltip';
import { FILE_TYPE as EXPORT_FILE_TYPE } from 'ducks/exportFile/constants';
import { Types as ExportFileTypes, Creators as ExportFileActions } from 'ducks/exportFile/actions';
import { selectCurrentDocumentVersion, selectDocumentStatus, selectIsCurrentVersionLatest } from 'pages/DocumentDetails/selectors';
import { isLoading as isLoadingSelector } from 'utils/statusSelectors';
import { SIGNED } from 'bundles/shared/constants/questionnaireStatus';

class DownloadPdfComponent extends PureComponent {
  static propTypes = {
    exportDocxRequest: PropTypes.func.isRequired,
    questionnaireName: PropTypes.string,
    variant: PropTypes.oneOf([SECONDARY, '']),
    userQuestionnaireId: PropTypes.string.isRequired,
    updateQuestionnaireName: PropTypes.func.isRequired,
    updateDocumentName: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
    disabled: PropTypes.bool,
    isDownloading: PropTypes.bool,
    exportToWordModule: PropTypes.bool.isRequired,
    documentStatus: PropTypes.string,
    downloadSignedDocumentPdf: PropTypes.func.isRequired,
  };

  static defaultProps = { variant: '' };

  state = {
    downloadDocumentModalOpened: false,
    exportMethod: null,
    shouldDownloadDocument: false,
  };

  componentDidUpdate(prevProps) {
    const { isDownloading, questionnaireName } = this.props;
    const { downloadDocumentModalOpened, shouldDownloadDocument } = this.state;
    const questionnaireNameWasSet = prevProps.questionnaireName !== questionnaireName;
    if (questionnaireNameWasSet && shouldDownloadDocument) {
      this.handleDownload();
    }

    if (prevProps.isDownloading && !isDownloading && downloadDocumentModalOpened) {
      this.toggleModal();
    }
  }

  toggleModal = () => {
    this.setState(prevState => ({ downloadDocumentModalOpened: !prevState.downloadDocumentModalOpened }));
  };

  handleDownload = () => {
    const {
      questionnaireName,
      exportDocxRequest,
    } = this.props;
    const { exportMethod } = this.state;
    if (!questionnaireName) {
      this.toggleModal();
      return;
    }
    if (exportMethod === EXPORT_FILE_TYPE.DOCX) exportDocxRequest(questionnaireName);
    if (exportMethod === EXPORT_FILE_TYPE.PDF) this.downloadDocumentPdf(questionnaireName);
    this.setState({ shouldDownloadDocument: false });
  };

  downloadDocumentPdf(documentName) {
    const {
      currentVersion,
      documentStatus,
      exportPdfRequest,
      downloadSignedDocumentPdf,
      isCurrentVersionLatest,
    } = this.props;
    if (documentStatus === SIGNED && isCurrentVersionLatest) {
      downloadSignedDocumentPdf(currentVersion.isQuestionnaire ? 'user_questionnaire' : 'document_version', currentVersion.slug);
    } else {
      exportPdfRequest(documentName);
    }
  }

  updateQuestionnaireName = ({ documentName, exportMethod }) => {
    const { userQuestionnaireId, questionnaireName } = this.props;

    if (questionnaireName !== documentName) { // check if the name has changed in the modal if not don't call update name api calls.
      this.props.updateQuestionnaireName(userQuestionnaireId, { name: documentName });
      this.props.updateDocumentName(documentName);
      this.setState({ exportMethod }, () => this.setState({ shouldDownloadDocument: true }));
    } else {
      this.setState({ exportMethod }, () => this.handleDownload());
    }
  };

  getDownloadButtonComponent = () => {
    const { variant, isMobile, disabled, isDownloading } = this.props;

    if (variant === SECONDARY) {
      return (
        <Tooltip title={<FormattedMessage id="questionnaire.download_tooltip" />}>
          <IconButton
            disabled={disabled || isDownloading}
            onClick={this.toggleModal}
            testId="DownloadPdf-download">
            <Download />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <Button
        disabled={disabled}
        onClick={this.toggleModal}
        glyph={Download}
        size={isMobile ? SMALL : LARGE}
        loading={isDownloading}
        testId="DownloadPdf-download">
        <FormattedMessage id="questionnaire.download" />
      </Button>
    );
  };

  render() {
    const { downloadDocumentModalOpened, exportMethod: lastExportMethod } = this.state;
    const { questionnaireName, isDownloading, exportToWordModule, documentStatus } = this.props;

    return (
      <>
        {this.getDownloadButtonComponent()}
        <DownloadDocumentModal
          open={downloadDocumentModalOpened}
          toggleModal={this.toggleModal}
          onDownloadDocument={this.updateQuestionnaireName}
          initialValues={{ documentName: questionnaireName, exportMethod: lastExportMethod || EXPORT_FILE_TYPE.PDF }}
          isDownloading={isDownloading}
          exportToWordModule={exportToWordModule}
          questionnaireStatus={documentStatus} />
      </>
    );
  }
}

// todo add here the ne action
export const isDownloadingSelectorWrapper = state => (
  isLoadingSelector(state, QuestionnaireTypes.DOWNLOAD_ATTACHMENT_REQUEST.replace(/\//g, '_').replace('_REQUEST', ''))
  || isLoadingSelector(state, ExportFileTypes.EXPORT_DOCX_REQUEST.replace(/\//g, '_').replace('_REQUEST', ''))
  || isLoadingSelector(state, ExportFileTypes.EXPORT_PDF_REQUEST.replace(/\//g, '_').replace('_REQUEST', ''))
  || isLoadingSelector(state, DocumentDetailsTypes.DOWNLOAD_SIGNED_DOCUMENT_PDF_REQUEST.replace(/\//g, '_').replace('_REQUEST', ''))
);

const mapStateToProps = state => ({
  userQuestionnaireId: _get(state, 'questionnaire.userQuestionnaire.id'),
  isDownloading: isDownloadingSelectorWrapper(state),
  questionnaireName: _get(state, 'questionnaire.userQuestionnaire.name', ''),
  exportToWordModule: _get(state, 'questionnaire.company.export_to_word_module'),
  currentVersion: selectCurrentDocumentVersion(state),
  isCurrentVersionLatest: selectIsCurrentVersionLatest(state),
  documentStatus: selectDocumentStatus(state),
});
const mapDispatchToProps = {
  downloadSignedDocumentPdf: DocumentDetailsActions.downloadSignedDocumentPdfRequest,
  updateQuestionnaireName: QuestionnaireActions.updateUserQuestionnaireRequest,
  updateDocumentName: DocumentDetailsActions.updateDocumentName,
  exportDocxRequest: ExportFileActions.exportDocxRequest,
  exportPdfRequest: ExportFileActions.exportPdfRequest,
};

export { DownloadPdfComponent, SECONDARY };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadPdfComponent);
