import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import compact from 'lodash/compact';
import { injectIntl, intlShape } from 'react-intl';

import proptypes from 'utils/proptypes';
import ANSWER_STATUS from 'shared/constants/answerStatuses';
import { TagsSelectList } from 'components/form/TagsSelectList';
import FieldRow from 'components/form/FieldRow';
import InputCheckbox from 'components/form/InputCheckbox';
import wasThisAnswerRemoved from './helpers/wasThisAnswerRemoved';

export class CheckboxQuestionComponent extends Component {
  static propTypes = {
    question: proptypes.radioQuestionScheme.isRequired,
    defaultValue: PropTypes.string,
    saveAnswer: PropTypes.func.isRequired,
    answer: proptypes.answerScheme,
    intl: intlShape.isRequired,
  };

  state = { checkedAnswers: this.props.defaultValue ? this.props.defaultValue.split(';') : [] };

  componentDidMount() {
    const { answer, saveAnswer, defaultValue } = this.props;

    if (wasThisAnswerRemoved(answer)) {
      saveAnswer(defaultValue, defaultValue);
    }
  }

  onCheckboxChange = (e) => {
    const { target: { value } } = e;
    const answers = this.state.checkedAnswers;
    let checkedAnswers;

    if (answers.includes(value)) {
      checkedAnswers = answers.filter(a => a !== value);
    } else {
      checkedAnswers = [...answers, value];
    }
    checkedAnswers = compact(checkedAnswers);

    this.updateAnswers(checkedAnswers);
  };

  updateAnswers = (checkedAnswers = []) => {
    const params = checkedAnswers.length ? undefined : { status: ANSWER_STATUS.DRAFT };
    this.setState({ checkedAnswers }, () => this.props.saveAnswer(checkedAnswers.join(';'), checkedAnswers.join(';'), params));
  };

  onTagsSelectListChange = (event) => {
    const checkedAnswers = event.target.value;
    this.updateAnswers(checkedAnswers);
  };

  onRemoveAnswer = (event) => {
    const { checkedAnswers } = this.state;
    const radix = 10;
    const index = parseInt(event.currentTarget.dataset.index, radix);
    const newCheckedAnswers = checkedAnswers.filter((answer, answerIndex) => answerIndex !== index);
    this.updateAnswers(newCheckedAnswers);
  };

  transformQuestionTags = questions => questions.map(question => ({ value: question.id, label: question.name }));

  render() {
    const { question, intl } = this.props;
    const options = get(question, 'details.options', []);

    return options.length < 10 ? (
      <React.Fragment>
        {options.map(field => (
          <FieldRow key={field.id}>
            <InputCheckbox
              checked={this.state.checkedAnswers.includes(field.id)}
              name="checkboxInput"
              onChange={this.onCheckboxChange}
              value={field.id}
              key={field.id}
              label={field.name}
              id={field.id} />
          </FieldRow>
        ))}
      </React.Fragment>
    ) : (
      <TagsSelectList
        name={`${question.name}-inputMultiSelect`}
        id={`${question.name}-inputMultiSelect`}
        selectedTags={this.state.checkedAnswers}
        tagsList={this.transformQuestionTags(options)}
        onChange={this.onTagsSelectListChange}
        remove={this.onRemoveAnswer}
        placeholder={intl.formatMessage({ id: 'helpers.select.prompt' })} />
    );
  }
}

export default injectIntl(CheckboxQuestionComponent);
