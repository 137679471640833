import { createActions } from 'reduxsauce';

export const REDUX_NAMESPACE = 'app/UsersEdit/';
export const REDUXACTIONS_STRUCTURE = {
  fetchUserRequest: ['userId'],
  fetchUserSuccess: ['user'],
  fetchUserFailure: ['error'],
  updateUserRequest: ['userId', 'userData'],
  updateUserSuccess: ['user'],
  updateUserFailure: ['error'],
  reinviteUserRequest: ['userId'],
  reinviteUserSuccess: null,
  reinviteUserFailure: ['error'],
};

export const { Types, Creators } = createActions(REDUXACTIONS_STRUCTURE, { prefix: REDUX_NAMESPACE });
