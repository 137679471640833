import React from 'react';
import { connect } from 'react-redux';
import {
  show,
  success,
  error,
  warning,
  info,
  hide,
  removeAll,
} from 'react-notification-system-redux';

export default function (WrappedComponent) {
  class withNotificationsHoc extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      showNotification: properties => dispatch(show(properties)),
      successNotification: properties => dispatch(success(properties)),
      errorNotification: properties => dispatch(error(properties)),
      warningNotification: properties => dispatch(warning(properties)),
      infoNotification: properties => dispatch(info(properties)),
      hideNotification: properties => dispatch(hide(properties)),
      removeAllNotification: properties => dispatch(removeAll(properties)),
    };
  }

  return connect(null, mapDispatchToProps)(withNotificationsHoc);
}
