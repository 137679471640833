import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

import { StyledGrid } from './styled-components';
import PersonalInformationForm from './PersonalInformationForm';
import PasswordChangeForm from './PasswordChangeForm';

class UserProfileForm extends PureComponent {
  static propTypes = {
    changePassword: PropTypes.func.isRequired,
    changePersonalInformation: PropTypes.func.isRequired,
    isUpdatingUser: PropTypes.bool.isRequired,
    userPersonalInformation: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
  }

  handlePersonalInformationForm = ({ firstName, lastName, email }) => {
    this.props.changePersonalInformation(firstName, lastName, email);
  };

  handlePasswordChangeForm = ({ currentPassword, newPassword, confirmPassword }) => {
    this.props.changePassword(currentPassword, newPassword, confirmPassword);
  };

  render() {
    const { isUpdatingUser, userPersonalInformation } = this.props;

    return (
      <StyledGrid container spacing={40}>
        <Grid classes={{ item: 'item--padding' }} item xs={12} sm={6}>
          <Typography classes={{ gutterBottom: 'profile-heading' }} variant="h5" gutterBottom>
            <FormattedMessage id="user_profile.user_profile_form.personal_information" />
          </Typography>
          <PersonalInformationForm
            initialValues={userPersonalInformation}
            onSubmit={this.handlePersonalInformationForm} />
        </Grid>
        <Grid classes={{ item: 'item--padding' }} item xs={12} sm={6}>
          <Typography classes={{ gutterBottom: 'profile-heading' }} variant="h5" gutterBottom>
            <FormattedMessage id="user_profile.user_profile_form.password_change" />
          </Typography>
          <PasswordChangeForm
            initialValues={{
              currentPassword: '',
              newPassword: '',
              confirmPassword: '',
            }}
            isUpdatingUser={isUpdatingUser}
            onSubmit={this.handlePasswordChangeForm} />
        </Grid>
      </StyledGrid>
    );
  }
}

export default UserProfileForm;
