import React from 'react';
import PropTypes from 'prop-types';
import Button, { SECONDARY, SMALL } from 'components/Button';
import styled from 'styled-components';
import EyeIcon from '@material-ui/icons/Visibility';
import { FormattedMessage } from 'react-intl';

const Styled = styled.div`
  display: flex;
  flex: 2;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0 10px 0 auto;
`;

const PreviewLink = ({ toggleModal }) => (
  <Styled>
    <Button testId="preview-document" variant={SECONDARY} size={SMALL} glyph={EyeIcon} onClick={toggleModal}>
      <FormattedMessage id="questionnaire.preview.show_full_screen" />
    </Button>
  </Styled>
);

PreviewLink.propTypes = { toggleModal: PropTypes.func.isRequired };
export default PreviewLink;
