import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

import Folder from '@material-ui/icons/Folder';
import Button from 'components/Button';
import { Creators } from 'pages/Templates/reducer';
import { getTemplateDetailEditPath } from 'MainApp/routesPaths';

const Header = styled.div`
  padding: 15px 100px;
  background-color: #fff;
  max-width: 100%;
  color: ${({ theme }) => theme.subtitleColor};

  .template-details-header__subheader {
    font-size: 1.1rem;
    font-weight: 300;
    margin-left: 20px;
    color: ${({ theme }) => theme.font.normal};
  }
`;

const StyledButton = styled(Button)`
  &.template-details-header__btn {
    font-weight: 400;
    padding-top: 13px;
    padding-bottom: 13px;
  }
`;

class TemplateDetailsHeader extends PureComponent {
  static propTypes = {
    fetchCurrentTemplateRequest: PropTypes.func.isRequired,
    clearTemplatesState: PropTypes.func.isRequired,
    templateId: PropTypes.string.isRequired,
    templateName: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history.isRequired,
  }

  // When moving templates out of bundles, these didMount/willUnmount should be the pages responsibility
  componentDidMount() {
    const { fetchCurrentTemplateRequest, templateId } = this.props;
    fetchCurrentTemplateRequest(templateId);
  }

  componentWillUnmount() {
    this.props.clearTemplatesState();
  }

  editDraft = () => {
    const { history, templateId } = this.props;
    history.push(getTemplateDetailEditPath(templateId));
  };

  render() {
    const { templateName } = this.props;
    return (
      <Header>
        <Grid container justify="space-between" alignItems="center">
          <Grid container alignItems="center" xs={12} sm={6}>
            <Folder />
            <Typography variant="subtitle1" inline classes={{ subtitle1: 'template-details-header__subheader' }}>
              {templateName}
            </Typography>
          </Grid>
          <Grid container justify="flex-end" xs={12} sm={2}>
            <StyledButton
              onClick={this.editDraft}
              className="template-details-header__btn"
              testId="TemplatesInformation-editDraft">
              <FormattedMessage id="visualization.top_toolbar.edit_draft" />
            </StyledButton>
          </Grid>
        </Grid>
      </Header>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { id } = props.match.params;
  return {
    templateId: id,
    templateName: get(state, 'templates.currentTemplate.name'),
  };
};

const mapDispatchToProps = {
  fetchCurrentTemplateRequest: Creators.fetchCurrentTemplateRequest,
  clearTemplatesState: Creators.clearTemplatesState,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TemplateDetailsHeader);
