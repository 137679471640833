import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import Page from 'components/Page';
import NavigationBar from 'components/NavigationBar';
import { UserEditContainer } from './containers/UserEditContainer';
import { UserEditPageHeader } from './components/UserEditPageHeader';

class UserListPage extends PureComponent {
  static propTypes = {
    currentUserId: PropTypes.string.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
  }

  getMemberId = () => this.props.match.params.memberId;

  isCurrentUser = () => this.props.currentUserId === this.getMemberId();

  render() {
    if (this.isCurrentUser()) {
      return <Redirect to="/members" />;
    }

    return (
      <Fragment>
        <NavigationBar />
        <Page>
          <UserEditPageHeader />
          <UserEditContainer />
        </Page>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({ currentUserId: _get(state, 'loggedInUserState.user.id') });

export default compose(
  connect(mapStateToProps),
)(UserListPage);
