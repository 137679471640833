import assert from 'assert';
import ApiClient from '../ApiClient';

export default class CredentialsService {
  /**
   * Service to manage Lexolve's Rest API credentials.
   *
   * @param {Object} obj
   * @param {ApiClient} obj.apiClient
   * @param {string} obj.key
   */
  constructor({ apiClient, credentialsStore } = {}) {
    assert.ok(
      apiClient instanceof ApiClient || typeof apiClient === 'undefined',
      'apiClient<ApiClient> is optional',
    );
    assert.ok(credentialsStore, 'credentialsStore<LocalStore> is required');

    this._apiClient = apiClient;
    this._credentialsStore = credentialsStore;
  }

  /**
   * Returns the current credentials without triggering an API request to get new ones.
   * If no credentials are currently available it will return null.
   *
   * @returns {Promise<Credentials|null>} - the credentials currently in use, null otherwise.
   */
  _fetchCurrentCredentials() {
    return this._credentialsStore.load();
  }

  /**
   * Returns a promise that will contain the credentials for this client.
   *
   * @returns {Promise<Credentials>} - Promise that resolves with the credentials.
   */
  fetchCredentials() {
    return this._fetchCurrentCredentials()
      .then((storedCredentials) => {
        if (storedCredentials) {
          return storedCredentials;
        }
        return null;
      });
  }

  /**
   * Saves new credentials which replace the existing ones and abort any pending request to get new ones.
   *
   * @param {Credentials} credentials - New credentials to store.
   * @returns {Promise<Credentials>} - Promise that resolves with the credentials.
   */
  saveCredentials(credentials) {
    return this._credentialsStore.save(credentials);
  }

  /**
   * Saves new credentials which replace the existing ones and abort any pending request to get new ones.
   *
   * @param {string} refreshToken
   * @returns {Promise<ApiClient>} - Promise that resolves with the credentials.
   */
  updateRefreshToken(refreshToken) {
    if (!this._apiClient) {
      return Promise.reject(new Error('CredentialsService.updateRefreshToken: No apiClient supplied'));
    }

    return this._apiClient.request(
      '/sessions/refresh_token',
      {
        method: 'patch',
        data: { refresh_token: refreshToken },
      },
    );
  }
}
