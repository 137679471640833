import cheerio from 'cheerio';

export default function removeQuestionTagsFromHtml(html, removeInputQuestions) {
  const $$ = cheerio.load(html);

  while ($$('[data-showif]').length) {
    $$('[data-showif]').each(function () {
      $$(this)
        .parent('[data-question-container]')
        .replaceWith($$(this).html());
    });
  }
  if (removeInputQuestions) {
    while ($$('[data-replace]').length) {
      $$('[data-replace]').each(function () {
        $$(this)
          .parent('[data-question-container]')
          .replaceWith($$(this).html());
      });
    }
  }

  return $$('body').html();
}
