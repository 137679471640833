import React from 'react';
import PropTypes from 'prop-types';
import removeQuestionFromDom from 'Visualization/helpers/removeQuestionFromDom';
import proptypes from 'utils/proptypes';
import Button, { SECONDARY, SMALL } from 'components/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import InputText from 'components/form/InputText';
import IconButton, { MINIMAL } from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import styles from './styles.css';


class Option extends React.Component {
  static propTypes = {
    enableTriggersFn: PropTypes.func.isRequired,
    triggersFnEnabled: PropTypes.bool.isRequired,
    onChangeFn: PropTypes.func.isRequired,
    removeOption: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    linkTranslation: PropTypes.string.isRequired,
    unlinkTranslation: PropTypes.string.isRequired,
    removeTranslation: PropTypes.string.isRequired,
    value: PropTypes.string,
    option: proptypes.optionScheme,
    index: PropTypes.number,
  };

  onChange = (e) => {
    this.props.onChangeFn(this.props.option.id, e);
  };

  removeTriggers = () => {
    const optionId = this.props.option.id;
    $(`[data-showif="${optionId}"]`).each((i, node) => {
      removeQuestionFromDom(node);
    });
    this.forceUpdate();
  };

  removeOption = () => {
    const optionId = this.props.option.id;
    $(`[data-showif="${optionId}"]`).each((i, node) => {
      removeQuestionFromDom(node);
    });
    this.props.removeOption(this.props.option.id);
  };

  optionAssigned = () => $(`[data-showif="${this.props.option.id}"]`).length > 0;

  render() {
    const {
      option, value, placeholder, linkTranslation, unlinkTranslation,
      removeTranslation, index, triggersFnEnabled, enableTriggersFn,
    } = this.props;

    return (
      <div className={styles.component}>
        <InputText
          className={styles.input}
          placeholder={placeholder}
          value={value}
          testId={`Option-textInput-${option.id}`}
          onChange={this.onChange} />
        <Tooltip title={removeTranslation}>
          <IconButton
            size={MINIMAL}
            className={styles.removeBtn}
            onClick={this.removeOption}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <div className={styles.optionActions}>
          {this.optionAssigned() ? (
            <Button
              testId={`Option-unlinkButton-${index}`}
              variant={SECONDARY}
              size={SMALL}
              onClick={this.removeTriggers}>
              {unlinkTranslation}
            </Button>
          ) : (
            <Button
              size={SMALL}
              testId={`Option-linkButton-${index}`}
              loading={triggersFnEnabled}
              disabled={triggersFnEnabled}
              onClick={enableTriggersFn}>
              {linkTranslation}
            </Button>
          )}
        </div>
      </div>
    );
  }
}
export default Option;
