import assert from 'assert';

import ApiClient from '../ApiClient';

export default class SignDocumentService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  signDocument({ questionnaireId, signingInfo } = {}) {
    assert.ok(questionnaireId, 'questionnaireId<string> is required');
    assert.ok(signingInfo, 'signingInfo is required');

    const signingDocument = {
      email_greetings: signingInfo.message,
      signers_emails: signingInfo.emails.tags,
      document_title: signingInfo.userQuestionnaireName,
      from: signingInfo.from,
      signing_method: signingInfo.signingMethod,
      signing_order: signingInfo.signingOrder,
      documentType: signingInfo.documentType,
      documentSlug: signingInfo.documentSlug,
      documentHtml: signingInfo.documentHtml,
      exportOptions: signingInfo.exportOptions,
    };

    return this.apiClient.request(
      `/signings?userQuestionnaire=${questionnaireId}`,
      {
        method: 'post',
        data: { signing_document: signingDocument },
      },
    );
  }

  cancelSigning(documentSlug, documentType = 'user_questionnaire') {
    assert.ok(documentSlug, 'documentSlug<string> is required');

    return this.apiClient.request(
      `/signings/${documentSlug}?documentSlug=${documentSlug}&documentType=${documentType}`,
      { method: 'delete' },
    );
  }

  downloadSignedDocument(documentType = 'user_questionnaire', documentSlug) {
    assert.ok(documentSlug, 'documentSlug<string> is required');

    return this.apiClient.request(`/signings/pdf?documentType=${documentType}&documentSlug=${documentSlug}`, { method: 'get' });
  }

  getErrors(documentSlug, documentType = 'document_version') {
    assert.ok(documentSlug, 'documentSlug<string> is required');

    return this.apiClient.get(`/signings/errors?documentType=${documentType}&documentSlug=${documentSlug}`);
  }
}
