import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button';
import { getTemplateDetailEditPath } from 'MainApp/routesPaths';
import { StyledFlexCenter } from './styled-components';

const Buttons = ({ templateId, push }) => (
  <StyledFlexCenter>
    <Button testId="edit-draft" onClick={() => push(getTemplateDetailEditPath(templateId))}>
      <FormattedMessage id="visualization.top_toolbar.edit_draft" />
    </Button>
  </StyledFlexCenter>
);

Buttons.propTypes = {
  templateId: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
};

export default Buttons;
