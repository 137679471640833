class Logger {
  constructor({ isEnabled = false } = {}) {
    this._isEnabled = Boolean(isEnabled);
  }

  isEnabled() {
    return this._isEnabled;
  }

  enable() {
    this._isEnabled = true;
  }

  disable() {
    this._isEnabled = false;
  }

  info(...params) {
    if (!this._isEnabled) {
      return;
    }
    // eslint-disable-next-line no-console
    console.info(...params);
  }

  warn(...params) {
    if (!this._isEnabled) {
      return;
    }
    // eslint-disable-next-line no-console
    console.warn(...params);
  }

  error(...params) {
    if (!this._isEnabled) {
      return;
    }
    // eslint-disable-next-line no-console
    console.error(...params);
  }
}

export default Logger;
