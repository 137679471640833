import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import Button, { SECONDARY } from 'components/Button';
import FieldRow from 'components/form/FieldRow';
import Label from 'components/form/Label';
import RadioGroup from 'components/form/RadioGroup';
import TagsInput from 'components/form/TagsInput';
import * as validator from 'shared/validation';
import { ModalFooter, ModalContent } from 'components/Modal';
import mapStateToProps from '../selectors';
import { Creators as userListActions } from '../actions';


const roleOptions = [
  {
    value: 'admin',
    label: <FormattedMessage id="users.roles.admin" />,
    subtitle: <FormattedMessage id="user_edit.roles_permissions.admin" />,
  },
  {
    value: 'producer',
    label: <FormattedMessage id="users.roles.producer" />,
    subtitle: <FormattedMessage id="user_edit.roles_permissions.producer" />,
  },
  {
    value: 'consumer',
    label: <FormattedMessage id="users.roles.consumer" />,
    subtitle: <FormattedMessage id="user_edit.roles_permissions.consumer" />,
  },
];

export class UserInviteForm extends PureComponent {
  static propTypes = {
    toggleModal: PropTypes.func.isRequired,
    responseError: PropTypes.shape({ data: PropTypes.shape({ email: PropTypes.string }) }),
    inviteUsers: PropTypes.func.isRequired,
  };

  requiredValues = ['role'];

  validate = (values) => {
    const errors = {};

    const emailsTags = _get(values, 'emails.tags', []);
    const emailsInputValue = _get(values, 'emails.inputValue', '');

    if (
      (!emailsTags.length && (validator.required(emailsInputValue) || validator.emailsList(emailsInputValue)))
      || (emailsTags.length && validator.emailsList(emailsInputValue))
    ) {
      errors.emails = <FormattedMessage id="users_invite.errors.no_emails" />;
    }

    this.requiredValues.forEach((name) => {
      if (validator.required(values[name])) {
        errors[name] = <FormattedMessage id="users_invite.errors.is_blank" />;
      }
    });

    return errors;
  };

  validateTag = (tag, tags) => {
    if (tags.some(email => email.toLowerCase() === tag.toLowerCase())) {
      return 'shared_components.tags_area.errors.email_exist';
    }

    if (validator.email(tag)) {
      return 'user_profile.personal_information_form.invalid_email';
    }

    return null;
  };

  getInitialValues = () => ({ emails: { tags: [], inputValue: '' }, role: 'producer' })

  isInitialValid = () => !Object.keys(this.validate(this.getInitialValues())).length

  getEmailResponseErrors = () => {
    const { responseError } = this.props;
    if (!responseError) {
      return '';
    }

    const { data: { emails = [] } = {} } = responseError;
    return emails.join('; ');
  };

  onSubmit = (values) => {
    this.props.inviteUsers(values.emails.tags, values.role);
    this.props.toggleModal();
  };

  render() {
    return (
      <Formik
        onSubmit={this.onSubmit}
        enableReinitialize
        initialValues={this.getInitialValues()}
        isInitialValid={this.isInitialValid}
        validate={this.validate}>
        {({ isValid, isSubmitting, handleChange, handleBlur }) => (
          <Form noValidate>
            <ModalContent>
              <FieldRow>
                <Label htmlFor="emails">
                  <FormattedMessage id="users_invite.form_labels.email_addresses" />
                </Label>
                <Field name="emails">
                  {({ form, field }) => (
                    <TagsInput
                      autofocus
                      errorMessage={this.getEmailResponseErrors()}
                      field={field}
                      form={form}
                      id="emails"
                      name="emails"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      validators={[this.validateTag]}
                      value={field.value} />
                  )}
                </Field>
              </FieldRow>
              <FieldRow>
                <Label htmlFor="role">
                  <FormattedMessage id="users_invite.form_labels.role" />
                </Label>
                <Field name="role">
                  {({ field }) => (
                    <RadioGroup
                      options={roleOptions}
                      {...field} />
                  )}
                </Field>
              </FieldRow>
            </ModalContent>
            <ModalFooter>
              <Button variant={SECONDARY} onClick={this.props.toggleModal} testId="UserInviteForm-cancel" type="cancel">
                <FormattedMessage id="users_invite.cancel" />
              </Button>
              <Button disabled={!isValid || isSubmitting} testId="UserInviteForm-send" type="submit">
                <FormattedMessage id="users_invite.send" />
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapDispatchToProps = { inviteUsers: userListActions.inviteUsersRequest };
export default connect(mapStateToProps, mapDispatchToProps)(UserInviteForm);
