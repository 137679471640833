import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { withFormik, Field } from 'formik';
import { injectIntl, intlShape } from 'react-intl';

import proptypes from 'utils/proptypes';
import * as v from 'shared/validation';
import ANSWER_STATUS from 'shared/constants/answerStatuses';
import FieldRow from 'components/form/FieldRow';
import InputText from 'components/form/InputText';
import wasThisAnswerRemoved from './helpers/wasThisAnswerRemoved';
import styles from './UnsignedIntegerQuestion.css';

const validator = fieldName => v.createValidator({ [fieldName]: [v.uint] });
const isValid = fieldName => values => isEmpty(validator(fieldName)(values));

export class UnsignedIntegerQuestionComponent extends Component {
  static propTypes = {
    saveAnswer: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    question: proptypes.inputQuestionScheme.isRequired,
    answer: proptypes.answerScheme,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.shape({ integer: PropTypes.string }).isRequired,
    touched: PropTypes.objectOf(PropTypes.bool).isRequired,
    startSavingAnswer: PropTypes.func.isRequired,
  };

  _saveAnswer = (value) => {
    const { saveAnswer, question } = this.props;
    const idToReplace = get(question, 'details.id', '');
    const params = isValid(question.id)(this.props.values) ? undefined : { status: ANSWER_STATUS.DRAFT };
    saveAnswer(idToReplace, value, params);
  };

  constructor() {
    super();
    this.onKeyPress = this.onKeyPress.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
  }

  componentDidMount() {
    const { defaultValue, answer } = this.props;
    // if defaultValue is filled and answer id is undefined then it means we should save that answer
    // as those values are from removedAnswers array and we need to
    // auto save it to be able to clikc NextQuestion
    if (wasThisAnswerRemoved(answer)) {
      this._saveAnswer(defaultValue);
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      touched,
      question,
      values,
      errors,
    } = this.props;

    return values[question.id] !== nextProps.values[question.id] || touched[question.id] !== nextProps.touched[question.id] || Object.keys(errors).length !== Object.keys(nextProps.errors).length;
  }

  componentDidUpdate = (prevProps) => {
    const { question } = this.props;
    const integerBefore = prevProps.values[question.id];
    const nextInteger = this.props.values[question.id];

    if (integerBefore === nextInteger) return;
    this.props.startSavingAnswer();
    this._saveAnswer(nextInteger);
  };

  onKeyPress(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    this.preventUnNumericValues(keyValue, event);
  }

  handlePaste(event) {
    const paste = event.clipboardData.getData('text');
    this.preventUnNumericValues(paste, event);
  }

  preventUnNumericValues(value, event) {
    const { question } = this.props;
    const validateData = {};
    validateData[question.id] = value;
    this.props.validateForm(validateData, this.props);
    if (v.uint(value)) {
      event.preventDefault();
    }
  }

  render() {
    const { intl, handleBlur, handleChange, question } = this.props;
    return (
      <Field name={question.id}>
        {({ form, field }) => (
          <FieldRow>
            <InputText
              {...field}
              className={styles.input}
              autoFocus
              placeholder={intl.formatMessage({ id: 'questionnaire.questions.input_number_placeholder' })}
              onChange={handleChange}
              onKeyPress={this.onKeyPress}
              onPasteCapture={this.handlePaste}
              onBlur={handleBlur}
              errorMessage={form.errors[field.name]}
              type="text" />
          </FieldRow>
        )}
      </Field>
    );
  }
}

export const formikConfig = {
  mapPropsToValues: props => ({ [props.name]: props.defaultValue }),
  isInitialValid: props => isValid(props.name)(props.initialValues),
  validate: (values, props) => validator(props.name)(values),
};

export default compose(
  injectIntl,
  withFormik(formikConfig),
)(UnsignedIntegerQuestionComponent);
