import { put, takeLatest, select } from 'redux-saga/effects';
import { error } from 'react-notification-system-redux';
import RangeHelper from 'Visualization/helpers/rangeHelper';
import { INPUT_REPLACE_TYPE } from 'shared/constants/logicTypes';
import { Types, Creators } from './LogicsManagerRedux';
import checkIfSelectionIsInsideQuestion from './LogicsTypes/TriggersFn/utils/checkIfSelectionIsInsideQuestion';
import checkIfOnlyOneOptionFromQuestionInSelection from './LogicsTypes/TriggersFn/utils/checkIfOnlyOneOptionFromQuestionInSelection';
import ExtendSelection from './LogicsTypes/TriggersFn/utils/extendSelection';
import checkIfAnyQuestionsWithinSelection from './LogicsTypes/TriggersFn/utils/checkIfAnyQuestionsWithinSelection';

function* validateCanAddQuestionSaga({ logicType, optionId, onSuccess, questionId }) {
  try {
    // TODO nodeshelper is throwing errors, can we fix nodehelper ?
    new ExtendSelection(logicType).call(); // eslint-disable-line
    // function to extend user selection - it helps editor apply correctly styles

    // we have to perform those two checks after selection have been extended
    if (!(yield* _isSelectionValid(logicType, questionId))) {
      return;
    }

    onSuccess(optionId, logicType, questionId);
  } catch (e) {
    yield put(Creators.validateCanAddQuestionFailure(e));
  }
}

function* setSelectionErrorMessageSaga({ errorMessage }) {
  if (!errorMessage) return;

  yield put(error({
    message: `visualization.logics_manager${errorMessage}`,
    level: 'error',
    useTranslate: true,
  }));
  new RangeHelper().clearSelection();
  const triggerImmediately = yield select(state => state.logicsManager.triggerImmediately);
  if (triggerImmediately) {
    yield put(Creators.setTriggerImmediately(false));
    yield put(Creators.setTriggersOptionId(''));
    yield put(Creators.toggleTriggersActive(false));
  }
  $('.temp-whitespace-for-selection-extend').remove();
}
function* _isSelectionValid(logicType, questionId) {
  if (
    checkIfSelectionIsInsideQuestion(INPUT_REPLACE_TYPE)
    || (logicType === INPUT_REPLACE_TYPE && checkIfAnyQuestionsWithinSelection())
  ) {
    yield put(Creators.setSelectionErrorMessage('.errors.no_additional_logic_to_text_that_will_be_replaced'));
    return false;
  }
  if (checkIfOnlyOneOptionFromQuestionInSelection(logicType, questionId)) {
    yield put(Creators.setSelectionErrorMessage('.errors.no_nested_options_from_one_question'));
    return false;
  }

  return true;
}

export default [
  takeLatest(Types.VALIDATE_CAN_ADD_QUESTION, validateCanAddQuestionSaga),
  takeLatest(Types.SET_SELECTION_ERROR_MESSAGE, setSelectionErrorMessageSaga),
];
