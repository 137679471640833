import React from 'react';
import PropTypes from 'prop-types';
import { SigningTypeWrapper, SigningTypeDescription, SigningTypeTitle } from './styled-components';

export const SigningTypeTooltipDescription = ({ imageSrc, title, text }) => (
  <SigningTypeWrapper>
    <img src={imageSrc} alt="signing type" />
    <SigningTypeTitle>{title}</SigningTypeTitle>
    <SigningTypeDescription>{text}</SigningTypeDescription>
  </SigningTypeWrapper>
);

SigningTypeTooltipDescription.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  text: PropTypes.node.isRequired,
};
