import { selectUserLanguage } from 'ducks/loggedInUser/selectors';
import _get from 'lodash/get';
import { createStructuredSelector } from 'reselect';

export default createStructuredSelector({
  appInitialized: state => state.appState.appInitialized,
  userLanguage: selectUserLanguage,
});

export const selectIsV2Experience = state => _get(state, 'appState.v2Experience', false);
export const selectWhiteLabelCompanyUrl = state => _get(state, 'appState.whiteLabelCompanyUrl', _get(state, 'questionnaire.company.white_label_redirect_url'));
