import assert from 'assert';
import { AMPLITUDE_APP_PROJECTNAME, AMPLITUDE_APP2_PROJECTNAME, AMPLITUDE_APP2_TRACKING_ID, AMPLITUDE_TRACKING_ID } from 'appRoot/constants/services';

const instanceStub = {
  identify: () => {},
  init: () => {},
  logEvent: () => {},
  setDeviceId: () => {},
  setGroup: () => {},
  setUserId: () => {},
  setUserProperties: () => {},
};

class AmplitudeService {
  constructor({ amplitude, isTrackingEnabled = true, logger, dashboardUser } = {}) {
    assert.ok(amplitude, 'amplitude is required');
    assert.ok(logger, 'logger is required');

    this._amplitude = amplitude;
    this._logger = logger;
    this._isTrackingEnabled = Boolean(isTrackingEnabled);
    this._userFromDashboard = dashboardUser;
    this._amplitudeProject = this._userFromDashboard ? AMPLITUDE_APP2_PROJECTNAME : AMPLITUDE_APP_PROJECTNAME;
    this._trackingId = this._userFromDashboard ? AMPLITUDE_APP2_TRACKING_ID : AMPLITUDE_TRACKING_ID;

    this._logger.info('[AMPLITUDE] class instance has initialised');
  }

  /**
   * Init tracking
   *
   * @returns {void}
   */
  initTracking() {
    assert.ok(this._amplitude, 'amplitude is required');
    assert.ok(typeof this._isTrackingEnabled === 'boolean', 'isTrackingEnabled<boolean> is required');

    if (this._logger && typeof this._logger.info === 'function') {
      this._logger.info('[AMPLITUDE] static init triggered');
    }

    if (!this._isTrackingEnabled) {
      return;
    }
    assert.ok(typeof this._trackingId === 'string', 'trackingId<string> is required');

    this._amplitude.getInstance(this._amplitudeProject)
      .init(this._trackingId, null, { includeReferrer: !this._userFromDashboard });

    if (!this._userFromDashboard) {
      const identify = new this._amplitude.Identify()
        .add('sessionTotal', 1)
        .set('lastSession', new Date().getTime());
      this._amplitude.getInstance(this._amplitudeProject)
        .identify(identify);
    }
  }

  /**
   * Disable event tracking
   *
   * @returns {void}
   */
  disableTracking() {
    this._logger.info('[AMPLITUDE] disableTracking');
    this._isTrackingEnabled = false;
  }

  /**
   * Enable event tracking
   *
   * @returns {void}
   */
  enableTracking() {
    this._logger.info('[AMPLITUDE] enableTracking');
    this._isTrackingEnabled = true;
  }

  /**
   * Get status of analytics tracking
   *
   * @returns {boolean}
   */
  isEnabled() {
    this._logger.info('[AMPLITUDE] isEnabled:', this._isTrackingEnabled);
    return this._isTrackingEnabled;
  }

  /**
   * Get amplitude instance
   *
   * @returns {object} - Amplitude instance.
   */
  getInstance() {
    if (!this._isTrackingEnabled) {
      return instanceStub;
    }
    return this._amplitude.getInstance(this._amplitudeProject);
  }

  /**
   * Identify the user
   * https://amplitude.zendesk.com/hc/en-us/articles/115001361248#callbacks-for-logevent-identify-and-redirect
   *
   * @param {string} userId
   * @returns {void}
   */
  identifyUser(userId) {
    assert.ok(typeof userId === 'string', 'userId<string> is required');

    this._logger.info('[AMPLITUDE] setUserId', { userId });
    this.getInstance().setUserId(userId);
  }

  /**
   * Set user properties
   * https://amplitude.zendesk.com/hc/en-us/articles/115001361248#setting-multiple-user-properties
   *
   * @param {object} properties - User properties to set
   * @returns {void}
   */
  setUserProperties(properties) {
    assert.ok(properties, 'properties are required');

    if (Object.keys(properties).length > 0) {
      this._logger.info('[AMPLITUDE] setUserProperties', { properties });
      this.getInstance().setUserProperties(properties);
    }
  }

  /**
   * Increment user property number
   * https://amplitude.zendesk.com/hc/en-us/articles/115001361248#setting-user-properties
   *
   * @param {string} name - User property name
   * @param {number} value - The value the property should be incremented by
   * @returns {void}
   */
  incrementUserProperty(name, value) {
    assert.ok(typeof name === 'string', 'name<string> is required');
    assert.ok(typeof value === 'number', 'value<number> is required');

    const identify = new this._amplitude.Identify()
      .add(name, value);

    this._amplitude.getInstance(this._amplitudeProject)
      .identify(identify);
  }

  /**
   * Logs event with properties in amplitude
   *
   * @param {string} eventName - Event logged in amplitude.
   * @param {object} properties - Object containing properties logged in amplitude
   */
  logEvent(eventName, properties) {
    assert.ok(typeof eventName === 'string', 'eventName<string> is required');
    assert.ok(properties, 'properties are required');

    this._logger.info('[AMPLITUDE] logEvent', { eventName, properties });
    this.getInstance().logEvent(eventName, properties);
  }
}

export default AmplitudeService;
