import React from 'react';
import GridItem from 'components/Grid/Item';
import PropTypes from 'prop-types';
import DownloadPdf from '../modules/DownloadPdf';

const PreviewCompleted = ({ isMobile, disabled }) => (
  <GridItem xs="auto" sm="auto">
    <DownloadPdf isMobile={isMobile} disabled={disabled} />
  </GridItem>
);

PreviewCompleted.propTypes = {
  isMobile: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PreviewCompleted;
