import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal, { ModalContent, ModalFooter } from 'components/Modal';
import Button, { SECONDARY, DANGER } from 'components/Button';
import WarningIcon from '@material-ui/icons/Warning';
import styles from './DeleteDocumentConfirmationModal.css';

const DeleteDocumentConfirmationModal = ({ toggleModal, deleteDocument, open }) => (
  <Modal
    open={open}
    hiddenCloseButton
    size="medium"
    toggleModal={toggleModal}>
    <ModalContent>
      <div className={styles.container} data-test-id="Documents-deleteConfirmationContent">
        <WarningIcon className={styles.warning} />
        <h1 className={styles.title}>
          <FormattedMessage id="documents_list.delete_document_modal.title" />
        </h1>
        <p>
          <FormattedMessage id="documents_list.delete_document_modal.description" />
        </p>
        <p>
          <FormattedMessage id="documents_list.delete_document_modal.reverse_description" />
        </p>
        <p className={styles.confirmation}>
          <FormattedMessage id="documents_list.delete_document_modal.wish_to_abort" />
        </p>
      </div>
    </ModalContent>
    <ModalFooter>
      <Button onClick={toggleModal} variant={SECONDARY} testId="Documents-cancelDelete">
        <FormattedMessage id="shared.cancel" />
      </Button>
      <Button color={DANGER} onClick={deleteDocument} testId="Documents-deleteDocument">
        <FormattedMessage id="documents_list.delete_document_modal.delete" />
      </Button>
    </ModalFooter>
  </Modal>
);

DeleteDocumentConfirmationModal.propTypes = {
  deleteDocument: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DeleteDocumentConfirmationModal;
