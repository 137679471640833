import styled from 'styled-components';

export default styled.div.attrs({ 'data-disable-exactly': true /* Disable exactlyapp.com extension */ })`
  .mce-edit-focus {
    outline: none;
  }
  .mce-active {
    background: #fff !important;
    border-width: 1px !important;
    border-color: #e2e4e7 !important;
    i {
      color: #474747 !important;
    }
  }
  }
  strong {
    font-weight: bold;
  }

  .mce-content-body {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);

    h1,
    h2 {
      margin: 0.67rem 0;
    }

    h3,
    h4 {
      margin: 1rem 0;
    }
  }
`;
