import styled from 'styled-components';
import { rem } from 'polished/lib/index';
import FieldLabel from 'components/form/Label';

export const StyledTextInput = styled.div`
  align-items: center;
  input {
    width: 100%;
    height: 40px;
    padding: 0 7px;
    background-color: ${p => p.theme.background.white};
    border: 1px solid ${p => p.theme.border.normal} !important;
    margin: 0;
    outline: 0;
    border-radius: 4px;
    font-size: ${rem('15px')};
    transition: box-shadow 0.2s;
    &:focus {
      border-bottom: 2px solid ${p => p.theme.mainColorHover} !important;
    }
  }
`;

export const Label = styled(FieldLabel)`
  height: 18px;
  font-size: ${rem('14px')};
  font-weight: normal;
  color: ${p => p.theme.font.logicLabel};
  margin-bottom: 12px;
`;

export const HelpTextEditorContainer = styled.div`
  margin-top: 20px;
`;

export const HelpTextEditorWrapper = styled.div`
  border: 1px solid ${p => p.theme.border.normal};
  border-radius: 4px;

  .mce-active {
    background: #fff !important;
    border-width: 1px !important;
    border-color: #e2e4e7 !important;
    i {
      color: #474747 !important;
    }
  }

  .mce-panel {
    border: 0px;
    box-shadow: none;
  }

  .mce-top-part::before {
    box-shadow: none;
    border-bottom: 1px solid ${p => p.theme.border.normal};
  }

  .mce-btn {
    margin: 0 6px;
  }

  #logics_manager_help_text {
    box-shadow: none;
  }

  [class*='mce-i-']::before {
    width: 14px;
    height: 14px;
    margin: 0px;
  }
`;
