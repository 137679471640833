import compact from 'lodash/compact';
import cheerio from 'cheerio';

export default function getOptionsFromHtml(html) {
  const $ = cheerio.load(html);
  const optionsIds = [];
  $('[data-showif]').each((index, item) => {
    optionsIds.push(...item.attribs['data-showif'].split(';'));
  });
  $('[data-replace]').each((index, item) => {
    optionsIds.push(item.attribs['data-replace']);
  });
  return compact(optionsIds);
}
