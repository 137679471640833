import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import { Field } from 'formik';
import EmailIcon from '@material-ui/icons/Email';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputCheckbox from 'components/form/InputCheckbox';
import Typography from 'components/Typography';
import Button from 'components/Button';
import InputText from 'components/form/InputText';
import FindBlackContract from 'icons/FindBlackContract';
import styles from './index.css';

class SecondStep extends PureComponent {
  inputRef = null;

  componentDidUpdate(prevProps) {
    const { autofocus } = this.props;

    if (!prevProps.autofocus && autofocus) {
      this.inputRef.focus();
    }
  }

  setInputRef = (ref) => {
    this.inputRef = ref;
  };

  render() {
    const { intl, isValid, isSubmitting, touched, errors, ...otherProps } = this.props;
    return (
      <Fragment>
        <FindBlackContract className={styles.picture} />
        <Typography variant="h3">
          <FormattedMessage id="share_wizard.find_contract" />
        </Typography>
        <Typography variant="bodySmall" className={styles.paragraph}>
          <FormattedMessage id="share_wizard.provide_email" />
        </Typography>
        <Field
          name="email"
          {...otherProps}
          render={({ field }) => (
            <InputText
              {...field}
              className={styles.styledInput}
              inputRef={this.setInputRef}
              placeholder={intl.formatMessage({ id: 'share_wizard.enter_email' })}
              errorMessage={touched.email && errors.email}
              startAdornment={<EmailIcon />}
              testId="Page-GenericAccessLink-SecondStep-email-Input" />
          )} />
        <Field
          name="acceptedTerms"
          {...otherProps}
          render={({ field }) => (
            <FormControlLabel
              className={styles.formControl}
              control={(
                <InputCheckbox
                  {...field}
                  label={(
                    <span className={styles.termsCheckboxLabel}>
                      <FormattedMessage id="share_wizard.accept" />
                      {' '}
                      <a
                        rel="noopener noreferrer"
                        href="https://www.lexolve.com/tos"
                        target="_blank"
                        className={styles.link}>
                        <FormattedMessage id="share_wizard.terms_and_condition" />
                      </a>
                    </span>
                  )}
                  testId="Page-GenericAccessLink-SecondStep-acceptedTerms-CheckBox" />
              )} />
          )} />
        <Button
          loading={isSubmitting}
          disabled={!isValid}
          type="submit"
          className={styles.buttonNext}
          testId="Page-GenericAccessLink-SecondStep-createDocument-Button">
          <FormattedMessage id="share_wizard.create_document" />
        </Button>
      </Fragment>
    );
  }
}

SecondStep.propTypes = {
  isValid: PropTypes.bool,
  intl: intlShape.isRequired,
  errors: PropTypes.shape({ email: PropTypes.string }).isRequired,
  touched: PropTypes.shape({ email: PropTypes.bool }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  autofocus: PropTypes.bool,
};

export default SecondStep;
