import assert from 'assert';
import queryString from 'qs';

import ApiClient from '../ApiClient';

export default class TemplatesService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  createNewTemplate(folderId) {
    return this.apiClient.request('/questionnaire_templates', {
      method: 'post',
      data: { folder_id: folderId },
    });
  }

  createNewTemplateVersion(questionnaireTemplateId) {
    assert.ok(questionnaireTemplateId, 'questionnaireTemplateId<string> is required');
    return this.apiClient.request(`/questionnaire_templates/${questionnaireTemplateId}/edit`, { method: 'get' });
  }

  fetchTemplates() {
    return this.apiClient.request('/questionnaire_templates', { method: 'get' });
  }

  fetchTemplateDetails(slug) {
    assert.ok(slug, 'slug<string> is required');

    return this.apiClient.request(`/questionnaire_templates/${slug}`, { method: 'get' });
  }

  updateTemplate(questionnaireTemplateId, params) {
    assert.ok(questionnaireTemplateId, 'questionnaireTemplateId<string> is required');
    assert.ok(params, 'params are required');

    return this.apiClient.request(`/questionnaire_templates/${questionnaireTemplateId}`, {
      method: 'patch',
      data: params,
    });
  }

  moveTemplateToFolder(templateId, folderId) {
    assert.ok(templateId, 'templateId<string> is required');
    assert.notStrictEqual(folderId, undefined, 'folderId<string> is required');

    return this.apiClient.request(`/questionnaire_templates/${templateId}/move_to_folder/${folderId}`, { method: 'patch' });
  }

  fetchTemplatesInfo(queryParams) {
    return this.apiClient.request(`/questionnaire_templates?${queryString.stringify(queryParams)}`, { method: 'get' });
  }

  fetchPublishedTemplatesInfo(queryParams) {
    return this.apiClient.request(`/published_templates?${queryString.stringify(queryParams)}`, { method: 'get' });
  }

  fetchTemplateVersionHistory(templateId) {
    assert.ok(templateId, 'templateId<string> is required');

    return this.apiClient.request(`/questionnaire_templates/${templateId}/questionnaire_template_versions`, { method: 'get' });
  }

  fetchDocumentsForTemplate(templateId) {
    assert.ok(templateId, 'templateId<string> is required');

    return this.apiClient.request(`/questionnaire_templates/${templateId}/user_questionnaires`, { method: 'get' });
  }

  publishQuestionnaireTemplateVersion(questionnaireTemplateVersionId, params) {
    assert.ok(questionnaireTemplateVersionId, 'questionnaireTemplateVersionId<string> is required');
    assert.ok(params, 'params is requred');

    return this.apiClient.request(`/questionnaire_template_versions/${questionnaireTemplateVersionId}/publish`, {
      method: 'put',
      data: params,
    });
  }

  unpublishQuestionnaireTemplateVersion(questionnaireTemplateVersionId) {
    assert.ok(questionnaireTemplateVersionId, 'questionnaireTemplateVersionId<string> is required');

    return this.apiClient.request(`/questionnaire_template_versions/${questionnaireTemplateVersionId}/unpublish`, { method: 'put' });
  }

  moveToTrash(templateId) {
    assert.ok(templateId, 'templateId<string> is required');

    return this.apiClient.request(`/questionnaire_templates/${templateId}/trash`, { method: 'post' });
  }

  restoreFromTrash(trashId) {
    assert.ok(trashId, 'trashId<string> is required');

    return this.apiClient.request(`/deleted_items/${trashId}/restore`, { method: 'put' });
  }

  patchTemplateSettings(templateId, settings = {}) {
    assert.ok(templateId, 'templateId<string> is required');
    assert.ok(settings, 'settings are required');

    return this.apiClient.request(`/questionnaire_template_versions/${templateId}`, {
      method: 'patch',
      data: { questionnaire_template_version: { qtv_property_attributes: settings } },
    });
  }

  validateTemplate(templateSharingSlug) {
    assert.ok(templateSharingSlug, 'templateSharingSlug<string> is required');

    return this.apiClient.request(`/questionnaire_templates/${templateSharingSlug}/correctness`, { method: 'get' });
  }
}
