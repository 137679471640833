import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Logo from 'components/Logo';
import NoAccessIcon from 'icons/NoAccess';
import styles from './index.css';

export default () => (
  <div className={styles.component}>
    <Logo className={styles.logo} />
    <NoAccessIcon className={styles.image} />
    <h1 className={styles.title}><FormattedMessage id="shared.errors.user_disabled.title" /></h1>
    <p className={styles.message}>
      <FormattedMessage id="shared.errors.user_disabled.subtitle" values={{ newLine: <br /> }} />
    </p>
    <p className={cn(styles.message, styles.bottomSpacing)}>
      <FormattedMessage id="shared.errors.user_disabled.message" values={{ newLine: <br /> }} />
    </p>
    <Button className={styles.button} testId="ErrorUserDisabled-signIn">
      <Link to="/auth/sign-in">
        <FormattedMessage id="shared.errors.user_disabled.button" />
      </Link>
    </Button>
  </div>
);
