import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import IconButton, { PRIMARY } from 'components/IconButton';
import Typography, { VARIANTS, COLORS } from 'components/Typography';
import styles from './styles.css';

/**
 * Get correct icon for two different states:
 * - when button is on the left or right(rtl)
 * - if the toggle button is checked or not
 *
 * @param {boolean} checked is toggle button checked
 * @param {boolean} rtl is toggle button on the right or on the left
 * @returns {React.ComponentType} icon
 */
const getIcon = (checked, rtl) => {
  if (checked) {
    return rtl ? ChevronLeft : ChevronRight;
  }
  return rtl ? ChevronRight : ChevronLeft;
};

function ToggleButton({ rtl, caption, checked, onToggle, className }) {
  const IconComponent = getIcon(checked, rtl);
  const classes = cn(styles.component, className, {
    [styles.componentReverse]: rtl,
    [styles.componentUnchecked]: !checked,
  });

  return (
    <div
      onClick={onToggle}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      data-test-id="toggle-btn"
      className={classes}>
      <IconButton variant={PRIMARY} className={styles.iconBtn}>
        <IconComponent className={styles.icon} />
      </IconButton>
      <Typography variant={VARIANTS.CAPTION} color={COLORS.PRIMARY}>
        {checked ? <FormattedMessage id="questionnaire.toggle_button.hide" /> : caption}
      </Typography>
    </div>
  );
}

ToggleButton.propTypes = {
  rtl: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  caption: PropTypes.node,
  className: PropTypes.string,
};

ToggleButton.defaultProps = { rtl: false };

export default ToggleButton;
