import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button, { SMALL, LARGE } from 'components/Button';
import SaveVersionModal from './SaveVersionModal';

export default class SaveVersion extends Component {
  static propTypes = { isMobile: PropTypes.bool };

  // eslint-disable-next-line react/no-unused-state
  state = { isOpen: false };

  toggleModal = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  render() {
    const { isMobile } = this.props;

    return (
      <div>
        <Button
          onClick={this.toggleModal}
          size={isMobile ? SMALL : LARGE}
          testId="DocumentPreview-SaveVersion">
          <FormattedMessage id="questionnaire.save_version" />
        </Button>
        {this.state.isOpen && (
          <SaveVersionModal
            open={this.state.isOpen}
            toggleModal={this.toggleModal} />
        )}
      </div>
    );
  }
}
