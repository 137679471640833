import assert from 'assert';

const refreshTokenInterceptor = ({ apiClient, credentialsService }) => {
  assert.ok(apiClient, 'apiClient<apiClient> is required');
  assert.ok(credentialsService, 'credentialsService<credentialsService> is required');

  return async (failedRequest) => {
    const credentials = await credentialsService.fetchCredentials();

    if (!credentials || !credentials.refreshToken) {
      return Promise.resolve();
    }

    return credentialsService.updateRefreshToken(credentials.refreshToken).then(({ data }) => credentialsService.saveCredentials(({
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
    })).then(() => {
      // eslint-disable-next-line no-param-reassign
      failedRequest.response.config.headers.Authorization = `Bearer ${data.access_token}`;
      return Promise.resolve();
    })).catch(() => Promise.resolve());
  };
};
export default refreshTokenInterceptor;
