import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, withFormik } from 'formik';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import _get from 'lodash/get';

import FieldRow from 'components/form/FieldRow';
import InputText from 'components/form/InputText';
import InputPassword from 'components/form/InputPassword';
import Label from 'components/form/Label';
import Button from 'components/Button';
import { isPasswordStrong } from 'utils/password';
import { isLoading as isLoadingSelector } from 'utils/statusSelectors';
import { Creators } from '../actions';


import styles from './index.css';

export class UserInvitationFormComponent extends PureComponent {
  static propTypes = {
    checkInvitation: PropTypes.func.isRequired,
    acceptInvitation: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    values: PropTypes.shape({
      last_name: PropTypes.string,
      first_name: PropTypes.string,
      role: PropTypes.string,
      email: PropTypes.string,
    }),
    match: ReactRouterPropTypes.match,
  };

  componentDidMount() {
    const invitationId = _get(this.props, 'match.params.invitationId');
    if (invitationId) {
      this.props.checkInvitation({ invitationId });
    }
  }

  render() {
    const { handleSubmit, isLoading } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate className={styles.form}>
        <FieldRow>
          <Label htmlFor="first_name">
            <FormattedMessage id="user_invitations.fields.first_name" />
          </Label>
          <Field name="first_name">
            {({ field, form }) => (
              <InputText
                errorMessage={form.errors[field.name]}
                testId="UserInvitation_firstName"
                id="first_name"
                required
                {...field} />
            )}
          </Field>
        </FieldRow>

        <FieldRow>
          <Label htmlFor="last_name">
            <FormattedMessage id="user_invitations.fields.last_name" />
          </Label>
          <Field name="last_name">
            {({ field, form }) => (
              <InputText
                errorMessage={form.errors[field.name]}
                id="last_name"
                testId="UserInvitation_lastName"
                required
                {...field} />
            )}
          </Field>
        </FieldRow>

        <FieldRow>
          <Label htmlFor="password">
            <FormattedMessage id="user_invitations.fields.password" />
          </Label>
          <Field name="password">
            {({ field, form }) => (
              <InputPassword
                errorMessage={form.errors[field.name]}
                id="password"
                testId="UserInvitation_password"
                required
                showStrengthIndicator
                {...field} />
            )}
          </Field>
        </FieldRow>

        <FieldRow>
          <Label htmlFor="repeat_password">
            <FormattedMessage id="user_invitations.fields.repeat_password" />
          </Label>
          <Field name="repeat_password">
            {({ field, form }) => (
              <InputPassword
                errorMessage={form.errors[field.name]}
                id="repeat_password"
                testId="UserInvitation_repeatPassword"
                required
                {...field} />
            )}
          </Field>
        </FieldRow>

        <div className={styles.termsStatement}>
          <FormattedMessage
            id="user_invitations.terms_statement"
            values={{
              terms: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.lexolve.com/tos">
                  <FormattedMessage id="user_invitations.terms_label" />
                </a>
              ),
              privacy: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.lexolve.com/privacy-policy">
                  <FormattedMessage id="user_invitations.privacy_label" />
                </a>
              ),
            }} />
        </div>
        <Button loading={isLoading} className={styles.sendButton} testId="UserInvitation-send" type="submit">
          <FormattedMessage id="user_invitations.send" />
        </Button>
      </form>
    );
  }
}

const requiredValues = ['first_name', 'last_name', 'password', 'repeat_password'];
export const formConfig = {
  isInitialValid: false,
  mapPropsToValues: () => ({
    first_name: '',
    last_name: '',
    password: '',
    repeat_password: '',
  }),
  validate: (values) => {
    const errors = {};

    requiredValues.forEach((name) => {
      if (!values[name]) errors[name] = <FormattedMessage id="user_invitations.cant_be_blank" />;
    });

    if (values.password !== values.repeat_password) {
      errors.repeat_password = <FormattedMessage id="user_invitations.passwords_must_match" />;
    }

    if (errors.password == null && !isPasswordStrong(values.password)) {
      errors.password = <FormattedMessage id="user_invitations.password_not_strong_enough" />;
    }

    return errors;
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true);
    props.acceptInvitation({
      data: values,
      token: _get(props, 'match.params.invitationId'),
    });
  },
};

const mapStateToProps = state => ({
  data: state.data,
  isLoading: isLoadingSelector(state, 'appUserListInviteUsers'),
});

const mapDispatchToProps = {
  checkInvitation: Creators.checkInvitationRequest,
  acceptInvitation: Creators.acceptInvitationRequest,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withFormik(formConfig),
)(UserInvitationFormComponent);
