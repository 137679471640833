import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import API from 'Visualization/services/Api';
import { Creators as QuestionActions } from 'Visualization/Editor-v2/Questions/QuestionsRedux';
import addFormatsToSelection from './TriggersFn/utils/addFormatsToSelection';
import { Creators as LogicsManagerActions } from '../LogicsManagerRedux';

export default function (WrappedComponent) {
  class withTriggersFnHoc extends React.Component {
    static propTypes = {
      triggersFnEnabled: PropTypes.bool.isRequired,
      triggerImmediately: PropTypes.bool.isRequired,
      triggeredOptionId: PropTypes.string,
      toggleTriggersActive: PropTypes.func.isRequired,
      setTriggersOptionId: PropTypes.func.isRequired,
      closeLogicsManager: PropTypes.func.isRequired,
      setTriggerImmediately: PropTypes.func.isRequired,
      addNewQuestionSuccess: PropTypes.func.isRequired,
      setCurrentQuestion: PropTypes.func.isRequired,
    };

    state = { creatingQuestion: false };

    enableImmediatelyTriggersFn = (questionParams, callback) => {
      // this function will first create question then enables triggers fn
      // used to instanly assign logic after user has selected text and opens
      // logic manager
      try {
        this.setState({ creatingQuestion: true }, async () => {
          const response = await API.addNewQuestion(questionParams);
          const { question } = response.data;
          const optionId = question.details.id;
          this.setState({ creatingQuestion: false });
          this.props.setTriggerImmediately(true);
          this.props.addNewQuestionSuccess(question);
          this.props.setCurrentQuestion(question);
          this.assignAndKeepOpen(optionId, 'inputReplaceType', question.id);
          if (callback) callback();
        });
      } catch (e) {
        this.stopTriggersFn();
        this.props.addNewQuestionFailure(e);
      }
    };

    enableTriggersFn = (optionId, questionParams) => {
      // this function will enable triggers fn,
      if (this.state.creatingQuestion) return;

      if (questionParams.id) {
        this.startTriggersFn(optionId);
        return;
      }

      try {
        this.setState({ creatingQuestion: true }, async () => {
          const response = await API.addNewQuestion(questionParams);
          const { question } = response.data;
          this.props.addNewQuestionSuccess(question);
          this.props.setCurrentQuestion(question);
          this.startTriggersFn(optionId);
        });
      } catch (e) {
        this.props.addNewQuestionFailure(e);
        this.stopTriggersFn();
      }
    };

    startTriggersFn = (optionId) => {
      this.props.setTriggersOptionId(optionId);
      this.props.toggleTriggersActive(true);
      this.setState({ creatingQuestion: false });
    };

    stopTriggersFn = () => {
      this.props.setTriggerImmediately(false);
      this.props.setTriggersOptionId('');
      this.props.toggleTriggersActive(false);
      this.setState({ creatingQuestion: false });
    };

    refresh = () => {
      // method to refresh triggers buttons after removal of assigned options from text
      this.forceUpdate();
    };

    assignAndClose = (optionId, logicType, questionId) => {
      addFormatsToSelection(optionId, logicType, questionId);
      this.stopTriggersFn();
      this.props.closeLogicsManager();
    };

    assignAndKeepOpen = (optionId, logicType, questionId) => {
      addFormatsToSelection(optionId, logicType, questionId);
      this.stopTriggersFn();
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          triggerImmediately={this.props.triggerImmediately}
          triggersFnEnabled={this.props.triggersFnEnabled}
          triggeredOptionId={this.props.triggeredOptionId}
          enableTriggersFn={this.enableTriggersFn}
          enableImmediatelyTriggersFn={this.enableImmediatelyTriggersFn}
          stopTriggersFn={this.stopTriggersFn}
          assignAndClose={this.assignAndClose}
          assignAndKeepOpen={this.assignAndKeepOpen}
          refreshOptions={this.refresh} />
      );
    }
  }
  function mapStateToProps(state) {
    return {
      triggersFnEnabled: state.logicsManager.triggersFnEnabled,
      triggeredOptionId: state.logicsManager.triggeredOptionId,
      triggerImmediately: state.logicsManager.triggerImmediately,
    };
  }
  const mapDispatchToProps = {
    closeLogicsManager: LogicsManagerActions.closeLogicsManager,
    toggleTriggersActive: LogicsManagerActions.toggleTriggersActive,
    setTriggersOptionId: LogicsManagerActions.setTriggersOptionId,
    setTriggerImmediately: LogicsManagerActions.setTriggerImmediately,
    addNewQuestionSuccess: QuestionActions.addNewQuestionSuccess,
    setCurrentQuestion: QuestionActions.setCurrentQuestion,
    addNewQuestionFailure: QuestionActions.addNewQuestionFailure,
  };

  return connect(mapStateToProps, mapDispatchToProps)(withTriggersFnHoc);
}
