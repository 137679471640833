import ReactDom from 'react-dom';
import flatMap from 'lodash/flatMap';
import isFunction from 'lodash/isFunction';
import {
  findDirectQuestionNodes,
  performRenderQuestion,
} from 'Visualization/Editor-v2/utils/changeQuestionNodesToReactComponents';

export const observeQuestions = (targetElm) => {
  const observerConfig = { childList: true, subtree: true };
  const observer = new MutationObserver((mutationList) => {
    mutationList
      .filter(mutation => mutation.type === 'childList' && mutation.addedNodes.length > 0)
      .forEach((mutation) => {
        mutation.removedNodes.forEach((node) => {
          if (!isFunction(node.getAttribute)) return;
          if (node.getAttribute('data-question-container')) {
            ReactDom.unmountComponentAtNode(node);
          }
        });
        const questionNodes = flatMap(mutation.addedNodes, node => findDirectQuestionNodes(node));
        performRenderQuestion(questionNodes);
      });
  });

  observer.observe(targetElm, observerConfig);
};

export default null;
