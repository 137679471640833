exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-components-Breadcrumbs-___index__wrapper___2OryM {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  color: #767676;\n  color: var(--c-bw-50);\n}\n\nsvg.app-components-Breadcrumbs-___index__iconItem___L3ZkK {\n  margin: 0 4px;\n}\n", "", {"version":3,"sources":["/workspace/app/components/Breadcrumbs/index.css"],"names":[],"mappings":"AAAA;EACE,cAAa;EACb,oBAAmB;EACnB,aAAY;EACZ,eAAqB;EAArB,sBAAqB;CACvB;;AAEA;EACE,cAAa;CACf","file":"index.css","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  color: var(--c-bw-50);\n}\n\nsvg.iconItem {\n  margin: 0 4px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "app-components-Breadcrumbs-___index__wrapper___2OryM",
	"iconItem": "app-components-Breadcrumbs-___index__iconItem___L3ZkK"
};