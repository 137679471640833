import assert from 'assert';

/**
 * Class used for all Lexolve API calls.
 */
export default class ApiClient {
  /**
   * Create an ApiClient instance.
   *
   * @param {Object} options - options for the api client.
   * @param {Object} options.httpClient - http client to make requests
   */
  constructor({ httpClient } = { httpClient: undefined }) {
    assert.ok(httpClient, 'httpClient<HttpClient> is required');

    this.httpClient = httpClient;
  }

  /**
   * Wrapper for the fetch API
   *
   * @param {string} path - URL (appended to base URL) where API call will be made
   * @param {object} options - Required. Contains the data needed for the fetch API
   * @return {Promise<Response>} A promise which will return a Response object (https://developer.mozilla.org/en-US/docs/Web/API/Response)
   */
  request(path, options) {
    assert.ok(options, 'options are required');

    return this.httpClient.request(path, options);
  }

  post(path, options = {}) {
    return this.httpClient.request(path, { ...options, method: 'post' });
  }

  patch(path, options = {}) {
    return this.httpClient.request(path, { ...options, method: 'patch' });
  }

  put(path, options = {}) {
    return this.httpClient.request(path, { ...options, method: 'put' });
  }

  get(path, options = {}) {
    return this.httpClient.request(path, { ...options, method: 'get' });
  }

  delete(path, options = {}) {
    return this.httpClient.request(path, { ...options, method: 'delete' });
  }
}
