import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { withAcl } from 'services/acl';
import DataProvider from 'shared/DataProvider';
import EmptyBucket from 'components/EmptyBucket';
import FolderList from 'components/FolderList';
import RecordsTable, { TableCell } from 'shared/Components/RecordsTable';
import { CONSUMER_ROLE } from 'shared/constants/user/roles';
import { Creators, Types } from 'pages/Documents/actions';
import DocumentTableRow from './components/TableRows/DocumentTableRow';
import NewDocumentDialog from './components/NewDocumentDialog';
import styles from './components/styles.css';
import { selectDuplicateDocumentEnabled } from './selectors';


export class DocumentsComponent extends Component {
  static propTypes = {
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match,
    intl: intlShape.isRequired,
    hasRole: PropTypes.func.isRequired,
  }

  state = { newDocumentDialogOpen: false };

  handleFolderClick = folderId => this.props.history.push(`/documents/folders/${folderId}`);

  toggleDocumentDialog = () => {
    this.setState(({ newDocumentDialogOpen }) => ({ newDocumentDialogOpen: !newDocumentDialogOpen }));
  };

  getFolderId = () => (this.props.match.params.folderId ? this.props.match.params.folderId : undefined);

  renderHeaders = () => {
    const { intl } = this.props;

    return [
      <TableCell width="40%" key="document">
        {intl.formatMessage({ id: 'documents_list.columns_names.document' })}
      </TableCell>,
      <TableCell width="10%" key="created_at">
        {intl.formatMessage({ id: 'documents_list.columns_names.created_at' })}
      </TableCell>,
      <TableCell width="10%" key="edited_at">
        {intl.formatMessage({ id: 'documents_list.columns_names.edited_at' })}
      </TableCell>,
      <TableCell width="12%" key="status">
        {intl.formatMessage({ id: 'documents_list.columns_names.status' })}
      </TableCell>,
      <TableCell width="23%" key="created_by">
        {intl.formatMessage({ id: 'documents_list.columns_names.created_by' })}
      </TableCell>,
      <TableCell key="actions" />,
    ];
  };

  renderEmptyComponent = () => {
    const { intl } = this.props;

    return [
      <EmptyBucket
        onClick={this.toggleDocumentDialog}
        heading={intl.formatMessage({ id: 'documents_list.empty_bucket.heading' })}
        paragraph={intl.formatMessage({ id: 'documents_list.empty_bucket.paragraph' })}
        buttonLabel={intl.formatMessage({ id: 'documents_list.empty_bucket.buttonLabel' })}
        key="documents-empty-bucket" />,
      <NewDocumentDialog
        onClose={this.toggleDocumentDialog}
        isOpen={this.state.newDocumentDialogOpen}
        key="documents-new-dialog" />,
    ];
  };

  renderTableRow = (doc) => {
    const {
      history,
      moveToTrash,
      moveToFolder,
      folders,
      currentFolder,
      currentViewFolder,
      goToFolderRequest,
      duplicateDocumentEnabled,
    } = this.props;
    const folderId = this.getFolderId();

    return (
      <DocumentTableRow
        document={doc}
        folderId={folderId}
        goToRoute={history.push}
        moveToTrash={moveToTrash}
        moveToFolder={moveToFolder}
        currentFolder={currentFolder}
        currentViewFolder={currentViewFolder}
        folders={folders}
        goToFolderRequest={goToFolderRequest}
        duplicateDocumentEnabled={duplicateDocumentEnabled}
        key={doc.id} />
    );
  };

  render() {
    const { fetchDocuments, searchDocuments, updateFolderNameRequest, deleteFolder, hasRole } = this.props;
    const folderId = this.getFolderId();

    return (
      <DataProvider
        fetchData={fetchDocuments}
        search={searchDocuments}
        folderId={folderId}
        itemKey="documents"
        statusKey={Types.FETCH_DOCUMENTS_REQUEST.replace(/\//g, '_').replace('_REQUEST', '')}>
        {({ data }) => (
          <Fragment>
            <article className={styles.folderListWrapper}>
              <FolderList
                folders={data.folders}
                disableLoadingFolder
                hasAction={!hasRole([CONSUMER_ROLE])}
                onFolderClick={this.handleFolderClick}
                updateFolderNameRequest={updateFolderNameRequest}
                deleteFolder={deleteFolder}
                testIdPrefix="DocumentsFolders" />
            </article>
            <RecordsTable
              data={data.documents}
              total={data.count}
              testId="Documents-table"
              columnHeaders={this.renderHeaders()}
              EmptyComponent={this.renderEmptyComponent()}
              renderRow={this.renderTableRow} />
          </Fragment>
        )}
      </DataProvider>
    );
  }
}

const mapStateToProps = state => ({
  currentFolder: state.moveDocument.currentFolder,
  folders: state.moveDocument.folders,
  currentViewFolder: state.documents.currentFolder,
  duplicateDocumentEnabled: selectDuplicateDocumentEnabled(state),
});

const mapDispatchToProps = {
  fetchDocuments: Creators.fetchDocumentsRequest,
  updateFolderNameRequest: Creators.updateFolderNameRequest,
  deleteFolder: Creators.deleteFolderRequest,
  moveToTrash: Creators.deleteDocumentRequest,
  moveToFolder: Creators.moveToFolderRequest,
  goToFolderRequest: Creators.goToFolderRequest,
  searchDocuments: Creators.searchDocumentsRequest,
};

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  withAcl,
)(DocumentsComponent);
