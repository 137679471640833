import ANSWER_STATUSES from 'shared/constants/answerStatuses';
import get from 'lodash/get';
import _compact from 'lodash/compact';
import _flatMap from 'lodash/fp/flatMap';
import _filter from 'lodash/fp/filter';
import _identity from 'lodash/fp/identity';
import _flow from 'lodash/flow';
import _last from 'lodash/last';
import { createSelector } from 'reselect';

import {
  findNextQuestion,
  copyFlow,
  bindQuestions,
  bindAnswers,
  findInFlow,
  filterUnanswerable,
  filterUnvisited,
  filterDuplicates,
  markDuplicates,
  findUnfinishedQuestion,
} from 'Questionnaire2/flow';
import {
  createFlatFlowToQuestionsSelector,
  createFlowToFlatFlowSelectorWithFilter,
} from 'Questionnaire2/flowSelectorsUtils';

export const isQuestionSkippedSelector = state => get(state, 'answer.status') === ANSWER_STATUSES.SKIPPED;

export const templatePropertiesSelector = state => get(state, ['questionnaire', 'templateVersion', 'qtv_property'], {});

export const templatePropertyEditDocumentEnabledSelector = state => get(templatePropertiesSelector(state), 'edit_document_enabled', false);

export const isFinishedWithSkippedSelector = (state) => {
  const hasSkippedAnswers = get(
    state,
    'questionnaire.userQuestionnaire.hasSkippedAnswers',
  );
  const currentQuestionId = get(state, 'questionnaire.currentQuestion.id');
  return hasSkippedAnswers && !currentQuestionId;
};

// Legacy?
export const currentAnswerSelector = (state) => {
  const answers = get(state, 'questionnaire.answers');
  const removedAnswers = get(state, 'questionnaire.removedAnswers');
  const currentQuestion = get(state, 'questionnaire.currentQuestion');
  const answer = answers.find(a => a.question_id === currentQuestion.id);
  const removedAnswer = removedAnswers.find(
    a => a.question_id === currentQuestion.id,
  );
  return answer || removedAnswer || {};
};

export const questionBindedFlow = (state) => {
  const flow = get(state, 'questionnaire.questionsFlow');
  const questions = get(state, 'questionnaire.questions');
  return bindQuestions(flow, questions);
};

export const entryFlow = (state) => {
  const flow = questionBindedFlow(state);
  const answers = get(state, 'questionnaire.answers');
  const copiedFlow = copyFlow(flow);
  return bindAnswers(copiedFlow, answers);
};

// The most important flow. Using this flow you won't be able to select impossible to answer questions.
export const answerableFlow = (state) => {
  const flow = entryFlow(state);
  const answers = get(state, 'questionnaire.answers', []);

  const answeredOptionIds = _flow(
    _flatMap(({ input_id }) => input_id && input_id.split(';')), // eslint-disable-line camelcase
    _filter(_identity),
  )(answers);

  return markDuplicates(filterUnanswerable(flow, answeredOptionIds));
};

export const answerableFlatFlowSelector = createFlowToFlatFlowSelectorWithFilter(
  answerableFlow,
  filterDuplicates,
);
export const answerableQuestionsSelector = createFlatFlowToQuestionsSelector(
  answerableFlatFlowSelector,
);

export const visitedFlow = (state) => {
  const flow = answerableFlow(state);
  const currentQuestion = get(state, 'questionnaire.currentQuestion');
  return filterUnvisited(flow, currentQuestion);
};

export const visitedFlatFlowSelector = createFlowToFlatFlowSelectorWithFilter(
  visitedFlow,
  filterDuplicates,
);
export const visitedQuestionsSelector = createFlatFlowToQuestionsSelector(
  visitedFlatFlowSelector,
);
export const visitedAnswersSelector = (state) => {
  const visitedQuestions = visitedQuestionsSelector(state);
  return _compact(visitedQuestions.map(question => question.answer));
};

export const nextQuestionSelector = (state) => {
  const currentQuestion = get(state, 'questionnaire.currentQuestion');
  const flow = answerableFlow(state);
  return findNextQuestion(currentQuestion, flow);
};

export const unfinishedQuestionSelector = (state) => {
  const flow = answerableFlow(state);
  return findUnfinishedQuestion(flow);
};

export const skippedQuestionSelector = (state) => {
  const flow = answerableFlow(state);
  return findInFlow(
    flow,
    question => question.answer && question.answer.status === ANSWER_STATUSES.SKIPPED,
  );
};

export const lastQuestionSelector = (state) => {
  const questions = answerableQuestionsSelector(state);
  if (questions.length > 0) {
    return questions[questions.length - 1];
  }
  return undefined;
};

export const questionnaireSelector = state => get(state, 'questionnaire');

export const questionnaireStatusSelector = state => get(state, 'questionnaire.userQuestionnaire.status');

export const questionsEditStartedSelector = createSelector(
  questionnaireSelector,
  questionnaireState => questionnaireState.questionsEditStarted === true,
);

export const selectIsQuestionnairePreview = createSelector(
  questionnaireSelector,
  questionnaireState => questionnaireState.userQuestionnaire.preview,
);

export const selectLastAnswer = createSelector(
  questionnaireSelector,
  questionnaireState => _last(questionnaireState.answers),
);

export const selectQuestionnaireFeedbackModule = state => get(state, 'questionnaire.company.questionnaire_feedback_module', false);
export default null;
