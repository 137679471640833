import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'shared/Components/Form/Select';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { rem } from 'polished/lib/index';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0;
`;

export const FieldWrapper = styled.div`
  flex: 1 1 0%;

  & + & {
    margin-left: 15px;
  }
`;

export const FooterWrapper = styled.div`
  > button + button {
    margin-left: 20px;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-right: 40px;
  }
`;

export const RadioLabel = styled.span`
  color: #6e6e6e;
`;

export const StyledSelect = styled(Select)`
  && {
    border: 1px solid ${p => p.theme.border.normal} !important;
  }
`;

// Fragment is a first element of a tooltip, to pass styled component classes i had add them to popper
export const StyledTooltip = styled(props => (
  <Tooltip
    classes={{ popper: props.className, tooltip: 'tooltip' }}
    {...props} />
))`
    & .tooltip {
      background-color: white;
      margin-bottom: 25px;
      box-shadow: 5px 10px 30px 5px rgba(33,34,35,0.2);
      padding: 0px;
    }
    && {
      opacity: 1;
    }
`;

export const TooltipContent = styled.div`
  display: flex;
  font-size: ${rem('14px')};
  padding: 15px;
  max-width: 500px;
`;

export const SigningTypeTitle = styled.p`
  font-size: ${rem('15px')};
  color: #35404a;
  font-weight: 700;
`;

export const SigningTypeDescription = styled.div`
  font-size: ${rem('13px')};
  color: #787878;
  margin-top: 7px;
`;

export const SigningTypeWrapper = styled.div`
  margin: 10px;
  max-width: 200px;
`;

export const SenderHasToSignWrapper = styled.div`
  margin-top: 30px;
`;

export const RowWrapper = styled.div`
  position: relative;
`;

export const Error = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
  top: calc(100% - 14px);
  left: 0;
  right: 0;
  font-weight: normal;
  font-size: ${rem('13px')};
  height: 27px;
  font-style: normal;
  padding: 4px 10px 6px 0;
  text-overflow: ellipsis;
  color: ${p => p.theme.errors.color};
`;
