export const pageMarginsSelector = (state) => {
  // eslint-disable-next-line camelcase
  const { margin_top, margin_right, margin_bottom, margin_left } = state.documentStyles.data;
  return { margin_top, margin_right, margin_bottom, margin_left };
};

export const headerAndFooterHeightSelector = (state) => {
  // eslint-disable-next-line camelcase
  const { header_height, footer_height } = state.documentStyles.data;
  return { header_height, footer_height };
};
export const headerAndFooterHtmlSelector = (state) => {
  // eslint-disable-next-line camelcase
  const { header_html, footer_html } = state.documentStyles.data;
  return { header: header_html, footer: footer_html };
};
