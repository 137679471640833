import React from 'react';
import ReactDom from 'react-dom';
import { ThemeProvider } from 'styled-components';
import QuestionContainer from 'Visualization/Editor-v2/Questions/Question/QuestionContainer';
import theme from 'Visualization/theme';
import { store } from 'MainApp';
import { TEMPLATE_EDITOR_ID } from 'appRoot/constants';

export default (props, questionType, tagType, surroundContents) => {
  const dataProps = {
    'data-logictype': props.logicType,
    'data-question-id': props.questionId,
    'data-question': questionType,
    [`data-${tagType}`]: props[tagType],
  };
  const templateEditor = tinymce.get(TEMPLATE_EDITOR_ID); // eslint-disable-line

  ReactDom.render(
    <ThemeProvider theme={theme}>
      <QuestionContainer {...props} store={store} {...dataProps} />
    </ThemeProvider>,
    surroundContents,
    () => templateEditor && templateEditor.fire('change'),
  );
};
