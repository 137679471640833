import { takeLatest, put } from 'redux-saga/effects';
import { Creators, Types } from './redux';
import calculateQuestionsLeft from './helpers/calculateQuestionsLeft';

function* setProgressBar({ html }) {
  const questionsLeftLength = calculateQuestionsLeft(html);
  const maxQuestionsLength = calculateQuestionsLeft(html, false);
  yield put(Creators.setRemainingQuestionsLength(questionsLeftLength));
  yield put(Creators.setMaxQuestionsLength(maxQuestionsLength));
}

export default [takeLatest(Types.SET_PROGRESS_BAR, setProgressBar)];
