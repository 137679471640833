import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';
import { Types } from './actions';

export const initialState = {
  refreshInProgress: false,
  newLinkSent: false,
};

const resendLinkRequest = state => update(state, { refreshInProgress: { $set: true } });
const resendLinkSuccess = state => update(state, {
  refreshInProgress: { $set: false },
  newLinkSent: { $set: true },
});
const resendLinkFailure = state => update(state, { refreshInProgress: { $set: false } });

export default createReducer(initialState, {
  [Types.RESEND_LINK_REQUEST]: resendLinkRequest,
  [Types.RESEND_LINK_SUCCESS]: resendLinkSuccess,
  [Types.RESEND_LINK_FAILURE]: resendLinkFailure,
});
