/**
 *
 * Helpers for React components
 *
 */
import assert from 'assert';

/** @function createClassNameModifiers
 *
 * Map array of strings from modifier name to the correct component
 * modifier class name.
 * ["full", "dark"] would be transformed into classes
 * `.component--full` and `.component--dark`
 *
 * @param {array} modifiers - Modifier class names.
 * @param {object} styles - styles object.
 * @param {string} [rootClassName] - Component root class name.
 * @return {array}
 */
export function createClassNameModifiers({
  modifiers,
  styles,
  rootClassName = 'component',
}) {
  assert.ok(Array.isArray(modifiers), 'modifiers<Array> are required');
  assert.ok(styles, 'styles are required');

  return modifiers.map(item => styles[`${rootClassName}--${item}`]);
}
