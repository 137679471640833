import React from 'react';
import PropTypes from 'prop-types';
import proptypes from 'utils/proptypes';
import cn from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createClassNameModifiers } from 'utils/componentUtils';
import Radio from '@material-ui/core/Radio';
import RadioButtonUncheckedOutlined from '@material-ui/icons/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlined from '@material-ui/icons/RadioButtonCheckedOutlined';
import styles from './index.css';

export const ERROR = 'error';

export default class InputRadio extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    testId: PropTypes.string.isRequired,
    modifiers: proptypes.arrayOfModifiers([ERROR]),
    onChange: PropTypes.func,
    label: PropTypes.node,
    value: PropTypes.string,
  };

  static defaultProps = { modifiers: [] };

  render() {
    const {
      className,
      modifiers,
      testId,
      label,
      value,
      ...otherProps
    } = this.props;

    const formattedModifiersForLabel = createClassNameModifiers({ modifiers, styles, rootClassName: 'label' });
    const formattedModifiersForRadio = createClassNameModifiers({ modifiers, styles });
    const classStringForRadio = cn(styles.component, ...formattedModifiersForRadio, className);
    const classStringForLabel = cn(styles.label, ...formattedModifiersForLabel);

    return (
      <FormControlLabel
        value={value}
        classes={{ root: styles.formControl, label: classStringForLabel }}
        label={label}
        control={
          (
            <Radio
              {...otherProps}
              className={classStringForRadio}
              classes={{
                disabled: styles.radioDisabled,
                root: classStringForRadio,
                checked: styles.checked,
              }}
              onChange={this.props.onChange ? this.props.onChange : undefined}
              icon={<RadioButtonUncheckedOutlined classes={{ root: styles.radioIcon }} />}
              checkedIcon={<RadioButtonCheckedOutlined classes={{ root: styles.radioIcon }} />}
              color="primary"
              disableRipple
              data-test-id={testId} />
          )
        } />
    );
  }
}
