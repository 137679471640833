import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import Button from 'components/Button';
import FieldRow from 'components/form/FieldRow';
import InputText from 'components/form/InputText';
import Label from 'components/form/Label';
import { isEmail } from 'shared/utils/validators';
import { ButtonGroup } from './styled-components';

const requiredValues = ['firstName', 'lastName', 'email'];

class PersonalInformationFormComponent extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isUpdatingUser: PropTypes.bool,
    intl: intlShape.isRequired,
    initialValues: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
  }

  validateFormik = (values) => {
    const errors = {};
    const { intl } = this.props;

    if (!isEmail(values.email)) {
      errors.email = intl.formatMessage({ id: 'user_profile.personal_information_form.invalid_email' });
    }

    requiredValues.forEach((name) => {
      if (!values[name]) errors[name] = intl.formatMessage({ id: 'user_profile.personal_information_form.cant_be_blank' });
    });

    return errors;
  }

  onSubmitFormik = (values) => {
    if (!this.props.isUpdatingUser) {
      this.props.onSubmit(values);
    }
  }

  render() {
    const { initialValues, isUpdatingUser } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={this.onSubmitFormik}
        validate={this.validateFormik}>
        {props => (
          <React.Fragment>
            <FieldRow>
              <Label htmlFor="firstName">
                <FormattedMessage id="user_profile.personal_information_form.first_name" />
              </Label>
              <Field name="firstName">
                {({ form, field }) => (
                  <InputText
                    {...field}
                    errorMessage={form.errors[field.name]}
                    required
                    testId="PersonalInformationForm-firstNameField" />
                )}
              </Field>
            </FieldRow>
            <FieldRow>
              <Label htmlFor="lastName">
                <FormattedMessage id="user_profile.personal_information_form.last_name" />
              </Label>
              <Field name="lastName">
                {({ form, field }) => (
                  <InputText
                    {...field}
                    errorMessage={form.errors[field.name]}
                    required
                    testId="PersonalInformationForm-lastNameField" />
                )}
              </Field>
            </FieldRow>
            <FieldRow>
              <Label htmlFor="email">
                <FormattedMessage id="user_profile.personal_information_form.email" />
              </Label>
              <Field name="email">
                {({ form, field }) => (
                  <InputText
                    {...field}
                    required
                    errorMessage={form.errors[field.name]}
                    testId="PersonalInformationForm-emailField" />
                )}
              </Field>
            </FieldRow>
            <ButtonGroup>
              <Button
                onClick={props.submitForm}
                testId="PersonalInformationForm-submit"
                loading={isUpdatingUser}>
                <FormattedMessage id="user_profile.personal_information_form.save_changes" />
              </Button>
            </ButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    );
  }
}

export { PersonalInformationFormComponent };

export default injectIntl(PersonalInformationFormComponent);
