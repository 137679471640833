exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-bundles-Questionnaire2-TopToolbar-modules-Editing-___styles__menuItemDisabled___v0Mre {\n  opacity: 0.5;\n}\n\nli.app-bundles-Questionnaire2-TopToolbar-modules-Editing-___styles__menuItemDisabled___v0Mre:hover {\n  background-color: transparent;\n  cursor: auto;\n}\n\nli.app-bundles-Questionnaire2-TopToolbar-modules-Editing-___styles__menuItemDisabled___v0Mre:hover span {\n  color: #565656;\n  color: var(--c-bw-60);\n}\n", "", {"version":3,"sources":["/workspace/app/bundles/Questionnaire2/TopToolbar/modules/Editing/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAY;CACd;;AAEA;EACE,8BAA6B;EAC7B,aAAY;CACd;;AAEA;EACE,eAAqB;EAArB,sBAAqB;CACvB","file":"styles.css","sourcesContent":[".menuItemDisabled {\n  opacity: 0.5;\n}\n\nli.menuItemDisabled:hover {\n  background-color: transparent;\n  cursor: auto;\n}\n\nli.menuItemDisabled:hover span {\n  color: var(--c-bw-60);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"menuItemDisabled": "app-bundles-Questionnaire2-TopToolbar-modules-Editing-___styles__menuItemDisabled___v0Mre"
};