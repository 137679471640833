import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './StyledField.css';

export default class StyledField extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
    children: PropTypes.node,
  };

  render() {
    const { name, label, children } = this.props;
    return (
      <div wide className={cn(styles.component, 'select-container', 'mb-10')}>
        <label htmlFor={name}>
          {' '}
          {label}
          {' '}
        </label>
        {children}
      </div>
    );
  }
}
