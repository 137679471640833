import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import cn from 'classnames';
import * as v from 'shared/validation';

import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';

import Button, { SECONDARY } from 'components/Button';
import InputText from 'components/form/InputText';
import Typography from 'components/Typography';
import SelectedTags, { EMAIL } from './SelectedTags';

import styles from './index.css';

export class TagsInputBase extends PureComponent {
  static propTypes = {
    validators: PropTypes.arrayOf(PropTypes.func),
    intl: intlShape.isRequired,
    label: PropTypes.node,
    errorMessage: PropTypes.string,
    field: PropTypes.shape({ name: PropTypes.string }),
    form: PropTypes.shape({
      touched: PropTypes.instanceOf(Object),
      errors: PropTypes.instanceOf(Object),
      setFieldTouched: PropTypes.func,
      setFieldValue: PropTypes.func,
    }),
    helpText: PropTypes.string,
    placeholder: PropTypes.string,
    reversedOrder: PropTypes.bool,
    addManually: PropTypes.bool,
    buttonTestId: PropTypes.string,
    buttonTextId: PropTypes.string,
    onTagAdd: PropTypes.func,
    autofocus: PropTypes.bool,
    value: PropTypes.shape({
      inputValue: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
    renderCustomTags: PropTypes.func,
    testId: PropTypes.string.isRequired,
  };

  static defaultProps = { validators: [], errorMessage: '', onTagAdd: () => {} };

  state = { error: null };

  inputRef = React.createRef();

  get error() {
    const { field, form, intl, errorMessage } = this.props;
    const { error } = this.state;
    const tagError = error ? intl.formatMessage({ id: error }) : null;

    return errorMessage || (form.touched.emails ? tagError || form.errors[field.name] : null);
  }

  componentDidMount() {
    const { autofocus } = this.props;

    if (autofocus) {
      this.inputRef.focus();
    }
  }

  addTag = (tag, handleChange) => {
    const { form, field, value, reversedOrder, onTagAdd } = this.props;
    form.setFieldTouched(field.name, true);
    if (tag) {
      if (!this.checkValidators(tag)) {
        return;
      }
      if (handleChange) {
        const newTags = reversedOrder ? [tag, ...value.tags] : [...value.tags, tag];
        handleChange(newTags, '');
        onTagAdd();
      }
    }
  };

  removeTag = (event) => {
    const { tags, inputValue } = this.props.value;
    const { index } = event.currentTarget.dataset;
    const newTags = [...tags];

    newTags.splice(index, 1);

    this.handleChange(newTags, inputValue);
  };

   moveTag = (index, toIndex) => {
     const { value: { tags, inputValue } } = this.props;

     const newTags = [...tags];
     const tag = tags[index];

     newTags.splice(index, 1);
     newTags.splice(toIndex, 0, tag);
     this.handleChange(newTags, inputValue);
   };

  handleChange = (tags, inputValue) => {
    const { form, field } = this.props;
    const isList = inputValue.split(',').length > 1;
    if (isList && !v.emailsList(inputValue)) {
      const emails = inputValue.split(',');
      emails.forEach(e => this.addTag(e.trim()));
    }
    form.setFieldValue(field.name, { tags, inputValue });
  };

  checkValidators = (tag) => {
    const { validators, value: { tags } } = this.props;

    let error = null;
    // eslint-disable-next-line no-return-assign
    if (validators.length && validators.some(validator => (error = validator(tag, tags)))) {
      this.setState({ error });
      return false;
    }

    this.setState({ error: null });
    return true;
  };

  onKeyDown = (event) => {
    if (event.keyCode === keycode('enter')) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  onKeyUp = (event) => {
    const text = event.target.value;

    if (event.keyCode === keycode('enter')) {
      this.addTag(text, this.handleChange);
    }
  };

  onManualAdd = () => {
    this.addTag(this.inputRef.value, this.handleChange);
  };

  onBlur = (event) => {
    const text = event.target.value;
    const isValid = this.checkValidators(text);
    if (isValid) {
      this.addTag(text, this.handleChange);
    }
  };

  onInputChange = (event) => {
    const { addManually, value: { tags } } = this.props;
    const { error } = this.state;
    const text = event.target.value;
    const lastLetter = text[text.length - 1];

    if ((lastLetter === ',' || lastLetter === ' ') && !addManually) {
      this.addTag(text.slice(0, -1), this.handleChange);
    } else {
      this.handleChange(tags, text);
      if (error && text) {
        this.checkValidators(text);
      } else {
        this.setState({ error: null });
      }
    }
  };

  setInputRef = (ref) => {
    this.inputRef = ref;
  };

  render() {
    const {
      helpText,
      label,
      placeholder,
      renderCustomTags,
      value: { tags, inputValue },
      testId,
      addManually,
      buttonTestId,
      buttonTextId,
    } = this.props;

    return (
      <Fragment>
        {label && <Typography variant="label">{label}</Typography>}
        <div className={styles.flexWrapper}>
          <div className={cn(styles.wrapper, { [styles.selectedTags]: !renderCustomTags })}>
            { !renderCustomTags && <SelectedTags tags={tags} removeTag={this.removeTag} icon={EMAIL} />}
            <InputText
              type="text"
              className={cn({ [styles.borderlessInput]: !renderCustomTags })}
              inputRef={this.setInputRef}
              onChange={this.onInputChange}
              onKeyUp={this.onKeyUp}
              onKeyDown={this.onKeyDown}
              value={inputValue}
              errors={this.error}
              placeholder={placeholder}
              testId={testId}
              onBlur={!addManually && this.onBlur}
              errorMessage={this.error}
              endAdornment={!!tags.length && !this.error && !renderCustomTags && <DoneIcon fontSize="small" glyph="done" classes={{ root: styles.doneIcon }} />} />
          </div>
          {addManually && (
            <Button
              onClick={this.onManualAdd}
              variant={SECONDARY}
              testId={buttonTestId}
              glyph={AddIcon}
              className={styles.addButton}>
              <FormattedMessage id={buttonTextId} />
            </Button>
          )}
        </div>
        {helpText ? (<Typography variant="helpText">{helpText}</Typography>) : null}
        <div className={styles.customTagsWrapper}>
          {renderCustomTags && renderCustomTags(this.removeTag, this.moveTag)}
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(TagsInputBase);
