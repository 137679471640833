import { getEnv } from 'utils/env';

const API_BASE_URL = getEnv('BACKEND_URL');
const ASSET_STORE_URL = getEnv('ASSET_STORE_URL');
const DOCUMENT_API_URL = getEnv('DOCUMENT_API_URL');
const DOCUMENT_EXPORT_SERVICE_HOST = getEnv('DOCUMENT_EXPORT_SERVICE_HOST');
const SIGNING_API_URL = getEnv('SIGNING_API_URL');
const DASHBOARD_API = getEnv('DASHBOARD_API');

const FRESHCHAT_TOKEN = getEnv('FRESHCHAT_TOKEN');

const DEFAULT_APP_PATH = '/documents';
const LEXOLVE_LANDING_PAGE_PATH = 'https://lexolve.com/';
const LOGIN_PATH = '/auth/sign-in';
const NOT_FOUND_PATH = '/error/not-found';

const routeTypes = {
  WHITE_LABEL: 'whiteLabel',
  PUBLIC: 'public',
  PRIVATE: 'private',
  GENERIC_ACCESS: 'genericAccess',
};

const appTypes = {
  DEFAULT: 'default',
  WHITE_LABEL: 'whiteLabel',
  GENERIC_ACCESS: 'genericAccess',
};

const logicTypes = {
  CHOICE_SHOW_IF: 'choiceShowIfType',
  INPUT_REPLACE_TYPE: 'inputReplaceType',
  ANCHOR_TYPE: 'anchorType',
};

const LOCAL_STORAGE_STORE_NAME = 'LawboticsStore';

const TEMPLATE_EDITOR_ID = 'template_editor';

export {
  API_BASE_URL,
  ASSET_STORE_URL,
  DOCUMENT_API_URL,
  DOCUMENT_EXPORT_SERVICE_HOST,
  FRESHCHAT_TOKEN,
  LEXOLVE_LANDING_PAGE_PATH,
  LOCAL_STORAGE_STORE_NAME,
  DEFAULT_APP_PATH,
  DASHBOARD_API,
  LOGIN_PATH,
  NOT_FOUND_PATH,
  TEMPLATE_EDITOR_ID,
  SIGNING_API_URL,
  routeTypes,
  appTypes,
  logicTypes,
};
