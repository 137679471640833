import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import SelectTemplateForDocumentContainer from '../containers/SelectTemplateForDocumentContainer';
import styles from './styles.css';

class NewDocumentDialog extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Dialog
        open={isOpen}
        maxWidth="md"
        fullWidth
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
        onClose={onClose}>
        <DialogTitle disableTypography>
          <Typography align="center" variant="h6">
            <FormattedMessage id="panel.user_questionnaires.new.new_document" />
          </Typography>
        </DialogTitle>
        <DialogContent classes={{ root: styles.dialogContent }}>
          <SelectTemplateForDocumentContainer />
        </DialogContent>
      </Dialog>
    );
  }
}

export default NewDocumentDialog;
