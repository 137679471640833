import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FolderList from 'components/FolderList';
import { constructFolderQueryParams } from 'utils/url';
import Pagination from 'components/Pagination';
import Views from 'shared/Components/Views';
import Tabs, { Tab } from 'shared/Components/Tabs';
import TemplatesLibraryTable from '../containers/TemplatesLibraryTableContainer';
import styles from './styles.css';

class selectTemplateForDocumentRequest extends PureComponent {
  static propTypes = {
    selectTemplateForDocumentRequest: PropTypes.func.isRequired,
    fetchTemplatesInfoRequest: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
    folders: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      questionnaireTemplatesCount: PropTypes.number.isRequired,
    })).isRequired,
  };

  state = {
    currentTabIndex: 0,
    currentPage: 0,
  };

  componentDidMount() {
    this.props.fetchTemplatesInfoRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.props.fetchTemplatesInfoRequest({ page: this.state.currentPage });
    }
  }

  onFolderClick = (folderId) => {
    this.props.fetchTemplatesInfoRequest(constructFolderQueryParams(folderId));
  };

  getFolderSubheader = folder => (
    <FormattedMessage
      id="panel.templates_library.folders.subfolders.templates_count_non_ruby"
      values={{ val: folder.questionnaireTemplatesCount }} />
  );

  handleChange = (event, value) => {
    if (value === 0) {
      // All templates
      this.props.fetchTemplatesInfoRequest();
    }
    this.setState({ currentTabIndex: value });
  };

  onPageChange = ({ selected }) => {
    this.setState({ currentPage: selected + 1 });
  }

  render() {
    const { folders, total } = this.props;
    const { currentTabIndex, currentPage } = this.state;

    return (
      <section>
        <Tabs classes={{ root: styles.tabsRoot }} currentTabIndex={currentTabIndex} onChange={this.handleChange}>
          <Tab
            label={<FormattedMessage id="api.panel.questionnaire_template_versions.user_questionnaires.create.allTemplates" />}
            data-test-id="NewDocumentDialog-allTemplates" />
        </Tabs>
        <div className={styles.viewWrapper}>
          <Views index={currentTabIndex}>
            <section>
              <article className={styles.folderListWrapper}>
                <FolderList
                  folders={folders}
                  onFolderClick={this.onFolderClick}
                  disableLoadingFolder
                  getFolderSubheader={this.getFolderSubheader}
                  hasAction={false} />
              </article>
              <TemplatesLibraryTable onSelectTemplate={this.props.selectTemplateForDocumentRequest} />
              <Pagination pageCount={total} initialPage={currentPage} onPageChange={this.onPageChange} />
            </section>
          </Views>
        </div>
      </section>
    );
  }
}

export default selectTemplateForDocumentRequest;
