import { DEFAULT_APP_PATH } from 'appRoot/constants';
import Logo from 'components/Logo';
import { BackgroundShadowsPage } from 'components/Page';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import mapStateToProps from './selectors';
import styles from './styles.css';


export class PublicRouteComponent extends PureComponent {
  static propTypes = {
    hasFetchedUser: PropTypes.bool.isRequired,
    isUserAuthorized: PropTypes.bool.isRequired,
    withLogoHeader: PropTypes.bool,
  };

  static defaultProps = { withLogoHeader: true };

  componentDidMount() {
    const { title } = this.props;
    document.title = title;
  }

  _renderRoute = (props) => {
    const { component: Component, shouldRedirectAuthorizedUser, isUserAuthorized } = this.props;
    if (shouldRedirectAuthorizedUser && isUserAuthorized) {
      const pathname = _get(props.location, 'state.from.pathname', DEFAULT_APP_PATH);
      return (
        <Redirect
          to={{
            pathname,
            state: { from: props.location },
          }} />
      );
    }

    return (
      <BackgroundShadowsPage>
        {this.props.withLogoHeader && (
          <div className={styles.logoWrapper}>
            <Logo />
          </div>
        )}
        <Component {...props} />
      </BackgroundShadowsPage>
    );
  };

  render() {
    const routeProps = _omit(this.props, [
      'component',
      'isUserAuthorized',
      'hasFetchedUser',
      'shouldRedirectAuthorizedUser',
      'isChatEnabled',
    ]);

    return <Route {...routeProps} render={this._renderRoute} />;
  }
}

const connector = connect(mapStateToProps);
export default withRouter(connector(PublicRouteComponent));
