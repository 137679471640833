import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './BaseNotification.css';

const BaseNotification = ({ className, variant, message, values, icon: Icon }) => {
  const classes = cn(styles.component, className, {
    [styles.error]: variant === 'error',
    [styles.success]: variant === 'success',
    [styles.info]: variant === 'info',
  });

  return (
    <div className={classes}>
      <Icon className={styles.icon} />
      <FormattedMessage id={message} values={values} />
    </div>
  );
};

BaseNotification.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
  values: PropTypes.instanceOf(Object),
  icon: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['error', 'success', 'info']),
};

export default BaseNotification;
