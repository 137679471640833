import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import proptypes from 'utils/proptypes';
import { Creators } from 'ducks/questionnaire/actions';
import { visitedAnswersSelector } from 'ducks/questionnaire/selectors';

class GoToPreviousQuestionLogic extends Component {
  static propTypes = {
    findPreviousQuestion: PropTypes.func.isRequired,
    answers: proptypes.answersScheme,
    questionnaireFinished: PropTypes.bool.isRequired,
    render: PropTypes.func.isRequired,
  };

  state = {
    currentAnswer: {},
    currentAnswerIndex: 0,
  }

  static getDerivedStateFromProps(props) {
    const { answers, currentQuestion } = props;
    const currentAnswerIndex = answers.findIndex(a => a.question_id === currentQuestion.id);
    const currentAnswer = answers[currentAnswerIndex] || {};

    return {
      currentAnswer,
      currentAnswerIndex,
    };
  }

  shouldComponentUpdate = (nextProps, nextState) => this.state.currentAnswerIndex !== nextState.currentAnswerIndex

  findPreviousQuestion = () => {
    const { findPreviousQuestion, answers, questionnaireFinished } = this.props;
    let answerIndex = this.state.currentAnswerIndex - 1;

    if (questionnaireFinished || !this.state.currentAnswer.id) {
      // if questionnaire finished or current answer is not yet been persisted
      answerIndex = answers.length - 1;
    }

    findPreviousQuestion(answerIndex);
  };

  render() {
    const { answers } = this.props;
    if (this.state.currentAnswerIndex === 0 || answers.length === 0) return null;

    return this.props.render(this.findPreviousQuestion);
  }
}

function mapStateToProps(state) {
  return {
    answers: visitedAnswersSelector(state),
    currentQuestion: state.questionnaire.currentQuestion,
    questionnaireFinished: state.questionnaire.finished,
  };
}

function mapDispatchToProps(dispatch) {
  return { findPreviousQuestion: answerIndex => dispatch(Creators.findPreviousQuestion(answerIndex)) };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoToPreviousQuestionLogic);
