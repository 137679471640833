import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { getTemplateDetailPath } from 'MainApp/routesPaths';
import StyledTopToolbar from 'shared/TopToolbar/StyledTopToolbar';
import SaveNotification from 'shared/TopToolbar/SaveNotification';
import { SAVING } from 'shared/constants/saveStatus';
import TemplateSettingsModal from '../TemplateSettingsModal';
import SharedLinkModal from '../SharedLinkModal/index';
import TemplateName from './TemplateName';
import Published from './Buttons/Published';
import Unpublished from './Buttons/Unpublished';
import Draft from './Buttons/Draft';
import Archived from './Buttons/Archived';
import { Creators as EditorActions } from '../reducer';
import styles from './index.css';

const components = {
  published: Published,
  unpublished: Unpublished,
  draft: Draft,
  archived: Archived,
};

class TopToolbar extends Component {
  static propTypes = {
    savingStatus: PropTypes.string,
    loaded: PropTypes.bool.isRequired,
    previewMode: PropTypes.bool,
    templateVersionStatus: PropTypes.string,
    templateHasDrafts: PropTypes.bool.isRequired,
    templateVersionId: PropTypes.string.isRequired,
    templateVersionChangeNote: PropTypes.string,
    templateId: PropTypes.string.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    templateSharingSlug: PropTypes.string.isRequired,
    templateSharingActive: PropTypes.bool.isRequired,
    toggleSharingTemplateRequest: PropTypes.func.isRequired,
  };

  static defaultProps = { previewMode: false };

  state = {
    isSettingsModalOpen: false,
    isSharedLinkModalOpen: false,
  };

  handleToggleSettingsModal = () => {
    this.setState(({ isSettingsModalOpen }) => ({ isSettingsModalOpen: !isSettingsModalOpen }));
  };

  handleToggleSharedLinkModal = () => {
    this.setState(({ isSharedLinkModalOpen }) => ({ isSharedLinkModalOpen: !isSharedLinkModalOpen }));
  };

  handleTemplateDetailsClick = () => this.props.history.push(getTemplateDetailPath(this.props.templateId));

  render() {
    const {
      previewMode,
      templateId,
      templateVersionChangeNote,
      savingStatus,
      loaded,
      templateVersionStatus,
      history,
      templateHasDrafts,
      templateVersionId,
      templateSharingSlug,
      templateSharingActive,
      toggleSharingTemplateRequest,
    } = this.props;

    if (!loaded) {
      return <StyledTopToolbar className={styles.root} />;
    }

    const Buttons = components[templateVersionStatus];

    return (
      <StyledTopToolbar className={styles.root}>
        <TemplateName previewMode={previewMode} />
        <SaveNotification savingStatus={savingStatus} />
        <Buttons
          push={history.push}
          templateHasDrafts={templateHasDrafts}
          templateVersionId={templateVersionId}
          templateId={templateId}
          templateVersionChangeNote={templateVersionChangeNote}
          previewMode={previewMode}
          editorIsSaving={savingStatus === SAVING}
          onClickShare={this.handleToggleSharedLinkModal}
          onClickSettings={this.handleToggleSettingsModal}
          onClickTemplateDetails={this.handleTemplateDetailsClick} />
        <TemplateSettingsModal
          open={this.state.isSettingsModalOpen}
          toggleModal={this.handleToggleSettingsModal}
          push={history.push} />
        <SharedLinkModal
          open={this.state.isSharedLinkModalOpen}
          toggleModal={this.handleToggleSharedLinkModal}
          sharingActive={templateSharingActive}
          sharingSlug={templateSharingSlug}
          toggleSharingTemplate={toggleSharingTemplateRequest} />
      </StyledTopToolbar>
    );
  }
}

const mapStateToProps = state => ({
  templateHasDrafts: state.editor.template.has_drafts,
  templateVersionId: state.editor.templateVersion.id,
  templateId: state.editor.template.id,
  templateSharingSlug: state.editor.template.sharing_slug,
  templateSharingActive: state.editor.template.sharing_active,
  templateVersionChangeNote: state.editor.templateVersion.change_note,
  savingStatus: state.editor.savingStatus,
  templateVersionStatus: state.editor.templateVersion.status,
  loaded: !!state.editor.templateVersion.id,
});

const mapDispatchToProps = { toggleSharingTemplateRequest: EditorActions.toggleSharingTemplateRequest };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TopToolbar);
