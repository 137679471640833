import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import EsignTimeline from 'components/EsignTimeline';
import { ModalContent } from 'components/Modal';
import styles from './styles.css';

const EsignFormSummary = ({ emails, signingOrder }) => (
  <div className={styles.container}>
    <ModalContent>
      <div className={styles.summaryDescriptionContainer}>
        <p className={styles.summaryDescription}>
          <FormattedMessage id="questionnaire.esign.form.summary_description" />
        </p>
      </div>
      <EsignTimeline emails={emails} signingOrder={signingOrder} />
    </ModalContent>
  </div>
);

EsignFormSummary.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  signingOrder: PropTypes.string.isRequired,
};

export default EsignFormSummary;
