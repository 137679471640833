const isKeyEligible = key => /_height|_width|_top|_bottom|_right|_left/.test(key);

/**
 * Takes an object containing appends `mm` to values of keys containing `height`, `width`, `top`, `bottom`, `left` or `right`
 * @param sourceObject source object to convert
 * @return copy of inital source object but values appened in `mm` for matching keys
 */
export const appendMmToFilteredValues = sourceObject => Object.assign(
  ...Object.entries(sourceObject).map(([key, value]) => {
    const newValue = isKeyEligible(key) ? `${value}mm` : value;
    return { [key]: newValue };
  }),
);

/**
 * Converts any page number elements from TinyMCE into a Puppeteer compatible HTML string
 * @param content source HTML content which may or may not page numbers
 * @return HTML string with Puppeteer pageNumber span replacing any TinyMCE equivelent.
 */

export const replacePaginationForPuppeteer = (content) => {
  const paginationPattern = /<button class="tc__pagination">\[page\]<\/button>/;

  return content.replace('<span class="pageNumber"></span>', '').replace(paginationPattern, `<span class="pageNumber"></span> / <span class="totalPages"></span>`);
};

/**
 * Converts mm to pixels, assuming a 96 dpi resolution
 * @param input source value in millimeters (int or string, even if input contains other characters like `mm`)
 * @return value in pixels (int)
 */

export const mmToPx = input => Number(/\d+/.exec(input)) * 3.7795275591;

/**
 * Compares two margins and returns the highest
 * @param optOne first option (int or string)
 * @param optTwo second option (int or string)
 * @return highest number of the two values, as a string using the unit from optOne
 */

export const getHighestMargin = (optOne, optTwo) => {
  const marginSuffix = optOne.toString().split(/\d/).filter(Boolean);
  return `${Math.max(optOne.toString().split(/\D/)[0], optTwo.toString().split(/\D/)[0])}${marginSuffix}`;
};

/**
 * Converts all Pt font sizes to Px with the given ratio.
 * Puppeteer has bigger ratio for header and footer template it's -
 * a known issue ref: https://github.com/puppeteer/puppeteer/issues/2104 and -
 * we come up with lowering down with this fix.
 * @param styles string
 * @param ratio number
 */
export const convertPtFontSizesToPxValue = (styles, ratio = 1) => {
  let reConstructedStyles = styles;
  const matches = styles.match(/font-size:(.*)pt/g);
  for (let i = 0; i < matches.length; i += 1) {
    reConstructedStyles = reConstructedStyles.replace(matches[i], `font-size: ${Number(matches[i].trim().match(/(\d+)(\.\d+)?/g)[0]) * ratio}px`);
  }
  return reConstructedStyles;
};
