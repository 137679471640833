import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import WarningIcon from '@material-ui/icons/Warning';
import { ModalFooter, ModalContent } from 'components/Modal';
import Button, { SECONDARY, DANGER } from 'components/Button';
import styles from './AbortSigningModal.css';

const AbortSigningModal = ({ toggleModal, cancelSigning, isSubmitting }) => (
  <Fragment>
    <ModalContent className={styles.center}>
      <WarningIcon className={styles.warning} />
      <h1 className={styles.title}>
        <FormattedMessage id="questionnaire.abort_signing.modal.header" />
      </h1>
      <p>
        <FormattedMessage id="questionnaire.abort_signing.modal.message" />
      </p>
      <p className={styles.confirmation}>
        <FormattedMessage id="questionnaire.abort_signing.modal.confirm" />
      </p>
    </ModalContent>
    <ModalFooter>
      <Button
        onClick={toggleModal}
        variant={SECONDARY}
        testId="AbortSigningModal-no">
        <FormattedMessage id="questionnaire.abort_signing.modal.no" />
      </Button>
      <Button
        type="submit"
        color={DANGER}
        loading={isSubmitting}
        onClick={cancelSigning}
        testId="AbortSigningModal-yes">
        <FormattedMessage id="questionnaire.abort_signing.modal.yes" />
      </Button>
    </ModalFooter>
  </Fragment>
);

AbortSigningModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  cancelSigning: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

export default AbortSigningModal;
