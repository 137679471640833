import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { UserEdit } from '../components/UserEdit';
import { Creators } from '../actions';

export const mapStateToProps = state => ({
  user: state.userData.user,
  userReinvited: state.userData.userReinvited,
  currentUser: state.loggedInUserState.user,
});
export const mapDispatchToProps = {
  fetchUser: Creators.fetchUserRequest,
  reinviteUser: Creators.reinviteUserRequest,
  updateUser: Creators.updateUserRequest,
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  memberId: ownProps.match.params.memberId,
  saveUser: values => dispatchProps.updateUser(ownProps.match.params.memberId, values),
  reinviteUser: () => dispatchProps.reinviteUser(ownProps.match.params.memberId),
});

export const UserEditContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(UserEdit);
