import appTheme from '../Visualization/theme';

const theme = {
  typography: { useNextVariants: true },
  palette: {
    primary: { main: appTheme.mainColor },
    action: { hover: appTheme.background.lightBlue },
  },
  overrides: {
    MuiTable: { root: { '& > tbody, & > thead': { backgroundColor: '#fff' } } },
    MuiTableRow: { hover: { '&$hover:hover': { backgroundColor: 'rgb(245, 250, 255)' } } },
    MuiTableCell: {
      root: {
        paddingRight: 35,
        paddingTop: 15,
        paddingBottom: 15,
      },
      head: {
        textTransform: 'uppercase',
        fontFamily: appTheme.fontFamily.primary,
        fontSize: '0.8rem',
        fontWeight: 300,
        color: appTheme.subtitleColor,
      },
      body: {
        color: appTheme.font.normal,
        lineHeight: 2,
        fontSize: '0.93em',
        fontFamily: appTheme.fontFamily.primary,
      },
      paddingNone: { padding: '15px 20px' },
    },
    MuiDialogTitle: { root: { borderBottom: `1px solid ${appTheme.border.normal}` } },
    MuiLinearProgress: {
      root: {
        position: 'relative',
        overflow: 'hidden',
        height: 7,
        borderRadius: 5,
      },
      barColorPrimary: { backgroundColor: appTheme.mainColor },
      barColorSecondary: { backgroundColor: '#d7e1ee' },
    },
    MuiSwitch: { switchBase: { height: 'auto' } },
    MuiSelect: {
      select:
        {
          height: '2.3rem',
          '&:focus': { backgroundColor: '#fff' },
        },
    },
    MuiTooltip: { tooltip: { maxWidth: '600px' } },
  },
};

export default theme;
