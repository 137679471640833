export default `
  .tc__pagination {
    width: 70px;
    letter-spacing: 2px;
    cursor: pointer;
    color:  #6E7377;
    font-size: 15px;
    position: relative;
    border: 0;
    padding: 0;
  }
  .tc__pagination:hover:after {
    background-color: rgb(211, 211, 211);
    content: 'x';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
