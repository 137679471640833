import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import SettingsIcon from '@material-ui/icons/Settings';
import ShareIcon from '@material-ui/icons/Share';
import InfoIcon from '@material-ui/icons/Info';
import AddNoteIcon from '@material-ui/icons/NoteAdd';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import UnpublishTemplate from 'shared/PublishUnpublish/UnpublishTemplate';
import {
  getTemplateDetailEditPath,
  getTemplateDetailPath,
} from 'MainApp/routesPaths';
import { Creators as DocumentsCreators } from 'pages/Documents/actions';
import { StyledFlexCenter } from './styled-components';

const Buttons = ({
  editorIsSaving,
  templateHasDrafts,
  templateVersionId,
  push,
  templateId,
  createDocument,
  onClickShare,
  onClickSettings,
}) => (
  <StyledFlexCenter>
    <Tooltip
      title={
        <FormattedMessage id="api.panel.questionnaire_templates.actions.menu_template_settings" />
      }>
      <IconButton
        onClick={onClickSettings}
        testId="TopToolbar-templateSettings">
        <SettingsIcon />
      </IconButton>
    </Tooltip>
    <Tooltip
      title={
        <FormattedMessage id="api.panel.questionnaire_templates.actions.menu_template_information" />
      }>
      <IconButton
        component={Link}
        testId="TopToolbar-templateInfo"
        to={`/templates/${templateId}`}>
        <InfoIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title={<FormattedMessage id="visualization.top_toolbar.share" />}>
      <IconButton onClick={onClickShare} testId="TopToolbar-share">
        <ShareIcon />
      </IconButton>
    </Tooltip>
    <Tooltip
      title={
        <FormattedMessage id="visualization.top_toolbar.create_document" />
      }>
      <IconButton
        testId="create-document"
        onClick={() => createDocument(templateId)}
        disabled={editorIsSaving}>
        <AddNoteIcon />
      </IconButton>
    </Tooltip>
    <UnpublishTemplate
      redirectTo={() => push(getTemplateDetailPath(templateId))}
      templateVersionId={templateVersionId} />
    <Button
      testId="edit-draft"
      onClick={() => push(getTemplateDetailEditPath(templateId))}
      disabled={editorIsSaving}>
      {templateHasDrafts ? (
        <FormattedMessage id="visualization.top_toolbar.edit_draft" />
      ) : (
        <FormattedMessage id="visualization.top_toolbar.create_draft" />
      )}
    </Button>
  </StyledFlexCenter>
);

Buttons.propTypes = {
  editorIsSaving: PropTypes.bool,
  templateHasDrafts: PropTypes.bool.isRequired,
  templateVersionId: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
  createDocument: PropTypes.func.isRequired,
  onClickShare: PropTypes.func.isRequired,
  onClickSettings: PropTypes.func.isRequired,
};

const mapDispatchToProps = { createDocument: DocumentsCreators.selectTemplateForDocumentRequest };

export default connect(null, mapDispatchToProps)(Buttons);
