import Button, { LARGE, SECONDARY, SMALL } from 'components/Button';
import { MenuItem } from 'components/MenuList';
import SplitButton from 'components/SplitButton';
import Tooltip from 'components/Tooltip';
import { Creators as QuestionnaireActions } from 'ducks/questionnaire/actions';
import { selectLastAnswer, templatePropertyEditDocumentEnabledSelector } from 'ducks/questionnaire/selectors';
import { Creators as documentActions } from 'pages/DocumentDetails/actions';
import { selectDocumentHasVersions, selectDocumentId, selectDocumentName, selectLatestDocumentVersion } from 'pages/DocumentDetails/selectors';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import previewHtmlSelector from 'shared/utils/questionnairePreviewSelector';
import { EditDocumentConfirmationModal } from './EditDocumentConfrimationModal';
import styles from './styles.css';

export class EditingComponent extends Component {
  static propTypes = {
    isEnabled: PropTypes.bool,
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    isMobile: PropTypes.bool,
    latestVersion: PropTypes.shape({ revision: PropTypes.number.isRequired }),
    questionnaireHtml: PropTypes.string,
    createDocumentVersion: PropTypes.func.isRequired,
    documentId: PropTypes.string,
    documentName: PropTypes.string,
    documentHasVersions: PropTypes.bool,
  };

  state = { isOpen: false };

  toggleModal = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  onEditClick = () => {
    const { latestVersion, history, documentId } = this.props;
    const { pathname, search } = history.location;

    if (latestVersion.completed) {
      this.toggleModal();
    } else if (pathname.startsWith('/external')) {
      history.push(`/external/documents/${documentId}/edit-version${search}`);
    } else {
      history.push(`/documents/${documentId}/edit`);
    }
  }

  editDocument = () => {
    const { latestVersion, createDocumentVersion, questionnaireHtml, documentId, documentName } = this.props;
    const html = latestVersion.isQuestionnaire ? questionnaireHtml : latestVersion.html;
    createDocumentVersion(documentId, documentName, html);
  };

  editQuestionnaire = () => {
    const { startEditQuestion, goToQuestion, lastAnswer } = this.props;
    if (lastAnswer) {
      startEditQuestion();
      goToQuestion(lastAnswer.question_id);
    }
  }

  _renderSplitButtonOptions = () => [
    <MenuItem
      key="questionnaire.change_answers"
      classes={{ root: this.props.documentHasVersions ? styles.menuItemDisabled : '' }}
      onClick={this.props.documentHasVersions ? null : this.editQuestionnaire}>
      <Tooltip title={this.props.documentHasVersions ? <FormattedMessage id="questionnaire.change_answers_disabled" /> : ''}>
        <span>
          <FormattedMessage id="questionnaire.change_answers" />
        </span>
      </Tooltip>
    </MenuItem>,
    <MenuItem onClick={this.onEditClick} key="questionnaire.edit_document">
      <FormattedMessage id="questionnaire.edit_document" />
    </MenuItem>,
  ]

  render() {
    const { isEnabled, isMobile, lastAnswer } = this.props;
    if (isEnabled) {
      return (
        <div>
          <SplitButton
            btnTestId="EditDocument-button"
            variant={SECONDARY}
            onClick={this.onEditClick}
            options={this._renderSplitButtonOptions()}>
            <FormattedMessage id="questionnaire.edit" />
          </SplitButton>
          <EditDocumentConfirmationModal
            open={this.state.isOpen}
            toggleModal={this.toggleModal}
            onProceed={this.editDocument} />
        </div>
      );
    }
    if (!!lastAnswer && !isEnabled) {
      return (
        <Button
          onClick={this.editQuestionnaire}
          variant={SECONDARY}
          size={isMobile ? SMALL : LARGE}
          testId="DocumentPreview-Edit">
          <FormattedMessage id="questionnaire.edit" />
        </Button>
      );
    }

    return null;
  }
}

const mapStateToProps = state => ({
  isEnabled: templatePropertyEditDocumentEnabledSelector(state),
  latestVersion: selectLatestDocumentVersion(state),
  questionnaireHtml: previewHtmlSelector(state),
  documentId: selectDocumentId(state),
  documentName: selectDocumentName(state),
  lastAnswer: selectLastAnswer(state),
  documentHasVersions: selectDocumentHasVersions(state),
});

const mapDispatchToProps = {
  createDocumentVersion: documentActions.createDocumentVersionRequest,
  startEditQuestion: QuestionnaireActions.startEditQuestion,
  goToQuestion: QuestionnaireActions.goToQuestion,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(EditingComponent);
