import React from 'react';

export function Download(props) {
  return (
    <svg
      width="22"
      height="22"
      overflow="visible"
      preserveAspectRatio="none"
      version="1.2"
      viewBox="0 -52.415 684 684"
      {...props}>
      <path
        d="M0 552.5h684v26.3H0zM523.6 313.1L502.5 292 355.2 442V0h-26.3v442L181.5 292l-21 21.1L342 492z"
        vectorEffect="non-scaling-stroke"
        fill="currentColor" />
    </svg>
  );
}
