import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import Initials from 'shared/Components/Initials';
import getInitials from 'utils/getInitials';
import propTypes from 'utils/proptypes';
import User from 'models/User';
import styles from './UserRow.css';

export class UserRow extends PureComponent {
  static propTypes = {
    currentUser: propTypes.currentUser,
    user: propTypes.user,
    onUserRowClick: PropTypes.func.isRequired,
  }

  static defaultProps = { user: new User() }

  isEditDisabled = (id) => {
    const { currentUser } = this.props;
    return id === currentUser.id;
  };

  onClick = () => {
    const { user: { id }, onUserRowClick } = this.props;
    if (!this.isEditDisabled(id)) {
      onUserRowClick(id);
    }
  };

  render() {
    const {
      user: {
        firstName,
        lastName,
        email,
        roles,
        id,
        disabled,
      },
    } = this.props;
    return (
      <TableRow
        hover
        onClick={this.onClick}
        disabled={disabled}
        className={disabled ? styles.userDisabled : ''}
        classes={{ root: this.isEditDisabled(id) ? styles.componentDisabled : styles.component }}>
        <TableCell>
          <div className={styles.row}>
            <Initials type={roles[0]}>
              {getInitials(firstName, lastName, email)}
            </Initials>
            <div className={cn(styles.center, styles.column)}>
              <span data-test-id="EditUser-name" className={styles.name}>{`${firstName || ''} ${lastName || ''}`}</span>
              <span className={styles.email}>{email}</span>
            </div>
          </div>
        </TableCell>
        <TableCell>{roles.map(r => <FormattedMessage key={r} id={`users.roles.${r}`} />)}</TableCell>
      </TableRow>
    );
  }
}
