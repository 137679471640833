import _get from 'lodash/get';

export const selectIsUserAuthorized = state => !!_get(state, ['loggedInUserState', 'isUserAuthorized']);
export const selectHasFetchedUser = state => _get(state, ['loggedInUserState', 'hasFetchedUser']);
export const selectUserLanguage = state => _get(state, ['loggedInUserState', 'user', 'language']);
export const selectIsUpdatingUser = state => _get(state, ['loggedInUserState', 'isUpdatingUser']);
export const selectUserPersonalInformation = (state) => {
  const { firstName, lastName, email } = _get(state, ['loggedInUserState', 'user']) || {};
  const fullName = `${firstName} ${lastName}`.trim();
  return { fullName, firstName, lastName, email };
};
export const selectUserRoles = state => _get(state, ['loggedInUserState', 'user', 'roles']);
export const selectError = state => _get(state, ['loggedInUserState', 'error', 'data'], {});
export const selectUserData = state => _get(state, ['loggedInUserState', 'user']);
export const selectAppType = state => _get(state, ['loggedInUserState', 'appType']);
