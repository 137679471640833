import cloneDeep from 'lodash/cloneDeep';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isPlainObject from 'lodash/isPlainObject';

export const snakeToCamelCase = (object) => {
  if (isString(object)) {
    return object;
  }
  let camelCaseObject = cloneDeep(object);
  if (isArray(object)) return map(object, snakeToCamelCase);
  // Convert keys to camel case
  camelCaseObject = mapKeys(camelCaseObject, (value, key) => key.replace(/(_\w)/g, match => match[1].toUpperCase()));

  // Recursively apply throughout object
  return mapValues(camelCaseObject, (value) => {
    if (isPlainObject(value)) {
      return snakeToCamelCase(value);
    } if (isArray(value)) {
      return map(value, snakeToCamelCase);
    }
    return value;
  });
};

export default snakeToCamelCase;
