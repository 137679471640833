import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import TemplatePageHeader from './TemplatePageHeader';
import DocumentsPageHeader from './DocumentsPageHeader';
import TemplateDetailsHeader from './TemplateDetailsHeader';
import EditorPageHeader from '../../Visualization/Editor-v2/TopToolbar/TopToolbar';

export default () => (
  <Fragment>
    <Route exact path="/templates" component={TemplatePageHeader} />
    <Route exact path="/templates/:id" component={TemplateDetailsHeader} />
    <Route exact path="/templates/folders/:folderId" component={TemplatePageHeader} />
    <Route exact path="/templates/:id/preview" render={() => <EditorPageHeader previewMode />} />
    <Route exact path="/templates/:id/edit" component={EditorPageHeader} />
    <Route exact path="/documents" component={DocumentsPageHeader} />
    <Route exact path="/documents/folders/:folderId" component={DocumentsPageHeader} />
  </Fragment>
);
