import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import EditorView from 'Visualization/Editor-v2/views/EditorView';

class TemplatePreview extends PureComponent {
  render() {
    return <EditorView {...this.props} previewMode />;
  }
}

const mapStateToProps = ({ editor }) => ({ error: editor.error });

export default connect(mapStateToProps)(TemplatePreview);
