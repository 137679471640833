import { css } from 'styled-components';
import theme from 'Visualization/theme';

export const legacyMedia = Object.keys(theme.breakpoints).reduce((accumulator, label) => {
  const emSize = theme.breakpoints[label] / 16;
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export const media = Object.keys(theme.breakpoints).reduce((accumulator, label) => {
  const emSize = theme.breakpoints[label] / 16;
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});
