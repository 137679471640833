import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import get from 'lodash/get';
import { withFormik, Field } from 'formik';
import { injectIntl, intlShape } from 'react-intl';

import proptypes from 'utils/proptypes';
import { defaultFormat } from 'shared/date';
import DatePicker from 'components/Datepicker';
import wasThisAnswerRemoved from './helpers/wasThisAnswerRemoved';

export class DateQuestionComponent extends Component {
  static propTypes = {
    saveAnswer: PropTypes.func.isRequired,
    resetValue: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    question: proptypes.inputQuestionScheme.isRequired,
    answer: proptypes.answerScheme,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    values: PropTypes.shape({}),
    templateSettings: proptypes.templateSettingsScheme.isRequired,
    touched: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    // if defaultValue is filled and answer id is undefined then it means we should save that answer
    // as those values are from removedAnswers array and we need to
    // auto save it to be able to click NextQuestion
    const { answer, defaultValue, saveAnswer } = this.props;
    const idToReplace = get(this.props, 'question.details.id', '');

    if (wasThisAnswerRemoved(answer)) {
      saveAnswer(idToReplace, defaultValue);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { touched, values, question } = this.props;
    const dateValue = values[question.id];
    return dateValue !== nextProps.values[nextProps.question.id] || touched !== nextProps.touched;
  }

  onChange = (timestamp) => {
    const { saveAnswer } = this.props;
    const idToReplace = get(this.props, 'question.details.id', '');
    saveAnswer(idToReplace, timestamp);
  }

  render() {
    const { question, templateSettings, resetValue, intl } = this.props;

    return (
      <Field
        component={DatePicker}
        placeholder={intl.formatMessage({ id: 'questionnaire.questions.date_input_placeholder' })}
        name={question.id}
        onChange={this.onChange}
        resetValue={resetValue}
        format={defaultFormat}
        lang={templateSettings.language}
        autoFocus />
    );
  }
}

export const formConfig = { mapPropsToValues: ({ defaultValue, question }) => ({ [question.id]: parseInt(defaultValue, 10) }) };

export default compose(
  injectIntl,
  withFormik(formConfig),
)(DateQuestionComponent);
