import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { LEXOLVE_LANDING_PAGE_PATH } from 'appRoot/constants';
import { selectRedirectOnErrorPath, selectErrorType } from 'ducks/error/selectors';
import Logo from 'components/Logo';
import Typography, { VARIANTS } from 'components/Typography';
import Button, { LINK } from 'components/Button';
import styles from './index.css';

export class Error500Component extends PureComponent {
  static propTypes = {
    errorType: PropTypes.string,
    redirectOnErrorPath: PropTypes.string,
  };

  getRedirectLabel = () => (this.props.redirectOnErrorPath === LEXOLVE_LANDING_PAGE_PATH ? 'shared.errors.go_lexolve' : 'shared.errors.go_back');

  render() {
    const { errorType, redirectOnErrorPath } = this.props;
    const unknownErrorKey = errorType || 'default';
    return (
      <div className={styles.component}>
        <Logo className={styles.logo} />
        <img className={styles.picture} src="/assets/images/bg_500.png" alt="Lawbotics" />
        <Typography variant={VARIANTS.H1}>
          <FormattedMessage id={`shared.errors.${unknownErrorKey}.title`} />
        </Typography>
        <Typography variant={VARIANTS.BODY_SHORT}>
          <FormattedMessage id={`shared.errors.${unknownErrorKey}.message`} />
        </Typography>
        <Button variant={LINK} to={redirectOnErrorPath}>
          <FormattedMessage id={this.getRedirectLabel()} />
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  errorType: selectErrorType(state),
  redirectOnErrorPath: selectRedirectOnErrorPath(state),
});

export default connect(mapStateToProps)(Error500Component);
