exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-ChoiceShowIfType-___styles__component___1EGmf {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n}\n\n.app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-ChoiceShowIfType-___styles__input___ysuQv {\n  flex: 1;\n}\n\nbutton.app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-ChoiceShowIfType-___styles__removeBtn___VGmq4 {\n  color: #bebdbe;\n  color: var(--c-bw-20);\n  margin: 0 10px;\n}\n\nbutton.app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-ChoiceShowIfType-___styles__removeBtn___VGmq4 svg {\n    font-size: 1.33333rem;\n  }\n\n.app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-ChoiceShowIfType-___styles__optionActions___17ta9 {\n  width: 80px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n", "", {"version":3,"sources":["/workspace/app/bundles/Visualization/Editor-v2/LogicsManager/LogicsTypes/ChoiceShowIfType/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAa;EACb,oBAAmB;EACnB,+BAA8B;EAC9B,oBAAmB;EACnB,mBAAkB;CACpB;;AAEA;EACE,QAAO;CACT;;AAEA;EACE,eAAqB;EAArB,sBAAqB;EACrB,eAAc;CAKhB;;AAHE;IACE,sBAAe;GACjB;;AAGF;EACE,YAAW;EACX,cAAa;EACb,oBAAmB;EACnB,4BAA2B;CAC7B","file":"styles.css","sourcesContent":[".component {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n}\n\n.input {\n  flex: 1;\n}\n\nbutton.removeBtn {\n  color: var(--c-bw-20);\n  margin: 0 10px;\n\n  svg {\n    font-size: 20px;\n  }\n}\n\n.optionActions {\n  width: 80px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-ChoiceShowIfType-___styles__component___1EGmf",
	"input": "app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-ChoiceShowIfType-___styles__input___ysuQv",
	"removeBtn": "app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-ChoiceShowIfType-___styles__removeBtn___VGmq4",
	"optionActions": "app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-ChoiceShowIfType-___styles__optionActions___17ta9"
};