import React from 'react';
import PropTypes from 'prop-types';
import flatMap from 'lodash/flatMap';
import { intlShape, injectIntl } from 'react-intl';
import { folderScheme } from 'utils/proptypes';
import Document from 'icons/Document';
import { Template } from 'icons/Template';
import { ChevronRight } from 'icons/ChevronRight';
import styles from './styles.css';

const getFolderPath = (item, intl) => {
  const rootPath = item.isDocument ? 'panel.layouts.panel_navbar.documents' : 'panel.layouts.panel_navbar.templates';
  const path = flatMap(item.folder.parents, p => p.name);
  if (item.folder.name) {
    path.push(item.folder.name);
  }
  return [intl.formatMessage({ id: rootPath })].concat(path);
};

const createFolderPathsElements = folderPaths => folderPaths.reduce((acc, path, index) => {
  if (index > 0) {
    acc.push(<ChevronRight className={styles.folderDelimiter} key={`${path}-delimiter`} />);
  }
  acc.push(<span key={path}>{path}</span>);

  return acc;
}, []);

export const TrashNameComponent = ({ item, name, intl }) => {
  const folderPaths = getFolderPath(item, intl);
  const Icon = item.isDocument ? Document : Template;
  const itemName = name || item.basedOnTemplate;
  return (
    <div className={styles.component}>
      <Icon className={styles.icon} />
      <div>
        <strong data-test-id="Trash-templateNameData">{itemName}</strong>
        <div className={styles.folderPath}>
          {createFolderPathsElements(folderPaths)}
        </div>
      </div>
    </div>
  );
};

TrashNameComponent.propTypes = {
  name: PropTypes.string.isRequired,
  item: folderScheme.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(TrashNameComponent);
