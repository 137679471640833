import { delay } from 'redux-saga';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import getOptionsFromHtml from 'Visualization/helpers/getOptionsFromHtml';
import { Creators as FlowActions } from 'containers/Flow/actions';
import Api, { getDraftTemplateVersion, getTemplate, getTemplateVersionPreview } from '../services/Api';
import { Creators as EditorActions, Types as EditorTypes } from './reducer';
import { Creators as QuestionActions } from './Questions/QuestionsRedux';

export function* initializeEditor({ templateId }) {
  try {
    const fetchDraftVersion = yield call(getDraftTemplateVersion, templateId);
    const fetchTemplate = yield call(getTemplate, templateId);
    const documentTemplate = yield call(Api.fetchDocumentTemplateStylesheet);

    yield* _setupEditor(
      fetchDraftVersion.data.questionnaire_template_version,
      fetchTemplate.data.questionnaire_template,
      documentTemplate.data.document_template.stylesheet,
    );
    yield put(EditorActions.editorInitialized());
  } catch (e) {
    yield put(EditorActions.initializeEditorFailure(e));
  }
}

export function* initializeEditorPreview({ templateId, version }) {
  try {
    const fetchVersionPreview = yield call(getTemplateVersionPreview, templateId, version);
    const fetchTemplate = yield call(getTemplate, templateId);
    const documentTemplate = yield call(Api.fetchDocumentTemplateStylesheet);

    yield* _setupEditor(
      fetchVersionPreview.data.questionnaire_template_version,
      fetchTemplate.data.questionnaire_template,
      documentTemplate.data.document_template.stylesheet,
    );
    yield put(EditorActions.editorPreviewInitialized());
  } catch (e) {
    yield put(EditorActions.initializeEditorFailure(e));
  }
}

function* _setupEditor(templateVersion, template, stylesheet) {
  const usedOptions = getOptionsFromHtml(templateVersion.wysiwyg_html);

  yield all([
    EditorActions.setStylesheet(stylesheet),
    EditorActions.setTemplateVersionData(templateVersion),
    EditorActions.setTemplateData(template),
    QuestionActions.setQuestions(templateVersion.questions),
    QuestionActions.setUsedOptions(usedOptions),
  ].map(action => put(action)));
}

export function* saveTemplateName({ name }) {
  const templateId = yield select(state => state.editor.template.id);

  try {
    yield call(Api.updateQuestionnaireTemplate, templateId, { questionnaire_template: { name } });
    yield put(EditorActions.saveTemplateNameSuccess());
  } catch (e) {
    yield put(EditorActions.saveTemplateNameFailure(e));
  }
}

export function* toggleSharingTemplate({ sharingActive }) {
  const templateId = yield select(state => state.editor.template.id);

  try {
    yield call(
      Api.updateQuestionnaireTemplate,
      templateId,
      { questionnaire_template: { sharing_active: sharingActive } },
    );
    yield put(EditorActions.toggleSharingTemplateSuccess(sharingActive));
  } catch (e) {
    yield put(EditorActions.saveQuestionnaireTemplateNameFailure(e));
  }
}

export function* saveQuestionnaireTemplateName({ name }) {
  yield call(delay, 200);
  const templateId = yield select(state => state.editor.template.id);

  try {
    const response = yield call(Api.updateQuestionnaireTemplate, templateId, { questionnaire_template: { name } });
    yield put(EditorActions.saveQuestionnaireTemplateNameSuccess(response.data.answer));
  } catch (e) {
    yield put(EditorActions.saveQuestionnaireTemplateNameFailure(e));
  }
}

export function* saveEditorContent({ html }) {
  yield call(delay, 200);
  const questionnaireTemplateVersionId = yield select(state => state.editor.templateVersion.id);
  const params = { wysiwyg_html: html };

  try {
    const response = yield call(Api.updateQuestionnaireTemplateVersion, questionnaireTemplateVersionId, params);
    yield put(EditorActions.saveEditorContentSuccess(response.data.answer));
    yield put(FlowActions.regenerateFlow());
  } catch (e) {
    yield put(EditorActions.saveEditorContentFailure(e));
  }
}

export default [
  takeLatest(EditorTypes.SAVE_EDITOR_CONTENT, saveEditorContent),
  takeLatest(EditorTypes.INITIALIZE_EDITOR, initializeEditor),
  takeLatest(EditorTypes.INITIALIZE_EDITOR_PREVIEW, initializeEditorPreview),
  takeLatest(EditorTypes.SAVE_TEMPLATE_NAME, saveTemplateName),
  takeLatest(EditorTypes.TOGGLE_SHARING_TEMPLATE_REQUEST, toggleSharingTemplate),
  takeLatest(EditorTypes.SAVE_QUESTIONNAIRE_TEMPLATE_NAME, saveQuestionnaireTemplateName),
];
