import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import proptypes from 'utils/proptypes';
import Question from './Question';

const Item = props => (
  <Fragment>
    <Question
      nestingLevel={props.nestingLevel}
      flowItem={props.flowItem} />
    {props.flowItem.children.map(flowItemChild => (
      <Item
        nestingLevel={props.nestingLevel + 1}
        key={flowItemChild.questionId}
        flowItem={flowItemChild} />
    ))}
  </Fragment>
);

Item.propTypes = {
  nestingLevel: PropTypes.number.isRequired,
  flowItem: proptypes.flowItem.isRequired,
};

export default Item;
