import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal, { ModalContent, ModalFooter } from 'components/Modal';
import Button, { SECONDARY } from 'components/Button';
import styles from './EditDocumentConfirmationModal.css';

export class EditDocumentConfirmationModal extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onProceed: PropTypes.func.isRequired,
  };

  render() {
    const { open, toggleModal, onProceed } = this.props;
    return (
      <Modal
        modalTitle={<FormattedMessage id="questionnaire.edit_modal.title" />}
        open={open}
        size="medium"
        toggleModal={toggleModal}>
        <ModalContent>
          <p className={styles.text}>
            <FormattedMessage id="questionnaire.edit_modal.description" />
          </p>
          <p className={styles.text}>
            <FormattedMessage id="questionnaire.edit_modal.tip" />
          </p>
        </ModalContent>
        <ModalFooter>
          <Button onClick={toggleModal} variant={SECONDARY} testId="DocumentPreview-EditCancel">
            <FormattedMessage id="shared.cancel" />
          </Button>
          <Button onClick={onProceed} testId="DocumentPreview-EditProceed">
            <FormattedMessage id="questionnaire.edit_modal.proceed" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
