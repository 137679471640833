import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { createClassNameModifiers } from 'utils/componentUtils';
import ButtonBase from '@material-ui/core/ButtonBase';
import styles from './index.css';

const PRIMARY = 'primary';
const DANGER = 'danger';
const REGULAR = 'regular';
const MINIMAL = 'minimal';

const IconButton = React.forwardRef((props, ref) => {
  const { className, variant, size, testId, children, disabled, ...otherProps } = props;
  const formattedModifiers = createClassNameModifiers({
    modifiers: [].concat([variant], [size]),
    styles,
  });
  const classString = cn(className, ...formattedModifiers);

  return (
    <ButtonBase
      classes={{ root: styles.component, disabled: styles.disabled }}
      className={cn(classString, styles.component)}
      data-test-id={testId}
      buttonRef={ref}
      disabled={disabled}
      disableRipple={size === MINIMAL}
      {...otherProps}>
      {children}
    </ButtonBase>
  );
});

IconButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf([PRIMARY, DANGER]),
  size: PropTypes.oneOf([REGULAR, MINIMAL]),
  testId: PropTypes.string.isRequired,
  ref: PropTypes.func,
  disabled: PropTypes.bool,
  component: PropTypes.node,
  to: PropTypes.string,
};

IconButton.defaultProps = {
  variant: PRIMARY,
  size: REGULAR,
};

export {
  DANGER,
  PRIMARY,
  REGULAR,
  MINIMAL,
};

export default IconButton;
