import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';
import { PREVIEW } from 'shared/constants/questionnaireModes';
import { COMPLETED, STARTED, ERROR } from 'shared/constants/questionnaireStatus';
import { FAILED, SAVED, SAVING, NONE } from 'shared/constants/saveStatus';
import { Types as DocumentsTypes } from 'pages/Documents/actions';
import { Types } from './actions';

export const initialState = {
  loaded: false,
  savingStatus: NONE,
  error: false,
  finished: false,
  mode: PREVIEW,
  userQuestionnaire: {},
  questionnaireTemplateVersion: {},
  questions: [{}],
  questionsFlow: [{}],
  currentQuestion: {},
  answers: [],
  removedAnswers: [],
  company: {},
  questionsEditStarted: undefined,
  previewDocumentPdf: undefined,
};

// TODO: Move company state and actions from quesetionnaire reducer
const clearUserQuestionnaire = state => ({ ...initialState, company: state.company });

const updateUserQuestionnaireName = (state, { name }) => update(state, { userQuestionnaire: { name: { $set: name } } });
const updateUserQuestionnaire = (state, { params }) => update(state, { userQuestionnaire: { $merge: params }, savingStatus: { $set: SAVED } });

const setUserQuestionnaireData = (state, { userQuestionnaire }) => update(state, {
  userQuestionnaire: { $set: userQuestionnaire },
  templateVersion: { $set: userQuestionnaire.questionnaire_template_version },
  wysiwygHtml: { $set: userQuestionnaire.questionnaire_template_version.wysiwyg_html },
  answers: { $set: userQuestionnaire.answers },
  questions: { $set: userQuestionnaire.questionnaire_template_version.questions },
  settings: { $set: userQuestionnaire.questionnaire_template_version.qtv_property },
});

const setQuestionsFlow = (state, { questionsFlow }) => update(state, { questionsFlow: { $set: questionsFlow } });

const setCurrentQuestion = (state, { question }) => update(state, { currentQuestion: { $set: question } });

const saveAnswerSuccess = (state, { answer }) => update(state, { answers: { $push: [answer] }, savingStatus: { $set: SAVED } });

const updateAnswerSuccess = (state, { answer }) => {
  const answerIndex = state.answers.findIndex(a => a.question_id === answer.question_id);
  return update(state, { answers: { [answerIndex]: { $merge: answer } }, savingStatus: { $set: SAVED } });
};

const resetFieldValue = (state, { id }) => {
  const answers = state.answers.filter(a => a.question_id !== id);
  return update(state, { answers: { $set: answers } });
};

const storeRemovedAnswers = (state, { remainingAnswers, removedAnswers }) => {
  const allRemovedAnswers = [...state.removedAnswers, ...removedAnswers];
  return update(state, {
    removedAnswers: { $set: allRemovedAnswers },
    answers: { $set: remainingAnswers },
  });
};


const setDocumentStyles = (state, { stylesheet }) => update(state, { stylesheet: { $set: stylesheet } });

const fetchCompanySuccess = (state, { company }) => update(state, { company: { $set: company } });

const setDocumentStatus = status => state => update(state, { userQuestionnaire: { $merge: { status } } });

const setDocumentNotReady = (state, { has }) => update(state, { userQuestionnaire: { hasSkippedAnswers: { $set: has } } });


const setSavingQuestionnaireStatus = status => state => update(state, { savingStatus: { $set: status } });

const updateHtmlContent = (state, { html }) => update(state, { templateVersion: { wysiwygHtml: { $set: html } } });

export default createReducer(initialState, {
  [Types.FETCH_USER_QUESTIONNAIRE_SUCCESS]: setUserQuestionnaireData,
  [DocumentsTypes.CREATE_GENERIC_ACCESS_DOCUMENT_SUCCESS]: setUserQuestionnaireData,
  [Types.CLEAR_USER_QUESTIONNAIRE]: clearUserQuestionnaire,
  [Types.UPDATE_USER_QUESTIONNAIRE_NAME]: updateUserQuestionnaireName,
  [Types.UPDATE_USER_QUESTIONNAIRE_REQUEST]: setSavingQuestionnaireStatus(SAVING),
  [Types.UPDATE_USER_QUESTIONNAIRE_SUCCESS]: updateUserQuestionnaire,
  [Types.UPDATE_USER_QUESTIONNAIRE_FAILURE]: setSavingQuestionnaireStatus(FAILED),
  [Types.FETCH_USER_QUESTIONNAIRE_FINISH]: state => update(state, { loaded: { $set: true } }),
  [Types.FETCH_DOCUMENT_STYLES_SUCCESS]: setDocumentStyles,
  [Types.SAVE_ANSWER]: setSavingQuestionnaireStatus(SAVING),
  [Types.SAVE_ANSWER_SUCCESS]: saveAnswerSuccess,
  [Types.SAVE_ANSWER_FAILURE]: setSavingQuestionnaireStatus(FAILED),
  [Types.RESET_FIELD_VALUE]: resetFieldValue,
  [Types.START_SAVING_ANSWER]: setSavingQuestionnaireStatus(SAVING),
  [Types.UPDATE_ANSWER]: setSavingQuestionnaireStatus(SAVING),
  [Types.UPDATE_ANSWER_SUCCESS]: updateAnswerSuccess,
  [Types.UPDATE_ANSWER_FAILURE]: setSavingQuestionnaireStatus(FAILED),
  [Types.SET_FINISHED]: (state, { bool }) => update(state, { finished: { $set: bool } }),
  [Types.SET_MODE]: (state, { mode }) => update(state, { mode: { $set: mode } }),

  [Types.SET_CURRENT_QUESTION]: setCurrentQuestion,
  [Types.STORE_REMOVED_ANSWERS]: storeRemovedAnswers,

  [Types.SET_QUESTIONS_FLOW]: setQuestionsFlow,

  [Types.FETCH_COMPANY_SUCCESS]: fetchCompanySuccess,

  [Types.SET_DOCUMENT_COMPLETED]: setDocumentStatus(COMPLETED),
  [Types.SET_DOCUMENT_STARTED]: setDocumentStatus(STARTED),
  [Types.SET_DOCUMENT_NOT_READY]: setDocumentNotReady,
  [Types.SIGN_DOCUMENT_REQUEST_FAILURE]: setDocumentStatus(ERROR),
  [Types.START_EDIT_QUESTION]: state => update(state, { questionsEditStarted: { $set: true } }),
  [Types.UPDATE_HTML_CONTENT]: updateHtmlContent,
  [Types.FETCH_DOCUMENT_PREVIEW_PDF_SUCCESS]: (state, { data }) => update(state, { previewDocumentPdf: { $set: data } }),
});
