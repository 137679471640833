import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Clear from '@material-ui/icons/Clear';
import Grow from '@material-ui/core/Grow';
import { Circle, Button, Content, ButtonPlaceholder } from './styled-components';

class TapTarget extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    isCircleVisible: PropTypes.bool.isRequired,
    onCircleClick: PropTypes.func.isRequired,
  };

  render() {
    const { isCircleVisible, onClick, onCircleClick, children } = this.props;

    return (
      <React.Fragment>
        <Grow in={isCircleVisible}>
          <Circle onClick={onCircleClick}>
            <Content>{children}</Content>
            <ButtonPlaceholder />
          </Circle>
        </Grow>
        <Button onClick={onClick} testId="TapTarget-toggle">
          <Clear />
        </Button>
      </React.Fragment>
    );
  }
}

export default TapTarget;
