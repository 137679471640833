const test = {};

const development = {
  BACKEND_URL: 'http://localhost:3000',
  ASSET_STORE_URL: 'https://assets.onthree.xyz',
  DOCUMENT_API_URL: 'https://qm.onthree.xyz',
  SIGNING_API_URL: 'https://signing.onthree.xyz',
  DOCUMENT_EXPORT_SERVICE_HOST: 'https://document-export.onthree.xyz',
  FRONTEND_URL: 'http://localhost:5000',
  GOOGLE_ANALYTICS_TRACKING_ID: '',
  GOOGLE_ANALYTICS_APP2_TRACKING_ID: '',
  LOG_REPORTING_ENABLED: 'false',
  S3_BUCKET_REGION: 'eu-central-1',
  S3_EDITOR_ATTACHMENTS_BUCKET: 'lawbotics-staging-editor-attachments',
  AMPLITUDE_TRACKING_ID: 'b4810ede60d17a9b832916ea12e1bd2c',
  AMPLITUDE_APP2_TRACKING_ID: '61d69b049d0036a7f8c294513d9aab01',
  AMPLITUDE_APP2_PROJECTNAME: 'APP2',
  MIXPANEL_TOKEN: '99b824a35cca1a047a766a130f744edb',
  LOGGING: 'false',
  FRESHCHAT_TOKEN: 'eed309ae-c5cd-4c4d-98b2-5021f84518ab', // Personal test token
  HUBSPOT_PORTAL_ID: '12345', // not an existing id - don't need this in dev env
};

const developmentStaging = {
  ...development,
  BACKEND_URL: 'https://lexolve-api.onthree.xyz',
  DASHBOARD_API: 'http://localhost:3000',
};

const staging = {
  BACKEND_URL: 'https://lexolve-api.onthree.xyz',
  ASSET_STORE_URL: 'https://assets.onthree.xyz',
  DOCUMENT_API_URL: 'https://qm.onthree.xyz',
  SIGNING_API_URL: 'https://signing.onthree.xyz',
  DOCUMENT_EXPORT_SERVICE_HOST: 'https://document-export.onthree.xyz',
  FRONTEND_URL: 'https://staging.onthree.xyz',
  GOOGLE_ANALYTICS_TRACKING_ID: 'UA-97029958-6',
  GOOGLE_ANALYTICS_APP2_TRACKING_ID: 'UA-97029958-6',
  LOG_REPORTING_ENABLED: 'false',
  LOG_REPORTING_KEY: process.env.LOG_REPORTING_KEY,
  LOG_REPORTING_ENV: 'lexolve-prod',
  S3_BUCKET_REGION: 'eu-central-1',
  S3_EDITOR_ATTACHMENTS_BUCKET: 'lawbotics-staging-editor-attachments',
  AMPLITUDE_TRACKING_ID: 'b4810ede60d17a9b832916ea12e1bd2c',
  AMPLITUDE_APP2_TRACKING_ID: '61d69b049d0036a7f8c294513d9aab01',
  AMPLITUDE_APP2_PROJECTNAME: 'APP2',
  LOGGING: 'true',
  FRESHCHAT_TOKEN: 'eed309ae-c5cd-4c4d-98b2-5021f84518ab', // Personal test token
  HUBSPOT_PORTAL_ID: '8032258',
  DASHBOARD_API: 'https://api.dashboard.onthree.xyz',
};

const production = {
  BACKEND_URL: 'https://api.lexolve.com',
  ASSET_STORE_URL: 'https://assets.lexolve.com',
  DOCUMENT_API_URL: 'https://qm.lexolve.com',
  SIGNING_API_URL: 'https://signing.lexolve.com',
  DOCUMENT_EXPORT_SERVICE_HOST: 'https://document-export.lexolve.com',
  FRONTEND_URL: 'https://app.lexolve.com',
  GOOGLE_ANALYTICS_TRACKING_ID: 'UA-97029958-3',
  GOOGLE_ANALYTICS_APP2_TRACKING_ID: 'UA-97029958-4',
  LOG_REPORTING_ENABLED: 'true',
  LOG_REPORTING_KEY: process.env.LOG_REPORTING_KEY,
  LOG_REPORTING_ENV: 'lexolve-prod',
  S3_BUCKET_REGION: 'eu-central-1',
  S3_EDITOR_ATTACHMENTS_BUCKET: 'lawbotics-prod-editor-attachments',
  AMPLITUDE_TRACKING_ID: '638979c047c235b2efc394c17c4aae55',
  AMPLITUDE_APP2_TRACKING_ID: 'a9ecc7749a7b331e74fe7ff19a2c0d9a',
  AMPLITUDE_APP2_PROJECTNAME: 'APP2',
  MIXPANEL_TOKEN: '99b824a35cca1a047a766a130f744edb',
  LOGGING: 'false',
  FRESHCHAT_TOKEN: '96edda0a-ef83-4ba7-8500-186dd6a14ef7',
  HUBSPOT_PORTAL_ID: '8032258',
  DASHBOARD_API: 'https://api.dashboard.lexolve.com',
};

export default function (env) {
  return (
    {
      test,
      development,
      developmentStaging,
      staging,
      production,
    }[env] || {}
  );
}
