import '../../polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import amplitude from 'amplitude-js';
import * as mixpanel from 'mixpanel-browser';
import queryString from 'qs';
import _noop from 'lodash/noop';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import muiTheme from 'shared/muiTheme';
import appTheme from 'Visualization/theme';
import { ConnectedRouter } from 'connected-react-router';
import { createMuiTheme } from '@material-ui/core/styles';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import nb from 'react-intl/locale-data/nb';

import LocalStore from 'api/LocalStore';
import AuthenticatedHttpClient, { BasicHttpClient } from 'api/HttpClient';
import ApiClient from 'api/ApiClient';
import refreshTokenInterceptor from 'api/Interceptors/refreshTokenInterceptor';
import CredentialsService from 'api/CredentialsService';
import AuthenticationService from 'api/AuthenticationService';
import UserService from 'api/UserService';
import DocumentService from 'api/DocumentService';
import AnswerService from 'api/AnswerService';
import DocumentStylesService from 'api/DocumentStylesService';
import TemplatesService from 'api/TemplatesService';
import FoldersService from 'api/FoldersService';
import DocumentFoldersService from 'api/DocumentFoldersService';
import QuestionService from 'api/QuestionService';
import QuestionnaireService from 'api/QuestionnaireService';
import SignDocumentService from 'api/SignDocumentService';
import { CompanyService } from 'api/CompanyService';
import UploadService from 'api/UploadService';
import GenericAccessService from 'api/GenericAccessService';
import TrashService from 'api/TrashService';
import InvitationsService from 'api/InvitationsService';
import DocumentVersionsService from 'api/DocumentVersionsService';
import DocumentExportService from 'api/DocumentExportService';
import NewDocumentService from 'api/DocumentService/NewDocumentService';

// These need to be excluded from lint tests because translations
// don't exist on ci
// eslint-disable-next-line import/no-unresolved
import { translations } from 'shared/i18n/translations';

import { apiPaths, accessTypes } from 'appRoot/constants/api';
import { EN_LANG } from 'appRoot/constants/language';
import {
  API_BASE_URL,
  DOCUMENT_API_URL,
  DOCUMENT_EXPORT_SERVICE_HOST,
  LOCAL_STORAGE_STORE_NAME,
  SIGNING_API_URL,
  appTypes,
} from 'appRoot/constants';
import {
  LOG_REPORTING_ENABLED,
  LOG_REPORTING_KEY,
  LOG_REPORTING_ENV,
  GOOGLE_ANALYTICS_ENABLED,
  GOOGLE_ANALYTICS_TRACKING_ID,
  GOOGLE_ANALYTICS_APP2_TRACKING_ID,
  AMPLITUDE_TRACKING_ENABLED,
  LOGGER_ENABLED,
} from 'appRoot/constants/services';

import { getBrowserLocale } from 'appRoot/utils/language';
import { getAppTypeValues } from 'utils/appType';

import { storageConfig } from 'constants/storageConfig';
import LoggingService from 'modules/LoggingService';
import GoogleAnalyticsService from '../../modules/GoogleAnalyticsService';
import AmplitudeService from '../../modules/AmplitudeService';
import MixpanelService from '../../modules/MixpanelService';
import TrackingService from '../../modules/TrackingService';
import Logger from '../../modules/Logger';
import createRoutes from './routes';
import createStore from './store';
import rootSaga, { createSagas } from './sagas';
import App from '../../containers/App';

// Path fixing
// When arriving on the page with a double slash, redirect to intended path
// -----------
const currentPathname = window.location.pathname;
const newPathname = currentPathname.replace(/\/\//g, '/');

if (currentPathname !== newPathname) {
  window.location.replace(newPathname);
}

// eslint-disable-next-line no-undef
if (typeof firebase !== 'undefined') {
  // eslint-disable-next-line no-undef
  firebase.performance();
}

// Authentication
// --------------
const { appType, apiPath, accessType } = getAppTypeValues({ appTypes, apiPaths, accessTypes, currentPathname });

const basicHttpClient = new BasicHttpClient({
  accessType,
  apiPath,
  baseUrl: API_BASE_URL,
  axiosInstance: axios.create(),
});

export const basicApiClient = new ApiClient({ httpClient: basicHttpClient });
const credentialsStore = new LocalStore(LOCAL_STORAGE_STORE_NAME, global.localStorage);
const credentialsService = new CredentialsService({
  apiClient: basicApiClient,
  credentialsStore,
});

const authenticatedHttpClient = new AuthenticatedHttpClient({
  accessType,
  apiPath,
  baseUrl: API_BASE_URL,
  fetchCredentials: credentialsService.fetchCredentials.bind(credentialsService),
  axiosInstance: axios.create(),
});
export const apiClient = new ApiClient({ httpClient: authenticatedHttpClient });

if (appType === appTypes.DEFAULT) {
  createAuthRefreshInterceptor(
    authenticatedHttpClient.axiosInstance,
    refreshTokenInterceptor({ apiClient: authenticatedHttpClient, credentialsService }),
    { statusCodes: [401, 403] },
  );
}

// TODO This should be removed once default is changed to v1 instead of panel
const v1HttpClient = new AuthenticatedHttpClient({
  accessType: accessTypes.V1,
  apiPath: apiPaths.V1,
  baseUrl: API_BASE_URL,
  axiosInstance: axios.create(),
  fetchCredentials: credentialsService.fetchCredentials.bind(credentialsService),
});
const v1ApiClient = new ApiClient({ httpClient: v1HttpClient });
const documentsHttpClient = new AuthenticatedHttpClient({
  accessType,
  apiPath: '',
  baseUrl: DOCUMENT_API_URL,
  axiosInstance: axios.create(),
  fetchCredentials: credentialsService.fetchCredentials.bind(credentialsService),
});
const documentsApiClient = new ApiClient({ httpClient: documentsHttpClient });
if (appType === appTypes.DEFAULT) {
  createAuthRefreshInterceptor(
    documentsHttpClient.axiosInstance,
    refreshTokenInterceptor({ apiClient: documentsHttpClient, credentialsService }),
    { statusCodes: [401, 403] },
  );
}

// Analytics
// ---------
// Check if user comes from dashboard
const { v2Experience } = queryString.parse(window.location.search, { ignoreQueryPrefix: true });
const gaTrackingId = v2Experience ? GOOGLE_ANALYTICS_APP2_TRACKING_ID : GOOGLE_ANALYTICS_TRACKING_ID;
const loggerService = new Logger({ isEnabled: LOGGER_ENABLED });
const gtag = GOOGLE_ANALYTICS_ENABLED
  ? GoogleAnalyticsService.init(gaTrackingId)
  : _noop;
const googleAnalyticsService = new GoogleAnalyticsService({
  gtag,
  isTrackingEnabled: GOOGLE_ANALYTICS_ENABLED,
  logger: loggerService,
  trackingId: gaTrackingId,
});

const amplitudeService = new AmplitudeService({
  amplitude,
  isTrackingEnabled: AMPLITUDE_TRACKING_ENABLED,
  logger: loggerService,
  dashboardUser: v2Experience,
});

const mixpanelService = new MixpanelService({
  mixpanel,
  logger: loggerService,
});

const loggingReporter = new LoggingService({
  isEnabled: LOG_REPORTING_ENABLED,
  apiKey: LOG_REPORTING_KEY,
  env: LOG_REPORTING_ENV,
});

const documentExportServiceHttpClient = new BasicHttpClient({
  accessType,
  apiPath: '',
  baseUrl: DOCUMENT_EXPORT_SERVICE_HOST,
  axiosInstance: axios.create(),
});

const documentExportServiceApiClient = new ApiClient({ httpClient: documentExportServiceHttpClient });

const signingHttpClient = new AuthenticatedHttpClient({
  accessType,
  apiPath: '',
  baseUrl: SIGNING_API_URL,
  axiosInstance: axios.create(),
  fetchCredentials: credentialsService.fetchCredentials.bind(credentialsService),
});
const signingApiClient = new ApiClient({ httpClient: signingHttpClient });
if (appType === appTypes.DEFAULT) {
  createAuthRefreshInterceptor(
    signingHttpClient.axiosInstance,
    refreshTokenInterceptor({ apiClient: signingApiClient, credentialsService }),
    { statusCodes: [401, 403] },
  );
}

const trackingService = new TrackingService(amplitudeService, googleAnalyticsService, mixpanelService, loggerService);

// Routes
// ------
const history = createBrowserHistory();
const initialState = {};
export const store = createStore(initialState, history, {
  trackingService,
  credentialsService,
  loggingReporter,
});
const Routes = createRoutes(history);

// Services
// --------
const authenticationService = new AuthenticationService({ apiClient });
const userService = new UserService({ apiClient });
const documentService = new DocumentService({ apiClient });
const answerService = new AnswerService({ apiClient });
const documentStylesService = new DocumentStylesService({ apiClient });
const templatesService = new TemplatesService({ apiClient });
const foldersService = new FoldersService({ apiClient });
const documentFoldersService = new DocumentFoldersService({ apiClient });
const questionService = new QuestionService({ apiClient });
const questionnaireService = new QuestionnaireService({ apiClient });
const signDocumentService = new SignDocumentService({ apiClient: signingApiClient });
const companyService = new CompanyService({ apiClient });
const uploadService = new UploadService({ apiClient: v1ApiClient });
const genericAccessService = new GenericAccessService({ apiClient: basicApiClient });
const trashService = new TrashService({ apiClient });
const invitationsService = new InvitationsService({ apiClient });
const documentVersionsService = new DocumentVersionsService({ apiClient: documentsApiClient });
const documentExportService = new DocumentExportService({ apiClient: documentExportServiceApiClient });
const newDocumentService = new NewDocumentService(documentsApiClient);

// Sagas
// -----
store.runSaga(rootSaga);
createSagas(store, {
  amplitudeService,
  mixpanelService,
  googleAnalyticsService,
  trackingService,
  authenticationService,
  userService,
  credentialsService,
  documentService,
  answerService,
  documentStylesService,
  templatesService,
  foldersService,
  documentFoldersService,
  questionService,
  questionnaireService,
  signDocumentService,
  companyService,
  uploadService,
  storageConfig,
  externalHttpClient: axios.create(),
  genericAccessService,
  trashService,
  invitationsService,
  documentVersionsService,
  documentExportService,
  newDocumentService,
});
// Add language locales
addLocaleData([...en, ...nb]);

// Analytics tracking based on page change
let lastPath; // Used to prevent duplicated page views
history.listen((location) => {
  const { pathname, search } = location;
  const pathArr = pathname.split('/');
  const path = location.pathname + location.search;
  const title = pathArr[pathArr.length - 1];

  if (path !== lastPath) {
    // We have to let React router update the DOM (document title) to get right title for the page
    setTimeout(() => {
      googleAnalyticsService.sendPageview({ path, title });
      mixpanelService.track(`Pageview ${document.titile}`, { pageUrl: pathname + search });
    }, 0);
    lastPath = path;
  } else {
    loggerService.info('[HISTORY TRACKING] Same path detected, omitting analytics track');
  }
});

// Themes
// ------
const browserLocale = getBrowserLocale(translations, EN_LANG);
const materialTheme = createMuiTheme(muiTheme);

// Environment specific setup
// --------------------------

const { token } = queryString.parse(
  window.location.search,
  { ignoreQueryPrefix: true },
);

if (appType !== appTypes.DEFAULT && token) {
  credentialsService.saveCredentials({ accessToken: token });
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App
        translations={translations}
        browserLocale={browserLocale}
        materialTheme={materialTheme}
        appTheme={appTheme}
        loggingReporter={loggingReporter}
        appType={appType}>
        <Routes />
      </App>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app'),
);
