import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  width: 100%;
  .top-toolbar-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .document-icon {
    width: 22px;
    height: 28px;
    color: var(--c-brand);
  }
  .template-name {
    font-size: 1.4em;
    line-height: 25px;
    padding: 5px 8px;
    height: 100%;
    border: 1px solid transparent;
    color: ${props => props.theme.font.normal};

    &:focus {
      box-shadow: none;
      border-bottom-color:${props => props.theme.border.normal};
      outline: none;
    }
    .template-name-font {
      font-weight: 400;
    }
  }
`;
