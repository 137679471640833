exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-bundles-Questionnaire2-Question-___styles__readMoreBtn___34SWZ {\n  box-shadow: none !important;\n  border: 0 !important;\n  float: right;\n  margin-top: 5px;\n  position: relative;\n  padding: 0;\n  background-color: transparent !important;\n  outline: 0;\n}\n\n.app-bundles-Questionnaire2-Question-___styles__readMoreBtn___34SWZ:focus,\n.app-bundles-Questionnaire2-Question-___styles__readMoreBtn___34SWZ:hover {\n  background-color: transparent !important;\n  color: #2d866e !important;\n  color: var(--c-brand) !important;\n}\n\n.app-bundles-Questionnaire2-Question-___styles__readMoreBtn___34SWZ:focus i,\n.app-bundles-Questionnaire2-Question-___styles__readMoreBtn___34SWZ:hover i {\n  color: #2d866e !important;\n  color: var(--c-brand) !important;\n}\n", "", {"version":3,"sources":["/workspace/app/bundles/Questionnaire2/Question/styles.css"],"names":[],"mappings":"AAAA;EACE,4BAA2B;EAC3B,qBAAoB;EACpB,aAAY;EACZ,gBAAe;EACf,mBAAkB;EAClB,WAAU;EACV,yCAAwC;EACxC,WAAU;CACZ;;AAEA;;EAEE,yCAAwC;EACxC,0BAAgC;EAAhC,iCAAgC;CAClC;;AAEA;;EAEE,0BAAgC;EAAhC,iCAAgC;CAClC","file":"styles.css","sourcesContent":[".readMoreBtn {\n  box-shadow: none !important;\n  border: 0 !important;\n  float: right;\n  margin-top: 5px;\n  position: relative;\n  padding: 0;\n  background-color: transparent !important;\n  outline: 0;\n}\n\n.readMoreBtn:focus,\n.readMoreBtn:hover {\n  background-color: transparent !important;\n  color: var(--c-brand) !important;\n}\n\n.readMoreBtn:focus i,\n.readMoreBtn:hover i {\n  color: var(--c-brand) !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"readMoreBtn": "app-bundles-Questionnaire2-Question-___styles__readMoreBtn___34SWZ"
};