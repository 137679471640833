import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { MenuItem } from 'components/MenuList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from 'components/IconButton';
import styles from './index.css';


const EllipsisMenu = ({ domEl, isOpen, menuItems, handleClose, dataTestId }) => (
  <React.Fragment>
    {Boolean(domEl)
    && isOpen && (
      <Menu
        anchorEl={domEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{ disablePadding: true, 'data-test-id': dataTestId }}>
        {menuItems.map(menuItem => (
          <MenuItem
            component={menuItem.linkTo ? Link : 'li'}
            key={menuItem.dataTestId}
            onClick={menuItem.onClick}
            data-test-id={menuItem.dataTestId}
            to={menuItem.linkTo}
            divider={menuItem.divider}>
            { menuItem.icon && (
              <ListItemIcon>
                {menuItem.icon}
              </ListItemIcon>
            )
            }
            <ListItemText inset={!!menuItem.icon} primary={menuItem.text} />
          </MenuItem>
        ))}
      </Menu>
    )}
  </React.Fragment>
);

EllipsisMenu.propTypes = {
  domEl: PropTypes.instanceOf(Element),
  isOpen: PropTypes.bool.isRequired,
  dataTestId: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.node,
    text: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    divider: PropTypes.bool,
    dataTesId: PropTypes.string,
    linkTo: PropTypes.string,
  })).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export const EllipsisMenuButton = React.forwardRef(({ classes, ...otherProps }, ref) => (
  <IconButton
    ref={ref}
    classes={otherProps.isOpen ? { ...classes, root: styles.activeIcon } : classes}
    {...otherProps}>
    <MoreVertIcon />
  </IconButton>
));

EllipsisMenuButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

export default EllipsisMenu;
