export default [
  { label: '8 px', value: 8 },
  { label: '9 px', value: 9 },
  { label: '10 px', value: 10 },
  { label: '11 px', value: 11 },
  { label: '12 px', value: 12 },
  { label: '13 px', value: 13 },
  { label: '14 px', value: 14 },
  { label: '15 px', value: 15 },
  { label: '16 px', value: 16 },
  { label: '17 px', value: 17 },
  { label: '18 px', value: 18 },
  { label: '19 px', value: 19 },
  { label: '20 px', value: 20 },
];
