import update from 'immutability-helper';
import { createReducer } from 'reduxsauce';
import { Types } from './actions';

export const initialState = { appInitialized: false, v2Experience: false };

const setAppInitialized = state => update(state, { appInitialized: { $set: true } });

const setV2Experience = state => update(state, { v2Experience: { $set: true } });

const setWhiteLabelCompanyUrl = (state, { whiteLabelCompanyUrl }) => update(state, { whiteLabelCompanyUrl: { $set: whiteLabelCompanyUrl } });

export default createReducer(initialState, {
  [Types.INIT_APP_SUCCESS]: setAppInitialized,
  [Types.SET_V2_EXPERIENCE]: setV2Experience,
  [Types.SET_WHITE_LABEL_COMPANY_URL]: setWhiteLabelCompanyUrl,
});
