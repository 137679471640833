import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Switch from 'components/form/Switch';
import PublishModal from './PublishModal';
import withPublishUnpublishHoc from './withPublishUnpublishHoc';
import { PublishUnpublishToggleWrapper } from './styled-components';

class PublishUnpublishToggle extends Component {
  static propTypes = {
    toggleModal: PropTypes.func,
    templateStatus: PropTypes.string.isRequired,
    unpublishTemplate: PropTypes.func.isRequired,
  };

  onSwitchClick = () => {
    const { templateStatus, toggleModal, unpublishTemplate } = this.props;
    const isPublished = templateStatus === 'published';
    if (isPublished) {
      unpublishTemplate();
      return;
    }
    toggleModal();
  };

  render() {
    const { templateStatus } = this.props;
    const isPublished = templateStatus === 'published';
    const showStatus = !templateStatus.match(/^draft|^unpublished/) && !isPublished;

    if (showStatus) {
      return <div>{templateStatus}</div>;
    }
    return (
      <PublishUnpublishToggleWrapper data-test-id="toggle-publish-button">
        <Switch checked={isPublished} onChange={this.onSwitchClick} />
        <PublishModal {...this.props} />
      </PublishUnpublishToggleWrapper>
    );
  }
}

export default withPublishUnpublishHoc(PublishUnpublishToggle);
