exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-containers-Error500-___index__component___JfOAM {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.app-containers-Error500-___index__logo___x1kL1 {\n  width: 280px;\n  height: 90px;\n  margin-bottom: 45px;\n}\n\n.app-containers-Error500-___index__picture___YRkTS {\n  max-width: 300px;\n  margin-bottom: 45px;\n}\n", "", {"version":3,"sources":["/workspace/app/containers/Error500/index.css"],"names":[],"mappings":"AAAA;EACE,QAAO;EACP,cAAa;EACb,uBAAsB;EACtB,oBAAmB;EACnB,wBAAuB;CACzB;;AAEA;EACE,aAAY;EACZ,aAAY;EACZ,oBAAmB;CACrB;;AAEA;EACE,iBAAgB;EAChB,oBAAmB;CACrB","file":"index.css","sourcesContent":[".component {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.logo {\n  width: 280px;\n  height: 90px;\n  margin-bottom: 45px;\n}\n\n.picture {\n  max-width: 300px;\n  margin-bottom: 45px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-containers-Error500-___index__component___JfOAM",
	"logo": "app-containers-Error500-___index__logo___x1kL1",
	"picture": "app-containers-Error500-___index__picture___YRkTS"
};