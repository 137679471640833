import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Button, { GHOST } from 'components/Button';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import {
  HelpTextButtonWrapper,
  HelpTextContainer,
  HelpTextHelpContent,
  HelpTextWrapper,
} from './styled-components';
import styles from './styles.css';

class HelpText extends PureComponent {
  static propTypes = {
    helpText: PropTypes.string,
    intl: intlShape.isRequired,
  };

  helpTextWrapper = React.createRef();

  state = {
    showFullText: true,
    hasHelpTextOverflow: false,
    maxHeight: '',
  };

  componentDidMount() {
    this.setHelpTextOverflow();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.helpText !== this.props.helpText) {
      this.setHelpTextOverflow();
    }
  }

  setHelpTextOverflow = () => {
    const { helpText } = this.props;
    const hasHelpTextOverflow = helpText.length > 300; // "magic" number for when the help text is long enough to show the read more btn
    this.setState({ hasHelpTextOverflow, maxHeight: '100vh' });
  }

  onClick = () => this.setState(state => ({ showFullText: !state.showFullText }));

  render() {
    const { intl, helpText } = this.props;
    const { showFullText, hasHelpTextOverflow, maxHeight } = this.state;
    const btnText = showFullText
      ? intl.formatMessage({ id: 'questionnaire.questions.help_text.hide' })
      : intl.formatMessage({ id: 'questionnaire.questions.help_text.show' });
    const icon = showFullText ? <KeyboardArrowUp /> : <KeyboardArrowDown />;
    return (
      <HelpTextContainer>
        <HelpTextHelpContent>
          <HelpTextWrapper
            dangerouslySetInnerHTML={{ __html: helpText }}
            showFullText={showFullText}
            maxHeight={maxHeight}
            innerRef={this.helpTextWrapper} />
          {hasHelpTextOverflow && (
            <HelpTextButtonWrapper>
              <Button className={styles.readMoreBtn} onClick={this.onClick} variant={GHOST}>
                {btnText}
                {icon}
              </Button>
            </HelpTextButtonWrapper>
          )}
        </HelpTextHelpContent>
      </HelpTextContainer>
    );
  }
}

export default injectIntl(HelpText);
