export const apiPaths = {
  DEFAULT: '/api/panel',
  WHITE_LABEL: '/api/white_labels',
  // TODO: Set default api/v1 path prefix for all modes, when backend is ready, api/v1 will be default api path prefix for every request.
  GENERIC_ACCESS: '/api/v1',
  V1: '/api/v1',
};

export const accessTypes = {
  DEFAULT: 'Panel',
  WHITE_LABEL: 'WhiteLabel',
  GENERIC_ACCESS: 'GenericAccess',
  V1: 'Panel',
};
