import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { localeOptions, dateFormatOptions } from 'shared/date';
import { Creators } from 'pages/Templates/reducer';
import Modal, { ModalContent, ModalFooter } from 'components/Modal';
import Typography from 'components/Typography';
import Option from 'shared/Components/Form/Select/Option';
import Button, { SECONDARY } from 'components/Button';
import InfoBox from 'components/InfoBox';
import PublishTemplate from 'shared/PublishUnpublish/PublishTemplate';
import { DRAFT } from 'shared/constants/questionnaireTemplateStatus';
import { getTemplatesListPath } from 'MainApp/routesPaths';
import { PRIMARY } from 'bundles/shared/constants/components/buttonTypes';
import modalStyles from 'components/Modal/index.css';
import {
  Wrapper,
  FieldWrapper,
  FooterWrapper,
  StyledSelect,
} from './styled-components';
import { SigningSettings } from './SigningSettings';
import { templateSettingsFactory } from './templateSettingsFactory';

class TemplatePropertiesModal extends React.Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    templateVersionId: PropTypes.string.isRequired,
    templateSettings: PropTypes.shape({
      date_format: PropTypes.string,
      document_highlighting_enabled: PropTypes.bool,
      edit_document_enabled: PropTypes.bool,
      language: PropTypes.string,
      sender_has_to_sign: PropTypes.bool,
      signing_enabled: PropTypes.bool,
      signing_methods: PropTypes.arrayOf(PropTypes.string),
      signing_order: PropTypes.string,
      time_zone: PropTypes.string,
    }),
    templateStatus: PropTypes.string,
    publishFromTemplateList: PropTypes.bool,
    templateVersionChangeNote: PropTypes.string,
    esigningModule: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool,
    setTemplateSettings: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = { templateVersionChangeNote: { message: '' }, publishFromTemplateList: false }

  static modalFadeRef;

  static modalContentRef;

  componentDidMount() {
    this.modalFadeRef = React.createRef();

    this.modalContentRef = React.createRef();
  }

  validate = (values = {}) => {
    const errors = {};
    const { intl } = this.props;
    if (values.signing_enabled && values.signing_methods.length === 0) {
      errors.signing_methods = intl.formatMessage({ id: 'template_settings.missing_signing_methods' });
    }
    return errors;
  };

  nonDraftTemplateInfo = () => (<FormattedMessage id="template_settings.update_settings_info_box" />);

  handlePublishModalToggle = (isOpen) => {
    if (isOpen) {
      this.modalFadeRef.current.className = modalStyles.fadeBehind;
      this.modalContentRef.current.className = modalStyles.componentBehind;
    } else {
      this.modalFadeRef.current.className = modalStyles.fade;
      this.modalContentRef.current.className = modalStyles.component;
    }
  };

  isPublishingDisabled = (dirty, isValid) => {
    const { publishFromTemplateList, templateStatus } = this.props;
    if (publishFromTemplateList) {
      if (templateStatus === DRAFT && publishFromTemplateList && isValid) return false;
    }
    return !dirty || !isValid;
  };

  // we need to use window.location.reload here because react-router push method ignores routes matching current location
  redirectTo = this.props.publishFromTemplateList
    ? () => window.location.reload()
    : () => this.props.push(getTemplatesListPath());

  renderPublishButton = (isValid, dirty, values) => {
    const { templateId, templateVersionId, templateStatus, toggleModal } = this.props;
    const templateDetails = { templateId, templateVersionId, templateStatus };
    return (
      <PublishTemplate
        buttonDisabled={this.isPublishingDisabled(dirty, isValid)}
        buttonVariant={PRIMARY}
        isPatchAndPublish
        templateSettings={values}
        handleToggleModal={this.handlePublishModalToggle}
        toggleParentModal={toggleModal}
        templateDetails={templateDetails}
        redirectTo={this.redirectTo} />
    );
  };

  renderPublishOrSaveButton = (isValid, dirty, handleSubmit, values) => {
    const { templateStatus, inProgress } = this.props;
    return (
      <>
        { templateStatus === DRAFT ? (
          <Button
            disabled={!isValid}
            loading={inProgress}
            onClick={handleSubmit}
            testId="template-settings-save-button">
            <FormattedMessage id="template_settings.save" />
          </Button>
        ) : (
          this.renderPublishButton(isValid, dirty, values)
        )}
      </>
    );
  };

  render() {
    const {
      open,
      toggleModal,
      templateVersionId,
      templateSettings,
      setTemplateSettings,
      intl,
      esigningModule,
      templateStatus,
      publishFromTemplateList,
    } = this.props;


    // Sometimes when the TemplateSettingsModal is rendered there is no templateSettings object,
    // because it’s parent didn’t received it yet from API
    if (!open) {
      return null;
    }

    return (
      <Modal
        open
        toggleModal={toggleModal}
        modalTitle={<FormattedMessage id="template_settings.title" />}
        testId="template-settings-popup"
        modalFadeRef={this.modalFadeRef}
        modalContentRef={this.modalContentRef}>
        <Formik
          enableReinitialize
          initialValues={{
            ...templateSettings,
            sender_has_to_sign: templateSettings.sender_has_to_sign.toString(),
          }}
          validate={this.validate}
          isInitialValid
          onSubmit={(values) => {
            setTemplateSettings(templateVersionId, values);
            toggleModal();
          }}>
          {/* eslint-disable camelcase */}
          {({
            values,
            handleSubmit,
            errors,
            isValid,
            dirty,
            setFieldValue,
          }) => (
            <Form noValidate>
              <ModalContent>
                <Wrapper>
                  <FieldWrapper>
                    <Field name="language">
                      {({ field }) => (
                        <Fragment>
                          <Typography variant="label">
                            <FormattedMessage id="template_settings.locale" />
                          </Typography>
                          <StyledSelect
                            {...field}
                            fullWidth
                            testId="template-settings-date-local-button">
                            {localeOptions(intl).map(option => (
                              <Option key={option.value} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </StyledSelect>
                        </Fragment>
                      )}
                    </Field>
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field name="date_format">
                      {({ field }) => (
                        <Fragment>
                          <Typography variant="label">
                            <FormattedMessage id="template_settings.date_format" />
                          </Typography>
                          <StyledSelect
                            {...field}
                            fullWidth
                            testId="template-settings-date-format-button">
                            {dateFormatOptions(values.language).map(option => (
                              <Option key={option.value} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </StyledSelect>
                        </Fragment>
                      )}
                    </Field>
                  </FieldWrapper>
                </Wrapper>
                {templateSettingsFactory(templateSettings).map(
                  SettingsComponent => (
                    <SettingsComponent
                      values={values}
                      key={SettingsComponent.name}
                      esigningModule={esigningModule} />
                  ),
                )}
                {values.signing_enabled && esigningModule && (
                  <SigningSettings
                    errors={errors}
                    setFieldValue={setFieldValue}
                    methods={values.signing_methods} />
                )}
              </ModalContent>
              <ModalFooter>
                {templateStatus !== DRAFT && dirty ? (
                  <FieldWrapper>
                    <InfoBox
                      testId="template-settings-infoBox"
                      content={this.nonDraftTemplateInfo()} />
                  </FieldWrapper>
                ) : null}
                <FooterWrapper>
                  <Button
                    onClick={toggleModal}
                    variant={SECONDARY}
                    testId="template-settings-cancel-button">
                    <FormattedMessage id="template_settings.cancel" />
                  </Button>
                  {publishFromTemplateList
                    ? this.renderPublishButton(isValid, dirty, values)
                    : this.renderPublishOrSaveButton(isValid, dirty, handleSubmit, values)}
                </FooterWrapper>
              </ModalFooter>
            </Form>
          )}
          {/* eslint-enable camelcase */}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  templateVersionId: ownProps.templateVersionId || state.editor.templateVersion.id,
  templateId: ownProps.templateId || state.editor.template.id,
  inProgress: state.templates.inProgress,
  templateSettings: ownProps.templateSettings || state.templates.settings,
  esigningModule: state.questionnaire.company.esigning_module,
  templateStatus: ownProps.templateStatus || state.editor.templateVersion.status,
});

const mapDispatchToProps = {
  setTemplateSettings: Creators.templateSettingsRequest,
  patchAndPublish: Creators.patchAndPublishTemplate,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
)(TemplatePropertiesModal);
