import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FolderModal from './FolderModal';

export class RenameFolderModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onModalClose: PropTypes.func,
    folderId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  };

  onSubmit = (folderName) => {
    this.props.onSubmit(this.props.folderId, folderName);
  };

  render() {
    const { isOpen, name, onModalClose } = this.props;
    return (
      <FolderModal
        modalTitleId="panel.folders.folder_modal.rename"
        name={name}
        onSubmit={this.onSubmit}
        isOpen={isOpen}
        onModalClose={onModalClose} />
    );
  }
}
