import { connect } from 'react-redux';

import TemplatesLibraryTable from '../components/TemplatesLibraryTable';

export const mapStateToProps = state => ({ data: state.templates.templates.data });

export default connect(
  mapStateToProps,
  null,
)(TemplatesLibraryTable);
