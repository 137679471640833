import React from 'react';
import { Parser, ProcessNodeDefinitions } from 'html-to-react';
import renderQuestion from 'Visualization/Editor-v2/utils/renderQuestion';

export function findDirectQuestionNodes(element, result = []) {
  const $$ = tinyMCE.dom.DomQuery; // eslint-disable-line no-undef

  $$(element)
    .children()
    .each((i, el) => {
      if ($$(el).attr('data-question')) {
        result.push(el);
      } else {
        findDirectQuestionNodes(el, result);
      }
    });
  return result;
}

export function performRenderQuestion(questionNodes) {
  const $$ = tinyMCE.dom.DomQuery; // eslint-disable-line no-undef

  questionNodes.forEach((q) => {
    const logicType = q.getAttribute('data-logictype');
    const showif = q.getAttribute('data-showif');
    const replace = q.getAttribute('data-replace');
    const questionType = q.getAttribute('data-question');
    const questionId = q.getAttribute('data-question-id');

    const htmlParser = new Parser();
    const processNodeDefinitions = new ProcessNodeDefinitions(React);
    const processingInstructions = [
      {
        shouldProcessNode: () => true,
        processNode: processNodeDefinitions.processDefaultNode,
      },
    ];
    const children = htmlParser.parseWithInstructions(
      q.innerHTML,
      () => true,
      processingInstructions,
    );

    const props = {
      showif,
      replace,
      logicType,
      questionId,
      isBlock: questionType === 'block',
      children,
      parentCount: $$(q).parents('[data-question]').length,
    };
    const tagType = showif ? 'showif' : 'replace';
    renderQuestion(props, questionType, tagType, q.parentElement);
  });
}
