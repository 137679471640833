import React from 'react';
import MobileView from 'components/MobileView';

import DesktopNavigationBar from './DesktopNavigationBar';
import MobileNavigationBar from './MobileNavigationBar';

function NavigationBar() {
  return (
    <MobileView>
      {({ isMobile }) => (
        isMobile ? <MobileNavigationBar /> : <DesktopNavigationBar />
      )}
    </MobileView>
  );
}

export default NavigationBar;
