import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import calculatePosition from 'Visualization/helpers/calculatePosition';
import RangeHelper from 'Visualization/helpers/rangeHelper';

import { Creators as LogicsManagerActions } from '../LogicsManager/LogicsManagerRedux';
import Toolbar from './Toolbar';

class ToolbarContainer extends Component {
  static propTypes = {
    openLogicsManager: PropTypes.func.isRequired,
    selectedContent: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { menu: undefined };
    this.debouncedUpdateMenu = debounce(this.updateMenu, 200);
  }

  componentDidUpdate() {
    if (this.props.selectedContent && this.props.selectedContent.length > 0) {
      this.debouncedUpdateMenu();
    } else {
      this.state.menu && this.state.menu.removeAttribute('style'); // eslint-disable-line no-unused-expressions
    }
  }

  onOpen = (portalObject) => {
    this.setState({ menu: portalObject.firstChild });
  };

  openQuestionManager = (kind) => {
    this.props.openLogicsManager(kind, {});
  };

  updateMenu = () => {
    const { menu } = this.state;
    if (!menu) return;
    const rangeHelper = new RangeHelper();
    if (!rangeHelper.selectionLength()) {
      menu.removeAttribute('style');
    } else {
      setTimeout(() => {
        // hack to move this check to the end of call stack
        // https://www.quora.com/What-does-setTimeout-with-a-0ms-delay-do-Is-this-some-trick-to-spawn-a-new-thread-but-why
        const rangeHelper2 = new RangeHelper();
        if (!rangeHelper2.selectionLength()) {
          return menu.removeAttribute('style');
        }

        return null;
      }, 0);

      try {
        const position = calculatePosition({
          widthOffset: menu.offsetWidth / 2,
          heightOffset: menu.offsetHeight - 20,
        });
        menu.style.opacity = 1;
        menu.style.left = `${position.x}px`;
        menu.style.top = `${position.y}px`;
      } catch (err) {
        menu.removeAttribute('style');
      }
    }
  };

  render() {
    return (
      <Toolbar
        onOpen={this.onOpen}
        openQuestionManager={this.openQuestionManager} />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { openLogicsManager: (kind, options) => dispatch(LogicsManagerActions.openLogicsManager(kind, options)) };
}

export default connect(null, mapDispatchToProps)(ToolbarContainer);
