
export default class User {
  constructor(properties = {}) {
    this.avatarUrl = '';
    this.companyId = '';
    this.disabled = false;
    this.email = '';
    this.firstName = '';
    this.id = '';
    this.language = '';
    this.lastName = '';
    this.lastSignInAt = '';
    this.roles = []; // Array<string>
    this.invitationAccepted = false;

    // Only allow existing property names to be modified
    const validProperties = {};
    Object.getOwnPropertyNames(properties).forEach((prop) => {
      if (Object.getOwnPropertyNames(this).indexOf(prop) !== -1) {
        validProperties[prop] = properties[prop];
      }
    });

    Object.assign(this, validProperties);
  }
}
