import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select/';
import map from 'lodash/map';
import { MenuItem } from 'components/MenuList';
import styled from 'styled-components';
import find from 'lodash/find';
import get from 'lodash/get';

const StyledValue = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
class SelectField extends PureComponent {
  onChange = (e) => {
    this.props.field.onChange(e);
  };

  onBlur = (e) => {
    this.props.field.onBlur(e);
  };

  getValue = () => {
    const { dataSource, field } = this.props;
    const value = field.value || '';
    const valueObj = find(dataSource, { value });
    return get(valueObj, 'label') || value;
  };

  render() {
    const { dataSource, field, autoWidth } = this.props;
    const value = field.value || '';

    return (
      <Select
        autoWidth={autoWidth}
        {...field}
        value={value}
        onBlur={this.onBlur}
        renderValue={() => <StyledValue>{this.getValue()}</StyledValue>}
        inputProps={{
          name: field.name,
          id: field.id,
        }}
        onChange={this.onChange}>
        {map(dataSource, item => (
          <MenuItem key={`${field.name}-${item.value}`} value={item.value} selected={value === item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    );
  }
}

SelectField.propTypes = {
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }),
  dataSource: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  })),
  autoWidth: PropTypes.bool,
};

export default SelectField;
