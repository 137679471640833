import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Creators as UploadActions } from 'ducks/upload/actions';

export class EditorFilePickerComponent extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    convert: PropTypes.func.isRequired,
    upload: PropTypes.func.isRequired,
    uploadedFiles: PropTypes.objectOf(PropTypes.string).isRequired,
    convertFileToBase64: PropTypes.bool,
  }

  static defaultProps = { convertFileToBase64: false }

  state = { fileToUpload: null }

  componentDidUpdate(prevProps) {
    const { fileToUpload } = this.state;
    const uploadedFileUrl = this.props.uploadedFiles[fileToUpload];
    if (prevProps.uploadedFiles !== this.props.uploadedFiles && uploadedFileUrl && this.filePickCallback) {
      this.filePickCallback(uploadedFileUrl);
      this.filePickCallback = null;
    }
  }

  onFilePick = (callback) => {
    this.filePickCallback = callback;
    this.createInput();
  }

  createInput = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = this.onUploadChange;
    input.click();
  }

  onUploadChange = (event) => {
    const file = event.target.files[0];
    if (this.props.convertFileToBase64) {
      this.props.convert(file.name, file);
    } else {
      this.props.upload(file.name, file);
    }
    this.setState({ fileToUpload: file.name });
  }

  render() {
    return this.props.children({ onFilePick: this.onFilePick });
  }
}

const mapStateToProps = state => ({ uploadedFiles: state.upload.uploadedFiles });
const mapDispatchToProps = dispatch => ({
  upload: (imageName, file) => dispatch(UploadActions.uploadRequest(imageName, file)),
  convert: (imageName, file) => dispatch(UploadActions.convertRequest(imageName, file)),
});

export const EditorFilePicker = connect(mapStateToProps, mapDispatchToProps)(EditorFilePickerComponent);
