import PropTypes from 'prop-types';

const domElement = (props, propName, componentName) => {
  if (props[propName] && props[propName] instanceof Element === false) {
    return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
  }
  return null;
};

const currentUser = PropTypes.shape({ id: PropTypes.string.isRequired });

const questionScheme = PropTypes.shape({
  id: PropTypes.integer,
  kind: PropTypes.string,
  name: PropTypes.string,
  details: PropTypes.object,
});
const questionsScheme = PropTypes.arrayOf(questionScheme);

const answerScheme = PropTypes.shape({
  id: PropTypes.string,
  kind: PropTypes.string,
  name: PropTypes.string,
  details: PropTypes.object,
});
const answersScheme = PropTypes.arrayOf(answerScheme);

const flowItem = PropTypes.shape({
  children: PropTypes.any,
  optionId: PropTypes.string,
  type: PropTypes.string,
  questionIndex: PropTypes.number,
  questionId: PropTypes.string,
  questionName: PropTypes.string,
});

const optionScheme = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});
const optionsScheme = PropTypes.arrayOf(optionScheme);

const selectQuestionScheme = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  kind: PropTypes.string,
  questionnaire_template_version_id: PropTypes.string,
  details: PropTypes.shape({ options: optionsScheme }),
});

const radioQuestionScheme = selectQuestionScheme;

const inputQuestionScheme = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  kind: PropTypes.string,
  questionnaire_template_version_id: PropTypes.string,
  details: PropTypes.shape({
    id: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    kind: PropTypes.string,
  }),
});

const templateVersionScheme = PropTypes.shape({
  change_note: PropTypes.string,
  folder_breadcrumbs: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  questions: PropTypes.array,
  status: PropTypes.string,
  version: PropTypes.integer,
  wysiwyg_html: PropTypes.string,
});

const folderProperties = {
  id: PropTypes.string,
  name: PropTypes.string,
};

const folder = PropTypes.shape({ ...folderProperties });

export const folderScheme = PropTypes.shape({
  ...folderProperties,
  parents: PropTypes.arrayOf(folder),
});

const templateSettingsScheme = PropTypes.shape({
  language: PropTypes.string.isRequired,
  date_format: PropTypes.string.isRequired,
});

const arrayOfModifiers = modifiersArray => PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
  if (modifiersArray.length > 0 && !modifiersArray.includes(propValue[key])) {
    return new Error(
      `
          Invalid prop '${propFullName}' of value '${propValue[key]}' supplied to '${componentName}'.
          Available values ${JSON.stringify(modifiersArray)}.
        `,
    );
  }

  return undefined;
});

const user = PropTypes.shape({
  avatarUrl: PropTypes.string,
  companyId: PropTypes.string,
  disabled: PropTypes.bool,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  language: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  invitationAccepted: PropTypes.bool,
  lastSignInAt: PropTypes.string,
});

const autocomplete = PropTypes.oneOf(['off', 'new-password']);

export default {
  domElement,
  questionScheme,
  questionsScheme,
  answerScheme,
  answersScheme,
  flowItem,
  optionScheme,
  optionsScheme,
  selectQuestionScheme,
  radioQuestionScheme,
  inputQuestionScheme,
  templateVersionScheme,
  templateSettingsScheme,
  currentUser,
  user,
  arrayOfModifiers,
  autocomplete,
};
