import each from 'lodash/each';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { QUESTION_CLASS } from 'Visualization/helpers/constants';

function removeEmptyQuestionNodes() {
  const questions = document.querySelectorAll(`${QUESTION_CLASS}`);
  const emptyQuestionContainers = document.querySelectorAll('[data-question-container]');
  each(questions, (q) => {
    if (isEmpty(q.textContent)) q.remove();
  });
  each(emptyQuestionContainers, (q) => {
    if (isEmpty(q.textContent)) q.remove();
  });
}

export default debounce(removeEmptyQuestionNodes, 500);
