exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-AnchorType-___question__component___2C-E2 {\n  box-shadow: 0 2px 3px rgba(12, 38, 68, 0.1);\n  border-radius: 3px;\n  border-bottom: 1px solid #dcdcdc;\n  border-bottom: 1px solid var(--c-bw-10);\n  background-color: #fff;\n  background-color: var(--c-bw-00);\n  margin-bottom: 10px;\n}\n\n.app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-AnchorType-___question__title___3noHH {\n  align-self: center;\n}\n\n.app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-AnchorType-___question__header___97dEy {\n  min-height: 42px;\n  display: flex;\n  flex-direction: row;\n}\n\n.app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-AnchorType-___question__index___2N6_I {\n  margin-right: 10px;\n  flex-shrink: 0;\n  width: 32px;\n  height: 32px;\n  background-color: #2d866e;\n  background-color: var(--c-brand);\n  color: #f3f3f3;\n  color: var(--c-bw-05);\n}\n", "", {"version":3,"sources":["/workspace/app/bundles/Visualization/Editor-v2/LogicsManager/LogicsTypes/AnchorType/question.css"],"names":[],"mappings":"AAAA;EACE,4CAA2C;EAC3C,mBAAkB;EAClB,iCAAuC;EAAvC,wCAAuC;EACvC,uBAAgC;EAAhC,iCAAgC;EAChC,oBAAmB;CACrB;;AAEA;EACE,mBAAkB;CACpB;;AAEA;EACE,iBAAgB;EAChB,cAAa;EACb,oBAAmB;CACrB;;AAEA;EACE,mBAAkB;EAClB,eAAc;EACd,YAAW;EACX,aAAY;EACZ,0BAAgC;EAAhC,iCAAgC;EAChC,eAAqB;EAArB,sBAAqB;CACvB","file":"question.css","sourcesContent":[".component {\n  box-shadow: 0 2px 3px rgba(12, 38, 68, 0.1);\n  border-radius: 3px;\n  border-bottom: 1px solid var(--c-bw-10);\n  background-color: var(--c-bw-00);\n  margin-bottom: 10px;\n}\n\n.title {\n  align-self: center;\n}\n\n.header {\n  min-height: 42px;\n  display: flex;\n  flex-direction: row;\n}\n\n.index {\n  margin-right: 10px;\n  flex-shrink: 0;\n  width: 32px;\n  height: 32px;\n  background-color: var(--c-brand);\n  color: var(--c-bw-05);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"component": "app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-AnchorType-___question__component___2C-E2",
	"title": "app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-AnchorType-___question__title___3noHH",
	"header": "app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-AnchorType-___question__header___97dEy",
	"index": "app-bundles-Visualization-Editor-v2-LogicsManager-LogicsTypes-AnchorType-___question__index___2N6_I"
};