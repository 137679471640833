import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';
import { Types } from './actions';
import User from '../../models/User';

export const initialState = {

  appType: '',

  user: new User(),
  isFetchingUser: false,
  isUpdatingUser: false,
  hasFetchedUser: false,
  error: null,
  isUserAuthorized: false,

  signIn: { data: {} },
  auth: {},
  form: { personalInformation: {}, password: {} },
};

const fetchProfileRequest = state => update(state, { isFetchingUser: { $set: true } });
// Esentually a sign out if the user fails to fetch
const fetchProfileFailure = (state, { error }) => update(state, {
  user: { $set: new User() },
  error: { $set: error },
  isFetchingUser: { $set: false },
  hasFetchedUser: { $set: true },
});

const changeLanguageRequest = state => update(state, { user: { isUpdatingUser: { $set: true } } });
const changeLanguageSuccess = (state, { language }) => update(state, { user: { $merge: { language }, isUpdatingUser: { $set: false } } });
const changeLanguageFailure = state => update(state, { isUpdatingUser: { $set: false } });

const userUpdating = state => update(state, { isUpdatingUser: { $set: true } });
const userUpdated = (state, { user }) => update(state, {
  user: { $set: new User(user) },
  isUpdatingUser: { $set: false },
});

const signInFailure = (state, { error }) => update(state, {
  error: { $set: error },
  isUserAuthorized: { $set: false },
});

const signInSuccess = state => update(state, { isUserAuthorized: { $set: true } });

const resetPasswordRequest = state => update(state, { isUpdatingUser: { $set: true } });
const resetPasswordSuccess = state => update(state, { isUpdatingUser: { $set: false } });

const setUser = (state, { user }) => update(state, {
  user: { $set: new User(user) },
  isFetchingUser: { $set: false },
  hasFetchedUser: { $set: true },
});

const signOut = state => update(state, {
  user: { $set: new User() },
  isUserAuthorized: { $set: false },
  hasFetchedUser: { $set: false },
});
const setAppType = (state, { appType }) => update(state, { appType: { $set: appType } });
const setUserIsAuthorized = (state, { isUserAuthorized }) => update(
  state, { isUserAuthorized: { $set: isUserAuthorized } },
);

const generalFailure = (state, { error }) => update(state, { error: { $set: error }, isUpdatingUser: { $set: false } });

export default createReducer(initialState, {
  [Types.FETCH_PROFILE_REQUEST]: fetchProfileRequest,
  [Types.FETCH_PROFILE_SUCCESS]: setUser,
  [Types.FETCH_PROFILE_FAILURE]: fetchProfileFailure,

  [Types.CHANGE_LANGUAGE_REQUEST]: changeLanguageRequest,
  [Types.CHANGE_LANGUAGE_SUCCESS]: changeLanguageSuccess,
  [Types.CHANGE_LANGUAGE_FAILURE]: changeLanguageFailure,

  [Types.CHANGE_PASSWORD_REQUEST]: userUpdating,
  [Types.CHANGE_PASSWORD_SUCCESS]: userUpdated,
  [Types.CHANGE_PASSWORD_FAILURE]: generalFailure,

  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.CHANGE_PASSWORD_FAILURE]: generalFailure,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILURE]: signInFailure,

  [Types.CHANGE_PERSONAL_INFORMATION_REQUEST]: userUpdating,
  [Types.CHANGE_PERSONAL_INFORMATION_SUCCESS]: userUpdated,
  [Types.CHANGE_PERSONAL_INFORMATION_FAILURE]: generalFailure,

  [Types.SET_APP_TYPE]: setAppType,
  [Types.SET_IS_USER_AUTHORIZED]: setUserIsAuthorized,

  [Types.SIGN_OUT]: signOut,
});
