import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import QuestionContainerForm from 'containers/QuestionContainer';
import QuestionnaireIntroduction from 'components/QuestionnaireIntroduction';
import {
  QuestionnaireWithProgressBar,
  QuestionnaireWrapper,
  QuestionnaireContent,
} from './styled-components';
import PreviousQuestion from './PreviousQuestion';
import ProgressBar from './ProgressBar/Containers/ProgressBarContainer';

export class QuestionnaireComponent extends PureComponent {
  static propTypes = {
    isPreviewVisible: PropTypes.bool.isRequired,
    toggleLeftToolbar: PropTypes.func.isRequired,
    isLeftToolbarVisible: PropTypes.bool.isRequired,
    finished: PropTypes.bool.isRequired,
    documentName: PropTypes.string,
    isIntroductionVisible: PropTypes.bool.isRequired,
    hideIntroduction: PropTypes.func.isRequired,
  };

  render() {
    const {
      isPreviewVisible,
      toggleLeftToolbar,
      isLeftToolbarVisible,
      finished,
      documentName,
      hideIntroduction,
      isIntroductionVisible,
      signingEnabled,
    } = this.props;
    return (
      <QuestionnaireWithProgressBar
        isPreviewVisible={isPreviewVisible}
        isLeftToolbarVisible={isLeftToolbarVisible}>
        <ProgressBar
          onProgressClick={toggleLeftToolbar}
          isLeftToolbarVisible={isLeftToolbarVisible}
          hideProgressBar={finished} />
        <QuestionnaireWrapper>
          <QuestionnaireContent>
            {isIntroductionVisible ? (
              <QuestionnaireIntroduction
                onStartClick={hideIntroduction}
                documentName={documentName}
                signingEnabled={signingEnabled} />
            ) : (
              <>
                <PreviousQuestion />
                <QuestionContainerForm />
              </>
            )}
          </QuestionnaireContent>
        </QuestionnaireWrapper>
      </QuestionnaireWithProgressBar>
    );
  }
}

export default QuestionnaireComponent;
