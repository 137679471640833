import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'components/Tooltip';
import Button, { PRIMARY } from 'components/Button';
import { Img } from './styled-components';

const SubmitButton = ({ onClick, disabled }) => (
  disabled
    ? (
      <Tooltip title={<FormattedMessage id="api.panel.questionnaire_templates.already_in_folder" />}>
        <Button
          fullWidth
          testId="MoveTo-moveHereButton"
          variant={PRIMARY}
          disabled>
          <Img src="/assets/icons/folder-arrow.svg" />
          <FormattedMessage id="api.panel.questionnaire_templates.actions.move" />
        </Button>
      </Tooltip>
    ) : (
      <Button
        onClick={onClick}
        fullWidth
        testId="MoveTo-moveHereButton"
        variant={PRIMARY}>
        <Img src="/assets/icons/folder-arrow.svg" />
        <FormattedMessage id="api.panel.questionnaire_templates.actions.move" />
      </Button>
    )
);

SubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SubmitButton.defaultProps = { disabled: false };

export default SubmitButton;
