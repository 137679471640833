import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FieldRow from 'components/form/FieldRow';
import InputCheckbox from 'components/form/InputCheckbox';
import Label from 'components/form/Label';

const StyledCheckboxInput = styled.div`
  margin-top: 10px;
  .small {
    display: inline-block;
    margin-top: 0;
    font-size: 90%;
    color: ${props => props.theme.font.light};
  }
`;

const CheckboxInput = ({ label, labelCaption, value, name, onChangeFn }) => (
  <StyledCheckboxInput>
    <FieldRow>
      <Label>{label}</Label>
      <InputCheckbox
        testId="LogicsManager-allowMultiple"
        checked={value === 'checkbox'}
        onChange={onChangeFn}
        name={name}
        label={(
          <span className="small">
            {' '}
            {labelCaption}
          </span>
        )} />
    </FieldRow>
  </StyledCheckboxInput>
);

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChangeFn: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  labelCaption: PropTypes.string.isRequired,
};

CheckboxInput.defaultProps = { value: '' };

export default CheckboxInput;
