import assert from 'assert';
import { put, call, take } from 'redux-saga/effects';
import _get from 'lodash/get';
import { snakeToCamelCase } from 'shared/helpers/transforms';
import { push } from 'react-router-redux';
import { DRAFT } from 'shared/constants/questionnaireTemplateStatus';
import { GENERIC_ACCESS } from 'shared/constants/questionnaireModes';
import { Creators as QuestionnaireActions } from 'ducks/questionnaire/actions';
import { Creators, Types } from '../reducer';

export default function createSagas({ templatesService, documentService, notificationService } = {}) {
  assert.ok(templatesService, 'templatesService is required');
  assert.ok(documentService, 'documentService is required');
  assert.ok(notificationService, 'notificationService is required');

  function* publishTemplateSaga() {
    while (true) {
      const { templateId, templateVersionId, formData, redirectTo } = yield take(Types.PUBLISH_TEMPLATE);
      try {
        yield call([templatesService, templatesService.publishQuestionnaireTemplateVersion], templateVersionId, formData);
        if (redirectTo) {
          redirectTo();
        }
        if (templateId) {
          yield put(Creators.fetchTemplatesVersionHistoryRequest(templateId));
        }
        yield put(Creators.publishTemplateSuccess());
      } catch (e) {
        yield put(Creators.publishTemplateFailure(e));
      }
    }
  }
  function* getUpdatableTemplateVersionId(templateStatus, templateVersionId, templateId) {
    if (templateStatus === DRAFT) {
      return templateVersionId;
    }
    const { data: { questionnaire_template_version: { id: newQuestionnaireTemplateVersionId } } } = yield call([templatesService, templatesService.createNewTemplateVersion], templateId);
    return newQuestionnaireTemplateVersionId;
  }
  function* patchAndPublishTemplateSaga() {
    while (true) {
      const { templateDetails, templateSettings, changeNote, redirectTo } = yield take(Types.PATCH_AND_PUBLISH_TEMPLATE);
      const { templateId, templateVersionId, templateStatus } = templateDetails;
      try {
        const nextTemplateVersionId = yield getUpdatableTemplateVersionId(templateStatus, templateVersionId, templateId);
        yield call([templatesService, templatesService.patchTemplateSettings], nextTemplateVersionId, templateSettings);
        yield call([templatesService, templatesService.publishQuestionnaireTemplateVersion], nextTemplateVersionId, changeNote);

        if (redirectTo) {
          redirectTo();
        }
        yield put(Creators.patchAndPublishTemplateSuccess());
      } catch (e) {
        yield put(Creators.patchAndPublishTemplateFailure(e));
      }
    }
  }


  function* unpublishTemplateSaga() {
    while (true) {
      const { templateId, templateVersionId, redirectTo } = yield take(Types.UNPUBLISH_TEMPLATE);
      try {
        yield call([templatesService, templatesService.unpublishQuestionnaireTemplateVersion], templateVersionId);
        if (redirectTo) {
          redirectTo();
        }
        yield put(Creators.fetchTemplatesVersionHistoryRequest(templateId));
        yield put(Creators.unpublishTemplateSuccess());
      } catch (e) {
        yield put(Creators.unpublishTemplateFailure(e));
      }
    }
  }

  function* toggleSharingTemplateFromListSaga() {
    while (true) {
      const { sharingActive, templateId } = yield take(Types.TOGGLE_SHARING_TEMPLATE_FROM_LIST_REQUEST);
      try {
        yield call([templatesService, templatesService.updateTemplate], templateId, { questionnaire_template: { sharing_active: sharingActive } });
        yield put(Creators.toggleSharingTemplateFromListSuccess());
      } catch (e) {
        yield put(Creators.toggleSharingTemplateFromListFailure(e, templateId, !sharingActive));
      }
    }
  }

  function* validateSharingTemplate() {
    while (true) {
      const { templateSharingSlug } = yield take(Types.VALIDATE_SHARING_TEMPLATE_REQUEST);
      try {
        const { data: { questionnaire_template: { name, company } } } = yield call([templatesService, templatesService.validateTemplate], templateSharingSlug);
        yield put(Creators.validateSharingTemplateSuccess(templateSharingSlug, name));
        yield put(QuestionnaireActions.fetchCompanySuccess(company));
        yield put(QuestionnaireActions.setMode(GENERIC_ACCESS));
      } catch (e) {
        yield put(push('/template/error'));
        yield put(Creators.validateSharingTemplateFailure(e));
      }
    }
  }

  function* templateSettingsSaga() {
    while (true) {
      const { templateId, settings } = yield take(Types.TEMPLATE_SETTINGS_REQUEST);
      try {
        yield put(Creators.templateSettingsProgress(true));
        yield call([templatesService, templatesService.patchTemplateSettings], templateId, settings);
        yield put(Creators.templateSettingsSuccess(settings));
      } catch (e) {
        yield put(Creators.templateSettingsFailure(e));
      }
      yield put(Creators.templateSettingsProgress(false));
    }
  }

  function* createNewTemplateSaga() {
    while (true) {
      const { folderId } = yield take(Types.CREATE_NEW_TEMPLATE);
      try {
        const { data } = yield call([templatesService, templatesService.createNewTemplate], folderId);
        const { questionnaireTemplate } = snakeToCamelCase(data);
        yield put(Creators.createNewTemplateSuccess(questionnaireTemplate));
        yield put(push(`/templates/${questionnaireTemplate.id}/edit`));
      } catch (e) {
        yield put(Creators.createNewTemplateFailure(e));
      }
    }
  }

  function* deleteDocumentSaga() {
    while (true) {
      const { id } = yield take(Types.DELETE_DOCUMENT_REQUEST);
      try {
        const { data: { id: trashId } } = yield call([documentService, documentService.deleteDocument], id);
        yield put(Creators.deleteDocumentSuccess(id));
        yield put(notificationService.info({
          message: 'shared.statuses.moved_to_trash',
          action: {
            label: 'shared.statuses.undo',
            callback: Creators.restoreFromTrashRequest(trashId),
          },
          position: 'bl',
          useTranslate: true,
        }));
      } catch (e) {
        const errorMessage = _get(e, 'data.message', 'generic');
        yield put(Creators.deleteDocumentFailure(e));
        yield put(notificationService.error({
          message: `shared.errors.${errorMessage}`,
          position: 'bl',
          useTranslate: true,
        }));
      }
    }
  }

  return {
    publishTemplateSaga,
    patchAndPublishTemplateSaga,
    unpublishTemplateSaga,
    toggleSharingTemplateFromListSaga,
    validateSharingTemplate,
    templateSettingsSaga,
    createNewTemplateSaga,
    deleteDocumentSaga,
  };
}
