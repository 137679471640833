import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FormattedMessage } from 'react-intl';

import TemplateTableRow from '../TableRows/TemplateHistoryTableRow';

class TemplateHistoryTable extends PureComponent {
  static propTypes = {
    fetchTemplatesVersionHistoryRequest: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({
      version: PropTypes.number,
      isPublished: PropTypes.bool,
    })),
    unpublishTemplate: PropTypes.func,
    publishTemplate: PropTypes.func,
    match: PropTypes.shape({ params: PropTypes.shape({ templateId: PropTypes.string }) }),
  };

  state = { templateId: '' };

  componentDidMount() {
    const {
      match: { params },
      fetchTemplatesVersionHistoryRequest,
    } = this.props;
    fetchTemplatesVersionHistoryRequest(params.templateId);
    this.state.templateId = params.templateId;
  }

  render() {
    const { data, match } = this.props;
    const { templateId } = this.state;
    return (
      <Table data-test-id="TemplateHistoryTable-table">
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="panel.questionnaire_templates.template_versions.date" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="panel.questionnaire_templates.template_versions.version" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="panel.questionnaire_templates.template_versions.publish" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="panel.questionnaire_templates.template_versions.change" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="panel.questionnaire_templates.template_versions.created_by" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((templateVersion, index) => (
            <TemplateTableRow
              index={index}
              templateId={templateId}
              templateVersion={templateVersion}
              key={templateVersion.id}
              publishTemplate={this.props.publishTemplate}
              unpublishTemplate={this.props.unpublishTemplate}
              editRoute={`${match.url}/edit`}
              previewRoute={`${match.url}/preview?version=${templateVersion.version}`} />
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default TemplateHistoryTable;
