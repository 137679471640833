import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Folder from '@material-ui/icons/Folder';
import Popover from '@material-ui/core/Popover';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { MenuList, MenuItem } from 'components/MenuList';
import Typography from 'components/Typography';

import styles from './index.css';

class Index extends PureComponent {
  state = { open: false, anchorEl: undefined };

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { paths } = this.props;
    const { open, anchorEl } = this.state;

    return (
      <div
        className={styles.wrapper}
        ref={(el) => { this.setState({ anchorEl: el }); }}>
        <div className={styles.dropDownWrapper}>
          <Typography variant="h3" onClick={this.handleClick} className={styles.dropDownHandle}>
            ...
          </Typography>
        </div>
        <Popover
          classes={{ paper: styles.popOver }}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          elevation={4}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: -18,
            horizontal: 0,
          }}>
          <MenuList>
            {paths.map(p => (
              <Link to={p.route} key={p.route} data-test-id="CompressedBreadcrumbs-menuLink" className={styles.menuLink} onClick={this.handleClose}>
                <MenuItem classes={{ root: styles.menuItem }}>
                  <ListItemIcon classes={{ root: styles.listItemIcon }}>
                    <Folder />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ root: styles.listItemText }}
                    inset
                    primary={(
                      <Typography variant="bodySmall">
                        { p.title}
                      </Typography>
                  )} />
                </MenuItem>
              </Link>
            ))}
          </MenuList>
        </Popover>
      </div>
    );
  }
}

Index.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.shape({
    route: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  classes: PropTypes.shape({ paper: PropTypes.string }),
};

export default Index;
