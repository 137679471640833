import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _get from 'lodash/get';
import { withAcl } from 'services/acl';
import InfoNotification from 'components/Notifications/InfoNotification';
import ErrorNotification from 'components/Notifications/ErrorNotification';
import { ERROR, EXPIRED, REJECTED, STARTED, SIGNED, SENT_FOR_SIGNING, COMPLETED } from 'shared/constants/questionnaireStatus';
import {
  questionsEditStartedSelector,
  templatePropertyEditDocumentEnabledSelector,
  selectIsQuestionnairePreview,
} from 'ducks/questionnaire/selectors';
import {
  selectIsCurrentVersionLatest,
  selectCurrentDocumentVersion,
  selectCurrentDocumentRevision,
  selectDocumentStatus,
  selectDocumentErrors,
} from '../selectors';
import styles from './index.css';

const getErrorNotificationProps = ({ userQuestionnaireStatus, documentErrors }) => {
  switch (userQuestionnaireStatus) {
    case EXPIRED:
      return { message: 'questionnaire.document_error.expired', id: EXPIRED };
    case REJECTED: {
      const values = documentErrors.data && {
        comment: documentErrors.data
          .map(documentErrorsObj => documentErrorsObj.comment).join(),
        email: documentErrors.data
          .map(documentErrorsObj => documentErrorsObj.email).join(),
      };
      return { message: 'questionnaire.document_error.rejected', values, id: REJECTED };
    }
    default:
      return {
        message: documentErrors && documentErrors.data
          ? 'questionnaire.document_error.bounced'
          : 'questionnaire.document_error.error',
        id: ERROR,
        values: {
          email:
           documentErrors.data && documentErrors.data.map(bouncedEmail => bouncedEmail.email).join(),
        },
      };
  }
};
const getInfoNotificationProps = ({
  revision,
  isConsumer,
  isCurrentVersionCompleted,
  isQuestionnairePreview,
  isEditEnabled,
  questionsEditStarted,
  userQuestionnaireStatus,
}) => {
  const notificationDictionary = {
    documentPreview: { message: 'questionnaire.document_finished_preview' },
    documentFinished: { message: 'questionnaire.document_finished' },
    documentSentForSigning: { message: 'questionnaire.document_finished_sent_for_signing' },
    documentVersionFinished: {
      message: 'questionnaire.document_finished_version',
      values: { version: revision },
    },
    changedAnswer: { message: 'questionnaire.changed_answer_saved' },
    documentVersionInDraft: {
      message: 'document_details.version_draft.info_message',
      values: { version: revision },
    },
  };

  if (userQuestionnaireStatus === SENT_FOR_SIGNING) return notificationDictionary.documentSentForSigning;
  if (isConsumer() && !isCurrentVersionCompleted) return notificationDictionary.documentVersionInDraft;
  if (isEditEnabled && revision >= 1 && !isQuestionnairePreview) return notificationDictionary.documentVersionFinished;
  if (isQuestionnairePreview) return notificationDictionary.documentPreview;
  if (questionsEditStarted) return notificationDictionary.changedAnswer;

  return notificationDictionary.documentFinished;
};

export const DocumentNotificationComponent = (props) => {
  if (!props.userQuestionnaireStatus || props.userQuestionnaireStatus === SIGNED) return null;

  if ([EXPIRED, REJECTED, ERROR].includes(props.userQuestionnaireStatus)) {
    const errorProps = getErrorNotificationProps(props);
    if (!props.documentErrors.errorType) return null;

    return <ErrorNotification {...errorProps} />;
  }

  const notificationProps = getInfoNotificationProps(props);
  const classes = cn({ [styles.disabledNotification]: !props.isQuestionnairePreview && !props.isCurrentVersionLatest });
  return <InfoNotification {...notificationProps} className={classes} />;
};

DocumentNotificationComponent.propTypes = {
  revision: PropTypes.number.isRequired,
  isConsumer: PropTypes.func.isRequired,
  isCurrentVersionCompleted: PropTypes.bool.isRequired,
  isQuestionnairePreview: PropTypes.bool.isRequired,
  isEditEnabled: PropTypes.bool.isRequired,
  questionsEditStarted: PropTypes.bool.isRequired,
  isCurrentVersionLatest: PropTypes.bool.isRequired,
  userQuestionnaireStatus: PropTypes.oneOf([ERROR, EXPIRED, REJECTED, STARTED, SIGNED, SENT_FOR_SIGNING, COMPLETED]),
  documentErrors: PropTypes.shape({ data: PropTypes.object }),
};

const mapStateToProps = state => ({
  revision: selectCurrentDocumentRevision(state),
  isCurrentVersionCompleted: _get(selectCurrentDocumentVersion(state), 'completed') === true,
  isQuestionnairePreview: selectIsQuestionnairePreview(state),
  questionsEditStarted: questionsEditStartedSelector(state),
  isEditEnabled: templatePropertyEditDocumentEnabledSelector(state),
  isCurrentVersionLatest: selectIsCurrentVersionLatest(state),
  userQuestionnaireStatus: selectDocumentStatus(state),
  documentErrors: selectDocumentErrors(state),
});

export default compose(
  connect(mapStateToProps),
  withAcl,
)(DocumentNotificationComponent);
