import React, { Fragment, PureComponent } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import InputCheckbox from 'components/form/InputCheckbox';
import InputRadio from 'components/form/InputRadio';
import HelpIcon from 'components/HelpIcon';
import {
  Wrapper,
  FieldWrapper,
  RadioLabel,
  StyledTooltip,
  TooltipContent,
  SenderHasToSignWrapper,
  RowWrapper,
  Error,
} from './styled-components';
import { SigningTypeTooltipDescription } from './SigningTypeTooltipDescription';
import styles from './SigningSettings.css';

const signingTypes = [
  { value: 'parallel', label: 'template_settings.parallel' },
  { value: 'sequential', label: 'template_settings.sequential' },
];

const signingServices = [
  { value: 'email', label: 'template_settings.email' },
  { value: 'bankid-no', label: 'template_settings.bankID' },
];

const hasToSignOptions = [
  { value: 'true', label: 'general.yes' },
  { value: 'false', label: 'general.no' },
];

export class SigningSettings extends PureComponent {
  getTooltipContent = () => (
    <TooltipContent>
      <SigningTypeTooltipDescription
        imageSrc="/assets/images/paraell.png"
        title={<FormattedMessage id="template_settings.parallel" />}
        text={<FormattedMessage id="template_settings.parallel_description" />} />
      <SigningTypeTooltipDescription
        imageSrc="/assets/images/sequential.png"
        title={<FormattedMessage id="template_settings.sequential" />}
        text={<FormattedMessage id="template_settings.sequential_description" />} />
    </TooltipContent>
  );

  handleChangeSigningMethod = (e) => {
    const { setFieldValue, methods } = this.props;
    const { value } = e.target;
    const newSigningMethods = methods.includes(value)
      ? [...methods].filter(method => method !== value)
      : [...methods, value];
    setFieldValue('signing_methods', newSigningMethods);
  };

  render() {
    const { errors } = this.props;
    return (
      <Fragment>
        <Wrapper>
          <FieldWrapper>
            <Field
              name="signing_methods"
              render={({ field }) => (
                <RowWrapper>
                  <Typography variant="label">
                    <FormattedMessage id="template_settings.signing_methods" />
                  </Typography>
                  <Tooltip title={<FormattedMessage id="template_settings.signing_methods_tooltip" />}>
                    <HelpIcon />
                  </Tooltip>
                  <FormGroup row classes={{ root: styles.controlsWrapper }}>
                    {signingServices.map(signingService => (
                      <InputCheckbox
                        {...field}
                        key={signingService.value}
                        value={signingService.value}
                        checked={this.props.methods.includes(signingService.value)}
                        onChange={this.handleChangeSigningMethod}
                        testId={`SigningSettings-checkbox-services-${signingService.value}`}
                        label={(
                          <RadioLabel>
                            <FormattedMessage id={signingService.label} />
                          </RadioLabel>
                        )} />
                    ))}
                  </FormGroup>
                  {errors[field.name] && (<Error>{errors[field.name]}</Error>)}
                </RowWrapper>
              )} />
          </FieldWrapper>
        </Wrapper>
        <Field
          name="signing_order"
          render={({ field }) => (
            <Fragment>
              <Typography variant="label">
                <FormattedMessage id="template_settings.signing_order" />
              </Typography>
              <StyledTooltip title={this.getTooltipContent()} placement="right">
                <HelpIcon />
              </StyledTooltip>
              <RadioGroup
                row
                {...field}
                name="signing_order"
                classes={{ root: styles.controlsWrapper }}>
                {signingTypes.map(signingType => (
                  <InputRadio
                    key={signingType.value}
                    value={signingType.value}
                    color="primary"
                    testId={`SigningSettings-checkbox-types-${signingType.value}`}
                    label={(
                      <RadioLabel>
                        <FormattedMessage id={signingType.label} />
                      </RadioLabel>
                    )} />
                ))}
              </RadioGroup>
            </Fragment>
          )} />
        <SenderHasToSignWrapper>
          <Field
            name="sender_has_to_sign"
            render={({ field }) => (
              <Fragment>
                <Typography variant="label">
                  <FormattedMessage id="template_settings.sender_has_to_sign" />
                </Typography>
                <RadioGroup
                  row
                  {...field}
                  name="sender_has_to_sign"
                  classes={{ root: styles.controlsWrapper }}>
                  {hasToSignOptions.map(signOption => (
                    <InputRadio
                      key={signOption.value}
                      value={signOption.value}
                      color="primary"
                      testId={`SigningSettings-checkbox-has-to-${signOption.value}`}
                      label={(
                        <RadioLabel>
                          <FormattedMessage id={signOption.label} />
                        </RadioLabel>
                      )} />
                  ))}
                </RadioGroup>
              </Fragment>
            )} />
        </SenderHasToSignWrapper>
      </Fragment>
    );
  }
}
