import assert from 'assert';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { CONSUMER_ROLE } from 'shared/constants/user/roles';

export default (WrappedComponent) => {
  class WithAcl extends Component {
    hasRole = (roles) => {
      assert.ok(Array.isArray(roles), 'roles<Array> is required');

      return roles.includes(this.props.currentUserRole);
    };

    isConsumer = () => this.hasRole([CONSUMER_ROLE])

    render() {
      return <WrappedComponent {...this.props} hasRole={this.hasRole} isConsumer={this.isConsumer} />;
    }
  }

  const mapStateToProps = ({ loggedInUserState }) => ({ currentUserRole: get(loggedInUserState, 'user.roles.0', null) });

  return connect(mapStateToProps)(WithAcl);
};
