import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';
import { Types } from './actions';
import User from '../../models/User';

export const initialState = { user: new User(), userReinvited: undefined };

const setUser = (state, { user }) => update(state, { user: { $set: new User(user) } });
const reinviteUserRequest = state => update(state, { userReinvited: { $set: undefined } });
const reinviteUserSuccess = state => update(state, { userReinvited: { $set: true } });
const reinviteUserFailure = state => update(state, { userReinvited: { $set: false } });

export default createReducer(initialState, {
  [Types.FETCH_USER_SUCCESS]: setUser,
  [Types.UPDATE_USER_SUCCESS]: setUser,
  [Types.REINVITE_USER_REQUEST]: reinviteUserRequest,
  [Types.REINVITE_USER_SUCCESS]: reinviteUserSuccess,
  [Types.REINVITE_USER_FAILURE]: reinviteUserFailure,
});
