import { call, put, take } from 'redux-saga/effects';
import assert from 'assert';
import { push } from 'connected-react-router';
import { Creators as AuthActionCreators } from 'ducks/loggedInUser/actions';
import { Types, Creators } from './actions';

export default function createSagas({ invitationsService, notificationService }) {
  assert.ok(invitationsService, 'invitationsService is required');
  assert.ok(notificationService, 'notificationService is required');

  function* checkSaga() {
    while (true) {
      const { payload } = yield take(Types.CHECK_INVITATION_REQUEST);
      try {
        const { data } = yield call([invitationsService, invitationsService.checkInvitation], payload.invitationId);
        yield put(Creators.checkInvitationSuccess(data));
      } catch (e) {
        yield put(Creators.checkInvitationFailure(e));
      }
    }
  }

  function* acceptSaga() {
    while (true) {
      try {
        const { payload } = yield take(Types.ACCEPT_INVITATION_REQUEST);

        const { data } = yield call([invitationsService, invitationsService.acceptInvitation], payload);
        yield put(Creators.acceptInvitationSuccess({}));
        yield put(notificationService.success({
          title: 'user_invitations.success.title',
          message: 'user_invitations.success.description',
          position: 'bl',
          useTranslate: true,
        }));
        yield put(AuthActionCreators.signInSuccess(data.access_token, data.refresh_token));
        yield put(push('/'));
      } catch (e) {
        yield put(notificationService.error({
          title: 'user_invitations.failure.title',
          message: 'user_invitations.failure.description',
          position: 'bl',
          useTranslate: true,
        }));
        yield put(Creators.acceptInvitationFailure(e));
      }
    }
  }

  return {
    checkSaga,
    acceptSaga,
  };
}
