import assert from 'assert';

import ApiClient from '../ApiClient';

export default class InvitationsService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  checkInvitation(invitationId) {
    assert.ok(invitationId, 'invitationId is required');
    return this.apiClient.get(`/invitations/${invitationId}`);
  }

  acceptInvitation({ data, token }) {
    assert.ok(data, 'data is required');
    assert.ok(token, 'token is required');

    return this.apiClient.patch(`/invitations/${token}`, { data: { user: data } });
  }
}
