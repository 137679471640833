import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ICONS_URLS } from 'Visualization/helpers/constants';
import { OpacityChanger } from '../styled-components';

function ieMaskPolyfill(isIE, iconUrl) {
  return isIE
    ? `
    background-image: url("${iconUrl}");
    background-size: 13px 13px;
    background-color: transparent;
  `
    : '';
}

const Styled = OpacityChanger.extend`
  position: relative;
  overflow: auto;
  margin: 0 auto;
  height: 100%;
  max-width: 800px;
  width: 100%;
  background-color: #fff;
  color: black;
  box-shadow: 0 1px 3px rgba(0, 41, 87, 0.04);
  padding: 15px;
  strong {
    font-weight: bold;
  }
  table th,
  table td {
    border: 1px solid #ddd;
  }
  h1,
  h2 {
    margin: 0.67rem 0;
  }
  h3,
  h4 {
    margin: 1rem 0;
  }
  [data-question] {
    &.current-question-highlight {
      background-color: #e2f3fd !important;
      color: #509cf7;
      font-weight: bold;
    }
    &.answered-question-highlight {
      background-color: #e9e9e9;
    }
    &:before {
      text-align: center;
      width: 1em;
      height: 1em;
      margin: 0 auto;
      color: black;
      content: "";
      background-color: black;
    }
    &:not(.not_ready):before {
      content: none;
    }
  }
  [data-question="block"] {
    display: block;
    &:before {
      display: block;
      margin: 0 auto;
    }
  }
  [data-question="inline"] {
    display: inline;
    &:before {
      display: inline-block;
    }
  }
  [data-logictype="inputReplaceType"] {
    background-color: #fffab2;
    &:before {
      mask: url(${ICONS_URLS.inputReplaceType});
      mask-size: cover;
      ${({ isIE }) => ieMaskPolyfill(isIE, ICONS_URLS.inputReplaceType)}
    }
  }
  [data-logictype="anchorType"] {
    background-color: #fffab2;
    &:before {
      mask: url(${ICONS_URLS.anchorType});
      mask-size: cover;
      ${({ isIE }) => ieMaskPolyfill(isIE, ICONS_URLS.anchorType)}}
    }
  }
  [data-logictype="choiceShowIfType"] {
    background-color: #fffab2;
    &:before {
      mask: url(${ICONS_URLS.choiceShowIfType});
      mask-size: cover;

      ${({ isIE }) => ieMaskPolyfill(isIE, ICONS_URLS.choiceShowIfType)}
    }
  }
`;
class DocumentPreview extends Component {
  static propTypes = {
    html: PropTypes.string,
    isVisible: PropTypes.bool,
  };

  render() {
    const isIE = /* @cc_on!@ */ false || !!document.documentMode;

    return (
      <Styled
        id="document-template"
        isVisible={this.props.isVisible}
        isIE={isIE}
        dangerouslySetInnerHTML={{ __html: this.props.html }} />
    );
  }
}

export default DocumentPreview;
