import React from 'react';

export const ChevronRight = props => (
  <svg
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width={14}
    height={14}
    {...props}>
    <path
      d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"
      vectorEffect="non-scaling-stroke"
      fill="#7783a1" />
  </svg>
);
