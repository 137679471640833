export default function calculatePosition(payload) {
  try {
    const { widthOffset, heightOffset } = payload;
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    if (rect.top === 0 && rect.left === 0) throw Error;
    const left = Math.round(rect.left + window.pageXOffset - widthOffset + rect.width / 2);
    const top = Math.round(rect.top + window.pageYOffset + heightOffset + document.body.scrollTop);

    return {
      x: left > 0 ? left : 0,
      y: top > 0 ? top : 0,
    };
  } catch (err) {
    // if error then return coordinates of the center of screen
    return {
      x: Math.round((window.screen.availWidth - 500) / 2), // width of logicmanager
      y: Math.round((window.screen.availHeight - 500) / 2 + document.body.scrollTop), // 500 is height of logicmanager
    };
  }
}
