import { createReducer } from 'reduxsauce';
import update from 'immutability-helper';
import { Types } from './actions';

export const INITIAL_STATE = {
  data: {
    default_size: 0,
    margin_top: 0,
    margin_right: 0,
    margin_bottom: 0,
    margin_left: 0,
    font_family: '',
    headlines_font_family: '',
    h1_size: 0,
    h1_bold: false,
    h1_italic: false,
    h1_underline: false,
    h2_size: 0,
    h2_bold: false,
    h2_italic: false,
    h2_underline: false,
    h3_size: 0,
    h3_bold: false,
    h3_italic: false,
    h3_underline: false,
    h4_size: 0,
    h4_bold: false,
    h4_italic: false,
    h4_underline: false,
    title_size: 0,
    title_bold: false,
    title_italic: false,
    title_underline: false,
    footer_html: '',
    header_html: '',
    footer_height: 0,
    header_height: 0,
    preview_url: '',
    stylesheet: '',
    section_numbering_enabled: false,
  },
};

const setData = (state, { documentStyles }) => update(state, { data: { $set: documentStyles } });

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_DOCUMENT_STYLES_SUCCESS]: setData,
  [Types.UPDATE_DOCUMENT_STYLES_SUCCESS]: setData,
});
