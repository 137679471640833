import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { withAcl } from 'services/acl';

import { ADMIN_ROLE } from 'shared/constants/user/roles';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import { Trash as TrashIcon } from 'icons/Trash';
import EmptyTrashDialog from './EmptyTrash';
import styles from './styles.css';

class PageHeader extends PureComponent {
  static propTypes = {
    emptyTrash: PropTypes.func,
    intl: intlShape.isRequired,
    hasRole: PropTypes.func.isRequired,
  };

  state = { emptyTrashDialogOpened: false };

  openConfirmDialog = () => {
    this.setState({ emptyTrashDialogOpened: true });
  };

  closeConfirmDialog = () => {
    this.setState({ emptyTrashDialogOpened: false });
  };

  render() {
    const { intl, hasRole } = this.props;
    return (
      <Grid container spacing={0} classes={{ container: styles.pageHeader }}>
        <Grid item xs={12} sm={5}>
          <Breadcrumbs
            icon={<TrashIcon fill="#91a9bd" className={styles.headerTrashIcon} />}
            paths={[{
              title: intl.formatMessage({ id: 'panel.trash.show.trash' }),
              route: '/trash',
            }]} />
        </Grid>
        {hasRole([ADMIN_ROLE])
          && (
            <Fragment>
              <Grid container item xs={12} sm={7} alignItems="center" justify="flex-end">
                <Button
                  glyph={TrashIcon}
                  onClick={this.openConfirmDialog}
                  testId="TrashEmpty-button">
                  <FormattedMessage id="panel.trash.show.empty_trash" />
                </Button>
              </Grid>
              <EmptyTrashDialog
                onClose={this.closeConfirmDialog}
                onDelete={this.props.emptyTrash}
                isOpen={this.state.emptyTrashDialogOpened} />
            </Fragment>
          )
        }
      </Grid>
    );
  }
}

export default compose(withAcl, withRouter, injectIntl)(PageHeader);
