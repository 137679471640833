import { call, take, put } from 'redux-saga/effects';
import assert from 'assert';
import { Types } from './actions';

export default function createSsoSagas({ ssoService, notificationService } = {}) {
  assert.ok(ssoService, 'ssoService is required');
  assert.ok(notificationService, 'notificationService is required');

  function* openFreshdeskSaga() {
    while (true) {
      yield take(Types.OPEN_FRESHDESK);

      try {
        const { data } = yield call([ssoService, ssoService.getFreshdeskUrl]);
        global.location.assign(data.url);
      } catch (e) {
        yield put(notificationService.error({
          message: 'shared.errors.default.message',
          useTranslate: true,
        }));
      }
    }
  }

  return { openFreshdeskSaga };
}
