import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RangeHelper from 'Visualization/helpers/rangeHelper';
import getRelativeCoordinates from 'Visualization/helpers/getRelativeCoordinates';
import {
  findDirectQuestionNodes,
  performRenderQuestion,
} from '../../utils/changeQuestionNodesToReactComponents';
import { BlockElement, InlineElement } from './styled-components';

class Question extends PureComponent {
  static propTypes = {
    isBlock: PropTypes.bool,
    logicType: PropTypes.string.isRequired,
    replace: PropTypes.string,
    showif: PropTypes.string,
    parentCount: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    onIconClick: PropTypes.func.isRequired,
  };

  static defaultProps = { isBlock: false };

  componentDidMount() {
    // we are rerendinging all questions that are nested inside this question
    const questionNodes = findDirectQuestionNodes(this.ref);
    performRenderQuestion(questionNodes);
  }

  onClick = (e) => {
    const { nativeEvent } = e;
    const { isBlock } = this.props;
    const fontSize = this.elementFontSize();
    const coordinates = getRelativeCoordinates(nativeEvent, this.ref);
    if (isBlock) {
      // if question block then icon is on the center of block then,
      // we should only care about y coorinate and allow user to click at any x coorinate
      if (!(coordinates.y <= fontSize)) return;
    } else if (!(coordinates.x <= fontSize && coordinates.y <= fontSize)) {
      return;
    }

    const { onIconClick, logicType, replace, showif } = this.props;
    const optionId = replace || showif;

    const rangeHelper = new RangeHelper();
    rangeHelper.selectElementContents(this.ref);

    onIconClick(logicType, optionId);
  };

  // elementFontSize is used to calculate width of :before icon
  elementFontSize = () => window
    .getComputedStyle(this.ref, null)
    .getPropertyValue('font-size')
    .replace('px', '');

  render() {
    const { children, isBlock } = this.props;
    const WrapperElement = isBlock ? BlockElement : InlineElement;

    /* eslint-disable */
    return (
      <WrapperElement
        onClick={this.onClick}
        {...this.props}
        innerRef={ref => (this.ref = ref)}>
        {children}
      </WrapperElement>
    );
    /* eslint-enable */
  }
}

export default Question;
