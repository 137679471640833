import React from 'react';
import PropTypes from 'prop-types';
import _size from 'lodash/size';

import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Folder from '@material-ui/icons/Folder';

import CompressedBreadcrumbs from './components/CompressedBreadcrumbs';
import LinearBreadcrumbs from './components/LinearBreadcrumbs';

import styles from './index.css';

const ArrowRightIcon = () => <ArrowRight classes={{ root: styles.iconItem }} />;
const Breadcrumbs = ({ paths, icon, maxItems, itemsBeforeCollapse, itemsAfterCollapse }) => {
  if (_size(paths) > 0) {
    paths[paths.length - 1].lastBreadcrumb = true;
  }

  const firstPart = paths.slice(0, itemsBeforeCollapse);
  // eslint-disable-next-line max-len
  const lastPart = _size(paths) > 1 ? paths.slice(_size(paths) - itemsAfterCollapse).filter(x => !firstPart.includes(x)) : undefined;
  const midPart = _size(paths) > 2 ? paths.slice(itemsBeforeCollapse, _size(paths) - itemsAfterCollapse) : undefined;
  const canCollapse = (_size(midPart) > (maxItems - itemsAfterCollapse - itemsBeforeCollapse));

  return (
    <div className={styles.wrapper}>
      {icon}
      <ArrowRightIcon />
      {firstPart && <LinearBreadcrumbs paths={firstPart} />}
      {midPart && !canCollapse && <LinearBreadcrumbs paths={midPart} />}
      {midPart && canCollapse && <CompressedBreadcrumbs paths={midPart} />}
      {midPart && canCollapse && lastPart && <ArrowRightIcon />}
      {lastPart && <LinearBreadcrumbs paths={lastPart} />}
    </div>
  );
};

Breadcrumbs.propTypes = {
  icon: PropTypes.node,
  paths: PropTypes.arrayOf(PropTypes.shape({
    route: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
  maxItems: PropTypes.number,
  itemsBeforeCollapse: PropTypes.number,
  itemsAfterCollapse: PropTypes.number,
};

Breadcrumbs.defaultProps = {
  icon: <Folder classes={{ root: styles.iconItem }} />,
  paths: [],
  maxItems: 2,
  itemsBeforeCollapse: 1,
  itemsAfterCollapse: 1,
};

export default Breadcrumbs;
