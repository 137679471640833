/* istanbul ignore file */

export function useChat(portalId, user) {
  // Create script component.
  const script = document.createElement('script');
  script.src = `https://js.usemessages.com/conversations-embed.js`;
  script.type = 'text/javascript';
  script.id = 'hubspot-messages-loader';
  script.setAttribute('data-loader', 'hs-scriptloader');
  script.setAttribute('data-hsjs-portal', portalId);
  script.setAttribute('data-hsjs-env', 'prod');
  script.async = true;

  document.body.appendChild(script);
  window.hsConversationsOnReady = [() => setChatUser(user)];
}

export function setChatUser(user) {
  if (!window.HubSpotConversations) return;

  const hsq = window._hsq;
  if (user) {
    window.hsConversationsSettings = { identificationEmail: user.email };
    hsq.push([
      'identify',
      {
        email: user.email,
        id: user.uid,
        fullName: user.displayName,
      },
    ]);
  }
  hsq.push(['setPath', window.location.pathname]);
  hsq.push(['trackPageView']);
  window.HubSpotConversations.widget.load();
}
