import moment from 'moment-timezone';
import 'moment/locale/en-gb';
import 'moment/locale/nb';

const TIMEZONE = 'Europe/Oslo';

moment.updateLocale('en-gb', {
  longDateFormat: {
    L: 'DD/MM/YYYY',
    LLLL: 'dddd, D MMMM YYYY',
  },
});

moment.updateLocale('nb', {
  longDateFormat: {
    L: 'DD.MM.YYYY',
    LLLL: 'dddd D. MMMM YYYY',
  },
});

export const defaultLocale = 'en-gb';
export const defaultFormat = 'L';

const formatDate = (value, format = defaultFormat, locale = defaultLocale) => {
  const date = moment.tz(value, TIMEZONE).locale(locale);
  const formatted = date.isValid() ? date.format(format) : '';
  return formatted;
};

const toTimestamp = value => moment.tz(value.format('YYYY-MM-DD'), TIMEZONE).valueOf();

const today = moment();

const dateFormatOptions = locale => [
  {
    value: 'L',
    label: formatDate(today, 'L', locale),
  },
  {
    value: 'LL',
    label: formatDate(today, 'LL', locale),
  },
  {
    value: 'LLLL',
    label: formatDate(today, 'LLLL', locale),
  },
];

const localeOptions = intl => [
  {
    value: 'en-gb',
    label: intl.formatMessage({ id: 'template_settings.locales.en' }),
  },
  {
    value: 'nb',
    label: intl.formatMessage({ id: 'template_settings.locales.nb' }),
  },
];

export { formatDate, toTimestamp, dateFormatOptions, localeOptions };

export default moment;
