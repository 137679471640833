import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import Button, { LINK, PRIMARY } from 'components/Button';
import styles from './action.css';

class Action extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.node,
    icon: PropTypes.node,
    tooltip: PropTypes.string,
    disabled: PropTypes.bool,
    testId: PropTypes.string,
    primary: PropTypes.bool,
  }

  componentDidMount() {
    ReactTooltip.hide();
    ReactTooltip.rebuild();
  }

  render() {
    const { onClick, primary, title, icon, disabled, tooltip, testId } = this.props;
    return (
      <Button
        className={cn({ [styles.component]: !primary })}
        variant={primary ? PRIMARY : LINK}
        testId={testId}
        onClick={onClick}
        disabled={disabled}
        data-tip={tooltip}
        glyph={icon}>
        {title}
      </Button>
    );
  }
}

export default Action;
