import React from 'react';

export default function LinkBroken(props) {
  return (
    <svg
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 336.9289855957031 243.03599548339844"
      xmlSpace="preserve"
      height="203"
      width="281.33609958506"
      y="0px"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1_1540822201775"
      {...props}>
      <g transform="translate(1, 1)">
        <g opacity="0.08">
          <g>
            <g>
              <path
                d="M334.929,210.323c0,16.963-74.972,30.713-167.465,30.713C74.979,241.036,0,227.286,0,210.323&#10;&#9;&#9;&#9;&#9;c0-16.959,74.979-30.713,167.464-30.713C259.958,179.61,334.929,193.364,334.929,210.323z"
                fill="currentColor"
                vectorEffect="non-scaling-stroke" />
            </g>
          </g>
        </g>
        <g opacity="0.15">
          <path
            d="M248.338,183.177c0,10.299-8.349,18.646-18.647,18.646l-99.452-12.242&#10;&#9;&#9;c-10.299,0-18.647-8.348-18.647-18.646V18.647C111.591,8.349,119.94,0,130.239,0l99.452,12.243&#10;&#9;&#9;c10.299,0,18.647,8.349,18.647,18.647V183.177z"
            fill="currentColor"
            vectorEffect="non-scaling-stroke" />
        </g>
        <g opacity="0.77">
          <path
            d="M239.338,190.177c0,10.299-8.349,18.646-18.647,18.646l-99.452-12.242&#10;&#9;&#9;c-10.299,0-18.647-8.348-18.647-18.646V25.647C102.591,15.349,110.94,7,121.239,7l99.452,12.243&#10;&#9;&#9;c10.299,0,18.647,8.349,18.647,18.647V190.177z"
            fill="#FFFFFF"
            vectorEffect="non-scaling-stroke" />
        </g>
        <g opacity="0.15">
          <path
            d="M223.338,55.828v140.349c0,10.299-8.349,18.646-18.647,18.646l-99.452-12.242&#10;&#9;&#9;c-10.299,0-18.647-8.348-18.647-18.646V31.647C86.591,21.349,94.94,13,105.239,13l86.594,10.661"
            fill="currentColor"
            vectorEffect="non-scaling-stroke" />
        </g>
        <g>
          <path
            d="M191.833,23.66c0,0,2.23,1.833,2.23,5.167s0,27,0,27h29.275L191.833,23.66z"
            fill="currentColor"
            vectorEffect="non-scaling-stroke" />
        </g>
        <g>
          <path
            d="M157.438,131.888c0,1.355-0.472,2.475-1.414,3.359c-0.941,0.887-2.15,1.238-3.622,1.059&#10;&#9;&#9;c-1.355-0.168-2.533-0.813-3.534-1.938c-1.002-1.123-1.502-2.361-1.502-3.719V91.508c0-1.414,0.485-2.547,1.458-3.399&#10;&#9;&#9;c0.972-0.853,2.164-1.192,3.578-1.019c1.413,0.173,2.606,0.806,3.579,1.897c0.972,1.091,1.457,2.344,1.457,3.758V131.888z&#10;&#9;&#9; M155.981,148.274c-0.973,0.854-2.166,1.191-3.579,1.02c-1.414-0.174-2.606-0.807-3.578-1.898c-0.973-1.09-1.458-2.344-1.458-3.756&#10;&#9;&#9;c0-1.414,0.485-2.549,1.458-3.4c0.972-0.854,2.164-1.193,3.578-1.02c1.413,0.174,2.606,0.807,3.579,1.898&#10;&#9;&#9;c0.972,1.09,1.457,2.344,1.457,3.758S156.953,147.423,155.981,148.274z"
            fill="currentColor"
            vectorEffect="non-scaling-stroke" />
        </g>
      </g>
    </svg>
  );
}
