import React from 'react';

const BankIdLogo = props => (
  <svg
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 540.6 152.2"
    xmlSpace="preserve"
    y={0}
    x={0}
    id="bankID_Logo_RGB_svg__Layer_1_1559029058144"
    width={98.187}
    height={27.375}
    {...props}>
    <g transform="translate(1 1)">
      <style>{'.bankID_Logo_RGB_svg__st0_1559029058144{fill: currentColor}'}</style>
      <switch vectorEffect="non-scaling-stroke">
        <g>
          <path
            d="M119.5 61.2H94.4c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c.1-.8-.6-1.4-1.4-1.4zM119.5 79.5H94.4c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c.1-.7-.6-1.4-1.4-1.4zM156.3 97.9h-25.1c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c0-.8-.6-1.4-1.4-1.4zM82.8 97.9H57.7c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c0-.8-.6-1.4-1.4-1.4zM156.3 61.2h-25.1c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c0-.8-.6-1.4-1.4-1.4zM82.8 79.5H57.7c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c0-.7-.6-1.4-1.4-1.4zM82.8 42.8H57.7c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c0-.8-.6-1.4-1.4-1.4zM156.3 42.8h-25.1c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c0-.8-.6-1.4-1.4-1.4z"
            className="bankID_Logo_RGB_svg__st0_1559029058144"
            vectorEffect="non-scaling-stroke" />
          <g>
            <path
              d="M323.4 61.3h-28.7c-.7 0-1.3.6-1.3 1.3v43.2c0 .7.6 1.3 1.3 1.3h6.5c.7 0 1.3-.6 1.3-1.3V71.1c0-.4.3-.6.7-.6h20.2c1.9 0 3.4.6 4.8 2 1.3 1.3 2 2.9 2 4.7v28.7c0 .7.6 1.3 1.3 1.3h6.5c.7 0 1.3-.6 1.3-1.3V77.2c0-4.4-1.6-8.1-4.7-11.2-3-3.1-6.8-4.7-11.2-4.7zM279.3 66c3.1 3.1 4.7 6.9 4.7 11.2v27.4c0 1.4-1.2 2.6-2.7 2.6h-29.2c-7.7 0-14-6.3-14-14s6.3-13.5 14-13.5h22c.4 0 .7-.3.7-.6v-1.9c0-1.8-.7-3.4-2-4.7-1.3-1.3-2.9-2-4.7-2h-23.8c-.7 0-1.3-.6-1.3-1.3v-6.5c0-.7.6-1.3 1.3-1.3h23.8c4.4-.1 8.1 1.5 11.2 4.6zm-4.5 23.6c0-.4-.3-.7-.7-.7h-22.3c-2.6 0-4.7 2.1-4.6 4.7.1 2.5 2.3 4.5 4.8 4.5h22.1c.4 0 .7-.3.7-.6v-7.9zM417.5 43H411c-.7 0-1.3.6-1.3 1.3v61.6c0 .7.6 1.3 1.3 1.3h6.5c.7 0 1.3-.6 1.3-1.3V44.3c.1-.7-.5-1.3-1.3-1.3zm64.8 25.4v13.4c0 16.4-9 25.4-25.3 25.4h-25.8c-.7 0-1.3-.6-1.3-1.3V44.3c0-.7.6-1.3 1.3-1.3h25.6c16.2 0 25.5 9.2 25.5 25.4zm-9.4-.7c0-10.1-5.6-15.6-15.6-15.6h-17.6c-.4 0-.7.3-.7.7v44.6c0 .4.3.7.7.7h17.6c10.2 0 15.6-5.4 15.6-15.5V67.7zm-246-1.7c3.1 3.1 4.7 6.9 4.7 11.2v14.1c0 4.4-1.6 8.2-4.7 11.3-3.1 3.1-6.9 4.6-11.2 4.6H187c-.7 0-1.3-.6-1.3-1.3V44.3c0-.7.6-1.3 1.3-1.3h6.5c.7 0 1.3.6 1.3 1.3v16.3c0 .4.3.7.7.7h20.2c4.3 0 8.1 1.6 11.2 4.7zm-4.5 11.2c0-1.9-.6-3.4-2-4.7-1.3-1.3-2.9-2-4.8-2h-20.1c-.4 0-.7.3-.7.6v26.4c0 .4.3.6.7.6h20.1c1.9 0 3.4-.6 4.8-2 1.3-1.3 2-2.9 2-4.7V77.2zM359.2 80.7c-.2.2-.5.3-.8.2-.3-.1-.4-.4-.5-.7V44.3c0-.7-.6-1.3-1.3-1.3h-6.5c-.7 0-1.3.6-1.3 1.3v61.6c0 .7.6 1.3 1.3 1.3h6.5c.7 0 1.3-.6 1.3-1.3V89c0-.3.2-.5.5-.6.3-.1.6 0 .8.2l21.3 18.4c.3.2.6.3.9.3h8.9c.7 0 1.3-.6 1.3-1.3 0-.4-.2-.7-.4-1l-23-19.8c-.1-.1-.2-.3-.2-.5s.1-.4.2-.5l23.6-20.4c.3-.2.4-.6.4-1 0-.7-.6-1.3-1.3-1.3H382c-.3 0-.7.1-.9.3l-21.9 18.9z"
              className="bankID_Logo_RGB_svg__st0_1559029058144"
              vectorEffect="non-scaling-stroke" />
          </g>
        </g>
      </switch>
    </g>
  </svg>
);

export default BankIdLogo;
