import assert from 'assert';
import { Creators, Types } from 'ducks/exportFile/actions';
import { FILE_TYPE } from 'ducks/exportFile/constants';
import { questionnaireSelector } from 'ducks/questionnaire/selectors';
import { selectCurrentDocumentVersion } from 'pages/DocumentDetails/selectors';
import { headerAndFooterHtmlSelector, pageMarginsSelector } from 'pages/DocumentStyles/selectors';
import { call, put, select, take } from 'redux-saga/effects';
import openUrl from 'shared/helpers/openUrl';
import previewHtmlSelector, { stylesheetSelector } from 'shared/utils/questionnairePreviewSelector';
import {
  applyHeaderAndFooter,
  convertMillimeterMarginsToTwipMargins,
  fixQuestionHighlightingStyle,
} from 'utils/exportDOCXutils';

export default function createSagas({ questionnaireService, documentExportService }) {
  assert.ok(questionnaireService, 'questionnaireService is required');
  assert.ok(documentExportService, 'documentExportService is required');

  function* exportDocxSaga() {
    while (true) {
      try {
        const { fileName } = yield take(Types.EXPORT_DOCX_REQUEST);
        let previewHtml;
        const {
          userQuestionnaire: { id },
          settings: { document_highlighting_enabled: documentHighlightingEnabled },
        } = yield select(questionnaireSelector);
        const currentDocumentVersion = yield select(selectCurrentDocumentVersion);
        const stylesheet = yield select(stylesheetSelector);
        const pageMargins = yield select(pageMarginsSelector);
        const { header, footer } = yield select(headerAndFooterHtmlSelector);

        if (currentDocumentVersion.isQuestionnaire) {
          previewHtml = yield select(previewHtmlSelector);
        } else {
          previewHtml = `<style>${stylesheet}</style>\n${currentDocumentVersion.html}`;
        }

        const { data: { access_token: fileAccessToken } } = yield call([questionnaireService, questionnaireService.getDownloadAttachmentToken], id);

        if (documentHighlightingEnabled && currentDocumentVersion.isQuestionnaire) {
          previewHtml = yield call(fixQuestionHighlightingStyle, previewHtml);
        }

        previewHtml = yield call(applyHeaderAndFooter, previewHtml, header, footer);

        const documentOptions = yield call(convertMillimeterMarginsToTwipMargins, pageMargins);
        const { data: fileBlob } = yield call(
          [documentExportService, documentExportService.htmlToMsWord],
          previewHtml,
          documentOptions,
          fileAccessToken,
        );
        const blobURL = yield call(window.URL.createObjectURL, fileBlob);
        openUrl(blobURL, `${fileName}.${FILE_TYPE.DOCX.toLowerCase()}`);

        yield put(Creators.exportDocxSuccess(blobURL));
      } catch (e) {
        yield put(Creators.exportDocxFailure(e));
      }
    }
  }

  return { exportDocxSaga };
}
