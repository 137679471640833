import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import HelpIcon from 'components/HelpIcon';

import styles from './styles.css';

const LabelWrapper = ({ required, helpText, htmlFor, description, children }) => (
  <div className={styles.labelContainer}>
    <Typography variant="label" htmlFor={htmlFor}>
      {children}
      {required && <span className={styles.requiredStar}>*</span>}
      {helpText && (
        <Tooltip title={helpText}>
          <HelpIcon />
        </Tooltip>
      )}
    </Typography>
    {description && <p className={styles.documentNameDescription}>{description}</p>}
  </div>
);

LabelWrapper.propTypes = {
  children: PropTypes.node,
  required: PropTypes.bool,
  helpText: PropTypes.node,
  htmlFor: PropTypes.string,
  description: PropTypes.node,
};

export default LabelWrapper;
