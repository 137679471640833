import assert from 'assert';

import createTemplateSagas from './sagas/template';
import createFetchSagas from './sagas/fetchData';
import createOrganiseSagas from './sagas/organise';

export default function createSagas({
  templatesService,
  foldersService,
  documentService,
  notificationService,
} = {}) {
  assert.ok(templatesService, 'templatesService is required');
  assert.ok(foldersService, 'foldersService is required');
  assert.ok(documentService, 'documentService is required');
  assert.ok(notificationService, 'notificationService is required');

  return {
    ...createTemplateSagas({ templatesService, documentService, notificationService }),
    ...createFetchSagas({ templatesService, foldersService, documentService, notificationService }),
    ...createOrganiseSagas({ templatesService, foldersService, documentService, notificationService }),
  };
}
