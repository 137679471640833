import React from 'react';
import PropTypes from 'prop-types';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import DoneIcon from '@material-ui/icons/Done';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './index.css';

const PARALLEL = 'parallel';
const SEQUENTIAL = 'sequential';


const getSequentialMessage = (index, emailsLength, firstMessageSuffix, nextMessageSuffix, lastMessageSuffix) => {
  if (index === 0) {
    return `questionnaire.esign.timeline.${firstMessageSuffix}`;
  } if (index === emailsLength - 1) {
    return `questionnaire.esign.timeline.${lastMessageSuffix}`;
  }
  return `questionnaire.esign.timeline.${nextMessageSuffix}`;
};

const getSignatureMessage = (index, emailsLength) => getSequentialMessage(index,
  emailsLength,
  'signature_first',
  'signature',
  'signature_last');

const getSendInvitationMessage = (index, emailsLength) => getSequentialMessage(index,
  emailsLength,
  'send_invitation',
  'send_invitation_next',
  'send_invitation_last');


const messages = {
  sequential: {
    send_invitation: getSendInvitationMessage,
    signature: getSignatureMessage,
    summary: 'questionnaire.esign.timeline.summary_sequential',
  },
  parallel: {
    send_invitation: () => 'questionnaire.esign.timeline.send_invitation',
    signature: () => 'questionnaire.esign.timeline.signature',
    summary: 'questionnaire.esign.timeline.summary_parallel',
  },
};

const EsignTimeline = ({ emails, signingOrder }) => (
  <div>
    <ul className={cn(styles.esignTimeline, { [styles[signingOrder]]: signingOrder })}>
      {emails.map((email, i) => (
        <li>
          <span className={styles.step}><EmailIcon /></span>
          <div className={styles.descriptionContainer}>
            <p><FormattedMessage id={messages[signingOrder].send_invitation(i, emails.length)} /></p>
            <p className={styles.email}>{email}</p>
            <p><FormattedMessage id={messages[signingOrder].signature(i, emails.length)} /></p>
          </div>
        </li>
      ))}
      <li>
        <span className={styles.step}><DoneIcon /></span>
        <div className={styles.descriptionContainer}>
          <p><FormattedMessage id={messages[signingOrder].summary} /></p>
        </div>
      </li>
    </ul>
  </div>
);

EsignTimeline.propTypes = {
  signingOrder: PropTypes.oneOf([PARALLEL, SEQUENTIAL]).isRequired,
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { PARALLEL, SEQUENTIAL };
export default EsignTimeline;
