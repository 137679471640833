import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { shouldDispatch } from 'utils/shouldDispatch';

const TITLES = {
  success: 'Success',
  error: 'Error',
  info: 'Info',
};

const TITLES_TRANSLATION = {
  success: 'shared.notifications.success',
  error: 'shared.notifications.error',
  info: 'shared.notifications.info',
};

const defaultOptions = {
  title: 'default title',
  message: 'default content',
  position: 'tl',
  autoDismiss: 3,
};

class NotificationSystem extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
      position: PropTypes.string,
      autoDismiss: PropTypes.string,
      level: PropTypes.string,
      useTranslate: PropTypes.bool,
    })),
    dispatch: PropTypes.func.isRequired,
  };

  enchanedNotifications = notifications => notifications.map((notification) => {
    let title;
    let message = notification.message || '';
    const action = notification.action ? {
      ...notification.action,
      label: notification.useTranslate ? this.props.intl.formatMessage({ id: notification.action.label }) : notification.action.label,
      callback: shouldDispatch(notification.action.callback) ? () => this.props.dispatch(notification.action.callback) : notification.action.callback,
    } : undefined;

    if (notification.useTranslate) {
      title = notification.title || TITLES_TRANSLATION[notification.level] || '';
      title = this.props.intl.formatMessage({ id: title }, notification.values);
      message = this.props.intl.formatMessage({ id: message }, notification.values);
    } else {
      title = notification.title || TITLES[notification.level] || '';
    }

    return {
      ...defaultOptions,
      ...notification,
      action,
      title,
      message,
    };
  });

  render() {
    // https://github.com/igorprado/react-notification-system/blob/master/src/styles.js
    const style = {
      NotificationItem: {
        DefaultStyle: {
          margin: '10px 5px 2px 1px',
          cursor: 'default',
        },
      },
      Action: { DefaultStyle: { cursor: 'pointer' } },
    };

    const notifications = this.enchanedNotifications(this.props.notifications);


    return <Notifications notifications={notifications} style={style} />;
  }
}

export default compose(
  connect(state => ({ notifications: state.notifications }), dispatch => ({ dispatch })),
  injectIntl,
)(NotificationSystem);
