import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import cn from 'classnames';
import { createClassNameModifiers } from 'utils/componentUtils';
import styles from './StyleCheckbox.css';

const label = {
  bold: 'B',
  underline: 'U',
  italic: 'it',
};

export default class StyleCheckbox extends Component {
  static propTypes = {
    headerType: PropTypes.string.isRequired,
    styleType: PropTypes.string.isRequired,
    leftRadiusBorder: PropTypes.bool,
    rightRadiusBorder: PropTypes.bool,
  };

  render() {
    const {
      headerType,
      styleType,
      leftRadiusBorder,
      rightRadiusBorder,
    } = this.props;
    const name = `${headerType}_${styleType}`;
    const modifiers = [];

    if (leftRadiusBorder) {
      modifiers.push('leftRadiusBorder');
    }
    if (rightRadiusBorder) {
      modifiers.push('rightRadiusBorder');
    }

    const formattedModifiers = createClassNameModifiers({ modifiers, styles });
    const classString = cn(styles.component, ...formattedModifiers);
    return (
      <div className={classString}>
        <Field
          component={props => (
            <input
              {...props}
              type="checkbox"
              defaultChecked={props.field.value}
              {...props.field} />
          )}
          name={name}
          id={name} />
        <label className={styleType} htmlFor={name}>
          {label[styleType]}
        </label>
      </div>
    );
  }
}
