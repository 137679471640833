import isEmpty from 'lodash/isEmpty';
import castArray from 'lodash/castArray';
import isQuestion from 'Visualization/helpers/isQuestion';
import { EDITOR_CONTENT_CLASS } from 'Visualization/helpers/constants';

export default function getAllNodesInTree(
  node,
  tagWeLookFor,
  options = {}, // withClass: string
  nodesList = [], // iterator result
) {
  if (!node) return [];
  if (
    (node.tagName === 'DIV' && node.classList.contains(EDITOR_CONTENT_CLASS))
    || node.tagName === 'HTML' // to prevent infinite loop - edge case
  ) {
    // that means we cannot go further - return last element
    return nodesList;
  }
  const arrayedTagWeLookFor = castArray(tagWeLookFor);
  if (check(arrayedTagWeLookFor, node, options)) {
    nodesList.unshift(node);
  }

  // go further, find more question nodes in this tree
  return getAllNodesInTree(node.parentNode, tagWeLookFor, options, nodesList);
}

function check(tagWeLookFor, node, options) {
  if (!tagWeLookFor.includes(node.tagName)) {
    return false;
  }
  if (isEmpty(options)) {
    return true;
  }
  if (options.withClass && !node.classList.contains(options.withClass)) {
    return false;
  }
  if (options.isQuestion && !isQuestion(node)) {
    return false;
  }
  return !(options.withLogicType && node.getAttribute('data-logictype') !== options.withLogicType);
}
