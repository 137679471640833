import React from 'react';
import PropTypes from 'prop-types';
import MuiMenuItem from '@material-ui/core/MenuItem';
import styles from './index.css';

const MenuItem = ({ children, onClick, selected, ...otherProps }) => (
  <MuiMenuItem
    classes={{ root: styles.menuItem }}
    {...otherProps}
    onClick={onClick}
    selected={selected}>
    {children}
  </MuiMenuItem>
);

MenuItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default MenuItem;
