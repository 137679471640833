import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import Logo from 'components/Logo';
import Typography, { VARIANTS } from 'components/Typography';
import TokenExpired from 'icons/TokenExpired';
import styles from './styles.css';


export class ErrorSessionExpiredComponent extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({ state: PropTypes.shape({ delayRedirectUrl: PropTypes.string }) }),
    errorType: PropTypes.string,
  };

  componentDidMount() {
    const delayRedirectUrl = _get(this.props.location, 'state.delayRedirectUrl');
    if (!delayRedirectUrl) {
      return;
    }
    this.redirectOnErrorTimeout = global.setTimeout(() => {
      window.location.replace(global.decodeURIComponent(delayRedirectUrl));
    }, 5000);
  }

  componentWillUnmount() {
    if (this.redirectOnErrorTimeout) {
      global.clearTimeout(this.redirectOnErrorTimeout);
    }
  }

  render() {
    const sessionExpiredKey = this.props.errorType || 'session_expired';
    return (
      <div className={styles.c}>
        <Logo className={styles.logo} />
        <TokenExpired className={styles.picture} />
        <Typography variant={VARIANTS.H1} className={styles.heading}>
          <FormattedMessage id={`shared.errors.${sessionExpiredKey}.title`} />
        </Typography>
        <Typography variant={VARIANTS.BODY_SHORT} className={styles.paragraph}>
          <FormattedMessage id={`shared.errors.${sessionExpiredKey}.message`} />
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = state => ({ errorType: state.error.errorType });

export default connect(mapStateToProps)(ErrorSessionExpiredComponent);
