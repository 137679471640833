import assert from 'assert';

import ApiClient from '../ApiClient';

export default class AuthenticationService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  signIn({ email, password } = {}) {
    assert.ok(email, 'email<string> is required');
    assert.ok(password, 'password<string> is required');

    return this.apiClient.request('/sessions', {
      method: 'post',
      data: { auth: { email, password } },
    });
  }

  forgotPassword({ email } = {}) {
    assert.ok(email, 'email<string> is required');

    return this.apiClient.request('/forget_password', {
      method: 'post',
      data: { auth: { email } },
    });
  }

  resetPassword({ password, confirmPassword } = {}, token) {
    assert.ok(password, 'password<string> is required');
    assert.ok(confirmPassword, 'confirmPassword<string> is required');
    assert.ok(token, 'token<string> is required');

    return this.apiClient.request('/forget_password', {
      method: 'patch',
      data: {
        auth: { password, confirmPassword },
        token,
      },
    });
  }
}
