import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { QuestionnaireCompletedWrapper } from './styled-components';
import BigPreview from './BigPreview';
import FinishedWithSkipped from '../FinishedWithSkipped';

export default class QuestionnaireCompleted extends Component {
  render() {
    const { isFinishedWithSkipped } = this.props;
    return (
      <QuestionnaireCompletedWrapper>
        {isFinishedWithSkipped ? <FinishedWithSkipped /> : <BigPreview />}
      </QuestionnaireCompletedWrapper>
    );
  }
}
QuestionnaireCompleted.propTypes = { isFinishedWithSkipped: PropTypes.bool.isRequired };
