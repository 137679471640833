import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { compose } from 'redux';
import proptypes from 'utils/proptypes';
import { EDITOR_CONTENT_CLASS } from 'Visualization/helpers/constants';
import { TINYMCE_KEY } from 'shared/helpers/constants';
import WithPageBreak from 'shared/Components/WithPageBreak';
import { observeQuestions } from 'Visualization/helpers/editorObserver';
import { withRouter } from 'react-router-dom';
import { editorSkinLoader } from 'components/Editor/editorSkinLoader';
import { TEMPLATE_EDITOR_ID } from 'appRoot/constants';
import { EditorFilePicker } from 'containers/EditorFilePicker';

import { findDirectQuestionNodes, performRenderQuestion } from './utils/changeQuestionNodesToReactComponents';
import forbidUserToPasteInInlineQuestion from './utils/forbidUserToPasteInInlineQuestion';
import editorSetupHoc from './editorSetupHoc';
import Toolbar from './Toolbar/ToolbarContainer';
import normalizeHtmlContentOnKeyup from './utils/normalizeHtmlContentOnKeyup';
import parsePastedContent from './utils/parsePastedContent';
import EditorWrapper from './EditorWrapper';
import ToolbarContainer from './ToolbarContainer';
import replaceImageHoc from './replaceImageHoc';

editorSkinLoader();

class TemplateEditor extends PureComponent {
  static propTypes = {
    setupEditor: PropTypes.func,
    saveEditorContent: PropTypes.func.isRequired,
    regenerateFlow: PropTypes.func.isRequired,
    displayError: PropTypes.func.isRequired,
    templateVersion: proptypes.templateVersionScheme,
    stylesheet: PropTypes.string.isRequired,
    uploadImages: PropTypes.func.isRequired,
  };

  state = {
    content: this.props.templateVersion.wysiwyg_html,
    questionsTransformedToReact: false,
    selectedContent: null,
  };

  onKeyUp = (e, editor) => {
    normalizeHtmlContentOnKeyup(e);
    this.setState({ selectedContent: editor.selection.getContent() });
  };

  onMouseUp = (e, editor) => {
    this.setState({ selectedContent: editor.selection.getContent() });
  };

  onFocusOut = () => this.setState({ selectedContent: null });

  handleEditorChange = (content) => {
    if (this.state.questionsTransformedToReact) {
      this.setState({ content }, () => this.props.saveEditorContent(content));
    }
  };

  handleEditorInit = (editor) => {
    this.editor = editor;
    // This will trick the editor into thinking it was focused
    // without actually focusing it (causing the toolbar to appear)
    editor.fire('focus');
    this.handleLoadContent();
    // editor.on('SetContent', this.handleLoadContent);
    if (this.editor.targetElm !== null) {
      this.props.regenerateFlow();
      observeQuestions(this.editor.targetElm);
    }
  };

  handleLoadContent = () => {
    const questionNodes = findDirectQuestionNodes(`.${EDITOR_CONTENT_CLASS}`);
    performRenderQuestion(questionNodes);
    this.setState({ questionsTransformedToReact: true });
  };

  render() {
    const { setupEditor, stylesheet, displayError } = this.props;
    return (
      <WithPageBreak>
        <EditorWrapper>
          <ToolbarContainer id="mytoolbar" />
          <EditorFilePicker>
            {
            ({ onFilePick }) => (
              <Editor
                id={TEMPLATE_EDITOR_ID}
                apiKey={TINYMCE_KEY}
                value={this.state.content}
                initialValue={this.props.templateVersion.wysiwyg_html}
                onEditorChange={this.handleEditorChange}
                onSelectionChange={this.selectionChanged}
                onKeyUp={this.onKeyUp}
                onMouseUp={this.onMouseUp}
                onFocusOut={this.onFocusOut}
                init={{
                  height: 800,
                  minHeight: 400,
                  content_style: stylesheet,
                  menubar: false,
                  skin: false,
                  init_instance_callback: this.handleEditorInit,
                  setup: setupEditor,
                  browser_spellcheck: true,
                  inline: true,
                  fixed_toolbar_container: '#mytoolbar',
                  autosave_ask_before_unload: false,
                  theme: 'modern',
                  plugins: [
                    'stickytoolbar autolink autosave lists link image charmap hr anchor paste',
                    'insertdatetime media nonbreaking save table directionality',
                    'emoticons template textcolor colorpicker textpattern imagetools toc preventdelete enterinsidequestion pagebreak tableborders',
                  ],
                  table_appearance_options: false,
                  table_advtab: false,
                  table_cell_advtab: false,
                  table_row_advtab: false,
                  table_controls: 'table',
                  table_toolbar:
                'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | tablemergecells tablesplitcells',
                  paste_preprocess: (plugin, args) => {
                    if (forbidUserToPasteInInlineQuestion(args.content)) {
                  args.content = ''; // eslint-disable-line
                      displayError({
                        message: 'visualization.errors.forbid_to_paste_inside_inline_question',
                        useTranslate: true,
                      });
                    } else {
                  args.content = parsePastedContent(args.content); // eslint-disable-line
                    }
                    this.props.uploadImages(args);
                  },
                  formats: {
                    paragraph: { block: 'p', attributes: { class: '' } },
                    title: { block: 'p', attributes: { class: 'title' } },
                    heading1: { block: 'h1', attributes: { class: '' } },
                    heading2: { block: 'h2', attributes: { class: '' } },
                    heading3: { block: 'h3', attributes: { class: '' } },
                    heading4: { block: 'h4', attributes: { class: '' } },
                  },
                  block_formats: 'Paragraph=paragraph;Title=title;Header 1=heading1;Header 2=heading2;Header 3=heading3;Header 4=heading4',
                  toolbar1:
                'formatselect | undo redo | bold italic underline forecolor backcolor image | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | pageBreak | table tableborders',
                  toolbar2: 'textInputQuestion multipleChoiceQuestion showIf',
                  valid_styles: {
                    '*': 'color,padding-left,padding-right,text-align,background-color,text-decoration',
                    table: 'width,height',
                    tr: 'width,height',
                    td: 'width,height',
                  },
                  paste_retain_style_properties: 'color,padding-left,padding-right',
                  paste_webkit_styles: 'color,padding-left,padding-right',
                  paste_block_drop: true,
                  valid_children: '*[*]',
                  valid_elements: '*[*]',
                  paste_data_images: true,
                  file_picker_types: 'image',
                  file_picker_callback: onFilePick,
                }} />
            )
          }
          </EditorFilePicker>
          <Toolbar selectedContent={this.state.selectedContent} />
        </EditorWrapper>
      </WithPageBreak>
    );
  }
}

export default compose(
  replaceImageHoc,
  editorSetupHoc,
  withRouter,
)(TemplateEditor);
