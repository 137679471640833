/* istanbul ignore file */

const menuItems = [
  { text: 'Top', className: 'borderTop' },
  { text: 'Right', className: 'borderRight' },
  { text: 'Bottom', className: 'borderBottom' },
  { text: 'Left', className: 'borderLeft' },
  { text: 'All', className: 'borderAll' },
  { text: 'None', className: 'borderNone' },
];

const getSelectedTableCells = (node) => {
  const $$ = tinymce.dom.DomQuery; // eslint-disable-line
  let nodeEl = $$(node);

  if (node.tagName.toLowerCase() !== 'td') {
    nodeEl = nodeEl.closest('td');
  }

  const selectedEl = nodeEl.closest('table').find('[data-mce-selected]');

  return selectedEl.length ? selectedEl : nodeEl;
};

const setBorderType = (node, className) => {
  const tableCellEl = getSelectedTableCells(node);
  // Clear previous classes
  tableCellEl.removeAttr('class');
  // Add new class
  tableCellEl.addClass(`table__${className}`);
};

export default (editor) => {
  // Remove buttons from table tinymce toolbar menu
  editor.buttons.table.menu.splice(1, editor.buttons.table.menu.length - 1);

  editor.addButton('tableborders', {
    icon: 'tableBordersNone',
    type: 'menubutton',
    menu: menuItems.map(item => ({
      text: item.text,
      onclick() {
        const node = editor.selection.getNode();
        const { className } = item;
        setBorderType(node, className);
        editor.save();
      },
    })),
  });
};
