import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchDocumentStylesRequest: null,
  fetchDocumentStylesSuccess: ['documentStyles'],
  fetchDocumentStylesFailure: ['error'],
  updateDocumentStylesRequest: ['payload'],
  updateDocumentStylesSuccess: ['documentStyles'],
  updateDocumentStylesFailure: ['error'],
});
