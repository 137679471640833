import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Portal from 'shared/Portal';
import { connect } from 'react-redux';
import { compose } from 'redux';
import rangy from 'rangy';
import { FormattedMessage } from 'react-intl';

import withNotificationsHoc from 'shared/NotificationSystem/withNotificationsHoc';
import RangeHelper from 'Visualization/helpers/rangeHelper';
import { EDITOR_CONTENT_CLASS } from 'Visualization/helpers/constants';
import hasParent from 'Visualization/helpers/hasParent';
import TapTarget from 'shared/Components/TapTarget';

import { TapTargetHeader } from './styled-components';
import CursorNotification from './CursorNotification';
import { Creators as ActionCreators } from '../../LogicsManagerRedux';

class TriggersFn extends Component {
  static propTypes = {
    stopTriggersFn: PropTypes.func.isRequired,
    optionId: PropTypes.string.isRequired,
    logicType: PropTypes.string.isRequired,
    triggerImmediately: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func.isRequired,
    validateCanAddQuestion: PropTypes.func.isRequired,
    questionId: PropTypes.string.isRequired,
  };

  state = { isCircleVisible: true }

  componentDidMount() {
    if (this.props.triggerImmediately) {
      this.handleMouseUp();
      return;
    }
    document.addEventListener('keydown', this.escFunction, false);
    new RangeHelper().clearSelection();
    $('body').on('mouseup.triggerFn', this.handleMouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
    new RangeHelper().clearSelection();
    $('body').off('mouseup.triggerFn', this.handleMouseUp);
  }

  handleMouseUp = () => {
    const { optionId, logicType, onSuccess, validateCanAddQuestion, questionId } = this.props;
    if (!new RangeHelper().selectionLength() > 0) return;
    const sel = rangy.getSelection().getRangeAt(0);
    const hasEditorParent = hasParent(
      sel.commonAncestorContainer,
      node => node.classList && node.classList.contains(EDITOR_CONTENT_CLASS),
    );
    if (!hasEditorParent) {
      return;
    }

    validateCanAddQuestion(logicType, optionId, onSuccess, questionId);
  };

  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.props.stopTriggersFn();
    }
  };

  handleTapTargetCircleClick = () => {
    this.setState({ isCircleVisible: false });
  }

  render() {
    const { stopTriggersFn } = this.props;
    const { isCircleVisible } = this.state;

    return (
      <Portal testId="LogicsManager-closeLogicModal">
        <TapTarget
          onClick={stopTriggersFn}
          onCircleClick={this.handleTapTargetCircleClick}
          isCircleVisible={isCircleVisible}>
          <TapTargetHeader>
            <FormattedMessage id="visualization.logics_manager.tap_target.header" />
          </TapTargetHeader>
          <p>
            <FormattedMessage id="visualization.logics_manager.tap_target.description1" />
          </p>
          <p>
            <FormattedMessage id="visualization.logics_manager.tap_target.description2" />
          </p>
        </TapTarget>
        <CursorNotification />
      </Portal>
    );
  }
}

const mapDispatchToProps = dispatch => ({ validateCanAddQuestion: (logicType, optionId, onSuccess, questionId) => dispatch(ActionCreators.validateCanAddQuestion(logicType, optionId, onSuccess, questionId)) });

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withNotificationsHoc,
)(TriggersFn);
