import { createActions } from 'reduxsauce';

export const REDUX_NAMESPACE = 'app/Trash/';
export const REDUXACTIONS_STRUCTURE = {
  fetchTrashRequest: ['payload', 'toast'],
  fetchTrashSuccess: ['trash', 'totalCount'],
  fetchTrashFailure: ['error'],

  restoreItem: ['id'],
  restoreItemSuccess: null,
  restoreItemFailure: ['error'],

  deleteItem: ['id'],
  deleteItemSuccess: null,
  deleteItemFailure: ['error'],

  emptyTrash: null,
  emptyTrashSuccess: null,
  emptyTrashFailure: ['error'],
};

export const { Types, Creators } = createActions(REDUXACTIONS_STRUCTURE, { prefix: REDUX_NAMESPACE });
