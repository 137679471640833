import styled from 'styled-components';

export default styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid ${props => props.theme.border.normal};
  margin: 1em 0;
  padding: 0;
`;
