import React from 'react';

export default function TokenExpired(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="271.685"
      height="196.036"
      overflow="visible"
      preserveAspectRatio="none"
      version="1.2"
      viewBox="0 0 336.929 243.036"
      {...props}>
      <path
        fill="currentColor"
        d="M335.929 211.323c0 16.963-74.973 30.713-167.465 30.713C75.979 242.036 1 228.286 1 211.323c0-16.959 74.979-30.713 167.464-30.713 92.494 0 167.465 13.754 167.465 30.713z"
        opacity=".08"
        vectorEffect="non-scaling-stroke" />
      <path
        fill="currentColor"
        d="M249.337 184.176c0 10.3-8.349 18.646-18.646 18.646l-99.452-12.242c-10.299 0-18.647-8.348-18.647-18.646V19.647C112.591 9.349 120.94 1 131.239 1l99.452 12.243c10.299 0 18.646 8.349 18.646 18.647v152.286z"
        opacity=".15"
        vectorEffect="non-scaling-stroke" />
      <path
        fill="#FFF"
        d="M240.337 191.176c0 10.3-8.349 18.646-18.646 18.646l-99.452-12.242c-10.299 0-18.647-8.348-18.647-18.646V26.647C103.591 16.349 111.94 8 122.239 8l99.452 12.243c10.299 0 18.646 8.349 18.646 18.647v152.286z"
        opacity=".77"
        vectorEffect="non-scaling-stroke" />
      <path
        fill="currentColor"
        d="M224.337 56.828v140.348c0 10.3-8.349 18.646-18.646 18.646l-99.452-12.242c-10.299 0-18.647-8.348-18.647-18.646V32.647C87.591 22.349 95.94 14 106.239 14l86.595 10.661"
        opacity=".15"
        vectorEffect="non-scaling-stroke" />
      <path
        fill="currentColor"
        d="M192.833 24.66s2.229 1.833 2.229 5.167v27h29.274L192.833 24.66z"
        vectorEffect="non-scaling-stroke" />
      <g fill="currentColor">
        <path
          d="M162.445 140.178c-.508.128-1.024.239-1.539.334a2.474 2.474 0 00-.973 4.453 2.465 2.465 0 001.874.409 30.941 30.941 0 001.832-.396 2.474 2.474 0 00-1.194-4.8zm18.284-33.14c.173.523.504.948.922 1.242a2.471 2.471 0 003.772-2.797 30.313 30.313 0 00-.643-1.76 2.472 2.472 0 10-4.592 1.836c.195.485.377.983.541 1.479zm-10.25 29.612c-.437.286-.886.565-1.34.826a2.475 2.475 0 00-.191 4.163 2.48 2.48 0 002.662.121 32.422 32.422 0 001.595-.986 2.472 2.472 0 00-2.726-4.124zm16.482-22.707a2.472 2.472 0 00-4.942.195c.021.521.027 1.052.015 1.572a2.464 2.464 0 001.05 2.076 2.472 2.472 0 003.893-1.968 30.87 30.87 0 00-.016-1.875zm-6.635 16.202a2.47 2.47 0 00-3.462.493 25.3 25.3 0 01-.983 1.229 2.473 2.473 0 003.766 3.206c.404-.474.798-.969 1.173-1.468a2.472 2.472 0 00-.494-3.46zm3.634-8.937a2.473 2.473 0 00-3.1 1.618c-.155.498-.329.999-.516 1.489a2.47 2.47 0 001.43 3.189 2.471 2.471 0 003.19-1.43c.222-.582.429-1.177.614-1.77a2.468 2.468 0 00-1.618-3.096zm-32.302 19.328a25.487 25.487 0 01-6.343-2.017c-.023-.012-.045-.026-.069-.038a27.629 27.629 0 01-1.413-.715 26.595 26.595 0 01-2.46-1.538c-11.631-8.193-14.427-24.321-6.233-35.952a25.688 25.688 0 016.43-6.372c8.47-5.836 19.971-6.23 28.966-.232l-1.932 2.791c-.537.777-.207 1.344.733 1.259l8.391-.752c.941-.083 1.504-.897 1.252-1.808l-2.254-8.118c-.252-.91-.898-1.02-1.436-.243l-1.937 2.799c-6.601-4.432-14.517-6.122-22.375-4.759a31.06 31.06 0 00-2.341.501l-.016.001-.089.025A30.489 30.489 0 00131.626 96.7c-.036.042-.072.083-.105.128-.14.189-.28.383-.417.575-.222.318-.442.642-.653.966-.026.04-.046.079-.069.119-3.481 5.395-5.16 11.631-4.873 17.979v.063c.028.62.078 1.249.144 1.868.003.04.013.077.02.118.069.621.155 1.246.264 1.869 1.103 6.365 4.106 12.092 8.604 16.548a.981.981 0 01.032.033l.012.009a31.091 31.091 0 003.939 3.292 30.463 30.463 0 0012.259 5.131 2.471 2.471 0 002.87-1.995 2.469 2.469 0 00-1.995-2.867z"
          vectorEffect="non-scaling-stroke" />
        <path
          d="M154.715 95.159a2 2 0 00-2.001 2v19.931l18.228 9.423a2.003 2.003 0 002.695-.858 2 2 0 00-.859-2.695l-16.063-8.307V97.16a2.003 2.003 0 00-2-2.001z"
          vectorEffect="non-scaling-stroke" />
      </g>
    </svg>
  );
}
