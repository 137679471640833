import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import proptypes from 'utils/proptypes';
import cn from 'classnames';
import styles from './Option.css';

const setOptionHoverStateInTheEditor = (option, state) => {
  $(`[data-logictype="choiceShowIfType"][data-showif="${option.id}"]`)[state ? 'addClass' : 'removeClass']('hovered');
};

const Option = ({ index, option, selectedNodeWithOption, onClick }) => {
  const isSelected = selectedNodeWithOption ? option.id === selectedNodeWithOption.getAttribute('data-showif') : false;
  return (
    <div
      role="presentation"
      className={cn(styles.component, { [styles.active]: isSelected })}
      onClick={onClick}
      onMouseEnter={() => setOptionHoverStateInTheEditor(option, true)}
      onMouseLeave={() => setOptionHoverStateInTheEditor(option, false)}>
      <div className={styles.prefix}>
        A
        {index + 1}
        :
      </div>
      <div className={styles.title} data-test-id="ShowIf-option">{option.name}</div>
      <div className={styles.actions}>
        <span>
          <FormattedMessage id="visualization.logics_manager.reuse_question.reuse" />
        </span>
      </div>
    </div>
  );
};

Option.propTypes = {
  index: PropTypes.number.isRequired,
  option: proptypes.optionScheme,
  onClick: PropTypes.func.isRequired,
  selectedNodeWithOption: proptypes.domElement,
};

export default Option;
