import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './index.css';

export default class Label extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
  }

  render() {
    const {
      className,
      children,
      ...otherProps
    } = this.props;

    return (
      <label {...otherProps} className={cn(className, styles.component)}>
        {children}
      </label>
    );
  }
}
