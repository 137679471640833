import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import Button from 'components/Button';
import Logo from 'components/Logo';
import Typography, { VARIANTS } from 'components/Typography';
import LinkBroken from 'icons/LinkBroken';
import TokenExpired from 'icons/TokenExpired';
import { Creators } from './actions';
import mapStateToProps from './selectors';
import styles from './index.css';

class ErrorTemplateNotActive extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({ state: PropTypes.shape({ errorType: PropTypes.string }) }).isRequired,
    handleResendLink: PropTypes.func.isRequired,
    refreshInProgress: PropTypes.bool.isRequired,
    newLinkSent: PropTypes.bool.isRequired,
  };

  handleResendLink = () => {
    const id = _get(this.props.location, 'state.templateId', '0');
    this.props.handleResendLink(id);
  };

  render() {
    const { refreshInProgress, newLinkSent, location } = this.props;
    const templateId = _get(location, 'state.templateId');

    return (
      <div className={styles.component}>
        <Logo className={styles.logo} />
        { templateId
          ? (
            <>
              <TokenExpired className={styles.picture} />
              <Typography variant={VARIANTS.H1}>
                <FormattedMessage id="shared.errors.link_expired.title" />
              </Typography>
              <Typography variant={VARIANTS.BODY_SHORT}>
                <FormattedMessage id="shared.errors.link_expired.message" />
              </Typography>
              <Button onClick={this.handleResendLink} loading={refreshInProgress} disabled={newLinkSent}>
                <FormattedMessage id="shared.errors.link_expired.button" />
              </Button>
            </>
          ) : (
            <>
              <LinkBroken className={styles.picture} />
              <Typography variant={VARIANTS.H1}>
                <FormattedMessage id="shared.errors.template_not_active.title" />
              </Typography>
              <Typography variant={VARIANTS.BODY_SHORT}>
                <FormattedMessage id="shared.errors.template_not_active.message" />
              </Typography>
            </>
          )}
      </div>
    );
  }
}

const mapDispatchToProps = { handleResendLink: Creators.resendLinkRequest };

export { ErrorTemplateNotActive as ErrorTemplateNotActiveComponent };

export default connect(mapStateToProps, mapDispatchToProps)(ErrorTemplateNotActive);
