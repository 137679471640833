import assert from 'assert';

import ApiClient from '../ApiClient';

export default class DocumentExportService {
  constructor({ apiClient } = {}) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }

  htmlToMsWord(html, pageLayout, fileAccessToken) {
    assert.ok(html, 'html<string> is required');
    assert.ok(pageLayout, 'pageLayout<object> is required');
    assert.ok(fileAccessToken, 'fileAccessToken<string> is required');

    return this.apiClient.post(`/HtmlToMsWord`, {
      data: { html, pageLayout },
      headers: { Authorization: `Bearer ${fileAccessToken}` },
      responseType: 'blob',
    });
  }

  htmlToPdf(html, pageLayout, accessToken) {
    assert.ok(html, 'html<string> is required');
    assert.ok(pageLayout, 'pageLayout<string> is required');
    return this.apiClient.post(
      `/HtmlToPdf`, { data: { html, options: pageLayout }, headers: { Authorization: `Bearer ${accessToken}` }, responseType: 'blob' },
    );
  }
}
