import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Creators as loggedInUserActions } from 'ducks/loggedInUser/actions';
import Page from 'components/Page';
import NavigationBar from 'components/NavigationBar';
import UserProfileView from './components/UserProfileView';
import mapStateToProps from './selectors';

export class UserProfileComponent extends PureComponent {
  static propTypes = {
    changeLanguage: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    changePersonalInformation: PropTypes.func.isRequired,
    isUpdatingUser: PropTypes.bool.isRequired,
    userLanguage: PropTypes.string.isRequired,
    userPersonalInformation: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
  };

  render() {
    const {
      changeLanguage,
      changePassword,
      changePersonalInformation,
      isUpdatingUser,
      userLanguage,
      userPersonalInformation,
    } = this.props;

    return (
      <React.Fragment>
        <NavigationBar />
        <Page>
          <UserProfileView
            changeLanguage={changeLanguage}
            changePassword={changePassword}
            changePersonalInformation={changePersonalInformation}
            isUpdatingUser={isUpdatingUser}
            userLanguage={userLanguage}
            userPersonalInformation={userPersonalInformation} />
        </Page>
      </React.Fragment>
    );
  }
}

export const mapDispatchToProps = {
  changeLanguage: loggedInUserActions.changeLanguageRequest,
  changePassword: loggedInUserActions.changePasswordRequest,
  changePersonalInformation: loggedInUserActions.changePersonalInformationRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileComponent);
