import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Tooltip from 'components/Tooltip';
import cn from 'classnames';
import Folder from '@material-ui/icons/Folder';
import styles from './index.css';

const getFolderBreadcrumbs = (folder, folderId) => {
  let { name } = folder;
  if (folder.parents != null && folder.parents.length > 0 && folder.parents[0].id !== folderId) {
    name = `${getFolderBreadcrumbs(folder.parents[0], folderId)} / ${name}`;
  }
  return name;
};

const TemplateCell = ({ template, folderId, intl }) => {
  const showFolderBreadcrumbs = template.folders && template.folders.id !== folderId;

  return (
    <div className={styles.templateCellContent}>
      <div className={cn(styles.nameWrapper, { [styles.compressed]: showFolderBreadcrumbs })}>
        <strong>{template.name}</strong>
        {template.hasDrafts && (
          <Tooltip title={intl.formatMessage({ id: 'panel.questionnaire_templates.questionnaire_template.unpublished_changes' })}>
            <div className={styles.draftStatusBox}>
              <FormattedMessage id="panel.questionnaire_templates.questionnaire_template.draft" />
            </div>
          </Tooltip>
        )}
        {template.sharingActive && (
          <Tooltip title={intl.formatMessage({ id: 'panel.questionnaire_templates.questionnaire_template.shared' })}>
            <img src="/assets/icons/shared.svg" alt="Shared" />
          </Tooltip>
        )}
      </div>
      {showFolderBreadcrumbs && (
        <span className={styles.templateCellSubheading}>
          <Folder classes={{ root: styles.smallFolderIcon }} />
          {getFolderBreadcrumbs(template.folders, folderId)}
        </span>
      )}
    </div>
  );
};

TemplateCell.propTypes = {
  template: PropTypes.shape({
    hasDrafts: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  intl: intlShape.isRequired,
  folderId: PropTypes.string,
};

export default injectIntl(TemplateCell);
