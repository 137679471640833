import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { formatDate } from 'shared/date';
import InputText from 'components/form/InputText';
import CalendarIcon from './CalendarIcon';
import styles from './styles.css';

class DatepickerInput extends Component {
  static propTypes = {
    defaultValue: PropTypes.instanceOf(Date),
    open: PropTypes.bool.isRequired,
    toggleDatepicker: PropTypes.func.isRequired,
    field: PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
    placeholder: PropTypes.string,
    format: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };


  static defaultProps = { defaultValue: '' };

  getFieldValue = () => {
    const value = this.props.field.value || this.props.defaultValue;
    if (typeof value === 'string') {
      return value;
    }

    return formatDate(value, this.props.format, this.props.lang);
  };

  render() {
    const { open, toggleDatepicker, placeholder, mask, onClick, onChange, onBlur } = this.props;

    return (
      <div className={styles.datepickerInput}>
        <InputMask
          mask={mask}
          onChange={onChange}
          onBlur={onBlur}
          value={this.getFieldValue()}>
          {inputMaskProps => (
            <InputText
              {...inputMaskProps}
              data-test-id="date-picker"
              onClick={onClick}
              placeholder={placeholder} />
          )}
        </InputMask>
        <button onClick={toggleDatepicker} className={styles.button} data-test-id="DatePicker-toggle" type="button">
          <CalendarIcon highlight={open} />
        </button>
      </div>
    );
  }
}

export default DatepickerInput;
