import map from 'lodash/map';
import each from 'lodash/each';
import removeQuestionFromDom from './removeQuestionFromDom';

export default function (availableOptions) {
  // function used to remove options from editor, that do not anymore exist
  // because question / option have been removed
  const availableOptionIds = map(availableOptions, 'id');
  const isOptionAvailable = optId => availableOptionIds.includes(optId);

  function removeNotAvailableShowIfOptions(nodes) {
    each(nodes, (node) => {
      const optionId = node.getAttribute('data-showif');
      if (!isOptionAvailable(optionId)) {
        const nestedShowIfNodes = node.querySelectorAll('[data-showif]');
        const nestedReplaceNodes = node.querySelectorAll('[data-replace]');
        if (nestedShowIfNodes.length) {
          removeNotAvailableShowIfOptions(nestedShowIfNodes);
        }
        if (nestedReplaceNodes.length) {
          removeNotAvailableReplaceOptions(nestedReplaceNodes);
        }
        removeQuestionFromDom(node);
      }
    });
  }

  function removeNotAvailableReplaceOptions(nodes) {
    each(nodes, (node) => {
      const optionId = node.getAttribute('data-replace');
      if (!isOptionAvailable(optionId)) {
        removeQuestionFromDom(node);
      }
    });
  }

  const nodes = document.querySelectorAll('[data-showif]');
  const nodes2 = document.querySelectorAll('[data-replace]');
  removeNotAvailableShowIfOptions(nodes);
  removeNotAvailableReplaceOptions(nodes2);
}
