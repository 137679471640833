import assert from 'assert';
import { put, take } from 'redux-saga/effects';
import _get from 'lodash/get';
import { redirectError } from 'ducks/error/helpers';
import { push } from 'connected-react-router';
import { errorTypes } from 'ducks/error/constants';
import { accessTypes } from 'appRoot/constants/api';
import { DEFAULT_APP_PATH, LEXOLVE_LANDING_PAGE_PATH } from 'appRoot/constants';
import { Types, Creators } from './actions';
import { Creators as LoggedInUserCreators } from '../loggedInUser/actions';

export function createErrorsSagas({ googleAnalyticsService }) {
  assert.ok(googleAnalyticsService, 'googleAnalyticsService<googleAnalyticsService> is required');


  function* catchFailureActionsSaga() {
    while (true) {
      const failureAction = yield take(action => action.type.endsWith('FAILURE'));
      const { error, redirectOnErrorPath } = failureAction;
      const isUnauthorizedStatus = _get(error, 'status') === 401;
      yield put(
        isUnauthorizedStatus
          ? Creators.unauthorized(error, redirectOnErrorPath, failureAction.type)
          : Creators.error(error, redirectOnErrorPath, failureAction.type),
      );

      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  function* catchUnauthorizedErrorSaga() {
    while (true) {
      yield take(Types.UNAUTHORIZED);
      yield put(LoggedInUserCreators.signOut());
    }
  }

  function* errorSaga() {
    while (true) {
      const { error, redirectOnErrorPath } = yield take(Types.ERROR);
      const errorType = _get(error, 'data.message', '');
      const errorStatus = _get(error, 'status', '');
      if (errorType === 'user_disabled') {
        yield put(push('/error/no-access'));
      }
      if (redirectError(errorType) || redirectOnErrorPath || errorStatus === 500) {
        const source = _get(error, 'data.source');
        const redirectPath = source === accessTypes.GENERIC_ACCESS
          ? LEXOLVE_LANDING_PAGE_PATH
          : redirectOnErrorPath || DEFAULT_APP_PATH;
        yield put(Creators.redirectError(error, redirectPath));
      }
    }
  }

  function* redirectOnErrorSaga() {
    while (true) {
      const { error } = yield take(Types.REDIRECT_ERROR);
      const errorType = _get(error, 'data.message', '');
      if (errorTypes.DOCUMENT_IN_TRASH === errorType || _get(error, 'status', '') === 404) {
        yield put(push('/error/not-found'));
      } else {
        yield put(push('/external/error'));
      }
    }
  }

  function* logErrorToRollbarSaga() {
    while (true) {
      const { error, actionType } = yield take(Types.ERROR);
      const errorType = _get(error, 'data.message', '');
      yield put(Creators.logToRollbar({
        analytics: {
          type: actionType,
          payload: {
            command: 'error',
            error,
            info: errorType,
          },
        },
      }));
    }
  }

  function* logToGoogleAnalyticsSaga() {
    while (true) {
      const { error } = yield take([Types.ERROR, Types.UNAUTHORIZED]);
      googleAnalyticsService.sendEvent({
        action: 'exception',
        category: 'Error Page',
        label: 'errorMessage',
        value: error,
      });
    }
  }

  return {
    catchFailureActionsSaga,
    catchUnauthorizedErrorSaga,
    errorSaga,
    redirectOnErrorSaga,
    logErrorToRollbarSaga,
    logToGoogleAnalyticsSaga,
  };
}
