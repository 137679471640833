import React from 'react';
import PropTypes from 'prop-types';

class Option extends React.PureComponent {
  static propTypes = { children: PropTypes.node.isRequired };

  render() {
    const { children, ...otherProps } = this.props;

    return <option {...otherProps}>{children}</option>;
  }
}

export default Option;
