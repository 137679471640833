export const translations = {
  "en": {
    "api.panel.questionnaire_templates.modal_title": "Templates",
    "api.panel.questionnaire_templates.back": "Back",
    "api.panel.questionnaire_templates.already_in_folder": "Item is already in this folder",
    "api.panel.questionnaire_templates.actions.menu_template_information": "Template information",
    "api.panel.questionnaire_templates.actions.menu_template_settings": "Template settings",
    "api.panel.questionnaire_templates.actions.menu_move_to": "Move to...",
    "api.panel.questionnaire_templates.actions.move_to": "Move to",
    "api.panel.questionnaire_templates.actions.share": "Share document link",
    "api.panel.questionnaire_templates.actions.move": "Move here",
    "api.panel.questionnaire_templates.actions.cancel": "Cancel",
    "api.panel.questionnaire_templates.actions.trash": "Move to trash",
    "api.panel.questionnaire_templates.actions.duplicate": "Duplicate",
    "api.panel.questionnaire_templates.create.new_template": "New template",
    "api.panel.questionnaire_template_versions.user_questionnaires.create.allTemplates": "All templates",
    "api.panel.questionnaire_template_versions.user_questionnaires.create.new_document": "New document",
    "forgot_password.title": "Forgot your password?",
    "forgot_password.subtitle": "Enter your email below and we will send you instructions to reset your password.",
    "forgot_password.labels.email": "Email",
    "forgot_password.labels.submit": "Reset password",
    "forgot_password.labels.already_logged": "Already a user? Sign in here",
    "forgot_password.errors.invalid_email": "Invalid email",
    "forgot_password.errors.email_not_exist": "Provided email doesn't exist",
    "forgot_password.errors.cant_be_blank": "Can't be blank",
    "forgot_password.verified.title": "Reset Password Confirmation",
    "forgot_password.verified.paragraph": "If an account exists with the email you entered, we will send you instructions on how to create a new password.<br><br>\nIf you didn't receive the email check your spam folder or double check the email address you entered and try again. \n",
    "forgot_password.verified.log_in": "Log in here",
    "reset_password.title": "New password",
    "reset_password.labels.password": "New Password",
    "reset_password.labels.confirm_password": "Confirm Password",
    "reset_password.labels.submit": "Save password",
    "reset_password.labels.already_logged": "Already a user? Sign in here",
    "reset_password.errors.must_be_the_same": "Passwords must match",
    "reset_password.errors.password_not_strong_enough": "This password is not strong enough",
    "reset_password.errors.cant_be_blank": "Can't be blank",
    "sign_in.title": "Log in to your account",
    "sign_in.heading": "Welcome to Lexolve",
    "sign_in.subheading": "Please enter your login details below.",
    "sign_in.labels.email": "Email",
    "sign_in.labels.password": "Password",
    "sign_in.labels.submit": "Login",
    "sign_in.labels.forgot_password": "Reset password",
    "sign_in.errors.invalid_email": "Invalid email",
    "sign_in.errors.cant_be_blank": "Can't be blank",
    "sign_in.errors.wrong_password": "The email or password you entered was incorrect. Please try again.",
    "company_mailer.invite_employee.how_to_start": "How to start?",
    "company_mailer.invite_employee.create_template_with_editor": "Create template with editor",
    "company_mailer.invite_employee.create_template_with_editor_description": "Aliquip exercitation nostrud ad tempor ipsum exercitation tempor. Tempor amet ad do laboris. Sint excepteur cillum magna labore. Eu occaecat incididunt sit sint eiusmod qui officia exercitation eiusmod eiusmod qui veniam. Lorem velit enim enim magna sunt deserunt duis eu dolor elit dolor.",
    "company_mailer.invite_employee.manage_versions": "Manage versions",
    "company_mailer.invite_employee.manage_versions_description": "Aliquip exercitation nostrud ad tempor ipsum exercitation tempor. Tempor amet ad do laboris. Sint excepteur cillum magna labore. Eu occaecat incididunt sit sint eiusmod qui officia exercitation eiusmod eiusmod qui veniam. Lorem velit enim enim magna sunt deserunt duis eu dolor elit dolor.",
    "company_mailer.invite_employee.send_to_clients": "Send to clients",
    "company_mailer.invite_employee.send_to_clients_description": "Aliquip exercitation nostrud ad tempor ipsum exercitation tempor. Tempor amet ad do laboris. Sint excepteur cillum magna labore. Eu occaecat incididunt sit sint eiusmod qui officia exercitation eiusmod eiusmod qui veniam. Lorem velit enim enim magna sunt deserunt duis eu dolor elit dolor.",
    "company_mailer.invite_employee.need_help": "Need help? Please contact us :",
    "company_mailer.invite_employee.with_regards_html": "With regards <br> <b>Team Lawbotics</b>",
    "company_mailer.invite_employee.invite": "INVITE",
    "company_mailer.invite_employee.welcome": "Welcome to Lawbotics",
    "company_mailer.invite_employee.hello": "Hello %{email},",
    "company_mailer.invite_employee.activate_account": "Activate my account",
    "company_mailer.invite_employee.invite_message": "You’ve been invited to join the %{company}  workspace in Lawbotics. Click the button below to set up and create your account.",
    "company_mailer.invite_employee.subject": "Your invitation to Lawbotics",
    "company_mailer.invite_admin.how_to_start": "How to start?",
    "company_mailer.invite_admin.create_template_with_editor": "Create template with editor",
    "company_mailer.invite_admin.create_template_with_editor_description": "Aliquip exercitation nostrud ad tempor ipsum exercitation tempor. Tempor amet ad do laboris. Sint excepteur cillum magna labore. Eu occaecat incididunt sit sint eiusmod qui officia exercitation eiusmod eiusmod qui veniam. Lorem velit enim enim magna sunt deserunt duis eu dolor elit dolor.",
    "company_mailer.invite_admin.manage_versions": "Manage versions",
    "company_mailer.invite_admin.manage_versions_description": "Aliquip exercitation nostrud ad tempor ipsum exercitation tempor. Tempor amet ad do laboris. Sint excepteur cillum magna labore. Eu occaecat incididunt sit sint eiusmod qui officia exercitation eiusmod eiusmod qui veniam. Lorem velit enim enim magna sunt deserunt duis eu dolor elit dolor.",
    "company_mailer.invite_admin.send_to_clients": "Send to clients",
    "company_mailer.invite_admin.send_to_clients_description": "Aliquip exercitation nostrud ad tempor ipsum exercitation tempor. Tempor amet ad do laboris. Sint excepteur cillum magna labore. Eu occaecat incididunt sit sint eiusmod qui officia exercitation eiusmod eiusmod qui veniam. Lorem velit enim enim magna sunt deserunt duis eu dolor elit dolor.",
    "company_mailer.invite_admin.need_help": "Need help? Please contact us :",
    "company_mailer.invite_admin.with_regards_html": "With regards <br> <b>Team Lawbotics</b>",
    "company_mailer.invite_admin.admin": "Admin",
    "company_mailer.invite_admin.hello": "Now you may register to the site and start inviting others to join your company.",
    "company_mailer.invite_admin.congratulations": "Congratulations",
    "company_mailer.invite_admin.invite_message": "Have fun, and don't hesitate to contact us with your feedback.",
    "company_mailer.invite_admin.register": "Register your account",
    "company_mailer.invite_admin.subject": "Your invitation to Lawbotics",
    "top_navbar.settings": "Settings",
    "top_navbar.profile_settings": "Profile settings",
    "top_navbar.log_out": "Sign out",
    "top_navbar.dashboard": "Dashboard",
    "top_navbar.templates": "Templates",
    "top_navbar.documents": "Documents",
    "top_navbar.users": "Team",
    "top_navbar.template_library": "Templates library",
    "top_navbar.help": "Help",
    "top_navbar.logo_alt": "Lawbotics",
    "top_navbar.company": "Company",
    "top_navbar.team": "Team",
    "top_navbar.template_styles": "Template styles",
    "top_navbar.company_settings": "Company setttings",
    "top_navbar.search": "Search...",
    "top_navbar.home": "Home",
    "top_navbar.create_document": "Create document",
    "top_navbar.log_in": "Sign in",
    "search_no_results.header": "No results found",
    "search_no_results.subheader": "Try typing in other phrase",
    "search_results.heading": "Search results",
    "devise.confirmations.confirmed": "Your email address has been successfully confirmed.",
    "devise.confirmations.send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
    "devise.confirmations.send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes.",
    "devise.failure.already_authenticated": "You are already signed in.",
    "devise.failure.inactive": "Your account is not activated yet.",
    "devise.failure.invalid": "Invalid %{authentication_keys} or password.",
    "devise.failure.locked": "Your account is locked.",
    "devise.failure.last_attempt": "You have one more attempt before your account is locked.",
    "devise.failure.not_found_in_database": "Invalid %{authentication_keys} or password.",
    "devise.failure.timeout": "Your session expired. Please sign in again to continue.",
    "devise.failure.unauthenticated": "You need to sign in or sign up before continuing.",
    "devise.failure.unconfirmed": "You have to confirm your email address before continuing.",
    "devise.mailer.confirmation_instructions.subject": "Confirmation instructions",
    "devise.mailer.reset_password_instructions.subject": "Reset password instructions",
    "devise.mailer.reset_password_instructions.hello": "Hello, %{name}",
    "devise.mailer.reset_password_instructions.change_password": "Reset Your Password",
    "devise.mailer.reset_password_instructions.first_paragraph": "You've recently asked to reset the password. To update your password, click the button below:",
    "devise.mailer.reset_password_instructions.button_text": "Reset my password",
    "devise.mailer.reset_password_instructions.second_paragraph": "If you did not request your password to be reset and believe your account has been compromised, please",
    "devise.mailer.reset_password_instructions.mail_to_text": "contact us",
    "devise.mailer.reset_password_instructions.help": ".",
    "devise.mailer.reset_password_instructions.greetings": "Best regards, ",
    "devise.mailer.unlock_instructions.subject": "Unlock instructions",
    "devise.mailer.email_changed.subject": "Email Changed",
    "devise.mailer.password_change.subject": "Password Changed",
    "devise.omniauth_callbacks.failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
    "devise.omniauth_callbacks.success": "Successfully authenticated from %{kind} account.",
    "devise.passwords.no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
    "devise.passwords.send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
    "devise.passwords.send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
    "devise.passwords.updated": "Your password has been changed successfully. You are now signed in.",
    "devise.passwords.updated_not_active": "Your password has been updated.",
    "devise.passwords.new.forgot_password_title": "Forgot your password?",
    "devise.passwords.new.forgot_password_subtitle": "Enter your email below and we will send you instructions to reset your password.",
    "devise.passwords.new.email": "Email",
    "devise.passwords.new.send_new_password": "Reset password",
    "devise.passwords.edit.create_new_password": "New password",
    "devise.passwords.edit.new_password": "New password",
    "devise.passwords.edit.confirm_password": "Confirm password",
    "devise.passwords.edit.save_password": "Save password",
    "devise.passwords.edit.short": "Password is too short.",
    "devise.passwords.edit.weak": "Your password is weak. Add letters (uppercase/downcase).",
    "devise.passwords.edit.good": "Good. Add numbers or special characters.",
    "devise.passwords.edit.strong": "Your password is STRONG",
    "devise.registrations.destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
    "devise.registrations.signed_up": "Welcome! You have signed up successfully.",
    "devise.registrations.signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
    "devise.registrations.signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
    "devise.registrations.signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
    "devise.registrations.update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirm link to confirm your new email address.",
    "devise.registrations.updated": "Your account has been updated successfully.",
    "devise.sessions.signed_in": "Signed in successfully.",
    "devise.sessions.signed_out": "Signed out successfully.",
    "devise.sessions.already_signed_out": "Signed out successfully.",
    "devise.sessions.new.log_in": "Log in to your account",
    "devise.sessions.new.email": "Email",
    "devise.sessions.new.password": "Password",
    "devise.sessions.new.forgot_password": "Forgot password?",
    "devise.unlocks.send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
    "devise.unlocks.send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
    "devise.unlocks.unlocked": "Your account has been unlocked successfully. Please sign in to continue.",
    "devise.shared.links.already_user": "Already a user? Sign in here",
    "devise.shared.links.register": "Register your Lawbotics account",
    "devise.shared.devise_navbar.home": "Home",
    "devise.shared.devise_navbar.create_document": "Create document",
    "devise.shared.devise_navbar.log_in": "Log in",
    "devise.shared.devise_navbar.create_account": "Create account",
    "devise.shared.tooltip.characters_count": "At least 6 characters",
    "devise.shared.tooltip.letters_numbers": "Use letters and numbers",
    "devise.shared.tooltip.mix": "Mix lower and uppercase",
    "errors.messages.already_confirmed": "was already confirmed, please try signing in",
    "errors.messages.confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
    "errors.messages.expired": "has expired, please request a new one",
    "errors.messages.not_found": "not found",
    "errors.messages.not_locked": "was not locked",
    "errors.messages.not_saved.one": "1 error prohibited this %{resource} from being saved:",
    "errors.messages.not_saved.other": "%{count} errors prohibited this %{resource} from being saved:",
    "document_details.versions.see_latest_version": "See new version",
    "document_details.version_draft.info_message": "This document is being edited. It must be saved before it can be downloaded or sent.",
    "documents_list.columns_names.document": "Document",
    "documents_list.columns_names.created_at": "Created at",
    "documents_list.columns_names.edited_at": "Edited at",
    "documents_list.columns_names.status": "Status",
    "documents_list.columns_names.created_by": "Created by",
    "documents_list.actions.move_to_trash": "Move to trash",
    "documents_list.empty_bucket.heading": "This folder is empty",
    "documents_list.empty_bucket.paragraph": "Click the button below to create a new document.",
    "documents_list.empty_bucket.buttonLabel": "Create new document",
    "documents_list.delete_document_modal.title": "Deleting a document to be signed",
    "documents_list.delete_document_modal.description": "You are about to delete a document that is in a signing process.",
    "documents_list.delete_document_modal.reverse_description": "This action can’t be reversed. It will also cancel the signing process.",
    "documents_list.delete_document_modal.wish_to_abort": "Do you wish to abort the signing and delete the document?",
    "documents_list.delete_document_modal.delete": "Delete",
    "documents_list.duplicate_document.title": "Duplicating document",
    "documents_list.duplicate_document.subheading": "Please give the duplicate a name so you can recognize it from the original.",
    "documents_list.duplicate_document.copying_from_old_template_version": "Note: The document you are copying is based on an older template. If you want the latest version, please create a new document.",
    "documents_list.duplicate_document.copy": "Copy",
    "documents_list.duplicate_document.label": "Document name",
    "documents_list.trash": "Trash",
    "documents_list.based_on": "Based on",
    "documents_list.version": "Version",
    "documents_list.documents_count": "documents",
    "documents_list.new_folder": "Create folder",
    "documents_list.enter_folder_name": "Enter folder name",
    "document_templates.header_and_footer": "Header and footer",
    "document_templates.header": "Header",
    "document_templates.footer": "Footer",
    "document_templates.add_pagination": "Add pagination",
    "document_templates.styles": "Styles",
    "document_templates.paragraphs": "Paragraphs",
    "document_templates.headlines": "Headlines",
    "document_templates.font": "Font",
    "document_templates.font_size": "Font size",
    "document_templates.margins": "Page margins",
    "document_templates.top": "Top",
    "document_templates.right": "Right",
    "document_templates.bottom": "Bottom",
    "document_templates.left": "Left",
    "document_templates.h1": "Heading 1",
    "document_templates.h2": "Heading 2",
    "document_templates.h3": "Heading 3",
    "document_templates.h4": "Heading 4",
    "document_templates.title": "Title",
    "document_templates.numbering.label": "Numbering",
    "document_templates.numbering.description": "When ‘On’ all headers in the templates will be shown as a numbered list using the format 1., 1.1., 1.1.1. and 1.1.1.1. Titles will not be numbered.",
    "document_templates.numbering.switch_on": "On",
    "document_templates.numbering.switch_off": "Off",
    "drift.welcome_message": "Hi, how can we help you?",
    "drift.away_message": "We’re not currently online right now but if you leave a message, we’ll get back to you as soon as possible!",
    "drift.email_capture_message": "Leave your email so we can get back to you.",
    "drift.thank_you_message": "Thanks, we'll follow up soon.",
    "activerecord.errors.messages.too_weak": "Your password is not secure. Use hints to make it stronger.",
    "activerecord.errors.models.user.attributes.email.invalid": "Email is invalid",
    "general.are_you_sure": "Are you sure?",
    "general.yes": "Yes",
    "general.no": "No",
    "general.hide": "Hide",
    "general.show": "Show",
    "views.pagination.next": ">",
    "views.pagination.previous": "<",
    "views.pagination.truncate": "...",
    "time.formats.first": "%d.%m.%Y",
    "time.formats.date_time": "%d.%m.%Y, %H:%M",
    "time.am": "AM",
    "time.pm": "PM",
    "errors.template_preview": "Sorry, something went wrong.",
    "errors.email?": "is incorrect",
    "errors.sender_has_to_sign": "sender must be included into recipient lists",
    "errors.page_not_authorized": "You are not authorized to access this page.",
    "errors.action_not_authorized": "You are not authorized to perform this action.",
    "errors.not_authorized": "You are not authorized.",
    "errors.permissions.super_admin": "You have no permission to access super admin section",
    "errors.permissions.admin": "You have no permission to access admin section",
    "errors.permissions.client": "You have no permission to access the admin section",
    "application.error.header": "There is a small problem.",
    "application.error.back": "Go back to main page.",
    "shared.save": "Save",
    "shared.cancel": "Cancel",
    "shared.ok": "Ok",
    "shared.next": "Next",
    "main.new_folder.is_required": "Please enter folder name",
    "main.new_folder.new_folder": "Create folder",
    "main.new_folder.enter_folder_name": "Enter folder name",
    "main.index.help_improve": "Help us improve - Do you have any suggestions or suggestions?",
    "main.index.give_feedback": "Give feedback",
    "main.home_navbar.about_lawbotics": "About Lawbotics",
    "main.home_navbar.how_works": "This is how it works",
    "main.home_navbar.about_agreements": "About shareholders agreements",
    "main.home_navbar.create_document": "Create document",
    "main.home_navbar.client_panel": "Client panel",
    "main.home_navbar.log_in": "Log in",
    "main.home_intro.create_agreement": "Create shareholder agreement",
    "main.home_intro.subtitle": "Create a completed shareholder agreement tailored to your needs",
    "main.home_intro.create_document": "Create document",
    "main.home_testimonials.even_testimonials": "Finally, a digital service that helps startups with what they usually need attorney to!",
    "main.home_testimonials.even_title": "General Manager Nabobil",
    "main.home_testimonials.christine_testimonials": "The questions are simple and guide you through the most important conversation you may have in the creation phase.",
    "main.home_testimonials.christine_title": "Entrepreneur of Listfully",
    "main.home_boxes.why_agreement_title": "Why shareholder agreement?",
    "main.home_boxes.why_agreement_par": "Shareholder agreement is the most important foundation for the relationship between the owners of an AS. It explains how to drive the company together.",
    "main.home_boxes.more_than_template_title": "More than template",
    "main.home_boxes.more_than_template_par": "A shareholder agreement created with Lawbotics is tailored to",
    "main.home_boxes.their": "their",
    "main.home_boxes.needs": "needs",
    "main.home_boxes.fraction_cost": "for a fraction of the cost of using a lawyer.",
    "main.home_boxes.security_title": "The security you need",
    "main.home_boxes.security_par": "Smart entrepreneurs decide how difficult situations are solved before they occur. With Lawbotics, the risk of surprises is minimized later.",
    "main.home_steps.steps_title": "How do I make a shareholder agreement in Lawbotics?",
    "main.home_steps.step1_title": "Start creating the document",
    "main.home_steps.step1_par": "First, you fill out information about the company and the parties to the agreement.",
    "main.home_steps.create_document": "Create document",
    "main.home_steps.step2_title": "Answer to Question",
    "main.home_steps.step2_par": "Then you answer questions by choosing between different answer options. Both questions and answers are designed by lawyers with extensive experience in the field, to ensure that the shareholder agreement is adapted to their situation and needs.",
    "main.home_steps.step3_tile": "The agreement is completed in less than 20 minutes",
    "main.home_steps.step3_par": "When all questions have been answered, only email and payment information are required. Then the completed agreement is sent to you in word format so it can be edited and shared with others.",
    "main.home_orders_type.home_orders_title": "What is important when you enter into a shareholder agreement?",
    "main.home_orders_type.read_more": "Read more",
    "main.home_orders_type.enterpreneurs": "If you are more entrepreneurs who join a company together.",
    "main.home_orders_type.shareholders": "If you are two shareholders who each own 50% of the company.",
    "main.home_orders_type.agreement_employees": "Should a shareholders agreement be entered into with employees.",
    "main.home_orders_type.external_investor": "If enterpreneurs enter into an agreement with an external investor.",
    "main.footer.about_lawbotics": "About Lawbotics",
    "main.footer.terms_of_use": "Purchase and Terms of Use.",
    "main.footer.price": "Price",
    "main.footer.contact": "Contact",
    "main.footer.about_agreements": "About shareholders agreements",
    "main.footer.enterpreneurs": "Between entrepreneurs",
    "main.footer.enterpreneurs_50_50": "Between entrepreneurs who own 50/50",
    "main.footer.enterpreneurs_employees": "Between entrepreneurs and employees",
    "main.footer.enterpreneurs_investors": "Between entrepreneurs and external investors",
    "main.footer.newsletter": "Newsletter",
    "main.footer.newsletter_par": "Would you like advice on how to avoid the most common mistakes entrepreneurs do? Keep up to date with new rules and things to watch by signing up for the Lawbotics newsletter here!",
    "main.footer.copyright": "2019 All rights reserved for this site are reserved to Lawbotics JR AS, corporate no. 918567852",
    "main.footer.privacy": "About privacy and use of cookies on the website",
    "pages.account_verification.account_verified": "Account verivied",
    "pages.account_verification.receive_in_minutes": "In a few minutes you will receive an",
    "pages.account_verification.email_information": "email with information",
    "pages.account_verification.how_to": "about how to create a new password.",
    "pages.account_verification.log_in": "Log in here",
    "superadmin.layouts.sadmin_navbar.users": "Users",
    "superadmin.layouts.sadmin_navbar.templates": "Templates",
    "superadmin.layouts.sadmin_navbar.documents": "Documents",
    "superadmin.layouts.sadmin_navbar.settings": "Settings",
    "superadmin.layouts.sadmin_navbar.log_out": "Log out",
    "superadmin.users.index.today": "Today",
    "superadmin.users.index.last_week": "Last week",
    "superadmin.users.index.last_month": "Last month",
    "superadmin.users.index.last_year": "Last year",
    "superadmin.users.index.from": "from:",
    "superadmin.users.index.till": "till:",
    "superadmin.users.index.users_registered": "Users registrations",
    "superadmin.users.index.users_active": "Users logins",
    "superadmin.users.index.users_with_orders": "Users making orders",
    "superadmin.users.index.users": "Users",
    "superadmin.users.index.users_subtitle": "List of all registered users",
    "superadmin.users.index.search": "Search...",
    "superadmin.users.index.user_name": "User name",
    "superadmin.users.index.email": "Email",
    "superadmin.users.index.company": "Company",
    "superadmin.users.index.role": "Role",
    "superadmin.users.index.login_total": "Login total",
    "superadmin.users.index.last_active": "Last active",
    "superadmin.users.user.user_name": "Name",
    "superadmin.users.user.preview": "Preview",
    "superadmin.users.user.company": "Company",
    "superadmin.users.user.role": "Role",
    "superadmin.users.user.last_active": "Last active",
    "superadmin.users.user.edit": "Edit",
    "superadmin.users.user.delete": "Delete",
    "superadmin.users.show.back": "Go back to users list",
    "superadmin.users.show.registered": "Registered:",
    "superadmin.users.show.agreement": "Agreement",
    "superadmin.users.show.creation_date": "Creation date",
    "superadmin.users.show.last_edit": "Last edit",
    "superadmin.users.show.progress": "Progress",
    "superadmin.users.show.activity": "Activity",
    "superadmin.users.show.time": "Time",
    "superadmin.users.show.edit_user": "Edit user",
    "superadmin.users.show.company": "Company",
    "superadmin.users.show.role": "Role",
    "superadmin.users.show.contact": "Contact",
    "superadmin.users.show.templates": "Templates",
    "superadmin.users.show.documents": "Documents",
    "superadmin.users.documents.document": "Name",
    "superadmin.users.documents.user": "User",
    "superadmin.users.documents.creation_date": "Creation date",
    "superadmin.users.documents.last_edit": "Last edit",
    "superadmin.users.documents.progress": "Progress",
    "superadmin.users.templates.template_name": "Name",
    "superadmin.users.templates.created_by": "Created by",
    "superadmin.users.templates.status": "Status",
    "superadmin.users.edit.back": "Go back to users list",
    "superadmin.users.edit.first_name": "First name",
    "superadmin.users.edit.last_name": "Last name",
    "superadmin.users.edit.email": "Email",
    "superadmin.users.edit.role": "Role",
    "superadmin.users.edit.company_admin": "Company admin",
    "superadmin.users.edit.producer": "Producer",
    "superadmin.users.edit.consumer": "Consumer",
    "superadmin.users.edit.save": "Save",
    "superadmin.users.edit.delete_user": "Delete user",
    "superadmin.users.edit.active_user": "Active user:",
    "superadmin.users.edit.yes": "Yes",
    "superadmin.users.edit.no": "No",
    "superadmin.users.edit.reinvite_user": "Reinvite user",
    "superadmin.users.edit.not_authorized": "You are not authorized to access this page",
    "superadmin.users.edit.confirm_resend_invitation": "Are you sure you want resend invitation?",
    "superadmin.users.destroy.destroy": "User was succesfully deleted",
    "superadmin.users.update.success": "User information was successfully updated",
    "superadmin.settings.edit.account": "Account settings",
    "superadmin.settings.edit.account_subtitle": "Personal info & account access",
    "superadmin.settings.edit.personal_information": "Personal information",
    "superadmin.settings.edit.password_change": "Password change",
    "superadmin.settings.edit.current_password": "Current password",
    "superadmin.settings.edit.save_changes": "Save changes",
    "superadmin.settings.edit.first_name": "First name",
    "superadmin.settings.edit.last_name": "Last name",
    "superadmin.settings.edit.email": "Email",
    "superadmin.settings.edit.new_password": "New password",
    "superadmin.settings.edit.confirm_password": "Confirm password",
    "superadmin.settings.edit.save_password": "Save your new password",
    "superadmin.settings.edit.short": "Password is too short.",
    "superadmin.settings.edit.weak": "Your password is weak. Add letters (uppercase/downcase).",
    "superadmin.settings.edit.good": "Add numbers or special characters.",
    "superadmin.settings.edit.strong": "Your password is strong",
    "superadmin.documents.index.today": "Today",
    "superadmin.documents.index.last_week": "Last week",
    "superadmin.documents.index.last_month": "Last month",
    "superadmin.documents.index.last_year": "Last year",
    "superadmin.documents.index.from": "from:",
    "superadmin.documents.index.till": "till:",
    "superadmin.documents.index.agreements_created": "Opened documents",
    "superadmin.documents.index.agreements_finished": "Finished documents",
    "superadmin.documents.index.documents": "Created documents",
    "superadmin.documents.index.documents_subtitle": "List of all agreements",
    "superadmin.documents.index.search": "Search...",
    "superadmin.documents.index.document": "Document",
    "superadmin.documents.index.user": "User",
    "superadmin.documents.index.creation_date": "Creation date",
    "superadmin.documents.index.last_edit": "Last edited",
    "superadmin.documents.index.progress": "Progress",
    "superadmin.documents.index.download": "Download",
    "superadmin.companies.index.add_company": "Add company",
    "superadmin.companies.index.companies": "Companies",
    "superadmin.companies.index.companies_subtitle": "List of all created companies",
    "superadmin.companies.index.search": "Search...",
    "superadmin.companies.index.company_name": "Company name",
    "superadmin.companies.index.members": "Members",
    "superadmin.companies.index.templates": "Templates",
    "superadmin.companies.index.documents": "Documents",
    "superadmin.companies.index.filters": "Filters",
    "superadmin.companies.show.back": "Go back to companies",
    "superadmin.companies.show.registered": "Registered: ",
    "superadmin.companies.show.company_name": "Company name",
    "superadmin.companies.show.company_contact": "Company contact",
    "superadmin.companies.show.members": "Members",
    "superadmin.companies.show.templates": "Templates",
    "superadmin.companies.show.documents": "Documents",
    "superadmin.companies.form.back": "Back to companies",
    "superadmin.companies.form.create_company": "Create company",
    "superadmin.companies.form.edit_company": "Edit company",
    "superadmin.companies.form.name": "Company name",
    "superadmin.companies.form.organization_number": "Organisation Number",
    "superadmin.companies.form.address": "Address",
    "superadmin.companies.form.address1": "Address line 1",
    "superadmin.companies.form.address2": "Address line 2",
    "superadmin.companies.form.postal_code": "Postal code",
    "superadmin.companies.form.city": "City",
    "superadmin.companies.form.country": "Country",
    "superadmin.companies.form.phone_number": "Phone number",
    "superadmin.companies.form.company_admin_first_name": "First name",
    "superadmin.companies.form.company_admin_last_name": "Last name",
    "superadmin.companies.form.company_admin_email": "Email",
    "superadmin.companies.form.save": "Save",
    "superadmin.companies.form.create": "Create and invite",
    "superadmin.companies.form.admin_fields": "Admin details",
    "superadmin.companies.create.success": "Company has been created",
    "superadmin.companies.create.failed": "Error creating company - %{error}",
    "superadmin.companies.update.success": "Company has been updated",
    "superadmin.companies.update.failed": "Company has not been updated - %{error}",
    "superadmin.companies.company.company_name": "Company name",
    "superadmin.companies.company.preview": "Preview",
    "superadmin.companies.company.members": "Members",
    "superadmin.companies.company.templates": "Templates",
    "superadmin.companies.company.documents": "Documents",
    "superadmin.companies.documents.document": "Name",
    "superadmin.companies.documents.user": "User",
    "superadmin.companies.documents.creation_date": "Creation date",
    "superadmin.companies.documents.last_edit": "Last edit",
    "superadmin.companies.documents.progress": "Progress",
    "superadmin.companies.members.user_name": "User name",
    "superadmin.companies.members.company": "Company",
    "superadmin.companies.members.role": "Role",
    "superadmin.companies.members.last_active": "Last active",
    "superadmin.companies.templates.template_name": "Name",
    "superadmin.companies.templates.created_by": "Created by",
    "superadmin.companies.templates.status": "Status",
    "superadmin.invite_employees.form.new_invitation": "New invitation",
    "superadmin.invite_employees.form.invitation_subtitle": "Fill in the information below to create new users. We'll email your teammates instructions for logging in.",
    "superadmin.invite_employees.form.email": "Email address(es)",
    "superadmin.invite_employees.form.email_subtitle": "Separate multiple e-mail addresses with comma, ex: email1, email 2",
    "superadmin.invite_employees.form.role": "Role",
    "superadmin.invite_employees.form.company_admin": "Company Admin",
    "superadmin.invite_employees.form.admin_permissions": "Is able to manage users, create templates and documents",
    "superadmin.invite_employees.form.producer": "Producer",
    "superadmin.invite_employees.form.producer_permissions": "Is able to create templates and documents",
    "superadmin.invite_employees.form.consumer": "Consumer",
    "superadmin.invite_employees.form.consumer_permissions": "Is able to create documents",
    "superadmin.invite_employees.form.save": "Send invitation",
    "superadmin.invite_employees.create.success": "Invitation was sent",
    "superadmin.questionnaire_templates.index.templates": "Templates",
    "superadmin.questionnaire_templates.index.templates_subtitle": "List of all templates",
    "superadmin.questionnaire_templates.index.search": "Search...",
    "superadmin.questionnaire_templates.index.template_name": "Name",
    "superadmin.questionnaire_templates.index.created_by": "Created by",
    "superadmin.questionnaire_templates.index.status": "Status",
    "superadmin.questionnaire_templates.questionnaire_template.template_name": "Name",
    "superadmin.questionnaire_templates.questionnaire_template.preview": "Preview",
    "superadmin.questionnaire_templates.questionnaire_template.created_by": "Created by",
    "superadmin.questionnaire_templates.questionnaire_template.status": "Status",
    "superadmin.questionnaire_templates.folder_routes.templates": "Templates",
    "panel.layouts.panel_navbar.templates": "Templates",
    "panel.layouts.panel_navbar.documents": "Documents",
    "panel.layouts.panel_navbar.users": "Users",
    "panel.layouts.panel_navbar.templates_library": "Templates library",
    "panel.layouts.panel_navbar.settings": "Settings",
    "panel.layouts.panel_navbar.log_out": "Log out",
    "panel.settings.edit.account": "Account settings",
    "panel.settings.edit.account_subtitle": "Personal info & account access",
    "panel.settings.edit.edit_document_template": "Template styles",
    "panel.settings.edit.personal_information": "Personal information",
    "panel.settings.edit.password_change": "Password change",
    "panel.settings.edit.current_password": "Current password",
    "panel.settings.edit.save_changes": "Save changes",
    "panel.settings.edit.first_name": "First name",
    "panel.settings.edit.last_name": "Last name",
    "panel.settings.edit.email": "Email",
    "panel.settings.edit.new_password": "New password",
    "panel.settings.edit.confirm_password": "Confirm password",
    "panel.settings.edit.save_password": "Save your new password",
    "panel.settings.edit.short": "Password is too short.",
    "panel.settings.edit.weak": "Your password is weak. Add letters (uppercase/downcase).",
    "panel.settings.edit.good": "GOOD. Add numbers or special characters.",
    "panel.settings.edit.strong": "Your password is strong",
    "panel.settings.update.success": "You account was successfully updated",
    "panel.settings.update.failed": "You account was not updated",
    "panel.dashboard.show.welcome": "Welcome, ",
    "panel.dashboard.show.dashboard_header": "Dashboard? What dashboard?",
    "panel.dashboard.show.dashboard_subheader": "Click the support button on the right side to let us know<br> what kind of information you want to see on the dashboard.",
    "panel.dashboard.show.templates_url": "templates",
    "panel.dashboard.show.documents_url": "documents",
    "panel.dashboard.show.got_to": "Or go to managing your %{templates_href} or %{documents_href}.",
    "panel.questionnaire_templates.template_versions.date": "Date",
    "panel.questionnaire_templates.template_versions.version": "Version",
    "panel.questionnaire_templates.template_versions.publish": "Publish",
    "panel.questionnaire_templates.template_versions.change": "Change",
    "panel.questionnaire_templates.template_versions.created_by": "Create by",
    "panel.questionnaire_templates.index.templates_title": "Templates",
    "panel.questionnaire_templates.index.templates_subtitle": "List of all templates",
    "panel.questionnaire_templates.index.create_template": "New Template",
    "panel.questionnaire_templates.index.search": "Search...",
    "panel.questionnaire_templates.index.templates": "Templates",
    "panel.questionnaire_templates.index.template_name": "Name",
    "panel.questionnaire_templates.index.created_by": "Created by",
    "panel.questionnaire_templates.index.created_at": "Created at",
    "panel.questionnaire_templates.index.edited_at": "Last edit",
    "panel.questionnaire_templates.index.status": "Status",
    "panel.questionnaire_templates.index.new_folder": "Folder",
    "panel.questionnaire_templates.index.new_template": "Template",
    "panel.questionnaire_templates.index.rename": "Rename",
    "panel.questionnaire_templates.index.move_to": "Move to...",
    "panel.questionnaire_templates.index.delete": "Delete",
    "panel.questionnaire_templates.index.trash": "Trash",
    "panel.questionnaire_templates.questionnaire_template.template_name": "Name",
    "panel.questionnaire_templates.questionnaire_template.preview": "Preview",
    "panel.questionnaire_templates.questionnaire_template.edit": "Edit",
    "panel.questionnaire_templates.questionnaire_template.created_by": "Created by",
    "panel.questionnaire_templates.questionnaire_template.created_at": "Created at",
    "panel.questionnaire_templates.questionnaire_template.edited_at": "Edited at",
    "panel.questionnaire_templates.questionnaire_template.status": "Status",
    "panel.questionnaire_templates.questionnaire_template.unpublished_changes": "This template has an unpublished draft",
    "panel.questionnaire_templates.questionnaire_template.shared": "Open to anyone who has a link",
    "panel.questionnaire_templates.questionnaire_template.draft": "Draft",
    "panel.questionnaire_templates.questionnaire_template.user_deleted": "User does not exist",
    "panel.questionnaire_templates.questionnaire_template.trash": "Move to trash",
    "panel.questionnaire_templates.questionnaire_template.empty_bucket.heading": "This folder is empty",
    "panel.questionnaire_templates.questionnaire_template.empty_bucket.paragraph": "Click the button below to create a new template.",
    "panel.questionnaire_templates.questionnaire_template.empty_bucket.buttonLabel": "Create new template",
    "panel.questionnaire_templates.show.edit": "Edit",
    "panel.questionnaire_templates.show.view": "View",
    "panel.questionnaire_templates.show.create_draft": "Create draft",
    "panel.questionnaire_templates.show.documents": "Documents",
    "panel.questionnaire_templates.show.history": "History",
    "panel.questionnaire_templates.show.linked_laws": "Linked laws",
    "panel.questionnaire_templates.destroy.success": "Template was deleted succesufully",
    "panel.questionnaire_templates.destroy.not_authorized": "You cannot destroy this template. It may have published versions.",
    "panel.questionnaire_templates.folder_routes.templates": "Templates",
    "panel.questionnaire_templates.template_documents.document": "Document",
    "panel.questionnaire_templates.template_documents.created_at": "Created at",
    "panel.questionnaire_templates.template_documents.edited_at": "Edited at",
    "panel.questionnaire_templates.template_documents.status": "Status",
    "panel.questionnaire_templates.template_documents.created_by": "Created by",
    "panel.questionnaire_templates.template_documents.white_label": "White label",
    "panel.questionnaire_templates.template_documents.generic_access_link": "Generic access link user",
    "panel.questionnaire_templates.new.new_template": "New template",
    "panel.templates_library.index.templates_title": "Templates Library",
    "panel.templates_library.index.templates_subtitle": "All published templates",
    "panel.templates_library.index.search": "Search...",
    "panel.templates_library.index.create_template": "Create template",
    "panel.templates_library.index.template": "Name",
    "panel.templates_library.index.created_at": "Created at",
    "panel.templates_library.questionnaire_template.template": "Name",
    "panel.templates_library.questionnaire_template.created_at": "Created at",
    "panel.templates_library.questionnaire_template.preview": "Preview",
    "panel.templates_library.questionnaire_template.create_agreement": "Create new document",
    "panel.templates_library.folders.show.templates_title": "Templates library",
    "panel.templates_library.folders.show.templates_subtitle": "List of all published templates",
    "panel.templates_library.folders.show.filters": "Filters",
    "panel.templates_library.folders.show.folder": "New Folder",
    "panel.templates_library.folders.show.documents": "Document",
    "panel.templates_library.folders.show.template": "Name",
    "panel.templates_library.folders.show.created_at": "Created at",
    "panel.templates_library.folders.show.search": "Search...",
    "panel.templates_library.folders.show.create_template": "New Document",
    "panel.templates_library.folders.folder.templates_count": "%{val} templates",
    "panel.templates_library.folders.folder.templates_count_non_ruby": "{val} templates",
    "panel.templates_library.folders.subfolders.templates_count": "%{val} templates",
    "panel.templates_library.folders.subfolders.templates_count_non_ruby": "{val} templates",
    "panel.templates_library.folders.breadcrumbs.templates": "Templates",
    "panel.questionnaire_template_versions.edit.edit_error": "You cannot edit the template which is already published",
    "panel.questionnaire_template_versions.questionnaire_template_version.edit": "Edit",
    "panel.questionnaire_template_versions.questionnaire_template_version.view": "View",
    "panel.questionnaire_template_versions.questionnaire_template_version.create_draft": "Create draft",
    "panel.questionnaire_template_versions.questionnaire_template_version.created_at": "Created at",
    "panel.users.index.users": "Users",
    "panel.users.index.users_subtitle": "List of all company users",
    "panel.users.index.search": "Search...",
    "panel.users.index.invite_members": "Invite members",
    "panel.users.index.user": "User",
    "panel.users.index.role": "Role",
    "panel.users.index.last_active": "Last active",
    "panel.users.user.edit_user": "Edit user",
    "panel.users.user.first_name": "First name",
    "panel.users.user.last_name": "Last name",
    "panel.users.user.email": "Email",
    "panel.users.user.role": "Role",
    "panel.users.user.company_admin": "Company admin",
    "panel.users.user.producer": "Producer",
    "panel.users.user.consumer": "Consumer",
    "panel.users.user.save": "Save",
    "panel.users.user.delete_user": "Delete user",
    "panel.users.user.reinvite_user": "Reinvite user",
    "panel.users.edit.back": "Go back to users list",
    "panel.users.edit.first_name": "First name",
    "panel.users.edit.last_name": "Last name",
    "panel.users.edit.email": "Email",
    "panel.users.edit.role": "Role",
    "panel.users.edit.company_admin": "Company admin",
    "panel.users.edit.producer": "Producer",
    "panel.users.edit.consumer": "Consumer",
    "panel.users.edit.save": "Save",
    "panel.users.edit.delete_user": "Disable user",
    "panel.users.edit.active_user": "Active user:",
    "panel.users.edit.yes": "Yes",
    "panel.users.edit.no": "No",
    "panel.users.edit.reinvite_user": "Reinvite user",
    "panel.users.edit.confirm_resend_invitation": "Are you sure you want resend invitation?",
    "panel.users.edit.admin_permissions": "Is able to manage users, create templates and documents",
    "panel.users.edit.producer_permissions": "Is able to create templates and documents",
    "panel.users.edit.consumer_permissions": "Is able to create documents",
    "panel.users.form.admin_permissions": "Is able to manage users, create templates and documents",
    "panel.users.form.producer_permissions": "Is able to create templates and documents",
    "panel.users.form.consumer_permissions": "Is able to create documents",
    "panel.users.destroy.destroy": "User was succesfully disabled",
    "panel.users.invite_employees.form.new_invitation": "New invitation",
    "panel.users.invite_employees.form.invitation_subtitle": "Fill in the information below to create new users. We'll email your teammates instructions for logging in.",
    "panel.users.invite_employees.form.email": "Email address(es)",
    "panel.users.invite_employees.form.email_subtitle": "Separate multiple e-mail addresses with comma, ex: email1, email 2",
    "panel.users.invite_employees.form.role": "Role",
    "panel.users.invite_employees.form.company_admin": "Company Admin",
    "panel.users.invite_employees.form.admin_permissions": "Is able to manage users, create templates and documents",
    "panel.users.invite_employees.form.producer": "Producer",
    "panel.users.invite_employees.form.producer_permissions": "Is able to create templates and documents",
    "panel.users.invite_employees.form.consumer": "Consumer",
    "panel.users.invite_employees.form.consumer_permissions": "Is able to create documents",
    "panel.users.invite_employees.form.save": "Send invitation",
    "panel.users.invite_employees.create.success": "Invitations sent to new members.",
    "panel.users.invite_employees.create.failed": "There was an error inviting members to your company, please check the fields and try again. ",
    "panel.users.invite_employees.reinvite.success": "You have reinvited {email} to your company.",
    "panel.users.invite_employees.reinvite.failed": "You have failed to invite {email} to your company !",
    "panel.folders.destroy.destroyed": "Folder was succesfully destroyed",
    "panel.folders.destroy.cant_delete": "Folder cannot be destroyed because it contains templates.",
    "panel.folders.destroy.not_authorized": "You cannot delete this folder. It may have content inside.",
    "panel.folders.create.success": "Folder succesfully created",
    "panel.folders.create.failed": "Faild to create the folder",
    "panel.folders.update.success": "Folder succesfully updated",
    "panel.folders.update.failed": "Failed to update the folder",
    "panel.folders.folder.delete": "Delete",
    "panel.folders.folder.rename": "Rename",
    "panel.folders.folder.new_folder": "Folder",
    "panel.folders.folder.templates_count": "%{val} templates",
    "panel.folders.folder.templates_count_non_ruby": "{val} templates",
    "panel.folders.show.templates_title": "Templates",
    "panel.folders.show.templates_subtitle": "List of all templates",
    "panel.folders.show.search": "Search...",
    "panel.folders.show.create_template": "Create new",
    "panel.folders.show.new_subfolder": "Folder",
    "panel.folders.show.rename": "Rename",
    "panel.folders.show.move_to": "Move to...",
    "panel.folders.show.new_template": "Template",
    "panel.folders.show.delete": "Delete folder",
    "panel.folders.show.template_name": "Name",
    "panel.folders.show.created_at": "Created at",
    "panel.folders.show.edited_at": "Edited at",
    "panel.folders.show.status": "Status",
    "panel.folders.show.created_by": "Created by",
    "panel.folders.show.trash": "Trash",
    "panel.folders.breadcrumbs.templates": "Templates",
    "panel.folders.hover_breadcrumbs.templates": "Templates",
    "panel.folders.folder_modal.rename": "Rename folder",
    "panel.folders.folder_modal.create": "Create folder",
    "panel.folders.folder_modal.folder_name": "Enter folder name",
    "panel.trash.show.trash": "Trash",
    "panel.trash.show.empty_trash": "Empty trash",
    "panel.trash.show.search": "Search...",
    "panel.trash.show.trash_empty": "Trash is empty.",
    "panel.trash.show.template_name": "Name",
    "panel.trash.show.deleted_at": "Deleted at",
    "panel.trash.show.edited_at": "Last edited",
    "panel.trash.show.created_by": "Created by",
    "panel.trash.deleted_item.restore": "Restore",
    "panel.trash.deleted_item.delete": "Delete",
    "panel.trash.deleted_item.preview": "Preview",
    "panel.trash.deleted_item.templates": "Templates",
    "panel.trash.deleted_item.documents": "Documents",
    "panel.trash.delete_item_modal.delete_forever": "Delete forever?",
    "panel.trash.delete_item_modal.are_you_sure": "Are you sure you want to delete this file? {newLine}You can’t undo this action.",
    "panel.trash.delete_item_modal.cancel": "Cancel",
    "panel.trash.delete_item_modal.delete": "Delete",
    "panel.trash.empty_trash_modal.delete_forever": "Delete?",
    "panel.trash.empty_trash_modal.are_you_sure": "Are you sure you want to delete all files? {newLine}You can’t undo this action.",
    "panel.trash.empty_trash_modal.cancel": "Cancel",
    "panel.trash.empty_trash_modal.delete": "Delete",
    "panel.trash.empty_bucket.heading": "Trash is empty",
    "panel.trash.empty_bucket.paragraph": "No items were moved to trash",
    "invitations.accept.edit.unauthorized": "This invitation link isn't valid. Perhaps you already used it?",
    "invitations.accept.edit.set_up_password": "Set up a password",
    "invitations.accept.edit.set_up_subtitle": "Create your password and log in to your Lawbotics account",
    "invitations.accept.edit.register": "Register your account",
    "invitations.accept.edit.first_name": "First name",
    "invitations.accept.edit.last_name": "Last name",
    "invitations.accept.edit.email": "Email",
    "invitations.accept.edit.password": "Password",
    "invitations.accept.edit.at_least": "Password needs to be at least 10 characters long using a mix of lowercase and uppercase letters, numbers and a special character. ",
    "invitations.accept.edit.password_confirmation": "Repeat password",
    "invitations.accept.edit.create_account": "Set password and log in",
    "invitations.accept.edit.accept_by_clicking": "By proceeding to create your account and use Lawbotics, you agree to our",
    "invitations.accept.edit.terms_conditions": "Terms of Service.",
    "invitations.accept.edit.and": "and",
    "invitations.accept.edit.privacy_policy": "Privacy Policy",
    "invitations.accept.edit.newsletter": "I'd like to receive product updates, technical content and more from Lawbotics. I won't be spammed and can unsubscribe at anytime.",
    "invitations.accept.edit.short": "Password is too short.",
    "invitations.accept.edit.weak": "Your password is weak. Add letters (uppercase/downcase).",
    "invitations.accept.edit.good": "Add numbers or special characters.",
    "invitations.accept.edit.strong": "Your password is strong",
    "invitations.accept.update.success": "You have successfully updated your account",
    "invitations.accept.update.failed": "There was an error updating your account, please check the fields and try again.",
    "invitations.accept.tos.terms": "Terms and conditions",
    "invitations.accept.tos.close": "Close",
    "rails_email_preview.emails.send_form.send_are_you_sure": "Are you sure you want to send this e-mail?",
    "ransack.predicates.false": "is incorrect",
    "share_template.title": "Share document link",
    "share_template.description": "Anyone with this link can create a document using the template.",
    "share_template.enable": "Enable document link",
    "share_template.info": "Please note that any documents created with this link will be added to your company plan.",
    "share_template.copy": "Copy to clipboard",
    "share_template.copied": "Copied to clipboard",
    "share_template.not_available.action_button": "Go to template",
    "share_template.not_available.description": "Providing this link to other people will allow them to create a document using your template.",
    "share_template.not_available.info": "In order to create a document link the template needs to be published first",
    "share_wizard.errors.invalid_email": "Please enter a valid email address",
    "share_wizard.errors.no_document_title": "Document title cannot be empty",
    "share_wizard.name_contract": "Name your document",
    "share_wizard.name_contract_description.default": "Give your document a descriptive name so that you can easily find it again.",
    "share_wizard.name_contract_description.arv": "Welcome! We recommend that you write your will on a computer or similar. Here you can give the document a descriptive name so that you can easily find it again.",
    "share_wizard.provide_email": "Enter your email address and we'll send you a link to return to this document at any time in the future. ",
    "share_wizard.find_contract": "Find your way back",
    "share_wizard.accept": "I accept",
    "share_wizard.terms_and_condition": "Terms of Service",
    "share_wizard.create_document": "Create document",
    "share_wizard.enter_email": "Enter your email",
    "share_wizard.message": "Message",
    "share_wizard.template": "NDA Template",
    "template_settings.parallel": "Parallel",
    "template_settings.sequential": "Sequential",
    "template_settings.parallel_description": "Signers receive and sign the document all at the same time. Once the last person has completed the signing process, all parties will receive an email with a link to the signed document.",
    "template_settings.sequential_description": "Signers receive and sign the document in the order that their e-mail addresses are entered. Once the last person has completed the signing process, all parties receive an email with a link to the signed document.",
    "template_settings.title": "Template settings",
    "template_settings.date_format": "Date format",
    "template_settings.signing_enabled": "eSigning",
    "template_settings.signing_upsell_description": "eSigning with email and BankID is not yet part of your company plan. To enable this feature ask your administrator or",
    "template_settings.signing_upsell_email_link_description": "get in touch.",
    "template_settings.signing_methods": "Signing methods",
    "template_settings.signing_methods_tooltip": "The selected signing methods will be displayed when sending documents for signing. If you select multiple methods then you will be able to choose the appropriate method when sending a document for signing.",
    "template_settings.document_highlighting": "Answers highlighted in PDF",
    "template_settings.document_highlighting_tooltip": "Text answers given by the user will be highlighted in the final PDF",
    "template_settings.edit_document_enabled": "Document Editing",
    "template_settings.edit_document_enabled_for_consumer": "Allow document creators to edit",
    "template_settings.edit_document_tooltip": "Allows documents created from this templated to be edited by people with Producer rights.",
    "template_settings.update_settings_info_box": "You have changed a setting that will require a new version to be published. Please note that only documents created from this point on will benefit from the change.",
    "template_settings.patch_and_publish": "Publish",
    "template_settings.bankID": "BankID",
    "template_settings.email": "Email",
    "template_settings.sender_has_to_sign": "Sender has to sign",
    "template_settings.signing_order": "Signing order",
    "template_settings.missing_signing_methods": "Please provide at least one signing method",
    "template_settings.locale": "Locale",
    "template_settings.locales.en": "English (United Kingdom)",
    "template_settings.locales.nb": "Norway",
    "template_settings.cancel": "Cancel",
    "template_settings.save": "Save",
    "template_settings.publish": "Publish",
    "folder_list.rename_folder.error": "Foldername cannot be empty.",
    "folder_list.delete_folder.error": "You cannot delete this folder. It may have content inside.",
    "resend_link.success": "New link has been sent",
    "resend_link.error": "Something went wrong",
    "generic_access_mailer.document_created.email_title": "Your %{document}",
    "generic_access_mailer.document_created.hello": "Hello %{email},",
    "generic_access_mailer.document_created.intro": " ",
    "generic_access_mailer.document_created.content": "Click the button below at any time to return to your document.",
    "generic_access_mailer.document_created.open_contract": "Open document",
    "generic_access_mailer.document_created.greetings_depart": "With best regards,",
    "generic_access_mailer.document_created.support_reminder": " ",
    "generic_access_mailer.document_created.footer.help": "Help",
    "generic_access_mailer.document_created.footer.privacy_policy": "Terms of Service",
    "generic_access_mailer.document_created.footer.rights": "All rights reserved",
    "generic_access_mailer.document_created.footer.add_sender_to_address_book": "To ensure you receive our emails correctly, please add noreply@lawbotics.io to your address book.",
    "generic_access_mailer.resend_document.email_title": "New access link for %{document}",
    "helpers.select.prompt": "Please select",
    "user_invitations.title": "New invitation",
    "user_invitations.subtitle": "Fill in the information below to create new users. We'll email your teammates instructions for logging in.",
    "user_invitations.fields.first_name": "First Name",
    "user_invitations.fields.last_name": "Last Name",
    "user_invitations.fields.password": "Password",
    "user_invitations.fields.repeat_password": "Repeat Password",
    "user_invitations.send": "Save password and login",
    "user_invitations.terms_statement": "By proceeding to create your account and use Lawbotics, you agree to our {terms} and {privacy}",
    "user_invitations.terms_label": "Terms of Service",
    "user_invitations.privacy_label": "Privacy Policy",
    "user_invitations.cant_be_blank": "Can't be blank",
    "user_invitations.passwords_must_match": "Passwords must match",
    "user_invitations.password_not_strong_enough": "This password is not strong enough",
    "user_invitations.success.title": "Success",
    "user_invitations.success.description": "Welcome to Lawbotics",
    "user_invitations.failure.title": "Error",
    "user_invitations.failure.description": "Something went wrong. Try again and contact your administrator.",
    "invitations.accept.privacy_policy.terms": "Privacy Policy",
    "invitations.accept.privacy_policy.close": "Close",
    "invitations.accept.privacy_policy.01_0_title": "1. Formål",
    "invitations.accept.privacy_policy.01_paragraph": "Formålet med personopplysningsloven er blant annet å beskytte den enkeltes personvern. Lawbotics' Personvernpolicy skal, i likhet med loven, bidra til at personopplysninger blir behandlet i samsvar med grunnleggende personvernhensyn, herunder behovet for personlig integritet, privatlivets fred og tilstrekkelig kvalitet på de lagrede personopplysningene. Vår policy omfatter både innsamlingen av personopplysninger og den senere bruken av disse. Du skal til enhver tid ha oversikt over de opplysningene vi har om deg og ha anledning til å reservere deg i henhold til gjeldende lovgivning. Lawbotics skal behandle alle opplysninger på den måten som er beskrevet i denne personvernerklæringen, i henhold til fullmakten du gir oss og innenfor lovgivningen. Vi i Lawbotics vil at du skal være tilfreds med den måten vi behandler personopplysninger på.",
    "invitations.accept.privacy_policy.02_0_title": "2. Behandlingsansvarlig",
    "invitations.accept.privacy_policy.02_paragraph": "Lawbotics AS er hovedansvarlig for behandlingen av personopplysningene vi samler inn. For å forsikre oss om at opplysningene vi samler inn behandles på en betryggende måte, er det bare kvalifiserte ansatte som har tilgang til dine personopplysninger. Vi har gode interne rutiner for hvordan personopplysninger skal behandles. Vi benytter oss av avansert teknologi for å hindre at uvedkommende kommer inn på våre systemer og får tilgang til informasjon.<br><br>Vi kan la underleverandører (samarbeidspartnere) stå for den tekniske delen av databehandlingen. Databehandleren skal etter avtale med oss behandle personopplysningene dine på den måten vi bestemmer. Vi pålegger databehandleren å sørge for en forsvarlig informasjonssikkerhet og sletting av personopplysninger.",
    "invitations.accept.privacy_policy.03_0_title": "3. Rettslig grunnlag",
    "invitations.accept.privacy_policy.03_paragraph": "Får å opprette brukerprofil og bruke vår software må du gi ditt samtykke til våre vilkår og betingelser og personvernerklæring. Med det samtykker du blant annet til at vi kan oppbevare ditt fulle navn og din e-postadresse. Det er således ditt samtykke som er det rettslige grunnlaget for Lawbotics sin behandling av personopplysninger. I tillegg til ditt samtykke vil Lawbotics kun behandle personopplysninger innenfor rammene som satt av norsk lov.",
    "invitations.accept.privacy_policy.04_0_title": "4. Hvilke personopplysninger som behandles",
    "invitations.accept.privacy_policy.04_paragraph": "Ved registrering som bruker av Lawbotics registreres ditt navn og e-postadresse slik at Lawbotics kan administrere ditt kundeforhold og kontakte deg ved behov slik som ved kommunikasjon om funksjonalitet, endringer eller nyheter i tjenester og produkter eller annen informasjon som antas å være relevant for den enkelte bruker.",
    "invitations.accept.privacy_policy.05_0_title": "5. Hvor opplysningene hentes fra",
    "invitations.accept.privacy_policy.05_paragraph": "Alle personopplysninger vi har lagret hos oss blir gitt til oss gjennom registrering som bruker av Lawbotics sin plattform/software. <br>Dersom du ønsker å få tilsendt elektronisk informasjon fra Lawbotics, slik som informasjon om tilbud på produkter eller tjenester, benytter Lawbotics de opplysninger som er gitt til dette formålet, slik som preferanser og elektronisk kontaktinformasjon.",
    "invitations.accept.privacy_policy.06_0_title": "6. Frivillighet",
    "invitations.accept.privacy_policy.06_paragraph": "Det er helt frivillig å oppgi personopplysninger til Lawbotics. Man kan således velge å la være å gi fra seg opplysningene, men opplysninger som navn og e-postadresse er nødvendig for å kunne opprette en brukerkonto og logge inn i systemet.",
    "invitations.accept.privacy_policy.07_0_title": "7. Utlevering av opplysninger til tredjeparter",
    "invitations.accept.privacy_policy.07_paragraph": "Vi leverer ikke ut registrerte opplysninger om deg til andre organisasjoner eller virksomheter uten ditt frivillige, uttrykkelige og informerte samtykke. Dersom du gir et slikt samtykke, vil vi bare utlevere opplysningene innenfor det formål samtykket omfatter.",
    "invitations.accept.privacy_policy.08_0_title": "8. Oppbevaring av personopplysninger",
    "invitations.accept.privacy_policy.08_paragraph": "Dersom du er inne på vår nettside, www.lawbotics.io, vil Lawbotics for en tidsbegrenset periode registrere hvilken maskinvare og programvare du bruker gjennom såkalte cookies (informasjonskapsler) og IP-adressen som er knyttet til din datamaskin, nettbrett eller mobiltelefon. Denne informasjonen brukes til å skaffe statistikk og opprettholde kvalitet på nettsiden vår.<br><br>I tillegg registreres konkrete kjøpsopplysninger og loggdata om hvilke kategorier, tekst og produkter brukere klikker eller søker på, for å kunne lære hva brukerne er opptatt av, forbedre produkt- og tjenesteutvalg og tilbud. Denne informasjonen brukes også for å vise deg mer relevante produkter på nettsiden vår, og for å produsere trafikkstatistikker for blant annet kapasitetsplanlegging.",
    "invitations.accept.privacy_policy.09_0_title": "9. Jurisdiksjon og dine rettigheter",
    "invitations.accept.privacy_policy.09_paragraph": "Lawbotics sin plattform er regulert av norsk lov, og brukere av vår plattform vil til enhver tid være underlagt gjeldende rettigheter og plikter etter norsk lov. Dette innebærer blant annet at du har rett til innsyn i dine personopplysninger, og du har rett til å bli slettet fra våre registre.<br><br>Så lenge du er en registrert bruker av Lawbotics sin plattform vil registrerte opplysninger ikke bli slettet, med mindre du uttrykkelig ber om det. Vi presiserer at bruk av plattform forutsetter at du oppgir fullt navn og e-postadresse. Ved utmelding, avslutting av abonnement, avbrytelse av avtale med Labwotics, eller lignende, vil alle persopplysninger om deg slettes.",
    "invitations.accept.privacy_policy.10_0_title": "10. Sikkerhet",
    "invitations.accept.privacy_policy.10_paragraph": "Lawbotics vil til enhver tid sørge for at ingen som ikke skal ha tilgang til dine personopplysninger, får tilgang til dine personopplysninger.",
    "invitations.accept.privacy_policy.11_0_title": "11. Kontaktinformasjon",
    "invitations.accept.privacy_policy.11_paragraph": "Er du uenig i hvordan vi behandler dine personopplysninger, eller har spørsmål til behandlingen, retting, sletting, innsyn, eller lignende, kan du sende e-post til support@lawbotics.io og merke den med \"Personopplysninger\". Du kan klage på hvordan Lawbotics behandler dine opplysninger til Datatilsynet: e-post: %{mail}, telefon: 22 39 69 00.",
    "invitations.accept.privacy_policy.11_paragraph_react": "Er du uenig i hvordan vi behandler dine personopplysninger, eller har spørsmål til behandlingen, retting, sletting, innsyn, eller lignende, kan du sende e-post til support@lawbotics.io og merke den med \"Personopplysninger\". Du kan klage på hvordan Lawbotics behandler dine opplysninger til Datatilsynet: e-post: {mail}, telefon: 22 39 69 00.",
    "questionnaire.close": "Close",
    "questionnaire.open": "Change answer to ",
    "questionnaire.completed": "You have answered all the questions",
    "questionnaire.download_document_modal.title": "Download document",
    "questionnaire.download_document_modal.description": "Please give your document a name of your own so you can locate it again.",
    "questionnaire.download_document_modal.placeholder": "Give a proper name so you can find it again",
    "questionnaire.download_document_modal.label": "Document name",
    "questionnaire.download_document_modal.format_label": "Format",
    "questionnaire.download_document_modal.download": "Download",
    "questionnaire.download": "Download",
    "questionnaire.download_tooltip": "Download",
    "questionnaire.previous_question": "Previous question",
    "questionnaire.save_version": "Save as version",
    "questionnaire.save_version_modal.title": "Save version",
    "questionnaire.save_version_modal.description": "You are saving this as a version of your document. If you wish you can update the name to reflect your changes.",
    "questionnaire.save_version_modal.version": "Version:",
    "questionnaire.save_version_modal.placeholder": "E.g. Rental Agreement - Downing Street 10, London",
    "questionnaire.save_version_modal.name": "Document name",
    "questionnaire.edit": "Edit",
    "questionnaire.change_answers": "Change answers",
    "questionnaire.change_answers_disabled": "The questionnaire is no longer accessible",
    "questionnaire.edit_document": "Edit document",
    "questionnaire.edit_modal.title": "Creating a new version",
    "questionnaire.edit_modal.description": "Once you continue to edit the document it will no longer be possible to go back to the questionnaire.",
    "questionnaire.edit_modal.tip": "If you want to simply change an answer click on it in the document.",
    "questionnaire.edit_modal.proceed": "Proceed",
    "questionnaire.restart_signing": "Restart signing",
    "questionnaire.questionnaire_error": "Sorry, something went wrong.",
    "questionnaire.changed_answer_saved": "Your changes have been saved",
    "questionnaire.document_finished": "Your document is finished. You can change answers by clicking on them directly in the document.",
    "questionnaire.document_finished_sent_for_signing": "Your document is sent for signing. At this state you cannot edit your document. You can always cancel signing and edit your document.",
    "questionnaire.document_finished_version": "You are now in Version {version} of your document",
    "questionnaire.document_finished_preview": "This is the preview of a finished document. To go back click on an answer directly in the document",
    "questionnaire.document_sent_for_signing_answer_tooltip_message": "This document is sent for signing, answers cannot be changed.",
    "questionnaire.document_error.error": "There's been an error in your document.",
    "questionnaire.document_error.rejected": "{email} rejected signing the document for the following reason: {comment}.",
    "questionnaire.document_error.bounced": "The email account {email} does not exist, please double check the recipients email address and resend.",
    "questionnaire.document_error.expired": "Your document has expired.",
    "questionnaire.abort_signing.button_text": "Cancel signing",
    "questionnaire.abort_signing.modal.header": "Cancel eSigning process",
    "questionnaire.abort_signing.modal.message": "You are about to cancel the signing process. Please note that some recipients may have signed already and will need to sign anew.",
    "questionnaire.abort_signing.modal.confirm": "Do you wish to abort the signing process?",
    "questionnaire.abort_signing.modal.yes": "Abort",
    "questionnaire.abort_signing.modal.no": "Back",
    "questionnaire.esign.send": "Send for eSigning",
    "questionnaire.esign.send_mobile": "eSigning",
    "questionnaire.esign.send_signature": "Send for eSigning",
    "questionnaire.esign.sequential_signing": "Sequential signing workflow",
    "questionnaire.esign.sequential_signing_description": "Signers receive and sign the document in the order that their e-mail addresses are entered. Once the last person has completed the signing process, all parties receive an email with a link to the signed document.",
    "questionnaire.esign.parallel_signing": "Parallel signing workflow",
    "questionnaire.esign.parallel_signing_description": "Signers receive and sign the document all at the same time. Once the last person has completed the signing process, all parties will receive an email with a link to the signed document.",
    "questionnaire.esign.notifications.success": "Your document has been sent for signing.",
    "questionnaire.esign.notifications.error": "Your document has been rejected. Edit the document and resend.",
    "questionnaire.esign.notifications.signing_still_in_progress": "Retrieving document status is taking longer than expected. Please try again in a minute.",
    "questionnaire.esign.notifications.cant_cancel_signed_envelope": "The document has already been signed. You can't cancel it.",
    "questionnaire.esign.timeline.send_invitation": "We’ve sent an invitation to",
    "questionnaire.esign.timeline.send_invitation_next": "Once signed we will send an email to",
    "questionnaire.esign.timeline.send_invitation_last": "Lastly we will send an email to",
    "questionnaire.esign.timeline.signature": "asking for their signature.",
    "questionnaire.esign.timeline.signature_first": "asking for first signature.",
    "questionnaire.esign.timeline.signature_last": "asking for the final signature.",
    "questionnaire.esign.timeline.summary_parallel": "When all parties have signed, you and the signees receive an email with a link to the signed document.",
    "questionnaire.esign.timeline.summary_sequential": "Finally you and the signees receive an email with a link to the signed document.",
    "questionnaire.esign.errors.no_emails": "Please specify at least one recipient",
    "questionnaire.esign.errors.no_signees": "A contract needs at least one third party.",
    "questionnaire.esign.errors.no_document_title": "Document title cannot be empty",
    "questionnaire.esign.errors.no_message": "Message cannot be empty",
    "questionnaire.esign.errors.invalid_email": "Please enter a valid email address",
    "questionnaire.esign.form.to": "Signees",
    "questionnaire.esign.form.document_title": "Document name",
    "questionnaire.esign.form.signing_method": "Signing method",
    "questionnaire.esign.form.signing_method_tooltip": "The method used to sign the document. All methods are equally valid.",
    "questionnaire.esign.form.signing_details": "Message",
    "questionnaire.esign.form.email_addresses": "Email address",
    "questionnaire.esign.form.invitation": "Invitation",
    "questionnaire.esign.form.invitation_to_sign": "Invitation to sign",
    "questionnaire.esign.form.sent_from": "Sent from",
    "questionnaire.esign.form.sent_from_tooltip": "This email will be shown as the sender for the recipient. It will also become the contact email.",
    "questionnaire.esign.form.confirmation_title": "Confirm before sending",
    "questionnaire.esign.form.confirmation_description": "Below are the emails of the individuals invited to sign using {sendingMethod}. Please check the spelling before you send.",
    "questionnaire.esign.form.summary_title": "On it’s way!",
    "questionnaire.esign.form.summary_description": "The esigning process has begun! Below you can see what will happen next. We’ve sent a copy of this to your email as well.",
    "questionnaire.esign.form.submit_label": "Press to send",
    "questionnaire.esign.form.document_name": "Document name",
    "questionnaire.esign.form.document_name_description": "A clear name helps you and the recipient relocate it.",
    "questionnaire.esign.form.custom_message": "Custom message",
    "questionnaire.esign.form.signees": "Signees",
    "questionnaire.esign.form.signee": "Signee",
    "questionnaire.esign.form.signees_title": "Add emails for all parties needing to sign",
    "questionnaire.esign.form.signees_add": "Add to list",
    "questionnaire.esign.form.signees_add_short": "Add",
    "questionnaire.esign.form.signing_parallel": "Signing in parallel",
    "questionnaire.esign.form.signing_parallel_description": "All signees will receive an invitation to sign simultaenously. As the sender you will receive a confirmation once all signees have signed.",
    "questionnaire.esign.form.signing_sequential": "Signing in a sequence",
    "questionnaire.esign.form.signing_sequential_description": "This document will be signed in the exact order shown above. Signees will only receive an invitation to sign when it has become their turn.",
    "questionnaire.esign.form.confirm": "Confirm",
    "questionnaire.esign.form.from": "From",
    "questionnaire.esign.form.message": "Message",
    "questionnaire.esign.form.send": "Send",
    "questionnaire.esign.form.continue": "Continue",
    "questionnaire.esign.form.back": "Back",
    "questionnaire.esign.form.close": "Close",
    "questionnaire.esign.form.cancel": "Cancel",
    "questionnaire.esign.form.add_signees": "Add signees",
    "questionnaire.esign.form.greetings": "Hi,\nYou have been invited by {name} to sign the following document.\n",
    "questionnaire.esign.form.placeholder.document_name": "E.g. Rental Agreement - John Doe",
    "questionnaire.esign.form.placeholder.custom_message": "Add a custom message like “Hi Here is the document for signing. Please contact me at {email} if you have any questions.”",
    "questionnaire.esign.form.placeholder.default_custom_message": "Please contact me at {email} if you have any questions.",
    "questionnaire.esign.form.placeholder.sent_from": "E.g. JohnDoe@Email.com",
    "questionnaire.esign.form.placeholder.message": "Write a message to the recipients",
    "questionnaire.esign.form.placeholder.document_title": "E.g. Collaboration Agreement - Acme AS",
    "questionnaire.esign.form.placeholder.signee": "E.g. michael@angelo.it",
    "questionnaire.esign.form.placeholder.from": "Enter your email",
    "questionnaire.esign.form.placeholder.emails": "Enter one email per person",
    "questionnaire.esign.form.placeholder.next_email": "Add more people",
    "questionnaire.esign.form.placeholder.next_emails": "Add more people",
    "questionnaire.scroll_notification.description": "Scroll to switch questions",
    "questionnaire.top_toolbar.rename_tooltip": "Rename document",
    "questionnaire.toggle_button.hide": "Hide",
    "questionnaire.toggle_button.show": "Show",
    "questionnaire.preview.heading": "Preview",
    "questionnaire.preview.show_full_screen": "Preview document",
    "questionnaire.preview.modal.header": "Document preview",
    "questionnaire.questions.errors.email": "Please enter a single valid email address",
    "questionnaire.questions.errors.required": "This field is required",
    "questionnaire.questions.errors.uint": "Please enter positive whole number",
    "questionnaire.questions.errors.date": "Please enter a valid date in the format {format}",
    "questionnaire.questions.not_sure": "Not sure?",
    "questionnaire.questions.skip": "Fill out later",
    "questionnaire.questions.input_placeholder": "type...",
    "questionnaire.questions.input_number_placeholder": "Enter a number",
    "questionnaire.questions.date_input_placeholder": "choose...",
    "questionnaire.questions.help_text.hide": "Hide",
    "questionnaire.questions.help_text.show": "Read more",
    "questionnaire.questions.buttons.ok": "Continue",
    "questionnaire.questions.buttons.ok_caption": "or press",
    "questionnaire.questions.buttons.complete": "Finish",
    "questionnaire.questions.buttons.enter": " Enter",
    "questionnaire.questions.buttons.begin": "Begin",
    "questionnaire.left_toolbar.based_on_template": "Based on template:",
    "questionnaire.left_toolbar.question_list": "Questions",
    "questionnaire.left_toolbar.introduction": "Introduction",
    "questionnaire.left_toolbar.go_back_documents": "Go back to documents",
    "questionnaire.left_toolbar.go_back_editor": "Go back to editor",
    "questionnaire.finished_with_skipped.heading": "Almost finished!",
    "questionnaire.finished_with_skipped.description": "Please answer the remaining questions.",
    "questionnaire.finished_with_skipped.go_to_editor": "Go to editor",
    "questionnaire.introduction.stepsWithSigning.one": "Answer the questions",
    "questionnaire.introduction.stepsWithSigning.two": "Send for digital signing or download to sign it with a pen",
    "questionnaire.introduction.stepsWithSigning.three": "When all parties have signed your contract is finished!",
    "questionnaire.introduction.stepsWithoutSigning.one": "Answer the questions",
    "questionnaire.introduction.stepsWithoutSigning.two": "Get your completed customised document",
    "questionnaire.introduction.stepsWithoutSigning.three": "Download your document for storage or signing",
    "questionnaire.introduction.title": "Introduction",
    "questionnaire.introduction.primary_description": "Welcome to this questionnaire for {documentName}. To complete it we will guide you through these three steps.",
    "questionnaire.introduction.secondary_description": "Your answers are saved continuously. You can come back anytime and continue where you left off.",
    "questionnaire.introduction.help_description": "Need help using the solution?",
    "questionnaire.introduction.help_chat_toggle_button_label": "Ask us! 👉",
    "validation.email": "Please enter a valid email address",
    "shared_components.page_break": "Page break",
    "shared_components.tags_area.errors.email_exist": "Email already exist",
    "shared_components.tags_area.errors.invalid_email": "Invalid email",
    "shared_components.save_notification.saving": "Saving...",
    "shared_components.save_notification.saved": "Saved automatically",
    "shared_components.save_notification.failed": "Can't save changes",
    "shared_components.save_notification.saving_tooltip": "We're automatically saving your changes.",
    "shared_components.password_input.length_requirement": "At least 10 characters",
    "shared_components.password_input.letters_and_numbers": "Use letters and numbers",
    "shared_components.password_input.mix_lower_and_uppercase": "Mix lower and uppercase",
    "shared_components.password_input.password_must_contain": "Password must contain",
    "shared_components.password_input.special_character": "At least 1 special character",
    "shared_components.password_strength_indicator.strength": "Strength",
    "shared_components.password_strength_indicator.weak": "Very weak",
    "shared_components.password_strength_indicator.ok": "Ok",
    "shared_components.password_strength_indicator.good": "Good",
    "shared.notifications.success": "Success",
    "shared.notifications.error": "Error",
    "shared.notifications.alert": "Alert",
    "shared.notifications.info": "Info",
    "shared.statuses.started": "Started",
    "shared.statuses.sent_for_signing": "Sent for signing",
    "shared.statuses.signing_requested": "Sent for signing",
    "shared.statuses.signing_expired": "Expired",
    "shared.statuses.signing_aborted": "Aborted",
    "shared.statuses.signing_failed": "Error",
    "shared.statuses.signed": "Signed",
    "shared.statuses.completed": "Completed",
    "shared.statuses.expired": "Expired",
    "shared.statuses.rejected": "Rejected",
    "shared.statuses.error": "Error",
    "shared.statuses.active": "Active",
    "shared.statuses.inactive": "Inactive",
    "shared.statuses.draft": "Draft",
    "shared.statuses.moved_to_trash": "Item moved to trash",
    "shared.statuses.undo": "Undo",
    "shared.statuses.restored_from_trash": "Restored {name}",
    "shared.errors.go_back": "Go back",
    "shared.errors.go_lexolve": "Return to Lexolve",
    "shared.errors.user_not_authorized": "You are not authorized to perform this action.",
    "shared.errors.trash_template_has_documents": "Template has documents. You can't remove it.",
    "shared.errors.document_undeletable": "This document cannot be deleted at the moment. Please cancel the signing process first.",
    "shared.errors.default.title": "Sorry, something went wrong.",
    "shared.errors.default.message": "Please go back and try again.",
    "shared.errors.document_in_trash.title": "Oops! This item doesn't exist anymore",
    "shared.errors.document_in_trash.message": "Please check the link is correct or contact the owner to get a new link.",
    "shared.errors.no_published_version.title": "Oops! This template is no longer active.",
    "shared.errors.no_published_version.message": "Please check the link is correct or contact the owner of the template to get a new link.",
    "shared.errors.create_document_failed.title": "Sorry, something went wrong.",
    "shared.errors.create_document_failed.message": "There was an error creating this document. Please try again or contact customer support if the issue persists.",
    "shared.errors.company_not_authorized.title": "Sorry, something went wrong.",
    "shared.errors.company_not_authorized.message": "There was an error opening this document. Please try again or contact customer support.",
    "shared.errors.token_expired.title": "Session Expired.",
    "shared.errors.token_expired.message": "For your security we have signed you out automatically due to inactivity. You will be redirected in 5 seconds to sign in again to access your document.",
    "shared.errors.wrong_token.title": "Sorry, something went wrong when logging in.",
    "shared.errors.wrong_token.message": "Please try signing in again or contact customer support if the issue persists.",
    "shared.errors.template_not_active.title": "Oops! This template is no longer active.",
    "shared.errors.template_not_active.message": "Please contact the owner of the template",
    "shared.errors.link_expired.title": "This link has expired",
    "shared.errors.link_expired.message": "Due to the security reason, your access link is no longer valid. Click the button below and we will send you a new link",
    "shared.errors.link_expired.button": "Send a new access link",
    "shared.errors.session_expired.title": "Your session has expired",
    "shared.errors.session_expired.message": "Your session has expired",
    "shared.errors.not_found.title": "Page not found",
    "shared.errors.not_found.message": "The link you clicked may be broken or the page may have been removed",
    "shared.errors.not_found.button": "Take me back to homepage",
    "shared.errors.user_disabled.title": "Did you use the wrong account?",
    "shared.errors.user_disabled.subtitle": "The email you used does not have access to Lawbotics anymore.",
    "shared.errors.user_disabled.message": "If you believe you should have access we encourage you to get in {newLine}touch with your administrator to restore your account.",
    "shared.errors.user_disabled.button": "Back to login",
    "shared.errors.record_not_found.title": "Page not found",
    "shared.errors.record_not_found.message": "The link you clicked may be broken or the page may have been removed",
    "shared.errors.record_not_found.button": "Take me back to homepage",
    "signing_document_mailer.notify_sender.email_title": "%{name} is sent for signing",
    "signing_document_mailer.notify_sender.hello": "Hi %{name}",
    "signing_document_mailer.notify_sender.intro": "The contract has now been sent to the signees for signing.",
    "signing_document_mailer.notify_sender.parallel.content": "Signers receive and sign the document all at the same time. Once the last person has completed the signing process, all parties will receive an email with a link to the signed document. If you are to sign you will receive a separate link.",
    "signing_document_mailer.notify_sender.sequential.content": "Signers receive and sign the document in the order shown below. If you are to sign, you will receive a link in a separate email. Once the last person has completed the signing process, all parties receive an email with a link to the signed document.",
    "signing_document_mailer.notify_sender.sequential.order_of_signees": "Order of signees:",
    "signing_document_mailer.notify_sender.sequential.signee": "Signee %{index}: %{email}",
    "simple_form.yes": "Yes",
    "simple_form.no": "No",
    "simple_form.required.text": "required",
    "simple_form.required.mark": "*",
    "simple_form.error_notification.default_message": "Please review the problems below:",
    "invitations.accept.tos.01_00_title": "Generelt",
    "invitations.accept.tos.01_01_paragraph": "Takk for at du bruker Lawbotics! Disse generelle vilkårene («Vilkårene») dekker din bruk og kjøp av produkter, tjenester, software, plattform og nettsider (sammen kalt «Tjenesten» eller «Tjenester») tilgjengeliggjort av Lawbotics AS org.nr. 918567852 (heretter kalt Selskapet, «vi» og/eller «oss»). Selskapet eier Tjenesten. Selskapet er ikke formidler av advokattjenester, gir ikke juridisk rådgivning eller deltar i noen juridisk representasjon. Selskapet er ikke et advokatfirma og bruk av Tjenesten er ikke et alternativ til bruk av advokat eller et advokatfirma.",
    "invitations.accept.tos.01_02_paragraph": "Hvis du bruker Tjenesten er du bundet av disse Vilkårene. Dersom du bruker Tjenesten som en ansatt eller agent i et selskap eller en organisasjon, så samtykker du til disse Vilkårene på vegne av det selskapet eller den organisasjonen. Ved å bruke Tjenesten bekrefter og innestår du for at du er 18 år eller eldre, myndig og har full juridisk rett til å inngå avtale med en annen part, og at Tjenesten skal benyttes i næringsvirksomhet.",
    "invitations.accept.tos.01_03_paragraph": "Les Vilkårene nøye før du benytter deg av Tjenestene til Selskapet. Ved å benytte deg av Tjenesten bekrefter du at du aksepter og forplikter deg til Vilkårene og at du har lest og godkjent Vilkårene. I tillegg vil enkelte særskilte vilkår kunne gjelde for visse tjenester og visse tilbud. Slike særskilte vilkår vil være tilgjengelig på Selskapets nettside, lawbotics.io (heretter \"Nettsiden\".)",
    "invitations.accept.tos.02_00_title": "Refunderingspolicys",
    "invitations.accept.tos.02_01_paragraph": "Vi vil at du skal være 100 % fornøyd med Tjenesten. Dersom du er misfornøyd eller tror det har skjedd en feil ved betalingen for Tjenesten, vennligst kontakt vårt kundesenter på kontakt@lawbotics.io umiddelbart så vi kan hjelpe deg med å løse problemet, eller gi deg refusjon eller kredit som kan benyttes senere. Dersom du kontakter oss, vennligst gi oss alle detaljer relatert til ditt kjøp og bruk av Tjenesten så vi kan forsikre oss at du blir fornøyd. Alle forespørsler om refusjon må gjøres innen 7 dager etter kjøpet. Med mindre du gir oss en god grunn, vil vi ikke gi refusjon for Tjenester du allerede har benyttet.",
    "invitations.accept.tos.03_00_title": "Lawbotics er ikke et advokatfirma og gir ikke juridisk rådgivning",
    "invitations.accept.tos.03_01_paragraph": "Lawbotics gjør tilgjengelig en plattform for juridisk informasjon og gir deg tilgang til selv å lage egne juridiske avtaler eller dokumenter. Informasjonen gitt av Selskapet sammen med innholdet på Nettsiden relatert til juridiske spørsmål er gitt for din egen bruk og innebærer ikke juridisk rådgivning. Vi undersøker ikke om den informasjonen du gir oss er korrekt eller tilstrekkelig, og vi trekker ingen juridiske konklusjoner eller gjør vurderinger av dine valg på Nettsiden.",
    "invitations.accept.tos.03_02_paragraph": "Dersom du trenger juridisk rådgivning for et spesifikt problem bør du konsultere med en advokat. Verken Selskapet eller noe juridisk informasjon som gis av Selskapet er et alternativ til juridisk rådgivning som gis av en kvalifisert advokat. Da Selskapet ikke er et advokatfirma, vennligst merk deg at kommunikasjon mellom deg og Selskapet ikke er underlagt kravet til advokaters taushetsplikt.",
    "invitations.accept.tos.03_03_paragraph": "Din bruk av Tjenesten etablerer ikke et advokat-klientforhold mellom deg og Selskapet, eller mellom deg og noen ansatt i eller representant for Selskapet. Med mindre du er representert av en advokat, representerer du deg selv i alle juridiske handlinger du foretar gjennom bruk av Tjenesten.",
    "invitations.accept.tos.04_00_title": "Kjøp av produkt eller tjeneste",
    "invitations.accept.tos.04_01_paragraph": "Dersom du ønsker å kjøpe et produkt eller tjeneste som er gjort tilgjengelig gjennom Tjenesten kan du bli spurt om å gi informasjon relevant til kjøpet ditt, inkludert, men ikke begrenset til, ditt kredittkortnummer, utløpsdatoen på ditt kredittkort, faktureringsadresse og hvilken e-postadresse produktet skal sendes til.",
    "invitations.accept.tos.04_02_paragraph": "Ved å gi oss denne informasjonen, gir du oss rett til å gi informasjonen videre til tredjeparter for det formål å gjennomføre betaling av kjøpet.",
    "invitations.accept.tos.04_03_paragraph": "Vi reserverer retten til å nekte eller kansellere din bestilling dersom vi mistenker at transaksjonen innebærer svindel, uautorisert bruk eller en ulovlig transaksjon.",
    "invitations.accept.tos.04_04_paragraph": "Alle priser oppgitt fra oss er i norske kroner. Når du gjennomfører et kjøp av Tjenesten, vil beløpet bli trukket av ditt kredittkort samme dag.",
    "invitations.accept.tos.04_05_paragraph": "Tjenesten mottar betaling via debet- og kredittkort. Selskapet betaler det gebyr som kreves fra betalingsformidler. Du betaler selv eventuelle andre gebyrer, som din egen kortutsteder skulle kreve utover dette.",
    "invitations.accept.tos.04_06_paragraph": "For å bruke Tjenesten må du oppgi din e-postadresse. For å opprette bruker slik at du kan logge inn i Tjenesten må du i tillegg oppgi ditt fulle navn. Du er selv ansvarlig for å oppgi korrekt e-postadresse og navn.",
    "invitations.accept.tos.05_00_title": "Omfang av Tjenesten",
    "invitations.accept.tos.05_01_paragraph": "Vi oppdater og endrer konstant vårt tilbud av produkter og tjenester tilgjengelig innenfor Tjenesten. Produktene og tjenestene som er tilgjengelig via Tjenesten kan ved enkelte tilfeller være feilpriset eller inneha upresis beskrivelse, eller være utilgjengelig, og vi kan ha forsinkelser i oppdatering av informasjon på Tjenesten og i vår markedsføring på andre nettsider.",
    "invitations.accept.tos.05_02_paragraph": "Vi kan ikke garantere, og garanterer ikke for nøyaktigheten eller fullstendigheten av noen informasjon, inkludert priser, bilder av produkter, spesifikasjoner, tilgjengelighet eller tjenester. Vi reserver oss retten til å endre eller oppdatere informasjonen og til å rette opp feil, upresisheter eller unnlatelser, når som helst og uten varsel.",
    "invitations.accept.tos.06_00_title": "Immaterielle rettigheter",
    "invitations.accept.tos.06_01_paragraph": "Tjenesten og dens originale innhold, egenskaper og funksjoner er og vil forbli Selskapets eksklusive eiendom. Tjenesten er beskyttet av åndsverksloven, varemerkeloven, markedsføringsloven og annen lovgivning både i Norge og andre land. Våre varemerker og andre kjennetegn kan ikke benyttes i tilknytning til noe annet produkt eller tjenester uten Selskapets skriftlige samtykke.",
    "invitations.accept.tos.06_02_paragraph": "Det er ikke tillatt å kopiere, distribuere eller på annen måte mangfoldiggjøre innholdet på Tjenesten uten etter avtale med Selskapet.",
    "invitations.accept.tos.06_03_paragraph": "Er det inngått egen avtale med Selskapet om bruk av Tjenesten, for eksempel avtale om å være pilot- eller betakunde, vil vilkårene i en slik avtale gjelde parallellt med vilkårene i denne avtalen.",
    "invitations.accept.tos.07_00_title": "Linker til andre nettsider",
    "invitations.accept.tos.07_01_paragraph": "Tjenesten kan inneholde linker til tredjeparts nettsider som ikke er eid eller kontrollert av Selskapet. Selskapet har ikke noen kontroll over, eller tar noe ansvar for, innholdet, behandling av personopplysninger eller praksiser på en tredjeparts nettside eller tjeneste. Du forstår og samtykker til at Selskapet ikke skal være ansvarlig, verken direkte eller indirekte, for noe tap eller skade som er forårsaket eller påstås å være forårsaket i tilknytning til bruk av slikt innhold, varer eller tjenester som er gjort tilgjengelig gjennom slike nettsider eller tjenester.",
    "invitations.accept.tos.07_02_paragraph": "Vi anbefaler på det sterkeste at du leser vilkårene og betingelsene for tredjeparts nettsider og tjenester som du besøker.",
    "invitations.accept.tos.08_00_itle": "Begrensing av ansvar",
    "invitations.accept.tos.08_01_paragraph": "Du bruker Tjenesten på eget ansvar. Tjenesten er tilbudt «som den er». Tjenesten er gitt uten noen garantier av noe slag, verken uttrykt eller implisitt, inkludert, men ikke begrenset til, implisitte garantier om produktets eller tjenestens egenskaper, ytelse, at den er tilpasset et spesielt formål, at den ikke innebærer overtredelse av andres rettigheter eller noen garanti om dens ytelse.",
    "invitations.accept.tos.08_02_paragraph": "Selskapet og dens underenheter, agenter eller lisensorer garanterer ikke at a) Tjenesten fungerer uavbrutt, sikkert eller at den er tilgjengelig til hvilket som helst tidspunkt eller lokasjon; b) at feil eller mangler vil bli korrigert, c) at Tjenesten er fri for virus eller andre skadelige komponenter, eller at d) resultatet av å bruke Tjenesten vil møte dine kriterier eller krav.",
    "invitations.accept.tos.08_03_paragraph": "Enkelte av produktene som er tilgjengelig via Tjenesten er avhengig av informasjon eller annen input du gir, hvor eventuelle feil i din input eller valg ikke er Selskapets ansvar. Dersom du velger å endre eller på annen måte modifisere det produktet som er mottatt via Tjenesten, vil dette kunne medføre at produktet ikke får den ønskede virkning, noe som Selskapet ikke er ansvarlig for.",
    "invitations.accept.tos.08_04_paragraph": "Ikke i noe tilfelle skal Selskapet, dens styre, ansatte, partnere, agenter, leverandører eller selskap i samme konsern, være ansvarlig for noe direkte eller indirekte, tilfeldig, spesielt, konsekvens- eller følgetap, inkludert, men ikke begrenset til, tap av profitt, data, bruk, goodwill eller andre immaterielle tap, som er et resultat av (i) din tilgang til, eller bruk av, eller manglende evne til å bruke eller få tilgang til, Tjenesten, (ii) noe oppførsel eller innhold fra en tredjepart på Tjenesten, (iii) innhold mottatt fra Tjenesten og (iv) uautorisert tilgang, bruk eller endring av dine data eller innhold, enten den er basert på garanti, kontrakt, uaktsomhet eller annen juridisk teori, uansett om vi har blitt informert om muligheten for slik tap eller ikke, og selv om et virkemiddel ved Tjenesten har mislyktes sitt hovedformål. Selskapet er under enhver omstendighet ikke ansvarlig for å yte erstatning som overstiger det du har betalt for bruk av Tjenesten.",
    "invitations.accept.tos.08_05_paragraph": "Tjenesten kan fra tid til annen være helt eller delvis utilgjengelig på grunn av oppdateringer.",
    "invitations.accept.tos.09_00_title": "Levering",
    "invitations.accept.tos.09_01_paragraph": "Når du har gjennomført betaling, vil det digitale produktet du har kjøpt bli levert uten merkostnader til den e-postadresse du har oppgitt innen kort tid. Dersom en forsinket eller manglende levering beror på noe som skyldes deg, slik som at du har oppgitt feil e-postadresse, er Selskapet ikke ansvarlig. Du er ansvarlig for å kontrollere produktet når du mottar det, og taper din rett til å reklamere dersom du ikke sier fra til Selskapet med en gang du oppdager noe feil eller burde ha oppdaget feilen eller mangelen, senest 7 dager etter mottakelse av produktet.",
    "invitations.accept.tos.09_02_paragraph": "Dersom forsinkelse skulle skje vil du vil ikke ha rett på erstatning eller annen kompensasjon med mindre produktet ikke blir levert. Dersom produktet ikke er levert innen 5 dager etter at du har gjennomført betaling, vil du kunne motta refusjon av det du har betalt for den aktuelle Tjenesten.",
    "invitations.accept.tos.10_00_title": "Ingen angrerett",
    "invitations.accept.tos.10_01_paragraph": "Tjenesten er rettet mot næringsdrivende, og skal ikke benyttes av forbrukere. Det gjelder ingen angrerett for kjøp av produkter tilgjengeliggjort via Tjenesten, og du aksepterer at levering av produktet vil skje straks kjøpet gjennomføres slik at en eventuell angrerett vil uansett gå tapt.",
    "invitations.accept.tos.11_00_title": "Endringer",
    "invitations.accept.tos.11_01_paragraph": "Vi reserverer oss retten til, etter eget skjønn, å endre eller erstatte pris, avgifter og disse Vilkårene på ett hvert tidspunkt.",
    "invitations.accept.tos.11_02_paragraph": "Ved å fortsette å benytte eller besøke Tjenesten etter at slike endringer har trådt i kraft, bekrefter du at du er enig i at du er forpliktet til de endrede Vilkårene. Dersom du ikke er enig i de nye vilkårene, må du slutte å bruke Tjenesten.",
    "invitations.accept.tos.12_00_title": "Verneting",
    "invitations.accept.tos.12_01_paragraph": "Disse Vilkårene skal tolkes i samsvar med norsk lov.",
    "invitations.accept.tos.12_02_paragraph": "Selv om vi ikke skulle håndheve enkelte av disse Vilkårene skal dette ikke anses for at vi har avstått fra slik håndhevelse. Dersom noen av disse Vilkårene er ugyldige eller ikke kan håndheves, skal likevel de resterende Vilkårene bestå. Disse Vilkårene skal gjelde parallellt med eventuelle andre avtaler som du har inngått med Selskapet, for eksempel en preproduksjonsavtale, avtale om å være pilot- eller betakunde, eller lignende. Vilkårene går foran og erstatter alle eventuelle tidligere avtaler vi har hatt i tilknytning til Tjenesten.",
    "invitations.accept.tos.12_03_paragraph": "Eventuelle konflikter eller uenigheter mellom partene skal søkes løst gjennom dialog. Dersom situasjonen ikke blir avklart med dialog innen rimelig tid kan søksmål reises for Oslo tingrett.",
    "invitations.accept.tos.13_00_title": "Kontakt oss",
    "invitations.accept.tos.13_01_paragraph": "Dersom du har noen spørsmål om disse Vilkårene, vennligst ta kontakt med oss på %{mail}",
    "invitations.accept.tos.13_01_paragraph_react": "Dersom du har noen spørsmål om disse Vilkårene, vennligst ta kontakt med oss på {mail}",
    "user_edit.active_user": "Active user",
    "user_edit.reinvite_user": "Reinvite user",
    "user_edit.reinvite_user_description": "Please confirm sending new invite to {userEmail}",
    "user_edit.cancel": "Cancel",
    "user_edit.confirm": "Confirm",
    "user_edit.save": "Save",
    "user_edit.go_back": "Go back to users list",
    "user_edit.deactivate_user": "Deactivate user",
    "user_edit.deactivate_user_description": "Please confirm deactivating user {userEmail}",
    "user_edit.activate_user": "Activate user",
    "user_edit.activate_user_description": "Please confirm activating user {userEmail}",
    "user_edit.roles_permissions.admin": "Is able to manage users, create templates and documents",
    "user_edit.roles_permissions.producer": "Is able to create templates and documents",
    "user_edit.roles_permissions.consumer": "Is able to create documents",
    "user_mailer.forget_password.subject": "Reset password instructions",
    "user_mailer.forget_password.hello": "Hello, %{name}",
    "user_mailer.forget_password.change_password": "Reset Your Password",
    "user_mailer.forget_password.first_paragraph": "You've recently asked to reset the password. To update your password, click the button below:",
    "user_mailer.forget_password.button_text": "Reset my password",
    "user_mailer.forget_password.second_paragraph": "If you did not request your password to be reset and believe your account has been compromised, please",
    "user_mailer.forget_password.mail_to_text": "contact us",
    "user_mailer.forget_password.help": ".",
    "user_mailer.forget_password.greetings": "Best regards, ",
    "user_profile.password_change_form.cant_be_blank": "Can't be blank",
    "user_profile.password_change_form.passwords_must_match": "Passwords must match",
    "user_profile.password_change_form.password_not_strong_enough": "This password is not strong enough",
    "user_profile.password_change_form.save_new_passsword": "Save your new password",
    "user_profile.password_change_form.current_password": "Current password",
    "user_profile.password_change_form.new_password": "New password",
    "user_profile.password_change_form.confirm_password": "Confirm password",
    "user_profile.password_change_form.notification_success": "Success",
    "user_profile.password_change_form.notification_password_changed": "Your password has been changed",
    "user_profile.password_change_form.notification_error": "Error",
    "user_profile.password_change_form.notification_error_default": "Something went wrong. Please contact your administrator.",
    "user_profile.password_change_form.notification_error_current_password": "Please provide correct current password",
    "user_profile.personal_information_form.cant_be_blank": "Can't be blank",
    "user_profile.personal_information_form.save_changes": "Save changes",
    "user_profile.personal_information_form.email": "Email",
    "user_profile.personal_information_form.first_name": "First name",
    "user_profile.personal_information_form.last_name": "Last name",
    "user_profile.personal_information_form.invalid_email": "Invalid email",
    "user_profile.personal_information_form.notification_success": "Success",
    "user_profile.personal_information_form.notification_personal_information_updated": "Your personal informations has been updated",
    "user_profile.personal_information_form.notification_error": "Error",
    "user_profile.personal_information_form.notification_error_something": "Something went wrong. Please contact your administrator.",
    "user_profile.user_profile_form.password_change": "Password change",
    "user_profile.user_profile_form.personal_information": "Personal information",
    "user_profile.user_profile_header.subheading": "Personal info & account access",
    "user_profile.user_profile_header.account_settings": "Account settings",
    "panel.user_questionnaires.statuses.started": "Started",
    "panel.user_questionnaires.statuses.sent_for_signing": "Sent for signing",
    "panel.user_questionnaires.statuses.signed": "Signed",
    "panel.user_questionnaires.statuses.completed": "Completed",
    "panel.user_questionnaires.statuses.expired": "Expired",
    "panel.user_questionnaires.statuses.rejected": "Rejected",
    "panel.user_questionnaires.statuses.error": "Error",
    "panel.user_questionnaires.index.documents": "Documents",
    "panel.user_questionnaires.index.documents_subtitle": "List of all Documents",
    "panel.user_questionnaires.index.search": "Search...",
    "panel.user_questionnaires.index.create_document": "Create new",
    "panel.user_questionnaires.index.customers_docs": "Customer documents",
    "panel.user_questionnaires.index.your_docs": "Your documents",
    "panel.user_questionnaires.index.document": "Document",
    "panel.user_questionnaires.index.created_at": "Created at",
    "panel.user_questionnaires.index.edited_at": "Edited at",
    "panel.user_questionnaires.index.based_on": "Based on",
    "panel.user_questionnaires.index.status": "Status",
    "panel.user_questionnaires.index.created_by": "Created by",
    "panel.user_questionnaires.index.new_folder": "Folder",
    "panel.user_questionnaires.index.new_document": "Document",
    "panel.user_questionnaires.index.not_authorized": "You are not authorized to access this page",
    "panel.user_questionnaires.user_questionnaire.document": "Document",
    "panel.user_questionnaires.user_questionnaire.created_at": "Created at",
    "panel.user_questionnaires.user_questionnaire.edited_at": "Edited at",
    "panel.user_questionnaires.user_questionnaire.based_on": "Based on: ",
    "panel.user_questionnaires.user_questionnaire.status": "Status",
    "panel.user_questionnaires.user_questionnaire.created_by": "Created by",
    "panel.user_questionnaires.user_questionnaire.version": "/ Version: ",
    "panel.user_questionnaires.user_questionnaire.trash": "Move to trash",
    "panel.user_questionnaires.new.cant_create": "Can't create document from unpublished template version",
    "panel.user_questionnaires.new.new_document": "New document",
    "panel.user_questionnaires.new.not_authorized": "You are not authorized to perform this action",
    "panel.user_questionnaires.document_folders.show.create_new": "Create new",
    "panel.user_questionnaires.document_folders.show.new_folder": "Folder",
    "panel.user_questionnaires.document_folders.show.new_document": "Document",
    "panel.user_questionnaires.document_folders.show.search": "Search...",
    "panel.user_questionnaires.document_folders.show.rename": "Rename",
    "panel.user_questionnaires.document_folders.show.delete": "Delete",
    "panel.user_questionnaires.document_folders.document_folder.documents_count": "%{val} documents",
    "panel.user_questionnaires.document_folders.document_folder.delete": "Delete",
    "panel.user_questionnaires.document_folders.document_folder.rename": "Rename",
    "panel.user_questionnaires.document_folders.create_document_folder_modal.folder_name": "Folder name",
    "panel.user_questionnaires.document_folders.create_document_folder_modal.cancel": "Cancel",
    "panel.user_questionnaires.document_folders.create_document_folder_modal.create_folder": "Create folder",
    "panel.user_questionnaires.document_folders.document_subfolder.documents_count": "%{val} documents",
    "panel.user_questionnaires.document_folders.document_subfolder.delete": "Delete",
    "panel.user_questionnaires.document_folders.document_subfolder.rename": "Rename",
    "panel.user_questionnaires.document_folders.breadcrumbs.documents": "Documents",
    "panel.user_questionnaires.document_folders.hover_breadcrumbs.documents": "Documents",
    "panel.user_questionnaires.document_folders.create.success": "Folder succesfully created",
    "panel.user_questionnaires.document_folders.update.success": "Folder succesfully updated",
    "panel.user_questionnaires.document_folders.update.failed": "Could not update the folder",
    "panel.user_questionnaires.document_folders.destroy.destroyed": "Folder succesfully deleted",
    "panel.user_questionnaires.signing.success.heading": "You have successfully signed the document",
    "panel.user_questionnaires.signing.success.description_1": "You have done your part. You don't need to take further actions.",
    "panel.user_questionnaires.signing.success.description_2": "When all parties have signed you will receive a final email containing a link you can use to download the signed document.",
    "panel.user_questionnaires.signing.success.feedback_title": "We love feedback",
    "panel.user_questionnaires.signing.success.feedback_description": "It will only take you a minute and it is very valuable in helping us improve your experience.",
    "panel.user_questionnaires.signing.success.feedback_button": "Give feedback",
    "panel.user_questionnaires.notifications.rename_failure": "Unable to rename document",
    "users_invite.title": "New invitation",
    "users_invite.subtitle": "Fill in the information below to create new users. We'll email your teammates instructions for logging in.",
    "users_invite.send": "Send invitation",
    "users_invite.cancel": "Cancel",
    "users_invite.success.title": "Success",
    "users_invite.success.description": "Invitations sent to new members",
    "users_invite.failure.title": "Error",
    "users_invite.failure.description": "Something went wrong. Try again and contact your administrator.",
    "users_invite.errors.no_emails": "There needs to be at least one email address",
    "users_invite.errors.is_blank": "Can't be blank",
    "users_invite.roles_labels.admin": "Company Admin",
    "users_invite.roles_labels.producer": "Producer",
    "users_invite.roles_labels.consumer": "Consumer",
    "users_invite.roles_permissions.admin": "Is able to manage users, create templates and documents",
    "users_invite.roles_permissions.producer": "Is able to create templates and documents",
    "users_invite.roles_permissions.consumer": "Is able to create documents",
    "users_invite.form_labels.email_addresses": "Email address(es)",
    "users_invite.form_labels.role": "Role",
    "users_list.edit_user_btn": "Edit user",
    "users_list.title": "Users",
    "users_list.subTitle": "List of all company users",
    "users_list.invite": "Invite Members",
    "users_list.new_invitation": "New Invitation",
    "users.roles.consumer": "Consumer",
    "users.roles.producer": "Producer",
    "users.roles.admin": "Company admin",
    "visualization.errors.forbid_to_paste_inside_inline_question": "You can't paste that content inside inline question.",
    "visualization.publish_unpublish.header": "Publish template",
    "visualization.publish_unpublish.intro": "Publishing a template will result in it appearing in the template library where others can use this template to create documents from.",
    "visualization.publish_unpublish.what_did_you_change": "Version Description",
    "visualization.publish_unpublish.textarea_placeholder": "Add a description of what you changed",
    "visualization.publish_unpublish.notification": "Notification",
    "visualization.publish_unpublish.notification_caption": "Do you want to notify users about the changes?",
    "visualization.publish_unpublish.yes": "Yes",
    "visualization.publish_unpublish.no": "No",
    "visualization.publish_unpublish.publish": "Publish",
    "visualization.publish_unpublish.unpublish": "Unpublish",
    "visualization.publish_unpublish.cancel": "Cancel",
    "visualization.top_toolbar.back": "Back",
    "visualization.top_toolbar.version.title": "Version {val}",
    "visualization.top_toolbar.version.help_text": "Only the newest version of the document can be sent for signing.",
    "visualization.top_toolbar.version.viewing": "Viewing",
    "visualization.top_toolbar.version.view": "View >",
    "visualization.top_toolbar.status": "Status {val}",
    "visualization.top_toolbar.questionnaire_preview": "Preview Questionnaire",
    "visualization.top_toolbar.share": "Share",
    "visualization.top_toolbar.publish": "Publish",
    "visualization.top_toolbar.unpublish": "Unpublish",
    "visualization.top_toolbar.edit_draft": "Edit draft",
    "visualization.top_toolbar.create_draft": "Create draft",
    "visualization.top_toolbar.create_document": "Create document",
    "visualization.top_toolbar.rename_template": "Rename template",
    "visualization.top_toolbar.sub_menu.template_details": "Template details",
    "visualization.top_toolbar.sub_menu.settings": "Settings",
    "visualization.right_sidebar.flow": "Flow",
    "visualization.right_sidebar.drafts": "Draft questions",
    "visualization.right_sidebar.drafts_help_text": "List of questions not currently linked to the template",
    "visualization.right_sidebar.no_question_name": "No question name.",
    "visualization.editor.placeholder": "Edit your content here",
    "visualization.editor.choice": "Multiple Choice",
    "visualization.editor.text_input": "Text input",
    "visualization.editor.reuse_question": "Show if ...",
    "visualization.editor.group": "Group options",
    "visualization.editor.refresh": "Refresh",
    "visualization.flow.header": "Questions",
    "visualization.flow.edit": "Edit",
    "visualization.logics_manager.formatting_title": "Formatting",
    "visualization.logics_manager.allow_long_answer_checkbox": "Allow long answer",
    "visualization.logics_manager.settings": "Settings",
    "visualization.logics_manager.edit_question": "Edit question",
    "visualization.logics_manager.question_label": "Question",
    "visualization.logics_manager.question_placeholder": "What do you want to ask the user?",
    "visualization.logics_manager.help_text_label": "Help text",
    "visualization.logics_manager.help_text_placeholder": "Add some help text to better explain your question",
    "visualization.logics_manager.close": "Close",
    "visualization.logics_manager.link": "Link",
    "visualization.logics_manager.unlink": "Unlink",
    "visualization.logics_manager.remove": "Remove",
    "visualization.logics_manager.cursor_notification": "Please select text",
    "visualization.logics_manager.errors.no_nested_options_from_one_question": "Two answers from the same question can not be nested within one another.",
    "visualization.logics_manager.errors.no_additional_logic_to_text_that_will_be_replaced": "A text input question can not be added within another text input question.",
    "visualization.logics_manager.errors.default": "The question can not be added to this text, try selecting a different location to add a question.",
    "visualization.logics_manager.tap_target.header": "Assign logic to text",
    "visualization.logics_manager.tap_target.description1": "Please select text",
    "visualization.logics_manager.tap_target.description2": "To cancel click button below or press ESC.",
    "visualization.logics_manager.choice.option_placeholder": "Enter text to display to the user",
    "visualization.logics_manager.choice.add_option": "Add another option",
    "visualization.logics_manager.choice.allow_multiple": "Allow multiple?",
    "visualization.logics_manager.choice.allow_multiple_caption": "User will be able to select multiple options.",
    "visualization.logics_manager.choice.options": "Available options",
    "visualization.logics_manager.input.linking": "Linking",
    "visualization.logics_manager.input.linking_caption": "Click button and select text to link question",
    "visualization.logics_manager.input.unlinking": "Change or remove link",
    "visualization.logics_manager.input.unlinking_caption": "Click button to remove question from the template",
    "visualization.logics_manager.reuse_question.header": "Show if ...",
    "visualization.logics_manager.reuse_question.header_caption": "Show text within the document if a previous multiple choice answer was selected. Select an answer below and then highlight the text in the document to show.",
    "visualization.logics_manager.reuse_question.no_questions": "No multiple choice questions found. Add a multiple choice question to be able to use this feature.",
    "visualization.logics_manager.reuse_question.search_placeholder": "Start typing...",
    "visualization.logics_manager.reuse_question.reuse": "Select",
    "visualization.logics_manager.constrains.heading": "Response validation",
    "visualization.logics_manager.constrains.title": "Constraints",
    "visualization.logics_manager.constrains.none": "None",
    "visualization.logics_manager.constrains.string": "ABC",
    "visualization.logics_manager.constrains.string_caption": "Only text",
    "visualization.logics_manager.constrains.uint": "123",
    "visualization.logics_manager.constrains.uint_caption": "Numerical",
    "visualization.logics_manager.constrains.number": "123",
    "visualization.logics_manager.constrains.number_caption": "Only numbers",
    "visualization.logics_manager.constrains.date": "Date",
    "visualization.logics_manager.constrains.date_caption": "Date format",
    "visualization.logics_manager.constrains.time": "Time",
    "visualization.logics_manager.constrains.time_caption": "Time format",
    "visualization.logics_manager.constrains.email": "Email",
    "visualization.logics_manager.constrains.email_caption": "Email format"
  },
  "nb": {
    "date.formats.default": "%d.%m.%Y",
    "date.formats.long": "%e. %B %Y",
    "date.formats.short": "%e. %b",
    "date.order": [
      "day",
      "month",
      "year"
    ],
    "number.currency.format.delimiter": " ",
    "number.currency.format.format": "%n %u",
    "number.currency.format.precision": 2,
    "number.currency.format.separator": ",",
    "number.currency.format.significant": false,
    "number.currency.format.strip_insignificant_zeros": false,
    "number.currency.format.unit": "kr",
    "number.format.delimiter": " ",
    "number.format.precision": 2,
    "number.format.separator": ",",
    "number.format.significant": false,
    "number.format.strip_insignificant_zeros": true,
    "number.human.decimal_units.format": "%n %u",
    "number.human.decimal_units.units.unit": "",
    "number.human.format.delimiter": " ",
    "number.human.format.precision": 1,
    "number.human.format.significant": false,
    "number.human.format.strip_insignificant_zeros": true,
    "number.human.storage_units.format": "%n %u",
    "number.percentage.format.delimiter": "",
    "number.percentage.format.format": "%n%",
    "number.precision.format.delimiter": "",
    "support.array.last_word_connector": " og ",
    "support.array.two_words_connector": " og ",
    "support.array.words_connector": ", ",
    "time.formats.default": "%A, %e. %B %Y, %H:%M",
    "time.formats.long": "%A, %e. %B %Y, %H:%M",
    "time.formats.short": "%e. %B, %H:%M",
    "visualization.logics_manager.constrains.email_caption": "E-postformat",
    "visualization.logics_manager.constrains.title": "Begrensninger",
    "visualization.logics_manager.constrains.none": "Ingen",
    "visualization.logics_manager.constrains.string": "ABC",
    "visualization.logics_manager.constrains.string_caption": "Kun tekst",
    "visualization.logics_manager.constrains.number": "123",
    "visualization.logics_manager.constrains.number_caption": "Bare tall",
    "visualization.logics_manager.constrains.date": "Dato",
    "visualization.logics_manager.constrains.date_caption": "Datoformat",
    "visualization.logics_manager.constrains.time": "Tid",
    "visualization.logics_manager.constrains.time_caption": "Tidsformat",
    "visualization.logics_manager.constrains.email": "E-post",
    "visualization.logics_manager.constrains.uint": "123",
    "visualization.logics_manager.constrains.uint_caption": "\nNumerisk",
    "visualization.logics_manager.constrains.heading": "Validering",
    "visualization.logics_manager.question_label": "Spørsmål",
    "visualization.logics_manager.question_placeholder": "Hva vil du spørre brukeren om?",
    "visualization.logics_manager.help_text_label": "Hjelpetekst",
    "visualization.logics_manager.help_text_placeholder": "Legg til hjelpetekst for å bedre forklare spørsmålet ditt",
    "visualization.logics_manager.close": "Lukk",
    "visualization.logics_manager.link": "Koble",
    "visualization.logics_manager.unlink": "Fjern kobling",
    "visualization.logics_manager.remove": "Fjern",
    "visualization.logics_manager.cursor_notification": "Marker tekst",
    "visualization.logics_manager.errors.no_nested_options_from_one_question": "To svar fra det samme spørsmålet kan ikke nestes i hverandre.",
    "visualization.logics_manager.errors.no_additional_logic_to_text_that_will_be_replaced": "Et tekstinnspørselsspørsmål kan ikke legges til i et annet innspillingsspørsmål.",
    "visualization.logics_manager.errors.default": "Spørsmålet kan ikke legges til denne teksten, prøv å velge et annet sted for å legge til et spørsmål.",
    "visualization.logics_manager.tap_target.header": "Koble logikk til tekst",
    "visualization.logics_manager.tap_target.description1": "Marker tekst",
    "visualization.logics_manager.tap_target.description2": "For å avbryte, trykk på knappen under eller trykk på ESC.",
    "visualization.logics_manager.choice.option_placeholder": "Skriv inn tekst som skal vises til brukeren",
    "visualization.logics_manager.choice.add_option": "Legg til et annet alternativ",
    "visualization.logics_manager.choice.allow_multiple": "Tillat flere?",
    "visualization.logics_manager.choice.allow_multiple_caption": "Brukeren vil kunne velge flere alternativer.",
    "visualization.logics_manager.choice.options": "Mulige valg",
    "visualization.logics_manager.input.linking": "Koble",
    "visualization.logics_manager.input.linking_caption": "Trykk på knappen og marker tekst for å lenke spørsmålet",
    "visualization.logics_manager.input.unlinking": "Endre eller fjern kobling",
    "visualization.logics_manager.input.unlinking_caption": "Trykk på knappen for å fjerne kobling",
    "visualization.logics_manager.reuse_question.header": "Vis om ...",
    "visualization.logics_manager.reuse_question.header_caption": "Vis tekst i dokumentet hvis et tidligere flervalgsvar ble valgt. Velg et svar nedenfor, og merk deretter teksten i dokumentet som skal vises.",
    "visualization.logics_manager.reuse_question.no_questions": "Ingen flere valgspørsmål funnet. Legg til et flervalgspørsmål for å kunne bruke denne funksjonen.",
    "visualization.logics_manager.reuse_question.search_placeholder": "Begynn å skrive ...",
    "visualization.logics_manager.reuse_question.reuse": "Bruk om igjen",
    "visualization.logics_manager.edit_question": "Rediger spørsmål",
    "visualization.logics_manager.settings": "Innstillinger",
    "visualization.logics_manager.formatting_title": "Formatering",
    "visualization.logics_manager.allow_long_answer_checkbox": "Tillat lange svar",
    "visualization.errors.forbid_to_paste_inside_inline_question": "Du kan ikke lime inn dette innholdet i inline-spørsmålet.",
    "visualization.publish_unpublish.header": "Publiser mal",
    "visualization.publish_unpublish.intro": "Publisering av en mal vil føre til at den vises i malbiblioteket, der andre kan bruke malen til å opprette dokumenter.",
    "visualization.publish_unpublish.what_did_you_change": "Versjonsbeskrivelse",
    "visualization.publish_unpublish.textarea_placeholder": "Legg til en beskrivelse av hva du endret",
    "visualization.publish_unpublish.notification": "Varsel",
    "visualization.publish_unpublish.notification_caption": "Varsle brukere som har opprettet et dokument med en gammel versjon av denne malen om at den nå er oppdatert?",
    "visualization.publish_unpublish.yes": "Ja",
    "visualization.publish_unpublish.no": "Nei",
    "visualization.publish_unpublish.publish": "Publiser",
    "visualization.publish_unpublish.unpublish": "Avpubliser",
    "visualization.publish_unpublish.cancel": "Avbryt",
    "visualization.top_toolbar.back": "Tilbake",
    "visualization.top_toolbar.status": "Status {val}",
    "visualization.top_toolbar.questionnaire_preview": "Forhåndsvis",
    "visualization.top_toolbar.publish": "Publiser",
    "visualization.top_toolbar.unpublish": "Avpubliser",
    "visualization.top_toolbar.edit_draft": "Rediger utkast",
    "visualization.top_toolbar.create_draft": "Opprett utkast",
    "visualization.top_toolbar.create_document": "Opprett dokument",
    "visualization.top_toolbar.rename_template": "Gi nytt navn til mal",
    "visualization.top_toolbar.sub_menu.template_details": "Mal detaljer",
    "visualization.top_toolbar.sub_menu.settings": "Innstillinger",
    "visualization.top_toolbar.share": "Del",
    "visualization.top_toolbar.version.title": "Versjon {val}",
    "visualization.top_toolbar.version.help_text": "Bare nyeste versjon av dokumentet kan sendes til signering.",
    "visualization.top_toolbar.version.viewing": "Viser",
    "visualization.top_toolbar.version.view": "Vis >",
    "visualization.right_sidebar.flow": "Flyt",
    "visualization.right_sidebar.drafts": "Utkast spørsmål",
    "visualization.right_sidebar.drafts_help_text": "Liste over spørsmål som ikke er koblet til malen",
    "visualization.right_sidebar.no_question_name": "Ingen spørsmål",
    "visualization.editor.placeholder": "Rediger innholdet ditt her",
    "visualization.editor.choice": "Flervalgsspørsmål",
    "visualization.editor.text_input": "Tekst input",
    "visualization.editor.reuse_question": "Vis om ...",
    "visualization.editor.group": "Gruppealternativer",
    "visualization.editor.refresh": "Oppdater",
    "visualization.flow.header": "Spørsmål",
    "visualization.flow.edit": "Rediger",
    "date.day_names": [
      "Søndag",
      "Mandag",
      "Tirsdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lørdag"
    ],
    "date.abbr_day_names": [
      "Søn",
      "Man",
      "Tirs",
      "Ons",
      "Tors",
      "Fre",
      "Lør"
    ],
    "date.month_names": [
      null,
      "Januar",
      "Februar",
      "Mars",
      "April",
      "Mai",
      "Juni\n",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Desember"
    ],
    "date.abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mars",
      "April",
      "Mai",
      "Juni\n",
      "Juli",
      "Aug",
      "Sept",
      "Okt",
      "Nov",
      "Des"
    ],
    "time.am": "AM",
    "time.pm": "PM",
    "number.human.storage_units.units.byte.one": "Byte",
    "number.human.storage_units.units.byte.other": "Bytes",
    "number.human.storage_units.units.kb": "kB",
    "number.human.storage_units.units.mb": "MB",
    "number.human.storage_units.units.gb": "GB",
    "number.human.storage_units.units.tb": "TB",
    "number.human.storage_units.units.pb": null,
    "number.human.storage_units.units.eb": null,
    "number.human.decimal_units.units.thousand": "tusen",
    "number.human.decimal_units.units.million": null,
    "number.human.decimal_units.units.billion": null,
    "number.human.decimal_units.units.trillion": null,
    "number.human.decimal_units.units.quadrillion": null,
    "errors.messages.already_confirmed": "was already confirmed, please try signing in",
    "errors.messages.confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
    "errors.messages.expired": "has expired, please request a new one",
    "errors.messages.not_found": "not found",
    "errors.messages.not_locked": "was not locked",
    "errors.messages.not_saved.one": "1 error prohibited this %{resource} from being saved:",
    "errors.messages.not_saved.other": "%{count} errors prohibited this %{resource} from being saved:",
    "errors.page_not_authorized": "Du har ikke tilgang til denne siden.",
    "errors.action_not_authorized": "Du er ikke autorisert til å utføre denne handlingen.",
    "errors.not_authorized": "Du er ikke autorisert.",
    "errors.permissions.super_admin": "You have no permission to access super admin section",
    "errors.permissions.admin": "You have no permission to access admin section",
    "errors.permissions.client": "Du har ikke tilgang til administratorområdet",
    "errors.email?": "stemmer ikke",
    "errors.template_preview": "Ops, her har det skjedd en feil.",
    "errors.sender_has_to_sign": null,
    "activerecord.errors.messages.too_weak": "Passordet er ikke sikkert. Bruk hint for å gjøre det sterkere.",
    "activerecord.errors.models.user.attributes.email.invalid": "E-posten er ugyldig",
    "helpers.select.prompt": "Velg",
    "ransack.predicates.false": "stemmer ikke",
    "devise.confirmations.confirmed": "Din e-postadresse er nå bekreftet.",
    "devise.confirmations.send_instructions": "Du vil om kort tid motta en e-post med instruksjoner for hvordan du bekrefter e-postadressen din.",
    "devise.confirmations.send_paranoid_instructions": "Du vil straks motta en e-post med instruksjoner for hvordan du bekrefte e-postadressen din.",
    "devise.failure.already_authenticated": "Du er allerede pålogget.",
    "devise.failure.inactive": "Din konto er ikke aktivert ennå.",
    "devise.failure.invalid": "Ugyldig %{authentication_keys} eller passord.",
    "devise.failure.locked": "Kontoen din er nå låst.",
    "devise.failure.last_attempt": "Du har ett forsøk igjen før kontoen blir låst.",
    "devise.failure.not_found_in_database": "Ugyldig %{authentication_keys} eller passord.",
    "devise.failure.timeout": "Økten er utløpt. Logg deg inn på nytt for å fortsette.",
    "devise.failure.unauthenticated": "Du må logge inn eller registrere deg for å fortsette.",
    "devise.failure.unconfirmed": "Du må bekrefte e-postadressen din før du fortsetter.",
    "devise.mailer.confirmation_instructions.subject": "Bekreftelsesinstruksjoner",
    "devise.mailer.reset_password_instructions.subject": "Instruksjoner for tilbakestilling av passord",
    "devise.mailer.reset_password_instructions.hello": "Hei, %{name}",
    "devise.mailer.reset_password_instructions.change_password": "Tilbakestill ditt passord",
    "devise.mailer.reset_password_instructions.first_paragraph": "Du ba nettopp om å tilbakestille passordet ditt. Klikk på knappen under for å få det tilbakestilt:",
    "devise.mailer.reset_password_instructions.button_text": "Tilbakestill passord",
    "devise.mailer.reset_password_instructions.second_paragraph": "Hvis det ikke var du som forsøkte å endre passordet, og du tror din konto kan ha blitt kompromittert, kan du",
    "devise.mailer.reset_password_instructions.mail_to_text": "kontakte oss",
    "devise.mailer.reset_password_instructions.help": ".",
    "devise.mailer.reset_password_instructions.greetings": "Med vennlig hilsen,",
    "devise.mailer.unlock_instructions.subject": "Se instruksjoner",
    "devise.mailer.email_changed.subject": "E-postadressen er nå endret",
    "devise.mailer.password_change.subject": "Passordet er nå endret",
    "devise.omniauth_callbacks.failure": "Kunne ikke bekrefte deg fra %{kind} fordi \"%{reason}\".",
    "devise.omniauth_callbacks.success": "Bekreftet fra %{kind} konto.",
    "devise.passwords.no_token": "For å få tilgang til denne siden, må du klikke på linken som vi sender i e-post for tilbakestilling av passord. Om du har mottatt en slik link, sørg for at du bruker hele URL-adressen.",
    "devise.passwords.send_instructions": "Du vil straks motta en e-post med instruksjoner for hvordan du tilbakestiller passordet ditt. ",
    "devise.passwords.send_paranoid_instructions": "Du vil straks motta en e-post med en link. Følg linken for å tilbakestille passordet ditt.",
    "devise.passwords.updated": "Passordet ditt er endret. Du er nå logget inn.",
    "devise.passwords.updated_not_active": "Ditt passord har nå blitt endret.",
    "devise.passwords.new.forgot_password_title": "Glemt passordet ditt?",
    "devise.passwords.new.forgot_password_subtitle": "Skriv inn e-posten din nedenfor, og vi vil sende deg instruksjoner for å tilbakestille passordet ditt.",
    "devise.passwords.new.email": "E-post",
    "devise.passwords.new.send_new_password": "Tilbakestill passord",
    "devise.passwords.edit.create_new_password": "Nytt passord",
    "devise.passwords.edit.new_password": "Nytt passord",
    "devise.passwords.edit.confirm_password": "Bekreft passord",
    "devise.passwords.edit.save_password": "Lagre passord",
    "devise.passwords.edit.short": "Passordet er for kort.",
    "devise.passwords.edit.weak": "Passordet er svakt. Legg til bokstaver (store/små bokstaver).",
    "devise.passwords.edit.good": "Bra legg til numr",
    "devise.passwords.edit.strong": "Ditt passord er STERKT",
    "devise.registrations.destroyed": "Du har nå avsluttet din konto hos oss. Vi håper å se deg igjen!",
    "devise.registrations.signed_up": "Velkommen! Du er nå registrert. ",
    "devise.registrations.signed_up_but_inactive": "Du er nå registrert, men før du kan logge deg inn må kontoen din aktiveres. ",
    "devise.registrations.signed_up_but_locked": "Du er nå registrert. Du kan imidlertid ikke logge deg inn nå, fordi kontoen din er låst. ",
    "devise.registrations.signed_up_but_unconfirmed": "En melding med en bekreftelseslink er sendt til din e-postadresse. Følg linken for å aktivere kontoen din.",
    "devise.registrations.update_needs_confirmation": "Din konto har nå blitt oppdatert, men vi trenger å bekrefte e-postadressen din. Følg linken som vi har sendt til e-posten din for å bekrefte e-postadressen. ",
    "devise.registrations.updated": "Kontoen din har nå blitt oppdatert.",
    "devise.sessions.signed_in": "Du er pålogget. ",
    "devise.sessions.signed_out": "Logget av. ",
    "devise.sessions.already_signed_out": "Logget av. ",
    "devise.sessions.new.log_in": "Logg på din konto",
    "devise.sessions.new.email": "E-post",
    "devise.sessions.new.password": "Passord",
    "devise.sessions.new.forgot_password": "Glemt passord?",
    "devise.unlocks.send_instructions": "Du vil om kort tid motta en e-post med instruksjoner om hvordan du skal åpne kontoen din.",
    "devise.unlocks.send_paranoid_instructions": "Du vil om kort tid motta en e-post med instruksjoner om hvordan du åpner kontoen din.",
    "devise.unlocks.unlocked": "Din konto har nå blitt låst opp. Logg inn for å fortsette.",
    "devise.shared.links.already_user": "Allerede en bruker? Logg inn her",
    "devise.shared.links.register": "Registrer din Lawbotics-konto",
    "devise.shared.devise_navbar.home": "Hjem",
    "devise.shared.devise_navbar.create_document": "Opprett dokument",
    "devise.shared.devise_navbar.log_in": "Logg inn",
    "devise.shared.devise_navbar.create_account": "Opprett konto",
    "devise.shared.tooltip.characters_count": "Minst 6 tegn",
    "devise.shared.tooltip.letters_numbers": "Bruk bokstaver og tall",
    "devise.shared.tooltip.mix": "Bruk både små og store bokstaver",
    "views.pagination.previous": "<",
    "views.pagination.next": ">",
    "views.pagination.truncate": "...",
    "rails_email_preview.emails.send_form.send_are_you_sure": "Er du sikker på at du vil sende denne e-posten?",
    "api.panel.questionnaire_templates.actions.menu_template_information": "Malinformasjon",
    "api.panel.questionnaire_templates.actions.menu_move_to": "Flytt til...",
    "api.panel.questionnaire_templates.actions.move_to": "Flytt til...",
    "api.panel.questionnaire_templates.actions.move": "Flytt",
    "api.panel.questionnaire_templates.actions.cancel": "Avbryt",
    "api.panel.questionnaire_templates.actions.trash": "Flytt til papirkurv",
    "api.panel.questionnaire_templates.actions.share": "Del dokument link",
    "api.panel.questionnaire_templates.actions.duplicate": "Duplisere",
    "api.panel.questionnaire_templates.actions.menu_template_settings": "Innstillinger for maler",
    "api.panel.questionnaire_templates.create.new_template": "Ny mal",
    "api.panel.questionnaire_templates.modal_title": "Maler",
    "api.panel.questionnaire_templates.back": "Tilbake",
    "api.panel.questionnaire_templates.already_in_folder": "Elementet er allerede i denne mappen",
    "api.panel.questionnaire_template_versions.user_questionnaires.create.new_document": "Nytt dokument",
    "api.panel.questionnaire_template_versions.user_questionnaires.create.allTemplates": "Alle maler",
    "forgot_password.title": "Glemt passord?",
    "forgot_password.subtitle": "Skriv inn e-posten din nedenfor, og vi vil sende deg instruksjoner for å tilbakestille passordet ditt.",
    "forgot_password.labels.email": "E-post",
    "forgot_password.labels.submit": "Tilbakestill passord",
    "forgot_password.labels.already_logged": "Er du allerede bruker? Logg inn her",
    "forgot_password.errors.invalid_email": "Ugyldig epost",
    "forgot_password.errors.email_not_exist": "Benyttet e-post finnes ikke",
    "forgot_password.errors.cant_be_blank": "Feltet kan ikke være tomt",
    "forgot_password.verified.title": "Ditt passord er tilbakestilt. ",
    "forgot_password.verified.paragraph": "Hvis det finnes en konto med e-posten du skrev inn, sender vi deg instruksjoner om hvordan du oppretter et nytt passord. Hvis du ikke mottok e-posten må du kontrollere spam mappen din eller dobbelkjekke e-postadressen du skrev inn, og prøv igjen.",
    "forgot_password.verified.log_in": "Logg inn her",
    "reset_password.title": "Nytt passord",
    "reset_password.labels.password": "Nytt passord",
    "reset_password.labels.confirm_password": "Bekreft passord",
    "reset_password.labels.submit": "Lagre passord",
    "reset_password.labels.already_logged": "Er du allerede bruker? Logg inn her",
    "reset_password.errors.must_be_the_same": "Passordene må være like",
    "reset_password.errors.password_not_strong_enough": "Dette passordet er ikke sterkt nok",
    "reset_password.errors.cant_be_blank": "kan ikke være tomt",
    "sign_in.title": "Logg på din konto",
    "sign_in.labels.email": "E-post",
    "sign_in.labels.password": "Passord",
    "sign_in.labels.submit": "Logg på din konto",
    "sign_in.labels.forgot_password": "Glemt passord?",
    "sign_in.errors.invalid_email": "E-post\n",
    "sign_in.errors.cant_be_blank": "Feltet kan ikke være tomt",
    "sign_in.errors.wrong_password": "E-postadressen eller passordet du skrev inn var feil. Vennligst prøv på nytt.",
    "sign_in.heading": null,
    "sign_in.subheading": null,
    "company_mailer.invite_employee.how_to_start": "Kom i gang",
    "company_mailer.invite_employee.create_template_with_editor": "Lag mal med redigeringsverktøy",
    "company_mailer.invite_employee.create_template_with_editor_description": "Alquip utøvelse nostrud ad tempor ipsum trening tempor. Tempor amet ad do laboris. Sint excepteur cillum magna labore. Eu occaecat incididunt er sint eiusmod qui officia utøvelse eiusmod eiusmod qui veniam. Du vil bare ha en god dag, og du vil ikke tro det.",
    "company_mailer.invite_employee.manage_versions": "Få oversikt",
    "company_mailer.invite_employee.manage_versions_description": "Alquip utøvelse nostrud ad tempor ipsum trening tempor. Tempor amet ad do laboris. Sint excepteur cillum magna labore. Eu occaecat incididunt er sint eiusmod qui officia utøvelse eiusmod eiusmod qui veniam. Du vil bare ha en god dag, og du vil ikke tro det.",
    "company_mailer.invite_employee.send_to_clients": "Send til klient",
    "company_mailer.invite_employee.send_to_clients_description": "Alquip utøvelse nostrud ad tempor ipsum trening tempor. Tempor amet ad do laboris. Sint excepteur cillum magna labore. Eu occaecat incididunt er sint eiusmod qui officia utøvelse eiusmod eiusmod qui veniam. Du vil bare ha en god dag, og du vil ikke tro det.",
    "company_mailer.invite_employee.need_help": "Trenger du hjelp? Kontakt oss på: ",
    "company_mailer.invite_employee.with_regards_html": "Beste hilsener <br> <b>Team Lawbotics</b>",
    "company_mailer.invite_employee.invite": "INVITERE",
    "company_mailer.invite_employee.welcome": "Velkommen til Lawbotics",
    "company_mailer.invite_employee.hello": "Hei %{email},",
    "company_mailer.invite_employee.activate_account": "Aktiver min konto",
    "company_mailer.invite_employee.invite_message": "Du har blitt invitert til å delta i %{company} arbeidsområdet i Lawbotics. Klikk på knappen nedenfor for å konfigurere og opprette din konto.",
    "company_mailer.invite_employee.subject": "Din invitasjon til Lawbotics",
    "company_mailer.invite_admin.how_to_start": "Kom i gang",
    "company_mailer.invite_admin.create_template_with_editor": "Lag maler med vårt redigeringsverktøy",
    "company_mailer.invite_admin.create_template_with_editor_description": "Alquip utøvelse nostrud ad tempor ipsum trening tempor. Tempor amet ad do laboris. Sint excepteur cillum magna labore. Eu occaecat incididunt er sint eiusmod qui officia utøvelse eiusmod eiusmod qui veniam. Du vil bare ha en god dag, og du vil ikke tro det.",
    "company_mailer.invite_admin.manage_versions": "Få oversikt",
    "company_mailer.invite_admin.manage_versions_description": "Alquip utøvelse nostrud ad tempor ipsum trening tempor. Tempor amet ad do laboris. Sint excepteur cillum magna labore. Eu occaecat incididunt er sint eiusmod qui officia utøvelse eiusmod eiusmod qui veniam. Du vil bare ha en god dag, og du vil ikke tro det.",
    "company_mailer.invite_admin.send_to_clients": "Send til klienter",
    "company_mailer.invite_admin.send_to_clients_description": "Alquip utøvelse nostrud ad tempor ipsum trening tempor. Tempor amet ad do laboris. Sint excepteur cillum magna labore. Eu occaecat incididunt er sint eiusmod qui officia utøvelse eiusmod eiusmod qui veniam. Du vil bare ha en god dag, og du vil ikke tro det.",
    "company_mailer.invite_admin.need_help": "Trenger du hjelp? Kontakt oss på: ",
    "company_mailer.invite_admin.with_regards_html": "Beste hilsener <br> <b>Team Lawbotics</b>",
    "company_mailer.invite_admin.admin": "Admin",
    "company_mailer.invite_admin.hello": "Du kan nå registrere deg i systemet, og invitere andre fra selskapet ditt.",
    "company_mailer.invite_admin.congratulations": "Hei",
    "company_mailer.invite_admin.invite_message": "Ha det gøy, og ikke nøl med å gi oss tilbakemelding.",
    "company_mailer.invite_admin.register": "Registrer din konto",
    "company_mailer.invite_admin.subject": "Din invitasjon til Lawbotics",
    "top_navbar.settings": "Innstillinger",
    "top_navbar.profile_settings": "Profilinnstillinger",
    "top_navbar.log_out": "Logg ut",
    "top_navbar.dashboard": "Dashbord",
    "top_navbar.templates": "Maler",
    "top_navbar.documents": "Dokumenter",
    "top_navbar.users": "Brukere",
    "top_navbar.template_library": "Malarkiv",
    "top_navbar.help": "Hjelp",
    "top_navbar.logo_alt": "Lawbotics",
    "top_navbar.company": "Selskap",
    "top_navbar.team": "Team",
    "top_navbar.template_styles": "Tilpass mal",
    "top_navbar.company_settings": "Selskap innstillinger",
    "top_navbar.search": "Søk...",
    "top_navbar.home": "Hjem",
    "top_navbar.create_document": "Opprett dokument",
    "top_navbar.log_in": "Logg inn",
    "search_no_results.header": "Ingen resultat funnet",
    "search_no_results.subheader": "Prøv å skrive inn en annen setning",
    "search_results.heading": "Søkeresultater",
    "documents_list.columns_names.document": "Dokument",
    "documents_list.columns_names.created_at": "Opprettet på",
    "documents_list.columns_names.edited_at": "Redigert på",
    "documents_list.columns_names.status": "Status",
    "documents_list.columns_names.created_by": "Opprettet av",
    "documents_list.actions.move_to_trash": "Flytt til papirkurv",
    "documents_list.empty_bucket.heading": "Denne mappen er tom",
    "documents_list.empty_bucket.paragraph": "Klikk på knappen nedenfor for å opprette et nytt dokument.",
    "documents_list.empty_bucket.buttonLabel": "Opprett nytt dokument",
    "documents_list.trash": "Papirkurv",
    "documents_list.based_on": "Basert på",
    "documents_list.version": "Versjon",
    "documents_list.documents_count": "dokumenter",
    "documents_list.new_folder": "Ny mappe",
    "documents_list.enter_folder_name": "Skriv inn mappenavn",
    "documents_list.delete_document_modal.title": "Sletter et dokument som skal signeres",
    "documents_list.delete_document_modal.description": "Du er i ferd med å slette et dokument som er i en signeringsprosess.",
    "documents_list.delete_document_modal.wish_to_abort": "Ønsker du å avbryte signeringen og slette dokumentet?",
    "documents_list.delete_document_modal.delete": "Slett",
    "documents_list.delete_document_modal.reverse_description": "Denne handlingen kan ikke reverseres. Det vil også avbryte signeringsprosessen.",
    "documents_list.duplicate_document.title": "Dupliserer dokument",
    "documents_list.duplicate_document.subheading": "Gi duplikatet et navn så du kan gjenkjenne det fra originalen.",
    "documents_list.duplicate_document.copying_from_old_template_version": "Merk: Dokumentet du kopierer er basert på en eldre mal. Lag et nytt dokument hvis du heller ønsker å benytte nyeste versjon.",
    "documents_list.duplicate_document.copy": "Kopiér",
    "documents_list.duplicate_document.label": "Dokument navn",
    "document_templates.header_and_footer": "Topptekst og bunntekst",
    "document_templates.header": "Topptekst",
    "document_templates.footer": "Bunntekst",
    "document_templates.add_pagination": "Sett inn sidetall",
    "document_templates.styles": "Stiler",
    "document_templates.paragraphs": "Avsnitt",
    "document_templates.headlines": "Overskrifter",
    "document_templates.font": "Font",
    "document_templates.font_size": "Skriftstørrelse",
    "document_templates.margins": "Sidemarginer",
    "document_templates.top": "Øverst",
    "document_templates.right": "Høyre",
    "document_templates.bottom": "Nederst",
    "document_templates.left": "Venstre",
    "document_templates.h1": "Overskrift 1",
    "document_templates.h2": "Overskrift 2",
    "document_templates.h3": "Overskrift 3",
    "document_templates.h4": "Overskrift 4",
    "document_templates.numbering.label": "Nummerering",
    "document_templates.numbering.description": "Når 'Aktiv' så vil alle overskrifter i malen vises som en nummerert liste med format 1., 1.1., 1.1.1. osv. Titler nummereres ikke",
    "document_templates.numbering.switch_on": "På",
    "document_templates.numbering.switch_off": "Av",
    "document_templates.title": "Tittel",
    "drift.welcome_message": "Hei, hvordan kan vi hjelpe deg?",
    "drift.away_message": "Vi er for øyeblikket ikke på nett, men hvis du skriver inn en melding, kommer vi tilbake til deg så snart som mulig!",
    "drift.email_capture_message": "Skriv inn din epost slik at vi kan komme tilbake til deg.",
    "drift.thank_you_message": "Takk, vi skal følge det opp snart.",
    "general.are_you_sure": "Er du sikker?",
    "general.yes": "Ja",
    "general.no": "Nei",
    "general.hide": "Skjul",
    "general.show": null,
    "application.error.header": "Det har oppstått et lite problem.",
    "application.error.back": "Gå tilbake til hovedsiden.",
    "shared.cancel": "Avbryt",
    "shared.ok": "Ok\n",
    "shared.notifications.alert": "Varsling",
    "shared.notifications.success": "Suksess",
    "shared.notifications.error": "Feil",
    "shared.notifications.info": "Info",
    "shared.statuses.started": "Påbegynt",
    "shared.statuses.sent_for_signing": "Sendt for signering",
    "shared.statuses.signed": "Signert",
    "shared.statuses.completed": "Fullført",
    "shared.statuses.expired": "Utløpt",
    "shared.statuses.rejected": "Avvist",
    "shared.statuses.error": "Feil",
    "shared.statuses.active": "Aktiv",
    "shared.statuses.inactive": "Inaktiv",
    "shared.statuses.moved_to_trash": "Elementet ble flyttet til papirkurven",
    "shared.statuses.undo": "Angre",
    "shared.statuses.restored_from_trash": "Gjenopprettet {name}",
    "shared.statuses.draft": "Utkast",
    "shared.statuses.signing_requested": "Sendt for signering",
    "shared.statuses.signing_expired": "Utløpt",
    "shared.statuses.signing_aborted": "Avbrutt",
    "shared.statuses.signing_failed": "Feil",
    "shared.errors.go_back": "Gå tilbake",
    "shared.errors.default.title": "Ops, her har det skjedd en feil.",
    "shared.errors.default.message": "Vennligst gå tilbake og prøv igjen.",
    "shared.errors.template_not_active.title": "Oops, denne malen er desverre ikke aktiv lengre.",
    "shared.errors.template_not_active.message": "Vennligst ta kontakt med eieren av malen",
    "shared.errors.user_not_authorized": "Du er ikke autorisert til å utføre denne handlingen.",
    "shared.errors.trash_template_has_documents": "Malen har dokumenter. Du kan ikke fjerne den.",
    "shared.errors.document_in_trash.title": "Oops! Dette elementet finnes ikke lengre",
    "shared.errors.document_in_trash.message": "Vennligst sjekk om du har riktig link. Hvis feilen fortsetter må du dessverre kontakte eieren av malen for å be om en ny link.",
    "shared.errors.no_published_version.title": "Oops, denne malen er desverre ikke aktiv lengre.",
    "shared.errors.no_published_version.message": "Vennligst sjekk om du har riktig link. Hvis feilen fortsetter må du dessverre kontakte eieren av dokument malen for å be om en ny link.",
    "shared.errors.create_document_failed.title": "Ops, her har det skjedd en feil.",
    "shared.errors.create_document_failed.message": "Vi klarte dessverre ikke lage ditt dokument. Ta kontakt med kunde support hvis feilen fortsetter.",
    "shared.errors.company_not_authorized.title": "Ops, her har det skjedd en feil.",
    "shared.errors.company_not_authorized.message": "Vi klarte dessverre ikke lage ditt dokument. Ta kontakt med kunde support hvis feilen fortsetter.",
    "shared.errors.token_expired.title": "Ops, her har det skjedd en feil.",
    "shared.errors.token_expired.message": "Din login er utløpt. Vennligst login via huseiernes hjemmeside for å få tilgang til dokumentet.",
    "shared.errors.wrong_token.title": "Ops, her har det skjedd en feil.",
    "shared.errors.wrong_token.message": "Vi klarer ikke å logge deg inn. Vennligst kontakt eier av malen for å få tilgang til dokumentet.",
    "shared.errors.document_undeletable": "Dette dokumentet kan ikke slettes da det er sendt til signering. Venligst stop signeringsprocessen først.",
    "shared.errors.link_expired.title": "Denne linken er utløpt",
    "shared.errors.link_expired.message": "På grunn av sikkerhetsårsaken er tilgangslinken din ikke lenger gyldig. Klikk på knappen under og vi vil sende deg en ny lenke",
    "shared.errors.link_expired.button": "Send ny tilgangslink",
    "shared.errors.not_found.title": "Siden ble ikke funnet",
    "shared.errors.not_found.message": "Koblingen du klikket på kan være ødelagt, eller siden kan ha blitt fjernet",
    "shared.errors.not_found.button": "Ta meg tilbake til startsiden",
    "shared.errors.session_expired.title": "Økten er utløpt.",
    "shared.errors.session_expired.message": "Økten er utløpt.",
    "shared.errors.record_not_found.title": "Siden ble ikke funnet",
    "shared.errors.record_not_found.message": "Koblingen du klikket på kan være ødelagt, eller siden kan ha blitt fjernet",
    "shared.errors.record_not_found.button": "Ta meg tilbake til startsiden",
    "shared.errors.user_disabled.title": "Har du brukt feil konto?",
    "shared.errors.user_disabled.message": "Hvis du mener at du skal ha tilgang, anbefaler vi deg å ta {newLine} kontakt med din administratoren for å gjenopprette kontoen din.",
    "shared.errors.user_disabled.button": "Tilbake til innlogging",
    "shared.errors.user_disabled.subtitle": "E-posten du brukte har ikke tilgang til Lawbotics lenger.",
    "shared.errors.go_lexolve": "Gå tilbake til Lexolve",
    "shared.next": "Neste",
    "shared.save": "Lagre",
    "main.new_folder.is_required": "Vennligst skriv inn mappenavn",
    "main.new_folder.new_folder": "Ny mappe",
    "main.new_folder.enter_folder_name": "Skriv inn mappenavn",
    "main.index.help_improve": "Hjelp oss med å bli bedre - har du innspill eller forslag til oss?",
    "main.index.give_feedback": "Gi tilbakemelding",
    "main.home_navbar.about_lawbotics": "Om Lawbotics",
    "main.home_navbar.how_works": "Slik fungerer det",
    "main.home_navbar.about_agreements": null,
    "main.home_navbar.create_document": "Opprett dokument",
    "main.home_navbar.client_panel": "Klientpanel",
    "main.home_navbar.log_in": "Logg inn",
    "main.home_intro.create_agreement": null,
    "main.home_intro.subtitle": null,
    "main.home_intro.create_document": "Opprett dokument",
    "main.home_testimonials.even_testimonials": null,
    "main.home_testimonials.even_title": null,
    "main.home_testimonials.christine_testimonials": null,
    "main.home_testimonials.christine_title": null,
    "main.home_boxes.why_agreement_title": null,
    "main.home_boxes.why_agreement_par": null,
    "main.home_boxes.more_than_template_title": null,
    "main.home_boxes.more_than_template_par": null,
    "main.home_boxes.their": null,
    "main.home_boxes.needs": null,
    "main.home_boxes.fraction_cost": null,
    "main.home_boxes.security_title": null,
    "main.home_boxes.security_par": null,
    "main.home_steps.steps_title": null,
    "main.home_steps.step1_title": null,
    "main.home_steps.step1_par": null,
    "main.home_steps.create_document": "Opprett dokument",
    "main.home_steps.step2_title": null,
    "main.home_steps.step2_par": "Deretter svarer du på spørsmål ved å velge mellom ulike svaralternativer. Både spørsmål og svar er utformet av advokater med høy erfaring, for å sikre at aksjonæravtalen tilpasses deres situasjon og behov.",
    "main.home_steps.step3_tile": null,
    "main.home_steps.step3_par": null,
    "main.home_orders_type.home_orders_title": null,
    "main.home_orders_type.read_more": "Les mer",
    "main.home_orders_type.enterpreneurs": null,
    "main.home_orders_type.shareholders": null,
    "main.home_orders_type.agreement_employees": null,
    "main.home_orders_type.external_investor": null,
    "main.footer.about_lawbotics": "Om Lawbotics",
    "main.footer.terms_of_use": null,
    "main.footer.price": null,
    "main.footer.contact": "Kontakt",
    "main.footer.about_agreements": null,
    "main.footer.enterpreneurs": null,
    "main.footer.enterpreneurs_50_50": null,
    "main.footer.enterpreneurs_employees": null,
    "main.footer.enterpreneurs_investors": null,
    "main.footer.newsletter": "Nyhetsbrev",
    "main.footer.newsletter_par": null,
    "main.footer.copyright": null,
    "main.footer.privacy": null,
    "pages.account_verification.account_verified": "Konto verifisert",
    "pages.account_verification.receive_in_minutes": "Du vil i løpet av noen minutter motta en",
    "pages.account_verification.email_information": "e-post med informasjon",
    "pages.account_verification.how_to": "om hvordan du oppretter et nytt passord.",
    "pages.account_verification.log_in": "Logg inn her",
    "superadmin.layouts.sadmin_navbar.users": "Brukere",
    "superadmin.layouts.sadmin_navbar.templates": "Maler",
    "superadmin.layouts.sadmin_navbar.documents": "Dokumenter",
    "superadmin.layouts.sadmin_navbar.settings": "Innstillinger",
    "superadmin.layouts.sadmin_navbar.log_out": "Logg ut",
    "superadmin.users.index.today": "I dag",
    "superadmin.users.index.last_week": "Forrige uke",
    "superadmin.users.index.last_month": "Forrige måned",
    "superadmin.users.index.last_year": "I fjor",
    "superadmin.users.index.from": "fra:",
    "superadmin.users.index.till": "inntil:",
    "superadmin.users.index.users_registered": "Brukerregistreringer",
    "superadmin.users.index.users_active": "Innlogging for brukere",
    "superadmin.users.index.users_with_orders": "Brukerbestillinger",
    "superadmin.users.index.users": "Brukere",
    "superadmin.users.index.users_subtitle": "Oversikt over alle registrerte brukere",
    "superadmin.users.index.search": "Søk...",
    "superadmin.users.index.user_name": "Brukernavn",
    "superadmin.users.index.email": "E-post",
    "superadmin.users.index.company": "Selskap\n",
    "superadmin.users.index.role": "Rolle",
    "superadmin.users.index.login_total": "Totale innlogginger",
    "superadmin.users.index.last_active": "Sist aktiv",
    "superadmin.users.user.user_name": "Navn",
    "superadmin.users.user.preview": "Forhåndsvis",
    "superadmin.users.user.company": "Selskap\n",
    "superadmin.users.user.role": "Rolle",
    "superadmin.users.user.last_active": "Sist aktiv",
    "superadmin.users.user.edit": "Rediger",
    "superadmin.users.user.delete": "Slett",
    "superadmin.users.show.back": "Gå tilbake til oversikt over brukere",
    "superadmin.users.show.registered": "Registrert: ",
    "superadmin.users.show.agreement": "Avtale",
    "superadmin.users.show.creation_date": "Dato for opprettelse",
    "superadmin.users.show.last_edit": "Siste endring",
    "superadmin.users.show.progress": "Fremgang",
    "superadmin.users.show.activity": "Aktivitet",
    "superadmin.users.show.time": "Tidspunkt",
    "superadmin.users.show.edit_user": "Rediger bruker",
    "superadmin.users.show.company": "Selskap",
    "superadmin.users.show.role": "Rolle",
    "superadmin.users.show.contact": "Kontakt",
    "superadmin.users.show.templates": "Maler",
    "superadmin.users.show.documents": "Dokumenter",
    "superadmin.users.documents.document": "Navn",
    "superadmin.users.documents.user": "Bruker",
    "superadmin.users.documents.creation_date": "Dato for opprettelse",
    "superadmin.users.documents.last_edit": "Siste endring",
    "superadmin.users.documents.progress": "Fremgang",
    "superadmin.users.templates.template_name": "Navn",
    "superadmin.users.templates.created_by": "Opprettet av",
    "superadmin.users.templates.status": "Status",
    "superadmin.users.edit.back": "Gå tilbake til brukeroversikt",
    "superadmin.users.edit.first_name": "Fornavn",
    "superadmin.users.edit.last_name": "Etternavn",
    "superadmin.users.edit.email": "E-post",
    "superadmin.users.edit.role": "Rolle",
    "superadmin.users.edit.company_admin": "Bedriftsadministrator",
    "superadmin.users.edit.producer": "Produsent",
    "superadmin.users.edit.consumer": "Bruker",
    "superadmin.users.edit.save": "Lagre",
    "superadmin.users.edit.delete_user": "Slett bruker",
    "superadmin.users.edit.active_user": "Aktiv bruker:",
    "superadmin.users.edit.yes": "Ja",
    "superadmin.users.edit.no": "Nei",
    "superadmin.users.edit.reinvite_user": "Inviter bruker på nytt",
    "superadmin.users.edit.not_authorized": "Du har ikke tilgang til denne siden.",
    "superadmin.users.edit.confirm_resend_invitation": "Er du sikker på at du vil sende invitasjon på nytt?",
    "superadmin.users.destroy.destroy": "Brukeren ble slettet",
    "superadmin.users.update.success": "Brukerinformasjonen er nå oppdatert",
    "superadmin.settings.edit.account": "Kontoinstillinger",
    "superadmin.settings.edit.account_subtitle": "Personlige opplysninger og kontotilgang",
    "superadmin.settings.edit.personal_information": "Personlige opplysninger",
    "superadmin.settings.edit.password_change": "Endring av passord",
    "superadmin.settings.edit.current_password": "Nåværende passord",
    "superadmin.settings.edit.save_changes": "Lagre endringer",
    "superadmin.settings.edit.first_name": "Fornavn",
    "superadmin.settings.edit.last_name": "Etternavn",
    "superadmin.settings.edit.email": "E-post",
    "superadmin.settings.edit.new_password": "Nytt passord",
    "superadmin.settings.edit.confirm_password": "Bekreft passord",
    "superadmin.settings.edit.save_password": "Lagre ditt nye passord",
    "superadmin.settings.edit.short": "Passordet er for kort.",
    "superadmin.settings.edit.weak": "Passordet ditt er svakt. Legg til bokstaver (store/små).",
    "superadmin.settings.edit.good": "Legg til tall eller spesialtegn.",
    "superadmin.settings.edit.strong": "Passordet ditt er sterkt",
    "superadmin.documents.index.today": "I dag",
    "superadmin.documents.index.last_week": "Forrige uke",
    "superadmin.documents.index.last_month": "Forrige måned",
    "superadmin.documents.index.last_year": "I fjor",
    "superadmin.documents.index.from": "fra:",
    "superadmin.documents.index.till": "inntil:",
    "superadmin.documents.index.agreements_created": "Åpnede dokumenter",
    "superadmin.documents.index.agreements_finished": "Ferdige dokumenter",
    "superadmin.documents.index.documents": "Opprettede dokumenter",
    "superadmin.documents.index.documents_subtitle": "Oversikt over alle avtaler",
    "superadmin.documents.index.search": "Søk...",
    "superadmin.documents.index.document": "Dokument",
    "superadmin.documents.index.user": "Bruker",
    "superadmin.documents.index.creation_date": "Dato for opprettelse",
    "superadmin.documents.index.last_edit": "Sist redigert",
    "superadmin.documents.index.progress": "Fremgang",
    "superadmin.documents.index.download": "Last ned",
    "superadmin.companies.index.add_company": "Legg til selskap",
    "superadmin.companies.index.companies": "Selskaper",
    "superadmin.companies.index.companies_subtitle": "Oversikt over alle opprettede selskaper",
    "superadmin.companies.index.search": "Søk...",
    "superadmin.companies.index.company_name": "Selskapsnavn",
    "superadmin.companies.index.members": "Medlemmer",
    "superadmin.companies.index.templates": "Maler",
    "superadmin.companies.index.documents": "Dokumenter",
    "superadmin.companies.index.filters": "Filtre",
    "superadmin.companies.show.back": "Gå tilbake til selskaper",
    "superadmin.companies.show.registered": "Registrert: ",
    "superadmin.companies.show.company_name": "Selskapsnavn",
    "superadmin.companies.show.company_contact": "Firmakontakt",
    "superadmin.companies.show.members": "Medlemmer",
    "superadmin.companies.show.templates": "Maler",
    "superadmin.companies.show.documents": "Dokumenter",
    "superadmin.companies.form.back": "Tilbake til selskaper",
    "superadmin.companies.form.create_company": "Opprett selskap",
    "superadmin.companies.form.edit_company": "Rediger selskap",
    "superadmin.companies.form.name": "Selskapsnavn",
    "superadmin.companies.form.organization_number": "Organisasjonsnummer",
    "superadmin.companies.form.address": "Adresse",
    "superadmin.companies.form.address1": "Adresselinje 1",
    "superadmin.companies.form.address2": "Adresselinje 2",
    "superadmin.companies.form.postal_code": "Postnummer",
    "superadmin.companies.form.city": "By",
    "superadmin.companies.form.country": "Land",
    "superadmin.companies.form.phone_number": "Telefonnummer",
    "superadmin.companies.form.company_admin_first_name": "Fornavn",
    "superadmin.companies.form.company_admin_last_name": "Etternavn",
    "superadmin.companies.form.company_admin_email": "E-post",
    "superadmin.companies.form.save": "Lagre",
    "superadmin.companies.form.create": "Opprett og inviter",
    "superadmin.companies.form.admin_fields": "Admin detaljer",
    "superadmin.companies.create.success": "Selskapet har blitt oppdatert",
    "superadmin.companies.create.failed": "Feil ved oppretting av selskap - %{error}",
    "superadmin.companies.update.success": "Selskapet har blitt oppdatert",
    "superadmin.companies.update.failed": "Selskapet har ikke blitt oppdatert - %{error}",
    "superadmin.companies.company.company_name": "Navn på bedriften",
    "superadmin.companies.company.preview": "Forhåndsvis",
    "superadmin.companies.company.members": "Medlemmer",
    "superadmin.companies.company.templates": "Maler",
    "superadmin.companies.company.documents": "Dokumenter",
    "superadmin.companies.documents.document": "Navn",
    "superadmin.companies.documents.user": "Bruker",
    "superadmin.companies.documents.creation_date": "Dato for opprettelse",
    "superadmin.companies.documents.last_edit": "Siste endring",
    "superadmin.companies.documents.progress": "Forløp",
    "superadmin.companies.members.user_name": "Brukernavn",
    "superadmin.companies.members.company": "Selskap\n",
    "superadmin.companies.members.role": "Rolle",
    "superadmin.companies.members.last_active": "Sist aktiv",
    "superadmin.companies.templates.template_name": "Navn",
    "superadmin.companies.templates.created_by": "Opprettet av",
    "superadmin.companies.templates.status": "Status",
    "superadmin.invite_employees.form.new_invitation": "Ny invitasjon",
    "superadmin.invite_employees.form.invitation_subtitle": "Fyll ut informasjonen nedenfor for å opprette nye brukere. Vi sender en e-post til lagkameratene dine for å logge inn.",
    "superadmin.invite_employees.form.email": "E-postadresse(r)",
    "superadmin.invite_employees.form.email_subtitle": "Separér flere e-postadresser med komma, f. eks: e-post1, e-post 2, osv",
    "superadmin.invite_employees.form.role": "Rolle",
    "superadmin.invite_employees.form.company_admin": "Administrator",
    "superadmin.invite_employees.form.admin_permissions": "Har tilgang til å administrere brukere, og lage maler og dokumenter",
    "superadmin.invite_employees.form.producer": "Produsent",
    "superadmin.invite_employees.form.producer_permissions": "Har tilgang til å lage maler og dokumenter",
    "superadmin.invite_employees.form.consumer": "Konsument",
    "superadmin.invite_employees.form.consumer_permissions": "Har tilgang til å lage dokumenter",
    "superadmin.invite_employees.form.save": "Send invitasjon",
    "superadmin.invite_employees.create.success": "Invitasjonen ble sendt",
    "superadmin.questionnaire_templates.index.templates": "Maler",
    "superadmin.questionnaire_templates.index.templates_subtitle": "Oversikt over alle maler",
    "superadmin.questionnaire_templates.index.search": "Søk...",
    "superadmin.questionnaire_templates.index.template_name": "Navn",
    "superadmin.questionnaire_templates.index.created_by": "Opprettet av",
    "superadmin.questionnaire_templates.index.status": "Status",
    "superadmin.questionnaire_templates.questionnaire_template.template_name": "Navn",
    "superadmin.questionnaire_templates.questionnaire_template.preview": "Forhåndsvis",
    "superadmin.questionnaire_templates.questionnaire_template.created_by": "Opprettet av",
    "superadmin.questionnaire_templates.questionnaire_template.status": "Status",
    "superadmin.questionnaire_templates.folder_routes.templates": "Maler",
    "panel.layouts.panel_navbar.templates": "Maler",
    "panel.layouts.panel_navbar.documents": "Dokumenter",
    "panel.layouts.panel_navbar.users": "Brukere",
    "panel.layouts.panel_navbar.templates_library": "Malarkiv",
    "panel.layouts.panel_navbar.settings": "Kontoinstillinger",
    "panel.layouts.panel_navbar.log_out": "Logg ut",
    "panel.settings.edit.account": "Kontoinstillinger",
    "panel.settings.edit.account_subtitle": "Personlig informasjon og kontotilgang",
    "panel.settings.edit.edit_document_template": "Tilpass mal",
    "panel.settings.edit.personal_information": "Personlig informasjon",
    "panel.settings.edit.password_change": "Endring av passord",
    "panel.settings.edit.current_password": "Nåværende passord",
    "panel.settings.edit.save_changes": "Lagre endringer",
    "panel.settings.edit.first_name": "Fornavn",
    "panel.settings.edit.last_name": "Etternavn",
    "panel.settings.edit.email": "E-post",
    "panel.settings.edit.new_password": "Nytt passord",
    "panel.settings.edit.confirm_password": "Bekreft passord",
    "panel.settings.edit.save_password": "Lagre ditt nye passord",
    "panel.settings.edit.short": "Passordet er for kort.",
    "panel.settings.edit.weak": "Passordet ditt er svakt. Legg til bokstaver (store/små).",
    "panel.settings.edit.good": "Legg til tall eller spesialtegn.",
    "panel.settings.edit.strong": "Passordet ditt er sterkt",
    "panel.settings.update.success": "Kontoen din har nå blitt oppdatert.",
    "panel.settings.update.failed": "Kontoen din ble ikke oppdatert",
    "panel.dashboard.show.welcome": "Velkommen,",
    "panel.dashboard.show.dashboard_header": "Dashboard? Hvilket dashbord?",
    "panel.dashboard.show.dashboard_subheader": "Klikk på support-knappen på høyre side for å fortelle<br> oss hva slags informasjon du vil se på dashbordet.",
    "panel.dashboard.show.templates_url": "maler",
    "panel.dashboard.show.documents_url": "dokumenter",
    "panel.dashboard.show.got_to": "Eller gå til dine %{templates_href} eller %{documents_href}.",
    "panel.questionnaire_templates.template_versions.date": "Dato",
    "panel.questionnaire_templates.template_versions.version": "Versjon",
    "panel.questionnaire_templates.template_versions.publish": "Publiser",
    "panel.questionnaire_templates.template_versions.change": "Endre",
    "panel.questionnaire_templates.template_versions.created_by": "Opprettet av",
    "panel.questionnaire_templates.index.templates_title": "Maler",
    "panel.questionnaire_templates.index.templates_subtitle": "Oversikt over alle maler",
    "panel.questionnaire_templates.index.create_template": "Ny mal",
    "panel.questionnaire_templates.index.search": "Søk...",
    "panel.questionnaire_templates.index.templates": "Maler",
    "panel.questionnaire_templates.index.template_name": "Navn",
    "panel.questionnaire_templates.index.created_by": "Opprettet av",
    "panel.questionnaire_templates.index.created_at": "Dato for opprettelse",
    "panel.questionnaire_templates.index.edited_at": "Siste endring",
    "panel.questionnaire_templates.index.status": "Status",
    "panel.questionnaire_templates.index.new_folder": "Mappe",
    "panel.questionnaire_templates.index.new_template": "Mal",
    "panel.questionnaire_templates.index.rename": "Gi nytt navn",
    "panel.questionnaire_templates.index.move_to": "Flytt til... ",
    "panel.questionnaire_templates.index.delete": "Slett",
    "panel.questionnaire_templates.index.trash": "Papirkurv",
    "panel.questionnaire_templates.questionnaire_template.template_name": "Navn",
    "panel.questionnaire_templates.questionnaire_template.preview": "Forhåndsvis",
    "panel.questionnaire_templates.questionnaire_template.edit": "Rediger",
    "panel.questionnaire_templates.questionnaire_template.created_by": "Opprettet av",
    "panel.questionnaire_templates.questionnaire_template.created_at": "Dato for opprettelse",
    "panel.questionnaire_templates.questionnaire_template.edited_at": "Redigert",
    "panel.questionnaire_templates.questionnaire_template.status": "Status",
    "panel.questionnaire_templates.questionnaire_template.unpublished_changes": " Denne malen har et upublisert utkast",
    "panel.questionnaire_templates.questionnaire_template.user_deleted": "Bruker finnes ikke",
    "panel.questionnaire_templates.questionnaire_template.trash": "Flytt til papirkurv",
    "panel.questionnaire_templates.questionnaire_template.empty_bucket.heading": "Denne mappen er tom",
    "panel.questionnaire_templates.questionnaire_template.empty_bucket.paragraph": "Klikk på knappen nedenfor for å opprette ny mal.",
    "panel.questionnaire_templates.questionnaire_template.empty_bucket.buttonLabel": "Ny mal",
    "panel.questionnaire_templates.questionnaire_template.draft": "Utkast",
    "panel.questionnaire_templates.questionnaire_template.shared": "Åpen for alle som har en lenke",
    "panel.questionnaire_templates.show.edit": "Rediger",
    "panel.questionnaire_templates.show.view": "Vis",
    "panel.questionnaire_templates.show.create_draft": "Nytt utkast",
    "panel.questionnaire_templates.show.documents": "Dokumenter",
    "panel.questionnaire_templates.show.history": "Historikk",
    "panel.questionnaire_templates.show.linked_laws": "Koblede lover",
    "panel.questionnaire_templates.destroy.success": "Malen er slettet",
    "panel.questionnaire_templates.destroy.not_authorized": "Du kan ikke slette denne malen, fordi det ser ut som den inneholder upubliserte versjoner. ",
    "panel.questionnaire_templates.folder_routes.templates": "Maler",
    "panel.questionnaire_templates.template_documents.document": "Dokument",
    "panel.questionnaire_templates.template_documents.created_at": "Opprettet",
    "panel.questionnaire_templates.template_documents.edited_at": "Sist redigert",
    "panel.questionnaire_templates.template_documents.status": "Status",
    "panel.questionnaire_templates.template_documents.created_by": "Opprettet av",
    "panel.questionnaire_templates.template_documents.white_label": "White label",
    "panel.questionnaire_templates.template_documents.generic_access_link": "Generisk lenke bruker ",
    "panel.questionnaire_templates.new.new_template": "Ny mal",
    "panel.templates_library.index.templates_title": "Oversikt over alle maler",
    "panel.templates_library.index.templates_subtitle": "Liste over alle maler",
    "panel.templates_library.index.search": "Søk...",
    "panel.templates_library.index.create_template": "Ny mal",
    "panel.templates_library.index.template": "Name",
    "panel.templates_library.index.created_at": "Dato for opprettelse",
    "panel.templates_library.questionnaire_template.template": "Navn",
    "panel.templates_library.questionnaire_template.created_at": "Dato for opprettelse",
    "panel.templates_library.questionnaire_template.preview": "Forhåndsvis",
    "panel.templates_library.questionnaire_template.create_agreement": "Opprett nytt dokument",
    "panel.templates_library.folders.show.templates_title": "Malbibliotek",
    "panel.templates_library.folders.show.templates_subtitle": "Oversikt over alle publiserte maler",
    "panel.templates_library.folders.show.filters": "Filtre",
    "panel.templates_library.folders.show.folder": "Ny mappe",
    "panel.templates_library.folders.show.documents": "Dokument",
    "panel.templates_library.folders.show.template": "Navn",
    "panel.templates_library.folders.show.created_at": "Opprettet",
    "panel.templates_library.folders.show.search": "Søk...",
    "panel.templates_library.folders.show.create_template": "Nytt dokument",
    "panel.templates_library.folders.folder.templates_count": "%{val} maler",
    "panel.templates_library.folders.folder.templates_count_non_ruby": "{val} maler",
    "panel.templates_library.folders.subfolders.templates_count": "%{val} maler",
    "panel.templates_library.folders.subfolders.templates_count_non_ruby": "{val} maler",
    "panel.templates_library.folders.breadcrumbs.templates": "Maler",
    "panel.questionnaire_template_versions.edit.edit_error": "Du kan ikke redigere en mal som allerede er publisert",
    "panel.questionnaire_template_versions.questionnaire_template_version.edit": "Rediger",
    "panel.questionnaire_template_versions.questionnaire_template_version.view": "Vis",
    "panel.questionnaire_template_versions.questionnaire_template_version.create_draft": "Nytt utkast",
    "panel.questionnaire_template_versions.questionnaire_template_version.created_at": "Opprettet",
    "panel.users.index.users": "Brukere",
    "panel.users.index.users_subtitle": "Liste over alle brukere i bedriften",
    "panel.users.index.search": "Søk...",
    "panel.users.index.invite_members": "Inviter medlemmer",
    "panel.users.index.user": "Bruker",
    "panel.users.index.role": "Rolle",
    "panel.users.index.last_active": "Sist aktiv",
    "panel.users.user.edit_user": "Rediger bruker",
    "panel.users.user.first_name": "Fornavn",
    "panel.users.user.last_name": "Etternavn",
    "panel.users.user.email": "E-post",
    "panel.users.user.role": "Rolle",
    "panel.users.user.company_admin": "Bedriftsadministrator",
    "panel.users.user.producer": "Produsent",
    "panel.users.user.consumer": "Bruker",
    "panel.users.user.save": "Lagre",
    "panel.users.user.delete_user": "Slett bruker",
    "panel.users.user.reinvite_user": "Inviter bruker på nytt",
    "panel.users.edit.back": "Gå tilbake til brukerlisten",
    "panel.users.edit.first_name": "Fornavn",
    "panel.users.edit.last_name": "Etternavn",
    "panel.users.edit.email": "E-post",
    "panel.users.edit.role": "Rolle",
    "panel.users.edit.company_admin": "Bedriftsadministrator",
    "panel.users.edit.producer": "Produsent",
    "panel.users.edit.consumer": "Bruker",
    "panel.users.edit.save": "Lagre endringer",
    "panel.users.edit.delete_user": "Slett bruker",
    "panel.users.edit.active_user": "Aktiv bruker:",
    "panel.users.edit.yes": "Ja",
    "panel.users.edit.no": "Nei",
    "panel.users.edit.reinvite_user": "Inviter bruker på nytt",
    "panel.users.edit.confirm_resend_invitation": "Er du sikker på at du vil sende invitasjon på nytt?",
    "panel.users.edit.admin_permissions": "Har tilgang til å administrere brukere, lage maler og dokumenter",
    "panel.users.edit.producer_permissions": "Har tilgang til å lage maler og dokumenter",
    "panel.users.edit.consumer_permissions": "Har tilgang til å lage dokumenter",
    "panel.users.form.admin_permissions": "Har tilgang til å administrere brukere, og lage maler og dokumenter",
    "panel.users.form.producer_permissions": "Har tilgang til å lage maler og dokumenter",
    "panel.users.form.consumer_permissions": "Har tilgang til å lage dokumenter",
    "panel.users.destroy.destroy": "Brukeren ble deaktivert",
    "panel.users.invite_employees.form.new_invitation": "Ny invitasjon",
    "panel.users.invite_employees.form.invitation_subtitle": "Fyll ut informasjonen nedenfor for å opprette nye brukere. Vi sender en e-post til lagkameratene dine for å logge inn.",
    "panel.users.invite_employees.form.email": "E-postadresse(r)",
    "panel.users.invite_employees.form.email_subtitle": "Separér flere e-postadresser med komma, f. eks: e-post1, e-post 2, osv",
    "panel.users.invite_employees.form.role": "Rolle",
    "panel.users.invite_employees.form.company_admin": "Bedriftsadministrator",
    "panel.users.invite_employees.form.admin_permissions": "Har tilgang til å administrere brukere, lage maler og dokumenter",
    "panel.users.invite_employees.form.producer": "Produsent",
    "panel.users.invite_employees.form.producer_permissions": "Har tilgang til å lage maler og dokumenter",
    "panel.users.invite_employees.form.consumer": "Bruker",
    "panel.users.invite_employees.form.consumer_permissions": "Har tilgang til å lage dokumenter",
    "panel.users.invite_employees.form.save": "Send invitasjon",
    "panel.users.invite_employees.create.success": "Invitasjoner sendt til nye medlemmer.",
    "panel.users.invite_employees.create.failed": "Det oppsto en feil ved å invitere medlemmer til firmaet ditt. Vennligst sjekk feltene og prøv igjen.",
    "panel.users.invite_employees.reinvite.success": "Du har på nytt invitert %{email} til din bedrift.",
    "panel.users.invite_employees.reinvite.failed": "%{email} ble ikke invitert til din bedrift. Prøv på nytt. ",
    "panel.folders.destroy.destroyed": "Slettet",
    "panel.folders.destroy.cant_delete": "Mappen kan ikke slettes fordi den inneholder maler.",
    "panel.folders.destroy.not_authorized": "Du kan ikke slette denne mappen, fordi det ser ut som den har innhold. ",
    "panel.folders.create.success": "Mappe opprettet",
    "panel.folders.create.failed": "Kunne ikke opprette mappen",
    "panel.folders.update.success": "Mappen er nå oppdatert",
    "panel.folders.update.failed": "Kunne ikke oppdatere mappen",
    "panel.folders.folder.delete": "Slett",
    "panel.folders.folder.rename": "Gi nytt navn",
    "panel.folders.folder.new_folder": "Mappe",
    "panel.folders.folder.templates_count": "%{val} maler",
    "panel.folders.folder.templates_count_non_ruby": "{val} maler",
    "panel.folders.show.templates_title": "Maler",
    "panel.folders.show.templates_subtitle": "Oversikt over alle maler",
    "panel.folders.show.search": "Søk...",
    "panel.folders.show.create_template": "Opprett ny",
    "panel.folders.show.new_subfolder": "Mappe",
    "panel.folders.show.rename": "Gi nytt navn",
    "panel.folders.show.move_to": "Flytt til... ",
    "panel.folders.show.new_template": "Mal",
    "panel.folders.show.delete": "Slett mappe",
    "panel.folders.show.template_name": "Navn",
    "panel.folders.show.created_at": "Opprettet",
    "panel.folders.show.edited_at": "Redigert",
    "panel.folders.show.status": "Status",
    "panel.folders.show.created_by": "Laget av",
    "panel.folders.show.trash": "Papirkurv",
    "panel.folders.breadcrumbs.templates": "Maler",
    "panel.folders.hover_breadcrumbs.templates": "Maler",
    "panel.folders.folder_modal.rename": "Gi nytt navn til mappen",
    "panel.folders.folder_modal.create": "Ny mappe",
    "panel.folders.folder_modal.folder_name": "Navn",
    "panel.trash.show.trash": "Papirkurv",
    "panel.trash.show.empty_trash": "Tøm papirkurv",
    "panel.trash.show.search": "Søk...",
    "panel.trash.show.trash_empty": "Papirkurven er tom.",
    "panel.trash.show.template_name": "Navn",
    "panel.trash.show.deleted_at": "Slettet",
    "panel.trash.show.edited_at": "Sist redigert",
    "panel.trash.show.created_by": "Opprettet av",
    "panel.trash.deleted_item.restore": "Gjenopprett",
    "panel.trash.deleted_item.delete": "Slett",
    "panel.trash.deleted_item.preview": "Forhåndsvis",
    "panel.trash.deleted_item.templates": "Maler",
    "panel.trash.deleted_item.documents": "Dokumenter",
    "panel.trash.delete_item_modal.delete_forever": "Slette?",
    "panel.trash.delete_item_modal.are_you_sure": "Er du sikker på at du vil slette denne filen? {newLine} Du kan ikke angre denne handlingen.",
    "panel.trash.delete_item_modal.cancel": "Avbryt",
    "panel.trash.delete_item_modal.delete": "Slett",
    "panel.trash.empty_trash_modal.delete_forever": "Slette?",
    "panel.trash.empty_trash_modal.are_you_sure": "Er du sikker på at du vil slette alle filer? {newLine}Du kan ikke angre denne handlingen.",
    "panel.trash.empty_trash_modal.cancel": "Avbryt",
    "panel.trash.empty_trash_modal.delete": "Slett",
    "panel.trash.empty_bucket.heading": "Papirkurven er tom.",
    "panel.trash.empty_bucket.paragraph": "Ingen elementer ble flyttet til papirkurven",
    "panel.user_questionnaires.statuses.started": "Påbegynt",
    "panel.user_questionnaires.statuses.sent_for_signing": "Sendt for signering",
    "panel.user_questionnaires.statuses.signed": "Signert",
    "panel.user_questionnaires.statuses.completed": "Fullført",
    "panel.user_questionnaires.statuses.expired": "Utløpt",
    "panel.user_questionnaires.statuses.rejected": "Avvist",
    "panel.user_questionnaires.statuses.error": "Feil",
    "panel.user_questionnaires.index.documents": "Dokumenter",
    "panel.user_questionnaires.index.documents_subtitle": "Oversikt over alle dokumenter",
    "panel.user_questionnaires.index.search": "Søk...",
    "panel.user_questionnaires.index.create_document": "Opprett nytt dokument",
    "panel.user_questionnaires.index.customers_docs": "Kundedokumenter",
    "panel.user_questionnaires.index.your_docs": "Dine dokumenter",
    "panel.user_questionnaires.index.document": "Dokument",
    "panel.user_questionnaires.index.created_at": "Dato for opprettelse",
    "panel.user_questionnaires.index.edited_at": "Redigert",
    "panel.user_questionnaires.index.based_on": "Basert på",
    "panel.user_questionnaires.index.status": "Status",
    "panel.user_questionnaires.index.created_by": "Laget av",
    "panel.user_questionnaires.index.new_folder": "Mappe",
    "panel.user_questionnaires.index.new_document": "Dokument",
    "panel.user_questionnaires.index.not_authorized": "Du har ikke tilgang til denne siden.",
    "panel.user_questionnaires.user_questionnaire.document": "Dokument",
    "panel.user_questionnaires.user_questionnaire.created_at": "Dato for opprettelse",
    "panel.user_questionnaires.user_questionnaire.edited_at": "Redigert",
    "panel.user_questionnaires.user_questionnaire.based_on": "Kontraktstype",
    "panel.user_questionnaires.user_questionnaire.status": "Status",
    "panel.user_questionnaires.user_questionnaire.created_by": "Laget av",
    "panel.user_questionnaires.user_questionnaire.version": "/ Versjon:",
    "panel.user_questionnaires.user_questionnaire.trash": "Flytt til papirkurv",
    "panel.user_questionnaires.new.cant_create": "Kan ikke opprette dokument fra en upublisert mal",
    "panel.user_questionnaires.new.new_document": "Nytt dokument",
    "panel.user_questionnaires.new.not_authorized": "Du har ikke adgang til å utføre denne handlingen",
    "panel.user_questionnaires.document_folders.show.create_new": "Opprett ny",
    "panel.user_questionnaires.document_folders.show.new_folder": "Mappe",
    "panel.user_questionnaires.document_folders.show.new_document": "Dokument",
    "panel.user_questionnaires.document_folders.show.search": "Søk...",
    "panel.user_questionnaires.document_folders.show.rename": "Gi nytt navn",
    "panel.user_questionnaires.document_folders.show.delete": "Slett",
    "panel.user_questionnaires.document_folders.document_folder.documents_count": "%{val} dokumenter",
    "panel.user_questionnaires.document_folders.document_folder.delete": "Slett",
    "panel.user_questionnaires.document_folders.document_folder.rename": "Gi nytt navn",
    "panel.user_questionnaires.document_folders.create_document_folder_modal.folder_name": "Mappenavn",
    "panel.user_questionnaires.document_folders.create_document_folder_modal.cancel": "Avbryt",
    "panel.user_questionnaires.document_folders.create_document_folder_modal.create_folder": "Ny mappe",
    "panel.user_questionnaires.document_folders.document_subfolder.documents_count": "%{val} dokumenter",
    "panel.user_questionnaires.document_folders.document_subfolder.delete": "Slett",
    "panel.user_questionnaires.document_folders.document_subfolder.rename": "Gi nytt navn",
    "panel.user_questionnaires.document_folders.breadcrumbs.documents": "Dokumenter",
    "panel.user_questionnaires.document_folders.hover_breadcrumbs.documents": "Dokumenter",
    "panel.user_questionnaires.document_folders.create.success": "Mappe opprettet",
    "panel.user_questionnaires.document_folders.update.success": "Mappen er nå oppdatert",
    "panel.user_questionnaires.document_folders.update.failed": "Kunne ikke oppdatere mappen",
    "panel.user_questionnaires.document_folders.destroy.destroyed": "Mappe slettet",
    "panel.user_questionnaires.signing.success.heading": "Dokumentet er nå signert av deg",
    "panel.user_questionnaires.signing.success.description_1": "Du er ferdig med din del, og trenger ikke foreta deg noe mer. ",
    "panel.user_questionnaires.signing.success.description_2": "Når alle parter har signert avtalen vil du motta en epost med link til det signerte dokumentet, som du kan laste ned og lagre på din datamaskin. ",
    "panel.user_questionnaires.signing.success.feedback_title": "Vi setter pris på tilbakemeldinger!",
    "panel.user_questionnaires.signing.success.feedback_description": "Det tar kun ett minutt, og vil hjelpe oss med å forbedre din brukeropplevelse.",
    "panel.user_questionnaires.signing.success.feedback_button": "Gi tilbakemelding",
    "panel.user_questionnaires.notifications.rename_failure": "Kan ikke endre navn på dokumentet",
    "invitations.accept.edit.unauthorized": "Denne invitationslinket er ikke gyldig. Kanskje har du allerede brukt den?",
    "invitations.accept.edit.set_up_password": "Lag et passord",
    "invitations.accept.edit.set_up_subtitle": "Lag ditt passordet og logg inn på din Lawbotics-konto",
    "invitations.accept.edit.register": "Registrer din konto",
    "invitations.accept.edit.first_name": "Fornavn",
    "invitations.accept.edit.last_name": "Etternavn",
    "invitations.accept.edit.email": "E-post",
    "invitations.accept.edit.password": "Passord",
    "invitations.accept.edit.at_least": "Passordet må være minst 10 tegn med kombinasjon av tall, spesialtegn, små og store bokstaver.",
    "invitations.accept.edit.password_confirmation": "Gjenta passord",
    "invitations.accept.edit.create_account": "Angi passord og logg inn",
    "invitations.accept.edit.accept_by_clicking": "Ved å opprette en konto og bruke Lawbotics, godtar du våre",
    "invitations.accept.edit.terms_conditions": "Brukervilkår.",
    "invitations.accept.edit.and": "og",
    "invitations.accept.edit.privacy_policy": "Personvernerklæring",
    "invitations.accept.edit.newsletter": "Jeg vil gjerne motta oppdateringer om produkter, teknologi og mer fra Lawbotics. Jeg vil ikke bli spammet og kan avslutte abonnementet når som helst.",
    "invitations.accept.edit.short": "Passordet er for kort.",
    "invitations.accept.edit.weak": "Passordet ditt er svakt. Legg til bokstaver (store/små).",
    "invitations.accept.edit.good": "Legg til tall eller spesialtegn.",
    "invitations.accept.edit.strong": "Passordet ditt er sterkt",
    "invitations.accept.update.success": "Kontoen din er nå oppdatert",
    "invitations.accept.update.failed": "Det oppstod en feil under oppdateringen av kontoen din. Vennligst sjekk feltene og prøv igjen.",
    "invitations.accept.tos.terms": "Brukervilkår",
    "invitations.accept.tos.close": "Lukk",
    "invitations.accept.tos.01_00_title": null,
    "invitations.accept.tos.01_01_paragraph": null,
    "invitations.accept.tos.01_02_paragraph": null,
    "invitations.accept.tos.01_03_paragraph": null,
    "invitations.accept.tos.02_00_title": null,
    "invitations.accept.tos.02_01_paragraph": null,
    "invitations.accept.tos.03_00_title": null,
    "invitations.accept.tos.03_01_paragraph": null,
    "invitations.accept.tos.03_02_paragraph": null,
    "invitations.accept.tos.03_03_paragraph": null,
    "invitations.accept.tos.04_00_title": null,
    "invitations.accept.tos.04_01_paragraph": null,
    "invitations.accept.tos.04_02_paragraph": null,
    "invitations.accept.tos.04_03_paragraph": null,
    "invitations.accept.tos.04_04_paragraph": null,
    "invitations.accept.tos.04_05_paragraph": null,
    "invitations.accept.tos.04_06_paragraph": null,
    "invitations.accept.tos.05_00_title": null,
    "invitations.accept.tos.05_01_paragraph": null,
    "invitations.accept.tos.05_02_paragraph": null,
    "invitations.accept.tos.06_00_title": null,
    "invitations.accept.tos.06_01_paragraph": null,
    "invitations.accept.tos.06_02_paragraph": null,
    "invitations.accept.tos.06_03_paragraph": null,
    "invitations.accept.tos.07_00_title": null,
    "invitations.accept.tos.07_01_paragraph": null,
    "invitations.accept.tos.07_02_paragraph": null,
    "invitations.accept.tos.08_00_itle": null,
    "invitations.accept.tos.08_01_paragraph": null,
    "invitations.accept.tos.08_02_paragraph": null,
    "invitations.accept.tos.08_03_paragraph": null,
    "invitations.accept.tos.08_04_paragraph": null,
    "invitations.accept.tos.08_05_paragraph": null,
    "invitations.accept.tos.09_00_title": null,
    "invitations.accept.tos.09_01_paragraph": null,
    "invitations.accept.tos.09_02_paragraph": null,
    "invitations.accept.tos.10_00_title": null,
    "invitations.accept.tos.10_01_paragraph": null,
    "invitations.accept.tos.11_00_title": null,
    "invitations.accept.tos.11_01_paragraph": null,
    "invitations.accept.tos.11_02_paragraph": null,
    "invitations.accept.tos.12_00_title": null,
    "invitations.accept.tos.12_01_paragraph": null,
    "invitations.accept.tos.12_02_paragraph": null,
    "invitations.accept.tos.12_03_paragraph": null,
    "invitations.accept.tos.13_00_title": null,
    "invitations.accept.tos.13_01_paragraph": null,
    "invitations.accept.tos.13_01_paragraph_react": null,
    "invitations.accept.privacy_policy.terms": "Personvernerklæring",
    "invitations.accept.privacy_policy.close": "Lukk",
    "invitations.accept.privacy_policy.01_0_title": null,
    "invitations.accept.privacy_policy.01_paragraph": null,
    "invitations.accept.privacy_policy.02_0_title": null,
    "invitations.accept.privacy_policy.02_paragraph": null,
    "invitations.accept.privacy_policy.03_0_title": null,
    "invitations.accept.privacy_policy.03_paragraph": null,
    "invitations.accept.privacy_policy.04_0_title": null,
    "invitations.accept.privacy_policy.04_paragraph": null,
    "invitations.accept.privacy_policy.05_0_title": null,
    "invitations.accept.privacy_policy.05_paragraph": null,
    "invitations.accept.privacy_policy.06_0_title": null,
    "invitations.accept.privacy_policy.06_paragraph": null,
    "invitations.accept.privacy_policy.07_0_title": null,
    "invitations.accept.privacy_policy.07_paragraph": null,
    "invitations.accept.privacy_policy.08_0_title": null,
    "invitations.accept.privacy_policy.08_paragraph": null,
    "invitations.accept.privacy_policy.09_0_title": null,
    "invitations.accept.privacy_policy.09_paragraph": null,
    "invitations.accept.privacy_policy.10_0_title": null,
    "invitations.accept.privacy_policy.10_paragraph": null,
    "invitations.accept.privacy_policy.11_0_title": null,
    "invitations.accept.privacy_policy.11_paragraph": null,
    "invitations.accept.privacy_policy.11_paragraph_react": null,
    "template_settings.title": "Innstillinger for maler",
    "template_settings.date_format": "Datoformat",
    "template_settings.locale": "Region",
    "template_settings.locales.en": "Engelsk (Storbritannia)",
    "template_settings.locales.nb": "Norge",
    "template_settings.cancel": "Avbryt",
    "template_settings.save": "Lagre",
    "template_settings.signing_enabled": "eSignering",
    "template_settings.parallel": "Parallell",
    "template_settings.sequential": "Sekvensiell",
    "template_settings.parallel_description": "Personer som skal signere mottar og signerer dokumentet på samme tid. Når siste signerer fullført signeringsprosessen, mottar alle parter en e-post med en link til det signerte dokument. ",
    "template_settings.sequential_description": "Personer som skal signere mottar og signerer dokumentet i den rekkefølgen e-postadressene blir oppgitt. Når den siste personen har fullført signeringsprosessen, mottar alle parter en e-post med en link til det signerte dokument.",
    "template_settings.signing_methods": "eSigneringmetode",
    "template_settings.bankID": "BankID",
    "template_settings.email": "E-post",
    "template_settings.signing_order": "eSignering ",
    "template_settings.missing_signing_methods": "Vennligst oppgi minst én eSigneringsmetode",
    "template_settings.sender_has_to_sign": "Sender må signere",
    "template_settings.edit_document_enabled": "Dokumentredigering",
    "template_settings.signing_methods_tooltip": "Bare valgte metoder for signering vil tilbys når et dokument sendes til signering. Om du velger å tillate flere muligheter vil det i hver enkel sak være muligt for personen som sender dokumentet til signering å velge hvilken metode det foretrekkes.",
    "template_settings.edit_document_tooltip": "Tillat at dokumenter som er opprettet fra denne malen kan bli redigert av personer med produsentrettigheter.",
    "template_settings.signing_upsell_description": "eSignering med e-post og BankID er ikke aktivert for denne malen. For å aktivere eSignering, spør administratoren eller",
    "template_settings.signing_upsell_email_link_description": null,
    "template_settings.document_highlighting": "Svar fremhevet i PDF",
    "template_settings.document_highlighting_tooltip": "Tekstbesvarelser gitt av brukeren vil bli fremhevet i den endelige PDF-filen",
    "template_settings.update_settings_info_box": "Du har endret en innstilling som krever at en ny versjon må publiseres. Vær oppmerksom på at bare dokumenter opprettet fra dette tidspunktet vil dra nytte av endringen.",
    "template_settings.publish": "Publiser",
    "template_settings.patch_and_publish": "Publiser",
    "template_settings.edit_document_enabled_for_consumer": "Gi eier tilgang til å redigere",
    "folder_list.rename_folder.error": null,
    "folder_list.delete_folder.error": null,
    "user_invitations.title": "Ny invitasjon",
    "user_invitations.subtitle": "Fyll ut informasjonen nedenfor for å opprette nye brukere. Vi sender en e-post til lagkameratene dine for å logge inn.",
    "user_invitations.fields.first_name": "Fornavn",
    "user_invitations.fields.last_name": "Etternavn",
    "user_invitations.fields.password": "Passord",
    "user_invitations.fields.repeat_password": "Gjenta passord",
    "user_invitations.send": "Sett passord og logg inn",
    "user_invitations.terms_statement": "Ved å fortsette å opprette din konto og bruke Lawbotics, godtar du {terms} og {privacy}",
    "user_invitations.terms_label": "Brukervilkår",
    "user_invitations.privacy_label": "Personvernerklæring",
    "user_invitations.cant_be_blank": "Kan ikke være tomt",
    "user_invitations.passwords_must_match": "Passordene må være like",
    "user_invitations.password_not_strong_enough": "Dette passordet er ikke sterkt nok",
    "user_invitations.success.title": "Suksess",
    "user_invitations.success.description": "Velkommen til Lawbotics",
    "user_invitations.failure.title": "Feil",
    "user_invitations.failure.description": "Ops, her har det skjedd en feil. Prøv igjen eller vennligst kontakt systemadministrator.",
    "questionnaire.close": "Lukk",
    "questionnaire.completed": "Du har svart på alle spørsmålene",
    "questionnaire.previous_question": "Forrige spørsmål",
    "questionnaire.edit": "Rediger",
    "questionnaire.restart_signing": "Start signeringsprosessen på nytt",
    "questionnaire.document_error.error": "Det har oppstått en feil i ditt dokument.",
    "questionnaire.document_error.rejected": "{email} avviste å signere dokumentet med følgende kommentar: {comment}.",
    "questionnaire.document_error.bounced": "Email kontoen {email} eksisterer dessverre ikke, vennligst bekreft mottakerens e-postadresse og prøv igjen.",
    "questionnaire.document_error.expired": "Dokumentet ditt er utløpt.",
    "questionnaire.abort_signing.button_text": "Avbryt signering",
    "questionnaire.abort_signing.modal.header": "Avbryt eSignering prosess",
    "questionnaire.abort_signing.modal.yes": "Avbryt",
    "questionnaire.abort_signing.modal.no": "Tilbake",
    "questionnaire.abort_signing.modal.message": "Du er i ferd med å avbryte signeringsprosessen. Vær oppmerksom på at mottakere som har signert allerede må signere på nytt.",
    "questionnaire.abort_signing.modal.confirm": "Ønsker du å avbryte signeringsprosessen?",
    "questionnaire.esign.send": "Send for eSignering",
    "questionnaire.esign.send_signature": "Send for eSignering",
    "questionnaire.esign.notifications.success": "Ditt dokument er sendt for signering.",
    "questionnaire.esign.notifications.error": "Dokumentet ditt er avvist. Rediger dokumentet og send det på nytt.",
    "questionnaire.esign.notifications.signing_still_in_progress": "Henter dokumentstatus tar lengre tid enn forventet. Vennligst prøv igjen om et minutt.",
    "questionnaire.esign.notifications.cant_cancel_signed_envelope": "Dokumentet er allerede signert. Du kan ikke avbryte det.",
    "questionnaire.esign.errors.no_emails": "Vennligst skriv inn minst én mottaker",
    "questionnaire.esign.errors.no_document_title": "Dokumenttittel kan ikke være tomt",
    "questionnaire.esign.errors.no_message": "Meldingen kan ikke være tom",
    "questionnaire.esign.errors.invalid_email": "Her skal du skrive inn en gyldig e-post adresse",
    "questionnaire.esign.errors.no_signees": "En kontrakt trenger minst to kontraktsparter.",
    "questionnaire.esign.form.to": "Legg til mottaker",
    "questionnaire.esign.form.document_title": "Dokument tittel",
    "questionnaire.esign.form.from": "Fra",
    "questionnaire.esign.form.message": "Melding",
    "questionnaire.esign.form.send": "Send til",
    "questionnaire.esign.form.cancel": "Avbryt",
    "questionnaire.esign.form.greetings": "Hei,\nDu har blitt invitert av {name} for å signere følgende dokument.\n",
    "questionnaire.esign.form.placeholder.message": "Skriv en melding til mottakerne",
    "questionnaire.esign.form.placeholder.document_title": "F.eks Samarbeidavtale - Acme AS",
    "questionnaire.esign.form.placeholder.from": "Skriv inn din email",
    "questionnaire.esign.form.placeholder.emails": "Skriv inn en epost per person",
    "questionnaire.esign.form.placeholder.next_email": "Legg til flere personer",
    "questionnaire.esign.form.placeholder.next_emails": "Legg til flere personer",
    "questionnaire.esign.form.placeholder.custom_message": "Legg til en standard beskjed som \"Hei, her er dokumentet som er klar for signering. Vennligst ta kontakt på {email} hvis du har noen spørmål.\"",
    "questionnaire.esign.form.placeholder.document_name": "F.eks Leieavtale - Ola Nordmann",
    "questionnaire.esign.form.placeholder.sent_from": "F.eks Olanordmann@Email.com",
    "questionnaire.esign.form.placeholder.signee": "F.eks michael@angelo.it",
    "questionnaire.esign.form.placeholder.default_custom_message": "Ta kontakt med meg på {email} hvis du har spørsmål.",
    "questionnaire.esign.form.signing_method": "eSigneringmetode",
    "questionnaire.esign.form.signing_details": "eSignering detaljer",
    "questionnaire.esign.form.add_signees": "Legg til en mottaker",
    "questionnaire.esign.form.signing_method_tooltip": "Metoden som brukes til å signere dokumentet. Alle metoder er like gyldig som vanlig signatur.e",
    "questionnaire.esign.form.invitation": "Invitasjon",
    "questionnaire.esign.form.signees": "Legg til mottaker",
    "questionnaire.esign.form.confirm": "Bekreft",
    "questionnaire.esign.form.invitation_to_sign": "Invitasjon til å signere",
    "questionnaire.esign.form.sent_from": "Sendt fra",
    "questionnaire.esign.form.sent_from_tooltip": "Denne e-postmeldingen vil bli brukt som avsender for mottakeren. Det blir også kontakt-e-posten.",
    "questionnaire.esign.form.document_name": "Dokument navn",
    "questionnaire.esign.form.document_name_description": "Et tydelig navn hjelper deg og mottakeren å finne dokumentet",
    "questionnaire.esign.form.custom_message": "Tilpasset melding",
    "questionnaire.esign.form.continue": "Fortsett",
    "questionnaire.esign.form.close": "Lukk",
    "questionnaire.esign.form.signees_title": "Legg til e-postadresser for partene i avtalen",
    "questionnaire.esign.form.signees_add": "Legg til i listen",
    "questionnaire.esign.form.signing_parallel": "Parallell signeringsflyt",
    "questionnaire.esign.form.signing_parallel_description": "Alle signerte vil motta en invitasjon til å signere samtidig. Som avsender vil du motta en bekreftelse når alle parter har signert.",
    "questionnaire.esign.form.signing_sequential": "Sekvensiell signeringsflyt",
    "questionnaire.esign.form.signing_sequential_description": "Dette dokumentet vil bli signert i den eksakte rekkefølgen vist ovenfor. Partene vil bare motta en invitasjon til å signere når det har blitt deres tur.",
    "questionnaire.esign.form.confirmation_title": "Fikk du med alle?",
    "questionnaire.esign.form.confirmation_description": "Kun de epostadresser som er oppgitt nedenfor vil motta invitasjon til å signere med {sendingMethod}. Kontroller at disse er korrekte før du trykker send.",
    "questionnaire.esign.form.submit_label": "Trykk for å sende",
    "questionnaire.esign.form.signee": "Signerer",
    "questionnaire.esign.form.back": "Tilbake",
    "questionnaire.esign.form.summary_title": "Da er den på vei!",
    "questionnaire.esign.form.summary_description": "signeringsprosessen har begynt! Nedenfor kan du se hva som vil skje videre. Vi har sendt en kopi av dette til e-posten din.",
    "questionnaire.esign.form.email_addresses": "E-postadresse(r)",
    "questionnaire.esign.form.signees_add_short": "Legg til",
    "questionnaire.esign.sequential_signing": "Sekvensiell signeringsflyt",
    "questionnaire.esign.sequential_signing_description": "Personer som skal signere mottar og signerer dokumentet i den rekkefølgen e-postadressene blir oppgitt. Når den siste personen har fullført signeringsprosessen, mottar alle parter en e-post med en link til det signerte dokument. ",
    "questionnaire.esign.parallel_signing": "Parallell signeringsflyt",
    "questionnaire.esign.parallel_signing_description": "Personer som skal signere mottar og signerer dokumentet på samme tid. Når siste signerer fullført signeringsprosessen, mottar alle parter en e-post med en link til det signerte dokument. ",
    "questionnaire.esign.timeline.send_invitation": "Vi har sendt en invitasjon til",
    "questionnaire.esign.timeline.send_invitation_next": "Når signert sender vi en e-post til",
    "questionnaire.esign.timeline.send_invitation_last": "Til slutt vil vi sende en e-post til",
    "questionnaire.esign.timeline.signature": "ber om deres signatur.",
    "questionnaire.esign.timeline.signature_first": "ber om første signatur.",
    "questionnaire.esign.timeline.signature_last": "ber om den siste signaturen.",
    "questionnaire.esign.timeline.summary_parallel": "Når alle parter har signert avtalen vil du og restrende parter motta en epost med link til det signerte dokumentet, som du kan laste ned og lagre på din datamaskin.",
    "questionnaire.esign.timeline.summary_sequential": "Til slutt vil alle parter i avtalen motta en epost med link til det signerte dokumentet. ",
    "questionnaire.esign.send_mobile": "eSignering",
    "questionnaire.scroll_notification.description": "Bla for å bytte om på spørsmålene",
    "questionnaire.top_toolbar.rename_tooltip": "Gi nytt navn",
    "questionnaire.toggle_button.hide": "Skjul",
    "questionnaire.toggle_button.show": "Vis",
    "questionnaire.preview.heading": "Forhåndsvis",
    "questionnaire.preview.show_full_screen": "Forhåndsvis dokument",
    "questionnaire.preview.modal.header": "Forhåndsvisning",
    "questionnaire.questions.errors.email": "Oppgi en gyldig e-postadresse",
    "questionnaire.questions.errors.required": "Dette feltet er obligatorisk",
    "questionnaire.questions.errors.uint": "Skriv inn et positivt heltall",
    "questionnaire.questions.errors.date": "Vennligst angi en gyldig dato i formatet {format}",
    "questionnaire.questions.not_sure": "Ikke sikker?",
    "questionnaire.questions.skip": "Fyll inn spørsmål senere",
    "questionnaire.questions.input_placeholder": "skriv...",
    "questionnaire.questions.help_text.hide": "Skjul hjelp",
    "questionnaire.questions.help_text.show": "Vis hjelp",
    "questionnaire.questions.buttons.ok": "Fortsett",
    "questionnaire.questions.buttons.ok_caption": "eller trykk",
    "questionnaire.questions.buttons.complete": "Ferdig",
    "questionnaire.questions.buttons.enter": "Tast inn",
    "questionnaire.questions.buttons.begin": "Begynne",
    "questionnaire.questions.date_input_placeholder": "velg...",
    "questionnaire.questions.input_number_placeholder": "Skriv inn et nummer",
    "questionnaire.left_toolbar.based_on_template": "Basert på mal:",
    "questionnaire.left_toolbar.question_list": "Spørsmål",
    "questionnaire.left_toolbar.go_back_documents": "Gå tilbake til dokumenter",
    "questionnaire.left_toolbar.go_back_editor": "Gå tilbake til editor",
    "questionnaire.left_toolbar.introduction": "Introduksjon",
    "questionnaire.finished_with_skipped.heading": "Nesten ferdig!",
    "questionnaire.finished_with_skipped.description": "Vennligst svar på de øvrige spørsmålene for å fortsette.",
    "questionnaire.finished_with_skipped.go_to_editor": "Gå til editor",
    "questionnaire.questionnaire_error": "Ops, her har det skjedd en feil.",
    "questionnaire.changed_answer_saved": "Ditt endrede svar er blitt lagret",
    "questionnaire.download_document_modal.title": "Last ned dokumentet",
    "questionnaire.download_document_modal.description": "Gi dokumentet ditt et navn slik at du enkelt kan finne det igjen. ",
    "questionnaire.download_document_modal.placeholder": "Navngi dokumentet så du enkelt finner tilbake",
    "questionnaire.download_document_modal.label": "Dokument navn",
    "questionnaire.download_document_modal.format_label": "Format",
    "questionnaire.download_document_modal.download": "Last ned",
    "questionnaire.open": "Rediger svar på ",
    "questionnaire.edit_modal.title": "Opprette en ny versjon",
    "questionnaire.edit_modal.description": "Hvis du fortsetter å redigere dokumentet, vil det ikke lenger være mulig å gå tilbake til spørreskjemaet.",
    "questionnaire.edit_modal.tip": "Hvis du vil endre et svar, klikker du på det i dokumentet. ",
    "questionnaire.edit_modal.proceed": "Fortsett",
    "questionnaire.save_version": "Lagre versjon",
    "questionnaire.save_version_modal.title": "Lagre versjon",
    "questionnaire.save_version_modal.description": "Du lagrer dette som en versjon av dokumentet ditt. Hvis du ønsker det, kan du oppdatere navnet for å gjenspeile endringene dine.",
    "questionnaire.save_version_modal.name": "Dokument navn",
    "questionnaire.save_version_modal.version": "Versjon:",
    "questionnaire.save_version_modal.placeholder": "F.eks Leieavtale - Downing Street 10, London",
    "questionnaire.introduction.title": "Introduksjon",
    "questionnaire.introduction.primary_description": "Velkommen til dette skjema for {documentName}. For å få til det ferdige dokument er det 3 steg, som vi vil hjelpe deg gjennom.",
    "questionnaire.introduction.secondary_description": "Svarene dine lagres fortløpende, og du kan alltid returnere til skjemaet for å fortsette om du ikke blir ferdig med en gang.",
    "questionnaire.introduction.stepsWithSigning.one": "Fylle inn svar på spørsmålene til dokumentet",
    "questionnaire.introduction.stepsWithSigning.two": "Sende til digital signering eller laste ned for å signere med penn",
    "questionnaire.introduction.stepsWithSigning.three": "Når alle har signert har du en ferdig kontrakt!",
    "questionnaire.introduction.stepsWithoutSigning.one": "Fylle inn svar på spørsmålene til dokumentet",
    "questionnaire.introduction.stepsWithoutSigning.two": "Få ditt ferdigstilte, tilpassede dokument",
    "questionnaire.introduction.stepsWithoutSigning.three": "Last ned ditt dokument for lagring eller signering",
    "questionnaire.introduction.help_description": "Trenger du hjelp til å bruke løsningen?",
    "questionnaire.introduction.help_chat_toggle_button_label": "Spør oss! 👉",
    "questionnaire.document_finished": "Ditt dokument er ferdig. Du kan fortsatt endre svarene ved å klikke på de direkte i dokumentet.",
    "questionnaire.document_finished_version": "Du er nå i versjon {version} av dokumentet",
    "questionnaire.document_finished_preview": "Dette er en forhåndsvisning. For å gå tilbake, klikk på ett av svarene i selve dokumentet",
    "questionnaire.change_answers": "Endre svar",
    "questionnaire.edit_document": "Rediger dokument",
    "questionnaire.change_answers_disabled": "Spørreskjemaet er ikke lenger tilgjengelig",
    "questionnaire.download": "Last ned",
    "questionnaire.download_tooltip": "Last ned",
    "questionnaire.document_sent_for_signing_answer_tooltip_message": "Dette dokumentet er sendt til signering. Svarene du har avgitt kan ikke endres.",
    "questionnaire.document_finished_sent_for_signing": "Ditt dokument er sendt til signering. På dette tidspunktet kan du ikke redigere eller endre dokumentet. Hvis du vil endre noe må du avbryte signering og så gjøre de endringene du ønsker.",
    "validation.email": "Her skal du skrive inn en gyldig e-post adresse",
    "shared_components.page_break": "Sideskift",
    "shared_components.tags_area.errors.email_exist": "E-post finnes allerede",
    "shared_components.tags_area.errors.invalid_email": "E-posten er ugyldig",
    "shared_components.save_notification.saving": "Lagrer ...",
    "shared_components.save_notification.saved": "Lagret automatisk",
    "shared_components.save_notification.failed": "Endringene ble ikke lagret",
    "shared_components.save_notification.saving_tooltip": "Vi lagrer automatisk endringene dine.",
    "shared_components.password_input.length_requirement": "Minst 10 tegn",
    "shared_components.password_input.letters_and_numbers": "Bruk bokstaver og tall",
    "shared_components.password_input.mix_lower_and_uppercase": "Bruk både små og store bokstaver",
    "shared_components.password_input.password_must_contain": "Passordet må inneholde",
    "shared_components.password_input.special_character": "Minst 1 spesialtegn",
    "shared_components.password_strength_indicator.strength": "Styrke",
    "shared_components.password_strength_indicator.weak": "Veldig svak",
    "shared_components.password_strength_indicator.ok": "Svak",
    "shared_components.password_strength_indicator.good": "Flott",
    "simple_form.yes": "Ja",
    "simple_form.no": "Nei",
    "simple_form.required.text": "påkrevd",
    "simple_form.required.mark": "*",
    "simple_form.error_notification.default_message": "Vennligst se gjennom problemene under: ",
    "user_edit.active_user": "Aktiv bruker",
    "user_edit.reinvite_user": "Inviter bruker på nytt",
    "user_edit.cancel": "Avbryt",
    "user_edit.confirm": "Bekreft",
    "user_edit.save": "Lagre",
    "user_edit.go_back": "Gå tilbake til oversikt over brukere",
    "user_edit.roles_permissions.admin": "Har tilgang til å administrere brukere, og lage maler og dokumenter",
    "user_edit.roles_permissions.producer": "Har tilgang til å lage maler og dokumenter",
    "user_edit.roles_permissions.consumer": "Har tilgang til å lage dokumenter",
    "user_edit.deactivate_user": "Deaktiver brukeren",
    "user_edit.activate_user": "Aktiv bruker",
    "user_edit.deactivate_user_description": "Vennligst bekreft deaktivering av brukeren {userEmail}",
    "user_edit.activate_user_description": "Vennligst bekreft aktivering av brukeren {userEmail}",
    "user_edit.reinvite_user_description": "Vennligst bekreft ny invitasjon til brukeren {userEmail}",
    "user_mailer.forget_password.subject": "Instruksjoner for tilbakestilling av passord",
    "user_mailer.forget_password.hello": "Hei, %{name}",
    "user_mailer.forget_password.change_password": "Tilbakestill passordet ditt",
    "user_mailer.forget_password.first_paragraph": "Du har nylig bedt om å tilbakestille passordet. For å oppdatere passordet ditt, klikk på knappen nedenfor:",
    "user_mailer.forget_password.button_text": "Tilbakestill passordet mitt",
    "user_mailer.forget_password.second_paragraph": "Hvis du ikke ba om at passordet ditt skal tilbakestilles og tror at kontoen din har blitt kompromittert, vennligst",
    "user_mailer.forget_password.mail_to_text": "kontakt oss",
    "user_mailer.forget_password.help": ".",
    "user_mailer.forget_password.greetings": "Med vennlig hilsen,",
    "user_profile.password_change_form.cant_be_blank": "Feltet kan ikke være tomt",
    "user_profile.password_change_form.passwords_must_match": "Passordene må være like",
    "user_profile.password_change_form.password_not_strong_enough": "Dette passordet er ikke sterkt nok",
    "user_profile.password_change_form.save_new_passsword": "Lagre ditt nye passord",
    "user_profile.password_change_form.current_password": "Nåværende passord",
    "user_profile.password_change_form.new_password": "Nytt passord",
    "user_profile.password_change_form.confirm_password": "Bekreft passord",
    "user_profile.password_change_form.notification_success": "Suksess",
    "user_profile.password_change_form.notification_password_changed": "Ditt passord har nå blitt endret.",
    "user_profile.password_change_form.notification_error": "Feil",
    "user_profile.password_change_form.notification_error_default": "Ops, her har det skjedd en feil. Vennligst kontakt systemadministrator",
    "user_profile.password_change_form.notification_error_current_password": "Vennligst oppgi riktig nåværende passord",
    "user_profile.personal_information_form.cant_be_blank": "Feltet kan ikke være tomt",
    "user_profile.personal_information_form.save_changes": "Lagre endringer",
    "user_profile.personal_information_form.email": "E-post",
    "user_profile.personal_information_form.first_name": "Fornavn",
    "user_profile.personal_information_form.last_name": "Etternavn",
    "user_profile.personal_information_form.invalid_email": "E-posten er ugyldig",
    "user_profile.personal_information_form.notification_success": "Suksess",
    "user_profile.personal_information_form.notification_personal_information_updated": "Dine personlige opplysninger har blitt oppdatert",
    "user_profile.personal_information_form.notification_error": "Feil",
    "user_profile.personal_information_form.notification_error_something": "Ops, her har det skjedd en feil. Vennligst kontakt systemadministrator",
    "user_profile.user_profile_form.password_change": "Bytt passord",
    "user_profile.user_profile_form.personal_information": "Personlig informasjon",
    "user_profile.user_profile_header.subheading": "Personlige opplysninger og kontotilgang",
    "user_profile.user_profile_header.account_settings": "Kontoinstillinger",
    "users_invite.title": "Ny invitasjon",
    "users_invite.subtitle": "Fyll ut informasjonen nedenfor for å opprette nye brukere. Vi sender en e-post til lagkameratene dine for å logge inn.",
    "users_invite.send": "Send invitasjon",
    "users_invite.success.title": "Suksess",
    "users_invite.success.description": "Invitasjoner sendt til nye medlemmer",
    "users_invite.failure.title": "Feil",
    "users_invite.failure.description": "Ops, her har det skjedd en feil. Prøv igjen eller vennligst kontakt systemadministrator.",
    "users_invite.errors.no_emails": "Det må være minst én gyldig e-postadresse",
    "users_invite.errors.is_blank": "kan ikke være tomt",
    "users_invite.roles_labels.admin": "Bedriftsadministrator",
    "users_invite.roles_labels.producer": "Produsent",
    "users_invite.roles_labels.consumer": "Bruker",
    "users_invite.roles_permissions.admin": "Har tilgang til å administrere brukere, og lage maler og dokumenter",
    "users_invite.roles_permissions.producer": "Har tilgang til å lage maler og dokumenter",
    "users_invite.roles_permissions.consumer": "Har tilgang til å lage dokumenter",
    "users_invite.form_labels.email_addresses": "E-postadresse(r)",
    "users_invite.form_labels.role": "Rolle",
    "users_invite.cancel": "Avbryt",
    "users_list.edit_user_btn": "Rediger bruker",
    "users_list.title": "Brukere",
    "users_list.subTitle": "Liste over alle brukere i bedriften",
    "users_list.invite": "Inviter medlemmer",
    "users_list.new_invitation": "Ny invitasjon",
    "users.roles.consumer": "Bruker",
    "users.roles.producer": "Produsent",
    "users.roles.admin": "Bedriftsadministrator",
    "share_template.title": "Del dokument link",
    "share_template.description": "Alle med lenken kan lage et nytt dokument med denne malen.",
    "share_template.enable": "Aktiver dokument link",
    "share_template.info": "Vær oppmerksom på at alle dokumenter som er opprettet med denne lenken vil bli lagt til i bedriftens dokument plan.",
    "share_template.copy": "Kopier til utklippstavle",
    "share_template.copied": "Kopiert til utklippstavlen",
    "share_template.not_available.action_button": "Gå til mal",
    "share_template.not_available.description": "Ved å gi denne lenken til andre mennesker, tillater du dem å lage et nytt dokument på denne malen.",
    "share_template.not_available.info": "For å få tilgang til dokument link, må du først publisere malen.",
    "share_wizard.name_contract": "Gi dokumentet et navn ",
    "share_wizard.provide_email": "Skriv inn e-postadressen din, så sender vi deg en link slik at du enkelt finner tilbake til dokumentet i fremtiden.",
    "share_wizard.find_contract": "Gå tilbake",
    "share_wizard.accept": "Godkjenn",
    "share_wizard.terms_and_condition": "Brukervilkår",
    "share_wizard.create_document": "Opprett dokument",
    "share_wizard.enter_email": "Skriv inn din email",
    "share_wizard.message": "Melding",
    "share_wizard.template": "NDA-mal",
    "share_wizard.errors.invalid_email": "Her skal du skrive inn en gyldig e-post adresse",
    "share_wizard.errors.no_document_title": "Dokumenttittel kan ikke være tomt",
    "share_wizard.name_contract_description.default": "Gi dokumentet et beskrivende navn slik at du enkelt kan finne det igjen.",
    "share_wizard.name_contract_description.arv": "Velkommen! Vi anbefaler at du skriver ditt testament på en datamaskin eller lignende. Her kan du gi dokumentet et beskrivende navn slik at du enkelt kan finne det igjen.",
    "generic_access_mailer.document_created.email_title": "Ditt %{document}",
    "generic_access_mailer.document_created.hello": "Hei %{email},",
    "generic_access_mailer.document_created.content": "Klikk på knappen nedenfor når som helst for å gå tilbake til dokumentet.",
    "generic_access_mailer.document_created.open_contract": "Åpne dokument",
    "generic_access_mailer.document_created.greetings_depart": "Beste hilsener",
    "generic_access_mailer.document_created.footer.help": "Hjelp",
    "generic_access_mailer.document_created.footer.privacy_policy": "Brukervilkår",
    "generic_access_mailer.document_created.footer.rights": "Alle rettigheter reservert",
    "generic_access_mailer.document_created.footer.add_sender_to_address_book": "For å sikre at du mottar e-postene våre korrekt, vennligst legg til noreply@lawbotics.io i adresseboken.",
    "generic_access_mailer.resend_document.email_title": "Tilgangslink for %{document}",
    "signing_document_mailer.notify_sender.email_title": "%{name} er sendt for signering",
    "signing_document_mailer.notify_sender.hello": "Hei %{name}",
    "signing_document_mailer.notify_sender.intro": "Avtalen din har nå blitt sent til alle de som må signere.",
    "signing_document_mailer.notify_sender.parallel.content": "Denne avtalen vil bli signert samtidig av alle parter i avtalen. Om du skal signere selv vil du motta et link i en egen e-post. Når alle har signert vil du få beskjed på e-post.",
    "signing_document_mailer.notify_sender.sequential.content": "Denne avtalen vil bli signert i en bestemt rekkefølge. Alle som må signere vil få beskjed på e-post når det er deres tur til å signere avtalen. Når alle parter har signert vil du få beskjed på e-post.",
    "signing_document_mailer.notify_sender.sequential.order_of_signees": "Rekkefølge for signering:",
    "signing_document_mailer.notify_sender.sequential.signee": "Part %{index}: %{email}",
    "resend_link.success": null,
    "resend_link.error": null,
    "document_details.version_draft.info_message": "Dokumentet redigeres. Den må lagres før den kan lastes ned eller sendes for signering.",
    "document_details.versions.see_latest_version": "Se den nye versjonen"
  }
};
