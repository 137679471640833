import styled from 'styled-components';

export const Circle = styled.div`
  position: fixed;
  z-index: 3;
  bottom: -380px;
  right: -380px;
  width: 800px;
  height: 800px;
  background-color: ${props => props.theme.mainColor};
  color: ${props => props.theme.font.white};
  border-radius: 50%;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.14), 0 10px 50px 0 rgba(0, 0, 0, 0.12), 0 30px 10px -20px rgba(0, 0, 0, 0.2);
`;

export const Content = styled.div`
  position: absolute;
  top: 25%;
  left: 10%;
`;

export const Button = styled.button`
  padding: 20px;
  position: fixed;
  padding: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.theme.mainColor};
  color: ${props => props.theme.font.white};
  z-index: 4;
  border: none;
`;

export const ButtonPlaceholder = styled.div`
  border: 20px solid white;
  position: absolute;
  padding: 0;
  bottom: calc(50% - 40px);
  right: calc(50% - 40px);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.theme.mainColor};
  color: ${props => props.theme.font.white};
`;
