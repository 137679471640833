import React from 'react';
import PropTypes from 'prop-types';
import MaterialUiSwitch from '@material-ui/core/Switch';
import cn from 'classnames';
import { SwitchIcon } from './SwitchIcon';
import styles from './index.css';

export default function Switch({
  className,
  testId,
  disabled,
  ...otherProps
}) {
  return (
    <MaterialUiSwitch
      data-test-id={testId}
      color="default"
      icon={<SwitchIcon />}
      disabled={disabled}
      checkedIcon={<SwitchIcon checked />}
      classes={{
        root: cn(styles.root, disabled ? styles.disabledRoot : ''),
        bar: styles.bar,
        checked: styles.checked,
        disabled: styles.disabled,
      }}
      {...otherProps} />
  );
}

Switch.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  testId: PropTypes.string.isRequired,
};
