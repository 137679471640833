import React, { PureComponent } from 'react';
import keycode from 'keycode';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { StyledButton } from './styled-components';

export default class OkButton extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    nextDisabled: PropTypes.bool.isRequired,
    asComplete: PropTypes.bool,
    clickOnEnter: PropTypes.bool,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.enterFn, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.enterFn, false);
  }

  enterFn = (event) => {
    const { nextDisabled, clickOnEnter } = this.props;

    if (event.keyCode === keycode('enter') && !nextDisabled && clickOnEnter) {
      this.props.onClick();
    }
  };

  render() {
    const { onClick, nextDisabled, asComplete } = this.props;
    const buttonMessagePartialId = !asComplete ? 'ok' : 'complete';
    const testId = !asComplete ? 'Questionnaire-continueButton' : 'Questionnaire-finishButton';

    return (
      <StyledButton onClick={onClick} disabled={nextDisabled} testId={testId}>
        <FormattedMessage id={`questionnaire.questions.buttons.${buttonMessagePartialId}`} />
      </StyledButton>
    );
  }
}
