import React from 'react';
import PropTypes from 'prop-types';
import StyledNotifications from 'shared/TopToolbar/StyledNotifications';

const Notification = ({ text, icon, warning, testId }) => (
  <StyledNotifications warning={warning} data-test-id={testId}>
    <div data-tip={text}>
      {icon}
      {text}
    </div>
  </StyledNotifications>
);

Notification.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  warning: PropTypes.bool,
  testId: PropTypes.string,
};

export default Notification;
